import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "../../store/auth";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useModal } from "../../shared/hooks/modal-hook";
import shopifyBag from "../../shared/assets/images/icons/shopify-bag.png"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import 'react-toastify/dist/ReactToastify.css';
import cookies from "../../shared/util/cookies";
import { useNavigate } from "react-router-dom";
import { FaCheck, FaStripe } from "react-icons/fa";
import Modals from "../../shared/enums/Modals";

const NonShopifyStartSubscription = (props) => {
  const { openModal } = useModal()
  const navigate = useNavigate()
  const { isLoading, sendRequest } = useHttpClient();
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth);
  let user = auth.user;
  if (props.userInView) {
    user = props.userInView
  }
  
  const startSubscriptionStripe = async () => {
    if (!user.isSpecialUser && !user.subscription || (user.subscription && user.subscription.status !== 'active')) {
      openModal({
        type: Modals.PAYMENT_REQUIRED,
        closeOnClickOutside: false,
        from: "config",
      })
      cookies.set("config")
    } else {
      console.log("handle else case")
    }
  }

  return (
    <div>
      {
        isLoading ? <LoadingSpinner /> :
          (user.subscription && user.subscription.status === 'active') ?
            <div className="no-data">
              <div className="row">
                Status:
                <div className="dot"></div>
                <span style={{ fontWeight: "600" }}>Subscribed</span>
              </div>

              <p>Your Enhencer subscription has started.</p>
            </div>
            :
            < div className="no-data">
              <div>
                First of all, you should start your subscription to continue.
                <br></br>

                <div className="connect-options">
                  <div
                    className="option-button row"
                    onClick={startSubscriptionStripe}
                  >
                    Subscribe via
                    <FaStripe fontSize={32}></FaStripe>
                  </div>
                </div>

              </div>
            </div>
      }
    </div >

  );
};

export default NonShopifyStartSubscription;
