import React from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'

import metaIcon from '../../shared/assets/images/icons/meta-icon-green.svg'
import Card from '../../shared/components/UIElements/Card'
import numberFormatter from '../../shared/util/numberFormatter'
import helper from '../../shared/util/helper'

import './RevenueGraph.css'
import { PointElement } from 'chart.js'

const RevenueGraph = () => {
  const { yearlyInsights, totalRevenue } = useSelector(
    state => state.adHealthCheckup.insights,
  )
  const facebook = useSelector(state => state.facebook)

  const chartPointRadius =
    parseInt(window.innerWidth / 400) > 5
      ? 5
      : parseInt(window.innerWidth / 400)

  const labels = yearlyInsights.revenue.map(obj => Object.keys(obj)[0])
  const data = yearlyInsights.revenue.map(obj => Object.values(obj)[0])

  const findMonthByRevenue = revenue => {
    const index = data.indexOf(revenue)
    return labels[index]
  }

  const maxRevenue = Math.max(...data)
  const minRevenue = Math.min(...data)
  const averageRevenue = totalRevenue / data.length

  const topMonth = findMonthByRevenue(maxRevenue)
  const bottomMonth = findMonthByRevenue(minRevenue)

  const chartOptions = {
    responsive: true,
    tension: 0.7,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
      },
      y: {
        display: false,
      },
    },
    datasets: {
      label: false,
    },
  }

  const revenueData = {
    options: chartOptions,
    labels,
    datasets: [
      {
        label: 'Revenue',
        data,
        backgroundColor: () => {
          const ctx = document.createElement('canvas').getContext('2d')
          const gradient = ctx.createLinearGradient(0, 0, 0, 350)
          gradient.addColorStop(0, '#CAF9AD')
          gradient.addColorStop(1, 'rgba(24, 214, 100, 0.00)')
          return gradient
        },
        lineWidth: 0,
        borderColor: '#69B55A',
        fill: true,
        pointRadius: chartPointRadius,
        pointHoverRadius: parseInt(chartPointRadius * 1.6),
        pointBorderWidth: 3,
        pointBackgroundColor: '#69B55A',
        pointHoverBackgroundColor: '#69B55A',
      },
    ],
  }

  return (
    <div className="content-box revenue-graph-main">
      <div className="column">
        <div className="second-big-text">
          Here are your peak performance months <img src={metaIcon} />
        </div>
        <div className="small-text">
          Month by Month Revenue Earned through Meta Ads
        </div>
      </div>

      <div className="row statistics">
        <div className="top-bottom-revenue">
          <div className="top-revenue">
            <div className="smaller-text">Top Month</div>
            <div className="secondary-text">{topMonth}</div>
            <div className="small-text">
              <span className="currency">
                {helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)}
              </span>

              {numberFormatter.formatNumber(Number(maxRevenue.toFixed(3)))}
            </div>
          </div>
          <div className="bottom-revenue">
            <div className="smaller-text">Bottom Month</div>
            <div className="secondary-text">{bottomMonth}</div>
            <div className="small-text">
              <span className="currency">
                {helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)}
              </span>

              {numberFormatter.formatNumber(Number(minRevenue.toFixed(3)))}
            </div>
          </div>
        </div>
        <div className="average-revenue">
          <div className="small-text">Average Revenue</div>

          <div className="secondary-text">
            <span className="currency">
              {helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)}
            </span>
            {numberFormatter.formatNumber(Number(averageRevenue.toFixed(3)))}
          </div>
        </div>
      </div>

      <div className="graph">
        <Card>
          <Line options={revenueData.options} data={revenueData} />
        </Card>
      </div>
    </div>
  )
}

export default RevenueGraph
