import React, { useMemo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaTrash } from 'react-icons/fa' // Example with FaTrash (Delete) icon
import { toast } from 'react-toastify'

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import CustomTable from '../../shared/components/UIElements/CustomTable'
import SelectColumnFilter from '../../shared/components/UIElements/SelectColumnFilter'
import DateRangeColumnFilter from '../../shared/components/UIElements/DateRangeColumnFilter'
import NumberRangeColumnFilter from '../../shared/components/UIElements/NumberRangeColumnFilter'
import EditableCell from '../../shared/components/UIElements/EditableCell'
import helper from '../../shared/util/helper'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useConstants } from '../../shared/hooks/constants-hook'

import 'react-datepicker/dist/react-datepicker.css'
import './AdminPanelAnStatisticsTable.css'

const AdminPanelAnStatisticsTable = () => {
  const auth = useSelector(state => state.auth)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()

  let [userData, setUserData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const skipPageResetRef = useRef()
  const dataArray = []

  const [cardNotificationVisible, setCardNotificationVisible] = useState(false)
  const [cardNotificationMessage, setCardNotificationMessage] = useState('')

  const columns = useMemo(
    () => [
      {
        Header: 'An Statistics About Enhencer Customers',
        columns: [
          {
            Header: 'Enhencer Category',
            accessor: 'enhencerCategory',
          },
          {
            Header: 'Country',
            accessor: 'country',
          },
          {
            Header: 'Count',
            accessor: 'count',
          },
        ],
      },
    ],
    [],
  )

  useEffect(() => {
    //get customers or leads
    const fetchUsers = async () => {
      if (auth.token) {
        try {
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getAudienceNetworkStatistics`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          )
          setUserData(response.result)
        } catch (err) {
          toast.error(err?.message || "Something went wrong")
        }
      }
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    // After the table has updated, always remove the flag
    skipPageResetRef.current = false
  })

  return (
    <div className="admin-panel-table">
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <React.Fragment>
        <CustomTable
          columns={columns}
          data={userData}
          skipPageResetRef={skipPageResetRef}
          isResetFiltersVisible={false}
        />
      </React.Fragment>
      {/* {!isLoading && data &&
        <React.Fragment>

          <CustomTable columns={columns} data={data} updateMyData={updateMyData} skipPageResetRef={skipPageResetRef} />
        </React.Fragment>
      } */}
    </div>
  )
}

export default AdminPanelAnStatisticsTable