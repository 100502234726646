import React from 'react'
import './Switch.css'

const Switch = ({
  type,
  options,
  value,
  onChange,
  disabled,
  lowerCaseOptions,
}) => {
  return type === 'tab' ? (
    <div
      className={value === options[0] ? 'tab-switch left' : 'tab-switch right'}
    >
      <div
        className="left-side"
        onClick={() => {
          onChange(options[0])
        }}
      >
        {lowerCaseOptions ? options[0].toLowerCase() : options[0]}
      </div>
      <div
        className="right-side"
        onClick={() => {
          onChange(options[1])
        }}
      >
        {lowerCaseOptions ? options[1].toLowerCase() : options[1]}
      </div>
      <div className="selection"></div>
    </div>
  ) : (
    <div
      className={`${disabled ? 'disabled' : ''} ${value === options[0] ? 'switch left' : 'switch right'}`}
    >
      <div
        className="left-side"
        onClick={() => {
          onChange(options[0])
        }}
      >
        {lowerCaseOptions ? options[0].toLowerCase() : options[0]}
      </div>
      <div
        className="right-side"
        onClick={() => {
          onChange(options[1])
        }}
      >
        {options[1] === 'YEARLY' && (
          <>
            {options[1].toLowerCase()}{' '}
            <span className="save-text">(Save 50%)</span>
          </>
        )}
        {options[1] !== 'YEARLY' &&
          (lowerCaseOptions ? options[1].toLowerCase() : options[1])}
      </div>
      <div className="selection"></div>
    </div>
  )
}

export default Switch
