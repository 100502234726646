import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../../hooks/http-hook'
import { useModal } from '../../hooks/modal-hook'

import './LookalikeAudienceConfig.css'
import Checkbox from '../FormElements/Checkbox'
import Button from '../FormElements/Button'

const LookalikeAudienceConfig = props => {
  const { sendRequest, isLoading } = useHttpClient()
  const { closeModal } = useModal()
  const authToken = useSelector(state => state.auth.token)
  console.log("now ", props.data)
  const userId = props.data.userId
  const enhCampaign = props.data.enhCampaign
  const adAccountId = enhCampaign.adAccountId
  const adAccountAudiences = props.data.adAccountAudiences
  const adset = props.data.adset
  const adsetAudiences = adset.audiences
  const callback = props.data.callback



  const anAudiences = adsetAudiences.filter(a => !a.isLookalike)
  const initialLalAudiences = adsetAudiences.filter(a => a.isLookalike)

  /* 
  
  const [finalLalAudiences, setFinalLalAudiences] = useState(initialLalAudiences) */

  const [initialList, setInitialList] = useState(anAudiences)
  const [list, setList] = useState(anAudiences)



  useEffect(() => {
    let newList = [...list]

    anAudiences.forEach((audience, index) => {
      newList[index] = {
        ...list[index],
        hasLookalike: initialLalAudiences.map(a => a.seedId).includes(audience.id)
      }
    })

    setInitialList(newList)
    setList(newList)
    console.log("new list ", newList)

  }, [adsetAudiences])


  const handleCheckboxChange = ({ value, index }) => {
    let newList = [...list]
    newList[index] = {
      ...newList[index],
      hasLookalike: value
    }
    setList(newList)
  }

  const handleSave = async () => {
    console.log("save them ", list)

    let audiencesToAdd = []
    let audiencesToRemove = []

    list.forEach((audience, index) => {
      if (!initialList[index].hasLookalike && list[index].hasLookalike) {
        audiencesToAdd.push(audience)
      } else if (initialList[index].hasLookalike && !list[index].hasLookalike) {
        audiencesToRemove.push(audience)
      }
    })

    console.log("audiencesToAdd ", audiencesToAdd)
    console.log("audiencesToRemove ", audiencesToRemove)
    const { genders, age, countryCodes, countries } = enhCampaign

    try {
      let body = {
        adAccountId,
        adset,
        audiencesToAdd,
        audiencesToRemove,
        genders,
        age,
        countryCodes,
        countries,
        userId,
        existingAudiences: adsetAudiences,
        enhCampaignId: enhCampaign._id,
        adAccountAudiences
      }

      console.log("body ", body)
      let { finalAdsetAudiences } = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateAdsetLookalikes/`,
        'POST',
        JSON.stringify(body),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      callback(finalAdsetAudiences)
      closeModal()
      toast.success('Changes saved!')
    } catch (err) {
      toast.error(err?.message || 'Something went wrong')
    }
  }

  return (
    <div className="lookalike-audience-config">
      {list.length ?
        <React.Fragment>
          <h3>Select the lookalike audiences to share</h3>
          <ul>
            {list.map((audience, index) =>
              <li key={audience.id}>
                <Checkbox
                  name={audience.name}
                  label={audience.name}
                  checked={audience.hasLookalike}
                  onChange={(e) => {
                    handleCheckboxChange({
                      value: e.target.checked,
                      index
                    })
                  }}

                />

              </li>
            )}

          </ul>
          <Button onClick={handleSave} loadingText="Saving"
            loading={isLoading}>
            Save
          </Button>
        </React.Fragment>
        :
        <div>
          No AN audiences found, add them first for adding lookalikes.
        </div>
      }

    </div>
  )
}

export default LookalikeAudienceConfig
