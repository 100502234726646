// eslint-disable-next-line import/no-anonymous-default-export
export default {
  shortenNumber: function (number) {
    if (isNaN(number) || number === null) return ''
    let result = number.toFixed(0)
    if (number >= 1000000000) {
      result = (number / 1000000000).toFixed(1) + 'B'
    } else if (number >= 1000000) {
      result = (number / 1000000).toFixed(1) + 'M'
    } else if (number >= 1000) {
      result = (number / 1000).toFixed(1) + 'K'
    }
    return result
  },

  formatNumber: function (number, decimalPoints) {
    if (isNaN(number) || number === null) return ''
    if (number % 1 === 0) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return number
        .toFixed(decimalPoints ?? 1)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  reduceDecimalPointsIfFloat: function (number, decimalPoints) {
    if (!number) return ''
    return number % 1 > 0 ? number.toFixed(decimalPoints ?? 1) : number
  },
  // TODO: Right a function to shorten or format number conditionally
}
