import React from "react";
import { useNavigate } from "react-router-dom";

import { useModal } from "../../shared/hooks/modal-hook";

import "./ConnectOptions.css"
import Modals from "../../shared/enums/Modals";

const ConnectOptions = props => {
    const { openModal } = useModal()
    const navigate = useNavigate()
    const subscription = props.subscription

    const goTo = async link => {
      if (!subscription || (subscription && subscription.status !== 'active')) {
        openModal({
          type: Modals.PAYMENT_REQUIRED,
          closeOnClickOutside: false,
          from: 'connect-overview',
        })
      } else {
        navigate(link)
      }
    }

    return <div className="connect-your-website">
        <h4 className="connect-text">
            Enhencer is not connected to your website. You can connect with Google Tag Manager or Javascript SDK!
        </h4>
        <h3>Connect using:</h3>
        <div className="icon-div">
        <div className="tag">
            <img className="tag-icon" alt="tag" width="150px" onClick={() => {goTo("/connect/google-tag-manager")}} src="https://cdn.enhencer.com/admin-assets/images/icons/gtm.webp"></img>
        </div>
        <div className="sdk">
            <img className="sdk-icon" alt="sdk" width="150px" onClick={() => {goTo("/connect/javascript-sdk")}} src="https://cdn.enhencer.com/admin-assets/images/icons/js-sdk.png"></img>
        </div>
        </div>
    </div>
}

export default ConnectOptions;