import React from 'react'

import ProgressTracker from './ProgressTracker'

const AudienceProgress = ({ stats }) => {
  const steps = [
    {
      name: `${stats.uplift}x Uplift`,
      detail: (
        <div>
          Enhencer Remarketing AI Audience is the top{' '}
          {stats.topVisitorsPercentage}% of store visitors. This audience is{' '}
          {stats.uplift} times more likely to purchase your product compared to
          all visitors.
        </div>
      ),
      isActive: true,
      icon: <span>1</span>,
    },
    {
      name: `Top ${stats.topVisitorsPercentage}% of Store Visitors (Segment ${
        stats.numberOfSelectedSegments <= 1
          ? stats.numberOfSelectedSegments
          : `1 - ${stats.numberOfSelectedSegments}`
      })`,
      detail: (
        <div>
          Our algorithm automatically exclude the store visitors from the
          remarketing audience to distribute the budget to correct audience.{' '}
        </div>
      ),
      isActive: true,
      icon: <span>2</span>,
    },
    {
      name: 'Dynamic AI Segmentation',
      detail: (
        <div>
          All segment rule sets are dynamically updated by visitors purchase
          behavior with the help of our AI engine. Enhencer AI ads guarantee the
          highest conversion rate on the campaign via robust segmentation,
          therefore,
        </div>
      ),
      isActive: true,
      icon: <span>3</span>,
    },
  ]

  return <ProgressTracker steps={steps} />
}

export default AudienceProgress
