import React from 'react'

import './ProgressTracker.css'

const ProgressTracker = ({ steps }) => {
  return (
    <div className="progress-tracker">
      <div className="steps">
        {steps.map((item, index) => (
          <div className="step" key={index}>
            <div className="flow-line">
              <div
                className={item.isActive ? 'icon-circle active' : 'icon-circle'}
              >
                {item.icon}
              </div>
            </div>
            <div className="step-content">
              <h5 className="name">{item.name}</h5>
              <div className="detail">{item.detail}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProgressTracker
