import { createSlice } from '@reduxjs/toolkit'
import helper from '../shared/util/helper';
import CreativeTypes from '../shared/enums/CreativeTypes'


class AdCreative extends Object {
  id = null
  type = null
  details = {
    primaryText: "",
    websiteUrl: "",
    catalog: null,
    productSet: null,
    facebookPage: null,
    instagramAccount: null,
  }
  body = null
  isValid = false

  // below are for updating the creative in list
  isEdited = false
  existsInListWithId = null
}


class EnhCampaign extends Object {
  id;
  label = "New campaign";
  isLabelValid = true;
  isLabelDuplicate = false;

  targeting = {
    countries: [],
    genders: "All",
    age: {
      min: 18,
      max: 65
    },
    dailyBudget: null,
    isDailyBudgetMoreThanMinimum: false
  };

  currentCreative = JSON.parse(JSON.stringify(new AdCreative()));
  adCreatives = {};
  trafficCampaign = {
    isActive: true,
  }
  isPublishable;

  constructor(enhCampaign) {
    super(enhCampaign)
    if (enhCampaign) {
      this.id = enhCampaign._id
      this.label = enhCampaign.label
      this.isLabelValid = true
      this.isLabelDuplicate = false
      this.targeting = {
        countries: enhCampaign.countries,
        genders: helper.getGenderString(enhCampaign.genders),
        age: enhCampaign.age,
        dailyBudget: enhCampaign.dailyBudget,
        isDailyBudgetMoreThanMinimum: true
      }

      if (enhCampaign.adCreatives) {
        // new version enhCampaign is being edited
        let editableCreatives = { ...enhCampaign.adCreatives }
        this.adCreatives = enhCampaign.adCreatives
        this.currentCreative = {
          ...Object.values(this.adCreatives)[0],
          existsInListWithId: Object.values(this.adCreatives)[0].id
        }
      } else {
        // if enh campaign exists but is old version
        let newCreativeObj = {
          id: enhCampaign.adCreative.id,
          type: CreativeTypes.CATALOG,
          details: {
            primaryText: enhCampaign.primaryText,
            websiteUrl: enhCampaign.websiteUrl,
            catalog: enhCampaign.catalog,
            productSet: enhCampaign.productSet,
            facebookPage: enhCampaign.facebookPage ?? enhCampaign.page,
            instagramAccount: enhCampaign.instagramAccount,
          },
          body: {
            id: enhCampaign.adCreative.id
          },
          isValid: true,
        }

        this.currentCreative = newCreativeObj
        this.adCreatives = {
          [newCreativeObj.id]: newCreativeObj
        }
      }

      this.isPublishable = true // check this part, not used for now
    }
  }
}

const initialLoadings = {
  isFetchingCatalogs: false,
  isFetchingProductSets: false,
  isFetchingFacebookPages: false,
  isFetchingInstagramAccounts: false,
  isProcessingTextFields: false
}

const initialState = {
  initialCampaign: null,
  currentCampaign: null,
  loadings: initialLoadings
}

// TODO: Separate auth and user actions.
// This slice is currently not in use.

const facebookCampaignEditorSlice = createSlice({
  name: 'facebookCampaignEditor',
  initialState,
  reducers: {
    initializeCampaignEditor(state, action) {
      let newCampaign = new EnhCampaign(action.payload.enhCampaign)
      state.initialCampaign = JSON.parse(JSON.stringify(newCampaign))
      state.currentCampaign = JSON.parse(JSON.stringify(newCampaign))
    },
    initializeEmptyCampaign(state, action) {
      state.initialCampaign = JSON.parse(JSON.stringify(new EnhCampaign()))
      state.currentCampaign = JSON.parse(JSON.stringify(new EnhCampaign()))
    },
    endCampaignEditing(state, action) {

      state.initialCampaign = null;
      state.currentCampaign = null;
      state.loadings = initialLoadings
    },
    changeLabel(state, action) {
      state.currentCampaign.label = action.payload.label
      state.currentCampaign.isLabelValid = action.payload.isValid
      state.currentCampaign.isLabelDuplicate = action.payload.isDuplicate
    },
    setLabelValidity(state, action) {
      state.currentCampaign.isLabelValid = action.payload
    },
    changeTargeting(state, action) {

      Object.keys(action.payload).forEach(field => {
        if (action.payload[field] !== undefined) state.currentCampaign.targeting[field] = action.payload[field]
      })

    },
    changeCountries(state, action) {
      state.currentCampaign.targeting.countries = action.payload
    },
    selectCreativeFromAdCreativesList(state, action) {
      state.currentCampaign.currentCreative = {
        ...action.payload,
        existsInListWithId: action.payload.id,
        isEdited: false
      }
    },
    emptyCurrentCreative(state, action) {
      state.currentCampaign.currentCreative = JSON.parse(JSON.stringify(new AdCreative()));
    },
    updateCurrentCreative(state, action) {
      Object.keys(action.payload).forEach(field => {
        if (action.payload[field] !== undefined) state.currentCampaign.currentCreative[field] = action.payload[field]
      })
    },
    addCreativeToAdCreativesList(state, action) {
      let newList = {
        ...state.currentCampaign.adCreatives,
        [action.payload.id]: action.payload
      }

      state.currentCampaign.adCreatives = newList
      state.currentCampaign.currentCreative.name = action.payload.name
      state.currentCampaign.currentCreative.existsInListWithId = action.payload.id

    },
    editCreativeInList(state, action) {
      let { newCreative, oldCreativeIdToRemove } = action.payload
      let newList = {
        ...state.currentCampaign.adCreatives,
        [newCreative.id]: newCreative
      }
      delete newList[oldCreativeIdToRemove]

      state.currentCampaign.adCreatives = newList
      state.currentCampaign.currentCreative = newCreative
    },
    removeCreativeFromAdCreativesList(state, action) {
      let newList = { ...state.currentCampaign.adCreatives }
      delete newList[action.payload.id]

      state.currentCampaign.adCreatives = newList
      state.currentCampaign.currentCreative = JSON.parse(JSON.stringify(new AdCreative()));

    },
    setNewCatalog(state, action) {
      state.currentCampaign.currentCreative.details.catalog = action.payload
    },
    setNewProductSet(state, action) {
      state.currentCampaign.currentCreative.details.productSet = action.payload
    },
    setNewFacebookPage(state, action) {
      state.currentCampaign.currentCreative.details.facebookPage = action.payload
    },
    setNewInstagramAccount(state, action) {
      state.currentCampaign.currentCreative.details.instagramAccount = action.payload
    },
    startLoadingState(state, action) {
      state.loadings[action.payload] = true
    },
    endLoadingState(state, action) {
      state.loadings[action.payload] = false
    },
    setIsPreviewUpToDate(state, action) {
      // TODO: check this
      // state.currentCampaign.currentCreative.isPreviewUpToDate = action.payload
    },
    editTrafficCampaign(state, action) {
      state.currentCampaign.trafficCampaign = action.payload
    }

  },
})

export const facebookCampaignEditorActions = facebookCampaignEditorSlice.actions

export default facebookCampaignEditorSlice.reducer