import React, { useRef, useState, useEffect } from 'react'
import { FaChevronLeft, FaBars, FaTimes, FaCalendar } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { authActions } from '../../../store/auth'
import { facebookActions } from '../../../store/facebook'
import CheckupIcon from '../../assets/images/ai-ads/checkup.png'
import enhencerLogo from '../../assets/images/icons/enhencer-logo-white.png'
import Intro from '../UIElements/Intro'
import helper from '../../util/helper'
import './TopBar.css'
import BarButton from '../UIElements/BarButton'
import Button from '../FormElements/Button'
import { adminActions } from '../../../store/admin'
import ProfileMenu from './ProfileMenu'
import AccountManagerCard from './AccountManagerCard'
import { useModal } from '../../hooks/modal-hook'
import PixelEventsVerificationRequired from '../Modals/PixelEventsVerificationRequired'
import { useHttpClient } from '../../hooks/http-hook'
import AdminCustomerTabs from '../../../admin/components/AdminCustomerTabs'
import PrivateComponent from '../UIElements/PrivateComponent'
import Modals from '../../enums/Modals'
import { useAdmin } from '../../hooks/admin-hook'

const TopBar = props => {
  const navigate = useNavigate()
  const location = useLocation()
  const childRef = useRef()
  const auth = useSelector(state => state.auth)
  const user = useSelector(state => state.auth.user)
  const facebook = useSelector(state => state.facebook)
  const { hasUnreadMessages, userInView } = useSelector(state => state.admin)
  const { startViewingAsUser } = useAdmin()
  const millis20days = 1000 * 60 * 60 * 24 * 20
  const shouldShowVerifyEventsButton =
    user?.connectFlow?.completedFacebookConnections <
    new Date().valueOf() - millis20days
  const pixelEventsVerified = facebook.pixelEventsVerified
    ? facebook.pixelEventsVerified[facebook.selectedAdAccount?.id]
    : null
  const isConnected =
    facebook.selectedAdAccount?.id && facebook.selectedBusiness?.id
  const isInIndustrySelectionRoute = [
    '/industry-selection',
    '/industry-selection/apparel',
  ].includes(location.pathname)
  const isNotSubscribed =
    !user?.subscription || user?.subscription.status !== 'active'

  const admin = useSelector(state => state.admin)
  const { sendRequest } = useHttpClient()

  const isAdHealthCheckupPage = location.pathname.includes('/ad-health-checkup')

  const dispatch = useDispatch()

  const { openModal } = useModal()

  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }
    window.addEventListener('resize', handleResize)

    document.addEventListener('mousedown', handleClickOutsideMobileMenu)

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMobileMenu)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const logout = () => {
    dispatch(authActions.logout({}))
    document.body.classList.remove('adminsr')

    toggleMobileMenu() // Menu kapanmasını sağlamak için toggleMenu çağrılıyor
  }

  const handleClickOutsideMobileMenu = event => {
    if (
      !event.target.closest('.right-menu') &&
      !event.target.closest('.hamburger-menu')
    ) {
      setShowMobileMenu(false)
    }
  }

  const goToUserDetail = () => {
    navigate(`/admin-panel/user-detail/${userInView.id}`)
  }
  const goToAdHealthCheckup = async () => {
    startViewingAsUser({ user: userInView, pageRoute: `/ad-health-checkup` })
  }

  const clearTempUser = () => {
    dispatch(adminActions.setUserInView(null))
  }

  const startTour = () => {
    childRef.current.handleClickStart()
  }

  const openPixelEventsVerificationModal = () => {
    const modalConfig = {
      title: 'Verify Enhencer Events',
      imageUrl:
        'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
      modalComponent: <PixelEventsVerificationRequired />,
      text: '',
      actions: [
        {
          text: 'Do it later',
          callback() { },
        },
        {
          text: 'Verified',
          callback() {
            const verifyEvents = async () => {
              const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/pixelEventsVerified`,
                'POST',
                JSON.stringify({
                  userId: user.id,
                  userAdAccountId: facebook.selectedAdAccount.id,
                }),
                {
                  authorization: 'Bearer ' + auth.token,
                  'Content-Type': 'application/json',
                },
              )
              if (responseData) {
                dispatch(
                  facebookActions.updateInfo({
                    pixelEventsVerified: responseData.pixelEventsVerified,
                  }),
                )
              } else {
                console.log('error occurred')
              }
            }
            verifyEvents()
          },
        },
      ],
    }
    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
      closeOnClickOutside: true,
    })
  }

  const openGoogleAdsModal = () => {
    openModal({
      type: Modals.GOOGLE_ADS_SUPPORT,
      closeOnClickOutside: true,
      performanceManager: user.crmDetails?.performanceManager,
    })
  }

  const openFreeCheckupPopup = () => {
    openModal({
      type: Modals.AD_HEALTH_CHECKUP_MODAL,
      closeOnClickOutside: true,
    })
  }

  const openDemoMeetingPopup = () => {
    openModal({
      type: Modals.CHECKUP_MEETING,
      closeOnClickOutside: true,
      performanceManager: user.crmDetails?.performanceManager,
      scheduledMeeting: user.crmDetails.adHealthCheckupMeeting,
    })
  }

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  return auth.isLoggedIn &&
    !isAdHealthCheckupPage &&
    (user.completedRegistration || user.isSpecialUser) ? (
    <div className="topbar-container">
      <Intro ref={childRef} />
      <div className="topbar">
        <div className="logo-cont">
          <Link
            to={
              user.isSpecialUser
                ? '/admin-panel'
                : '/ai-ads'
            }
            onClick={clearTempUser}
          >
            <img alt="enhencerlogo" src={enhencerLogo} />
            {user.isSpecialUser && <span>Admin</span>}
            {!user.isSpecialUser && <span>AI Ads</span>}
          </Link>
        </div>

        {user.isSpecialUser && admin.userInView?.displayName && (
          <div>
            <div className="row customer-title">
              {(location.pathname.includes('ads-manager') ||
                location.pathname.includes('connect-overview') ||
                location.pathname.includes('ai-ads') ||
                location.pathname.includes('config')) && (
                  <FaChevronLeft
                    className="icon back-arrow"
                    title="User detail page"
                    onClick={goToUserDetail}
                  />
                )}
              <h3 style={{ color: 'white', fontWeight: '500' }}>
                {admin.userInView?.displayName}
              </h3>
              {location.pathname.includes('ai-ads') && (
                <BarButton to={`/config${location.search}`} type={'config'} />
              )}
              {location.pathname.includes('config') && (
                <BarButton to={`/ai-ads${location.search}`} type={'ai-ads'} />
              )}
              {location.pathname.includes('ai-ads') &&
                userInView?.adHealthCheckup && (
                  <BarButton
                    onClick={goToAdHealthCheckup}
                    type={'adHealthCheckup'}
                  >
                    Ad Health Checkup
                  </BarButton>
                )}
            </div>
          </div>
        )}

        {!user.isSpecialUser && (
          <div className="row customer-title">
            <h3 style={{ color: 'white', fontWeight: '500' }}>
              {user.shopify?.name ?? user.name}
            </h3>
          </div>
        )}

        {window.location.pathname === '/admin-panel' ||
          window.location.pathname === '/admin-panel/sign-ups' ||
          window.location.pathname === '/admin-panel/demo-meetings' ||
          window.location.pathname === '/admin-panel/alarms' ? (
          <AdminCustomerTabs />
        ) : null}

        {!user.isSpecialUser && isInIndustrySelectionRoute ? (
          <div className="right-menu">
            <div
              onClick={logout}
              className="bar-button"
              style={{ maxWidth: 'unset', padding: '0 1em' }}
            >
              Log Out
            </div>
          </div>
        ) : !user.isSpecialUser && isNotSubscribed ? (
          <div className="right-menu">
            {showMobileMenu ? (
              <FaTimes className="hamburger-menu" onClick={toggleMobileMenu} />
            ) : (
              <FaBars className="hamburger-menu" onClick={toggleMobileMenu} />
            )}
            <div className={`menu-items ${showMobileMenu ? 'show' : ''}`}>
              {window.location.pathname === '/account/subscription' && (
                <BarButton
                  onClick={() => {
                    openDemoMeetingPopup()
                    toggleMobileMenu()
                  }}
                  type={'bookADemoButton'}
                  title="Book a Demo"
                  expandable={false}
                />
              )}
              <BarButton
                to="start"
                type="start"
                title="Home"
                expandable={!isMobile}
                onClick={toggleMobileMenu}
              />

              <ProfileMenu
                isSpecialUser={user.isSpecialUser}
                logout={logout}
                onToggleMenuCalled={() => {
                  toggleMobileMenu()
                }}
              />
            </div>
          </div>
        ) : (
          <div className="right-menu ">
            {showMobileMenu ? (
              <FaTimes className="hamburger-menu" onClick={toggleMobileMenu} />
            ) : (
              <FaBars className="hamburger-menu" onClick={toggleMobileMenu} />
            )}
            <div className={`menu-items ${showMobileMenu ? 'show' : ''}`}>
              {user.isSpecialUser && (
                <React.Fragment>
                  <PrivateComponent
                    permissions={['viewCustomersPage']}
                    children={
                      <BarButton
                        to="admin-panel"
                        type="admin-panel"
                        title="Admin Panel"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                      />
                    }
                  />
                  <PrivateComponent
                    permissions={['viewTablePage']}
                    children={
                      <BarButton
                        to="admin-panel/table"
                        type="admin-panel/table"
                        title="Table"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                      />
                    }
                  />
                  <PrivateComponent
                    permissions={['viewAnTablePage']}
                    children={
                      <BarButton
                        to="admin-panel/antable"
                        type="admin-panel/antable"
                        title="Table"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                      />
                    }
                  />
                  <PrivateComponent
                    permissions={['viewSupportTicketsPage']}
                    children={
                      <BarButton
                        to={
                          hasUnreadMessages
                            ? 'admin-panel/support-tickets?unreadMessages=1'
                            : 'admin-panel/support-tickets'
                        }
                        type="admin-panel/support-tickets"
                        title="Support Tickets"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                        badge={hasUnreadMessages}
                      />
                    }
                  />
                  <PrivateComponent
                    permissions={['viewCRMReportsPage']}
                    children={
                      <BarButton
                        to="crm-report"
                        type="crm-report"
                        title="CRM Reports"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                      />
                    }
                  />
                  <PrivateComponent
                    permissions={['viewTeamReportsPage']}
                    children={
                      <BarButton
                        to="team-report"
                        type="team-report"
                        title="Team Report"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                      />
                    }
                  />
                  <PrivateComponent
                    permissions={['viewTeamPage']}
                    children={
                      <BarButton
                        to="admin-panel/team"
                        type="admin-panel/team"
                        title="Team"
                        onClick={() => {
                          clearTempUser()
                          toggleMobileMenu()
                        }}
                        expandable={!isMobile}
                      />
                    }
                  />
                </React.Fragment>
              )}

              {!user.isSpecialUser && (
                <React.Fragment>
                  {isNotSubscribed && (
                    <BarButton
                      onClick={() => {
                        openDemoMeetingPopup()
                        toggleMobileMenu()
                      }}
                      type={'bookADemoButton'}
                      title="Book a Demo"
                      expandable={false}
                    />
                  )}

                  <BarButton
                    onClick={() => {
                      openFreeCheckupPopup()
                      toggleMobileMenu()
                    }}
                    type={'adHealthCheckup'}
                    title="Ad Health Checkup"
                    expandable={!isMobile}
                  />

                  {(!user.subscription ||
                    user.subscription.status !== 'active') && (
                      <BarButton
                        to="checkup/config?editConnections=1"
                        type={'config'}
                        title="Connect"
                        onClick={toggleMobileMenu}
                        expandable={!isMobile}
                      />
                    )}

                  {isConnected &&
                    !pixelEventsVerified &&
                    shouldShowVerifyEventsButton && (
                      <BarButton
                        type="verifyEvents"
                        title="Verify Events"
                        onClick={openPixelEventsVerificationModal}
                      />
                    )}

                  <BarButton
                    to='ai-ads'
                    type='ai-ads-button'
                    title='AI Ads Home'
                    expandable={!isMobile}
                    onClick={toggleMobileMenu}
                  />

                  {user.subscription &&
                    user.subscription.status === 'active' && (
                      <BarButton
                        to={'config'}
                        type={'config'}
                        title="Connect"
                        onClick={toggleMobileMenu}
                        expandable={!isMobile}
                        badge={
                          facebook.connectionPageIsReady &&
                            !user.connectionsCompleted
                            ? '!'
                            : null
                        }
                      />
                    )}


                  <BarButton
                    onClick={() => {
                      openGoogleAdsModal()
                      toggleMobileMenu()
                    }}
                    type={'googleAds'}
                    title="Google Ads"
                    expandable={!isMobile}
                  />

                  {user.subscription &&
                    user.crmDetails?.performanceManager &&
                    <AccountManagerCard
                      expandable={!isMobile}
                      person={user.crmDetails?.performanceManager}
                    />
                  }
                </React.Fragment>
              )}

              <ProfileMenu
                isSpecialUser={user.isSpecialUser}
                logout={logout}
                onToggleMenuCalled={() => {
                  toggleMobileMenu()
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : null
}

const openWebsite = () => {
  window.open('https://enhencer.com', '_blank')
}

const createLetterImage = name => {
  let letter = name.substring(0, 1)
  return <div className="name-first-letter">{letter}</div>
}

export default TopBar
