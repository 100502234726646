import React, { useState } from 'react'
import { FaCheck, FaUser, FaUpload } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import ImageUpload from '../../../shared/components/FormElements/ImageUpload'
import Input from '../../../shared/components/FormElements/Input'
import { useForm } from '../../../shared/hooks/form-hook'
import { useHttpClient } from '../../../shared/hooks/http-hook'
import { authActions } from '../../../store/auth'

import './Customization.css'
import Palette from './Palette'

const Customization = () => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const [formState, inputHandler] = useForm(
    {
      image: {
        value: auth.user?.image || null,
        isValid: true,
      },
      companyName: {
        value: auth.user?.companyName || '',
        isValid: true,
      },
    },
    true,
  )

  const [logoToggle, setLogoToggle] = useState(true)
  const [nameToggle, setNameToggle] = useState(true)

  const saveCustomizationHandler = async () => {
    const { image, companyName } = formState.inputs

    try {
      const formData = new FormData()
      formData.append('image', image.value)
      formData.append('companyName', companyName.value)

      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.user.id}`,
        'PATCH',
        formData,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      )

      dispatch(
        authActions.updateUserObj({
          ...auth.user,
          image: result.user.image,
          companyName: result.user.companyName,
        }),
      )
      toast.success('Customization saved')
    } catch (err) {
      toast.error(err?.message || 'Could not save customization')
    }
  }

  return (
    <div className="customization-main">
      <div className="customization">
        <div className="company-info">
          <div className="customization-image-area">
            <div className="logo-header">
              <h5 className="title">Company Logo:</h5>
              <div className="customization-toggle">
                <input
                  type="checkbox"
                  id="logoSwitch"
                  checked={logoToggle}
                  onChange={() => setLogoToggle(!logoToggle)}
                />
                <label htmlFor="logoSwitch">Toggle</label>
              </div>
            </div>

            {logoToggle && (
              <div className="row">
                <label htmlFor="picInput" className="image-upload-area">
                  <div className="placeholder">
                    <FaUser className="icon" />
                  </div>
                  <ImageUpload
                    id="image"
                    onInput={inputHandler}
                    defaultImageURL={formState.inputs.image.value}
                  />
                </label>
                <label className="pic-input" htmlFor="picInput">
                  <div>
                    <div className="change-customization-photo">
                      <FaUpload className="icon" />
                      Upload new
                    </div>
                  </div>
                </label>
              </div>
            )}
          </div>

          <div className="input-group">
            <div className="name-header">
              <h5 className="title">Company Name:</h5>
              <div className="customization-toggle">
                <input
                  type="checkbox"
                  id="nameSwitch"
                  checked={nameToggle}
                  onChange={() => setNameToggle(!nameToggle)}
                />
                <label htmlFor="nameSwitch">Toggle</label>
              </div>
            </div>
            {nameToggle && (
              <div className="row">
                <Input
                  id="companyName"
                  element="input"
                  type="text"
                  placeholder="Enter company name"
                  validators={[]}
                  onInput={inputHandler}
                  initialValue={formState.inputs.companyName.value}
                  initialValid={true}
                />
              </div>
            )}
          </div>
        </div>
        <div className="colors">
          <h5 className="title">Select from palette:</h5>
          <Palette></Palette>
        </div>
      </div>
      <button
        className="customization-save-button"
        onClick={saveCustomizationHandler}
      >
        Save
        <FaCheck className="save-check"></FaCheck>
      </button>
    </div>
  )
}

export default Customization
