export default function (date, onlyDate) {
    if (date && date != "Invalid Date") {
        date = new Date(date);
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const month = monthNames[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        let hour = date.getHours();
        hour = hour < 10 ? "0" + hour : hour;
        let minute = date.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        if (onlyDate) {
            return month + '\n' + day + ', ' + year;
        } else {
            return month + '\n' + day + ', ' + year + ', ' + hour + ":" + minute;
        }
    } else {
        return null;
    }
}

const getShortElapsedTimeString = (date) => {
    if (typeof date !== "number") {
        date = date.valueOf()
    }

    const now = new Date();
    const secondsPast = Math.floor((now - date) / 1000);

    if (secondsPast < 60) {
        return `${secondsPast}s`; // Seconds
    }
    const minutesPast = Math.floor(secondsPast / 60);
    if (minutesPast < 60) {
        return `${minutesPast}m`; // Minutes
    }
    const hoursPast = Math.floor(minutesPast / 60);
    if (hoursPast < 24) {
        return `${hoursPast}h`; // Hours
    }
    const daysPast = Math.floor(hoursPast / 24);
    if (daysPast < 7) {
        return `${daysPast}d`; // Days
    }
    const weeksPast = Math.floor(daysPast / 7);
    if (weeksPast < 4) {
        return `${weeksPast}w`; // Weeks
    }
    const monthsPast = Math.floor(daysPast / 30);
    if (monthsPast < 12) {
        return `${monthsPast}mo`; // Months
    }
    const yearsPast = Math.floor(daysPast / 365);
    return `${yearsPast}y`; // Years


}

const getShortDateString = function (d) {
    let date;
    if (!d) return null

    if (typeof d == "number" || typeof d == "object" || typeof d === "string") {
        date = new Date(d)
    } else {
        date = d
    }


    let dayOfTheWeek = ""
    let month = ""
    switch (date.getDay()) {
        case 0: dayOfTheWeek = "Sun"; break;
        case 1: dayOfTheWeek = "Mon"; break;
        case 2: dayOfTheWeek = "Tue"; break;
        case 3: dayOfTheWeek = "Wed"; break;
        case 4: dayOfTheWeek = "Thu"; break;
        case 5: dayOfTheWeek = "Fri"; break;
        case 6: dayOfTheWeek = "Sat"; break;
    }

    switch (date.getMonth()) {
        case 0: month = "Jan"; break;
        case 1: month = "Feb"; break;
        case 2: month = "Mar"; break;
        case 3: month = "Apr"; break;
        case 4: month = "May"; break;
        case 5: month = "Jun"; break;
        case 6: month = "Jul"; break;
        case 7: month = "Aug"; break;
        case 8: month = "Sep"; break;
        case 9: month = "Oct"; break;
        case 10: month = "Nov"; break;
        case 11: month = "Dec"; break;
    }
    return `${dayOfTheWeek}, ${date.getDate()} ${month} ${date.getFullYear()}`
}

const getDaysPassedSince = function (dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate - inputDate;
    const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysPassed;
}

export { getShortDateString, getShortElapsedTimeString, getDaysPassedSince }