import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaTimes } from 'react-icons/fa'

import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/FormElements/Button'
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useAuth } from '../../shared/hooks/auth-hook'
import { authActions } from '../../store/auth'
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png'
import LoginOptions from '../components/LoginOptions'

import './Auth.css'
import { useUser } from '../../shared/hooks/user-hook'
import { generalActions } from '../../store/general'

const Login = props => {
  const dispatch = useDispatch()
  const { login } = useAuth()
  const [busy, setBusy] = useState(false)
  const location = useLocation()
  const { getProfile } = useUser()
  const [storedAccounts, setStoredAccounts] = useState(
    JSON.parse(localStorage.getItem('userData') || '[]'),
  )
  const today = new Date()
  const loggedOutFromAccountNotification = useSelector(state => state.general.loggedOutFromAccountNotification)

  useEffect(() => {
    if(storedAccounts.length < 1) {
        handleAppClass(true)
    }

    if (location.search !== '') {
      const query = new URLSearchParams(location.search)
      const token = query.get('token')
      const projectId = query.get('projectId')
      const existingEmail = query.get('email')
      fetchUserAndLogin({ projectId, token, email: existingEmail })
    }
    return () => {
      handleAppClass(false)
    }
  }, [])

  const fetchUserAndLogin = async ({ projectId, token, email }) => {
    try {

      if (email && loggedOutFromAccountNotification?.email &&
        email !== loggedOutFromAccountNotification?.email) {
        toast.info(`You have been logged out of ${loggedOutFromAccountNotification?.email} account.`)
      }
      dispatch(generalActions.showLoggedOutFromAccountNotification(null))

      let updatedUser = await getProfile({ token, shouldSetRedux: false })

      dispatch(authActions.login({ token, projectId, user: updatedUser }))
    } catch (err) {
      toast.error(err?.message || 'Something went wrong')
    }
  }

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: '',
        isValid: false,
      },
      password: {
        value: '',
        isValid: false,
      },
    },
    false,
  )

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-noscroll')
      : document.getElementById('app')?.classList.remove('full-screen-noscroll')
  }

  const submit = async event => {
    event.preventDefault()

    const { email, password } = formState.inputs

    setBusy(true)

    try {
      await login({
        email: email.value,
        password: password.value,
      })
    } catch (err) {
      setBusy(false)
      toast.error(err?.message || 'Something went wrong')
    }
  }
  const logoutFromStoredAccount = token => {
    let updatedUserData = JSON.parse(localStorage.getItem('userData') || '[]')
    updatedUserData = updatedUserData.filter(user => user.token !== token)
    localStorage.setItem('userData', JSON.stringify(updatedUserData))
    setStoredAccounts(updatedUserData)
  }

  return (
    <React.Fragment>
      <div className="full-container">
        <div className="login-main-cont">
          {/* <div className="login row"> */}
          {storedAccounts.length > 1 &&
            storedAccounts.filter(
              account =>
                new Date(account.tokenExpirationDate) > today.getTime(),
            ).length > 0 && (
              <div className="stored-accounts">
                <h2>Recent Logins</h2>
                <div className="accounts-grid">
                  {storedAccounts
                    .filter(
                      account =>
                        new Date(account.tokenExpirationDate) > today.getTime(),
                    )
                    .map((account, index) => (
                      <div className="stored-account" key={account.email}>
                        <div className="remove-user-row">
                          <FaTimes
                            className="icon"
                            onClick={() =>
                              logoutFromStoredAccount(account.token)
                            }
                          />
                        </div>
                        <div
                          className="account-name"
                          onClick={() =>
                            fetchUserAndLogin({
                              projectId: account.projectId,
                              token: account.token
                            })
                          }
                        >
                          {' '}
                          {account.email.substring(0, 2).toUpperCase()}
                        </div>
                        <div className="email-bar">
                          {account.email.split('@')[0]}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}

          <div className="login-cont">
            <div style={{ display: 'flex' }}>
              <img className="logo" src={enhencerLogo} />
            </div>
            <h1>Log In</h1>
            <form onSubmit={submit}>
              <Input
                element="input"
                id="email"
                type="text"
                label="E-Mail"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address"
                onInput={inputHandler}
              />

              <Input
                element="input"
                id="password"
                type="password"
                label="Password"
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText="Invalid password"
                onInput={inputHandler}
              />

              <div className="button-cont">
                <Link className="link-button" to="/forgot-password">
                  Forgot password?
                </Link>
              </div>

              <div className="cont">
                <Button loading={busy} type="submit" className="action-button">
                  Log In
                </Button>
              </div>
            </form>
            <LoginOptions />

            <div className="no-account-row">
              Don't have an account?
              <Link className="link-button" to="/register">
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Login
