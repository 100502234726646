import React from 'react'

import './AIAdsPageLayout.css'

function AIAdsPageLayout({ children }) {
  return <div className="ai-ads">{children}</div>
}

function Content({ children }) {
  return <div className="ai-ads-content-wrapper">{children}</div>
}

function Sidebar({ children }) {
  return (
    <div className="ai-ads-sidebar-wrapper">
      <div className="sidebar-items">{children}</div>
    </div>
  )
}

AIAdsPageLayout.Content = Content
AIAdsPageLayout.Sidebar = Sidebar

export default AIAdsPageLayout
