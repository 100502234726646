import React from 'react'
import './Checkbox.css'

const Checkbox = props => {
  const {
    reversePosition,
    name,
    value,
    label,
    checked,
    onChange,
    color,
    fontWeight,
  } = props

  const labelStyle = {
    color: color || 'var(--text-gray)',
    fontWeight: fontWeight || 'normal',
  }

  return (
    <div>
      <div className={`checkbox ${reversePosition ? 'right' : ''}`}>
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span style={labelStyle}>{label}</span>
      </div>
    </div>
  )
}

export default Checkbox
