import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaArrowLeft, FaLock, FaPaperPlane, FaPlus } from 'react-icons/fa'
import { toast } from 'react-toastify'
import Zoom from 'react-medium-image-zoom'
import moment from 'moment'

import ProfilePageLayout from '../components/ProfilePageLayout'
import Button from '../../shared/components/FormElements/Button'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { supportTicketsActions } from '../../store/support-tickets'

import 'react-medium-image-zoom/dist/styles.css'
import './TicketDetails.css'

const TicketDetails = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const performanceManagerProfile = useSelector(
    state => state.auth.user?.crmDetails?.performanceManagerProfile,
  )
  const { tickets } = useSelector(state => state.supportTickets)
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const params = useParams()
  const messagesEndRef = useRef(null)
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [loadingMessages, setLoadingMessages] = useState(true)
  const [ticket, setTicket] = useState(null)
  const [isMessageInputOpen, setIsMessageInputOpen] = useState(false)
  const [isMessageSending, setIsMessageSending] = useState(false)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' })
  }

  const addMessage = async () => {
    const body = message.trim()
    if (!body) return

    try {
      setIsMessageSending(true)

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/addSupportTicketMessage/${params.ticketId}`,
        'POST',
        JSON.stringify({ body }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        supportTicketsActions.findTicketByIdAndUpdate({
          id: params.ticketId,
          data: {
            ...ticket,
            messages: response.data.messages,
          },
        }),
      )

      const messagesOfTicket = response.data.messages
      const addedMessage = messagesOfTicket[messagesOfTicket.length - 1]

      setMessages([...messages, addedMessage])
      setIsMessageInputOpen(false)
      setMessage('')

      toast.success('Message sent!')
    } catch (err) {
      toast.error(err?.message || 'Something went wrong')
    } finally {
      setIsMessageSending(false)
    }
  }

  const getMessages = async () => {
    try {
      setLoadingMessages(true)

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/getSupportTicketMessages/${params.ticketId}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      )

      setMessages(response.data)

      const ticket = tickets.find(t => t._id === params.ticketId)

      dispatch(
        supportTicketsActions.findTicketByIdAndUpdate({
          id: params.ticketId,
          data: { ...ticket, messages: response.data },
        }),
      )
    } catch (err) {
      toast.error(err?.message || 'Something went wrong')
    } finally {
      setLoadingMessages(false)
    }
  }

  useEffect(() => {
    if (!tickets.length) {
      return
    }

    const ticketInView = tickets.find(t => t._id === params.ticketId)
    setTicket(ticketInView)
  }, [tickets.length, params.ticketId])

  useEffect(() => {
    if (loadingMessages) return

    const hasUnreadMessages = tickets.some(ticket =>
      ticket?.messages.some(
        message => message.receiver === auth.user._id && !message.read,
      ),
    )

    dispatch(supportTicketsActions.setHasUnreadMessages(hasUnreadMessages))
  }, [loadingMessages])

  const handleMessageInputChange = e => {
    const input = e.target.value
    setMessage(input)
  }

  const createUserAvatar = user => {
    if (!user.image) {
      const letter = user.email[0]
      return <div className="name-first-letter">{letter}</div>
    }

    return <img src={user.image} alt="Avatar" />
  }

  const createPerformanceManagerAvatar = performanceManagerProfile => {
    return <img src={performanceManagerProfile.image} alt="Avatar" />
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    if (!tickets.length) {
      return
    }

    getMessages()
  }, [tickets.length])

  if (!ticket) {
    return (
      <ProfilePageLayout>
        <ProfilePageLayout.Header>
          <div className="ticket-details-header">
            <div className="title">Failed to load ticket</div>
            <div onClick={() => navigate(-1)} className="go-back">
              <FaArrowLeft className="icon" />
              <span className="text">Back</span>
            </div>
          </div>
        </ProfilePageLayout.Header>
      </ProfilePageLayout>
    )
  }

  return (
    <ProfilePageLayout>
      <ProfilePageLayout.Header>
        <div className="ticket-details-header">
          <div className="title">
            {ticket?.bugType === 'Other'
              ? ticket?.otherBugTypeDescription
              : ticket?.bugType}
          </div>
          <div onClick={() => navigate(-1)} className="go-back">
            <FaArrowLeft className="icon" />
            <span className="text">Back</span>
          </div>
        </div>
      </ProfilePageLayout.Header>
      <ProfilePageLayout.Content>
        <div className="ticket-details-content">
          <div className="chatbox">
            <div className="messages">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`message ${
                    message.sender === auth.user._id ? 'sent' : 'received'
                  }`}
                >
                  <div className="avatar">
                    {message.sender === auth.user._id
                      ? createUserAvatar(auth.user)
                      : createPerformanceManagerAvatar(
                          performanceManagerProfile,
                        )}
                  </div>
                  <div className="content">
                    <div className="text">{message.body}</div>
                    <div className="time">
                      {moment(message.createdAt).format('hh:mm A')}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            {isMessageInputOpen ? (
              <div className="input">
                <textarea
                  value={message}
                  onChange={handleMessageInputChange}
                  onKeyDown={e =>
                    e.key === 'Escape' && setIsMessageInputOpen(false)
                  }
                  placeholder="Type a message"
                />
                <Button
                  disabled={!message.trim() || isMessageSending}
                  onClick={addMessage}
                >
                  <FaPaperPlane className="icon" />
                  Send
                </Button>
              </div>
            ) : (
              <div className="add-message">
                <Button
                  disabled={
                    ticket.displayStatus === 'completed' ||
                    ticket.displayStatus === 'closed'
                  }
                  onClick={() => setIsMessageInputOpen(true)}
                >
                  {ticket.displayStatus === 'completed' ||
                  ticket.displayStatus === 'closed' ? (
                    <>
                      <FaLock className="icon" />
                      Ticket Closed
                    </>
                  ) : (
                    <>
                      <FaPlus className="icon" />
                      Add Message
                    </>
                  )}
                </Button>
              </div>
            )}
          </div>
          <div className="information">
            <div className="details">
              <h2 className="header">Ticket Details</h2>
              <div className="section grouped">
                <div className="group">
                  <div className="title">Ticket ID</div>
                  <div className="value">{ticket._id}</div>
                </div>
                <div className="group">
                  <div className="title">Status</div>
                  <div
                    className={`value status ${ticket.displayStatus || 'pending'}`}
                  >
                    {ticket.displayStatus
                      ? ticket.displayStatus[0].toUpperCase() +
                        ticket.displayStatus.slice(1)
                      : 'Pending'}
                  </div>
                </div>
                <div className="group">
                  <div className="title">Date</div>
                  <div className="value">
                    {moment(ticket.createdAt).format('ll')}
                  </div>
                </div>
              </div>
              {ticket.description && (
                <div className="section">
                  <div className="title">Description</div>
                  <div className="value description">{ticket.description}</div>
                </div>
              )}
              {ticket.image && (
                <div className="section">
                  <div className="title">Attached Screenshot</div>
                  <Zoom>
                    <div className="value">
                      <img
                        className="screenshot"
                        src={ticket.image}
                        alt="Screenshot"
                      />
                    </div>
                  </Zoom>
                </div>
              )}
            </div>
            <div className="details">
              <h2 className="header">Your Marketing Expert</h2>
              <div className="section your-marketing-expert">
                <div className="avatar">
                  <img src={performanceManagerProfile.image} alt="Avatar" />
                </div>
                <div className="texts">
                  <span className="name">{performanceManagerProfile.name}</span>
                  <span className="email">
                    {performanceManagerProfile.email}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

export default TicketDetails
