import React from 'react'
import { useSelector } from 'react-redux'

const PrivateComponent = ({ children, permissions }) => {
  const userPermissions = useSelector(
    state => state.auth.user.accessDetails?.permissions || [],
  )

  if (
    permissions &&
    userPermissions.some(permission => permissions.includes(permission))
  ) {

    return children
  } 
  else {
    return null
  }
}

export default PrivateComponent 
