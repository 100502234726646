import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'

import instagramIcon from '../../assets/images/icons/instagram-icon-sm.png'
import metaIcon from '../../assets/images/icons/meta-icon-sm.png'
import { useFacebook } from '../../hooks/facebook-hook'
import { useModal } from '../../hooks/modal-hook'
import helper from '../../util/helper'
import Button from '../FormElements/Button'

import './FacebookReLogin.css'
import { FacebookLogin } from 'react-facebook-login'
import { FaFacebookF } from 'react-icons/fa'

const FacebookReLogin = ({ data }) => {
  const { enhCampaign, callback } = data
  const facebook = useSelector(state => state.facebook)
  const { closeModal } = useModal()
  

  


  return (
    <div className="facebook-session-expired">
        Your session expired, please login to Facebook.
        {/* <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={loginCallback}
              scope="email,public_profile,pages_show_list,pages_read_engagement,ads_read,ads_management,business_management,catalog_management"
              fields="name,email,picture"
              render={renderProps => (
                <Button className="fb-login-button" onClick={renderProps.onClick} disabled={!agreedToPrivacyPolicy} >
                  <FaFacebookF className="icon" />
                  Log in to your account
                </Button>
              )}
            /> */}
    </div>
  )
}

export default FacebookReLogin
