import React, { useRef, useEffect } from "react";

const Canvas = (props) => {
  const canvasRef = useRef(null);

  const start = props.start;
  const end = props.end;

  function resizeCanvasToDisplaySize(canvas) {
    const { width, height } = canvas.getBoundingClientRect();

    if (canvas.width !== width || canvas.height !== height) {
      canvas.width = width;
      canvas.height = height;
      // here you can return some usefull information like delta width and delta height instead of just true
      // this information can be used in the next redraw...
    }

    return { width: width, height: height };
  }

  const draw = (ctx) => {
    let { width, height } = resizeCanvasToDisplaySize(ctx.canvas);

    ctx.moveTo(0, (1 - start / 100) * height);
    ctx.lineTo(width, (1 - end / 100) * height);
    ctx.lineTo(width, height);
    ctx.lineTo(0, height);
    ctx.lineTo(0, (1 - start / 100) * height);
    ctx.fillStyle = "#2782a2";
    ctx.strokeStyle = "#2782a2";
    ctx.stroke();
    ctx.fill();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    //Our draw come here
    draw(context);
  }, [draw]);

  return <canvas ref={canvasRef} {...props} />;
};

export default Canvas;
