import React from 'react'
import { useModal } from '../../hooks/modal-hook'
import './GenericModal.css'
import Button from '../FormElements/Button'

const GenericModal = props => {
  const { closeModal, modalData } = useModal()
  const data = props.data.data

  const buttonClicked = action => {
    let shouldClose = action.shouldClose ? action.shouldClose : true
    if (action.callback) {
      action.callback()
    }

    if (shouldClose) {
      closeModal()
    }
  }

  return (
    <div className="generic-modal">
      <div className="header"></div>
      <div className="body">
        {data.imageUrl ? <img className="image-icon" src={data.imageUrl} alt="Modal Image" /> : null}
        {data.imageComponent ? data.imageComponent : null}
        <div>
          <h2 className="title">{data.title}</h2>
          <p>{data.text}</p>
        </div>
      </div>
      <div className="middle-component">
        {data.modalComponent ? data.modalComponent : null}{' '}
      </div>
      <div className="footer">
        {data.actions &&
          data.actions.map((action, index) => (
            <Button
              className={`action-button ${action.key}`}
              key={index}
              onClick={() => {
                buttonClicked(action)
              }}
            >
              {action.text}
            </Button>
          ))}
      </div>
    </div>
  )
}

export default GenericModal
