import React, { useState, useEffect } from "react";
//import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../../shared/components/FormElements/Button";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {  toast } from 'react-toastify';

//import numberFormatter from "../../shared/util/numberFormatter";
import dateFormatter from "../../shared/util/dateFormatter";

import { FaCalendarAlt, FaCheck, FaTimes } from "react-icons/fa";

import "./CardAdminModelSettings.css";
import Switch from "../../shared/components/UIElements/Switch";

const CardAdminModelSettings = ({ userId, job }) => {
  const [automaticManualSwitch, setAutomaticManualSwitch] =
    useState("automatic");

  const [project, setProject] = useState("");

  const [automaticDates, setAutomaticDates] = useState();
  const [manualDates, setManualDates] = useState();

  const [automaticSampleLimit, setAutomaticSampleLimit] = useState();
  const [manualSampleLimit, setManualSampleLimit] = useState();

  const [automaticThresholds, setAutomaticThresholds] = useState();
  const [manualThresholds, setManualThresholds] = useState();

  const [automaticFeatureList, setAutomaticFeatureList] = useState();
  const [manualFeatureList, setManualFeatureList] = useState();

  const [automaticTreeSettings, setAutomaticTreeSettings] = useState();
  const [manualTreeSettings, setManualTreeSettings] = useState();

  const [schedule, setSchedule] = useState();

  let projectId;

  const authToken = useSelector((state) => state.auth.token);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const init = async () => {
      if (userId) {
        try {
          
          let scheduleDayCount;
          if (!job || job?.message === "no job") {
          } else {
            const datesResult = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/dev/getMinMaxDates`,
              "POST",
              JSON.stringify({
                userId: userId
              }),
              {
                Authorization: "Bearer " + authToken,
                "Content-Type": "application/json",
              }
            );
            let manualStartDate = new Date(datesResult.MINDATE);
            manualStartDate.setHours(0);
            manualStartDate.setMinutes(0);
            manualStartDate.setSeconds(0);
            const manualEndDate = new Date(datesResult.MAXDATE);
            setManualDates({
              startDate: manualStartDate,
              endDate: manualEndDate,
            });
            projectId = job.data?.projectId;
            const projectResult = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/dev/getProject/${projectId}`,
              "GET",
              null,
              {
                Authorization: "Bearer " + authToken,
              }
            );
            setProject(projectResult);
            setAutomaticDates({
              startDate: new Date(
                projectResult.connectInfo.dates.automatic.startDate
              ),
              endDate: new Date(
                projectResult.connectInfo.dates.automatic.endDate
              ),
            });
            setAutomaticSampleLimit(
              projectResult.connectInfo.sampleLimit.automatic
            );
            if (projectResult.connectInfo.sampleLimit.manual) {
              setManualSampleLimit(
                projectResult.connectInfo.sampleLimit.manual
              );
            } else {
              setManualSampleLimit(
                projectResult.connectInfo.sampleLimit.automatic
              );
            }
            setAutomaticThresholds(
              projectResult.connectInfo.thresholds.automatic
            );
            if (projectResult.connectInfo.thresholds.manual) {
              setManualThresholds(projectResult.connectInfo.thresholds.manual);
            } else {
              setManualThresholds(
                projectResult.connectInfo.thresholds.automatic
              );
            }

            setAutomaticFeatureList(
              projectResult.connectInfo.featureList.automatic
            );

            if (projectResult.connectInfo.featureList.manual) {
              setManualFeatureList(
                projectResult.connectInfo.featureList.manual
              );
            } else {
              setManualFeatureList(
                projectResult.connectInfo.featureList.automatic
              );
            }

            setAutomaticTreeSettings(
              projectResult.connectInfo.treeSettings.automatic
            );
            if (projectResult.connectInfo.treeSettings.manual) {
              setManualTreeSettings(
                projectResult.connectInfo.treeSettings.manual
              );
            } else {
              setManualTreeSettings(
                projectResult.connectInfo.treeSettings.automatic
              );
            }

            scheduleDayCount = parseInt(job.repeatInterval.split(" ")[0]);
            setSchedule({
              nextRunAt: job.nextRunAt,
              day: scheduleDayCount,
            });
          }
        } catch (err) {
          toast.error(err?.message || "Something went wrong")
        }
      }
    };
    init();
  }, [authToken, job]);

  const automaticManualSwitchChanged = (choice) => {
    setAutomaticManualSwitch(choice);
  };

  const setDates = (date) => {
    setManualDates({
      ...manualDates,
      ["startDate"]: new Date(date),
    });
  };

  const onSampleLimitChange = (e) => {
    setManualSampleLimit(parseInt(e.target.value));
  };

  const onThresholdsChange = (e) => {
    setManualThresholds({
      ...manualThresholds,
      [e.target.id]: parseInt(e.target.value),
    });
  };

  const onSelectFeature = (selection, index) => {
    let tempManualFeatureList = [...manualFeatureList];
    tempManualFeatureList[index] = {
      ...tempManualFeatureList[index],
      usageLabel: selection
    }
    setManualFeatureList(tempManualFeatureList);
  };

  const onTreeSettingsChange = (e) => {
    setManualTreeSettings({
      ...manualTreeSettings,
      [e.target.id]: parseInt(e.target.value),
    });
  };

  const applyThresholds = async (e) => {
    const data = {
      userId: userId,
      startDate: manualDates.startDate,
      endDate: manualDates.endDate,
      thresholds: manualThresholds,
    };
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/generateFeatureList`,
        "POST",
        JSON.stringify(data),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );
      setManualSampleLimit(response.sampleLimit);
      setManualFeatureList(response.featureList);
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };

  const save = async (e) => {
    let data = {
      "connectInfo.dates.manual": null,
      "connectInfo.featureList.manual": null,
      "connectInfo.sampleLimit.manual": null,
      "connectInfo.thresholds.manual": null,
      "connectInfo.treeSettings.manual": null,
    };

    if (automaticManualSwitch === "manual") {
      manualTreeSettings.minSplit = manualTreeSettings.minBucket * 2;
      data = {
        "connectInfo.dates.manual": manualDates,
        "connectInfo.featureList.manual": manualFeatureList,
        "connectInfo.sampleLimit.manual": manualSampleLimit,
        "connectInfo.thresholds.manual": manualThresholds,
        "connectInfo.treeSettings.manual": manualTreeSettings,
      };
    }
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/updateProjectByUserId/${userId}`,
        "PATCH",
        JSON.stringify(data),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }

    try {
      const startDate = automaticManualSwitch === 'automatic' ? automaticDates.startDate : manualDates.startDate;
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createJob`,
        "POST",
        JSON.stringify({
          userId: userId,
          startDate: startDate,
          type: "purchase_now",
          day: schedule.day,
          isJobUpdate: false
        }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );
      toast.success("Changes saved!");

    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };

  return (
    <React.Fragment>
      <div className="edit-user section">
        {isLoading && (
          <div className="center">
            <LoadingSpinner />
          </div>
        )}
        <div className="header-row">
          <h4>
            Edit Model
          </h4>
          <div className="switch-container">
            <Switch options={["automatic", "manual"]} value={automaticManualSwitch} onChange={setAutomaticManualSwitch} />
          </div>
          {automaticManualSwitch === "automatic" && automaticDates && (
            <div className="date-input-cont">
              <div style={{ fontSize: "0.9em" }}>Since</div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                disabled={true}
                className="datepicker"
                pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                selected={automaticDates.startDate}
              />
              <FaCalendarAlt className="icon" />
            </div>
          )}

          {automaticManualSwitch === "manual" && manualDates && (
            <div className="date-input-cont">
              <div style={{ fontSize: "0.9em" }}>Since</div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="datepicker"
                pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                selected={manualDates.startDate}
                onChange={(date) => setDates(date)}
              />
              <FaCalendarAlt className="icon" />
            </div>
          )}
        </div>
        <div className="content-box">


          {/* <div className="attribute-name">Sample limit</div> */}
          <div className="row threshold-row">
            {automaticManualSwitch === "automatic" && automaticSampleLimit && (
              <div>
                <div className="type-row">
                  <label>Sample Limit</label>
                  <div className="text-edit">
                    <input
                      disabled={true}
                      id="sampleLimit"
                      type="number"
                      value={automaticSampleLimit}
                      onChange={onSampleLimitChange}
                    />
                  </div>
                </div>
              </div>
            )}

            {automaticManualSwitch === "manual" && manualSampleLimit && (
              <div>
                <div className="type-row">
                  <label>Sample Limit</label>
                  <div className="text-edit">
                    <input
                      id="sampleLimit"
                      type="number"
                      value={manualSampleLimit}
                      onChange={onSampleLimitChange}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* <div className="attribute-name">Thresholds</div> */}
            {automaticManualSwitch === "automatic" && automaticThresholds && (
              <React.Fragment>
                <div className="type-row">
                  <label>Product Threshold</label>
                  <div className="text-edit">
                    <input
                      disabled={true}
                      id="productThreshold"
                      type="number"
                      value={automaticThresholds.productThreshold}
                      onChange={onThresholdsChange}
                    />
                  </div>
                </div>

                <div className="type-row">
                  <label>Listing Threshold</label>
                  <div className="text-edit">
                    <input
                      disabled={true}
                      id="listingThreshold"
                      type="number"
                      value={automaticThresholds.listingThreshold}
                      onChange={onThresholdsChange}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}

            {automaticManualSwitch === "manual" && manualThresholds && (
              <React.Fragment>
                <div className="type-row">
                  <label>Product Threshold</label>
                  <div className="text-edit">
                    <input
                      id="productThreshold"
                      type="number"
                      value={manualThresholds.productThreshold}
                      onChange={onThresholdsChange}
                    />
                  </div>
                </div>

                <div className="type-row">
                  <label>Listing Threshold</label>
                  <div className="text-edit">
                    <input
                      id="listingThreshold"
                      type="number"
                      value={manualThresholds.listingThreshold}
                      onChange={onThresholdsChange}
                    />
                  </div>
                </div>
                <Button className="apply" onClick={applyThresholds}>Apply Thresholds</Button>
              </React.Fragment>
            )}
          </div>

          <div className="row feature-tree-row">
            <div className="feature-list">
              <div className="attribute-name">Feature list</div>
              {automaticManualSwitch === "automatic" &&
                automaticFeatureList &&
                automaticFeatureList.map((feature, index) => (
                  <div key={feature.questionNo} className="ruleset-row">
                    {feature.usageLabel.includes("Not") ?
                      <FaTimes className="icon red" />
                      :
                      <FaCheck className="icon" />
                    }
                    {feature.name} <span className="table-name">{feature.tableName}</span>
                  </div>
                ))}

              {automaticManualSwitch === "manual" &&
                manualFeatureList &&
                manualFeatureList.map((feature, index) => (
                  <div key={feature.questionNo} className="ruleset-row">
                    <div className="switch-container">
                      <Switch options={["Use", "Not Use"]} value={feature.usageLabel} onChange={(selection) => { onSelectFeature(selection, index) }} />
                    </div>
                    {feature.name} <span className="table-name">{feature.tableName}</span>
                  </div>
                ))}
            </div>


            <div className="tree-settings">
              <div className="attribute-name">Tree settings</div>
              {automaticManualSwitch === "automatic" && automaticTreeSettings && (
                <div>
                  <div className="type-row">
                    <label>Confidence Level(%)</label>
                    <div className="text-edit">
                      <input
                        disabled={true}
                        id="confidenceInterval"
                        type="number"
                        value={automaticTreeSettings.confidenceInterval || 95}
                        onChange={onTreeSettingsChange}
                      />
                    </div>
                  </div>

                  <div className="type-row">
                    <label>Minimum Segment Size</label>
                    <div className="text-edit">
                      <input
                        disabled={true}
                        id="minBucket"
                        type="number"
                        value={automaticTreeSettings.minBucket || 50}
                        onChange={onTreeSettingsChange}
                      />
                    </div>
                  </div>
                  <div className="type-row">
                    <label>Maximum Depth</label>
                    <div className="text-edit">
                      <input
                        disabled={true}
                        id="maxDepth"
                        type="number"
                        value={automaticTreeSettings.maxDepth || 6}
                        onChange={onTreeSettingsChange}
                      />
                    </div>
                  </div>
                </div>
              )}

              {automaticManualSwitch === "manual" && manualTreeSettings && (
                <div>
                  <div className="type-row">
                    <label>Confidence Level(%)</label>
                    <div className="text-edit">
                      <input
                        id="confidenceInterval"
                        type="number"
                        value={manualTreeSettings.confidenceInterval || 95}
                        onChange={onTreeSettingsChange}
                      />
                    </div>
                  </div>

                  <div className="type-row">
                    <label>Minimum Segment Size</label>
                    <div className="text-edit">
                      <input
                        id="minBucket"
                        type="number"
                        value={manualTreeSettings.minBucket || 50}
                        onChange={onTreeSettingsChange}
                      />
                    </div>
                  </div>
                  <div className="type-row">
                    <label>Maximum Depth</label>
                    <div className="text-edit">
                      <input
                        id="maxDepth"
                        type="number"
                        value={manualTreeSettings.maxDepth || 6}
                        onChange={onTreeSettingsChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="schedule-settings">


              <div className="attribute-name">Schedule settings</div>
              {schedule && (
                <div>
                  <div className="type-row">
                    <label>Next run at: </label>
                    <div>{dateFormatter(schedule.nextRunAt)}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="action-row">
            {automaticManualSwitch === "manual" && <Button onClick={save}>Save</Button>}

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardAdminModelSettings;
