import React, { useEffect, useRef } from 'react'
import facebook from '../../shared/assets/images/ad-health-checkup/social-logos/facebook.png'
import google from '../../shared/assets/images/ad-health-checkup/social-logos/google.png'
import instagram from '../../shared/assets/images/ad-health-checkup/social-logos/instagram.png'
import tiktok from '../../shared/assets/images/ad-health-checkup/social-logos/tiktok.png'
import youtube from '../../shared/assets/images/ad-health-checkup/social-logos/youtube.png'


import './SocialLogosAnimated.css'

const SocialLogosAnimated = () => {

  const contRef = useRef()

  useEffect(() => {

    document.addEventListener('mousemove', handleMouseMove)

    return () => {

      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  const handleMouseMove = (e) => {

    contRef.current.querySelectorAll(".social-logos-animated .logo").forEach((logo) => {
      const position = logo.getAttribute("value");
      const x = (window.innerWidth - e.pageX * position) / 600;
      const y = (window.innerHeight - e.pageY * position) / 600;

      logo.style.transform = `translateX(${x}px) translateY(${y}px)`;
  });
  }

  return <div className='social-logos-animated' ref={contRef}>
    <div id="socialContainer">
      <img value={10} className='logo facebook' src={facebook} />
      <img value={12} className='logo google' src={google} />
      <img value={10} className='logo youtube' src={youtube} />
      <img value={16} className='logo tiktok' src={tiktok} />
      <img value={16} className='logo instagram' src={instagram} />
    </div>
  </div>

}

export default SocialLogosAnimated
