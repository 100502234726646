import React, { useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";

const AdminSearchBar = props => {

    const [searchText, setSearchText] = useState(props.searchText || "");
    const [typingTimeout, setTypingTimeout] = useState(0);

    const onChangeHandler = (e) => {
        let value = e.target.value
        setSearchText(value)
        if (typingTimeout) {
            clearTimeout(typingTimeout)
        }
        setTypingTimeout(setTimeout(() => {
            props.doSearch(value)
            // Send Axios request here
        }, 1000))
    }

    return <div className="search-bar">
        <input
            type="text"
            placeholder={props.placeholder || "Search"}
            value={searchText || ""}
            onChange={onChangeHandler}
        />
        {searchText === "" ? (
            <FaSearch className="icon" />
        ) : (
            <FaTimes
                style={{ cursor: "pointer" }}
                onClick={() => {
                    onChangeHandler({ target: { value: "" } })
                }}
                className="icon"
            />
        )}
    </div>
}

export default AdminSearchBar;