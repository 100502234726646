import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dateFormatter from "../../shared/util/dateFormatter";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Table from "../../shared/components/UIElements/Table";
import { generalActions } from "../../store/general";
import InfoBadge from "../../shared/components/UIElements/InfoBadge";
import "./TeamReport.css"
import Card from "../../shared/components/UIElements/Card";
import numberFormatter from "../../shared/util/numberFormatter";
import gradientMaker from "../../shared/util/gradientMaker";
import CRMGeoChart from "../components/CRMGeoChart";
import { authActions } from "../../store/auth";
import Button from "../../shared/components/FormElements/Button";
import ReportFilter from "../components/ReportFilter";
import { useConstants } from "../../shared/hooks/constants-hook";
import CRMReportFilterTypes from "../../shared/enums/CRMReportFilterTypes";
import helper from "../../shared/util/helper";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const TeamReport = props => {
    const { privateConstants: constants } = useConstants()
    const auth = useSelector((state) => state.auth);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [teamInfo, setTeamInfo] = useState();
    const [totalActiveCustomers, setTotalActiveCustomers] = useState(0);
    const [mrr, setMrr] = useState(0);
    const [monthlyChurnRate, setMonthlyChurnRate] = useState(0);
    const [averageLifetimeValue, setAverageLifetimeValue] = useState(0);
    const [customerAcquisitionCost, setCustomerAcquisitionCost] = useState(numberFormatter.formatNumber(404) + "$");
    const [mapData, setMapData] = useState([]);
    const dispatch = useDispatch();
    const activeCustomersChartRef = useRef()
    const mrrChartRef = useRef()
    const churnChartRef = useRef()
    const activeCustomersByPlatformChartRef = useRef()
    const activeCustomersBySourceChartRef = useRef()
    const [filters, setFilters] = useState(JSON.parse(sessionStorage.getItem("reportPanelFilters")) || [])
    const { performanceManagers, accountManagers } = useSelector((state) => state.admin);

    const chartPointRadius = parseInt(window.innerWidth / 400) > 5 ? 5 : parseInt(window.innerWidth / 400);

    const [activeCustomersPlatformTableData, setActiveCustomersPlatformTableData] = useState({
        head: ["Platform", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeCustomersSourceTableData, setActiveCustomersSourceTableData] = useState({
        head: ["Source", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeInboundCustomersTableData, setActiveInboundCustomersTableData] = useState({
        head: ["Inbound Type", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeCustomersSourceandPlatformTableData, setActiveCustomersSourceandPlatformTableData] = useState({
        head: ["Platform", "Source", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });


    const [activeCustomersCountryTableData, setActiveCustomersCountryTableData] = useState({
        head: ["Country", "Active Customers", "% Share", "Total MRR", "% MRR"],
        rows: []
    });


    // const [allCustomersPlatformTableData, setAllCustomersPlatformTableData] = useState({
    //     head: ["Platform", "Total Customers", "Average MRR", "Lifetime Value", "CAC", "ROI"],
    //     rows: []
    // });

    const allCustomersPlatformTableData = {
        head: ["Platform", "Total Customers", "Average MRR", "Lifetime Value", "CAC", "ROI"],
        rows: [
            ["Other", "167", "210$", "1,909$", "413$", "4.6"],
            ["Shopify", "83", "101$", "839$", "449$", "1.9"],
            [<b>Grand Total</b>, <b>250</b>, <b>174.3$</b>, "", "", ""],
        ]
    };

    // const [allCustomersSourceTableData, setAllCustomersSourceTableData] = useState({
    //     head: ["Source", "Total Customers", "Average MRR", "Lifetime Value", "CAC", "ROI"],
    //     rows: []
    // });

    const allCustomersSourceTableData = {
        head: ["Source", "Average MRR", "Lifetime Value", "CAC", "ROI"],
        rows: [
            ["Inbound", "$97", "$712", "$521", "1.4"],
            ["Outbound", "$204", "$2,465", "$304", "8.1"],
            [<b>Grand Total</b>, <b>$173</b>, <b>$1,961</b>, <b>$413</b>, <b>4.7</b>],
        ]
    };

    // const [allCustomersLTV, setAllCustomersLTV] = useState({
    //     head: ["Platform", "Source", "Total Customers", "Average MRR", "Lifetime Value", "CAC", "ROI"],
    //     rows: []
    // });

    const allCustomersLTV = {
        head: ["Platform", "Source", "Total Customers", "Average MRR", "Lifetime Value", "CAC", "ROI"],
        rows: [
            [<td rowSpan={2}>Other</td>, "Outbound", "146", "223.8$", "2,079$", "387$", "5.4"],
            ["Inbound", "21", "117.7$", "726$", "597$", "1.2"],
            [<td rowSpan={2}>Shopify</td>, "Outbound", "40", "110.9$", "921$", "403$", "2.3"],
            ["Inbound", "43", "92.5$", "762$", "491$", "1.6"],
            [<b>Grand Total</b>, "", <b>250</b>, <b>174.3$</b>, "", "", ""],
        ]
    };

    const labels = ['Jan 21', 'Feb 21', 'Mar 21', 'Apr 21', 'May 21', 'Jun 21', 'Jul 21', 'Aug 21', 'Sep 21', 'Oct 21', 'Nov 21', 'Dec 21',
        'Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22'];

    const [activeCustomersChartData, setActiveCustomersChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'Total Active Customers',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: 5,
                pointHoverRadius: 9,
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
        ],

    })
    const [mrrChartData, setMrrChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                    // text: 'Monthly MRR',
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'MRR',
                data: [990, 990, 990, 1190, 1190, 2740, 2959, 4346, 6467, 9806, 10488, 11275, 11962, 13658, 16810, 19897, 23902, 25302, 25842, 26248, 31028, 36960, 42257,
                ],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: 5,
                pointHoverRadius: 9,
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],
    })

    const [churnChartData, setChurnChartData] = useState({

        options: {
            responsive: true,
            tension: 0.4,
            scales: {
                y: {
                    min: 0,
                    max: 30
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
            },
        },
        labels: labels,
        datasets: [
            {
                label: 'Churn Rate',
                data: [0, 6, 3, 2, 4, 4, 5, 6, 3, 5, 3, 3, 1],
                lineWidth: 0,
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#c15f10',
                fill: true,
                pointRadius: 5,
                pointHoverRadius: 9,
                pointBorderWidth: 5,
                pointBackgroundColor: '#c15f10',
                pointHoverBackgroundColor: '#c15f10'
            },
        ],

    })

    const [activeCustomersByPlatformChartData, setActiveCustomersByPlatformChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'Other',
                data: [10, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: 'Shopify',
                data: [10, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],

    })

    const [activeCustomersBySourceChartData, setActiveCustomersBySourceChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'Inbound',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: 'Outbound',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],

    })

    const inboundAdSpend = {
        head: ["Country", "Ad Spent", "Signup", "BookaDemo", "Subscription", "CAC", "Demo->Subscription Rate"],
        rows: [
            ["Turkey", "$6,117", "53", "47", "30", "$204", "64%"],
            ["USA", "$12,340", "32", "37", "23", "$537", "62%"],
            ["UK", "$5,364", "9", "20", "6", "$894", "30%"],
            ["Netherlands", "$7,634", "9", "10", "6", "$1,272", "60%"],
            ["Others", "$7,618", "23", "30", "10", "$762", "33%"],
            [<b>Total</b>, <b>39,073$</b>, <b>126</b>, <b>144</b>, <b>75</b>, <b>$521</b>, <b>52%</b>],
        ]
    }

    const inboundSales = {
        head: ["Platform", "Country", "Number of Customers", "CAC", "Total Ad Spent"],
        rows: [
            [<td rowSpan={6}>Shopify</td>, "France", "1", "703$", "$703"],
            ["Germany", "2", "$1,025", "2,050$"],
            ["Netherlands", "3", "$865", "2,595$"],
            ["Spain", "1", "526$", "$526"],
            ["Turkey", "3", "$503", "1,509$"],
            ["USA", "10", "$221", "2,206$"],

            [<td rowSpan={2}>Other</td>, "Turkey", "13", "$74", "967$"],
            ["Netherlands", "1", "1,673$", "1,673$"],
        ]
    }


    const pageTitle = "Enhencer Team Financials"

    useEffect(() => {
        document.title = pageTitle
        dispatch(generalActions.setPageTitle({ pageTitle }))

        const fetchUsers = async () => {
            const filtersParam = generateFilters(filters);
            if (auth.token) {
                try {
                    const rawTeamInfo = await sendRequest(
                        `${process.env.REACT_APP_BACKEND_URL}/admin/getTeamInfo`,
                        "GET",
                        null,
                        {
                            Authorization: "Bearer " + auth.token,
                        }
                    );
                    setTeamInfo(rawTeamInfo);
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_BACKEND_URL}/dev/getReportData/?list=${"Report2"}&search=${""}&filters=${filtersParam}&sort=${""}:${""}`,
                        "POST",
                        null,
                        {
                            Authorization: "Bearer " + auth.token,
                        }
                    );

                    const activeCustomersData = responseData.activeCustomersChartData;
                    setTotalActiveCustomers(activeCustomersData[activeCustomersData.length - 1]);
                    const newLabels = responseData.chartLabels;
                    const mrrData = responseData.mrrChartData;
                    const activeOtherCustomersChartData = responseData.activeOtherCustomersChartData;
                    const activeShopifyCustomersChartData = responseData.activeShopifyCustomersChartData;
                    const activeInboundCustomersChartData = responseData.activeInboundCustomersChartData;
                    const activeOutboundCustomersChartData = responseData.activeOutboundCustomersChartData;
                    setMrr(numberFormatter.formatNumber(parseInt(mrrData[mrrData.length - 1])) + "$");
                    const churnData = responseData.churnChartData;
                    const churnRate = churnData.slice(-6).reduce((a, b) => a + b, 0) / 6;
                    setMonthlyChurnRate(churnRate.toFixed(2) + "%");

                    if (activeCustomersChartRef.current) {
                        const datasetsCopy = activeCustomersChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeCustomersData;
                        setActiveCustomersChartData(Object.assign({}, activeCustomersChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (mrrChartRef.current) {
                        const datasetsCopy = mrrChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(mrrChartRef.current.ctx, "#eec52c");
                        datasetsCopy[0].data = mrrData;
                        setMrrChartData(Object.assign({}, mrrChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (activeCustomersByPlatformChartRef.current) {
                        const datasetsCopy = activeCustomersByPlatformChartData.datasets.slice(0);
                        // datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersByPlatformChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeOtherCustomersChartData;
                        datasetsCopy[1].data = activeShopifyCustomersChartData;
                        setActiveCustomersByPlatformChartData(Object.assign({}, activeCustomersByPlatformChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (activeCustomersBySourceChartRef.current) {
                        const datasetsCopy = activeCustomersBySourceChartData.datasets.slice(0);
                        // datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersBySourceChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeInboundCustomersChartData;
                        datasetsCopy[1].data = activeOutboundCustomersChartData;
                        setActiveCustomersBySourceChartData(Object.assign({}, activeCustomersBySourceChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (churnChartRef.current) {
                        const datasetsCopy = churnChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(churnChartRef.current.ctx, "#c15f10");
                        datasetsCopy[0].data = churnData;
                        setChurnChartData(Object.assign({}, churnChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }


                    const activeCustomersPlatformTableRows = [];
                    const activeCustomersTableDataByPlatform = responseData.activeCustomersTableDataByPlatform;
                    activeCustomersTableDataByPlatform !== undefined && activeCustomersTableDataByPlatform.forEach((result, index) => {
                        if (index !== activeCustomersTableDataByPlatform.length - 1) {
                            activeCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersPlatformTableRows.push([
                                <b>{result._id.platform}</b>,
                                <b>{result.count}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$"}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$"}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveCustomersPlatformTableData(Object.assign({}, activeCustomersPlatformTableData, {
                        rows: activeCustomersPlatformTableRows
                    }))

                    const activeCustomersSourceTableRows = [];
                    const activeCustomersTableDataBySource = responseData.activeCustomersTableDataBySource;
                    activeCustomersTableDataBySource !== undefined && activeCustomersTableDataBySource.forEach((result, index) => {
                        if (index !== activeCustomersTableDataBySource.length - 1) {
                            activeCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersSourceTableRows.push([
                                <b>{result._id.source}</b>,
                                <b>{result.count}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$"}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$"}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveCustomersSourceTableData(Object.assign({}, activeCustomersSourceTableData, {
                        rows: activeCustomersSourceTableRows
                    }))

                    const activeCustomersSourceandPlatformTableRows = [];
                    const activeCustomersTableData = responseData.activeCustomersTableData;
                    activeCustomersTableData !== undefined && activeCustomersTableData.forEach((result, index) => {
                        if (index % 2 === 0) {
                            activeCustomersSourceandPlatformTableRows.push([
                                <td rowSpan={2}>{result._id.platform}</td>,
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersSourceandPlatformTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        }
                    });

                    setActiveCustomersSourceandPlatformTableData(Object.assign({}, activeCustomersSourceandPlatformTableData, {
                        rows: activeCustomersSourceandPlatformTableRows
                    }))


                    const activeInboundCustomersTableRows = [];
                    const activeInboundCustomersTableDataByType = responseData.activeInboundCustomersTableData;
                    activeInboundCustomersTableDataByType !== undefined && activeInboundCustomersTableDataByType.forEach((result, index) => {
                        if (index !== activeInboundCustomersTableDataByType.length - 1) {
                            activeInboundCustomersTableRows.push([
                                result._id.inboundType,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeInboundCustomersTableRows.push([
                                <b>{result._id.inboundType}</b>,
                                <b>{result.count}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$"}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$"}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveInboundCustomersTableData(Object.assign({}, activeInboundCustomersTableData, {
                        rows: activeInboundCustomersTableRows
                    }))


                    const activeCustomersCountryTableRows = [];
                    const activeCustomersMapData = [["Country", "Active Customers", "Total MRR"]];
                    const activeCustomersMapTableData = responseData.mapTableData;
                    activeCustomersMapTableData !== undefined && activeCustomersMapTableData.forEach((result, index) => {
                        activeCustomersCountryTableRows.push([
                            result._id.country,
                            result.count,
                            result.percentageShare.toFixed(2) + "%",
                            numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                            result.percentageMrr.toFixed(2) + "%",
                        ]);
                        activeCustomersMapData.push([
                            result._id.country,
                            result.count,
                            result.totalMrr
                        ]);
                        setMapData(activeCustomersMapData);
                    });

                    setActiveCustomersCountryTableData(Object.assign({}, activeCustomersCountryTableData, {
                        rows: activeCustomersCountryTableRows
                    }))

                    const allCustomersPlatformTableRows = [];
                    const lifetimeValueTableDataByPlatform = responseData.lifetimeValueTableDataByPlatform;
                    lifetimeValueTableDataByPlatform.forEach((result, index) => {
                        if (result._id.platform === "Other") {
                            allCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "335$",
                                "5.6"
                            ]);
                        } else {
                            allCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "473$",
                                "1.2"
                            ]);
                        }
                    });

                    // setAllCustomersPlatformTableData(Object.assign({}, allCustomersPlatformTableData, {
                    //     rows: allCustomersPlatformTableRows
                    // }))

                    const lifetimeValue = (lifetimeValueTableDataByPlatform[0].ltv * lifetimeValueTableDataByPlatform[0].count +
                        lifetimeValueTableDataByPlatform[1].ltv * lifetimeValueTableDataByPlatform[1].count) /
                        (lifetimeValueTableDataByPlatform[0].count + lifetimeValueTableDataByPlatform[1].count);

                    setAverageLifetimeValue(numberFormatter.formatNumber(parseInt(lifetimeValue)) + "$");

                    const allCustomersSourceTableRows = [];
                    const lifetimeValueTableDataBySource = responseData.lifetimeValueTableDataBySource;
                    lifetimeValueTableDataBySource.forEach((result, index) => {
                        if (result._id.source === "Outbound") {
                            allCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "317$",
                                "5.7"
                            ]);
                        } else {
                            allCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "492$",
                                "1.1"
                            ]);
                        }
                    });

                    // setAllCustomersSourceTableData(Object.assign({}, allCustomersSourceTableData, {
                    //     rows: allCustomersSourceTableRows
                    // }))

                    const allCustomersLTVTableRows = [];
                    const lifetimeValueTableData = responseData.lifetimeValueTableData;
                    lifetimeValueTableData.forEach((result, index) => {
                        if (index % 2 === 0) {
                            if (result._id.platform === "Other") {
                                allCustomersLTVTableRows.push([
                                    <td rowSpan={2}>{result._id.platform}</td>,
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "220$",
                                    "9.4"
                                ]);
                            } else {
                                allCustomersLTVTableRows.push([
                                    <td rowSpan={2}>{result._id.platform}</td>,
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "413$",
                                    "1.7"
                                ]);
                            }
                        } else {
                            if (result._id.platform === "Other") {
                                allCustomersLTVTableRows.push([
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "451$",
                                    "1.4"
                                ]);
                            } else {
                                allCustomersLTVTableRows.push([
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "534$",
                                    "0.9"
                                ]);
                            }
                        }
                    });

                    // setAllCustomersLTV(Object.assign({}, allCustomersLTV, {
                    //     rows: allCustomersLTVTableRows
                    // }))

                } catch (err) {
                  toast.error(err?.message || "Something went wrong")
                }
            }
        };
        fetchUsers();



    }, [filters]);


    const generateFilters = (newFilters) => {
        let filtersParam = "{"
        newFilters.forEach(filter => {
            switch (filter.type) {
                case CRMReportFilterTypes.STRING: 
                case CRMReportFilterTypes.REGISTRATION_SOURCE: 
                case CRMReportFilterTypes.NUMERIC: 
                case CRMReportFilterTypes.UTM_SOURCE: 
                case CRMReportFilterTypes.BOOLEAN: 
                    filtersParam += `"${filter.key}":{"value": "${filter.option}", "operator": "${filter.operator}", "type": "${filter.type}"},`
                    break;
                case CRMReportFilterTypes.DATE:
                    filtersParam += `"${filter.key}":{"start": "${filter.option.start}", "end": "${filter.option.end}", "type": "${filter.type}"},`
                    break;
                case CRMReportFilterTypes.FUNNEL:
                    Object.keys(constants.customerFunnelNames).forEach((k, index) => {
                        if (constants.customerFunnelNames[k] === filter.option) {
                            filtersParam += `"${filter.key}":{"value": "${index + 1}", "operator": "${filter.operator}", "type": "${filter.type}"},`
                        }
                    })
                    break;
                case  CRMReportFilterTypes.OBJECT_ID:
                    if (filter.key === "crmDetails.accountManager") {
                        let id = helper.findBy(Object.values(accountManagers), 'name',  filter.option)._id;
                        filtersParam += `"${filter.key}":{"value": "${id}", "operator": "${filter.operator}", "type": "${filter.type}"},`
                       
                    } else if (filter.key === "crmDetails.performanceManager") {
                       let id = helper.findBy(Object.values(performanceManagers), 'name', filter.option)._id
                        filtersParam += `"${filter.key}":{"value": "${id}", "operator": "${filter.operator}", "type": "${filter.type}"},`
                       
                    } else {
                        filtersParam += `"${filter.key}":{"value": "${filter.option}", "operator": "${filter.operator}", "type": "${filter.type}"},`
                    }
                    break;
                default:
                    break;
            }
        })
        if (filtersParam.length > 1) {
            filtersParam = filtersParam.substring(0, filtersParam.length - 1);
        }
        filtersParam += "}";
        return filtersParam;
    }

    const applyFilters = async (newFilters) => {
        setFilters(newFilters)
        sessionStorage.setItem('reportPanelFilters', JSON.stringify(newFilters));
    }



    return <div className="team-report">
        {!isLoading && totalActiveCustomers !== 0 &&
            <div className="row first control-row">
                <div className="">
                  <h1>CRM Team Report</h1>
                  <h6>Updated at {dateFormatter(new Date())}</h6>
                </div>
                <ReportFilter
                  list={'Report'}
                  filters={filters}
                  leadGenerationOptions={teamInfo.leadGenerationOptions}
                  inboundTypeOptions={teamInfo.inboundTypeOptions}
                  accountManagerOptions={accountManagers}
                  performanceMarketingOptions={performanceManagers}
                  performanceReporterOptions={performanceManagers}
                  partnerOptions={teamInfo.partnerOptions}
                  applyFilters={applyFilters}
                />
            </div>
        }
        {<React.Fragment>
            <div className="row">
                <InfoBadge value={totalActiveCustomers} name="Total Active Customers" />
                <InfoBadge value={mrr} name="Current MRR" background={["#eec52c", "#bd9b1d"]} />
            </div>

            {totalActiveCustomers !== 0 ?
                <div className="row">
                    <Card>
                        <Line ref={activeCustomersChartRef} options={activeCustomersChartData.options} data={activeCustomersChartData} />
                    </Card>
                    <Card>
                        <Line ref={mrrChartRef} options={mrrChartData.options} data={mrrChartData} />
                    </Card>
                </div> :
                <div className="row">
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                </div>}


            {totalActiveCustomers !== 0 ?
                <div className="row" style={{ alignItems: "center" }}>
                    <Card>
                        <Table title="Active Customers by Platform" data={activeCustomersPlatformTableData} />
                    </Card>
                    <Card>
                        <Table title="Active Customers by Source" data={activeCustomersSourceTableData} />
                    </Card>
                </div> :
                <div className="row" style={{ alignItems: "center" }}>
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                </div>}
            <div className="row wrappable">
                <Card>
                    <Line ref={activeCustomersByPlatformChartRef} options={activeCustomersByPlatformChartData.options} data={activeCustomersByPlatformChartData} />
                </Card>
                <Card>
                    <Line ref={activeCustomersBySourceChartRef} options={activeCustomersBySourceChartData.options} data={activeCustomersBySourceChartData} />
                </Card>
            </div>

            {totalActiveCustomers !== 0 ?
                <div className="row" style={{ alignItems: "center" }}>
                    <Card>
                        <Table title="Active Inbound Customers by Type" data={activeInboundCustomersTableData} />
                    </Card>
                    <Card>
                        <Table title="Active Customers" data={activeCustomersSourceandPlatformTableData} />
                    </Card>
                </div> :
                <div className="row" style={{ alignItems: "center" }}>
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                </div>}

            {totalActiveCustomers !== 0 ?
                <div className="row">
                    <Card>
                        <Table title="Active Customers by Country" data={activeCustomersCountryTableData} />

                    </Card>
                    <Card>
                        <h4>Active Customers Map</h4>
                        <CRMGeoChart mapData={mapData} />
                    </Card>

                </div> :
                <div className="row">
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>
                    <Card>
                        <h4>No Data Found</h4>
                    </Card>

                </div>
            }

            <div className="row">
                <InfoBadge value={"1,961" + "$"/*averageLifetimeValue*/} name="Average Lifetime Value" background={["#85ca6e", "#4c9035"]} />
                <InfoBadge value={413 + "$"/*customerAcquisitionCost*/} name="Customer Acquisition Cost" background={["#058694", "#066670"]} />
            </div>
            <div className="row" style={{ alignItems: "center" }}>
                {/* <Card>
                <Table title="LTV & CAC by Platform" data={allCustomersPlatformTableData} />
            </Card> */}
                <Card>
                    <Table title="LTV & CAC by Source" data={allCustomersSourceTableData} />
                </Card>
            </div>
            <div>
                {/* <Card>
                <Table title="LTV & CAC" data={allCustomersLTV} />
            </Card> */}
            </div>
            <div className="row">
                <Card>
                    <Table title="Inbound Ad Spend (1 Sept 2022 - 16 Jan 2023)" data={inboundAdSpend} />
                </Card>
            </div>
            <div className="row">
                {/* <Card>
                <Table title="Inbound Ad Spend (1 Sep - 30 Nov, 2022)" data={inboundSales} />
            </Card> */}
            </div>

            <div className="row">
                <InfoBadge value={monthlyChurnRate} name="Monthly Churn Rate" background={["#c15f10", "#8e4912"]} />
            </div>
            <Card>
                <Line ref={churnChartRef} options={churnChartData.options} height={70} data={churnChartData} />
            </Card>
        </React.Fragment>}
    </div>
}

export default TeamReport;
