import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import { FaCalendarAlt } from 'react-icons/fa'

import Button from '../../shared/components/FormElements/Button'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import dateFormatter from '../../shared/util/dateFormatter'

import './CardAdminAIAudienceModel.css'
import 'react-datepicker/dist/react-datepicker.css'
import CollapsibleCard from '../../shared/components/UIElements/CollapsibleCard'
import CardAdminViewUserDataVariables from './CardAdminViewUserDataVariables'
import CardAdminModelSettings from './CardAdminModelSettings'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'

const CardAdminAIAudienceModel = ({ userId }) => {
  const [projectType, setProjectType] = useState('')
  const [schedule, setSchedule] = useState()
  const [newSchedule, setNewSchedule] = useState({ day: 30 })
  const [newJob, setNewJob] = useState({ day: 1 })
  const [scheduleDate, setScheduleDate] = useState(new Date())
  const [jobDate, setJobDate] = useState(new Date())

  const auth = useSelector(state => state.auth)
  const { sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const customer = useSelector(state => state.admin.customer)

  useEffect(() => {
    const init = async () => {
      if (userId && customer) {
        const job = customer.job
        let scheduleDayCount
        if (!job || job?.message === 'no job') {
        } else {
          try {
            const projectResult = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/admin/getProjectOfUser/${userId}`,
              'GET',
              null,
              {
                Authorization: 'Bearer ' + auth.token,
              },
            )
            setProjectType(projectResult.type)

            scheduleDayCount = parseInt(job.repeatInterval.split(' ')[0])
            setSchedule({
              nextRunAt: job.nextRunAt,
              day: scheduleDayCount,
            })
          } catch (err) {
            errorHandler(err)
          }
        }
      }
    }
    init()
  }, [customer, userId])

  const setDate = date => {
    setScheduleDate(new Date(date))
  }

  const onScheduleDayChange = e => {
    setNewSchedule({
      ...newSchedule,
      [e.target.id]: parseInt(e.target.value),
    })
  }

  const deleteJob = () => {
    openModal({
      type: Modals.ARE_YOU_SURE,
      question: 'You will delete the job. Are you sure?',
      callback: () => {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/dev/deleteJob`,
          'POST',
          JSON.stringify({
            userId: userId,
            jobName: 'purchase',
          }),
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )
          .then(result => {
            if (result === 'deleted_job') {
              toast.success('Job deleted successfully!')
            } else if (result === 'no_job') {
              toast.success('No job found!')
            }
          })
          .catch(err => {
            errorHandler(err)
          })
      },
    })
  }

  const save = async e => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createJob`,
        'POST',
        JSON.stringify({
          userId: userId,
          type: 'purchase',
          startsOn: {
            date: scheduleDate.toLocaleDateString(),
            time: scheduleDate.toLocaleTimeString(),
          },
          repeats: {
            months: 0,
            days: newSchedule.day,
            hours: 0,
            minutes: 0,
          },
          isJobUpdate: true,
        }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  return (
    <CollapsibleCard
      title="AI Audience Model"
      className="ruby ai-audience-model-card"
    >
      <CardAdminModelSettings userId={userId} job={customer.job} />

      <React.Fragment>
        {schedule && (
          <div className="section edit-job">
            <div className="header-row">
              <h4>Model Scheduler</h4>
            </div>
            <div className="row main-row">
              <div className="existing-job">
                <div className="attribute-name">Existing Job</div>
                <div className="content-box">
                  <div className="row">
                    <label>Next Run</label>
                    <div>{dateFormatter(schedule.nextRunAt) || 'Not set'}</div>
                  </div>

                  <div className="row">
                    <label>Repeats Every</label>
                    <div>{schedule.day} days</div>
                  </div>
                  <Button onClick={deleteJob}>Delete Job</Button>
                </div>
              </div>

              <div className="edit-job-box">
                <div className="attribute-name">Edit Job</div>
                <div className="content-box">
                  <div className="row">
                    <label>Starts on</label>
                    <div className="type-row">
                      <div className="date-input-cont">
                        <DatePicker
                          className="scheduledatepicker"
                          style={{ width: '144px' }}
                          pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                          selected={scheduleDate}
                          onChange={date => setDate(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={1}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                        <FaCalendarAlt className="icon" />
                      </div>
                    </div>
                  </div>

                  <div className="type-row row" style={{ margin: '0.5em 0' }}>
                    <label>Run model every</label>
                    <div className="text-edit row">
                      <input
                        id="day"
                        type="number"
                        value={newSchedule.day || 30}
                        onChange={onScheduleDayChange}
                      />
                      <label>days</label>
                    </div>
                  </div>
                  <Button onClick={save}>Save</Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <CardAdminViewUserDataVariables userId={userId} />
      </React.Fragment>
    </CollapsibleCard>
  )
}

export default CardAdminAIAudienceModel
