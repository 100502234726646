import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector, useStore } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import allCountriesList from '../../shared/data/countries.json'
import { useFacebook } from '../../shared/hooks/facebook-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import helper from '../../shared/util/helper'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import PublishButton from '../components/PublishButton'
import CampaignLabel from '../components/CampaignLabel'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import AdCreativePreviewer from '../components/AdCreativePreviewer'
import CampaignsPageLayout from '../components/CampaignsPageLayout'

import './CreateCampaign.css'
import CampaignTargetingForm from '../components/CampaignTargetingForm'
import AdCreativeMaker from '../components/AdCreativeMaker'
import LoadingDotsSpinner from '../../shared/components/UIElements/LoadingDotsSpinner'
import CampaignCreativesSection from '../components/CampaignCreativesSection'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'
import CampaignCreativesSectionEditMode from '../components/CampaignCreativesSectionEditMode'
import TrafficCampaignSection from '../components/TrafficCampaignSection'
import Modals from '../../shared/enums/Modals'

const CreateCampaign = () => {
  const { createCampaign } = useFacebook()
  const { endCampaignEditing, startNewEmptyCampaign } =
    useFacebookCampaignEditor()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [campaignCreated, setCampaignCreated] = useState(false)

  const [shouldRecreateCampaign, setShouldRecreateCampaign] = useState()
  const [campaignIdToBeRemoved, setCampaignIdToBeRemoved] = useState()
  const store = useStore()

  const [currentCreative, setCurrentCreative] = useState()
  const [isPreviewUpToDate, setIsPreviewUpToDate] = useState(false)
  const [editorRefreshed, setEditorRefreshed] = useState(false)

  const isProcessing = useSelector(
    state => state.facebookCampaignEditor?.loadings.isProcessingTextFields,
  )

  useEffect(() => {
    startNewEmptyCampaign()
    setEditorRefreshed(true)
  }, [])

  const handlePublish = async () => {
    let finalEditorState =
      store.getState().facebookCampaignEditor.currentCampaign
    try {
      const { dailyBudget, age, genders, countries } =
        finalEditorState.targeting
      const { type, body, details } = finalEditorState.currentCreative
      const label = finalEditorState.label
      const adCreatives = {}

      Object.keys(finalEditorState.adCreatives).forEach(creativeId => {
        let newCreative = { ...finalEditorState.adCreatives[creativeId] }
        delete newCreative.existsInListWithId
        delete newCreative.replacesCreativeWithId
        delete newCreative.isEdited
        adCreatives[creativeId] = newCreative
      })

      const data = {
        label: label.trim(),
        countryCodes: countries.map(
          r => helper.findBy(allCountriesList, 'name', r).code,
        ),
        countries: countries,
        dailyBudget,
        age,
        genders: helper.getGendersList(genders),
        creative: { id: body.id },
        shouldRecreateCampaign,
        campaignIdToBeRemoved,
        adCreatives,
        trafficCampaign: finalEditorState.trafficCampaign
      }

      console.log('publish it ', data)

      setIsLoading(true)
      let campaignResponse = await createCampaign(data)

      if (campaignResponse.error) {
        toast.warn(
          `Could not create campaign: ${campaignResponse.error.error_user_msg}`,
        )
        setIsLoading(false)
        return
      } else if (campaignResponse.shouldRecreate) {
        setShouldRecreateCampaign(true)
        setCampaignIdToBeRemoved(campaignResponse.adCampaign?.id)
        setIsLoading(false)
      } else {
        cleanUp(campaignResponse.enhCampaign)
      }
    } catch (err) {
      toast.warn(
        `Could not create campaign. ${err.error_user_msg ?? 'Please contact us.'}`,
      )
      setIsLoading(false)
    }
  }

  const cleanUp = newEnhCampaign => {
    setIsLoading(false)
    endCampaignEditing({ completedSuccessfully: true })
    if (newEnhCampaign) {
      openModal({
        type: Modals.CAMPAIGN_CREATION,
        enhCampaign: newEnhCampaign,
        callback: () => {
          navigate(`/ai-ads/full-funnel-campaigns?id=${newEnhCampaign._id}`)
          setTimeout(() => {
            setCampaignCreated(false)
          }, 2000)
        },
      })
    }
    setCampaignCreated(true)
  }

  return editorRefreshed ? (
    <CampaignsPageLayout>
      <FluidCard className="create-campaign">
        <FluidCard.Header>
          <div className="header-wrapper">
            <div className="options">
              <CampaignLabel />
            </div>
            <div className="actions">
              {isProcessing && <LoadingDotsSpinner />}
              <PublishButton busy={isLoading} onClick={handlePublish} />
            </div>
          </div>
        </FluidCard.Header>
        <FluidCard.Content>
          <div className="create-campaign-content-wrapper">
            {isLoading && (
              <div className="overlay">
                <InlineLoadingSpinner
                  height={40}
                  message="Publishing the campaign..."
                ></InlineLoadingSpinner>
              </div>
            )}
            {campaignCreated && (
              <div className="overlay">
                <div className="campaign-created-message">
                  <div className="icon-wrapper">
                    <img
                      src="https://cdn.enhencer.com/website-assets/images/icon-set/success.png"
                      alt="success"
                    />
                  </div>
                  Your campaign was published successfully!
                </div>
              </div>
            )}
            <div className="left-box">
              <CampaignTargetingForm />
              <CampaignCreativesSectionEditMode />
            </div>

            <div>
              <AdCreativePreviewer
                editing={true}
                creative={currentCreative}
                setIsPreviewUpToDate={setIsPreviewUpToDate}
              />
            </div>
          </div>
        </FluidCard.Content>
        <PublishButton
          className="float-publish-button"
          busy={isLoading}
          onClick={handlePublish}
        />
      </FluidCard>
    </CampaignsPageLayout>
  ) : (
    <InlineLoadingSpinner message="Initializing editor"></InlineLoadingSpinner>
  )
}

export default CreateCampaign
