import React, { useState, useEffect } from 'react'

const VerificationInput = ({ length = 6, onChange }) => {
  const [digits, setDigits] = useState(Array(length).fill(''))
  const inputs = Array(length).fill(null)

  useEffect(() => {
    const code = digits.join('')

    if (onChange) {
      onChange(code)
    }
  }, [digits, onChange])

  const handleInputChange = (e, index) => {
    const { value } = e.target

    if (!/^[0-9]$/.test(value) && value !== '') {
      return
    }

    if (/^[0-9]$/.test(value) && index < length - 1) {
      // Focus on the next input when writing
      inputs[index + 1].focus()
    }

    const updatedDigits = [...digits]
    updatedDigits[index] = value
    setDigits(updatedDigits)
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      const updatedDigits = [...digits]
      updatedDigits[index] = ''
      setDigits(updatedDigits)

      // Focus on the previous input when deleting
      inputs[index - 1].focus()
    }

    // Do not move the cursor
    if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
    }
  }

  return (
    <div className="verification-input">
      {digits.map((digit, index) => (
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={e => handleInputChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          ref={el => (inputs[index] = el)}
        />
      ))}
    </div>
  )
}

export default VerificationInput
