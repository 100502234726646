import React from 'react'
import { FaComment, FaExpand } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import constants from '../../constants'
import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import Tooltip from '../../shared/components/UIElements/Tooltip'

import './SupportTicketCard.css'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'

const SupportTicketCard = ({ ticket }) => {
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { openModal } = useModal()

  const { _id, user } = ticket

  const getUsername = user => {
    let domain = user.shopify
      ? user.shopify.name || user.shopify.domain
      : user.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : user.crmDetails?.contactName
  }

  const unreadMessages = ticket.messages.filter(
    message => !message.read && message.receiver === auth.user._id,
  )

  const updateTicket = async (field, payload) => {
    const query = {
      [field]: payload,
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateSupportTicket/${ticket._id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      const newTicketData = {
        ...ticket,
        [field]: payload,
      }

      dispatch(
        adminActions.findTicketByIdAndUpdate({
          id: ticket._id,
          data: newTicketData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const handlePriorityChange = async e => {
    await updateTicket('priority', e.target.value)
  }

  const handleStatusChange = async e => {
    await updateTicket('status', e.target.value)
  }

  return (
    <div
      className={`support-ticket-card ${ticket.priority || 'normal'}`}
      key={_id}
    >
      <div className="card-header">
        <div className="section">
          <span className="username">{getUsername(user)}</span>
          <div className="priority">
            <select
              defaultValue={ticket.priority || 'normal'}
              onChange={handlePriorityChange}
            >
              <option value="urgent">Urgent</option>
              <option value="high">High</option>
              <option value="normal">Normal</option>
            </select>
            <span>
              Priority:&nbsp;
              {ticket.priority
                ? ticket.priority[0].toUpperCase() + ticket.priority.slice(1)
                : 'Normal'}
            </span>
          </div>
        </div>
      </div>
      <div className="card-content">
        <div className="section">
          <p className="key-value">
            <span className="key">Issue Type:&nbsp;</span>
            {ticket.bugType}
          </p>
        </div>
        <div className="section">
          {ticket.description && (
            <p className="description">{ticket.description}</p>
          )}
        </div>
        <div className="section">
          <div className="indicators">
            <Tooltip content="See the details" size="sm">
              <div
                onClick={() =>
                  openModal({
                    type: Modals.TICKET_DETAILS,
                    closeOnClickOutside: true,
                    ticket,
                  })
                }
                className="item details"
              >
                <FaExpand />
              </div>
            </Tooltip>
            <div className="messages">
              <div className="icon-container">
                <FaComment className="icon" />
                {unreadMessages.length > 0 && <span className="unread" />}
              </div>
              <span className="count">{ticket.messages.length}</span>
            </div>
          </div>
          <div className={`status ${ticket.status || 'pending'}`}>
            <select
              defaultValue={ticket.status || 'pending'}
              onChange={handleStatusChange}
              name="status"
            >
              <option value="pending">Pending</option>
              <option value="in-progress">In progress</option>
              <option value="completed">Completed</option>
              <option value="postponed">Postponed</option>
              <option value="ignored">Ignored</option>
            </select>
            <span>
              {ticket.status
                ? ticket.status[0].toUpperCase() + ticket.status.slice(1)
                : 'Pending'}
            </span>
          </div>
          <div className="performance-marketer">
            <Tooltip content={ticket.performanceManager.name} size="sm">
              <img
                src={ticket.performanceManager.image}
                alt={ticket.performanceManager}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportTicketCard
