import React from "react";
import { useSelector } from "react-redux";

import { useModal } from "../../shared/hooks/modal-hook";

import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { FaShopify } from "react-icons/fa";
import Modals from "../../shared/enums/Modals";

const ShopifyStartSubscription = (props) => {
  const { openModal } = useModal()
  const auth = useSelector((state) => state.auth);
  let user = auth.user;
  if (props.userInView) {
    user = props.userInView
  }

  const startSubscriptionInShopify = async () => {
    if (!user.isSpecialUser && (!user.subscription || (user.subscription && user.subscription.status !== 'active'))) {
      openModal({
        type: Modals.PAYMENT_REQUIRED,
        closeOnClickOutside: false,
        from: "config",
      })
    } else {
      console.log("handle else case")
    }
  }

  return (
    <div>
      {
        (user.subscription && user.subscription.status === 'active') ?
          <div className="no-data">
            <div className="row">
              Status:
              <div className="dot"></div>
              <span style={{ fontWeight: "600" }}>Subscribed</span>
            </div>

            <p>Your subscription is active</p>

            <Link to={"/account/subscription"}>See Details</Link>
          </div>
          :
          < div className="no-data">
            <div>
              First of all, you should start your subscription to continue.
              <br></br>

              <div className="connect-options">
                <div
                  className="option-button row"
                  onClick={startSubscriptionInShopify}
                >
                  Start your subscription
                  <FaShopify fontSize={32}></FaShopify>
                </div>
              </div>

            </div>
          </div>
      }
    </div >

  );
};

export default ShopifyStartSubscription;
