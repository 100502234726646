import React, { useEffect, useState } from 'react'
import { FaCopy, FaHistory, FaLink, FaPenSquare, FaRegClock, FaSave, FaShopify } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'

import { Link, useNavigate } from 'react-router-dom'
import { useConstants } from '../../shared/hooks/constants-hook'
import checkUpIcon from '../../shared/assets/images/icons/checkup-icon.svg'
import helper from '../../shared/util/helper'
import AdminPanelCustomerCardAlarmPart from './AdminPanelCustomerCardAlarmPart'
import NotesPopup from './NotesPopup'

import './SignUpCard.css'
import './AdminPanelCustomerCardAlarmPart.css'
import QuickDropdown from './QuickDropdown'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'
import Checkbox from '../../shared/components/FormElements/Checkbox'
import { getDaysPassedSince } from '../../shared/util/dateFormatter'
import CustomerCardInput from './CustomerCardInput'
import SegmentationScoreIcon from './SegmentationScoreIcon'

const SignUpCard = ({ signUp }) => {
  const { sendRequest } = useHttpClient()
  const { publicConstants } = useConstants()
  const auth = useSelector(state => state.auth)
  const { accountManagers } = useSelector(state => state.admin)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { openModal } = useModal()

  const fullPhoneNumber = signUp.crmDetails?.contactNumber ? ((signUp.crmDetails?.contactNumberCountryCode || "") + signUp.crmDetails?.contactNumber) : ""
  let preferredCallTimeRanges = signUp.crmDetails?.preferredCallTimeRanges
  let availableCallHours = signUp.crmDetails?.availableCallHours
  const [preferredCallTimeRange, setPreferredCallTimeRange] = useState()

  const [isSignUpMeetingDone, setIsSignUpMeetingDone] = useState(signUp.signUpDetails.isSignUpMeetingDone ?? false)
  const [daysPassedSinceLastCall, setDaysPassedSinceLastCall] = useState(parseInt(signUp.daysPassedSinceLastCall))
  const [daysPassedSinceLastTalk, setDaysPassedSinceLastTalk] = useState(parseInt(signUp.daysPassedSinceLastTalk))
  const [daysPassedSinceLastEmail, setDaysPassedSinceLastEmail] = useState(parseInt(signUp.daysPassedSinceLastEmail))
  const [daysPassedSinceLastSMS, setDaysPassedSinceLastSMS] = useState(parseInt(signUp.daysPassedSinceLastSMS))

  const [fields, setFields] = useState({
    calls: {
      count: signUp.crmDetails?.calls?.count || 0,
      lastModified: signUp.crmDetails?.calls?.lastModified || '',
    },
    talks: {
      count: signUp.crmDetails?.talks?.count || 0,
      lastModified: signUp.crmDetails?.talks?.lastModified || '',
    },
    emails: {
      count: signUp.crmDetails?.emails?.count || 0,
      lastModified: signUp.crmDetails?.emails?.lastModified || '',
    },
    smses: {
      count: signUp.crmDetails?.smses?.count || 0,
      lastModified: signUp.crmDetails?.smses?.lastModified || '',
    },
  })

  function convertToTimeRanges(availableCallHoursRaw, timezoneOffset) {
    let availableHours = Array.from(availableCallHoursRaw)

    if (availableHours.length === 0) {
      return "Not specified";
    }

    availableHours = availableHours.map(hour => {
      let newHour = hour + timezoneOffset
      if (newHour >= 24) {
        newHour = newHour - 24
      } else if (newHour < 0) {
        newHour = newHour + 24
      }
      return newHour
    });

    let timeRanges = [];
    let startHour = availableHours[0];
    let endHour = availableHours[0];

    // Loop through the sorted available hours to find continuous ranges
    for (let i = 1; i < availableHours.length; i++) {
      if (availableHours[i] === ((endHour + 1) % 24)) {
        endHour = availableHours[i];
      } else {
        timeRanges.push(`${startHour < 10 ? '0' : ''}${startHour}:00-${endHour + 1 < 10 ? '0' : ''}${endHour + 1}:00`);
        startHour = availableHours[i];
        endHour = availableHours[i];
      }
    }

    // Push the last range
    timeRanges.push(`${startHour < 10 ? '0' : ''}${startHour}:00-${endHour + 1 < 10 ? '0' : ''}${endHour + 1}:00`);

    return timeRanges.join(", ");
  }
  useEffect(() => {
    let rangesString = ""
    let displayedRanges = []
    if (preferredCallTimeRanges && preferredCallTimeRanges.length) {
      let ranges = []
      preferredCallTimeRanges.forEach(range => {
        ranges.push(range)
        displayedRanges.push(publicConstants.callTimeRanges[range])
      })
      if (ranges.includes("dontCall")) {
        rangesString = "Don't call"
      } else if (ranges.includes("morning") && ranges.includes("afternoon") && ranges.includes("evening")) {
        rangesString = "09:00-22:00"
      } else if (ranges.includes("morning") && ranges.includes("afternoon")) {
        rangesString = "09:00-18:00"
      } else if (ranges.includes("afternoon") && ranges.includes("evening")) {
        rangesString = "12:00-22:00"
      } else if (ranges.includes("notSelected")) {
        rangesString = "Not specified"
      } else {
        rangesString = displayedRanges.join(", ")
      }

      setPreferredCallTimeRange(rangesString)
    } else if (availableCallHours) {
      //Turkey's timezone: gmt+3
      let timezoneOffset = 3
      setPreferredCallTimeRange(convertToTimeRanges(availableCallHours, timezoneOffset))
    } else {
      rangesString = "Not specified"
      setPreferredCallTimeRange(rangesString)
    }

  }, [])

  const saveCalls = async () => {
    await save('calls', { count: fields.calls.count, lastModified: Date.now() })
    setDaysPassedSinceLastCall(0)
  }

  const saveTalks = async () => {
    await save('talks', { count: fields.talks.count, lastModified: Date.now() })
    setDaysPassedSinceLastTalk(0)
  }

  const saveEmails = async () => {
    await save('emails', { count: fields.emails.count, lastModified: Date.now() })
    setDaysPassedSinceLastEmail(0)
  }

  const saveSMSes = async () => {
    await save('smses', { count: fields.smses.count, lastModified: Date.now() })
    setDaysPassedSinceLastSMS(0)
  }

  const saveIsSignUpMeetingDone = async (isSignUpMeetingDone) => {
    await save('isSignUpMeetingDone', isSignUpMeetingDone)
    setIsSignUpMeetingDone(isSignUpMeetingDone)
  }


  const save = async (field, payload) => {
    const query = { cardType: 'signUp' }
    let changingPart;

    switch (field) {
      case 'calls':
      case 'talks':
      case 'emails':
      case 'smses':
      case 'accountManager':
      case 'adHealthCheckupMeeting':
        query[`crmDetails.${field}`] = payload
        changingPart = "crmDetails"
        break;
      case 'isSignUpMeetingDone':
      case 'status':
      case 'ourNote':
        query[`signUpDetails.${field}`] = payload
        changingPart = "signUpDetails"
        break;
      default:
        break;
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${signUp.id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      let newUserData = {
        ...signUp,
        crmAlarmsSignUp: result.user.crmAlarmsSignUp,
        [changingPart]: {
          ...signUp[changingPart],
          [field]:
            typeof payload !== 'object'
              ? payload
              : {
                ...signUp[changingPart][field],
                ...payload,
              },
        },
      }
      if (payload === null || payload === undefined) {
        delete newUserData[changingPart][field]
      }

      dispatch(
        adminActions.findSignUpByIdAndUpdate({
          id: signUp.id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const getUsername = signUp => {
    let domain = signUp.shopify
      ? signUp.shopify.name || signUp.shopify.domain
      : signUp.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : signUp.crmDetails?.contactName
  }

  const copyPhoneNumber = (e) => {
    e.stopPropagation()
    if (fullPhoneNumber) {
      navigator.clipboard.writeText(fullPhoneNumber)
      toast.info('Phone number copied')
    }
  }

  function getStatusClass(status, subscriptionStatus) {
    let fastChurnSuffix = ""
    if (subscriptionStatus === "Fast Churn") {
      fastChurnSuffix = " fast-churn"
    }
    switch (status) {
      case "Touch":
        return 'touch' + fastChurnSuffix
        break;
      case "No Touch":
        return 'no-touch' + fastChurnSuffix
        break;
      default:
        return '' + fastChurnSuffix
        break;
    }
  }

  const goToDetails = () => {
    dispatch(adminActions.setNavigatedFromCustomerID(signUp.id))
    dispatch(adminActions.setCustomer(signUp))
    navigate(`/admin-panel/user-detail/${signUp.id}?isSignUp=1`)
  }

  return (
    <div
      data-id={signUp.id}
      className={`sign-up-card ${getStatusClass(signUp.signUpDetails?.status, signUp.crmDetails?.subscription?.status)}`}
      key={signUp.id}
    >
      <div className="card-header">
        <div className="section">
          <SegmentationScoreIcon userId={signUp.id} previouslySegmentationInfo={signUp.segmentationInfo} />
          {signUp.shopify && <FaShopify className="icon" />}
          <span className="username">{getUsername(signUp)}</span>
        </div>
        <div style={{ justifyContent: 'flex-end' }} className='section'>
          <div style={{ whiteSpace: 'nowrap' }}>{"Day " + getDaysPassedSince(signUp.createdAt)}</div>
          <a className='icon' style={{ color: "white" }} href={"https://" + signUp.url} target='_blank'>
            <FaLink />
          </a>
          <NotesPopup user={signUp} username={signUp.name} onClick={goToDetails} />
        </div>
      </div>
      <div className="card-content">
        <div className='section statuses'>
          <div className='signed-up-date'>🗓️ {helper.convertDateToString(signUp.createdAt)}</div>
          <div className={`signup-status ${getStatusClass(signUp.signUpDetails?.status, signUp.crmDetails?.subscription?.status)}`}>
            <QuickDropdown
              className="signup-status"
              options={
                ["Touch", "No Touch"]
              }
              emptyText={"<Not set>"}
              preSelectedOption={signUp.crmDetails?.subscription?.status === "Fast Churn" ? "Fast Churn" : signUp.signUpDetails?.status}
              onSave={async (value) => {
                await save('status', value)
              }} />
          </div>
        </div>

        <div className="section contact">
          <div className='inputs'>
            <div className="line">
              <CustomerCardInput
                label={"Calls"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.calls?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastCall)}
                onSave={async (value) => {
                  await save('calls', { count: value, lastModified: Date.now() })
                }}
              />
              <CustomerCardInput
                label={"Talks"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.talks?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastTalk)}
                onSave={async (value) => {
                  await save('talks', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
            <div className="line">
              <CustomerCardInput
                label={"Emails"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.emails?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastEmail)}
                onSave={async (value) => {
                  await save('emails', { count: value, lastModified: Date.now() })
                }}
              />
              <CustomerCardInput
                label={"SMSes"}
                userId={signUp.id}
                oldValue={signUp.crmDetails?.smses?.count}
                oldDaysPassedSinceLast={parseInt(signUp.daysPassedSinceLastSMS)}
                onSave={async (value) => {
                  await save('smses', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
            <div className='line'>
              <div className="input-checkbox">
                <label htmlFor={`meeting-done-${signUp.id}`}>Meeting done:</label>
                <Checkbox
                  name={"isSignUpMeetingDone"}
                  label={""}
                  value={signUp.signUpDetails?.isSignUpMeetingDone ?? false}
                  checked={signUp.signUpDetails?.isSignUpMeetingDone ?? false}
                  onChange={(event) => {
                    saveIsSignUpMeetingDone(event.target.value !== "true")
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section contact-info">
          <span
            className={`phone ${fullPhoneNumber ? '' : 'not-available'}`}
            onClick={copyPhoneNumber}
            title={fullPhoneNumber ? 'Click to copy' : 'No phone number info'}
          >
            <div>
              <div>
                {fullPhoneNumber ? fullPhoneNumber : 'No phone'}
                <FaCopy className="icon" />
              </div>
              {preferredCallTimeRange &&
                <span className='helper-text'>
                  <FaRegClock className='clock-icon' />{' '}
                  {preferredCallTimeRange}
                </span>
              }
            </div>
          </span>
          <div className='right-side'>
            <div className='email'><Link
              className="email"
              to={`mailto:${signUp.email}`}
              title="Send new mail"
            >
              <FaPenSquare className="icon" />
              {signUp.email}
            </Link></div>
          </div>
        </div>
        <div className="section crm-info">
          <div>
            {(!!accountManagers && Object.keys(accountManagers).length) ? <QuickDropdown
              className="account-manager"
              options={
                Object.keys(accountManagers)
              }
              preText={"Acc.Mngr: "}
              emptyText={"<Not set>"}
              preSelectedOption={signUp.crmDetails?.accountManager}
              isConvertingFromId={true}
              isShorteningName={true}
              onSave={async (value) => {
                await save('accountManager', value)
              }} /> : null}
          </div>

          <div className='country'>{signUp.crmDetails?.country || "<No country>"}</div>
        </div>
        <div className='section'>
          <AdminPanelCustomerCardAlarmPart user={signUp} findByIdAndUpdate={adminActions.findSignUpByIdAndUpdate} alarmsPath="crmAlarmsSignUp" />
        </div>
      </div>
    </div >
  )
}

export default SignUpCard
