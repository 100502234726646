
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { facebookActions } from '../../../store/facebook'
import { useHttpClient } from '../http-hook'
import analytics from '../../util/analytics'
import { useUser } from '../user-hook'
import { Link } from 'react-router-dom'

const useFacebookAudiences = () => {

    const dispatch = useDispatch()
    const { setConnectFlowStep } = useUser()

    const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
    const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
    const selectedPixel = useSelector(state => state.facebook.selectedPixel)


    const checkForAudienceErrors = ({ audiences }) => {
        let errorText;
        const adAccountAudiences = audiences[selectedAdAccount?.id]

        const { an, remarketing, lookalikeSeed } = adAccountAudiences
        let pixelFilteredRemarketing = remarketing.filter(a => a.pixelId === selectedPixel?.id)
        let pixelFilteredLookalikeSeed = lookalikeSeed.filter(a => a.pixelId === selectedPixel?.id)

        for (var index in pixelFilteredRemarketing) {
            //check if there is an error in remarketing
            if (pixelFilteredRemarketing[index].error?.code) {
                errorText = pixelFilteredRemarketing[index].error.error_user_msg ? pixelFilteredRemarketing[index].error.error_user_msg : pixelFilteredRemarketing[index].error.message

                if (pixelFilteredRemarketing[index].error?.code == 2663) {
                    let urlIndex = errorText.indexOf("https://")
                    let url = errorText.substring(urlIndex)
                    let messageText = errorText.substring(0, urlIndex)
                    errorText = <span>
                        {messageText}
                        <Link to={url}>Facebook Audience Manager</Link>
                    </span>
                }

            }
        }

        if (!errorText) {
            for (var index in pixelFilteredLookalikeSeed) {
                //check if there is an error in lookalike
                if (pixelFilteredLookalikeSeed[index].error?.code) {
                    errorText = pixelFilteredLookalikeSeed[index].error.error_user_msg
                        ? pixelFilteredLookalikeSeed[index].error.error_user_msg
                        : pixelFilteredLookalikeSeed[index].error.message
                }
            }
        }

        if (!errorText) {
            for (var index in an) {
                //check if there is an error in an

                if (an[index].error?.code) {
                    errorText =
                        an[index].error.error_subcode == 1885325
                            ? <>
                                Enhencer could not share a custom audience that is made special for you,
                                since the selected Ad Account is not owned by a Meta Business.<br /><br />
                                <span style={{ fontSize: '13px' }}>
                                    <a href={`https://business.facebook.com/settings/ad-accounts?business_id=${selectedBusiness.id}`}>Click here</a>
                                    {' '}to go to Meta Business Manager
                                </span>
                            </>
                            : `${an[index].error.error_user_msg
                                ? an[index].error.error_user_msg
                                : an[index].error.message
                            }`


                    break;
                }
            }

        }

        dispatch(facebookActions.setHasAudienceError(!!errorText))

        if (!errorText) {
            analytics.completeConnections()
            setConnectFlowStep({ step: "completedFacebookConnections" })
            return { message: "success" }
        } else {
            return { message: "error", error: errorText }
        }
    }

    return {
        checkForAudienceErrors
    }
}

export default useFacebookAudiences
