import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'
import CollapsibleCard from '../../shared/components/UIElements/CollapsibleCard'
import Input from '../../shared/components/FormElements/Input'
import { useForm } from '../../shared/hooks/form-hook'

import './CardAdminCampaignReporting.css'
import Switch from '../../shared/components/UIElements/Switch'
import dateFormatter, { getShortDateString } from '../../shared/util/dateFormatter'

function CardAdminCampaignReporting() {
  const customer = useSelector(state => state.admin.customer)
  const facebookReports = customer.facebookReports
  const [currentReport, setCurrentReport] = useState(facebookReports?.[0])
  const [ABAvailable, setABAvailable] = useState(typeof currentReport?.bAudienceLast14Days !== 'undefined')
  const enhencerCampaignOptions = customer.facebookReports?.map(
    report =>
      `${report.campaignName} - ${helper.getShortAudienceName(
        report.audienceName,
      )}`,
  )


  const [formState, inputHandler, setFormData] = useForm({
    selectedCampaign: {
      value: currentReport
        ? `${currentReport.campaignName} - ${helper.getShortAudienceName(
          currentReport.audienceName,
        )}`
        : null,
      isValid: true,
    },
    selectedComparisonType: {
      value: "Trends",
      isValid: true,
    },
  })

  const selectCampaign = value => {
    const [campaignName, audienceName] = value.split(' - ')
    const report = facebookReports?.find(
      r =>
        r.campaignName === campaignName &&
        helper.getShortAudienceName(r.audienceName) === audienceName,
    )
    setABAvailable(typeof report?.bAudienceLast14Days !== 'undefined')
    setCurrentReport(report)
  }

  useEffect(() => {
    setFormData({
      selectedCampaign: {
        value: currentReport
          ? `${currentReport.campaignName} - ${helper.getShortAudienceName(
            currentReport.audienceName,
          )}`
          : null,
        isValid: true,
      },
      selectedComparisonType: {
        value: "Trends",
        isValid: true,
      },
    })
  }, [currentReport])

  useEffect(() => {
    setCurrentReport(facebookReports?.[0])
  }, [customer])

  if (!currentReport) {
    return (
      <CollapsibleCard
        title="Campaign Reporting"
        className="amethyst campaign-reporting-card"
      >
        <p>No report found.</p>
      </CollapsibleCard>
    )
  }

  const onComparisonTypeChange = state => {
    inputHandler('selectedComparisonType', state, true)
  }

  return (
    <CollapsibleCard
      title="Campaign Reporting"
      className="amethyst campaign-reporting-card"
    >
      <div className="content-wrapper">
        <div className="dropdowns">
          <div className="input-row">
            <span>Enhencer Campaign</span>
            <Input
              type="text"
              element="dropdown"
              id="selectedCampaign"
              initialValue={formState.inputs.selectedCampaign.value}
              forceValue={formState.inputs.selectedCampaign.value}
              onInput={inputHandler}
              apply={selectCampaign}
              options={enhencerCampaignOptions}
            />
          </div>
        </div>
        {currentReport && currentReport.audience && (
          <div className="audience-details">
            <div className="audience-details-group">
              <span className="group-heading">Enhencer Audience</span>
              <div className="divider"></div>
              <div className="group-details">
                <span className="group-details-item">
                  <span>Audience Size</span>
                  <span>{numberFormatter.formatNumber(currentReport.audience.size)}</span>
                </span>
                <span className="group-details-item">
                  <span>Today's reach</span>
                  <span>{numberFormatter.formatNumber(currentReport.lastDayReach)}</span>
                </span>
              </div>
            </div>

            {currentReport.bAudienceLast14Days &&
              formState.inputs.selectedComparisonType.value === 'A/B' && (
                <div className="audience-details-group">
                  <span className="group-heading">B Audience</span>
                  <div className="divider"></div>
                  <div className="group-details">
                    <span className="group-details-item">
                      <span>Audience Size</span>
                      <span>{currentReport.audience.size}</span>
                    </span>
                  </div>
                </div>
              )}
          </div>
        )}
        <div className="report-messages">
          {currentReport.updatedAt && (
            <div className="report-message">
              <span className='gray-text'>Updated:&nbsp;{dateFormatter(new Date(currentReport.updatedAt))}</span>
            </div>
          )}
          {currentReport.campaignEffectiveStatus && (
            <div className="report-message">
              <span className='gray-text'>Campaign Status:&nbsp;</span>
              <span>{currentReport.campaignEffectiveStatus}</span>
            </div>
          )}
          {currentReport.error?.message && (
            <div className="report-message">
              <span className='gray-text'>Message:&nbsp;</span>
              <span>{currentReport.error.message}</span>
            </div>
          )}
          {currentReport.summary?.message && (
            <div className="report-message">
              <span className='gray-text'>Message:&nbsp;</span>
              <span>{currentReport.summary.message}</span>
            </div>
          )}
        </div>
        {currentReport &&
          currentReport.audience &&
          currentReport.last7Days &&
          currentReport.previous7Days && (
            <table className="comparison-table">
              <tbody>
                <tr>
                  <th>
                    <Switch
                      disabled={!ABAvailable}
                      value={formState.inputs.selectedComparisonType.value}
                      options={['A/B', 'Trends']}
                      onChange={onComparisonTypeChange}
                    />
                  </th>
                  <th>Spend</th>
                  <th>Reach</th>
                  <th>Frequency</th>
                  <th>CTR</th>
                  <th>ROAS</th>
                  <th>Revenue</th>
                  <th>CPA</th>
                </tr>
                {formState.inputs.selectedComparisonType.value ===
                  'Trends' &&
                  <React.Fragment>
                    <tr>
                      <td className='title-cell'>
                        <span>
                          Enhencer
                        </span>
                        <span className='gray-text'>
                          Last 7 days
                        </span>
                      </td>
                      <td>
                        {currentReport.last7Days.spend ?
                          <React.Fragment>
                            <span className='currency'>{currentReport.currency || 'USD'}</span>
                            <span>{numberFormatter.formatNumber(currentReport.last7Days.spend, 2)}</span>
                          </React.Fragment>
                          :
                          <span>-</span>
                        }
                      </td>
                      <td>
                        {currentReport.last7Days.reach ? numberFormatter.formatNumber(currentReport.last7Days.reach) : "-"}
                      </td>
                      <td>
                        {currentReport.last7Days.frequency ? numberFormatter.formatNumber(
                          currentReport.last7Days.frequency,
                          2,
                        ) : "-"}
                      </td>
                      <td>
                        {currentReport.last7Days.ctr ? `${numberFormatter.formatNumber(
                          currentReport.last7Days.ctr,
                          2,
                        )}%` : '-'}
                      </td>
                      <td>
                        {currentReport.last7Days.roas ? numberFormatter.formatNumber(
                          currentReport.last7Days.roas,
                          2,
                        ) : '-'}
                      </td>
                      <td>
                        {currentReport.last7Days.revenue ?
                          <React.Fragment>
                            <span className='currency'>{currentReport.currency || 'USD'}</span>
                            <span>{numberFormatter.formatNumber(currentReport.last7Days.revenue, 2)}</span>
                          </React.Fragment>
                          :
                          <span>-</span>
                        }
                      </td>
                      <td>
                        {currentReport.last7Days.costPerAction ?
                          <React.Fragment>
                            <span className='currency'>{currentReport.currency || 'USD'}</span>
                            <span>{numberFormatter.formatNumber(currentReport.last7Days.costPerAction, 2)}</span>
                          </React.Fragment>
                          :
                          <span>-</span>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td className='title-cell'>
                        <span>
                          Enhencer
                        </span>
                        <span className='gray-text'>
                          Previous 7 days
                        </span>
                      </td>
                      <td>
                        {currentReport.previous7Days.spend ?
                          <React.Fragment>
                            <span className='currency'>{currentReport.currency || 'USD'}</span>
                            <span>{numberFormatter.formatNumber(currentReport.previous7Days.spend, 2)}</span>
                          </React.Fragment>
                          :
                          <span>-</span>
                        }
                      </td>
                      <td>
                        {currentReport.previous7Days.reach ? numberFormatter.formatNumber(currentReport.previous7Days.reach) : "-"}
                      </td>
                      <td>
                        {currentReport.previous7Days.frequency ? numberFormatter.formatNumber(
                          currentReport.previous7Days.frequency,
                          2,
                        ) : '-'}
                      </td>
                      <td>
                        {currentReport.previous7Days.ctr ? `${numberFormatter.formatNumber(
                          currentReport.previous7Days.ctr,
                          2,
                        )}%` : '-'}
                      </td>
                      <td>
                        {currentReport.previous7Days.roas ? numberFormatter.formatNumber(
                          currentReport.previous7Days.roas,
                          2,
                        ) : '-'}
                      </td>
                      <td>
                        {currentReport.previous7Days.revenue ?
                          <React.Fragment>
                            <span className='currency'>{currentReport.currency || 'USD'}</span>
                            <span>{numberFormatter.formatNumber(currentReport.previous7Days.revenue, 2)}</span>
                          </React.Fragment>
                          :
                          <span>-</span>
                        }
                      </td>
                      <td>
                        {currentReport.previous7Days.costPerAction ?
                          <React.Fragment>
                            <span className='currency'>{currentReport.currency || 'USD'}</span>
                            <span>{numberFormatter.formatNumber(currentReport.previous7Days.costPerAction, 2)}</span>
                          </React.Fragment>
                          :
                          <span>-</span>
                        }
                      </td>
                    </tr>
                  </React.Fragment>
                }

                {currentReport.bAudienceLast14Days &&
                  formState.inputs.selectedComparisonType.value ===
                  'A/B' && (
                    <React.Fragment>
                      <tr>
                        <td className='title-cell'>
                          <span>
                            Enhencer
                          </span>
                          <span className='gray-text'>
                            Last 14 days
                          </span>
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.spend ?
                            <React.Fragment>
                              <span className='currency'>{currentReport.currency || 'USD'}</span>
                              <span>{numberFormatter.formatNumber(currentReport.enhencerLast14Days.spend, 2)}</span>
                            </React.Fragment>
                            :
                            <span>-</span>
                          }
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.reach ? numberFormatter.formatNumber(currentReport.enhencerLast14Days.reach) : "-"}
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.frequency ? numberFormatter.formatNumber(
                            currentReport.enhencerLast14Days.frequency,
                            2,
                          ) : "-"}
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.ctr ? `${numberFormatter.formatNumber(
                            currentReport.enhencerLast14Days.ctr,
                            2,
                          )}%` : '-'}
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.roas ? numberFormatter.formatNumber(
                            currentReport.enhencerLast14Days.roas,
                            2,
                          ) : '-'}
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.revenue ?
                            <React.Fragment>
                              <span className='currency'>{currentReport.currency || 'USD'}</span>
                              <span>{numberFormatter.formatNumber(currentReport.enhencerLast14Days.revenue, 2)}</span>
                            </React.Fragment>
                            :
                            <span>-</span>
                          }
                        </td>
                        <td>
                          {currentReport.enhencerLast14Days.costPerAction ?
                            <React.Fragment>
                              <span className='currency'>{currentReport.currency || 'USD'}</span>
                              <span>{numberFormatter.formatNumber(currentReport.enhencerLast14Days.costPerAction, 2)}</span>
                            </React.Fragment>
                            :
                            <span>-</span>
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className='title-cell'>
                          <span>
                            B Audience
                          </span>
                          <span className='gray-text'>
                            Last 14 days
                          </span>
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.spend ?
                            <React.Fragment>
                              <span className='currency'>{currentReport.currency || 'USD'}</span>
                              <span>{numberFormatter.formatNumber(currentReport.bAudienceLast14Days.spend, 2)}</span>
                            </React.Fragment>
                            :
                            <span>-</span>
                          }
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.reach ? numberFormatter.formatNumber(currentReport.bAudienceLast14Days.reach) : "-"}
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.frequency ? numberFormatter.formatNumber(
                            currentReport.bAudienceLast14Days.frequency,
                            2,
                          ) : '-'}
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.ctr ? `${numberFormatter.formatNumber(
                            currentReport.bAudienceLast14Days.ctr,
                            2,
                          )}%` : '-'}
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.roas ? numberFormatter.formatNumber(
                            currentReport.bAudienceLast14Days.roas,
                            2,
                          ) : '-'}
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.revenue ?
                            <React.Fragment>
                              <span className='currency'>{currentReport.currency || 'USD'}</span>
                              <span>{numberFormatter.formatNumber(currentReport.bAudienceLast14Days.revenue, 2)}</span>
                            </React.Fragment>
                            :
                            <span>-</span>
                          }
                        </td>
                        <td>
                          {currentReport.bAudienceLast14Days.costPerAction ?
                            <React.Fragment>
                              <span className='currency'>{currentReport.currency || 'USD'}</span>
                              <span>{numberFormatter.formatNumber(currentReport.bAudienceLast14Days.costPerAction, 2)}</span>
                            </React.Fragment>
                            :
                            <span>-</span>
                          }
                        </td>
                      </tr>
                    </React.Fragment>
                  )}
              </tbody>
            </table>
          )}
      </div>
    </CollapsibleCard>
  )
}

export default CardAdminCampaignReporting
