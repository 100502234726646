/* const fetchWithPagination = async ({ url }) => {
    let edges = []
    const next = async (nextUrl) => {
        await sendRequest(nextUrl, "GET").then(res => {
            if (res.error) {
                return { error: res.error }
            } else if (res.paging.next) {
                edges = edges.concat(res.data)
                next(res.paging.next)
            } else {
                edges = edges.concat(res.data)
                return {
                    edges: edges
                }
            }

        }).catch((err) => {
            return { error: err }
        })
    }

    await next(url)
    return edges
} */

const sortAdsetsInView = (a, b) => {
    if (a === "remarketing") {
        return -1
    }
    if (a === "lookalike" && b !== "remarketing") return -1
    if (a === "broad" && b !== "lookalike" && b !== "remarketing") return -1
    else return 1
}

export default {
    sortAdsetsInView
}