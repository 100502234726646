import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";

import FilterRow from "./FilterRow";

import "react-datepicker/dist/react-datepicker.css";
import "./Filters.css"

const Filters = props => {
    const [activeFilters, setActiveFilters] = useState([]);
    const [availableFilters, setAvailableFilters] = useState(props.availableFilters);

    useEffect(() => {
        setAvailableFilters(props.availableFilters);
    }, [props.list])

    useEffect(() => {
        setActiveFilters(props.filters)
    }, [props.filters])

    const applyFilters = () => {
        props.applyFilters(activeFilters)
    }

    const clearFilters = () => {
        setActiveFilters([])
        props.applyFilters([])
    }

    const addFilter = () => {
        let newFilters = [...activeFilters]
        newFilters.push(availableFilters[0])
        setActiveFilters(newFilters)
    }

    const removeFilter = (index) => {
        let newFilters = [...activeFilters]
        newFilters.splice(index, 1)
        setActiveFilters(newFilters)
    }

    const setFilter = (index, newFilter) => {
        let newFilters = [...activeFilters]
        newFilters[index] = newFilter
        setActiveFilters(newFilters)
    }

    return <div className="filters-box">
        <div className="row head">
            <h5>Filters</h5>
            <div className="row small">

                <div className="apply-button red" onClick={clearFilters}>Clear</div>
                <div className="apply-button" onClick={applyFilters}>Apply</div>
            </div>
        </div>
        <div>

            {activeFilters.map((filter, index) =>
                <FilterRow key={index} index={index} setFilter={setFilter} filter={filter} availableFilters={availableFilters} removeFilter={removeFilter} />
            )}

            {availableFilters.length > 0 ? <div onClick={addFilter} className="add-filter-button">
                <FaPlus />
                <span style={{margin: "5px"}}>
                    Add Filter
                </span>
            </div> :
                <div className="add-filter-button">No available filters</div>
            }
        </div>
    </div>
}

export default Filters;
