import { createSlice, current } from "@reduxjs/toolkit";
import helper from "../shared/util/helper";

const emptyProjectObject = {
  audiences: [],
  demoAudience: null,
  currentCampaignId: window.location.pathname.includes("ads-manager") ? window.location.pathname.split("/")[2] : null,
  campaigns: [],
  currentCampaign: null,
  campaignsFetched: false
};


const projectSlice = createSlice({
  name: "project",
  initialState: emptyProjectObject,
  reducers: {
    setAudiences(state, action) {
      state.audiences = action.payload.audiences;
    },
    setAudience(state, action) {
      if (action.payload.isDemo) {
        state.demoAudience = action.payload.audience;
      } else {
        let audiences = state.audiences;
        audiences[action.payload.index] = action.payload.audience;
        state.audiences = audiences;
      }
    },
    pushAudience(state, action) {
      let a = [...state.audiences]
      a.push(action.payload.audience)
      state.audiences = a
    },
    setCampaigns(state, action) {
      state.campaigns = action.payload.campaigns;
      if (!state.currentCampaignId) {
        state.currentCampaignId = action.payload.campaigns[0].id
      }
      state.campaignsFetched = true
    },
    setCampaignsFetched(state, action) {
      state.campaignsFetched = action.payload;
    },
    setAdminUserUsersCampaigns(state, action) {
      state.campaigns = action.payload.campaigns;
      state.currentCampaignId = action.payload.campaigns[0].id
    },
    setCurrentCampaign(state, action) {
      if (!state.currentCampaignId || action.payload.isDemo) {
        state.currentCampaignId = action.payload.campaign.id
      }

      state.currentCampaign = action.payload.campaign;
      let isDemo = action.payload.isDemo
      let newCampaigns = [...state.campaigns]

      let index;
      if (isDemo) {
        index = helper.findIndex(newCampaigns, "audiencePath", state.currentCampaign.audiencePath)

      } else {
        index = helper.findIndex(newCampaigns, "id", action.payload.campaign.id)

      }

      newCampaigns[index] = action.payload.campaign
      state.campaigns = newCampaigns
    },
    selectCampaign(state, action) {
      state.currentCampaignId = action.payload.id
    },
    updateCurrentCampaign(state, action) {

      let newCampaigns = [...current(state.campaigns)]
      let content = action.payload.campaign
      let isDemo = action.payload.isDemo

      let index;

      if (isDemo) {
        index = helper.findIndex(newCampaigns, "audiencePath", state.currentCampaign.audiencePath)
      } else {
        index = helper.findIndex(newCampaigns, "id", state.currentCampaignId)
      }
      let newCampaign = { ...newCampaigns[index] }

      Object.keys(content).forEach(key => {
        newCampaign[key] = content[key]
      })

      newCampaigns[index] = newCampaign
      state.campaigns = newCampaigns
      state.currentCampaign = newCampaign
    },
    updateCampaignbyId(state, action) {

      let newCampaigns = [...current(state.campaigns)]
      let content = action.payload.campaign
      let id = action.payload.id

      let index = helper.findIndex(newCampaigns, "id", id)

      let newCampaign = { ...newCampaigns[index] }

      Object.keys(content).forEach(key => {
        newCampaign[key] = content[key]
      })

      newCampaigns[index] = newCampaign
      state.campaigns = newCampaigns
    }
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
