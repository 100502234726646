import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa'

import './CallTimeRangeSelection.css'
import { useSelector } from 'react-redux'
import { useConstants } from "../../shared/hooks/constants-hook";

const CallTimeRangeSelection = props => {

    const { publicConstants } = useConstants()
	const { preferredCallTimeRanges, setPreferredCallTimeRanges } = props
	const timeRangesInHours = Object.values(publicConstants.callTimeRanges)
	const timeRanges = Object.keys(publicConstants.callTimeRanges)

	const toggleOption = (optionKey) => {
		
		let newRanges = [...preferredCallTimeRanges]
		let index = preferredCallTimeRanges.indexOf(optionKey)

		if (index > -1) {
			newRanges.splice(index, 1)
		} else {
			if (optionKey === "dontCall" || newRanges.includes("dontCall")) {
				newRanges = [optionKey]
			} else {
				newRanges.push(optionKey)
			}
		}

		setPreferredCallTimeRanges(newRanges)
	}

	return (
		<div className='call-time-range-selection-container'>
			<label>
				Our performance marketing team might reach you regarding your AI Ad campaigns. When would you prefer to be called?
			</label>
			<div
				className={`call-time-range-selection`}>
				{
					timeRangesInHours.map((timeRange, index) => {
						return <React.Fragment key={timeRange}>
							<div className={'one-time-range ' + (preferredCallTimeRanges.includes(timeRanges[index]) ? 'active' : '')} onClick={() => { toggleOption(timeRanges[index]) }}>
								{(preferredCallTimeRanges.includes(timeRanges[index]) ? < FaCheck className='icon' /> : null)}
								{timeRange}
							</div>
						</React.Fragment>
					})
				}

			</div>
		</div>

	)
}

export default CallTimeRangeSelection
