import React, { useEffect } from 'react'
import gsap from 'gsap'

const LetsDive = () => {
  useEffect(() => {
    gsap.from('.lets-dive-main', {
      duration: 1,
      y: 50,
      opacity: 0,
      ease: 'power3.out',
    })
  }, [])

  return (
    <div className="content-box lets-dive-main">
      <div className="second-big-text">
        We just showed you revenue statistics of your store.
      </div>

      <div className="second-big-text">
        Now, lets dive into the efficiency metrics on your Meta Ads campaigns.
      </div>
    </div>
  )
}

export default LetsDive
