import React from "react";
import { FaPaperclip } from "react-icons/fa";
import { useSelector } from "react-redux";
import WebsiteConnected from "../components/WebsiteConnected";
import ConnectOptions from "../components/ConnectOptions";
import { useOutletContext } from "react-router-dom";
import ShopifyConnect from "../components/ShopifyConnect";


const ConnectStore = props => {

    const user = useSelector(state => state.auth.user)
    const hasData = !!user?.connectFlow?.dataCollectionStarted
    
    const [connectProcess, processStep] = useOutletContext();
    let stepObj = connectProcess[processStep - 1] ? connectProcess[processStep - 1] : connectProcess[processStep]


    if (user.shopify) { return <ShopifyConnect hasData={hasData} /> }
    else {
        return <div className="result-card">
            <div className="card-header">
                <div className="row">
                    <FaPaperclip className="icon" />
                    <h4>{stepObj.name}</h4>
                </div>
                <div className="">

                </div>
            </div>
            <div className="card-content">
                {hasData ? <WebsiteConnected date={user.connectFlow?.dataCollectionStarted} /> : <ConnectOptions subscription={user.subscription} />}
            </div>
        </div>

    }



}

export default ConnectStore;