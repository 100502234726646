import React, { useState } from 'react'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { FaReceipt } from 'react-icons/fa'

import './UtmsPopup.css'
import helper from '../../shared/util/helper'

const UtmsPopup = props => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const demoMeeting = props.demoMeeting


  return (
    <Popover
      isOpen={isPopoverOpen}
      reposition={false}
      positions={['right', 'right', 'right', 'bottom']}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
          nudgetLeft={10}
        >
          <div
            onClick={e => e.stopPropagation()}
            className="utms-popup"
          >
            <h4 style={{ margin: "5px" }}>UTMS</h4>
            {(!demoMeeting.utms || demoMeeting.utms?.length === 0)
              ? <div className='note-container'>No utm detail present</div>
              : null}
            {(Object.keys(demoMeeting.utms ?? {})).map((utmKey) => {
              return <div key={utmKey} className='note-container'>
                <div className='note-createdat'>
                  {utmKey}
                </div>
                <div className='note-text'>
                  <div style={{ whiteSpace: "pre-wrap" }}>{demoMeeting.utms[utmKey]}</div>
                </div>
              </div>
            })}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="utms-popup-icon"
        onMouseEnter={e => {
          setIsPopoverOpen(true)
        }}
        onMouseLeave={e => {
          setIsPopoverOpen(false)
        }}
        onClick={e => {
          e.stopPropagation()
          props.onClick()
        }}
      >
        {props.child}
      </div>
    </Popover>
  )
}

export default UtmsPopup
