import React, { useEffect } from 'react'
import gsap from 'gsap'
import './UseAiAdsNow.css'
import circles from '../../shared/assets/images/ad-health-checkup/circles.svg'

const TopAds = () => {
  useEffect(() => {
    gsap.from('.use-ai-ads-now .second-big-text', {
      duration: 1,
      y: 50,
      opacity: 0,
      ease: 'power3.out',
    })
    gsap.from('.use-ai-ads-now .big-text', {
      duration: 1,
      x: -50,
      opacity: 0,
      ease: 'power3.out',
    })
    gsap.from('.use-ai-ads-now .orbits', {
      duration: 2,
      opacity: 0,
      scale: 0.5,
      ease: 'power3.out',
    })
  }, [])
  return (
    <div className="content-box use-ai-ads-now">
      <div className="second-big-text">
        Save your time and budget with AI Lookalike and AI Remarketing
      </div>
      <div className="big-text gradient-text">Use AI Ads Now!</div>
      <div className="orbits">
        <img src={circles} />
      </div>
    </div>
  )
}

export default TopAds
