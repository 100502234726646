import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useModal } from "./modal-hook";
import { authActions } from "../../store/auth";
import { useHttpClient } from "./http-hook";
import { toast } from 'react-toastify';
import Modals from "../enums/Modals";

export const useShopify = (props) => {
    const { openModal } = useModal()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isLoading, sendRequest } = useHttpClient();

    const auth = useSelector((state) => state.auth);
    let user = auth.user;
    if (props?.userInView) {
        user = props.userInView
    }

    const authHeader = {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
    }
    const [shopifyScriptId, setShopifyScriptId] = useState(user?.shopify?.scriptId);

    //before it was used for the customers that doesnt have scriptId but scriptTag
    const _getShopifyScriptId = async () => {
        try {
            const scriptResult = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/shopify/scriptTags?domain=${user.shopify.domain}&accessToken=${user.shopify.accessToken}`,
                "GET",
                null,
                {
                    Authorization: "Bearer " + auth.token,
                }
            );
            let tags = scriptResult.script_tags

            tags.forEach(tag => {
                // remove(tag.id)
                if (tag.src.includes("cdn.enhencer.com")) {
                    dispatch(authActions.updateUserObj(
                        {
                            ...auth.user,
                            shopify: {
                                ...auth.user.shopify,
                                scriptId: tag.id
                            }
                        }
                    ))
                    setShopifyScriptId(tag.id)
                }
            })

        } catch (err) {
            toast.error(err?.message || "Something went wrong")
        }
    }

    const connectShopifyStore = async () => {
        if (!shopifyScriptId) {
            try {
                let res = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/shopify/injectScript`, "post", JSON.stringify({
                    id: user.id,
                    domain: user.shopify.domain,
                    accessToken: user.shopify.accessToken,
                }),
                    {
                        Authorization: "Bearer " + auth.token,
                        "Content-Type": "application/json",
                    }
                )
                // stop loading

                //toast.success("Enhencer connected successfully to your store. Once your store is visited, you will see the details of data collection in this page.")
                dispatch(authActions.completedShopifyStoreConnection(
                    {
                        scriptId: res.tagInjectResult.script_tag.id,
                        dataCollectionStarted: res.updatedUser.connectFlow.dataCollectionStarted,
                        estimatedModelRun: res.updatedUser.connectFlow.estimatedModelRun,
                    }
                ))
                return true
            } catch (err) {
                // stop loading
                toast.error(err?.message || "Something went wrong")
            }
        } else {}
    }


    const disconnectFromShopify = async () => {
        // start loading
        try {
            let res = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/shopify/removeScript`, "post", JSON.stringify({
                id: auth.user.id,
                domain: auth.user.shopify.domain,
                accessToken: auth.user.shopify.accessToken,
                scriptId: props.scriptId
            }),
                {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                }
            )
            dispatch(authActions.updateUserObj({
                ...auth.user,
                isActive: false,
                shopify: {
                    ...auth.user.shopify,
                    scriptId: null
                }

            }))
            // stop loading
            toast.error("You have been disconnected your store from Enhencer. Your audiences won't work properly.")
            return true
        } catch (err) {
            // stop loading
            toast.error(err?.message || "Something went wrong")
        }
    }


    const getPriceRules = async () => {
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}/discounts/getPriceRulesAndDiscounts/${user.id}?accessToken=${user.shopify.accessToken}&shopDomain=${user.shopify.domain}`
            const result = await sendRequest(
                url,
                'GET',
                null,
                authHeader,
            )

            return  result
            
        } catch (err) {
            throw err
        }
    }

    const createPriceRule = async () => {
        try {
            const discountPercentage = -30
            const url = `${process.env.REACT_APP_BACKEND_URL}/discounts/generateDiscountCode`
            const result = await sendRequest(
                url,
                'POST',
                JSON.stringify({
                    userId: user.id,
                    shopDomain: user.shopify.domain,
                    accessToken: user.shopify.accessToken,
                    discountPercentage
                }),
                authHeader,
            )

        } catch (err) {
            throw err
        }
    }

    const deletePriceRule = async ({ priceRuleId }) => {
        try {
            const discountPercentage = -30
            const url = `${process.env.REACT_APP_BACKEND_URL}/discounts/deletePriceRule`
            const result = await sendRequest(
                url,
                'POST',
                JSON.stringify({
                    priceRuleId,
                    userId: user.id,
                    shopDomain: user.shopify.domain,
                    accessToken: user.shopify.accessToken,
                }),
                authHeader,
            )


        } catch (err) {
            throw err
        }
    }

    const canContinueToSubscription = ({ shouldShowModal }) => {
        return true
        if (!user.subscription && user?.shopify?.ordersCount < 2 && !user.shopify.ignoresOrderCountLimit) {
            if (shouldShowModal) {
                const modalConfig = {
                    title: 'Your store may not be ready yet',
                    imageUrl:
                        'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
                    text: <span>We are sorry to tell that you cannot use Enhencer, since your store seems to have too low traffic. <br></br><br></br> You can come back once you have at least 5 orders in the past month. If you have any further questions, please contact us at <a href="mailto: hello@enhencer.com">hello@enhencer.com</a>.</span>,
                    actions: [
                        {
                            text: 'Close',
                            callback() {
                                navigate("/")
                            },
                        },
                    ],
                }
                openModal({
                    type: Modals.GENERIC_MODAL,
                    data: modalConfig
                })
            }
            return false
        } else {
            return true
        }
    }

    return {
        _getShopifyScriptId,
        connectShopifyStore,
        disconnect: disconnectFromShopify,
        canContinueToSubscription,
        getPriceRules,
        createPriceRule,
        deletePriceRule
    }

};