import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { adminActions } from '../../store/admin'

import { useHttpClient } from './http-hook'
import { adHealthCheckupActions } from '../../store/ad-health-checkup'
import errorHandler from '../util/errorHandler'

const usePermissionHook = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()

  const authToken = useSelector(state => state.auth.token)
  const userAccessDetails = useSelector(state => state.auth.user.accessDetails)
  const role = userAccessDetails?.role ? userAccessDetails.role : 'Basic'
  const permissions = userAccessDetails.permissions
    ? userAccessDetails.permissions
    : []

  //   const authHeader = {
  //     Authorization: 'Bearer ' + authToken,
  //     'Content-Type': 'application/json',
  //   }
  const getSpecialUsers = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getSpecialUsers`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )
      dispatch(adminActions.setSpecialUsers(responseData.users))
      return responseData.users
    } catch (err) {
      errorHandler(err)
      throw err
    }
  }

  const checkPermission = async permission => {

    if (permissions.includes(permission)) {
      return true
    } else {
      toast.warn('You do not have permission to making changes in this field.')
      return false
    }
  }

  return {
    checkPermission,
    getSpecialUsers,
  }
}

export default usePermissionHook
