import { createSlice } from '@reduxjs/toolkit'

const emptyAuthObject = {
  user: null,
}

// TODO: Separate auth and user actions.
// This slice is currently not in use.

const userSlice = createSlice({
  name: 'authentication',
  initialState: emptyAuthObject,
  reducers: {
    setUser(state, action) {
      state.user = action.payload
    },
  },
})

export const userActions = userSlice.actions

export default userSlice.reducer
