import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import Input from "../FormElements/Input";
import { useForm } from "../../hooks/form-hook";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./FilterRow.css"
import CustomDatePicker from "./CustomDatePicker";
import CRMReportFilterTypes from "../../enums/CRMReportFilterTypes";

const FilterRow = props => {
    const [filter, setFilter] = useState(props.filter)

    useEffect(() => {
        setFormData({
            variable: {
                value: props.filter.name,
                isValid: true
            },
            operator: {
                value: props.filter.operator || filter.operators[0],
                isValid: true
            },
            value: {
                value: props.filter.option || (filter.options ? filter.options[0] : null),
                isValid: true
            }
        }, true)
        setFilter(props.filter)
    }, [props.filter])

    const [formState, inputHandler, setFormData] = useForm({
        variable: {
            value: filter.name,
            isValid: true
        },
        operator: {
            value: filter.operator || filter.operators[0],
            isValid: true
        },
        value: {
            value: filter.option || (filter.options ? filter.options[0] : null),
            isValid: true
        },
    }, true);

    const setVariable = (newName) => {
        props.availableFilters.forEach(filter => {
            if (filter.name === newName) {
                props.setFilter(props.index, {
                    ...filter,
                    variable: newName,
                    operator: filter.operators[0],
                    option: filter.options ? filter.options[0] : null
                })
            }
        })
    }
    const setOperator = (newOperator) => {
        props.setFilter(props.index, {
            ...filter,
            operator: newOperator,
        })

    }
    const setValue = (newValue) => {
        props.setFilter(props.index, {
            ...filter,
            option: newValue,
        })
    }

    const startDateChangeHandler = (val) => {
        props.setFilter(props.index, {
            ...filter,
            option: {
                ...filter.option,
                start: val
            },
        })
    }

    const endDateChangeHandler = (val) => {
        props.setFilter(props.index, {
            ...filter,
            option: {
                ...filter.option,
                end: val
            },
        })
    }



    return <div className="filter-row">
        <div className="remove-filter" onClick={() => { props.removeFilter(props.index) }}>
            <FaTrash />
        </div>
        <div className="filter-inner-cont row">
            <Input element="dropdown"
                id={`${filter.key}_variable`}
                // label="Variable"
                initialValue={formState.inputs.variable.value}
                onInput={inputHandler}
                options={props.availableFilters.map(f => f.name)}
                apply={setVariable}
                placeholder="Variable"
            />

            {filter.type !== CRMReportFilterTypes.DATE && filter.type !== CRMReportFilterTypes.UTM_SOURCE && filter.type !== CRMReportFilterTypes.FUNNEL && <Input element="dropdown"
                id={`${filter.key}_operator`}
                // label="Operator"
                initialValue={formState.inputs.operator.value}
                onInput={inputHandler}
                options={filter.operators}
                apply={setOperator} />
            }

            {filter.type === 0 || filter.type === CRMReportFilterTypes.STRING || filter.type === CRMReportFilterTypes.REGISTRATION_SOURCE || filter.type === CRMReportFilterTypes.OBJECT_ID ||  filter.type === CRMReportFilterTypes.UTM_SOURCE || filter.type === CRMReportFilterTypes.FUNNEL || filter.type === 7 || filter.type === 8 ?
                <Input element="dropdown"
                    id={`${filter.key}_value`}
                    // label="Value"
                    initialValue={formState.inputs.value.value}
                    onInput={inputHandler}
                    options={filter.options}
                    apply={setValue} />
                : filter.type === CRMReportFilterTypes.NUMERIC ?
                    <Input element="input"
                        type="number"
                        id={`${filter.key}_value`}
                        // label="Value"
                        placeholder="Value"
                        initialValue={formState.inputs.value.value}
                        onInput={inputHandler}
                        apply={setValue} />
                    :
                    filter.type === CRMReportFilterTypes.DATE &&
                    <div className="row">
                        <CustomDatePicker placeholder="Start" date={filter.option?.start} onChange={startDateChangeHandler} />
                        <CustomDatePicker placeholder="End" date={filter.option?.end} onChange={endDateChangeHandler} />
                    </div>
                // <DatePicker
                //     placeholderText="Select range"
                //     dateFormat="dd/MM/yyyy"
                //     selectsRange={true}
                //     startDate={filter.option?.start}
                //     endDate={filter.option?.end}
                //     onChange={dateChangeHandler}
                //     withPortal
                // />
            }
        </div>
    </div>
}

export default FilterRow;