import { createSlice } from '@reduxjs/toolkit'

const initialGeneralState = {
  pageTitle: '',
  companyTitle: '',
  bottomNotification: {},
  loggedOutFromAccountNotification: ""
}

const generalSlice = createSlice({
  name: 'general',
  initialState: initialGeneralState,

  reducers: {
    setCompanyTitle(state, action) {
      state.companyTitle = action.payload.companyTitle
    },
    setPageTitle(state, action) {
      state.pageTitle = action.payload.pageTitle
    },
    setBottomNotification(state, action) {
      state.bottomNotification = action.payload
    },
    setAnStats(state, action) {
      state.anStats = action.payload
    },
    showLoggedOutFromAccountNotification(state, action) {
      state.loggedOutFromAccountNotification = action.payload
    },
  },
})

export const generalActions = generalSlice.actions

export default generalSlice.reducer
