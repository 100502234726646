import React from 'react'

import AIAdsPageLayout from '../components/AIAdsPageLayout'
import FullFunnelCampaignsCard from '../components/FullFunnelCampaignsCard'
import CampaignReport from '../components/CampaignReport'
import SidebarContent from '../components/SidebarContent'

import './AIAds.css'
import YourHistoryCard from '../components/YourHistoryCard'

const AIAds = () => {

  return (
    <AIAdsPageLayout>
      <AIAdsPageLayout.Content>
        <div className="ai-ads-home-grid">
          <FullFunnelCampaignsCard />
          <YourHistoryCard />
          <CampaignReport />
        </div>
      </AIAdsPageLayout.Content>
      <AIAdsPageLayout.Sidebar>
        <SidebarContent />
      </AIAdsPageLayout.Sidebar>
    </AIAdsPageLayout>
  )
}

export default AIAds
