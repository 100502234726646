export default (ctx, color, height) => {
    if (ctx) {
        const colorStart = color;
        const colorEnd = "white"

        const gradient = ctx.createLinearGradient(0, 0, 0, height ?? 200);

        gradient.addColorStop(0, colorStart);
        if (window.innerWidth > 1400) {
            gradient.addColorStop(0.1, colorStart);
            gradient.addColorStop(1, colorEnd);
        } else {
            gradient.addColorStop(0.7, colorEnd);

        }
        return gradient;
    }
    return
}