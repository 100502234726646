import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import { FaTimes } from 'react-icons/fa'
import './BeforeYouCancel.css'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import MeetingIcon from '../../assets/images//icons/meeting-icon.svg'
import ChangePlanIcon from '../../assets/images/icons/change-plan.png'
import analytics from '../../util/analytics'
import { getPriceFromPlanNameForShopify } from '../../util/subscription'
import dateFormatter from '../../../shared/util/dateFormatter'
import Modals from '../../enums/Modals'

const BeforeYouCancel = props => {
  const { closeModal } = useModal()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  const performanceManagerProfile = user?.crmDetails?.performanceManagerProfile

  const planName = user.subscription?.plan
  const [subscriptionDetails, setSubscriptionDetails] = useState()

  function getPlanDetails(user) {
    let ret = {
      name: user.subscription.plan,
      price: 'No price detail',
      paymentInterval: user.subscription.paymentInterval,
    }
    if (user.subscription.price && user.subscription.currency) {
      ret.price =
        user.subscription.price +
        (user.subscription.currency === 'TL' ? '₺' : '$')
    } else if (user.subscription.customPlan) {
      ret.price =
        user.subscription.customPlan.price +
        (user.subscription.customPlan.currency === 'TL' ? '₺' : '$')
    }

    return ret
  }

  const getSubscriptionDetails = user => {
    let subscribed
    let activePlan = { name: '', price: '' }
    let endDate = new Date()
    let error = ''
    let paymentInterval = ''

    if (
      user.subscription &&
      user.subscription.status === 'active' &&
      !user.shopify
    ) {
      subscribed = true
      let d = new Date(user.subscription.createdDate)
      endDate = d.setFullYear(d.getFullYear() + 1)
      activePlan = getPlanDetails(user)
      paymentInterval = user.subscription.paymentInterval
    } else if (
      user.subscription &&
      user.subscription.status === 'active' &&
      user.shopify
    ) {
      subscribed = true
      let d = new Date(user.subscription.createdDate)
      endDate = d.setFullYear(d.getFullYear() + 1)
      activePlan = {
        name: user.subscription.plan,
        price: user.subscription.price
          ? user.subscription.price.toString() + '$'
          : getPriceFromPlanNameForShopify({
            planName: user.subscription.plan,
            version: user.subscription.pricingVersion
          }),
      }
      paymentInterval = user.subscription.paymentInterval
    } else if (
      user.subscription &&
      (user.subscription.status === 'payment-error' ||
        user.subscription.status === 'past_due' ||
        user.subscription.status === 'suspended')
    ) {
      subscribed = true
      activePlan = getPlanDetails(user)
      paymentInterval = user.subscription.paymentInterval
      error = 'Your payment could not be processed'
      if (user.subscription.lastPaymentDate) {
        error += ' on '
        error += dateFormatter(user.subscription.lastPaymentDate, true)
      }
      error += '.'
    } else {
      subscribed = false
    }

    return {
      error: error,
      subscribed: subscribed,
      hasCustomPlan: user.subscription && !!user.subscription.customPlan,
      customPlan: user.subscription?.customPlan,
      endDate: endDate,
      activePlan: activePlan,
      paymentInterval: paymentInterval,
    }
  }

  useEffect(() => {
    let details = getSubscriptionDetails(user)
    setSubscriptionDetails(details)
  }, [])

  useEffect(() => {
    if (window.Calendly) {
      window.Calendly.initInlineWidget({
        url: process.env.REACT_APP_CALENDLY_DEMO_MEETING_LINK,
        parentElement: document.getElementById('calendlyMyDiv'),
        prefill: {
          name: user?.name,
          email: user?.email,
        },
      })
    }
  }, [user])

  const scheduleMeeting = () => {
    closeModal()

    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: performanceManagerProfile?.meetingUrl || process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_LINK,
      })
    }

    analytics.bookASupportMeeting()
  }

  const close = () => {
    closeModal()
    navigate('/account/subscription')
  }

  const continueToCancel = () => {
    closeModal()

    openModal({
      type: Modals.CANCEL_SUBSCRIPTION,
      closeOnClickOutside: true,
      replaceAll: true
    })
  }

  const upgradePlan = () => {
    closeModal(() => {
      navigate('/subscription/update-plan', {
        state: {
          subscriptionDetails: subscriptionDetails,
          user: user,
          isFromShopify: user?.shopify !== undefined,
        },
      });
    });
  };
  return (
    <div className="before-you-cancel-modal">
      <div className="close-button" onClick={closeModal}>
        <FaTimes />
      </div>
      <div className="column">
        <h2>Before you cancel, maybe we can help </h2>

        <div className="boxes">
          <div className="box">
            <div className="row">
              <img src={MeetingIcon}></img>

              <p>
                <span>Book a support meeting </span> <br />
                Book a support meeting now to get professional support in
                launching your AI Ads or discussing any next steps on your
                journey to success
              </p>
            </div>

            <Button onClick={scheduleMeeting}>Schedule</Button>
          </div>

          {user.shopify ? (
            <div className="box">
              <div className="row">
                <img src={ChangePlanIcon}></img>

                <p>
                  <span>Change your plan </span>
                  <br />
                  Unsatisfied with your plan? Try another one.
                </p>
              </div>

              <Button onClick={upgradePlan}>Change Plan</Button>
            </div>
          ) : null}
        </div>

        <div className="content">
          <p>You are currently on an {planName} Package in AI Ads</p>
          <p>
            Boost your ad performance by 400% when you create an AI Ad Campaign,
            our campaigns increase your user base, retarget to the best
            customers and generate awareness for your brand.
          </p>
        </div>

        <div className="buttons-row">
          <Button className="gradient-button" onClick={close}>
            Save Money
          </Button>
          <Button className="white-button" onClick={continueToCancel}>
            Continue to Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BeforeYouCancel
