import React, { useState, useEffect } from 'react'
import {
  FaUser,
  FaCog,
  FaCreditCard,
  FaListAlt,
  FaAngleRight,
  FaAngleLeft,
  FaEnvelope,
} from 'react-icons/fa'

import {
  NavItems,
  Sidebar,
  SidebarHeader,
} from '../../shared/components/Navigation/Sidebar'

import './ProfilePageLayout.css'

const ProfilePageLayout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    if (windowWidth >= 768) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [windowWidth])

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  const navItems = [
    {
      to: '/account/info',
      title: 'Info',
      icon: <FaUser />,
    },
    {
      to: '/account/tickets',
      title: 'Support Tickets',
      icon: <FaEnvelope />,
    },
    {
      to: '/account/subscription',
      title: 'Subscription',
      icon: <FaCreditCard />,
    },
    {
      to: '/account/industry-selection',
      title: 'Industry Selection',
      icon: <FaListAlt />,
    },
    {
      to: '/account/settings',
      title: 'Account Settings',
      icon: <FaCog />,
    },
  ]

  return (
    <div className="profile">
      <div className={`profile-sidebar-wrapper ${isOpen ? 'open' : 'closed'}`}>
        <Sidebar isOpen={isOpen}>
          <SidebarHeader>Account Management</SidebarHeader>
          <NavItems items={navItems} />
          <button className="sidebar-toggle-button" onClick={toggleSidebar}>
            {isOpen ? <FaAngleLeft /> : <FaAngleRight />}
          </button>
        </Sidebar>
      </div>
      <div className="profile-content-wrapper">{children}</div>
    </div>
  )
}

const Header = ({ children }) => {
  return (
    <div className="profile-header">
      <h1>{children}</h1>
      <div className="line"></div>
    </div>
  )
}

const Content = ({ children }) => {
  return <div className="profile-content">{children}</div>
}

ProfilePageLayout.Header = Header
ProfilePageLayout.Content = Content

export default ProfilePageLayout
