import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { adminActions, resettedTicketFilters } from '../../store/admin'
import { useAdmin } from '../../shared/hooks/admin-hook'
import SupportTicketsList from '../components/SupportTicketsList'
import FiltersSection from '../components/FiltersSection'

import './SupportTickets.css'
import helper from '../../shared/util/helper'

const SupportTickets = () => {
  const dispatch = useDispatch()
  const { tickets, ticketFilters, performanceManagers } = useSelector(state => state.admin)
  const auth = useSelector(state => state.auth)
  const { search, state } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const userId = query.get('uid')
  const unreadMessages = query.get('unreadMessages')

  const { getTickets } = useAdmin()

  const [isLoading, setIsLoading] = useState(false)
  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const [searchText, setSearchText] = useState(
    JSON.parse(sessionStorage.getItem('supportTicketsSearch')) || '',
  )
  const [tempFilter, setTempFilter] = useState(ticketFilters)

  useEffect(() => {
    const savedFilters = sessionStorage.getItem('ticketFilters')

    if (savedFilters) {
      const initialFilter = JSON.parse(savedFilters)

      let filter = initialFilter
      if (userId) filter = { ...filter, user: userId }
      if (unreadMessages) filter = { ...filter, unreadMessages: true }

      getTicketsData({ freshFetch: true, filter })
    } else {
      const filter = {}

      if (userId) filter.user = userId
      if (unreadMessages) filter.unreadMessages = true

      getTicketsData({ freshFetch: true, filter })
    }
  }, [userId, unreadMessages])

  useEffect(() => {
    setTempFilter(ticketFilters)
  }, [ticketFilters])

  const handleFilterChange = ({ groupName, type, value, checked }) => {
    switch (type) {
      case 'switch':
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break
      case 'checkbox':
        if (groupName === 'performanceManager') {
          const id = helper.findBy(
            Object.values(performanceManagers),
            'name',
            value,
          )._id

          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked
                ? [...prevGroup, id]
                : prevGroup.filter(item => item !== id),
            }
          })
        } else {
          setTempFilter(prevFilter => {
            const { user, ...filter } = prevFilter
            const prevGroup = filter[groupName]
            return {
              ...filter,
              [groupName]: checked
                ? [...prevGroup, value]
                : prevGroup.filter(item => item !== value),
            }
          })
        }
        break
      case 'date-range':
        setTempFilter(prevFilter => {
          const { user, ...filter } = prevFilter
          return {
            ...filter,
            [groupName]: value,
          }
        })
        break
      default:
        break
    }

    setIsFilterChanged(true)
  }

  const handleApplyFilter = () => {
    let filter = tempFilter

    if (userId) filter = { ...filter, user: userId }
    if (unreadMessages) filter = { ...filter, unreadMessages: true }

    dispatch(adminActions.setTicketFilters(filter))
    dispatch(adminActions.setTickets([]))
    sessionStorage.setItem('ticketFilters', JSON.stringify(tempFilter))
    getTicketsData({ freshFetch: true, filter })
    setIsFilterChanged(false)
  }

  const resetFilters = () => {
    let filter = resettedTicketFilters

    if (userId) filter = { ...filter, user: userId }
    if (unreadMessages) filter = { ...filter, unreadMessages: true }

    setTempFilter(filter)
    dispatch(adminActions.resetTicketFilters())
    sessionStorage.setItem(
      'ticketFilters',
      JSON.stringify(filter),
    )
    getTicketsData({ freshFetch: true, filter })
    setIsFilterChanged(false)
  }

  const getTicketsData = async ({
    freshFetch = false,
    filter = ticketFilters,
    search = searchText,
  }) => {
    setIsLoading(true)
    await getTickets({ freshFetch, filter, search })
    setIsLoading(false)
  }

  const doSearch = async text => {
    dispatch(adminActions.setTickets([]))
    setSearchText(text)
    getTicketsData({ freshFetch: true, search: text })
    sessionStorage.setItem('supportTicketsSearch', JSON.stringify(text))
  }

  return (
    <div className="support-tickets-container">
      <div className="layout">
        <div className="content">
          <h1 className="heading">
            {state?.username ? `Tickets of ${state?.username}` : 'All tickets'}
            {state?.username ? <Link to="/admin-panel/support-tickets" style={{ fontSize: ".5em", marginLeft: "1em" }}>View All</Link> : null}
          </h1>
          {!isLoading && !tickets.length ? (
            <h2 className="not-found-text">
              No tickets found for these filters.
            </h2>
          ) : (
            <SupportTicketsList
              isFetching={isLoading}
              reFetch={() => {
                getTicketsData({ freshFetch: false })
              }}
            />
          )}
        </div>
        <FiltersSection
          page = 'supportTickets'
          isFilterChanged={isFilterChanged}
          handleApplyFilter={handleApplyFilter}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          doSearch={doSearch}
          searchText={searchText}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />
      </div>
    </div>
  )
}

export default SupportTickets
