import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import CampaignsPageLayout from '../components/CampaignsPageLayout'
import Campaign from '../components/Campaign'
import CampaignDetail from '../components/CampaignDetail'
import helper from '../../shared/util/helper'

import './FullFunnelCampaigns.css'

const FullFunnelCampaigns = () => {
  const user = useSelector(state => state.auth.user)
  const { enhCampaigns, campaignInView, connectionPageIsReady } = useSelector(
    state => state.facebook,
  )

  if (
    (!user?.isSpecialUser && !user.connectionsCompleted) ||
    !connectionPageIsReady
  ) {
    return <div></div> // TODO: Show a better result (e.g., toast, redirection, error page, etc.)
  }

  return (
    <CampaignsPageLayout>
      {campaignInView ?
        <CampaignDetail campaign={campaignInView} key={campaignInView._id} />
       : enhCampaigns.length ? (
        <React.Fragment>
          {enhCampaigns?.map(c => (
            <Campaign campaign={c} key={c._id} />
          ))}
        </React.Fragment>
      ) : (
        <div className="no-campaigns-text">
          You do not have any Enhencer campaigns. Create one{' '}
          <Link to="/ai-ads/full-funnel-campaigns/create">here</Link>.
        </div>
      )}
    </CampaignsPageLayout>
  )
}

export default FullFunnelCampaigns
