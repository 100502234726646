import React, { useState } from 'react'
import './TemplateList.css'
import { FaCheck } from 'react-icons/fa'

const TemplateList = () => {
  const [selectedItem, setSelectedItem] = useState(null)

  const handleItemClick = index => {
    setSelectedItem(index)
  }

  return (
    <div className="template-list-main">
      <div className="template-list">
        {[...Array(20)].map((_, index) => (
          <div
            key={index}
            className={`template-item ${selectedItem === index ? 'selected' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            <div className="select-item"></div>
          </div>
        ))}
      </div>
      {selectedItem !== null && ( // selectedItem seçili ise Save butonu gösterilecek
        <button className="template-save-button">
          Save
          <FaCheck className="save-check"></FaCheck>
        </button>
      )}
    </div>
  )
}

export default TemplateList
