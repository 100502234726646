import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";

import Filters from "../../shared/components/UIElements/Filters";
import { useConstants } from "../../shared/hooks/constants-hook";

import "./ReportFilter.css"

const ReportFilter = props => {
  const { privateConstants: constants } = useConstants()
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [list, setList] = useState(props.list);

  const accountManagersArray = Object.values(props.accountManagerOptions)
  let namesOfAccountManagers = accountManagersArray.map(manager => manager.name)
  const performanceManagersArray = Object.values(props.performanceMarketingOptions)
  let namesOfPerformanceManagers = performanceManagersArray.map(manager => manager.name)


  let availableFilters = constants.salesPanelCustomerFilters;

  availableFilters = availableFilters.map(filter => {
    switch (filter.key) {
      case 'crmDetails.leadPerson':
        return {
          ...filter,
          options: props.leadGenerationOptions,
          option: props.leadGenerationOptions[0],
        }
      case 'crmDetails.inboundType':
        return {
          ...filter,
          options: props.inboundTypeOptions,
          option: props.inboundTypeOptions[0],
        }
      case 'crmDetails.partner':
        return {
          ...filter,
          options: props.partnerOptions,
          option: props.partnerOptions[0],
        }
      case 'crmDetails.accountManager':
        return {
          ...filter,
          options: namesOfAccountManagers,
          option: namesOfAccountManagers[0],
        }
      case 'crmDetails.performanceManager':
        return {
          ...filter,
          options: namesOfPerformanceManagers,
          option: namesOfPerformanceManagers[0],
        }
      case 'crmDetails.performanceReporter':
        return {
          ...filter,
          options: namesOfPerformanceManagers,
          option: namesOfPerformanceManagers[0],
        }

      default:
        return filter
    }
  })

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen)
  }

  useEffect(() => {
    if (filtersOpen) {
      document.addEventListener("mousedown", handleClickOutside);

    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [filtersOpen])

  const handleClickOutside = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    let inside = false;
    path.forEach((elem) => {
      if (
        elem.classList !== undefined && elem.classList.contains("filters-box") ||
        elem.classList !== undefined && elem.classList.contains("filters")
      ) {
        inside = true;
      }
    });

    if (!inside && filtersOpen) {
      toggleFilters();
    }
  };

  const applyFilters = (filters) => {
    props.applyFilters(filters)
    setFiltersOpen(false)
  }

  useEffect(() => {
    setList(props.list)
  }, [props.list])

  return <div className="filters">
    <button className={filtersOpen ? "active filter-button" : "filter-button"} title="Filter" onClick={toggleFilters}>
      {props.filters.length > 0 && <div className="count-badge">{props.filters.length}</div>}
      <FaFilter />

    </button>
    {filtersOpen && <Filters className="filters-box" list={list} filters={props.filters} availableFilters={availableFilters} applyFilters={applyFilters} />}
  </div>
}

export default ReportFilter;