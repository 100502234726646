import React, { useEffect, useState } from 'react'

import './Payment.css'
import { useDispatch } from 'react-redux'
import { generalActions } from '../../store/general'
import { Link, useLocation } from 'react-router-dom'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import Button from '../../shared/components/FormElements/Button'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

const UpdateCreditCard = props => {
  const dispatch = useDispatch()
  const [checkoutFormVisible, setCheckoutFormVisible] = useState(true)
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    const pageTitle = 'Update Credit Card'
    dispatch(generalActions.setPageTitle({ pageTitle }))

    const params = new URLSearchParams(location.search)
    if (
      parseInt(params.get('success')) === 1 &&
      localStorage.getItem('iyziUpdateToken') === params.get('token')
    ) {
      setUpdateSuccess(true)
    } else if (parseInt(params.get('success')) === 0) {
      setUpdateSuccess(false)
    }
    localStorage.setItem('iyziUpdateToken', null)

    if (location.state?.checkoutFormContent) {
      let oldElem = document.getElementById('iyzico_script')
      if (oldElem != null) {
        oldElem.remove()
        window.iyziInit = undefined
      }

      let codes = location.state.checkoutFormContent.split('</script>')
      let c1 = codes[0].replace('<script type="text/javascript">', '')
      let c2 = codes[1].replace('<script type="text/javascript">', '')
      let c3 = codes[2].replace('<script type="text/javascript">', '')
      let all = c1 + c2 + c3

      let elem = document.createElement('script')
      elem.id = 'iyzico_script'
      elem.innerHTML = all
      document.body.appendChild(elem)
      setCheckoutFormVisible(true)
    }
    setLoading(false)
  }, [])

  return (
    <div className="update-card payment">
      {checkoutFormVisible ? (
        <div>
          <div className="header-row" style={{ maxWidth: '500px' }}>
            <h3 style={{ fontWeight: '500' }}>
              Enter new credit card information
            </h3>
            <Link
              to="/subscription"
              className="cancel-payment"
              style={{ fontSize: '1em' }}
            >
              <FaTimes />
              Cancel
            </Link>
          </div>
          <div>
            <div id="iyzipay-checkout-form" className="responsive"></div>
          </div>
        </div>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {updateSuccess ? (
            <div className="row">
              <FaCheckCircle className="icon" />
              <span>Card updated successfully.</span>
            </div>
          ) : (
            <div className="row">
              <FaTimes className="icon red" />
              <span>Update failed. Please contact us.</span>
            </div>
          )}
          <Button to="/subscription">Back to subscription</Button>
        </div>
      )}
    </div>
  )
}

export default UpdateCreditCard
