import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaEye, FaInfoCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo-white.png'
import metaLogo from '../../shared/assets/images/icons/meta-logo.png'
import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'
import FacebookDatePicker from '../../shared/components/UIElements/FacebookDatePicker'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import Input from '../../shared/components/FormElements/Input'
import dateFormatter from '../../shared/util/dateFormatter'

import './CampaignReportMobile.css'

const CampaignReportMobile = ({
  filter,
  setNewFilter,
  isSample,
  isFetching,
  currencySymbol,
  overallReport,
  reportsInView,
  formState,
  inputHandler,
  campaignLabels,
  campaignSelected,
  campaignStatus,
  goToReportDetails,
  errors,
}) => {
  const [report, setReport] = useState(reportsInView ? reportsInView[0] : {})

  useEffect(() => {
    if (reportsInView) setReport(reportsInView[0])
  }, [reportsInView])

  const onAudienceChange = e => {
    const audience = e.target.value
    const report = helper.findBy(reportsInView, 'audienceType', audience)
    setReport(report)
  }

  const getError = (level, audienceType) => {
    if (!audienceType) {
      return errors?.[level]
    }

    return errors?.[level]?.[audienceType.toLowerCase()]
  }

  return (
    <div className="mobile-campaign-report-wrapper">
      {!isSample && (
        <div className="filter">
          <FacebookDatePicker setFilters={setNewFilter} filter={filter} />
          <FaCaretDown className="down-arrow-icon" />
        </div>
      )}
      <div
        className={`mobile-campaign-report ${isSample ? 'sample' : ''}`}
        onClick={goToReportDetails}
      >
        <div className="card-header">
          <div className="logo">
            <img
              src={enhencerLogo}
              alt="Enhencer"
              className="enhencer-logo"
              width={110}
            />
            {!isSample ? (
              <div className="region-dropdown">
                <Input
                  element="dropdown"
                  placeholder="Campaign label"
                  initialValue={formState.inputs.campaignLabel.value}
                  forceValue={formState.inputs.campaignLabel.value}
                  id="label"
                  type="text"
                  options={campaignLabels}
                  onInput={inputHandler}
                  apply={campaignSelected}
                />
              </div>
            ) : (
              <div className="sample-text">
                SAMPLE REPORT
                <Tooltip content="The data displayed here is for demonstration purposes and represents sample data. Real data will become available once you start creating your campaigns with Enhencer.">
                  <FaInfoCircle className="icon" />
                </Tooltip>
              </div>
            )}
          </div>
          {!isFetching && (
            <React.Fragment>
              <div className="amount">
                <span>
                  {currencySymbol}
                  {numberFormatter.formatNumber(overallReport.spend)}
                </span>
                <span>Total ad-cost</span>
              </div>
              <div className="amount">
                <span>
                  {currencySymbol}
                  {numberFormatter.formatNumber(overallReport.sales)}
                </span>
                <span>Total sales</span>
              </div>
              <div className="amount">
                <span>{overallReport.roas || '-'}</span>
                <span>ROAS</span>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="card-content">
          {report?.noReport ? (
            report.element
          ) : getError('adCampaign') ? (
            <div className="error-container center">
              <span className="error-user-title">
                {`Campaign Error: ${getError('adCampaign')?.error_user_title}`}
              </span>
              <p className="error-user-message">
                {getError('adCampaign').error_user_msg}
                {` (#${getError('adCampaign')?.error_subcode})`}
              </p>
            </div>
          ) : !isFetching && campaignStatus && campaignStatus !== 'ACTIVE' ? (
            <div className="error-container center">
              <span className="error-user-title">Campaign is not active!</span>
              <p className="error-user-message">
                We can't show the reports for the current campaign since it is{' '}
                {campaignStatus.toLowerCase()}.
              </p>
            </div>
          ) : isFetching ? (
            <InlineLoadingSpinner message="Fetching ad reports..." />
          ) : (
            report && (
              <React.Fragment>
                <div className="audience">
                  <select onChange={onAudienceChange}>
                    {reportsInView?.map(
                      report =>
                        !report.noReport && (
                          <option
                            value={report.audienceType}
                            key={report.audienceType}
                          >
                            {`${report.audienceType
                              .charAt(0)
                              .toUpperCase()}${report.audienceType.slice(
                              1,
                            )} AI Audience`}
                          </option>
                        ),
                    )}
                  </select>
                  <div className="campaign-info">
                    <div className="line">
                      <img
                        className="meta-icon"
                        src={metaLogo}
                        alt="Meta"
                        width={18}
                      />
                      <span>Conversion Campaign</span>
                    </div>
                    <div className="line">
                      <span className="minor-text">
                        Status:{' '}
                        <span className="value">{report.effectiveStatus}</span>
                      </span>
                      {report.startTime && (
                        <span className="minor-text">
                          Start time:{' '}
                          <span className="value">
                            {dateFormatter(report.startTime, true)}
                          </span>
                        </span>
                      )}
                    </div>
                    {report.audienceType?.toLowerCase() === 'remarketing' && (
                      <div className="line">
                        <p className="description">
                          Please be aware that it may take a few days for
                          remarketing ad set to pass the learning phase.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {getError('adsets', report.audienceType) ? (
                  <div className="error-container center">
                    <span className="error-user-title">
                      Error:{' '}
                      {getError('adsets', report.audienceType)
                        ?.error_user_title ||
                        getError('adsets', report.audienceType)?.code}
                    </span>
                    <p className="error-user-message">
                      {getError('adsets', report.audienceType)
                        ?.error_user_msg ||
                        getError('adsets', report.audienceType)?.message}
                      {getError('adsets', report.audienceType)?.error_subcode &&
                        ` (#${
                          getError('adsets', report.audienceType)?.error_subcode
                        })`}
                    </p>
                  </div>
                ) : getError('ads', report.audienceType) ? (
                  <div className="error-container center">
                    <span className="error-user-title">
                      Error:{' '}
                      {getError('ads', report.audienceType)?.error_user_title ||
                        getError('ads', report.audienceType)?.code}
                    </span>
                    <p className="error-user-message">
                      {getError('ads', report.audienceType)?.error_user_msg ||
                        getError('ads', report.audienceType)?.message}
                      {getError('ads', report.audienceType)?.error_subcode &&
                        ` (#${
                          getError('ads', report.audienceType)?.error_subcode
                        })`}
                    </p>
                  </div>
                ) : (
                  report && (
                    <React.Fragment>
                      <div className="stats">
                        <div className="stat">
                          {report.spend ? (
                            <span>
                              {currencySymbol}
                              {numberFormatter.formatNumber(report.spend)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                          <span>Total spent</span>
                        </div>
                        <div className="stat">
                          {report.dailyBudget ? (
                            <span>
                              {currencySymbol}
                              {numberFormatter.formatNumber(report.dailyBudget)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                          <span>Daily Budget</span>
                        </div>
                      </div>
                      <div className="stats">
                        <div className="stat">
                          {report.sales ? (
                            <span>
                              {currencySymbol}
                              {numberFormatter.formatNumber(report.sales)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}

                          <span>Sales</span>
                        </div>
                        <div className="stat">
                          <span>
                            {report.purchase
                              ? numberFormatter.formatNumber(report.purchase)
                              : '-'}
                          </span>
                          <span>Purchases</span>
                        </div>
                      </div>
                      <div className="stats">
                        <div className="stat">
                          <span>
                            {report.roas ? report.roas.toFixed(2) : '-'}
                          </span>
                          <span>ROAS</span>
                        </div>
                        <div className="stat">
                          {report.costPerPurchase ? (
                            <span>
                              <span className="currency">{currencySymbol}</span>
                              {numberFormatter.formatNumber(
                                report.costPerPurchase,
                              )}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                          <span>Cost / Purchase</span>
                        </div>
                      </div>
                    </React.Fragment>
                  )
                )}
                {!isSample &&
                  !getError('adCampaign') &&
                  !getError('adsets', 'remarketing') &&
                  !getError('adsets', 'lookalike') &&
                  !getError('adsets', 'broad') &&
                  !getError('ads', 'remarketing') &&
                  !getError('ads', 'lookalike') &&
                  !getError('ads', 'broad') &&
                  reportsInView.length &&
                  !report.noReport && (
                    <div className="view-details">
                      <Link to="/ai-ads/report-details">
                        <FaEye className="icon" />
                        View details
                      </Link>
                    </div>
                  )}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default CampaignReportMobile
