import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import imageExpander from "../../../shared/util/imageExpander";

import gtm6 from '../../../shared/assets/images/gtm-guide/gtm-6.png'
import { FaArrowLeft } from "react-icons/fa";
import { generalActions } from "../../../store/general";


const HowToFindConversionID = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(generalActions.setPageTitle({ pageTitle: 'Find your Conversion ID on Google Ads' }))
        imageExpander.handleImages()
        document.querySelector(".App").classList.add("docs");

        return () => {
            document.querySelector(".App").classList.remove("docs")
        }
    }, [])

    return (
        <div className="docs-cont">
            <div className="tutorial-page">
                <div className="page-content">
                    <div className="text-container">
                        <p>
                            Conversion ID is the key for connecting your webpage events to your Google Ads account. Enhencer also relies on it to create events and audiences on Google Ads. <br></br><br></br>
                            To get your Conversion ID, log in to your Google Ads account and go to the main page.<br /><br />
                            - Go to <b>'Tools & Settings'</b>, select <b>'Audience Manager'</b>.<br />
                            - On the left panel select <b>'Your data sources'</b>, click on <b>'Details'</b> in the <b>'Google Ads tag'</b>.<br />
                            - At the very bottom, open <b>'Tag setup'</b> dropdown and select <b>'Use Google Tag Manager'</b>.<br /><br />
                            You can see your <b>Conversion ID</b> there.<br />
                        </p>

                    </div>
                    <img alt="gtm6" src={gtm6} className="docs-img"/>
                    <div className="text-container">
                        <Link className="iconized" to="/connect-overview">
                            <FaArrowLeft />
                            Go back
                        </Link>
                        {/* {user.shopify ? <Link className="iconized" to="/connect-overview">
                            <FaArrowLeft />
                            Go back
                        </Link>
                            :
                            props.guidePage ?
                                <Link className="iconized" to={props.guidePage}>
                                    <FaArrowLeft />
                                    Go back to the setup
                                </Link> : null
                        } */}

                    </div>
                </div>
            </div>

        </div>
    );

}

export default HowToFindConversionID;