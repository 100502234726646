import React from 'react'
import { FaArrowRight, FaInfoCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import 'react-toastify/dist/ReactToastify.css'
import './PaymentRequired.css'

const AccountSuspended = props => {
  const navigate = useNavigate()
  const { closeModal } = useModal()

  const yes = () => {
    closeModal()
    navigate('/account/subscription')
  }

  return (
    <div className="are-you-sure payment-required">
      <div className="info-icon">
        <FaInfoCircle />
      </div>

      <div className="question">
        Your account has been suspended since you haven't done your last
        payment. <br></br>
        Please activate your subscription in order to use Enhencer.
      </div>
      <div className="footer">
        <div className="action-bar" style={{ display: 'contents' }}>
          <Button
            className="action-button yes"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5em',
            }}
            onClick={yes}
          >
            Subscription
            <FaArrowRight />
          </Button>
        </div>
      </div>
      <a
        className="schedule-a-demo-link"
        href="https://enhencer.com/schedule-a-demo"
        target="_blank"
      >
        Or schedule a meeting with our team
      </a>
    </div>
  )
}

export default AccountSuspended
