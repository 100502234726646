import React, { createContext, useContext, useState } from 'react'

const NotificationPopupContext = createContext()

export const NotificationPopupProvider = ({ children }) => {
  const [popupData, setPopupData] = useState({})

  const showPopup = data => {
    setPopupData(data)
  }

  const hidePopup = () => {
    setPopupData({})
  }

  return (
    <NotificationPopupContext.Provider
      value={{ showPopup, hidePopup, popupData }}
    >
      {children}
    </NotificationPopupContext.Provider>
  )
}

export const useNotificationPopup = () => {
  const { showPopup, hidePopup, popupData } = useContext(
    NotificationPopupContext,
  )

  return {
    showPopup,
    hidePopup,
    popupData,
  }
}
