import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { authActions } from "../../store/auth";
import helper from "../../shared/util/helper";
import shopifyLogo from "../../shared/assets/images/icons/shopify-bag.png";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useModal } from "../../shared/hooks/modal-hook";
import Button from "../../shared/components/FormElements/Button";

import "./ShopifyConnectStatusBox.css"
import "./ConversionIDBox.css"
import Modals from "../../shared/enums/Modals";

const ShopifyConnectStatusBox = props => {
    const { openModal } = useModal()
    const { sendRequest } = useHttpClient()
    const dispatch = useDispatch()
    const performanceManagerProfile = useSelector(state => state.auth.user.crmDetails.performanceManagerProfile)


    const auth = useSelector(state => state.auth)

    const disconnectClick = () => {
        openModal({
            type: Modals.ARE_YOU_SURE,
            question:
                'Your store will be disconnected from Enhencer. Are you sure?',
            callback: disconnect,
        })
    }

    const disconnect = async () => {
        // start loading
        try {
            let res = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/shopify/removeScript`, "post", JSON.stringify({
                id: auth.user.id,
                domain: auth.user.shopify.domain,
                accessToken: auth.user.shopify.accessToken,
                scriptId: props.scriptId
            }),
                {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                }
            )
            dispatch(authActions.updateUserObj({
                ...auth.user,
                isActive: false,
                shopify: {
                    ...auth.user.shopify,
                    scriptId: null
                }

            }))
            // stop loading
            toast.error("You have been disconnected your store from Enhencer. Your audiences won't work properly.")
            return true
        } catch (err) {
            // stop loading
            toast.error(err?.message || "Something went wrong")
        }
    }

    const openBookADemo = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: performanceManagerProfile.meetingUrl || process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_LINK,
                prefill: {
                    email: auth.user.email || "",
                    name: auth.user.name || "",
                }
            })
        }
    }

    return <div className="conv-id-cont" >
        <div className="head-row">
            <h5>Store Data Connection</h5>
        </div>
        <img alt="adslogo" className="google-ads-logo" src={shopifyLogo} />

        <div className="shopify-connection-status content">

            <div className="row">
                Status:
                <div className="dot"></div>
                <span style={{ fontWeight: "600" }}>Active</span>
            </div>

            <p>You have successfully connected your store to Enhencer. Once your store gets visitors, the stats will appear here. You can <span className="link" onClick={openBookADemo}>schedule a demo meeting</span> with us if you have any questions.</p>

            <Button className="red button--danger" onClick={disconnectClick}>Disconnect</Button>

        </div>


    </div>
}

export default ShopifyConnectStatusBox;