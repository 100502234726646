import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useModal } from '../../hooks/modal-hook'
import bg from '../../assets/images/free-checkup-bg.png'
import { FaCheck, FaCheckCircle, FaCogs, FaPlug, FaTimes } from 'react-icons/fa'
import './AdHealthCheckupModal.css'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { useFacebook } from '../../hooks/facebook-hook'
import CheckupIcon from '../../assets/images/ai-ads/checkup.png'
import { adHealthCheckupActions } from '../../../store/ad-health-checkup'

const AdHealthCheckupModal = ({ data }) => {
  const { closeModal } = useModal()
  const user = useSelector(state => state.auth.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const location = useLocation()
  const query = new URLSearchParams(location.search)
//   const isBetaVersion = query.get('_beta')


  const goToConnectPage = () => {
    return () => {
      closeModal()
      navigate('/checkup/config')
    }
  }

  const goToCheckupPage = () => {
    dispatch(adHealthCheckupActions.setConnected(true))
    return () => {
      closeModal()
      navigate(`/ad-health-checkup`)
    }
  }

  return (
    <div
      className="ad-health-checkup-modal"
      style={{
        backgroundImage: data.scheduledMeeting ? 'none' : `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className="close-button" onClick={closeModal}>
        <FaTimes />
      </div>

      <div>
        <div className="left-side">
          <div className="subscription-header">
            Great News! You’re eligible for a{' '}
            <span className="green">Free</span> Ad Health Check Up with{' '}
            <span className="green">AI Ads</span>
          </div>

          <div className="content">
            {selectedPixel ? (
              <div className="middle-text">
                <FaCheckCircle className="icon" />
                <div>Your are all set! See your ad health checkup</div>
              </div>
            ) : (
              <div className="middle-text">
                Before starting, we need to connect to your Meta Ad Account
              </div>
            )}
          </div>
        </div>

        <div className="connect-page-button">
          {selectedPixel ? (
            <div className="button" onClick={goToCheckupPage()}>
              <div className="button-row">
                Go to Ad Health Checkup
                <img src={CheckupIcon} />
              </div>
            </div>
          ) : (
            <div className="button" onClick={goToConnectPage()}>
              <div className="button-row">
                Connect Your Account
                <FaPlug className="icon" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdHealthCheckupModal
