import React from 'react'
import { useSelector } from 'react-redux'
import { FaArrowRight, FaPlus } from 'react-icons/fa'

import FluidCard from '../../shared/components/UIElements/FluidCard'
import ClickToStartBG from '../../shared/assets/images/ai-ads/click-to-start-bg.png'
import helper from '../../shared/util/helper'
import { useModal } from '../../shared/hooks/modal-hook'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { useShopify } from "../../shared/hooks/shopify-hook";

import './ClickToStartCard.css'
import numberFormatter from '../../shared/util/numberFormatter'
import Button from '../../shared/components/FormElements/Button'
import Modals from '../../shared/enums/Modals'

const FullFunnelCampaignsCard = () => {
  const { openModal } = useModal()
  const { navigateWithAdminParams } = useAdmin()
  const user = useSelector(state => state.auth.user)
  const admin = useSelector(state => state.admin)
  const userInView = user?.isSpecialUser ? admin.userInView : user
  const facebook = useSelector(state => state.facebook)
  const { canContinueToSubscription } = useShopify();
  const enhCampaigns = facebook.enhCampaigns

  const canNavigate = () => {
    const canContinue = canContinueToSubscription({ shouldShowModal: true });
    if (!canContinue) {
      return false
    }


    if (
      !user?.isSpecialUser &&
      (!userInView.subscription ||
        (userInView.subscription &&
          userInView.subscription.status !== 'active'))
    ) {
      return openModal({
        type: Modals.PAYMENT_REQUIRED,
        closeOnClickOutside: false,
      })
    }

    if (!userInView.connectionsCompleted && facebook.connectionPageIsReady) {
      return openModal({
        type: Modals.CONNECTION_REQUIRED,
        shouldRedirect: true,
      })
    }

    return true
  }

  const goToCampaigns = () => {
    if (canNavigate()) {
      navigateWithAdminParams('/ai-ads/full-funnel-campaigns')
    }
  }

  const goToCampaignDetail = (id) => {
    if (canNavigate()) {
      navigateWithAdminParams(`/ai-ads/full-funnel-campaigns?id=${id}`)
    }
  }

  const goToCreateCampaign = () => {
    if (canNavigate()) {
      navigateWithAdminParams(`/ai-ads/full-funnel-campaigns/create`)
    }
  }

  const renderCardContent = () => {
    if (enhCampaigns.length > 0) {
      return (
        <React.Fragment>
          <div className="campaign-items">
            {enhCampaigns.map(campaign => (
              <div
                onClick={() => goToCampaignDetail(campaign._id)}
                className="campaign-item"
                key={campaign._id}
              >
                <div className="title" title={campaign.label}>
                  {campaign.label}
                </div>
                <div className="info">
                  <div className="budget-info">
                    <span className="amount">{helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)} {numberFormatter.reduceDecimalPointsIfFloat(campaign.dailyBudget)}</span>
                    <span>Daily Budget</span>
                  </div>
                  <div
                    className={
                      campaign?.reports?.campaignLevel?.effectiveStatus === 'ACTIVE' ?
                        'indicator active' : 'indicator'
                    }
                  />
                </div>
              </div>
            ))}
          </div>
          <div onClick={goToCampaigns} className="see-all">
            <FaArrowRight className="icon" />
            See all
          </div>
        </React.Fragment>
      )
    }

    return (
      <div className="img-cont" onClick={goToCampaigns}>
        <img src={ClickToStartBG} alt="Click To Start" className="bg-image" />
        <div className="cta-text">
          Click to start campaigns
        </div>
      </div>
    )
  }

  return (
    <FluidCard className="click-to-start">
      <FluidCard.Header>
        <div className="header-wrapper">
          <div className="title">AI Ads Launcher</div>

          {
            enhCampaigns.length > 0 ?
              <Button onClick={goToCreateCampaign}>
                <FaPlus className='icon' />
                Create New Campaign
              </Button>
              : null
          }

        </div>
      </FluidCard.Header>
      <FluidCard.Content>{renderCardContent()}</FluidCard.Content>
    </FluidCard>
  )
}

export default FullFunnelCampaignsCard
