import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useForm } from '../../shared/hooks/form-hook'
import { useConstants } from '../../shared/hooks/constants-hook'
import Input from '../../shared/components/FormElements/Input'
import CollapsibleCard from '../../shared/components/UIElements/CollapsibleCard'

import './CardAdminTeam.css'
import helper from '../../shared/util/helper'
import { useAdmin } from '../../shared/hooks/admin-hook'
import errorHandler from '../../shared/util/errorHandler'

function CardAdminTeam({ isSignup }) {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const { convertManagerIdToName, convertManagerNameToId } = useAdmin()
  const { customer, teamInfo, performanceManagers, accountManagers } = useSelector(state => state.admin)
  const auth = useSelector(state => state.auth)
  const [isDirty, setIsDirty] = useState(false)
  const [saving, setSaving] = useState(false)
  const { privateConstants: constants } = useConstants()

  const [teamFormState, teamInputHandler, setTeamFormData] = useForm(
    {
      leadType: {
        value: customer.crmDetails?.leadType || '',
        valid: true,
      },
      leadPerson: {
        value: customer.crmDetails?.leadPerson || '',
        isValid: true,
      },
      inboundType: {
        value: customer.crmDetails?.inboundType || '',
        isValid: true,
      },
      salesPerson: {
        value: customer.crmDetails?.salesPerson || '',
        isValid: true,
      },
      accountManager: {
        value: customer.crmDetails?.accountManager || '',
        isValid: true,
      },
      performanceManager: {
        value: customer.crmDetails?.performanceManager || '',
        isValid: true,
      },
      partner: {
        value: customer.crmDetails?.partner || '',
        isValid: true,
      },
    },
    true,
  )

  const save = async () => {
    try {
      setSaving(true)

      const isAccountManagerDirty =
        convertManagerNameToId(teamFormState.inputs.accountManager.value).found !==
        (customer.crmDetails?.accountManager || '')

      const isPerformanceManagerDirty =
        convertManagerNameToId(teamFormState.inputs.performanceManager.value).found !==
        (customer.crmDetails?.performanceManager || '')
        
      const queryData = {
        'crmDetails.leadType': teamFormState.inputs.leadType.value,
        'crmDetails.leadPerson': teamFormState.inputs.leadPerson.value,
        'crmDetails.inboundType': teamFormState.inputs.inboundType.value,
        'crmDetails.salesPerson': teamFormState.inputs.salesPerson.value,
        'crmDetails.partner': teamFormState.inputs.partner.value,
      }
      if (isAccountManagerDirty) {
        queryData["crmDetails.accountManager"] = convertManagerNameToId(teamFormState.inputs.accountManager.value).found
      }

      if (isPerformanceManagerDirty) {
        queryData["crmDetails.performanceManager"] = convertManagerNameToId(teamFormState.inputs.performanceManager.value).found
      }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
        'PATCH',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          isSignup,
          data: {
            ...customer,
            crmDetails: {
              ...customer.crmDetails,
              leadType: teamFormState.inputs.leadType.value,
              leadPerson: teamFormState.inputs.leadPerson.value,
              inboundType: teamFormState.inputs.inboundType.value,
              accountManager: teamFormState.inputs.accountManager.value,
              performanceManager: teamFormState.inputs.performanceManager.value,
              salesPerson: teamFormState.inputs.salesPerson.value,
              partner: teamFormState.inputs.partner.value,
            },
          },
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const detectChanges = () => {
    const isLeadTypeDirty =
      teamFormState.inputs.leadType.value !==
      (customer.crmDetails?.leadType || '')

    const isLeadPersonDirty =
      teamFormState.inputs.leadPerson.value !==
      (customer.crmDetails?.leadPerson || '')

    const isInboundTypeDirty =
      teamFormState.inputs.inboundType.value !==
      (customer.crmDetails?.inboundType || '')

    const isSalesPersonDirty =
      teamFormState.inputs.salesPerson.value !==
      (customer.crmDetails?.salesPerson || '')

    const isAccountManagerDirty =
      convertManagerNameToId(teamFormState.inputs.accountManager.value).found !==
      (customer.crmDetails?.accountManager || '')

    const isPerformanceManagerDirty =
      convertManagerNameToId(teamFormState.inputs.performanceManager.value).found !==
      (customer.crmDetails?.performanceManager || '')

    const isPartnerDirty =
      teamFormState.inputs.partner.value !==
      (customer.crmDetails?.partner || '')

    setIsDirty(
      isLeadTypeDirty ||
      isLeadPersonDirty ||
      isInboundTypeDirty ||
      isSalesPersonDirty ||
      isAccountManagerDirty ||
      isPerformanceManagerDirty ||
      isPartnerDirty,
    )
  }

  // We compare the current input values to the initial values at each render.
  useEffect(() => {
    detectChanges()
  })

  useEffect(() => {
    setTeamFormData(
      {
        leadType: {
          value: customer.crmDetails?.leadType || '',
          valid: true,
        },
        leadPerson: {
          value: customer.crmDetails?.leadPerson || '',
          isValid: true,
        },
        inboundType: {
          value: customer.crmDetails?.inboundType || '',
          isValid: true,
        },
        salesPerson: {
          value: customer.crmDetails?.salesPerson || '',
          isValid: true,
        },
        accountManager: {
          value: customer.crmDetails?.accountManager || '',
          isValid: true,
        },
        performanceManager: {
          value: customer.crmDetails?.performanceManager || '',
          isValid: true,
        },
        partner: {
          value: customer.crmDetails?.partner || '',
          isValid: true,
        },
      },
      true,
    )
  }, [customer])

  useEffect(() => {
    if (!accountManagers) return

    const accountManager = accountManagers[customer.crmDetails?.accountManager]

    setTeamFormData({
      ...teamFormState.inputs,
      accountManager: {
        ...teamFormState.inputs.accountManager,
        value: accountManager?.name
      }
    })
  }, [accountManagers])

  useEffect(() => {
    if (!performanceManagers) return

    const performanceManager = performanceManagers[customer.crmDetails?.performanceManager]

    setTeamFormData({
      ...teamFormState.inputs,
      performanceManager: {
        ...teamFormState.inputs.performanceManager,
        value: performanceManager?.name
      }
    })
  }, [performanceManagers])


  const buildAccountManagerSelectionComponent = () => {
    let showingValue;
    const accountManagersArray = Object.values(accountManagers)
    let namesOfAccountManagers = accountManagersArray.map(manager => manager.name)

    if (customer.crmDetails?.accountManager) {
      const conversionResult = convertManagerIdToName(customer.crmDetails?.accountManager)
      if (conversionResult.isConverted) {
        showingValue = conversionResult.found
      } else {
        namesOfAccountManagers = [conversionResult.found, ...namesOfAccountManagers]
      }
    }

    return <Input
      element="dropdown"
      initialValue={showingValue ?? ""}
      forceValue={showingValue ?? ""}
      id="accountManager"
      type="text"
      onInput={teamInputHandler}
      options={namesOfAccountManagers || []}
    />
  }

  const buildPerformanceManagerSelectionComponent = () => {
    let showingValue;
    const performanceManagersArray = Object.values(performanceManagers)
    let namesOfPerformanceManagers = performanceManagersArray.map(manager => manager.name)

    if (customer.crmDetails?.performanceManager) {
      const conversionResult = convertManagerIdToName(customer.crmDetails?.performanceManager)
      if (conversionResult.isConverted) {
        showingValue = conversionResult.found
      } else {
        namesOfPerformanceManagers = [conversionResult.found, ...namesOfPerformanceManagers]
      }
    }

    return <Input
      element="dropdown"
      initialValue={showingValue ?? ""}
      forceValue={showingValue ?? ""}
      id="performanceManager"
      type="text"
      onInput={teamInputHandler}
      options={namesOfPerformanceManagers || []}
    />
  }


  return (
    <CollapsibleCard title="Team" className="topaz team-card">
      <div className="inputs-wrapper">
        <div className="input-cell">
          <span>Lead Type</span>
          <Input
            element="dropdown"
            initialValue={teamFormState.inputs.leadType.value}
            forceValue={teamFormState.inputs.leadType.value}
            id="leadType"
            type="text"
            onInput={teamInputHandler}
            options={constants.leadTypes}
          />
        </div>
        <div className="input-cell">
          <span>Account Manager</span>
          {buildAccountManagerSelectionComponent()}
        </div>
        <div className="input-cell">
          <span>Performance Manager</span>
          {buildPerformanceManagerSelectionComponent()}
        </div>
        <div className="input-cell">
          <span>Inbound Type</span>
          <Input
            element="dropdown"
            initialValue={teamFormState.inputs.inboundType.value}
            forceValue={teamFormState.inputs.inboundType.value}
            id="inboundType"
            type="text"
            onInput={teamInputHandler}
            options={teamInfo.inboundTypeOptions || []}
          />
        </div>
        {/* <div className="input-cell">
          <span>Salesperson</span>
          <Input
            element="dropdown"
            initialValue={teamFormState.inputs.salesPerson.value}
            forceValue={teamFormState.inputs.salesPerson.value}
            id="salesPerson"
            type="text"
            onInput={teamInputHandler}
            options={teamInfo.accountManagerOptions || []}
          />
        </div> */}
        <div className="input-cell">
          <span>Partner</span>
          <Input
            element="dropdown"
            initialValue={teamFormState.inputs.partner.value}
            forceValue={teamFormState.inputs.partner.value}
            id="partner"
            type="text"
            onInput={teamInputHandler}
            options={teamInfo.partnerOptions || []}
          />
        </div>
      </div>
      {isDirty && (
        <div className="save-button-container">
          <button disabled={saving} className="save-button" onClick={save}>
            {saving ? 'Saving' : 'Save'}
          </button>
        </div>
      )}
    </CollapsibleCard>
  )
}

export default CardAdminTeam
