import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useFacebook } from '../../shared/hooks/facebook-hook'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'

import './ProductSetDropdownHover.css'

const ProductSetDropdownHover = ({ edgeId, productCount }) => {
  const fb = useSelector(state => state.facebook)
  const { getProductsOfEdge } = useFacebook()
  const [products, setProducts] = useState([])
  const [isLoading, setIsLoading] = useState()
  let isMounted;

  useEffect(() => {
    isMounted = true
    const getProducts = async () => {
      setIsLoading(true)
      const products = await getProductsOfEdge({
        edgeId,
        accessToken: fb.auth.accessToken,
        fields: 'image_url,name',
      })
      
      if (isMounted) {
        setProducts(products)
        setIsLoading(false)
      }
    }

    if (productCount) getProducts()
    return () => {
      isMounted = false

    }
  }, [])

  return (
    <div className="product-set-hover-content">
      {isLoading ?
        <InlineLoadingSpinner message="Fetching products..." />
        :
        productCount ?
          <React.Fragment>
            <div className='header'>{productCount} products</div>
            {products.map(p => (
              <div key={p.id} className="product-item" title={p.name}>
                <div className="product-image-container">
                  <img className="product-image" src={p.image_url} alt={p.name} />
                </div>
                <span className="product-name">{p.name}</span>
              </div>
            ))}
          </React.Fragment>
          :
          <div className='helper-text'>
            No available products in this set
          </div>
      }
    </div>
  )
}

export default ProductSetDropdownHover
