import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'

import { useConstants } from '../../shared/hooks/constants-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import helper from '../../shared/util/helper'
import analytics from '../../shared/util/analytics'
import { authActions } from '../../store/auth'
import { generalActions } from '../../store/general'

const PaymentCompleted = props => {
  const { publicConstants: constants, pricingConstants } = useConstants()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { sendRequest } = useHttpClient()

  let location = useLocation()
  const subscription = location.state?.stripe_subs // for stripe
  const query = new URLSearchParams(location.search)
  let token = ''
  let subscriptionCompleted = query.get('subscription_completed')
  let singlePayment = query.get('sp')
  if (subscriptionCompleted || subscription) {
    token = query.get('token') || subscription.id
  } else {
    navigate('/account/subscription')
  }

  const [processing, setProcessing] = useState(true)
  const [paymentError, setPaymentError] = useState(false)
  const auth = useSelector(state => state.auth)
  const user = auth.user

  const authHeader = {
    Authorization: 'Bearer ' + auth.token,
    'Content-Type': 'application/json',
  }

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle: 'Payment Successful' }))
  }, [])

  useEffect(() => {
    let isMounted = true
    const completeSubscription = async () => {
      let url = `${process.env.REACT_APP_BACKEND_URL}/payment/${singlePayment ? 'checkSinglePaymentStatus' : 'checkSubscriptionStatus'}`

      let result = !subscription
        ? await sendRequest(url, 'POST', JSON.stringify({ token }), authHeader)
        : null
      let data = result?.data

      if (!isMounted) return

      let planResult
      let planName = ''
      let iyzicoPlanCode = ''
      let planRefCode = subscription?.plan.id || ''
      let subscriptionRefCode

      if (singlePayment) {
        planRefCode = result.itemTransactions[0].itemId
        planResult = {
          plan: {
            price: result.price,
            currencyCode: result.currency,
          },
        }
        subscriptionRefCode = null
      }

      if (data) {
        planResult = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/payment/getSubscriptionPlanDetails`,
          'POST',
          JSON.stringify({ refCode: data.pricingPlanReferenceCode }),
          authHeader,
        )
        planRefCode = data.pricingPlanReferenceCode
        iyzicoPlanCode = data.pricingPlanReferenceCode
        subscriptionRefCode = data.referenceCode
      }

      let source = subscription ? 'Stripe' : 'Iyzico'
      let currency = planResult?.plan.currencyCode === 'TRY' ? 'TL' : 'USD'
      let price = planResult?.plan.price || subscription?.plan.amount / 100
      let customerRefCode =
        subscription?.customer || data?.customerReferenceCode || null
      let createdDate =
        parseInt(data?.createdDate) ||
        subscription?.created * 1000 ||
        Date.now()

      let paymentIntervalStripe =
        subscription?.plan.interval.toLowerCase() === 'month'
          ? 'MONTHLY'
          : 'YEARLY'
      let paymentInterval =
        planResult?.plan.paymentInterval.toUpperCase() ||
        paymentIntervalStripe ||
        'MONTHLY'

      let lastPaymentDate =
        subscription?.latest_invoice.created * 1000 || createdDate
      let nextPaymentDate =
        planResult?.plan.paymentInterval === 'MONTHLY'
          ? helper.addMonthAndReturnTimestamp(createdDate)
          : helper.addYearAndReturnTimestamp(createdDate)

      let refCode = subscriptionRefCode || subscription?.id

      //getting plan names by using planRefCode
      if (paymentInterval === 'MONTHLY' && source === 'Iyzico') {
        pricingConstants.monthlyPackages.forEach(p => {
          if (planRefCode === p.iyzicoPlanNameTR) {
            planName = p.name
          }
        })
      } else if (paymentInterval === 'YEARLY' && source === 'Iyzico') {
        pricingConstants.yearlyPackages.forEach(p => {
          if (planRefCode === p.iyzicoPlanNameTR) {
            planName = p.name
          }
        })
      } else if (paymentInterval === 'MONTHLY' && source === 'Stripe') {
        pricingConstants.monthlyPackages.forEach(p => {
          if (planRefCode === p.stripePlanCode) {
            planName = p.name
          }
        })
      } else if (paymentInterval === 'YEARLY' && source === 'Stripe') {
        pricingConstants.yearlyPackages.forEach(p => {
          if (planRefCode === p.stripePlanCode) {
            planName = p.name
          }
        })
      }

      let subscriptionDetails = {
        source: source,
        refCode: refCode,
        parentRefCode: data?.parentReferenceCode || null,
        plan: planName || null,
        paymentInterval: paymentInterval,
        interval: paymentInterval,
        iyzicoPlanCode: iyzicoPlanCode || null,
        stripePlanCode: subscription?.plan.id || null,
        price: parseInt(price),
        currency: currency,
        planRefCode: planRefCode || null,
        customerRefCode: customerRefCode,
        createdDate: createdDate,
        lastPaymentDate: lastPaymentDate,
        nextPaymentDate: nextPaymentDate,
        status: 'active',
        pricingVersion: 'v3',
      }

      const setQuery = {
        'subscription.source': source,
        'subscription.refCode': refCode,
        'subscription.parentRefCode': data?.parentReferenceCode || null,
        'subscription.plan': planName || null,
        'subscription.paymentInterval': paymentInterval,
        'subscription.iyzicoPlanCode': iyzicoPlanCode || null,
        'subscription.stripePlanCode': subscription?.plan.id || null,
        'subscription.price': parseInt(price),
        'subscription.currency': currency,
        'subscription.planRefCode': planRefCode || null,
        'subscription.customerRefCode': customerRefCode,
        'subscription.createdDate': createdDate,
        'subscription.lastPaymentDate': lastPaymentDate,
        'subscription.nextPaymentDate': nextPaymentDate,
        'subscription.status': 'active',

        'crmDetails.subscription.status': 'Recurring',
        'crmDetails.subscription.isPaused': 'No',
        'crmDetails.subscription.type': source,
        'crmDetails.subscription.mrr':
          parseInt(price) / (paymentInterval === 'YEARLY' ? 12 : 1),
        'crmDetails.subscription.price': parseInt(price),
        'crmDetails.subscription.currency': currency,
        'crmDetails.subscription.period': paymentInterval,
        'crmDetails.subscription.paymentInterval': paymentInterval,
        'crmDetails.subscription.contractCurrencyRate':
          constants.usdTlCurrencyRate,
        'crmDetails.contractStartDate': new Date(),
        'leadDetails.isWon': true,
      }

      if (!!user.subscription?.customPlan) {
        setQuery['subscription.customPlan.refCode'] =
          user.subscription.customPlan.refCode
        subscriptionDetails.customPlan = user.subscription.customPlan
      }

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/updateSubscription`,
          'POST',
          JSON.stringify({ userId: user.id, setQuery }),
          authHeader,
        )

        dispatch(authActions.updateSubscription(subscriptionDetails))
        analytics.completeSubscription({
          value: price,
          currency: currency,
          subscriptionId: refCode,
          subscriptionPlanName: planName,
          userId: user.id,
          userEmail: user.email,
        })

        if (isMounted) {
          setProcessing(false)
          setTimeout(() => {
            navigate('/config')
          }, 2000)
        }
      } catch (err) {
        if (isMounted) {
          toast.warn(err?.message || 'Something went wrong')
          setProcessing(false)
          setPaymentError(true)
        }
      }
      if (isMounted) {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/sendNewSubscriptionEmail`,
          'POST',
          JSON.stringify({
            name: user.name,
            email: user.email,
            plan: planName,
            interval: paymentInterval,
            price: price,
          }),
          authHeader,
        )
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/sendNewSubscriptionMessageToSlack`,
          'POST',
          JSON.stringify({
            name: user.name,
            email: user.email,
            url: user.url,
            contactNumber: user.contactNumber,
            country: user.country,
            inboundType: user.crmDetails.inboundType,
            plan: planName,
            interval: paymentInterval,
            price: price,
            currency: currency,
          }),
          authHeader,
        )
      }
    }

    if (user.subscription && user.subscription.status === 'active') {
      navigate('/config')
    } else {
      completeSubscription()
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className="payment-completed">
      <div className="subscription-success">
        {processing ? (
          <div className="row">
            <LoadingSpinner />
            <span>Starting subscription...</span>
          </div>
        ) : (
          <div className="row">
            {paymentError ? (
              <React.Fragment>
                <FaTimes className="icon red" />
                <span>Subscription failed. Try again later or contact us.</span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FaCheckCircle className="icon" />
                <span>Subscription started</span>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentCompleted
