import React, { useEffect, useState } from 'react'

const useSmallScreen = (breakpoint = 767) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= breakpoint)
    }

    checkScreenSize()

    window.addEventListener('resize', checkScreenSize)

    return () => {
      window.removeEventListener('resize', checkScreenSize)
    }
  }, [])

  return isSmallScreen
}

export default useSmallScreen
