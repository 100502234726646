import React, { useRef } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import 'react-toastify/dist/ReactToastify.css'

import './NewDemoMeeting.css'
import Input from '../FormElements/Input'

import { FaTimes } from 'react-icons/fa'

import { VALIDATOR_EMAIL } from '../../util/validators'

import './AddUser.css'

const AddUser = props => {
  const { closeModal, modalData } = useModal()
  const now = new Date()
  const emailInputRef = useRef()

  const create = () => {
    const enteredEmail = document.getElementById('email').value
    modalData.callback({ email: enteredEmail })
    closeModal()
  }

  const close = () => {
    closeModal()
  }

  return (
    <div className="add-user-modal">
      <div className="header">
        <h2>Add User</h2>
        <FaTimes className="close" onClick={close} />
      </div>
      <Input
        id="email"
        element="input"
        type="email"
        label="Email"
        ref={emailInputRef}
        validators={[VALIDATOR_EMAIL()]}
        errorText="Please enter a valid email address."
        onInput={() => {}}
      />
      <div className="buttons-row">
        <Button onClick={close}>Cancel</Button>
        <Button onClick={create}>Add</Button>
      </div>
    </div>
  )
}

export default AddUser
