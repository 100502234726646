import React from 'react'

import './FluidCard.css'

function FluidCard({ children, className, ...props }) {
  return (
    <div className={`fluid-card-container ${className}`} {...props}>
      {children}
    </div>
  )
}

function Header({ children }) {
  return <div className="fluid-card-header">{children}</div>
}

function Content({ children }) {
  return <div className="fluid-card-content">{children}</div>
}

FluidCard.Header = Header
FluidCard.Content = Content

export default FluidCard
