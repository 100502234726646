import React, { useEffect, useState } from 'react'
import './CustomerCardInput.css'
import { FaHistory, FaSave } from 'react-icons/fa'

const CustomerCardInput = ({ label, userId, oldValue, oldDaysPassedSinceLast, onSave }) => {
  const [currentValue, setCurrentValue] = useState(oldValue ?? 0)
  const [daysPassedSinceLast, setDaysPassedSinceLast] = useState(oldDaysPassedSinceLast)
  const fieldName = label.toLowerCase()

  return (
    <div className="input" onClick={e => e.stopPropagation()}>
      <label htmlFor={`${fieldName}-${userId}`}>{label}</label>
      <input
        id={`${fieldName}-${userId}`}
        type="number"
        min="0"
        value={currentValue}
        onChange={(domElement) => {
          domElement.persist()
          setCurrentValue(domElement.target.value)
        }}
      />
      {currentValue !== (oldValue ?? 0) ? (
        <button onClick={() => {
          onSave(currentValue)
          setDaysPassedSinceLast(0)
        }} className="save-btn">
          <FaSave className="icon" />
        </button>
      ) : !isNaN(daysPassedSinceLast) ? (
        <p className="description">
          <FaHistory />
          {daysPassedSinceLast === 0
            ? 'Today'
            : `${daysPassedSinceLast}d`}
        </p>
      ) : null}
    </div>
  )
}


export default CustomerCardInput
