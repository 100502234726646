import React, { createContext, useContext, useMemo, useState } from 'react'

const ModalContext = createContext()

export const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({})
  const [modalReturnData, setModalReturnData] = useState({})
  const [activeModals, setActiveModals] = useState([])

  const openModal = data => {
    setModalData(data)

    setActiveModals(prevModals =>
      data.replaceAll ? [data] : [...prevModals, data],
    )
  }

  const closeModal = data => {
    setModalData({})

    if (data) setModalReturnData(data)

    setActiveModals(prevModals => prevModals.slice(0, -1))
  }

  // Prevent unnecessary re-renders by only updating the context value when needed
  const contextValue = useMemo(
    () => ({
      modalData,
      modalReturnData,
      activeModals,
      openModal,
      closeModal,
    }),
    [modalData, modalReturnData, activeModals],
  )

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const { modalData, modalReturnData, activeModals, openModal, closeModal } = useContext(ModalContext)

  return {
    modalData,
    modalReturnData,
    activeModals,
    openModal,
    closeModal,
  }
}
