import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { FaCalendarCheck } from "react-icons/fa";

const NewStoreConnect = (props) => {
  const auth = useSelector((state) => state.auth);
  let user = auth.user;
  if (props.userInView) {
    user = props.userInView
  }

  const getSupportMeeting = async () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: process.env.REACT_APP_CALENDLY_ONBOARDING_MEETING_LINK,
      })
    }
  }

  return (
    <div>
      {
        user?.connectFlow?.dataCollectionStarted ?
          <div className="no-data">
            <div className="row">
              Status:
              <div className="dot"></div>
              <span style={{ fontWeight: "600" }}>Active</span>
            </div>

            <p>You have successfully connected your store to Enhencer.</p>
          </div>
          : user?.crmDetails?.bookedOnboardingMeeting ?
            <div className="no-data">
              <div className="row">
                Status:
                <div className="dot"></div>
                <span style={{ fontWeight: "600" }}>Booked an onboarding meeting</span>
              </div>
              <p> Waiting the support team to take action. You do not need to do anything right now.</p>
              <div className="center"><FaCalendarCheck fontSize={24}></FaCalendarCheck></div>
            </div>
            :
            <div className="no-data">
              <div>
                Your visitor data is not connected to Enhencer. An installation should be done on your store.
                <br></br>

                <div className="connect-options">
                  <div
                    className={`option-button row ${props.disabled ? "disabled" : ""}`}
                    onClick={props.disabled ? null : getSupportMeeting}
                  >
                    <FaCalendarCheck fontSize={16}></FaCalendarCheck>
                    Book an onboarding support meeting
                  </div>
                </div>

              </div>
            </div>
      }
    </div >

  );
};

export default NewStoreConnect;
