import React, { useReducer, useEffect } from 'react';

import { validate, validatePasswordMatch } from '../../util/validators';
import './Input.css';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      };
    }
    default:
      return state;
  }
};

const Input = props => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.initialValue ?? '',
    isTouched: false,
    isValid: props.initialValid || true
  });

  useEffect(() => {
    if (props.forceValue === undefined || props.forceValue === null) {
      return
    }

    dispatch({
      type: 'CHANGE',
      val: props.forceValue,
      validators: props.validators || []
    });
  }, [props.forceValue])

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  const changeHandler = event => {
    let val = event.target.value
    if (event.target.type === "textarea") {
      val.replace(/\r?\n/g, "\\n")
    }
    dispatch({
      type: 'CHANGE',
      val: val,
      validators: props.validators || []
    });
    if(props.apply) {
      props.apply(val);
    }
  };

  const touchHandler = (e) => {
    e.stopPropagation()
    dispatch({
      type: 'TOUCH'
    });
  };

  const clickHandler = (e) => {
    e.stopPropagation()
  }

  const element =
    props.element === 'input' ? (
      <input
        id={props.id}
        type={props.type}
        placeholder={props.placeholder}
        onChange={changeHandler}
        onBlur={touchHandler}
        // onClick={clickHandler}
        value={inputState.value}
        disabled={props.disabled}
      />
    ) : props.element === 'textarea' ? (
      <textarea
        id={props.id}
        rows={props.rows || 3}
        onChange={changeHandler}
        onBlur={touchHandler}
        value={inputState.value}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
    ) : (
      <div className='select-cont'>
        <select onChange={changeHandler} onClick={(e) => {e.stopPropagation()}} disabled={props.disabled}
        value={inputState.value || ""}>
          {(!(!!inputState.value) || inputState.value === "" || !props.options.includes(inputState.value)) &&
            <option key={"select"} value={""} disabled>Select</option>
          }
          {
            props.options.map((option, index) => {
              return <option key={`${index}-${option}`} value={option}>{option}</option>
            })
          }
        </select>
      </div>
    )

  return (
    <div
      className={`form-control ${!inputState.isValid &&
        inputState.isTouched &&
        'form-control--invalid'}`}
    >
      <label htmlFor={props.id}>{props.label}
        {props.required && <span style={{ color: "red", verticalAlign: "text-bottom" }}>*</span>}
      </label>
      {element}
      {!inputState.isValid && inputState.isTouched && <p className='error-text'>{props.errorText}</p>}
    </div>
  );
};

export default Input;
