import React, { useEffect, useState } from "react";
import { FaFacebookF, FaGoogle, FaLink, FaPaperclip, FaTiktok } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ControlCenter from "../../shared/components/Navigation/ControlCenter";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ProgressBar from "../../shared/components/UIElements/ProgressBar";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "./Connect.css"
import { getShortDateString } from "../../shared/util/dateFormatter";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

const Connect = () => {

    const auth = useSelector((state) => state.auth);
    const user = auth.user || {}
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [completedStep, setCompletedStep] = useState(0)
    const location = useLocation()

    const [processStep, setProcessStep] = useState(1)
    const [connectProcess, setConnectProcess] = useState([
        {
            name: user.shopify ? "Connect your store" : "Connect your website",
            id: "dataCollectionStarted",
            detail: user.connectFlow?.dataCollectionStarted ? getShortDateString(user.connectFlow.dataCollectionStarted) : "Not connected",
            isActive: user.connectFlow?.dataCollectionStarted,
            icon: <FaLink />,
            link: "store"
        },
        {
            name: "Link Google Ads",
            id: "linkedGoogleAds",
            detail: user.connectFlow?.linkedGoogleAds ? getShortDateString(user.connectFlow.linkedGoogleAds) : "For using Enhencer Audiences on Google Ads, you have to link your ads account with Enhencer.",
            isActive: user.connectFlow?.linkedGoogleAds,
            icon: <FaGoogle />,
            link: "google-conversion-setup"
        },
        {
            name: "Log into Facebook Ads Account",
            id: "loggedInToFacebook",
            detail: user.connectFlow?.loggedInToFacebook ? getShortDateString(user.connectFlow.loggedInToFacebook) : "Login Required",
            isActive: user.connectFlow?.loggedInToFacebook,
            icon: <FaFacebookF />,
            link: "facebook"
        },
        {
            name: "Log into Google Ads Account",
            id: "loginToGoogleAds",
            detail: "Will be available soon.",
            isActive: false,
            disabled: true,
            icon: <FaGoogle />,
            link: "google-ads"
        },
        {
            name: "Log into Tiktok Ads Account",
            id: "loginToTiktokAds",
            detail: "Will be available soon.",
            disabled: true,
            isActive: false,
            icon: <FaTiktok />,
            link: "tiktok"
        },

    ])

    useEffect(() => {
        switch (location.pathname) {
            case "/connect/google-conversion-setup":
                setProcessStep(2)
                break

            case "/connect/facebook":
                setProcessStep(3)
                break

            case "/connect/store":
                setProcessStep(1)
                break
            default:
                navigate("/connect/store")
                setProcessStep(1)
                break
        }

    }, [location.pathname])

    useEffect(() => {
        handleProcessFlow()
    }, [user.connectFlow])

    const handleProcessFlow = (camps) => {
        let step = 0
        if (user.connectFlow?.dataCollectionStarted) {
            step = 1
        }
        if (user?.connectFlow?.segmentsReady) {
            step = 2
        }

        if (user?.connectFlow?.loggedInToFacebook) {
            step = 3
        }

        if (user?.connectFlow?.loggedInToFacebook) {
            step = 3
        }

        // let newProcess = [...audienceProcess]
        // setAudienceProcess(newProcess)

        setCompletedStep(step)

    }


    return isLoading ? <LoadingSpinner /> :
        <div className="manager-page">
            <div className="middle-container">
                {/* {middleCardBuilder()} */}
                <Outlet context={[connectProcess, processStep]} />
            </div>

            <ControlCenter>
                <div className="header-row">
                    <h4>Connection Process</h4>
                    <p>{((completedStep) / connectProcess.length * 100).toFixed(0)}%</p>
                </div>
                <ProgressBar percentage={(completedStep) / connectProcess.length * 100} />

                <div className="flow-container">
                    <div className="vertical-line"></div>
                    <div className="steps">
                        {connectProcess.map((item, index) =>
                            <Link to={item.link} className={`row process-item ${item.disabled ? 'disabled' : ''}`} key={index} onClick={() => { if (!item.disabled) { setProcessStep(index + 1) } }}>
                                <div className={index < completedStep ? "icon-circle active" : "icon-circle"}>
                                    {item.icon}
                                </div>
                                <div className="process-item-content">
                                    <h5 className={`clickable ${index === processStep - 1 ? 'selected' : ''}`}>{item.name}</h5>
                                    <div className="detail">{item.detail}</div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </ControlCenter>
        </div>
}

export default Connect;