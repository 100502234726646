import { configureStore } from '@reduxjs/toolkit'

import authReducer from './auth'
import generalReducer from './general'
import projectReducer from './project'
import adminReducer from './admin'
import facebookReducer from './facebook'
import facebookCampaignEditor from './facebookCampaignEditor'
import adHealthCheckupReducer from './ad-health-checkup'
import userReducer from './user'
import supportTicketsReducer from './support-tickets'

const store = configureStore({
  reducer: {
    auth: authReducer,
    general: generalReducer,
    project: projectReducer,
    admin: adminReducer,
    facebook: facebookReducer,
    facebookCampaignEditor: facebookCampaignEditor,
    user: userReducer,
    adHealthCheckup: adHealthCheckupReducer,
    supportTickets: supportTicketsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['general/openModal'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.callback'],
      },
    }),
})

export default store
