import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "../../hooks/form-hook";
import Input from "../FormElements/Input";

import "./CustomDatePicker.css"

const years = [...Array(20).keys()].map(i => i + 2010);
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const CustomDatePicker = props => {
    const [date, setDate] = useState(props.date ? new Date(props.date) : null)
    const dateChangedOnHeader = (newDate) => {
        setDate(newDate)
    }

    useEffect(() => {
        if (!props.date) {
            setDate(null)
            return
        }

        setDate(new Date(props.date))
    }, [props.date])

    return (
        <React.Fragment>
            <div className="date-picker-cont">

                <DatePicker
                    placeholderText={props.placeholder}
                    renderCustomHeader={
                        ({ date, changeMonth, changeYear }) => <Header
                            changeMonth={changeMonth}
                            changeYear={changeYear}
                            date={date}
                            dateChangedOnHeader={dateChangedOnHeader}
                        />
                    }
                    dateFormat="dd/MM/yyyy"
                    selected={date}
                    startDate={date}
                    onChange={(d) => { setDate(d); props.onChange(d) }}
                    popperPlacement="auto"
                />
            </div>
        </React.Fragment>
    );
};


const Header = (props) => {

    const [date, setDate] = useState(props.date)
    const [month, setMonth] = useState(months[props.date.getMonth()])
    const [year, setYear] = useState(props.date.getFullYear())

    useEffect(() => {
        setDate(props.date)
        setMonth(months[props.date.getMonth()])
        setYear(props.date.getFullYear())
    }, [props.date])

    const [formState, inputHandler, setFormData] = useForm(
        {
            month: {
                value: months[props.date.getMonth()],
                isValid: true
            },
            year: {
                value: props.date.getFullYear(),
                isValid: true
            }
        },
        true
    );

    const monthChanged = (val) => {
        let newDate = date;
        let i = months.indexOf(val);
        newDate.setMonth(i);
        props.changeMonth(i)
        props.dateChangedOnHeader(newDate)
    }

    const yearChanged = (val) => {
        let newDate = date;
        newDate.setFullYear(val);
        props.changeYear(val)
        props.dateChangedOnHeader(newDate)
    }



    return <div className="datepicker-header">

        <Input
            element="dropdown"
            placeholder="Year"
            initialValue={year}
            id="year"
            type="text"
            options={years}
            onInput={inputHandler}
            apply={yearChanged}
        />

        <Input
            element="dropdown"
            placeholder="Month"
            initialValue={month}
            id="month"
            type="text"
            options={months}
            onInput={inputHandler}
            apply={monthChanged}
        />
    </div>

}


export default CustomDatePicker;