import React, { useEffect, useState } from "react";
import { FaArrowRight, FaCheck, FaCircle, FaCloudDownloadAlt, FaDownload, FaExternalLinkAlt, FaFacebook, FaFacebookF, FaList, FaRecycle, FaRedhat, FaRedo, FaSearch, FaTimes } from "react-icons/fa";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { authActions } from "../../store/auth";

import "./NewConnectFacebook.css"

import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";
import ChecklistCard from "../../shared/components/UIElements/ChecklistCard";
import fbLogo from "../../shared/assets/images/icons/fb-logo.png";

import { useFacebook } from "../../shared/hooks/facebook-hook";
import { useFacebookAuth } from "../../shared/hooks/facebook-auth-hook";
import Checkbox from "../../shared/components/FormElements/Checkbox";
import { Link } from "react-router-dom";
import { adHealthCheckupActions } from '../../store/ad-health-checkup'

const NewConnectFacebook = ({
  stepCompletions,
  isCheckup
}) => {

  const auth = useSelector(state => state.auth)
  const user = auth.user
  const dispatch = useDispatch()

  const [adAccountSearchText, setAdAccountSearchText] = useState("")
  const [businessSearchText, setBusinessSearchText] = useState("")
  const [pixelSearchText, setPixelSearchText] = useState("")
  const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(user.agreedToPrivacyPolicy || false)
  const [pixelErrorText, setPixelErrorText] = useState("")
  const facebook = useSelector(state => state.facebook)
  const isLoggedIn = facebook.isLoggedIn
  const [selectingAdAccount, setSelectingAdAccount] = useState(false)
  const [isRefreshButtonVisible, setIsRefreshButtonVisible] = useState(false)

  const { adAccounts, businesses, pixels, loadings } = facebook;


  const {
    selectPixel, selectAdAccount, selectBusiness, getAdAccounts
  } = useFacebook()

  const { logOut, loginCallback } = useFacebookAuth();

  const filterBySearch = (item, search) => {
    if (item.name.toLowerCase().includes(search.toLowerCase())) {
      return true
    } else {
      return false
    }
  }

  const checkboxToggle = (e) => {
    setAgreedToPrivacyPolicy(!agreedToPrivacyPolicy)
  }


  useEffect(() => {
    let activePixels = pixels.filter(p => !p.is_unavailable && p.last_fired_time)
    if (activePixels.length === 0 && adAccounts.length > 1) {
      setPixelErrorText("No active pixels found, try selecting another ad account")
    } else if (activePixels.length === 0 && adAccounts.length === 0) {

      setPixelErrorText(
        <React.Fragment>

          <span>You don't have any pixels and ad accounts, go to
            <a href={`https://business.facebook.com/settings/ad-accounts?business_id=${facebook.selectedBusiness?.id}`}>Facebook Business Manager</a>
            to create both
          </span>
          <br />
          <span>If you are feeling stuck, book a support meeting with a performance marketing expert</span>

        </React.Fragment>
      )
    }

  }, [pixels])


  const handleAdAccountSelect = async (adAccount) => {
    if (adAccount?.id !== facebook.selectedAdAccount?.id) {
      setSelectingAdAccount(true)
      await selectAdAccount(adAccount, isCheckup);
      setSelectingAdAccount(false)
      setPixelErrorText("")
    }
  }

  const handleNoConnection = () => {
    dispatch(adHealthCheckupActions.setConnected(false))
  }

  let businessesAfterSearch = businesses.filter(business => { return filterBySearch(business, businessSearchText) })
  let adAccountsAfterSearch = adAccounts.filter(adAccount => { return filterBySearch(adAccount, adAccountSearchText) })

  return <React.Fragment>
    {(stepCompletions.storeConnected || isCheckup) &&
      <ChecklistCard number={isCheckup ? 1 : 2} name="Connect Facebook Account" completed={stepCompletions.loggedInToFacebook}  >
        <img alt="Facebook" className="fb-logo corner-logo" src={fbLogo} />
        {isLoggedIn ?
          <div className="facebook-logged-in">
            <div className="row">

              <img alt="url" src={facebook.auth?.picture?.data.url} />
              <div>
                <p>
                  You are logged in as <b>{facebook.auth.name}</b>
                </p>
                <Button onClick={logOut}>Logout</Button>
              </div>
            </div>
          </div> :
          <div className="facebook-login-cont">

            <p className="connect-text">
              Enhencer needs to connect to your Facebook Ads account to manage your campaigns. Please log in below.
            </p>

            <Checkbox
              key={"privacy-policy"}
              name={"Privacy Policy"}
              value={agreedToPrivacyPolicy}
              label={<p style={{ margin: ".7em 0" }}>
                I've read and agree to the <a target="_blank" href="https://enhencer.com/privacy-policy">Privacy Policy <FaExternalLinkAlt className="icon" /></a>
              </p>}
              onChange={checkboxToggle}
            />

            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={loginCallback}
              scope="email,public_profile,pages_show_list,pages_read_engagement,ads_read,ads_management,business_management,catalog_management"
              fields="name,email,picture"
              render={renderProps => (
                <Button className="fb-login-button" onClick={renderProps.onClick} disabled={!agreedToPrivacyPolicy} >
                  <FaFacebookF className="icon" />
                  Log in to your account
                </Button>
              )}
            />
          </div>}
      </ChecklistCard>
    }

    {facebook.connectionPageIsReady ?
      isLoggedIn ?
        <React.Fragment>
          <ChecklistCard
            number={isCheckup ? 2 : 3}
            name="Select Facebook Business"
            completed={stepCompletions.selectedBusiness}
            clearSelection={stepCompletions.selectedBusiness ? () => { selectBusiness(null) } : null}
          >
            {loadings["businesses"] ? <InlineLoadingSpinner></InlineLoadingSpinner> : <div className="ad-accounts">

              {businesses.length > 0 &&
                <div className="search-bar">
                  <FaSearch className="icon" />
                  <input type="text" value={businessSearchText} onChange={(e) => { setBusinessSearchText(e.target.value) }} placeholder='Search Businesses' />
                  {businessSearchText.length > 0 && <FaTimes className="clear-icon" onClick={() => { setBusinessSearchText("") }} />}
                </div>
              }
              <div className="ad-account-select">
                {businessesAfterSearch.length > 0 ?
                  <ul className={businessesAfterSearch.length > 5 ? "checklist-list-long" : ""}>
                    {businessesAfterSearch.map(business =>
                      <li key={business.id} onClick={() => {
                        selectBusiness(business);
                        setIsRefreshButtonVisible(false)
                      }} className={business.id === facebook?.selectedBusiness?.id ? 'selected' : ''}>
                        <div>
                          <div className="name">
                            {business.name}
                          </div>
                          <div className="id">
                            Business ID: {business.id}
                          </div>
                        </div>
                        <FaCheck className="icon" />
                      </li>
                    )}

                  </ul>
                  :
                  facebook?.selectedBusiness ?
                    <div className="no-account-text">
                      Selected business: <b>{facebook.selectedBusiness.name}</b> <br />
                      <span>ID: {facebook.selectedBusiness.id}</span>
                    </div>
                    :
                    <div className="no-account-text">
                      No businesses could be found.{' '}
                      <span>Go to {' '}
                        <a href={`https://business.facebook.com/settings/`}>Facebook Business Manager</a>
                        {' '}
                        to create one.
                      </span>
                      {isCheckup && <Link to="/ad-health-checkup/0" onClick={handleNoConnection}><br /><br />  Go to Ad Health Checkup</Link>}
                    </div>
                }
              </div>
            </div>
            }
          </ChecklistCard>

          {stepCompletions.selectedBusiness ?
            <ChecklistCard
              number={isCheckup ? 3 : 4}
              name="Select Ad Account"
              completed={stepCompletions.selectedAdAccount}
              clearSelection={(stepCompletions.selectedAdAccount) ? () => { handleAdAccountSelect(null) } : null}
            >
              {
                <div className="ad-accounts-card">
                  {
                    loadings["adAccounts"] ? <InlineLoadingSpinner></InlineLoadingSpinner> : <div className="ad-accounts">
                      <div className="row" style={{ display: "flex", justifyContent: "space-between" }}>
                        {adAccounts.length > 0 &&
                          <div className="search-bar">
                            <FaSearch className="icon" />
                            <input type="text" value={adAccountSearchText} onChange={(e) => { setAdAccountSearchText(e.target.value) }} placeholder='Search Ad Accounts' />
                            {adAccountSearchText.length > 0 && <FaTimes className="clear-icon" onClick={() => { setAdAccountSearchText("") }} />}
                          </div>
                        }
                      </div>
                      <div className="ad-account-select">
                        {adAccountsAfterSearch.length > 0 ?
                          <React.Fragment>
                            <ul className={adAccountsAfterSearch.length > 5 ? "checklist-list-long" : ""}>
                              {adAccountsAfterSearch.map(adAccount =>
                                <li key={adAccount.id} onClick={() => {
                                  if (adAccount.business?.id === facebook.selectedBusiness?.id && Object.values(adAccount.tos_accepted ?? {}).includes(1)) {
                                    handleAdAccountSelect(adAccount)
                                    setIsRefreshButtonVisible(false)
                                  }
                                }} className={adAccount.account_id === facebook?.selectedAdAccount?.account_id ? 'selected' : '' + ' ' + (facebook.selectedBusiness && adAccount.business?.id === facebook.selectedBusiness?.id ? '' : 'not-owned')}>
                                  <div className={`row`}>
                                    <div className="row">
                                      {facebook.selectedBusiness?.id && adAccount.business?.id === facebook.selectedBusiness?.id
                                        ? <div className={`dot ${(Object.values(adAccount.tos_accepted ?? {}).includes(1)) ? 'green' : 'yellow'}`}></div>
                                        : null}
                                      <div style={{ flex: "1 1 auto" }}>
                                        <div className="name">
                                          {adAccount.name}
                                        </div>
                                        <div className="id">
                                          Ad account ID: {adAccount.account_id}
                                        </div>
                                      </div>

                                      {facebook.selectedBusiness?.id && adAccount.business?.id === facebook.selectedBusiness?.id
                                        ? (adAccount.business?.id === facebook.selectedBusiness?.id && !Object.values(adAccount.tos_accepted ?? {}).includes(1)) ?
                                          <div style={{ flex: "0 1 45%", fontSize: "0.9em", display: "flex", gap: "0.5em" }}>
                                            <div onClick={
                                              () => {
                                                window.open(`https://business.facebook.com/ads/manage/customaudiences/tos/?act=${adAccount.account_id}`, "_blank")
                                                setIsRefreshButtonVisible(true)
                                              }
                                            } className="solve-button">Solve<FaArrowRight /></div>
                                            <Link onClick={
                                              () => {
                                                window.open(`https://business.facebook.com/ads/manage/customaudiences/tos/?act=${adAccount.account_id}`, "_blank")
                                                setIsRefreshButtonVisible(true)
                                              }
                                            }>Go to Facebook to Accept Terms of Services</Link>
                                          </div> : null
                                        : <div style={{ flex: "0 1 45%", fontSize: "0.9em" }}>
                                          Not owned by selected business
                                        </div>}
                                      <FaCheck className="icon" />
                                    </div>
                                  </div>
                                </li>
                              )}

                            </ul>
                            {isRefreshButtonVisible ? <div className="row" style={{ justifyContent: "center", alignContent: "center", border: "2px solid #eee", padding: "1em", borderRadius: "1em", flexDirection: "column" }}>
                              After you accepted Terms of Services on Facebook, verify here.
                              <div onClick={
                                () => {
                                  getAdAccounts({
                                    userId: facebook.auth.id,
                                    accessToken: facebook.auth.accessToken,
                                    selectAdAccountId: facebook.selectAdAccount?.id,
                                    freshFetch: true
                                  })
                                  setIsRefreshButtonVisible(false)
                                }
                              } className="solve-button" style={{ height: "35px", display: "flex", justifyContent: "center", gap: "0.5em", fontSize: "1.2em" }}><FaCheck fontSize={20} />Verify</div>
                            </div> : null}
                          </React.Fragment>
                          :
                          facebook?.selectedAdAccount ?
                            <div className="no-account-text">
                              Selected ad account: <b>{facebook.selectedAdAccount.name}</b> <br />
                              <span>ID: {facebook.selectedAdAccount.id}</span>
                            </div>
                            :
                            <div className="no-account-text">
                              No ad accounts could be found.{' '}
                              <span>Go to {' '}
                                <a href={`https://business.facebook.com/settings/ad-accounts${facebook.selectedBusiness?.id ? `?business_id=${facebook.selectedBusiness?.id}` : ''}`}>Facebook Business Manager</a>
                                {' '}
                                to create one.
                              </span>
                              {isCheckup && <Link to="/ad-health-checkup/0" onClick={handleNoConnection}><br /><br />  Go to Ad Health Checkup</Link>}
                            </div>}
                      </div>
                    </div>
                  }
                </div>
              }
            </ChecklistCard>
            : null
          }

          {
            stepCompletions.selectedAdAccount &&
            <ChecklistCard number={isCheckup ? 4 : 5} name="Select Facebook Pixel"
              completed={stepCompletions.selectedPixel}
              clearSelection={() => { selectPixel(null) }} >

              {loadings["pixels"] ? <InlineLoadingSpinner></InlineLoadingSpinner> : <div className="ad-accounts">

                {
                  pixels.length > 0 &&
                  <div className="search-bar">
                    <FaSearch className="icon" />
                    <input type="text" value={pixelSearchText} onChange={(e) => { setPixelSearchText(e.target.value) }} placeholder='Search Pixels' />
                    {pixelSearchText.length > 0 && <FaTimes className="clear-icon" onClick={() => { setPixelSearchText("") }} />}
                  </div>
                }
                {

                  <div className="ad-account-select">
                    {pixels.filter(pixel => { return filterBySearch(pixel, pixelSearchText) }).length > 0 ?
                      <ul className="checklist-list">
                        {pixels.filter(pixel => { return filterBySearch(pixel, pixelSearchText) }).map(pixel =>
                          <li key={pixel.id} onClick={() => { selectPixel(pixel, isCheckup) }} className={pixel.id === facebook?.selectedPixel?.id ? 'selected' : ''}>
                            <div className="row">
                              <div className={`dot ${pixel.is_unavailable || !pixel.last_fired_time ? 'red' : 'green'}`}>
                              </div>
                              <div>
                                <div className="name">
                                  {pixel.name}
                                </div>
                                <div className="id">
                                  Pixel ID: {pixel.id}
                                </div>
                              </div>
                            </div>
                            {
                              pixel.is_unavailable || !pixel.last_fired_time &&
                              <div className="info-text">
                                Pixel is inactive
                              </div>
                            }
                            <FaCheck className="icon" />
                          </li>
                        )}
                      </ul>
                      :
                      facebook?.selectedPixel ?
                        <div className="no-account-text">
                          Selected pixel: <b>{facebook.selectedPixel.name}</b> <br />
                          <span>ID: {facebook.selectedPixel.id}</span>
                        </div>
                        :
                        <div className="no-account-text">
                          {/* No pixels could be found.{' '}
                          <span>Go to {' '}
                            <a href={`https://business.facebook.com/settings/ad-accounts${facebook.selectedBusiness?.id ? `?business_id=${facebook.selectedBusiness?.id}` : ''}`}>Facebook Business Manager</a>
                            {' '}
                            to create one.
                          </span> 
                          { isCheckup && <Link to="/ad-health-checkup"><br/><br/>  Go to Ad Health Checkup</Link>}*/}
                        </div>
                    }
                    {
                      pixelErrorText.length > 0 &&
                      <div className="no-account-text">
                        {pixelErrorText}
                        {isCheckup && <Link to="/ad-health-checkup/0" onClick={handleNoConnection}><br /><br />  Go to Ad Health Checkup</Link>}
                      </div>
                    }
                  </div>

                }

              </div>
              }
            </ChecklistCard>
          }





        </React.Fragment> : null
      :
      <InlineLoadingSpinner message="Getting info..."></InlineLoadingSpinner>
    }

    {/* <div>
      Create campaign!
      <Button onClick={createCampaign}>Start now</Button>
    </div> */}

  </React.Fragment >
}

export default NewConnectFacebook;