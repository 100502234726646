import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import helper from '../../util/helper'
import { useHttpClient } from '../../hooks/http-hook'
import { useModal } from '../../hooks/modal-hook'
import Button from '../FormElements/Button'

import 'react-toastify/dist/ReactToastify.css'
import './SelectCategories.css'

const SelectCategories = props => {
  const authToken = useSelector(state => state.auth.token)
  const authUserId = useSelector(state => state.auth.user.id)
  const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser)
  const userInView = useSelector(state => state.admin.userInView)

  const userInViewId = userInView.id || authUserId
  const { closeModal } = useModal()
  const { sendRequest } = useHttpClient()
  const audience = props.data.audience
  const [categories, setCategories] = useState(audience.categories)

  const toggleCategory = (index) => {
    let c = [...categories];
    c[index] = {
      ...c[index],
      selected: c[index].selected ? !c[index].selected : true
    }
    setCategories(c);
  }

  const cancel = async () => {
    closeModal();
  }

  const save = async () => {
    let selectedCategories = helper.findAll(
      categories,
      "selected",
      true
    );
    selectedCategories = selectedCategories.map((selectedCategory) => ({
      type: selectedCategory.tableType,
      name: selectedCategory.name,
    }));


    const url = `${process.env.REACT_APP_BACKEND_URL}/${isSpecialUser ? 'admin' : 'projects'}/updateCategoriesOfAudience`;
    const data = JSON.stringify({
      userId: userInViewId,
      audienceName: audience.name,
      selectedCategories: selectedCategories,
      isDemo: audience.isDemo,
    });

    await sendRequest(url, "POST", data,
      {
        Authorization: "Bearer " + authToken,
        "Content-Type": "application/json",
      }
    );

    closeModal({ updatedCategories: categories });
    toast.success("Categories saved!");
  }

  let categoriesList = [];
  let listingCategoriesList = [];
  let productCategoriesList = [];


  categoriesList = [];
  listingCategoriesList = [];
  productCategoriesList = [];
  categories.forEach((category, index) => {
    const categoryElement = (
      <div key={index} className={category.selected ? "category-box selected" : "category-box"} onClick={() => { toggleCategory(index) }}>
        {category.name}
      </div>
    );
    categoriesList.push(categoryElement);
    if (category.tableType.includes("Listing")) {
      listingCategoriesList.push(categoryElement);
    }
    if (category.tableType === "Product") {
      productCategoriesList.push(categoryElement);
    }
  });

  return (
    <div className="select-categories">
      <div className="header-title">Filter Categories</div>
      <div className="content-box">
        <h4 className="attribute-name">
          Listing Categories
          {categories.filter(
            item => item.tableType.includes('Listing') && item.selected,
          ).length > 0 ? (
            <span className="helper">
              &nbsp; (
              {
                categories.filter(
                  item => item.tableType.includes('Listing') && item.selected,
                ).length
              }{' '}
              selected)
            </span>
          ) : null}
        </h4>
        <div className="categories-grid">{listingCategoriesList}</div>
        <h4 className="attribute-name">
          Product Categories
          {categories.filter(
            item => item.tableType.includes('Product') && item.selected,
          ).length > 0 ? (
            <span className="helper">
              &nbsp; (
              {
                categories.filter(
                  item => item.tableType.includes('Product') && item.selected,
                ).length
              }{' '}
              selected)
            </span>
          ) : null}
        </h4>
        <div className="categories-grid">{productCategoriesList}</div>
      </div>
      <div className="footer">
        <div className="action-bar" style={{ display: 'contents' }}>
          <Button className="action-button no" onClick={cancel}>
            Cancel
          </Button>
          <Button className="action-button yes" onClick={save}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SelectCategories
