import { toast } from "react-toastify"

export default (err) => {
    const abortMessage = "signal is aborted without reason"
    if (err.message === abortMessage) return

    if (err?.status === 403) {
        return toast.warn(err.message || 'You are not authorized to perform this action.')
    }

    return toast.error(err?.message || 'Something went wrong')

}