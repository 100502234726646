import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'
import AIAdsPageLayout from '../components/AIAdsPageLayout'
import AdvancedCampaignReport from '../components/AdvancedCampaignReport'

import './ReportDetails.css'
import TutorialSlider from '../components/TutorialSlider'
import SidebarContent from '../components/SidebarContent'
import { useUser } from '../../shared/hooks/user-hook'
import helper from '../../shared/util/helper'
import { useSelector } from 'react-redux'
import { useAdmin } from '../../shared/hooks/admin-hook'

const ReportDetails = () => {
  const location = useLocation()
  const { viewedReportDetail } = useAdmin()
  const admin = useSelector(state => state.admin)
  const user = useSelector(state => state.auth.user)
  const { setConnectFlowStep } = useUser()

  useEffect(() => {
    if (user?.isSpecialUser) {

      if (admin.userInView &&
        (
          (admin.userInView?.crmDetails && !admin.userInView?.crmDetails.adminViewedDetailedCampaignReport) ||
          Date.now() - admin.userInView?.crmDetails?.adminViewedDetailedCampaignReport > 5 * 1000)
      ) {
        viewedReportDetail()
      }
    } else {
      setConnectFlowStep({ step: "viewedDetailedCampaignReport" })
    }
  }, [admin.userInView._id])

  return (
    <AIAdsPageLayout>
      <AIAdsPageLayout.Content>
        <Breadcrumb>
          <Link to={`/ai-ads${location.search}`}>Meta Ads</Link>
          <Link to="#">Report Details</Link>
        </Breadcrumb>
        <div className="ai-ads-report-details-grid">
          <AdvancedCampaignReport />
        </div>
      </AIAdsPageLayout.Content>
      <AIAdsPageLayout.Sidebar>
        <SidebarContent />
      </AIAdsPageLayout.Sidebar>
    </AIAdsPageLayout>
  )
}

export default ReportDetails
