import React, { useEffect, useState } from "react";
import Button from "../../shared/components/FormElements/Button";
import Input from "../../shared/components/FormElements/Input";
import { useForm } from "../../shared/hooks/form-hook";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./EditSubscription.css"
import { FaCalendarAlt } from "react-icons/fa";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const EditSubscription = props => {
    const authToken = useSelector(state => state.auth.token)
    const customer = useSelector(state => state.admin.customer)
    const [subscription, setSubscription] = useState(customer.subscription || {})

    const { sendRequest } = useHttpClient()


    const [formState, inputHandler, setFormData] = useForm(
        {
            status: {
                value: subscription?.status || "passive",
                isValid: true
            },
            plan: {
                value: subscription?.plan || "Select a plan",
                isValid: true
            },
            price: {
                value: subscription?.price || null,
                isValid: true
            },
            currency: {
                value: subscription?.customPlan?.currency || "USD",
                isValid: true
            },
        },
        false
    );

    useEffect(() => {
        setFormData(
            {
                status: {
                    value: subscription?.status || "passive",
                    isValid: true
                },
                plan: {
                    value: subscription?.plan || "Select a plan",
                    isValid: true
                },
                price: {
                    value: subscription?.price || null,
                    isValid: true
                },
                currency: {
                    value: subscription?.customPlan?.currency || "USD",
                    isValid: true
                },
            },
            false
        )

        createEmptySubscription()
    }, [])

    const [dates, setDates] = useState({
        createdDate: subscription?.createdDate ? new Date(subscription.createdDate) : null,
        lastPaymentDate: subscription?.lastPaymentDate ? new Date(subscription.lastPaymentDate) : subscription?.nextPaymentDate ? new Date(subscription.nextPaymentDate - 30 * 24 * 60 * 60 * 1000) : null,
        nextPaymentDate: subscription?.nextPaymentDate ? new Date(subscription.nextPaymentDate) : subscription?.billingOn ? new Date(subscription.nextPaymentDate) : null,
    })

    const submit = e => {
        e.preventDefault()
        saveChanges()
    }

    const createEmptySubscription = () => {
        setSubscription({
            status: "passive",
            plan: "Custom",
            source: "Admin"
        })
        setFormData(
            {
                status: {
                    value: "passive",
                    isValid: true
                },
                plan: {
                    value: "Custom",
                    isValid: true
                },
                price: {
                    value: 100,
                    isValid: true
                },
                currency: {
                    value: "USD",
                    isValid: true
                },
            },
            false
        )
    }

    const createCustomPlan = async () => {
        let body = {
            price: formState.inputs.price.value,
            currency: formState.inputs.currency.value,
            name: customer.name
        }

        let url = `${process.env.REACT_APP_BACKEND_URL}/payment/createSubscriptionPlan`
        if (subscription && subscription.customPlan) {
            url = `${process.env.REACT_APP_BACKEND_URL}/payment/updateSubscriptionPlan`
            body.refCode = customer.subscription.customPlan.refCode
        }

        let result = await sendRequest(url, 'POST', JSON.stringify(body), {
            Authorization: "Bearer " + authToken,
            "Content-Type": "application/json",
        })

        if (result?.refCode) {
            return result.refCode
        } else {
            toast.error("Error occured. ", result.errorMessage)
            return 0
        }

    }


    const saveChanges = async () => {
        const subscriptionDetails = {
            "source": "",
            "refCode": "",
            "plan": formState.inputs.plan.value,
            "price": parseInt(formState.inputs.price.value),
            "currency": formState.inputs.currency.value,
            "status": formState.inputs.status.value,
        }
        const setQuery = {
            "subscription.source": "",
            "subscription.refCode": "",
            "subscription.plan": formState.inputs.plan.value,
            "subscription.price": parseInt(formState.inputs.price.value),
            "subscription.currency": formState.inputs.currency.value,
            "subscription.status": formState.inputs.status.value,


            "crmDetails.subscription.type": "Admin",
            "crmDetails.subscription.mrr": parseInt(formState.inputs.price.value),
            "crmDetails.subscription.currency": formState.inputs.currency.value,
        }

        if (dates.createdDate !== null) {
            subscriptionDetails["createdDate"] = dates.createdDate;
            setQuery["subscription.createdDate"] = dates.createdDate;
        }
        if (dates.lastPaymentDate !== null) {
            subscriptionDetails["lastPaymentDate"] = dates.lastPaymentDate;
            setQuery["subscription.lastPaymentDate"] = dates.lastPaymentDate;
        }

        if (dates.nextPaymentDate !== null) {
            subscriptionDetails["nextPaymentDate"] = dates.nextPaymentDate;
            setQuery["subscription.nextPaymentDate"] = dates.nextPaymentDate;
        }


        if (formState.inputs.plan.value === "Custom") {
            let res = await createCustomPlan()
            setQuery["subscription.customPlan.refCode"] = res
            setQuery["subscription.customPlan.price"] = parseInt(formState.inputs.price.value)
            setQuery["subscription.customPlan.currency"] = formState.inputs.currency.value
            subscriptionDetails.customPlan = {
                refCode: res,
                price: parseInt(formState.inputs.price.value),
                currency: formState.inputs.currency.value,
            }
        } else if (formState.inputs.plan.value === "Shopify Custom") {
            setQuery["subscription.customPlan.price"] = parseInt(formState.inputs.price.value)
            setQuery["subscription.customPlan.currency"] = "USD"
            subscriptionDetails.customPlan = {
                price: parseInt(formState.inputs.price.value),
                currency: "USD",
            }

        }

        sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/updateSubscription`, 'POST', JSON.stringify({ userId: customer.id || customer._id, setQuery: setQuery }), {
            Authorization: "Bearer " + authToken,
            "Content-Type": "application/json",
        }).then(res => {
            toast.success("Custom plan created successfully!")
            props.endSubscriptionEditing(subscriptionDetails)
            //setSubscription(subscriptionDetails)

        }).catch(err => {
            toast.error(err?.message || "Something went wrong")
        })

    }

    return <div className="edit-subscription">
        {subscription.status ?
            <form className={formState.inputs.plan.value === 'Custom' ? 'wide' : ''}>
                <div className="gen-box">

                    <Input
                        element="dropdown"
                        options={["passive", "active", "payment-error"]}
                        id="status"
                        type="text"
                        label="Status"
                        initialValue={formState.inputs.status.value}
                        errorText="Select status."
                        onInput={inputHandler}
                    />

                    <Input
                        element="dropdown"
                        options={customer.shopify ? ["Small", "Medium", "Large", "Shopify Custom"] : ["Small", "Medium", "Large", "Custom"]}
                        id="plan"
                        type="text"
                        label="Plan"
                        initialValue={formState.inputs.plan.value}
                        errorText="Select the plan."
                        onInput={inputHandler}
                    />
                </div>

                {formState.inputs.plan.value.includes('Custom') && <div>

                    <div className="custom-package-builder">

                        {/* <div className="question">

                            {subscription && subscription.customPlan ? <span style={{ fontSize: "0.8em", fontWeight: "lighter", color: "red" }}><br />Note that if you update the plan, the customer has to subscribe all over again.</span> : ""}
                        </div> */}
                        <div className="amount-container">
                            <Input element="input"
                                id="price"
                                initialValue={formState.inputs.price.value}
                                label="Amount / Month"
                                type="text"
                                onInput={inputHandler} />

                            <Input
                                disabled={customer.isFromShopify}
                                element="dropdown"
                                id="currency"
                                label="Currency"
                                initialValue={formState.inputs.currency.value}
                                onInput={inputHandler}
                                options={["USD", "TL"]} />
                        </div>

                    </div>
                </div>
                }

                {subscription.createdDate &&
                    <div className="dates-box">
                        <label>Created Date</label>
                        <div className="date-input-cont">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className="datepicker"
                                pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                                selected={dates.createdDate}
                                onChange={(d) => { setDates({ ...dates, createdDate: d.getTime() }) }}

                            />
                            <FaCalendarAlt className="icon" />
                        </div>

                        <label>Last Payment Date</label>
                        <div className="date-input-cont">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className="datepicker"
                                pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                                selected={dates.lastPaymentDate}
                                onChange={(d) => { setDates({ ...dates, createdDate: d.getTime() }) }}
                            />
                            <FaCalendarAlt className="icon" />
                        </div>

                        <label>Next Payment Date</label>
                        <div className="date-input-cont">
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                className="datepicker"
                                pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                                selected={dates.nextPaymentDate}
                                onChange={(d) => { setDates({ ...dates, createdDate: d.getTime() }) }}
                            />
                            <FaCalendarAlt className="icon" />
                        </div>
                    </div>}
            </form>
            :
            null
        }
        <div className="row">

            <Button onClick={() => { props.setSubscriptionEditing(false) }}>Cancel</Button>
            <Button onClick={submit}>Save Changes</Button>
        </div>
    </div >
}

export default EditSubscription;