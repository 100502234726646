import React, { useEffect, useState } from 'react';
import "./HourRangePicker.css"
const HourRangePicker = (props) => {

    const [startHour, setStartHour] = useState(props.filter?.start ?? 0);
    const [endHour, setEndHour] = useState(props.filter?.end ?? 0);

    useEffect(() => {
        if (props.filter?.start !== 0 || props.filter?.end !== 0) {

            setStartHour(props.filter.start)
            setEndHour(props.filter.end)
        } else {

            setStartHour(0)
            setEndHour(0)
        }
    }, [props.filter])


    const clearSelection = () => {
        setStartHour(0)
        setEndHour(0)
        if (props.setFilters) props.setFilters({
            start: 0,
            end: 0,
        })
    }

    // Function to handle change in start hour
    const handleStartHourChange = (e) => {
        let hour = parseInt(e.target.value.split(":")[0])
        if (hour === endHour) {
            return;
        }
        setStartHour(hour);
        if (props.setFilters) props.setFilters({
            start: hour,
            end: endHour,
        })
    };

    // Function to handle change in end hour
    const handleEndHourChange = (e) => {
        let hour = parseInt(e.target.value.split(":")[0])
        if (hour === startHour) {
            return;
        }
        setEndHour(hour);

        if (props.setFilters) props.setFilters({
            start: startHour,
            end: hour,
        })
    };
    function convertToHourFormat(hourInteger) {
        const hour = (hourInteger < 10 ? '0' : '') + hourInteger + ":00";
        return hour;
    }

    // Generating hours from 00:00 to 23:00
    const hours = [];
    for (let i = 0; i < 24; i++) {
        const hour = (i < 10 ? '0' : '') + i + ':00';
        hours.push(hour);
    }
    return (
        <div className='hour-range-picker'>
            <select className='selectable' id="startHour" value={convertToHourFormat(startHour)} onChange={handleStartHourChange}>
                {hours.map((hour, index) => (
                    <option key={index} value={hour}>{hour}</option>
                ))}
            </select>
            -
            <select className='selectable' id="endHour" value={convertToHourFormat(endHour)} onChange={handleEndHourChange}>
                {hours.map((hour, index) => (
                    <option key={index} value={hour}>{hour}</option>
                ))}
            </select>
            {
                !(startHour === 0 && endHour === 0) ? <button className="clear-button" onClick={clearSelection}>
                    X
                </button> : null
            }
        </div>
    );
}

export default HourRangePicker;