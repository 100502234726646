import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import gsap from 'gsap'
import metaIcon from '../../shared/assets/images/icons/meta-icon-green.svg'
import calendar from '../../shared/assets/images/icons/calendar-green.svg'
import calculator from '../../shared/assets/images/icons/calculator-green.svg'
import graph from '../../shared/assets/images/icons/graph-white.svg'
import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'

import './MetaRevenue.css'

const MetaRevenue = () => {
  const facebook = useSelector(state => state.facebook)
  const { averageMoMRevenueGrowth, totalRevenue, averageBudget, timeRange } =
    useSelector(state => state.adHealthCheckup.insights)
  const [since, until] = [
    moment(timeRange.start).format('MMM YYYY'),
    moment(timeRange.end).format('MMM YYYY'),
  ]

  useEffect(() => {
    // Animate the .column div to come up from below with increasing opacity
    gsap.from('.meta-revenue-main .column div', {
      duration: 1,
      y: 50,
      opacity: 0,
      ease: 'power3.out',
    })

    // Animate each card in .row.cards
    gsap.from('.row.cards .card', {
      duration: 1,
      y: 50,
      opacity: 0,
      stagger: 0.2, // Delay between each card's animation
      ease: 'power3.out',
      delay: 0.5,
    })
  }, [])

  return (
    <div className="content-box meta-revenue-main">
      <div className="column">
        <div className="second-big-text">
          First, Let’s look at your Meta Revenue <img src={metaIcon} />
        </div>
        <div className="small-text">
          Here’s your meta ads revenue growth <b>over the past 12 months.</b>
        </div>
      </div>
      <div className="row cards">
        <div className="card">
          <div className="card-content">
            <img src={calendar}></img>
            <div className="header-text">MoM Revenue Growth</div>
          </div>
          <div className="card-content">
            <div className="green-text">
              {Math.round(averageMoMRevenueGrowth)}%
            </div>
            <div className="small-text">
              {since} - {until}
            </div>
            <div className="smaller-text">MoM = Month on Month</div>
          </div>
        </div>

        <div className="card gradient">
          <div className="card-content graph">
            <img src={graph}></img>
            <div className="header-text">Yearly Revenue</div>
          </div>
          <div className="card-content">
            <div className="green-text">
              <span className="currency">
                {helper.getCurrencySymbol(
                  facebook.selectedAdAccount?.currency,
                ) || ''}
              </span>
              {numberFormatter.formatNumber(Number(totalRevenue))}
            </div>
            <div className="small-text">
              {since} - {until}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <img src={calculator}></img>
            <div className="header-text">Avg. Budget/month</div>
          </div>
          <div className="card-content">
            <div className="green-text">
              <span className="currency">
                {helper.getCurrencySymbol(
                  facebook.selectedAdAccount?.currency,
                ) || ''}
              </span>

              {numberFormatter.formatNumber(averageBudget, 0)}
            </div>
            <div className="small-text">
              {since} - {until}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MetaRevenue
