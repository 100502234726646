import React from "react";
import "./Table.css"

const Table = props => {
    const data = props.data
    return <div className="table-cont">
        <h4>{props.title}</h4>

        <table>
            {data && data.head &&
                <thead>
                    <tr>
                        {data.head.map((cell, index) => (
                            <th key={index}>{cell}</th>
                        ))}

                    </tr>
                </thead>
            }
            {data && data.rows &&
                <tbody>
                    {data.rows.map((row, index) => (
                        <tr key={index}>
                            {row?.map((cell, i) => {

                                return typeof cell === 'object' && cell?.type === 'td' ?
                                    cell :
                                    (
                                        <td key={index.toString() + i.toString()}>{
                                            typeof cell === 'number' ? cell.toFixed(0) : cell
                                        }</td>
                                    )
                            }
                            )}
                        </tr>
                    ))}
                    <tr></tr>
                </tbody>
            }
        </table>
    </div>
}

export default Table;