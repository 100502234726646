import React from "react";
import SelectCategory from "./SelectCategory";
import './InitialIndustrySelection.css'

const InitialIndustrySelection = props => {
    return (
        <div className="initial-industry-container">
            <SelectCategory inAccountSettings={false} />
        </div>
    );
}

export default InitialIndustrySelection