import React, { useMemo, useState } from 'react'
import { FaAngleLeft, FaAngleRight, FaPlay } from 'react-icons/fa'
import videoThumbnailBg from "../../shared/assets/images/ai-ads/video-thumbnail-bg.png"
import './TutorialSlider.css'

const TutorialSlider = () => {
  const items = [
    {
      type: 'video',
      title: 'Enhencer AI Ads',
      subtitle:
        'Your user-friendly solution for boosting your e-commerce ads effortlessly',
      src: 'https://www.youtube.com/embed/3VfUOF2dg10?si=33_8ml3aODOkyiih&autoplay=1', // autoplay=1 parameter is needed
    },
    /* {
      type: 'video',
      title: 'Budgeting 101',
      subtitle: 'How to make sure you are not wasting your budget',
      src: 'https://www.youtube.com/embed/3VfUOF2dg10?si=33_8ml3aODOkyiih&autoplay=1', // autoplay=1 parameter is needed
    }, */
  ]

  const [currentItemInfo, setCurrentItemInfo] = useState({
    idx: 0,
    isOpen: false,
  })

  const currentItem = useMemo(
    () => items[currentItemInfo.idx],
    [currentItemInfo.idx],
  )

  const handlePrev = () => {
    if (currentItemInfo.idx === 0) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx - 1,
      isOpen: false,
    }))
  }

  const handleNext = () => {
    if (currentItemInfo.idx === items.length - 1) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx + 1,
      isOpen: false,
    }))
  }

  const handlePlayVideo = () => {
    setCurrentItemInfo(prev => ({
      ...prev,
      isOpen: true,
    }))
  }

  return (
    <div className="tutorial-slider">
      {items.length > 1 && (
        <React.Fragment>
          <div
            onClick={handlePrev}
            className={`controller prev${currentItemInfo.idx === 0 ? ' disabled' : ''
              }`}
          >
            <FaAngleLeft className="icon" />
          </div>
          <div
            onClick={handleNext}
            className={`controller next${currentItemInfo.idx === items.length - 1 ? ' disabled' : ''
              }`}
          >
            <FaAngleRight className="icon" />
          </div>
        </React.Fragment>
      )}
      <div className="content">
        {currentItemInfo.isOpen ? (
          <iframe
            width="350"
            height="200"
            src={currentItem.src}
            title="Watch introduction video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        ) : (
          <div className="thumbnail">
            <div className='bg'>
              <img src={videoThumbnailBg} />
            </div>
            {currentItem.type === 'video' && (
              <div onClick={handlePlayVideo} className="play-button">
                <FaPlay />
              </div>
            )}
            <div className="texts">
              <span className="action-text">
                {currentItem.type === 'video' ? 'Watch Video' : 'Read Article'}
              </span>
              <span className="title">{currentItem.title}</span>
              <span className="subtitle">{currentItem.subtitle}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TutorialSlider
