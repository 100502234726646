import React, { useEffect, useRef, useState } from 'react'
import { useFacebook } from '../../shared/hooks/facebook-hook'

import "./AdCreativePreviewer.css"
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { facebookCampaignEditorActions } from '../../store/facebookCampaignEditor'

const AdCreativePreviewer = ({ existingCampaignCreative }) => {

    const { getAdPreviews } = useFacebook()
    const [previews, setPreviews] = useState()
    const ref = useRef()
    const dispatch = useDispatch()
    const [isGeneratingPreviews, setIsGeneratingPreviews] = useState(false)

    
    const creativeIdInRedux =  useSelector(state => state.facebookCampaignEditor?.currentCampaign?.currentCreative?.body?.id)
    const isCreativeValidInRedux = useSelector(state => state.facebookCampaignEditor?.currentCampaign?.currentCreative?.isValid)
    const creativeId = existingCampaignCreative?.id ?? creativeIdInRedux
    const isCreativeValid = existingCampaignCreative?.id ? true : isCreativeValidInRedux

    useEffect(() => {
        if (!ref.current) return

        if (creativeId) {
            dispatch(facebookCampaignEditorActions.setIsPreviewUpToDate(false))
            getPreviewsOfCreative(creativeId)
        }

    }, [ref])


    useEffect(() => {
        if (creativeId) {
            getPreviewsOfCreative(creativeId)
        }
    }, [creativeId])



    const getPreviewsOfCreative = async (cid) => {
        setIsGeneratingPreviews(true)
        const previewResults = await getAdPreviews({ creativeId: cid ? cid : '120200070614810307' })
        generateAndSetDisplayedPreviews(previewResults)
        dispatch(facebookCampaignEditorActions.setIsPreviewUpToDate(true))
        setIsGeneratingPreviews(false)
    }


    const generateAndSetDisplayedPreviews = (previewResults) => {
        setPreviews(
            previewResults.map(preview =>
                <div key={preview.format}
                    className={`ad-creative-cont ${preview.format.toLowerCase().replaceAll("_", "-")}`}
                    style={{
                        padding: `${preview.dimensions.height / 2}px ${preview.dimensions.width / 2}px`,
                        marginRight: `${preview.dimensions.width / -2}px`,
                        marginBottom: `${preview.dimensions.height / -2}px`
                    }}
                    dangerouslySetInnerHTML={{ __html: preview.body }}
                >
                </div>
                /* element.firstChild.setAttribute("width", (preview.dimensions.width / 2).toString()) // iframe is the first child
                element.firstChild.setAttribute("height", (preview.dimensions.height / 2).toString()) */)
        )
    }

    return <React.Fragment>
        <div className='adcr'>
            <h3>Previews</h3>
            {
                isCreativeValid ?
                    <>
                        {isGeneratingPreviews &&
                            <InlineLoadingSpinner message={`${creativeId ? 'Updating' : 'Getting'} your Ad Preview, please wait`} />
                        }
                        {previews?.length ?

                            <div ref={ref} className='ad-creatives-box'>
                                {previews}
                            </div> : null
                        }
                    </>
                    :
                    <p className='helper-text'>Ad previews will appear here once a creative is configured.</p>
            }

        </div>
    </React.Fragment>
}

export default AdCreativePreviewer

{/* <iframe src="https://www.facebook.com/ads/api/preview_iframe.php?d=AQJ2FBxqbfez_xpsAEwDMMd4d5QMzEOxFBMd_tCCN7ndufL55mZah7ca6TkfKihMPF1ZhUcJ-PmQeT8rAggHlwtX2IfDvwKlKkIM5jOlJYAm0fYjOPtIFsw5WqJiNXXw3y40GM8MZlY_NrmRVWDeVznEpiM8zCeV6WH_4nKHjAkC8dmt3Bao1dVJqYt5aBUwN0cNgmBka45-aHinxDBt9qGmpjRn1QpgGXOILnPZz6D_waANSbHiPJD5UvuAY6bL8fM4x3h8-6EW5ktBmXbfNZB5TVZvU3ugu7LOyZnnXKAgsVpbxNP6ecbgLpq-gZSjIOdHgjBhgU2dxw7duIRHb-920lRNj3DDACZErjcvkav-If2B3fZKvxyWV9T6MHH8KVRKfeTVm-xEws203xikM-fXsWoHboO86t5HcglgX637vEdny9QtIUL1Bd6g4UTbhs4&amp;t=AQK9tuQHuoI4mB0xE8E"
    width="320" height="570" scrolling="yes" style="border: none;"></iframe> */}