import React from 'react';

import './LoadingSpinner.css';

const InlineLoadingSpinner = props => {
  return (
    <div className={props.center ? 'center inline-spinner-box' : 'inline-spinner-box'}>
      <div className={`small-spinner-cont ${props.size}`} style={props.height ? {height: `${props.height}px` , width: `${props.height}px`} : {}}>
        <div className='bar bar-1' style={props.height ? {width: `${props.height / 5}px`} : {}}></div>
        <div className='bar bar-2' style={props.height ? {width: `${props.height / 5}px`} : {}}></div>
        <div className='bar bar-3' style={props.height ? {width: `${props.height / 5}px`} : {}}></div>
        <div className='bar bar-4' style={props.height ? {width: `${props.height / 5}px`} : {}}></div>
        <div className='bar bar-5' style={props.height ? {width: `${props.height / 5}px`} : {}}></div>
      </div>
      <div className='spinner-message'>{props.message}</div>
    </div>
  );
};

export default InlineLoadingSpinner;
