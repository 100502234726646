import React, { useEffect, useState } from 'react';
//import { Link } from 'react-router-dom';
import './ChecklistCard.css'
import { FaCheck } from 'react-icons/fa';

const ChecklistCard = props => {

    // const [isOpen, setIsOpen] = useState(props.isOpen)
    const [isOpen, setIsOpen] = useState(true)

    /* useEffect(() => {
        setIsOpen(props.isOpen)
    }, [props.isOpen]) */

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return <div className={`checklist-card ${props.className || ''} ${isOpen ? 'is-open' : ''}`}>

        <div className={`header ${props.completed ? 'completed' : ''}`} onClick={toggle}>
            <div className='name-row'>
                <div className='number'>
                    {props.number}.
                </div>
                <div className='name'>
                    {props.name}
                </div>
            </div>
            <div className='status-row'>
                {props.completed ?
                    <React.Fragment>
                        {
                            props.clearSelection && <div onClick={(e) => {e.stopPropagation(); props.clearSelection()}} className="text-button">Clear Selection</div>
                        }
                        <FaCheck className='icon' />
                    </React.Fragment>
                    :
                    null
                }
            </div>
        </div>
        <div className={`content`}>
            {props.children}
        </div>
    </div>
}

export default ChecklistCard;