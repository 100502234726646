import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import auth, { authActions } from '../../store/auth'
import { useAuth } from './auth-hook'
import helper from '../util/helper'
import { generalActions } from '../../store/general'
import cookies from '../util/cookies'
import analytics from '../util/analytics'
import { useAdmin } from './admin-hook'
import errorHandler from '../util/errorHandler'

export const useUser = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const { getPerformanceManagerProfileForUser } = useAdmin()

  // const { user } = useSelector(state => state.user)
  // user slice is not used right now, use state.auth.user
  const user = useSelector(state => state.auth.user)
  const { authToken, authTokenInStorage, authTokenExpirationDateInStorage } = useAuth()

  const storedData = JSON.parse(localStorage.getItem('userData') || '[]')

  const getProfile = async ({ token, shouldSetRedux = true }) => {
    if (!token && !authTokenInStorage) {
      dispatch(authActions.setIsLoginStateReady(true))
      return
    }

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/profile`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + (token ?? authTokenInStorage),
        },
      )

      const { user } = responseData
      //dispatch(generalActions.setAnStats(anStats))
      const updatedUser = helper.getUIReadyUserObject({ user })
      if (updatedUser.crmDetails?.performanceManager) {
        updatedUser.crmDetails.performanceManagerProfile = await getPerformanceManagerProfileForUser({
          performanceManager: updatedUser.crmDetails.performanceManager,
          authToken: (token ?? authTokenInStorage)
        })
      }

      setEmailInLocalStorageIfNotExists({
        email: updatedUser.email,
        token: token ?? authTokenInStorage
      })

      // We added the authActions.updateUserObj temporarily because we are currently using
      // the user object from the auth slice in all of our components.
      // We will switch to using the user object from the user slice later,
      // but for the sake of simplicity, we still storing the user in the auth slice too.
      // TODO: Remove the line below after switching and use userActions.setUser instead.
      if (shouldSetRedux) {
        dispatch(
          authActions.setAuthObject({
            user: updatedUser,
            token: token ?? authTokenInStorage,
            tokenExpirationDate: authTokenExpirationDateInStorage,
            isLoginStateReady: true,
          })
        )
      }

      return updatedUser
    } catch (err) {
      if (err?.status === 401) {
        dispatch(authActions.logout({}))
      }

      if (cookies.get("lo")) {
        errorHandler(err)
      } else {
        dispatch(authActions.logout({}))
        dispatch(generalActions.setPageTitle({ pageTitle: null }))
        cookies.set("lo", "1", 10)
      }
    }
  }


  const setEmailInLocalStorageIfNotExists = ({ token, email }) => {
    let storedUserData = JSON.parse(localStorage.getItem('userData') || '[]')
    let updatedData = [...storedData]
    let foundEntryIndexInLocalStorage = helper.findIndex(storedUserData, "token", token)


    
    if (storedUserData[foundEntryIndexInLocalStorage] && !storedUserData[foundEntryIndexInLocalStorage].email) {
      updatedData[foundEntryIndexInLocalStorage] = {
        ...storedUserData[foundEntryIndexInLocalStorage],
        email
      }
      localStorage.setItem('userData', JSON.stringify(updatedData))
    }
  }


  const setConnectFlowStep = async ({ step, shouldOverwrite = false }) => {

    if ((user.connectFlow && !user.connectFlow[step]) || !user.connectFlow || shouldOverwrite) {

      let newConnectFlow = {
        ...user.connectFlow,
        [step]: Date.now()
      }
      let url = `${process.env.REACT_APP_BACKEND_URL}/users/updateConnectFlow`

      try {
        await sendRequest(url, "POST", JSON.stringify({
          userId: user.id,
          connectFlow: newConnectFlow
        }),
          {
            Authorization: "Bearer " + authToken,
            "Content-Type": "application/json",
          }
        );

        dispatch(authActions.updateConnectFlow(newConnectFlow))
      } catch (err) {
        toast.error(err?.message || "Something went wrong")
      }
    }
  }

  const setBookedOnboardingMeeting = async ({ userMeetingData }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/bookedOnboardingMeeting`

    try {
      await sendRequest(url, "POST", JSON.stringify({
        userId: user.id,
        userMeetingData: userMeetingData
      }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );

      dispatch(authActions.updateUserObj({
        ...user,
        crmDetails: {
          ...user.crmDetails,
          bookedOnboardingMeeting: true,
        }
      }))
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }


  const createAdHealthCheckupMeeting = async ({ userMeetingData }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/createAdHealthCheckupMeeting`

    try {
      await sendRequest(url, "POST", JSON.stringify({
        userId: user.id,
        userMeetingData: userMeetingData
      }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );

      analytics.bookAdHealthCheckupMeeting()

      dispatch(authActions.updateUserObj({
        ...user,
        crmDetails: {
          ...user.crmDetails,
          adHealthCheckupMeeting: userMeetingData,
        }
      }))
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }

  const createSupportMeeting = async ({ userMeetingData }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/users/createSupportMeeting`

    try {
      await sendRequest(url, "POST", JSON.stringify({
        userId: user.id,
        userMeetingData: userMeetingData
      }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );

      analytics.bookASupportMeeting()
      let supportMeetings = user.crmDetails.supportMeetings ? [...user.crmDetails.supportMeetings] : []
      supportMeetings.push(userMeetingData)

      console.log("supportMeetings", supportMeetings)

      dispatch(authActions.updateUserObj({
        ...user,
        crmDetails: {
          ...user.crmDetails,
          supportMeetings
        }
      }))
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }


  return {
    getProfile,
    setConnectFlowStep,
    setBookedOnboardingMeeting,
    createAdHealthCheckupMeeting,
    createSupportMeeting
  }
}
