import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { generalActions } from '../../store/general'
import { authActions } from '../../store/auth'
import PricingCards from '../components/PricingCards'
import SubscriptionDetails from '../components/SubscriptionDetails'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import dateFormatter from '../../shared/util/dateFormatter'
import { getPriceFromPlanNameForShopify } from '../../shared/util/subscription'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import { useShopify } from '../../shared/hooks/shopify-hook'
import ProfilePageLayout from '../components/ProfilePageLayout'

import './Subscription.css'
import Modals from '../../shared/enums/Modals'

function getPlanDetails(user) {
  let ret = {
    name: user.subscription.plan,
    price: 'No price detail',
    paymentInterval: user.subscription.paymentInterval,
  }
  if (user.subscription.price && user.subscription.currency) {
    ret.price =
      user.subscription.price +
      (user.subscription.currency === 'TL' ? '₺' : '$')
  } else if (user.subscription.customPlan) {
    ret.price =
      user.subscription.customPlan.price +
      (user.subscription.customPlan.currency === 'TL' ? '₺' : '$')
  }

  return ret
}

const getSubscriptionDetails = user => {
  let subscribed
  let activePlan = { name: '', price: '' }
  let endDate = new Date()
  let error = ''
  let paymentInterval = ''

  if (
    user.subscription &&
    user.subscription.status === 'active' &&
    !user.shopify
  ) {
    subscribed = true
    let d = new Date(user.subscription.createdDate)
    endDate = d.setFullYear(d.getFullYear() + 1)
    activePlan = getPlanDetails(user)
    paymentInterval = user.subscription.paymentInterval
  } else if (
    user.subscription &&
    user.subscription.status === 'active' &&
    user.shopify
  ) {
    subscribed = true
    let d = new Date(user.subscription.createdDate)
    endDate = d.setFullYear(d.getFullYear() + 1)
    activePlan = {
      name: user.subscription.plan,
      price: user.subscription.price
        ? user.subscription.price.toString() + '$'
        : getPriceFromPlanNameForShopify({
          planName: user.subscription.plan,
          pricingVersion: user.subscription.pricingVersion
        }),
    }
    paymentInterval = user.subscription.paymentInterval
  } else if (
    user.subscription &&
    (user.subscription.status === 'payment-error' ||
      user.subscription.status === 'past_due' ||
      user.subscription.status === 'suspended')
  ) {
    subscribed = true
    activePlan = getPlanDetails(user)
    paymentInterval = user.subscription.paymentInterval
    error = 'Your payment could not be processed'
    if (user.subscription.lastPaymentDate) {
      error += ' on '
      error += dateFormatter(user.subscription.lastPaymentDate, true)
    }
    error += '.'
  } else {
    subscribed = false
  }

  return {
    error: error,
    subscribed: subscribed,
    hasCustomPlan: user.subscription && !!user.subscription.customPlan,
    customPlan: user.subscription?.customPlan,
    endDate: endDate,
    activePlan: activePlan,
    paymentInterval: paymentInterval,
  }
}

const Subscription = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLoading, sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const { canContinueToSubscription } = useShopify()
  let [countryCode, setCountryCode] = useState('TR')

  const auth = useSelector(state => state.auth)
  const user = auth.user


  const [subscriptionDetails, setSubscriptionDetails] = useState()
  const [hasCustomPlan, setHasCustomPlan] = useState(false)
  const [subscribed, setSubscribed] = useState(false)
  const [subscriptionEndDate, setSubscriptionEndDate] = useState()

  const source = user?.subscription?.source
  const [shopifyCheckoutStarted, setShopifyCheckoutStarted] = useState(false)
  const [usageCharges, setUsageCharges] = useState([])

  const billingInfo = user?.billingInfo

  useEffect(() => {
    const getLocation = async () => {

      try {
        const res = await sendRequest(
          `https://api.ipregistry.co/?key=${process.env.REACT_APP_IP_REGISTRY_KEY}`,
          'GET',
        )
        setCountryCode(res.location.country.code)
      } catch (err) {
        console.log(err)
      }
    }
    if (process.env.NODE_ENV === "production") {
      getLocation()
    } else {
      setCountryCode("TR")
    }

    let details = getSubscriptionDetails(user)
    setSubscriptionDetails(details)
    setHasCustomPlan(details.hasCustomPlan)
    setSubscribed(details.subscribed)
    setSubscriptionEndDate(details.endDate)

  }, [])

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle: 'Subscription' }))
    const canContinue = canContinueToSubscription({ shouldShowModal: true })

    if (!canContinue) {
      const modalConfig = {
        title: 'Your store may not be ready yet',
        imageUrl:
          'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
        text: 'Your store seems to have low traffic. To get better efficiency from Enhencer, it would be beneficial to attract more traffic.  ',
        actions: [
          {
            text: 'Close',
            callback() { },
          },
        ],
      }
      openModal({
        type: Modals.GENERIC_MODAL,
        data: modalConfig,
      })
    }

    getUsageCharges()
  }, [])

  useEffect(() => {
    setSubscribed(auth.user.subscription?.status === 'Recurring' || auth.user.subscription?.status === 'active');
  }, [auth.user?.subscription?.status]);

  const startShopifyPayment = async ({ price, planName, interval }) => {
    setShopifyCheckoutStarted(true)
    let params = JSON.stringify({
      price: price,
      planName: planName,
      interval: interval,
      accessToken: user.shopify.accessToken,
      shopDomain: user.shopify.domain,
    })
    try {

      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/shopify/createSubscription`,
        'POST',
        params,
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      window.open(result.confirmationUrl, '_self')
    } catch (err) {
      toast.error('An error occured. Please, contact us.')
    }
  }

  const cancelSubscription = () => {
    openModal({
      type: Modals.BEFORE_YOU_CANCEL,
      closeOnClickOutside: true,

    })


  }

  const upgradePlan = () => {
    navigate('/subscription/update-plan', {
      state: {
        subscriptionDetails: subscriptionDetails,
        user: user,
        isFromShopify: user?.shopify !== undefined,
      },
    })
  }

  const retryPayment = async () => {
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/payment/retryPayment`,
        'POST',
        JSON.stringify({
          subscriptionReferenceCode: user.subscription.refCode,
          // subscriptionReferenceCode: '905396a2-c994-4dec-b0c8-37c2ceabc773'
        }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      if (result.data.body.status === 'payment-error') {
        toast.error(
          'Error happened while processing the payment. Try again & contact us if the problem persists.',
        )
      } else {
        toast.success('Payment successfull!')
      }

      dispatch(
        authActions.updateSubscription({
          lastPaymentDate: result.data.body.lastPaymentDate,
          nextPaymentDate: result.data.body.nextPaymentDate,
          status: result.data.body.status,
        }),
      )
    } catch (err) {
      toast.error(
        'Error happened while processing the payment. Try again & contact us if the problem persists.',
      )
    }
  }

  const getUsageCharges = async () => {
    if (user.shopify && user.subscription?.chargeID && user.subscription?.paymentInterval === "MONTHLY") {
      try {
        let result = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/shopify/usageCharges?chargeID=${user.subscription.chargeID}&shopDomain=${user.shopify.domain}&accessToken=${user.shopify.accessToken}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )

        let total = 0
        if (result.usage_charges?.length) {
          result.usage_charges.forEach(charge => {
            total += parseFloat(charge.price)
          })
        }
        if (total) {
          result.usage_charges.push({
            name: 'Total',
            price: total.toFixed(2),
            id: 'total',
          })
        }

        setUsageCharges(result.usage_charges)
      } catch (err) {
        console.log('err', err)
      }
    }
  }

  const updateCard = async () => {
    if (source === 'Stripe') {
      openModal({
        type: Modals.STRIPE_UPDATE_CREDIT_CARD,
        closeOnClickOutside: true,
      })
      return
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/payment/updatePaymentCard`,
        'POST',
        JSON.stringify({
          subscriptionReferenceCode: user.subscription.refCode,
          // subscriptionReferenceCode: '905396a2-c994-4dec-b0c8-37c2ceabc773'
        }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      if (result.status === 'success') {
        localStorage.setItem('iyziUpdateToken', result.token)
        navigate({
          pathname: '/subscription/payment/update-card',
          state: { checkoutFormContent: result.checkoutFormContent },
        })
      }
    } catch (err) {
      toast.error(
        'Error while updating the payment method. Try again & contact us if the problem persists.',
      )
    }
  }

  return (
    <ProfilePageLayout>
      <ProfilePageLayout.Header>Subscription</ProfilePageLayout.Header>
      <ProfilePageLayout.Content>
        {isLoading || shopifyCheckoutStarted ? (
          <LoadingSpinner message={shopifyCheckoutStarted ? 'Redirecting you to Shopify...' : ''} />
        ) : user && subscribed && subscriptionDetails ? (
          <SubscriptionDetails //if user subscribed
            usageCharges={usageCharges}
            status={user.subscription.status}
            nextPaymentDate={user.subscription.nextPaymentDate}
            lastPaymentDate={user.subscription.lastPaymentDate}
            subscriptionCreatedDate={user.subscription.createdDate}
            subscriptionEndDate={subscriptionEndDate}
            pricingVersion={user.subscription.pricingVersion}
            isFromShopify={user.shopify !== undefined}
            activePlanName={
              subscriptionDetails.activePlan?.name || billingInfo?.planName || ''
            }
            activePlanPrice={subscriptionDetails.activePlan.price || ''}
            activePlanInterval={
              subscriptionDetails.paymentInterval || ''
            }
            customPlan={hasCustomPlan}
            source={source}
            cancelSubscription={cancelSubscription}
            retryPayment={retryPayment}
            updateCard={updateCard}
            error={subscriptionDetails.error}
            upgradePlan={upgradePlan}
            monthlyAdSpent={user.facebookAds?.monthlyAdSpent}
            additionalCharge={user.subscription.additionalCharge}
          ></SubscriptionDetails>
        ) : (
          subscriptionDetails ?
            <PricingCards //if user not subscribed
              customPlan={subscriptionDetails.customPlan}
              hasCustomPlan={!!hasCustomPlan}
              isFromShopify={user?.shopify !== undefined}
              startShopifyPayment={startShopifyPayment}
              countryCode={countryCode}
            ></PricingCards>
            : null
        )}
      </ProfilePageLayout.Content>
    </ProfilePageLayout>
  )
}

export default Subscription
