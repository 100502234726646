import React from "react";
import "./InfoBadge.css"

const InfoBadge = props => {
    const value = props.value
    const name = props.name
    const firstColor = props.background ? props.background[0] : "#006b92"
    const secondColor = props.background ? props.background[1] : "#538ca0"
    
    return <div
    className={`info-badge-card ${props.className || ""}`}
    style={{background: `linear-gradient(${firstColor} 32%, ${secondColor})`}}>
        <div>
            <div className="value">
                {value}
            </div>
            <div className="name">
                {name}
            </div>
        </div>
    </div>
}

export default InfoBadge;
