import React, { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'

import gradientMaker from '../../shared/util/gradientMaker'

import './ReportChart.css'

const ReportChart = ({ chartData, chartLabels }) => {
  const reportChartRef = useRef()

  const chartOptions = {
    responsive: true,
    tension: 0.4,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        ticks: {
          color: '#7c8284',
          font: {
            size: 9,
          },
          callback: function (value) {
            const [day, month] = this.getLabelForValue(value).split('/')
            return `${day}/${month}`
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
        min: 0,
      },
    },
  }

  const [reportChartData, setReportChartData] = useState({
    options: chartOptions,
    labels: chartLabels,
    datasets: [
      {
        // label: 'Something Cool',
        data: chartData,
        backgroundColor: 'transparent',
        lineWidth: 0,
        borderColor: '#6aae9e',
        fill: true,
        pointRadius: 2,
        pointHitRadius: 10,
        pointHoverRadius: 3,
        pointBorderWidth: window.innerWidth > 1400 ? 3 : 2,
        pointBackgroundColor: '#6aae9e',
        pointHoverBackgroundColor: '#6aae9e',
      },
    ],
  })

  useEffect(() => {
    if (!reportChartRef.current) {
      return
    }

    const reportChartDatasetsCopy = reportChartData.datasets.slice(0)
    reportChartDatasetsCopy[0].backgroundColor = gradientMaker(
      reportChartRef.current.ctx,
      '#d9eeeb',
      100,
    )

    setReportChartData(
      Object.assign({}, reportChartData, {
        datasets: reportChartDatasetsCopy,
      }),
    )
  }, [reportChartRef])

  return (
    <div className="report-chart-container">
      <Line
        ref={reportChartRef}
        options={reportChartData.options}
        data={reportChartData}
      />
    </div>
  )
}

export default ReportChart
