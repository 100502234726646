import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'

import ReportChart from './ReportChart'
import Tooltip from '../../shared/components/UIElements/Tooltip'

import './ReportChartCard.css'

const ReportChartCard = ({
  metric,
  adsetId,
  audienceType,
  title,
  description,
  delay = 500,
  isHidden,
  children,
}) => {
  const [show, setShow] = useState(false)
  const [positionStyle, setPositionStyle] = useState({})
  const timeoutRef = useRef(null)
  const reportChartCardContainerRef = useRef(null)

  const facebook = useSelector(state => state.facebook)
  const { insightsForChart, activeEnhCampaignIdForReports } = facebook

  const [reports, setReports] = useState(null)
  const [activeReport, setActiveReport] = useState(null)
  const [chartLabels, setChartLabels] = useState([])
  const [chartData, setChartData] = useState([])

  useEffect(() => {
    if (!insightsForChart) {
      return
    }

    if (
      !activeEnhCampaignIdForReports ||
      activeEnhCampaignIdForReports === 'allEnhCampaigns'
    ) {
      setReports(insightsForChart.overallReports)
      return
    }

    setReports(insightsForChart.dailyReportsByAdsetId)
  }, [insightsForChart, activeEnhCampaignIdForReports])

  useEffect(() => {
    if (!reports) {
      return
    }

    let key = audienceType

    if (
      activeEnhCampaignIdForReports &&
      activeEnhCampaignIdForReports !== 'allEnhCampaigns'
    ) {
      key = adsetId
    }

    const report = reports[key]

    if (!report) {
      return
    }

    setActiveReport(report)
  }, [reports, adsetId, audienceType, activeEnhCampaignIdForReports])

  useEffect(() => {
    if (!activeReport) {
      return
    }

    const labels = activeReport.map(r => {
      const parts = r.label.split('_')
      const date = parts.map(p => p.padStart(2, '0')).join('/')
      return date
    })
    const data = activeReport.map(r => r.insights[metric])

    setChartLabels(labels)
    setChartData(data)
  }, [activeReport, metric])

  useEffect(() => {
    if (!show || !reportChartCardContainerRef.current) {
      return
    }

    const card = document.querySelector('.report-chart-card')
    const child = reportChartCardContainerRef.current.children[0] // The element that the report chart card is attached to.

    const cardRect = card.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    const topAmount = childRect.top + childRect.height / 2 - cardRect.height / 2
    const leftAmount = childRect.left + childRect.width

    setPositionStyle({
      top: topAmount,
      left: leftAmount,
    })
  }, [show, reportChartCardContainerRef])

  const handleMouseEnter = e => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, delay)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current)
    setShow(false)
  }

  const notEnoughData =
    chartLabels.filter(Boolean).length < 3 ||
    chartData.filter(Boolean).length < 3

  if (notEnoughData || isHidden) {
    return (
      <Tooltip content={description} title="">
        {children}
      </Tooltip>
    )
  }

  return (
    <div
      className="report-chart-card-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={reportChartCardContainerRef}
      title=""
    >
      {children}
      {show &&
        ReactDOM.createPortal(
          <div style={positionStyle} className="report-chart-card">
            <span className='metric-title'>{title}</span>
            <ReportChart chartLabels={chartLabels} chartData={chartData} />
            <p className="metric-description">{description}</p>
          </div>,
          document.body,
        )}
    </div>
  )
}

export default ReportChartCard
