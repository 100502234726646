import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import gsap from 'gsap'
import AdCard from './AdCard'
import './AdList.css'

const WorstCreatives = () => {
  const { worstCreatives } = useSelector(
    state => state.adHealthCheckup.adsetsAndCreatives,
  )

  useEffect(() => {
    const tl = gsap.timeline()

    tl.from('.top-ads-list .inefficient .grey-line', {
      opacity: 0,
      duration: 0.8,
    })
    tl.from('.top-ads-list .inefficient .header', {
      x: 100,
      y: 150,
      opacity: 0,
      duration: 0.8,
    })

      .from('.top-ads-list .inefficient .detail-card.first', {
        y: 100,
        opacity: 0,
        duration: 0.8,
      })
      .from(
        '.top-ads-list .inefficient .detail-card.first .order',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )

      .from('.top-ads-list .inefficient .detail-card.second', {
        y: 100,
        opacity: 0,
        duration: 0.8,
      })
      .from(
        '.top-ads-list .inefficient .detail-card.second .order',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )

      .from('.top-ads-list .inefficient .detail-card.third', {
        y: 100,
        opacity: 0,
        duration: 0.8,
      })
      .from(
        '.top-ads-list .inefficient .detail-card.third .order',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )
  }, [])

  return (
    <div className="top-ads-content">
      <div className="top-ads-list content-box">
        <div className="inefficient">
          <div className="red-line">
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 1659 442"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="svg-path-red"
                opacity="0.2"
                d="M12 317L396 137C485 211 680 371.5 700.5 371.5C712.685 371.5 932 150 1047 39.5L1277 310.5L1450.5 384.5L1654.5 415"
                stroke="#E54343"
                strokeWidth="53"
              />
            </svg>
          </div>
          <div className="column">
            <div className="header">
              <div className="second-big-text">
                {' '}
                Most Inefficient Creatives with High Budget
              </div>
              <div className="secondary-text gradient-text">Last 12 months</div>
            </div>

            <div className="grid ad-card-list ">
              {worstCreatives.map((ad, index) => {
                const { ctr, budget, creativeId } = ad
                const order = index + 1
                const className = {
                  1: 'first',
                  2: 'second',
                  3: 'third',
                }[order]

                return (
                  <AdCard
                    key={creativeId}
                    type="inefficient"
                    {...{
                      order,
                      compareBy: 'CTR',
                      compareByValue: ctr,
                      additionalMetric: 'Budget',
                      additionalMetricValue: budget,
                      creativeId,
                      className,
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorstCreatives
