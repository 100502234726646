import React from 'react';

import './LoadingSpinner.css';

const LoadingSpinner = props => {
  return (
    <div>
      <div className="spinner-cont">
        <div className="bar bar-1"></div>
        <div className="bar bar-2"></div>
        <div className="bar bar-3"></div>
        <div className="bar bar-4"></div>
        <div className="bar bar-5"></div>
      </div>
      <div className="spinner-message">{props.message}</div>
    </div>
  );
};

export default LoadingSpinner;
