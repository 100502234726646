import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import './Tooltip.css'

const Tooltip = ({
  size = 'md',
  content,
  isHidden,
  delay = 500,
  offsetX = 10,
  offsetY = 0,
  children,
  ...props
}) => {
  const [show, setShow] = useState(false)
  const [positionStyle, setPositionStyle] = useState({})
  const tooltipContainerRef = useRef(null)
  const timeoutRef = useRef(null)

  /**
   * Currently, the tooltip is shown only on the right side of the child element.
   * TODO: Add support for showing the tooltip on every side of the child element.
   */
  useEffect(() => {
    if (!show || !content || !tooltipContainerRef.current) {
      return
    }

    const tooltip = document.querySelector('.tooltip')
    const child = tooltipContainerRef.current.children[0] // The element that the tooltip is attached to.

    const tooltipRect = tooltip.getBoundingClientRect()
    const childRect = child.getBoundingClientRect()

    const topAmount =
      childRect.top + childRect.height / 2 - tooltipRect.height / 2
    const leftAmount = childRect.left + childRect.width + offsetX

    setPositionStyle({
      top: topAmount,
      left: leftAmount,
    })
  }, [show, content, offsetY, offsetX, tooltipContainerRef])

  // TODO: Hide the tooltip when scrolling.
  const handleMouseEnter = e => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, delay)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current)
    setShow(false)
  }

  if (isHidden) {
    return children
  }

  return (
    <div
      className="tooltip-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="tooltip"
      ref={tooltipContainerRef}
      {...props}
    >
      {children}
      {show && content &&
        ReactDOM.createPortal(
          <div style={positionStyle} className={`tooltip ${size}`}>
            {content}
          </div>,
          document.body,
        )}
    </div>
  )
}

export default Tooltip
