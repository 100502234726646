import React, { useEffect, useState } from 'react'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'

import './CollapsibleCard.css'

function CollapsibleCard({ children, open, title, className }) {
  const storageKey = 'openedCollapsibleCards'
  const [isContentVisible, setContentVisible] = useState(() => {
    const openedCollapsibleCards = JSON.parse(localStorage.getItem(storageKey))
    return open || openedCollapsibleCards?.includes(title) || false
  })

  const toggleContentVisibility = () => {
    setContentVisible(prevState => {
      const openedCollapsibleCards = JSON.parse(
        localStorage.getItem(storageKey),
      )
      const updatedCards = openedCollapsibleCards || []

      if (prevState) {
        // Remove current card from the local storage.
        const cardIndex = updatedCards.indexOf(title)
        if (cardIndex !== -1) {
          updatedCards.splice(cardIndex, 1)
          localStorage.setItem(storageKey, JSON.stringify(updatedCards))
        }
      } else {
        // Insert current card to the local storage.
        updatedCards.push(title)
        localStorage.setItem(storageKey, JSON.stringify(updatedCards))
      }

      return !prevState
    })
  }

  useEffect(() => {
    const openedCollapsibleCards = JSON.parse(localStorage.getItem(storageKey))
    if (openedCollapsibleCards && openedCollapsibleCards.includes(title)) {
      setContentVisible(true)
    }
  }, [title])

  return (
    <div
      className={`collapsible-card ${className} ${
        isContentVisible ? 'open' : ''
      }`}
    >
      <div
        className="collapsible-card-header"
        onClick={toggleContentVisibility}
      >
        <h1>{title}</h1>
        <button className="toggle-button">
          {isContentVisible ? <FaAngleUp /> : <FaAngleDown />}
        </button>
      </div>
      {isContentVisible && (
        <div className="collapsible-card-content">{children}</div>
      )}
    </div>
  )
}

export default CollapsibleCard
