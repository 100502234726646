import React, { useMemo } from "react";


function DateRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
})  {
  const [min, max] = useMemo(() => {
    let min = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id]).getTime()
      : 0
    let max = preFilteredRows.length
      ? new Date(preFilteredRows[0].values[id]).getTime()
      : 0

    preFilteredRows.forEach(row => {
      min = Math.min(new Date(row.values[id]).getTime(), min)
      max = Math.max(new Date(row.values[id]).getTime(), max)
    })
 
    return [min, max]
  }, [id, preFilteredRows])

  return (
    <div>
      <input
        value={filterValue[0] || ''}
        type="date"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val || undefined, old[1]])
        }}
        style={{
          width: '95px',
          marginRight: '0.5rem',
        }}
       
      />
      -
      <input
        value={filterValue[1] || ''}
        type="date"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val || undefined])
        }}
        style={{
          width: '95px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  )
}

export default DateRangeColumnFilter
