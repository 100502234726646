import React, { useEffect, useState } from "react";
import ChecklistCard from "../../shared/components/UIElements/ChecklistCard";
import Button from "../../shared/components/FormElements/Button";
import { useDispatch, useSelector } from "react-redux";
import { useFacebook } from "../../shared/hooks/facebook-hook";
import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";

import "./AudienceCreationBox.css"
import { FaArrowCircleRight, FaArrowRight, FaCheckCircle, FaThumbsUp } from "react-icons/fa";

const AudienceCreationBox = ({
    hasAudiencesWithoutError
}) => {

    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth)
    const user = auth.user


    const facebookAuth = useSelector(state => state.facebook.auth)
    const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
    const selectedPixel = useSelector(state => state.facebook.selectedPixel)
    const audiences = useSelector(state => state.facebook.audiences)
    const adAccountAudiences = audiences ? audiences[selectedAdAccount?.id] : null

    const pixelSelectionIsEligible = useSelector(state => state.facebook.pixelSelectionIsEligible)
    const creatingAudiences = useSelector(state => state.facebook.creatingAudiences)

    const [isVerifying, setIsVerifying] = useState(false)

    const [audienceTypesWithError, setAudienceTypesWithError] = useState([])
    const [errorMessage, setErrorMessage] = useState()
    const [errorSubcode, setErrorSubcode] = useState()

    const audienceListIsEmpty = !adAccountAudiences || !Object.keys(adAccountAudiences).length

    const { createAudiencesForTheFirstTime, retryAudienceCreation } = useFacebook()

    useEffect(() => {
        const check = () => {
            let errorMessage;
            let errorSubcode;

            if (selectedAdAccount && selectedPixel?.id && adAccountAudiences) {
                let e = []
                Object.keys(adAccountAudiences).forEach(audienceType => {
                    let audienceList = adAccountAudiences[audienceType]
                    if (audienceList[audienceList.length - 1]?.error) {
                        e.push(audienceType)
                        errorMessage = audienceList[audienceList.length - 1].error.error_user_msg || audienceList[audienceList.length - 1].error.message
                        errorSubcode = audienceList[audienceList.length - 1].error.error_subcode
                    }
                })
                setErrorSubcode(errorSubcode)
                setErrorMessage(errorMessage)
                setAudienceTypesWithError(e)
            } else {

            }
        }

        check()
    }, [selectedAdAccount, selectedPixel])

    const correctErrors = async () => {
        setIsVerifying(true);
        try {
            await retryAudienceCreation()
            setIsVerifying(false);
        } catch (err) {

        }
    }

    return <ChecklistCard className="audience-creation" number={6} name="Create AI Audiences" completed={hasAudiencesWithoutError}>

        {
            creatingAudiences ? <InlineLoadingSpinner message="Enhencer is creating audiences for you, please do not leave the page" /> :

                audienceListIsEmpty ? <div>
                    <span>
                        We prepared AI audiences customized for you
                        <br />
                        <br />
                    </span>

                    <Button onClick={createAudiencesForTheFirstTime} primary>
                        Send audiences to Meta
                    </Button>
                </div>
                    :
                    facebookAuth && !facebookAuth.loggedOut && pixelSelectionIsEligible && errorMessage ? <div className="audience-error-box">
                        <div className="row">
                            <FaArrowCircleRight size={50} color="var(--main-green)"></FaArrowCircleRight>
                            <div>
                                Audience Creation Prevented by Facebook:
                                <br />
                                <br />
                                <div className="err-message">{errorMessage}</div>
                                {errorSubcode === 1713092 &&
                                    <span className="err-message"><br></br> * This issue might be caused by the account payment issue, please check your payment methods.</span>
                                }
                                <div className="helper-text">If you have solved this issue on{' '}
                                    {errorMessage.includes("2663")
                                        ? <a href={`https://business.facebook.com/ads/manage/customaudiences/tos/?act=${selectedAdAccount.account_id}`}>
                                            Accept TOS on Facebook
                                        </a>
                                        : <a href={`https://adsmanager.facebook.com/adsmanager/audiences?act=${selectedAdAccount.account_id}`}>
                                            Facebook Audience Manager
                                        </a>}
                                </div>

                                <Button onClick={correctErrors} primary>
                                    Retry Creating Audiences
                                </Button>
                            </div>
                        </div>

                    </div>
                        :
                        <div className="all-set">
                            <FaThumbsUp className="icon" /> <br /><br />
                            <div>
                                Your AI audiences are all set! You can create full-funnel campaigns.
                            </div>
                        </div>
        }


    </ChecklistCard>
}

export default AudienceCreationBox;