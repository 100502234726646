import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  VALIDATOR_MAX,
  VALIDATOR_MIN,
  VALIDATOR_REQUIRE,
} from '../../shared/util/validators'
import helper from '../../shared/util/helper'
import { useForm } from '../../shared/hooks/form-hook'
import Input from '../../shared/components/FormElements/Input'
import CountriesInput from './CountriesInput'

import './CampaignForm.css'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'

const CampaignTargetingForm = () => {
  const [isRegionsValid, setIsRegionsValid] = useState(true)

  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const facebookSettings = useSelector(state => state.facebook.settings)
  const targeting = useSelector(state => state.facebookCampaignEditor.currentCampaign?.targeting)

  const [textupdateTimer, setTextUpdateTimer] = useState()
  const { changeTargeting, startLoadingState, endLoadingState } = useFacebookCampaignEditor()
  const [shouldShowErrorsForEmptyFields, setShouldShowErrorsForEmptyFields] = useState(false)


  const generateFormStateFromTargetingData = () => {
    return {
      dailyBudget: {
        value: targeting?.dailyBudget,
        isValid: !!targeting?.dailyBudget,
      },
      genders: {
        value: targeting?.genders ? helper.getGenderString(targeting.genders) : 'All',
        isValid: true,
      },
      ageMin: {
        value: targeting?.age?.min || 18,
        isValid: true,
      },
      ageMax: {
        value: targeting?.age?.max || 65,
        isValid: true,
      }
    }
  }

  const [formState, inputHandler, setFormData] = useForm(generateFormStateFromTargetingData(), true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShowErrorsForEmptyFields(true)
    }, 7000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {

    if (textupdateTimer) {
      clearTimeout(textupdateTimer)
    }
    startLoadingState("isProcessingTextFields")
    let timer = setTimeout(() => {
      const { dailyBudget, genders, ageMin, ageMax } = formState.inputs
      changeTargeting({
        isDailyBudgetMoreThanMinimum: dailyBudget.value >= facebookSettings.minDailyBudget,
        dailyBudget: dailyBudget.value,
        genders: genders.value,
        age: {
          min: ageMin.value,
          max: ageMax.value,
        }
      })
      endLoadingState("isProcessingTextFields")
    }, 3000);
    setTextUpdateTimer(timer)

    return () => { clearTimeout(textupdateTimer) }

  }, [formState.inputs])


  useEffect(() => {
    setIsRegionsValid(!!targeting?.countries?.length)
  }, [targeting?.countries])

  return (
    <div className="campaign-form">
      <h3>Targeting</h3>
      <div className="row">
        <label>Countries:</label>
        <CountriesInput />
      </div>

      {!isRegionsValid && shouldShowErrorsForEmptyFields && (
        <div className="error row">
          <label>&nbsp;</label>
          <p className="error-text">Please select at least one country</p>
        </div>
      )}

      <div className="row col-2">
        <div className="col">
          <label>Daily Budget:</label>

          <div className="daily-budget-input">
            <span>
              {helper.getCurrencySymbol(
                selectedAdAccount?.currency || 'USD',
              )}
            </span>
            <Input
              element="input"
              type="number"
              initialValue={formState.inputs.dailyBudget.value}
              id="dailyBudget"
              onInput={inputHandler}

              validators={[
                VALIDATOR_REQUIRE(),
                VALIDATOR_MIN(facebookSettings.minDailyBudget),
              ]}
            />
          </div>
        </div>
        <div className="col"></div>
      </div>

      {!formState.inputs.dailyBudget.isValid && (
        <div className="error row col-2">
          <div className="col">
            <label>&nbsp;</label>
            <p className="error-text">
              {formState.inputs.dailyBudget.value &&
                formState.inputs.dailyBudget.value < facebookSettings.minDailyBudget
                ? `Budget must be more than ${helper.getCurrencySymbol(
                  selectedAdAccount.currency
                )}${facebookSettings.minDailyBudget.toFixed(1)}`
                : shouldShowErrorsForEmptyFields ? 'Please enter a daily budget' : null}
            </p>
          </div>
          <div className="col"></div>
        </div>
      )}

      <div className="row">
        <label>Gender:</label>
        <Input
          element="dropdown"
          type="text"
          id="genders"
          initialValue={formState.inputs.genders.value ?? "All"}
          onInput={inputHandler}
          options={['All', 'Female', 'Male']}
          validators={[VALIDATOR_REQUIRE()]}
        />
      </div>

      <div className="row col-2">
        <div className="col">
          <label>Age:</label>

          <>
            <Input
              element="input"
              type="number"
              id="ageMin"
              placeholder="Min"
              initialValue={formState.inputs.ageMin.value}
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MIN(0)]}
            />
            <span className="dash">-</span>
            <Input
              element="input"
              type="number"
              id="ageMax"
              placeholder="Max"
              initialValue={formState.inputs.ageMax.value}
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAX(120)]}
            />
          </>
        </div>
        <div className="col"></div>
      </div>

      {(!formState.inputs.ageMin.isValid || !formState.inputs.ageMax.isValid) && (
        <div className="error row">
          <label>&nbsp;</label>
          <p className="error-text">Please enter a valid age range</p>
        </div>
      )}
    </div>
  )
}

export default CampaignTargetingForm
