import React, { useEffect, useState } from "react";
import { FaCheck, FaFacebook, FaFacebookF, FaList, FaSearch, FaTimes } from "react-icons/fa";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth";

import "./ConnectFacebook.css"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";
import helper from "../../shared/util/helper";
import { useFacebook } from "../../shared/hooks/facebook-hook";
import { useFacebookAuth } from "../../shared/hooks/facebook-auth-hook";

const ConnectFacebook = props => {

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useSelector(state => state.auth)
  const user = auth.user
  const dispatch = useDispatch()
  const [adAccounts, setAdAccounts] = useState([])
  const location = useLocation()
  const [searchText, setSearchText] = useState("")
  const navigate = useNavigate()
  const [fromPage, setFromPage] = useState()
  const currentCampaignId = useSelector(state => state.project.currentCampaignId)
  const facebook = useSelector(state => state.facebook)
  const isLoggedIn = facebook.isLoggedIn

  const {
    isGettingAdAccounts,
    selectPixel, selectAdAccount, selectBusiness
  } = useFacebook()
  const { logOut, loginCallback } = useFacebookAuth()


  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let fr = query.get("f")
    if (fr) {
      setFromPage(fr)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      setUserConnectFlowDates()
    }
  }, [isLoggedIn])


  const setUserConnectFlowDates = () => {
    let connectFlow = { ...auth.user.connectFlow } || {}
    if (!connectFlow.loggedInToFacebook) {
      connectFlow["loggedInToFacebook"] = Date.now()

      try {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/updateConnectFlow`,
          "POST",
          JSON.stringify({
            userId: auth.user.id,
            connectFlow: connectFlow
          }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );
        dispatch(authActions.updateConnectFlow(connectFlow))

      } catch (err) {
        toast.error(err?.message || "Something went wrong")
      }

    }

  }

  // do it if coming from profit page
  // if (fromPage) {
  //     toast.success("Ad account selected successfully, redirecting to profit page.")
  //     setTimeout(() => {
  //       navigate(`/ads-manager/${currentCampaignId}/profit`)
  //     }, 1000);
  //   }

  const filterAdAccountsBySearch = (item, search) => {
    if (item.name.toLowerCase().includes(search.toLowerCase())) {
      return true
    } else {
      return false
    }
  }

  return <div className="connect-facebook">
    <div className="result-card">
      <div className="card-header">
        <div className="row">
          <FaFacebook className="icon" style={{ fontSize: "1.7em" }} />
          <h4>Connect Facebook Ads Account</h4>
        </div>
      </div>
      <div className="card-content">
        {isLoggedIn ?
          <div className="facebook-logged-in">
            <div className="row">

              <img alt="url" src={facebook.auth.picture.data.url} />
              <div>
                <p>
                  You are logged in as <b>{facebook.auth.name}</b>
                </p>
                <Button onClick={logOut}>Logout</Button>
              </div>
            </div>
          </div> :
          <div>

            <p className="connect-text">
              Enhencer needs to connect to your Facebook Ads account to get the campaign results. Please log in below.
            </p>

            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={loginCallback}
              scope="email,public_profile,pages_show_list,pages_read_engagement,ads_read,ads_management,business_management,catalog_management"
              fields="name,email,picture"
              render={renderProps => (
                <Button className="fb-login-button" onClick={renderProps.onClick}>
                  <FaFacebookF className="icon" />
                  Log in to your account
                </Button>
              )}
            />
          </div>}
      </div>

    </div>

    {
      isLoggedIn && <div className="result-card ad-accounts-card" style={{ marginTop: "2em" }}>
        <div className="card-header">
          <div className="row">
            <FaList className="icon" />
            <h4>Select an Ad Account</h4>
          </div>
          {facebook.adAccounts.length > 0 && !user.isSpecialUser &&
            <div className="search-bar">
              <FaSearch className="icon" />
              <input type="text" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} placeholder='Search Ad Accounts' />
              {searchText.length > 0 && <FaTimes className="clear-icon" onClick={() => { setSearchText("") }} />}
            </div>
          }
        </div>
        {user.isSpecialUser ?
          <div style={{ margin: "10px" }}>Logged in, please select the ad account on <Link to={`/ads-manager/${currentCampaignId}/profit`}>profit page</Link></div>
          :
          isGettingAdAccounts ? <InlineLoadingSpinner></InlineLoadingSpinner> : <div className="ad-accounts">

            <p style={{ padding: "0 5px" }}>
              Select the ad account connected to your website
            </p>



            <div className="ad-account-select">
              {facebook.adAccounts.filter(adAccount => { return filterAdAccountsBySearch(adAccount, searchText) }).length > 0 ?
                <ul>
                  {facebook.adAccounts.filter(adAccount => { return filterAdAccountsBySearch(adAccount, searchText) }).map(adAccount =>
                    <li key={adAccount.id} onClick={() => { selectAdAccount(adAccount) }} className={adAccount.account_id === facebook?.selectedAdAccount?.account_id ? 'selected' : ''}>
                      <div>
                        <div className="name">
                          {adAccount.name}
                        </div>
                        <div className="id">
                          Ad account ID: {adAccount.account_id}
                        </div>
                      </div>
                      <FaCheck className="icon" />
                    </li>
                  )}

                </ul>
                :
                facebook?.selectedAdAccount?.account_id ?
                  <div className="no-account-text">Selected ad account: {facebook?.selectedAdAccount?.name}</div>
                  :
                  <div className="no-account-text">No ad account could be found.</div>}
            </div>

            {/* <Button onClick={getAudiences}>
              Test It Out
            </Button> */}

          </div>

        }

      </div>
    }

  </div>
}

export default ConnectFacebook;