import React from "react";
import {  useDispatch } from 'react-redux';
import GoogleLogin from "react-google-login";
import { toast } from "react-toastify";

import googleLogo from "../../shared/assets/images/icons/google-logo.png";
import shopifyLogo from "../../shared/assets/images/icons/shopify_logo_black.png";
import { authActions } from "../../store/auth";
import { generalActions } from "../../store/general";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useModal } from "../../shared/hooks/modal-hook";

const LoginOptions = props => {

    return <React.Fragment>
        <div className="or-row">
            <div className="left-line"></div>
            <div className="text">
                OR
            </div>
            <div className="right-line"></div>
        </div>
        <div>
            <Button className="action-button log-in-with-shopify" to="https://apps.shopify.com/enhencer-1">
                <img alt="shopify" src={shopifyLogo} />
                <span>
                    Continue with Shopify
                </span>
            </Button>
        </div>

    </React.Fragment>
}

export default LoginOptions;