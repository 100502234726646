import React from 'react'
import './CatalogPreview.css'

const CatalogPreview = () => {
  return (
    <div className="catalog-preview">
      <div className="header">
        <h2>Enhanced Catalog</h2>
        <span>Preview</span>
      </div>
      <div className="preview-area"></div>
      <div className="try-it">Try on different products:</div>
      <div className="product-selector">
        {[...Array(20)].map((_, index) => (
          <div key={index} className="product-item"></div>
        ))}
      </div>

      <div href="#" className="manage-products">
        Manage products
      </div>
      <button className="generate-button">Generate</button>
    </div>
  )
}

export default CatalogPreview
