import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaSave, FaTimes } from "react-icons/fa";

import "./AudienceManager.css"
import "../components/ManagerPage.css"

import filterIcon from "../../shared/assets/images/icons/filter-icon.svg"
import cogIcon from "../../shared/assets/images/icons/cog-icon.svg"
import cogIconGreen from "../../shared/assets/images/icons/cog-icon-green.svg"


import SegmentList from "../components/SegmentList";
import { projectActions } from "../../store/project";
import helper from "../../shared/util/helper";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import SegmentDetail from "../components/SegmentDetail";
import AudiencesSummary from "../components/AudiencesSummary";
import Button from "../../shared/components/FormElements/Button";
import AudienceSettings from "../components/AudienceSettings";
import { useConstants } from "../../shared/hooks/constants-hook";

const AudienceManager = props => {
    const { publicConstants } = useConstants()
    const location = useLocation();
    const dispatch = useDispatch();

    const [segmentDetailIndex, setSegmentDetailIndex] = useState(0)
    const authUserId = useSelector((state) => state.auth.user.id);
    const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser)
    const isDemoUser = useSelector(state => state.auth.user.isDemo)
    const authToken = useSelector(state => state.auth.token)
    const userInViewId = useSelector(state => state.admin.userInView?.id)
    

    const { sendRequest } = useHttpClient();

    const campaign = useSelector(state => state.project.currentCampaign);
    
    

    const [targetChoices, setTargetChoices] = useState(null);
    const [audienceSettingsOpen, setAudienceSettingsOpen] = useState(false)

    const [uplift, setUplift] = useState()
    const [topVisitorsPercentage, setTopVisitorsPercentage] = useState()

    const [isChartsCardLoading, setIsChartsCardLoading] = useState(false)


    useEffect(() => {
        if (campaign && campaign.audience?.segments) {
            let overallSize = 0
            let selectedSize = 0
            campaign.audience.segments.forEach(s => {
                if (s.isSelected) {
                    selectedSize += s.count
                }
                overallSize += s.count
            })

            const selectedSegments = campaign.audience.segments ? helper.findAll(campaign.audience.segments, "isSelected", true) : [];
            const percentage = campaign.audience.expected ? (selectedSize / overallSize * 100).toFixed(1) : ""
            const lift = campaign.audience.expected ? (campaign.audience.expected.propensity / campaign.audience.overall.propensity).toFixed(1) : ""
            setTopVisitorsPercentage(percentage)
            setUplift(lift)
            setAudienceSettingsOpen(false)

            if (location.state && location.state.targetChoices) {
                setTargetChoices(location.state.targetChoices);
            }
        }
    }, [campaign])

    const onToggleSegment = async (index) => {
        if (isSpecialUser) {


            setIsChartsCardLoading(true)
            let segments = JSON.parse(JSON.stringify(campaign.audience.segments));
            let selectedSegmentNos = JSON.parse(JSON.stringify(campaign.audience.selectedSegmentNos));
            if (index + 1 === selectedSegmentNos.length) {
                return true
            } else {
                let lengthOfSelectedSegmentNos = selectedSegmentNos.length;
                if (index + 1 < lengthOfSelectedSegmentNos) {
                    for (let i = index + 1; i < lengthOfSelectedSegmentNos; i++) {
                        segments[i].isSelected = false;
                    }
                    selectedSegmentNos.splice(index + 1, lengthOfSelectedSegmentNos - index - 1);
                } else {
                    for (let i = lengthOfSelectedSegmentNos; i < index + 1; i++) {
                        segments[i].isSelected = true;
                        selectedSegmentNos.push(i + 1);
                    }
                }
            }



            try {
                const requestType = "segment";
                const payload = {
                    selectedSegmentNos: selectedSegmentNos
                };
                const data = createDataForRequest(requestType, payload);
                let url = createUrlForRequest();
                const result = await sendRequest(
                    url,
                    "POST",
                    JSON.stringify(data),
                    {
                        Authorization: "Bearer " + authToken,
                        "Content-Type": "application/json",
                    }
                );
                const newAudience = {
                    ...campaign.audience,
                    selectedSegmentNos: selectedSegmentNos,
                    segments: segments,
                    expected: result.expected,
                    live: result.live,
                    expectedConvRateData: result.expectedConvRateData,
                    expectedNumOfVisitorsData: result.expectedNumOfVisitorsData,
                    expectedLabels: result.expectedLabels,
                };
                setIsChartsCardLoading(false)
                setSegmentDetailIndex(index)
                dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { audience: newAudience } }));
                return true
            } catch (err) {
                toast.error(err?.message || "Something went wrong")
                return false
            }
        } else {
            setSegmentDetailIndex(index)
        }
    }

    const onDayChange = async (newDayOption) => {
        const dayObj = helper.findBy(publicConstants.audienceResultIntervals, "name", newDayOption);
        const day = dayObj.days
        try {
            const requestType = "day";
            const payload = {
                day: day
            };
            const data = createDataForRequest(requestType, payload);
            let url = createUrlForRequest();
            const result = await sendRequest(
                url,
                "POST",
                JSON.stringify(data),
                {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                }
            );
            const newAudience = {
                ...campaign.audience,
                expected: result.expected,
                live: result.live
            };

            dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { audience: newAudience } }));
        } catch (err) {
            toast.error(err?.message || "Something went wrong")
        }
    }

    const createDataForRequest = (requestType, payload) => {
        let data;
        if (requestType === "day") {
            data = {
                audiencePath: campaign.audiencePath,
                selectedSegmentNos: campaign.audience.selectedSegmentNos,
                day: payload.day,
                includePurchased: campaign.audience.includePurchased,
                includeAddtoBasket: campaign.audience.includeAddtoBasket
            };
        } else if (requestType === "segment") {
            data = {
                audiencePath: campaign.audiencePath,
                selectedSegmentNos: payload.selectedSegmentNos,
                day: campaign.audience.day,
                includePurchased: campaign.audience.includePurchased,
                includeAddtoBasket: campaign.audience.includeAddtoBasket,
                isToggleSegment: true
            };
        } else if (requestType === "include-purchased") {
            data = {
                audiencePath: campaign.audiencePath,
                selectedSegmentNos: campaign.audience.selectedSegmentNos,
                day: campaign.audience.day,
                includePurchased: payload.includePurchased,
                includeAddtoBasket: campaign.audience.includeAddtoBasket
            }
        }
        if (isDemoUser) {
            data.isDemo = true;
        } else {
            if (isSpecialUser) {
                data.userId = userInViewId;
            }
        }
        return data;
    }

    const createUrlForRequest = () => {
        let url;
        return `${process.env.REACT_APP_BACKEND_URL}/${isSpecialUser ? 'admin' : 'projects'}/calculateValues`
    }

    const openSegmentDetail = (i) => {
        setSegmentDetailIndex(i)
    }

    const saveAudience = async (multi) => {
        try {
            let url;
            let data;

            if (isSpecialUser) {
                url = `${process.env.REACT_APP_BACKEND_URL}/admin/updateCampaignAudience`;
                data = JSON.stringify({
                    userId: userInViewId,
                    campaignId: campaign.id,
                    audience: campaign.audience,
                    multi: multi
                });
            } else {
                if (isDemoUser) {
                    url = `${process.env.REACT_APP_BACKEND_URL}/projects/updateCampaignAudience`;
                    data = JSON.stringify({
                        isDemo: true,
                        audiencePath: campaign.audiencePath,
                        audience: campaign.audience
                    });
                } else {
                    url = `${process.env.REACT_APP_BACKEND_URL}/projects/updateCampaignAudience`;
                    data = JSON.stringify({
                        userId: authUserId,
                        campaignId: campaign.id,
                        audience: campaign.audience
                    });
                }
            }

            await sendRequest(url, "POST", data,
                {
                    Authorization: "Bearer " + authToken,
                    "Content-Type": "application/json",
                }
            );

            if (campaign.audience.isDemo) {
                // dispatch(projectActions.setAudience({ index: -1, audience: audience, isDemo: true }));
            } else {
                dispatch(projectActions.updateCurrentCampaign({ isDemo: isDemoUser, campaign: { audience: campaign.audience } }))

            }
            toast.success("Audience saved!");
        } catch (err) {
            toast.error(err?.message || "Something went wrong")
        }
    }

    const toggleAudienceSettings = () => {
        setAudienceSettingsOpen(!audienceSettingsOpen)
    }

    return <div className="audience-manager manager-page">
        {campaign && campaign.audience?.segments && (
            <div className="middle-container column">
                <div className="result-card" style={{ flex: "1 1 40%", overflow: "initial" }}>
                    {((isSpecialUser) && targetChoices) && <div className="target-choices">
                        {targetChoices.map((targetChoice) => (
                            <div key={targetChoice.name} className={targetChoice.usageLabel === "Use" ? "use-target-choice green" : "use-target-choice gray"}>
                                {targetChoice.name}
                            </div>
                        ))}
                    </div>}
                    <AudiencesSummary isChartLoading={isChartsCardLoading} enhencerAudience={{
                        expected: campaign.audience.expected,
                        live: campaign.audience.live,
                        expectedConvRateData: campaign.audience.expectedConvRateData,
                        expectedNumOfVisitorsData: campaign.audience.expectedNumOfVisitorsData,
                        expectedLabels: campaign.audience.expectedLabels,
                        liveConvRateData: campaign.audience.liveConvRateData,
                        liveNumOfVisitorsData: campaign.audience.liveNumOfVisitorsData,
                        liveLabels: campaign.audience.liveLabels,
                    }} overall={campaign.audience.overall}
                        allVisitorsConvRateData={campaign.audience.allVisitorsConvRateData}
                        allVisitorsNumOfVisitorsData={campaign.audience.allVisitorsNumOfVisitorsData}
                        allVisitorsLabels={campaign.audience.allVisitorsLabels}
                        onDayChange={onDayChange} />

                </div>

                <div className="result-card second">
                    <div className="card-header">

                        {audienceSettingsOpen ?
                            <React.Fragment>

                                <div className="row">
                                    <img alt="cogicon" src={cogIcon} className="filters-toggle" />

                                    <h4><span className="green">Filter Category Bundles</span></h4>
                                </div>
                                <FaTimes style={{ fontSize: "1.2em", cursor: "pointer" }} onClick={() => { setAudienceSettingsOpen(false) }} />
                            </React.Fragment>
                            :
                            <React.Fragment>

                                <div className="row">
                                    <img alt="filtericon" src={filterIcon} />
                                    <h4>Top <span style={{ fontSize: "1.1em" }}>{topVisitorsPercentage}%</span> of Visitors - <span className="green">Enhencer Remarketing Audience</span></h4>
                                </div>
                                <div className="">

                                    {isDemoUser ?
                                        <div className="sample-text">
                                            Sample AI Segments
                                        </div>
                                        :
                                        <img alt="cogicon2" src={campaign.filterBundles.length > 0 ? cogIconGreen : cogIcon} onClick={toggleAudienceSettings} className="filters-toggle" />
                                    }

                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <div className="card-content">
                        {audienceSettingsOpen ?
                            <div className="audience-settings-container">
                                <AudienceSettings audience={campaign.audience} filterBundles={campaign.filterBundles} />
                            </div>
                            :
                            <div className="row segmentation-row">
                                {campaign.audience.segments?.length > 0 && <SegmentList openSegmentDetail={openSegmentDetail} segmentDetailIndex={segmentDetailIndex} segments={campaign.audience.segments} onToggleSegment={onToggleSegment} overallPropensity={campaign.audience.overall?.propensity} />}
                                <div style={{ flex: "1 1 50%" }}>
                                    {campaign.audience.segments?.length > 0 && <SegmentDetail segment={campaign.audience.segments[segmentDetailIndex]} segmentNo={segmentDetailIndex + 1} numberOfSegments={campaign.audience.segments.length} />}
                                    {(isSpecialUser) && <Button className="save-audience-button" onClick={() => { saveAudience(false) }}>
                                        <FaSave />
                                        Save Audience
                                    </Button>}
                                    {(isSpecialUser) && <Button className="save-audiences-button" onClick={() => { saveAudience(true) }}>
                                        <FaSave />
                                        Save All {campaign.audienceName === "Remarketing Audience" ? "Remarketing" : campaign.audienceName === "High Intent Remarketing Audience" ? "High Intent Remarketing" : "Lookalike"} Audiences
                                    </Button>}

                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>)
        }

    </div>
}

export default AudienceManager;