import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useHttpClient } from './http-hook'
import { adminActions } from '../../store/admin'
import helper from '../util/helper'
import constants from '../../constants'
import pricing from '../../constants-pricing'

/**
 * It provides various functionalities for managing
 * public constants data used by regular users, as well as
 * private constants data that comes from the server and is
 * used by authorized users (e.g., admin, developer, etc.).
 * @returns {Object}
 */
export const useConstants = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const admin = useSelector(state => state.admin)

  const authToken = auth.token

  const publicConstants = constants
  const pricingConstants = pricing
  const privateConstants = useSelector(state => state.admin.privateConstants)

  useEffect(() => {
    const getPrivateConstants = async () => {

      const isAuthorizedUser = auth.user && auth.user.isSpecialUser

      if (!authToken || !isAuthorizedUser) {
        return
      }

      const data = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/constants`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json'
        },
      )

      dispatch(adminActions.setPrivateConstants(data))
    }

    if (!admin.areConstantsReady) {
      getPrivateConstants()
    }

  }, [auth.user, authToken])

  return {
    publicConstants,
    privateConstants,
    pricingConstants,
  }
}
