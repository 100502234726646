'use client'

import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'

import oliviaJLogo from '../../shared/assets/images/clients/oliviaj.png'
import swoonLondonLogo from '../../shared/assets/images/clients/swoon-london.jpg'
import manzaraLogo from '../../shared/assets/images/clients/manzara.webp'
import andiisTreasuresLogo from '../../shared/assets/images/clients/andiis-treasures.png'
import unitedStatesFlag from '../../shared/assets/images/clients/flags/united-states.png'
import unitedKingdomFlag from '../../shared/assets/images/clients/flags/united-kingdom.png'
import czechRepublicFlag from '../../shared/assets/images/clients/flags/czech-republic.png'
import canadaFlag from '../../shared/assets/images/clients/flags/canada.png'
import starsImage from '../../shared/assets/images/clients/stars.png'
import dividerImage from '../../shared/assets/images/clients/divider-green.svg'

import 'swiper/css'
import './TestimonialsSwiper.css'

const TestimonialsSwiper = () => {
  return (
    <div className="testimonials-carousel">
      <div className="header">
        <h1>
          We love our job, and{' '}
          <span>
            our customers <br />
            across 42+ countries love us!
          </span>
        </h1>
      </div>

      <Swiper
        modules={[Autoplay]}
        className="testimonials"
        spaceBetween={40}
        slidesPerView={4}
        navigation={false}
        breakpoints={{
          0: { slidesPerView: 1 },
          650: { slidesPerView: 1 },
          950: { slidesPerView: 1 },
          1245: { slidesPerView: 2 },
          1500: { slidesPerView: 3 },
          2100: { slidesPerView: 3 },
        }}
      >
        <SwiperSlide>
          <div className="testimonials-card">
            <div className="card-header">
              <div className="name">
                <img
                  src={oliviaJLogo}
                  alt="Olivia J"
                  className="logo oliviaj"
                />
                <div>Olivia J.</div>
                <img
                  src={unitedStatesFlag}
                  alt="United States Flag"
                  className="flag united-states"
                />
              </div>
              <div>
                <img
                  src={starsImage}
                  alt="5 stars for rating"
                  className="stars"
                />
              </div>
            </div>
            <div className="text-part">
              <img src={dividerImage} alt="Divider" className="divider" />
              <p>
                I have to admit that I was a bit skeptical when we first
                starting working with Enhencer, but I&apos;ve definitely been
                proven wrong. In the past, ads have simply not worked for us
                regardless of what we tried. With Enhencer, we have seen
                incredible results.
              </p>
            </div>
          </div>
        </SwiperSlide>
        
        <SwiperSlide>
          <div className="testimonials-card">
            <div className="card-header">
              <div className="name">
                <img
                  src={swoonLondonLogo}
                  alt="Swoon London"
                  className="logo swoon-london"
                />
                <div>Swoon London</div>
                <img
                  src={unitedKingdomFlag}
                  alt="United Kingdom Flag"
                  className="flag united-kingdom"
                />
              </div>
              <div>
                <img
                  src={starsImage}
                  alt="5 stars for rating"
                  className="stars"
                />
              </div>
            </div>
            <div className="text-part">
              <img src={dividerImage} alt="Divider" className="divider" />
              <p>
                Simple to use, performs as it says. My ROAS has increased nearly
                2x since using the Enhencer app over a three week period. Great
                customer support from Gorkem & the team. Highly recommended.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonials-card">
            <div className="card-header">
              <div className="name">
                <img
                  src={andiisTreasuresLogo}
                  alt="Andiis Treasures"
                  className="logo andiis-treasures"
                />
                <div>Andiis Treasures</div>
                <img
                  src={canadaFlag}
                  alt="Canada Flag"
                  className="flag canada"
                />
              </div>
              <div>
                <img
                  src={starsImage}
                  alt="5 stars for rating"
                  className="stars"
                />
              </div>
            </div>
            <div className="text-part">
              <img src={dividerImage} alt="Divider" className="divider" />
              <p>
                Delighted to know that you&apos;re finding Enhencer amazing! Our
                team is dedicated to providing top-notch support and ensuring
                your experience is seamless. If there&apos;s anything specific
                you&apos;d like assistance with or if you have questions about
                maximizing your results with Enhencer, feel free to reach out.
                Your success is our priority!
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonials-card">
            <div className="card-header">
              <div className="name">
                <img src={manzaraLogo} alt="Manzara" className="logo manzara" />
                <div>Manzara</div>
                <img
                  src={czechRepublicFlag}
                  alt="Czech Republic Flag"
                  className="flag czech-republic"
                />
              </div>
              <div>
                <img
                  src={starsImage}
                  alt="5 stars for rating"
                  className="stars"
                />
              </div>
            </div>
            <div className="text-part">
              <img src={dividerImage} alt="Divider" className="divider" />
              <p>
                Amazing app, we have Enhencer campaigns in all 14 EU markets and
                in almost all markets they bring the highest ROAS of all other
                campaigns both on FB and n GOOGLE. The results are visible very
                quickly, mostly within a few days, and they continue to improve
                later.
              </p>
            </div>
          </div>
        </SwiperSlide>

      </Swiper>
    </div>
  )
}

export default TestimonialsSwiper
