import React, { useEffect, useMemo, useState } from 'react'
import { FaCog, FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useSelector, useStore } from 'react-redux'

import { useModal } from '../../shared/hooks/modal-hook'
import { useFacebook } from '../../shared/hooks/facebook-hook'
import { useAdmin } from '../../shared/hooks/admin-hook'
import countriesData from '../../shared/data/countries.json'
import helper from '../../shared/util/helper'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import CampaignLabel from './CampaignLabel'
import Switch from '../../shared/components/UIElements/Switch'
import PublishButton from './PublishButton'
import CancelButton from './CancelButton'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import AdCreativePreviewer from './AdCreativePreviewer'
import Button from '../../shared/components/FormElements/Button'

import './CampaignDetail.css'
import CampaignDetailErrors from './CampaignDetailErrors'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'
import CampaignTargetingForm from './CampaignTargetingForm'
import AdCreativeMaker from './AdCreativeMaker'
import CampaignCreativesSection from './CampaignCreativesSection'
import CampaignCreativesSectionEditMode from './CampaignCreativesSectionEditMode'
import TrafficCampaignSection from './TrafficCampaignSection'
import LoadingDotsSpinner from '../../shared/components/UIElements/LoadingDotsSpinner'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'
import Tooltip from '../../shared/components/UIElements/Tooltip'

const CampaignDetail = ({ campaign }) => {
  const { openModal } = useModal()
  const store = useStore()
  const { navigateWithAdminParams } = useAdmin()
  const oldCampaign = { ...campaign }
  const effectiveStatus =
    campaign.reports?.campaignLevel?.effectiveStatus || 'NO INFO'
  const facebook = useSelector(state => state.facebook)
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const {
    updateCampaign,
    updateCampaignStatus,
    deleteCampaign,
  } = useFacebook()
  const [switchEnabled, setSwitchEnabled] = useState(
    effectiveStatus === 'ACTIVE' ? 'Active' : 'Off',
  )
  const [label, setLabel] = useState(campaign?.label || '')

  const [errors, setErrors] = useState({})

  const { startExistingCampaignEditing, endCampaignEditing } = useFacebookCampaignEditor()

  const [currentCreative, setCurrentCreative] = useState(Object.values(campaign.adCreatives)[0])

  const isAdAcountCorrect = campaign?.adAccountId === facebook.selectedAdAccount.id
  const isPixelCorrect = campaign?.pixelId === facebook.selectedPixel.id
  const isBusinessCorrect = campaign?.businessId === facebook.selectedBusiness.id
  const isProcessing = useSelector(
    state => state.facebookCampaignEditor?.loadings.isProcessingTextFields,
  )

  console.log("CAMPAIGNN", campaign)



  useEffect(() => {
    const { adCampaign, adsets, ads, adCreative } = campaign
    const structure = {
      adCampaign: adCampaign?.error,
      adCreative: adCreative?.error,
      adsets: {
        remarketing: adsets?.remarketing?.error,
        lookalike: adsets?.lookalike?.error,
        broad: adsets?.broad?.error,
      },
      ads: {
        remarketing: ads?.remarketing?.error,
        lookalike: ads?.lookalike?.error,
        broad: ads?.broad?.error,
      },
    }

    setErrors(structure)
  }, [])

  const handlePublish = async () => {

    let editorState = store.getState().facebookCampaignEditor
    let finalEditorState = editorState.currentCampaign
    let initialEditorState = editorState.initialCampaign

    const finalDailyBudget = finalEditorState.targeting.dailyBudget
    const finalAge = finalEditorState.targeting.age
    const finalGenders = finalEditorState.targeting.genders
    const finalCountries = finalEditorState.targeting.countries

    const initialDailyBudget = initialEditorState.targeting.dailyBudget
    const initialAge = initialEditorState.targeting.age
    const initialGenders = initialEditorState.targeting.genders
    const initialCountries = initialEditorState.targeting.countries

    const countriesChanged = !helper.isEqual(initialCountries, finalCountries)

    const finalCreatives = finalEditorState.adCreatives
    const initialCreatives = initialEditorState.adCreatives


    let finalCreativeIds = Object.keys(finalCreatives)
    let initialCreativeIds = Object.keys(initialCreatives)


    let creativesChanged = finalCreativeIds.length !== initialCreativeIds.length
    finalCreativeIds.forEach(finalCreativeId => {
      // if final creative id doesnt exists in the initial list or it exists but edited so has replacesCreativeWithId field
      if (!initialCreativeIds.includes(finalCreativeId) || finalCreatives[finalCreativeId].replacesCreativeWithId) {
        creativesChanged = true
      }
    })


    console.log("initial and final budgets: ", initialDailyBudget, finalDailyBudget)
    const data = {
      campaignName: finalEditorState.label,
      enhCampaignId: campaign._id,
      label: initialEditorState.label.trim() !== finalEditorState.label.trim() ? finalEditorState.label.trim() : null,
      countryCodes: countriesChanged
        ? finalCountries.map(r => helper.findBy(countriesData, 'name', r).code)
        : null,
      countries: countriesChanged ? finalCountries : null,
      dailyBudget: parseInt(finalDailyBudget) !== initialDailyBudget ? parseInt(finalDailyBudget) : null,
      age:
        initialAge.min !== finalAge.min || initialAge.max !== finalAge.max
          ? finalAge
          : null,
      genders: finalGenders !== initialGenders ? helper.getGendersList(finalGenders) : null,
      id: campaign.id,
      adCampaign: campaign.adCampaign,
      adsets: campaign.adsets,
      ads: campaign.ads,

      trafficCampaign: campaign.trafficCampaign
    }


    if (creativesChanged) {
      data.adCreatives = finalCreatives
    }


    try {

      setIsEditing(false)
      setIsLoading(true)
      await updateCampaign(data)
      toast.success('Your changes were published successfully!')
      setIsLoading(false)
    } catch (err) {
      errorHandler(err)
    }
  }

  const handleCancel = () => {
    endCampaignEditing({
      completedSuccessfully: false, callback: () => {
        setIsEditing(false)
      }
    })
  }

  const handleStatusSwitch = state => {
    let futureStatus = ''
    let futureState = 'Off'

    if (switchEnabled === 'Off') {
      futureStatus = 'ACTIVE'
      futureState = 'Active'
    } else {
      futureStatus = 'PAUSED'
      futureState = 'Off'
    }

    return openModal({
      type: Modals.ARE_YOU_SURE,
      question: `Are you sure you want to ${switchEnabled === 'Off' ? 'activate' : 'deactivate'
        } this campaign?`,
      callback: async () => {
        let result = await updateCampaignStatus({
          enhCampaignId: campaign._id,
          adCampaign: campaign.adCampaign,
          status: futureStatus,
          trafficCampaign: campaign.trafficCampaign,
          label: campaign.label
        })
        if (result) {
          setSwitchEnabled(futureState)
          toast.success(
            switchEnabled === 'Off'
              ? 'Campaign activated!'
              : 'Campaign deactivated!',
          )
        }
      },
    })
  }

  const removeCampaign = () => {
    openModal({
      type: Modals.ARE_YOU_SURE,
      question:
        'The campaign will be permanently deleted, and this action is irreversible. Are you sure you want to proceed?',
      callback: async () => {
        setIsLoading(true)
        let body = {
          enhCampaignId: campaign._id,
          adCampaignId: campaign.adCampaign.id,
        }
        if (campaign.trafficCampaign?.isActive) {
          body.trafficCampaignId = campaign.trafficCampaign.campaign.id
        }
        await deleteCampaign(body)
        setIsLoading(false)
        navigateWithAdminParams("/ai-ads/full-funnel-campaigns")
      },
    })
  }

  const handleEdit = () => {
    if (isAdAcountCorrect && isPixelCorrect && isBusinessCorrect) {
      startExistingCampaignEditing({ enhCampaign: campaign })
      setIsEditing(true)
    } else {
      var modalConfig = {
        title: 'Campaign and connection mismatch',
        imageUrl:
          'https://cdn.enhencer.com/website-assets/images/icon-set/error.png',
        text: '',
        actions: [
          {
            text: 'Edit connection',
            callback() {
              navigateWithAdminParams('/config')
            },
          },
        ],
      }
      if (!isAdAcountCorrect) {
        modalConfig.text += "Your selected Ad Account is different from campaigns's Ad Account. Please select the same Ad Account when you create the campaign.";
      } else if (!isPixelCorrect) {
        modalConfig.text += "Your selected Pixel is different from campaigns's Pixel. Please select the same Pixel when you create the campaign.";
      } else if (!isBusinessCorrect) {
        modalConfig.text += "Your selected Business is different from campaigns's Business. Please select the same Business when you create the campaign.";
      }

      openModal({
        type: Modals.GENERIC_MODAL,
        data: modalConfig
      })
    }
  }

  const handleDelete = () => {
    setIsEditing(false)
    removeCampaign()
  }

  return (
    <FluidCard className="campaign-detail">
      <FluidCard.Header>
        <div className="header-wrapper">
          <div className="options">
            {
              isEditing ?
                <CampaignLabel />
                :

                <div
                  className="campaign-label"
                  title={label}
                >
                  {label}
                </div>
            }
          </div>
          {isEditing ? (
            isLoading ? null : (
              <div className="actions">
                {isProcessing && <LoadingDotsSpinner />}
                <CancelButton onClick={handleCancel} />
                <PublishButton busy={isLoading} onClick={handlePublish} />
              </div>
            )
          ) : (
            <div className="actions">
            <Tooltip isHidden={ campaign.createdOnFacebookPanel ? false : true} content={"this campaign created on facebook panel. You can't edit."}>
               <Button onClick={handleEdit} disabled={campaign.createdOnFacebookPanel ? true : false}>
                <FaCog />
                Edit
               </Button>
            </Tooltip>
              <Button danger onClick={handleDelete}>
                <FaTrash />
                Remove
              </Button>
              {effectiveStatus && effectiveStatus !== 'ACTIVE' && (
                <div className="campaign-status">
                  Campaign Status:{' '}
                  <span className="status">
                    {campaign.reports?.campaignLevel?.effectiveStatus}
                  </span>
                </div>
              )}
              <Switch
                value={switchEnabled}
                onChange={handleStatusSwitch}
                options={['Off', 'Active']}
              />
            </div>
          )}
        </div>
      </FluidCard.Header>
      <FluidCard.Content>
        <CampaignDetailErrors errors={errors} />
        {isLoading ? (
          <InlineLoadingSpinner message="Updating the campaign..."></InlineLoadingSpinner>
        ) : (
          <div className="campaign-content-wrapper">
            {isEditing ?
              <div className='left-side'>
                <CampaignTargetingForm />
                <CampaignCreativesSectionEditMode isCampaignInEditingMode={true} />

              </div>
              :
              <div className='left-side'>
                <div className='campaign-form'>
                  <h3>Targeting</h3>

                  <div className="row">
                    <label>Countries:</label>
                    <span className="text-field">{campaign.countries.join(",")}</span>
                  </div>

                  <div className="row col-2">
                    <div className="col">
                      <label>Daily Budget:</label>
                      <span className="text-field daily-budget">
                        {helper.getCurrencySymbol(
                          facebook.selectedAdAccount?.currency || 'USD',
                        )}
                        {campaign.dailyBudget}
                      </span>
                    </div>
                    <div className="col"></div>
                  </div>

                  <div className="row">
                    <label>Gender:</label>
                    <span className="text-field">{helper.getGenderString(campaign.genders)}</span>
                  </div>

                  <div className="row col-2">
                    <div className="col">
                      <label>Age:</label>

                      <span className="text-field age">
                        {campaign.age.min}
                        &nbsp;-&nbsp;
                        {campaign.age.max}
                      </span>
                    </div>
                    <div className="col"></div>
                  </div>
                </div>
                <CampaignCreativesSection selectCurrentCreative={setCurrentCreative} />

              </div>
            }

            <AdCreativePreviewer
              existingCampaignCreative={isEditing ? null : currentCreative}
            ></AdCreativePreviewer>

          </div>
        )}
      </FluidCard.Content>
      <PublishButton
        className="float-publish-button"
        busy={isLoading}
        onClick={handlePublish}
      />
    </FluidCard>
  )
}

export default CampaignDetail