import React, { useEffect, useMemo, useState } from 'react'
import { FaGripLines, FaPlus, FaAngleRight, FaAngleLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import helper from '../../shared/util/helper'
import { facebookActions } from '../../store/facebook'
import { useAdmin } from '../../shared/hooks/admin-hook'
import './CampaignsLeftMenu.css'

const CampaignsLeftMenu = () => {
  const dispatch = useDispatch()
  const { enhCampaigns, selectedAdAccount } = useSelector(
    state => state.facebook,
  )
  const [activeCampaigns, setActiveCampaigns] = useState([])
  const [inactiveCampaigns, setInactiveCampaigns] = useState([])
  const { navigateWithAdminParams } = useAdmin()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const selectedCampaignId = query.get('id')
  const [isOpen, setIsOpen] = useState(true)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768
      setIsMobile(isMobile)
      setIsOpen(!isMobile) // Sidebar always open above 768px
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const activeCampaigns = []
    const inactiveCampaigns = []

    enhCampaigns.forEach(campaign => {
      if (campaign?.reports?.campaignLevel?.effectiveStatus === 'ACTIVE') {
        activeCampaigns.push(campaign)
      } else {
        inactiveCampaigns.push(campaign)
      }
    })

    setActiveCampaigns(activeCampaigns)
    setInactiveCampaigns(inactiveCampaigns)
  }, [enhCampaigns])

  useEffect(() => {
    if (selectedCampaignId) {
      dispatch(facebookActions.setCampaignInView(selectedCampaignId))
      return
    }

    dispatch(facebookActions.setCampaignInView(null))
  }, [selectedCampaignId])

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={`campaigns-left-menu-cont ${isOpen ? 'open' : 'closed'}`}>
      <div className="campaigns-left-menu">
        <div
          onClick={() =>
            navigateWithAdminParams('/ai-ads/full-funnel-campaigns/create')
          }
          className="create-campaign-button"
        >
          <FaPlus />
          Create New Campaign
        </div>
        <div
          onClick={() =>
            navigateWithAdminParams('/ai-ads/full-funnel-campaigns')
          }
          className="view-all-campaigns-button"
        >
          <FaGripLines className="icon" />
          View All Campaigns
        </div>
        <div className="subheader">
          Active Campaigns ({activeCampaigns.length})
        </div>
        <ul className="campaigns-list">
          {!activeCampaigns.length && (
            <span className="no-campaigns-found">
              No active campaigns found
            </span>
          )}
          {activeCampaigns.map(campaign => (
            <li
              onClick={() => {
                navigateWithAdminParams(
                  `/ai-ads/full-funnel-campaigns?id=${campaign._id}`,
                )
              }}
              className={`${
                campaign._id === selectedCampaignId ? 'active' : ''
              }`}
              key={campaign._id}
            >
              <div>
                <span className="label">{campaign.label}</span>
                <div className="daily-budget">
                  Daily Budget:{' '}
                  <span className="number">
                    <span className="currency">
                      {helper.getCurrencySymbol(selectedAdAccount.currency)}
                    </span>
                    {campaign.dailyBudget}
                  </span>
                </div>
              </div>
              <div
                title={`Campaign Status: ${campaign.reports.campaignLevel.effectiveStatus}`}
                className={
                  campaign.reports.campaignLevel.effectiveStatus === 'ACTIVE'
                    ? 'indicator active'
                    : 'indicator'
                }
              ></div>
            </li>
          ))}
        </ul>
        <div className="subheader">
          Inactive Campaigns ({inactiveCampaigns.length})
        </div>
        <ul className="campaigns-list">
          {!inactiveCampaigns.length && (
            <span className="no-campaigns-found">
              No inactive campaigns found
            </span>
          )}
          {inactiveCampaigns.map(campaign => (
            <li
              onClick={() => {
                navigateWithAdminParams(
                  `/ai-ads/full-funnel-campaigns?id=${campaign._id}`,
                )
              }}
              className={`${
                campaign._id === selectedCampaignId ? 'active' : ''
              }`}
              key={campaign._id}
            >
              <div>
                <span className="label">{campaign.label}</span>
                <div className="daily-budget">
                  Daily Budget:{' '}
                  <span className="number">
                    <span className="currency">
                      {helper.getCurrencySymbol(selectedAdAccount.currency)}
                    </span>
                    {campaign.dailyBudget}
                  </span>
                </div>
              </div>
              <div
                title={`Campaign Status: ${campaign?.reports?.campaignLevel?.effectiveStatus}`}
                className={
                  campaign?.reports?.campaignLevel?.effectiveStatus === 'ACTIVE'
                    ? 'indicator active'
                    : 'indicator'
                }
              ></div>
            </li>
          ))}
        </ul>
      </div>
      {isMobile && (
        <button className="campaign-toggle-button" onClick={toggleSidebar}>
          {isOpen ? <FaAngleLeft /> : <FaAngleRight />}
        </button>
      )}
    </div>
  )
}

export default CampaignsLeftMenu
