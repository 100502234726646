import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { FaCalendarAlt } from "react-icons/fa";

import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useModal } from "../../shared/hooks/modal-hook";
import dateFormatter from "../../shared/util/dateFormatter";

import "./CardAdminDevZone.css";
import "react-datepicker/dist/react-datepicker.css";
import CollapsibleCard from "../../shared/components/UIElements/CollapsibleCard";
import { useNavigate, useParams } from "react-router-dom";
import Modals from "../../shared/enums/Modals";
import errorHandler from "../../shared/util/errorHandler";

const CardAdminDevZone = ({ userId }) => {

  const authToken = useSelector((state) => state.auth.token);
  const { sendRequest } = useHttpClient();
  const { openModal } = useModal()
  const params = useParams()
  const navigate = useNavigate()

  const [newJob, setNewJob] = useState({ repeats: {  } });
  const [existingJob, setExistingJob] = useState()

  const customer = useSelector(state => state.admin.customer)
  let passwordInputRef = useRef()

  useEffect(() => {
    getExistingCheckDataJob()
  }, [])

  const getExistingCheckDataJob = async () => {
    try {
      const jobResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getJob/${userId}?type=check_data`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + authToken,
        },
      )

      if (jobResult.message === "no job") {
        setExistingJob(null)
      } else {
        setExistingJob(jobResult)
        setNewJob(jobResult)
      }
    } catch (err) {
      errorHandler(err)
    }
  }

  const deleteUser = () => {
    const modalConfig = {
      title: 'You will delete user. Are you sure?',
      modalComponent: (
        <div>
          <h3>
            Enter the user deletion password. If you do not know the password,
            please contact the dev team.
          </h3>
          <input type="password" id="passwordInput" ref={passwordInputRef} />
        </div>
      ),
      text: '',
      actions: [
        {
          text: 'Cancel',
          callback() { },
        },
        {
          text: 'Confirm',
          callback() {
            const enteredPassword = passwordInputRef.current.value
            let pass = process.env.REACT_APP_DELETE_USER_PASSWORD

            //if the entered password is correct, delete the user
            if (enteredPassword === pass) {
              try {
                sendRequest(
                  `${process.env.REACT_APP_BACKEND_URL}/dev/${params.id}`,
                  'DELETE',
                  null,
                  {
                    Authorization: 'Bearer ' + authToken,
                  },
                ).then(res => {
                  navigate('/dev-admin-panel')
                  toast.warn('User deleted successfully.')
                })
              } catch (err) {
                errorHandler(err)
              }
            } else {
              alert('Wrong password')
            }
          },
        },
      ],
    }
    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
    })
  }

  const checkData = async () => {
    const data = {
      isActive: true,
    }
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/triggerCheckDataJob/`,
        'POST',
        JSON.stringify({
          userId
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )
      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const gotoModelLogs = async (uid) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/dev/getModelLogs`
    if (uid) url += `/${uid}`
    const responseData = await sendRequest(
      url,
      "GET",
      null,
      {
        Authorization: "Bearer " + authToken,
      }
    );

    openModal({
      type: Modals.MODEL_LOGS,
      closeOnClickOutside: true,
      modelLogs: responseData.logs
    });
  };

  const onScheduleJobDayChange = (e) => {
    setNewJob({
      ...newJob,
      repeats: {
        ...newJob.repeats,
        [e.target.id]: parseInt(e.target.value),
      }
    });
  };

  const saveCheckDataJob = async (e) => {
    console.log(newJob)
    try {
      const jobResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createJob`,
        "POST",
        JSON.stringify({
          userId: userId,
          type: "check_data",
          projectType: "",
          repeats: {
            months: 0,
            days: newJob.repeats.days,
            hours: newJob.repeats.hours,
            minutes: 0,
          },
          isCustom: true,
        }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );
      if (jobResult === "running_job") {
        toast.warning("Job already exists!");
      } else {
        toast.success("Changes saved!");
      }
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };

  const gtmOnboardingDone = async () => {
    try {
      const result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/gtmOnboardingDone`,
        "POST",
        JSON.stringify({
          userId: userId,
        }),
        {
          Authorization: "Bearer " + authToken,
          "Content-Type": "application/json",
        }
      );


      toast.success("Changes saved!");
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }



  return (
    <CollapsibleCard title="Dev Zone" className="ruby ai-audience-model-card dev-zone">
      <div className="row">
        {
          customer.connectFlow?.dataCollectionStarted ? null :
            <Button className="action small" onClick={gtmOnboardingDone}>
              GTM Onboarding Done
            </Button>
        }

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={checkData}
        >
          Trigger Check Data Job
        </Button>

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={() => { gotoModelLogs(userId) }}
        >
          Model Logs of User
        </Button>

        <Button
          className="small"
          style={{ fontSize: '0.8em' }}
          onClick={() => { gotoModelLogs() }}
        >
          All Model Logs
        </Button>

        <Button danger className="action small" onClick={deleteUser}>
          Delete User
        </Button>
      </div>

      <div className="edit-job-box">

        <div className="attribute-name">

          {existingJob ? "Job exists. Update" : "No check data job. Create New"}
        </div>
        <div className="content-box">
          {/*  <div className="row">
            <label>Starts on</label>
            <div className="type-row">
              <div className="date-input-cont">
                <DatePicker
                  className="scheduledatepicker"
                  style={{ width: "144px" }}
                  pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                  selected={jobDate}
                  onChange={(date) => setJobDate(new Date(date))}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
                <FaCalendarAlt className="icon" />
              </div>
            </div>
          </div> */}


          <div className="type-row row" style={{ margin: "0.5em 0" }}>
            <label>Run job every</label>
            <div className="text-edit row">
              <input
                id="days"
                className="repeat-input"
                type="number"
                value={newJob.repeats?.days || 0}
                onChange={onScheduleJobDayChange}
              />
              <label>days</label>

              <input
                id="hours"
                className="repeat-input"
                type="number"
                value={newJob.repeats?.hours || 0}
                onChange={onScheduleJobDayChange}
              />
              <label>hours</label>
            </div>
          </div>
          <Button onClick={saveCheckDataJob}>Save Check Data Job</Button>
        </div>
      </div>

    </CollapsibleCard>
  );
};

export default CardAdminDevZone;
