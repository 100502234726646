import React from 'react'
import { FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import './BottomNotificationBar.css'
import { generalActions } from '../../../store/general'

const BottomNotificationBar = () => {
  const dispatch = useDispatch()
  const bottomNotification = useSelector(
    state => state.general.bottomNotification,
  )

  const { display, className, type, dynamicData, hideCloseButton } = bottomNotification

  if (!display) {
    return null
  }

  // We don't want to show the 'store_connection' warning for now.
  if (type === 'store_connection') {
    return null
  }

  const onClose = () => {
    dispatch(generalActions.setBottomNotification({ display: false }))
  }

  return (
    <div className={`bottom-notification-bar ${className}`}>
      {getContent(type, dynamicData)}
      {!hideCloseButton && (
        <div onClick={onClose} className="close-button">
          <FaTimes className="icon" />
        </div>
      )}
    </div>
  )
}

function getContent(type, data) {
  switch (type) {
    case 'store_connection':
      return (
        <React.Fragment>
          In order to connect your store to Enhencer, you have to{' '}
          <Link to="/subscription">start your subscription</Link>
        </React.Fragment>
      )
    case 'payment_warning':
      return (
        <React.Fragment>
          Your payment could not be processed. Please{' '}
          <Link to="/subscription">retry the payment</Link>, or your account
          will be deactivated after {data.days} days.
        </React.Fragment>
      )
    default:
      return ''
  }
}

export default BottomNotificationBar
