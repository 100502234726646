import React from 'react'

import './CancelButton.css'

const CancelButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="cancel-button">
      Cancel
    </button>
  )
}

export default CancelButton
