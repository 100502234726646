import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import './CampaignCreativesSection.css'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'
import { FaList, FaPenFancy, FaPenNib, FaPenSquare, FaPlus } from 'react-icons/fa'
import AdCreativeMaker from './AdCreativeMaker'
import Button from '../../shared/components/FormElements/Button'

const CampaignCreativesSectionEditMode = () => {
  const { selectCurrentAdCreative, startNewCreativeMakerForm } = useFacebookCampaignEditor()
  const adCreatives = useSelector(state => state.facebookCampaignEditor.currentCampaign?.adCreatives)
  const currentCreative = useSelector(state => state.facebookCampaignEditor.currentCampaign?.currentCreative)

  return <div className="campaign-creatives-section campaign-form" style={{ marginTop: "2em" }}>
    <div className='header-row'>
      <h3>Creatives</h3>
      <div className='creative-action-button add' onClick={startNewCreativeMakerForm}>
        <FaPlus className='icon' />
        <span>Add new</span>
      </div>
    </div>
    <div className='row editor-header'>
      <FaList />
      Creatives in Use
    </div>

    {
      adCreatives && Object.keys(adCreatives).length ?
        <div className='campaign-creatives-list'>
          {Object.values(adCreatives).map(creative =>
            <div className={`campaign-creative-chip${currentCreative.name === creative.name ? ' active' : ''}`} key={creative.name}
              onClick={() => { selectCurrentAdCreative(creative) }}>
              <span>{creative.name}</span>
            </div>
          )}
        </div> : <p className='helper-text'>No creatives. Start crafting one in editor</p>
    }

    <React.Fragment>
      <div className='row editor-header'>
        <FaPenNib />
        Editor
      </div>
      <AdCreativeMaker />
    </React.Fragment>

  </div>

}

export default CampaignCreativesSectionEditMode
