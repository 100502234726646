import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import { adHealthCheckupActions } from '../../store/ad-health-checkup'
import errorHandler from '../util/errorHandler'

const useAdHealthCheckup = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()

  const authToken = useSelector(state => state.auth.token)
  const user = useSelector(state => state.auth.user)
  const authHeader = {
    Authorization: 'Bearer ' + authToken,
    'Content-Type': 'application/json',
  }
  const selectedAdAccount = useSelector(
    state => state.facebook.selectedAdAccount,
  )
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const auth = useSelector(state => state.facebook.auth)

  const prepareAdHealthCheckup = async () => {
    const adAccountId = selectedAdAccount?.id
    const pixelId = selectedPixel?.id
    const businessId = selectedBusiness?.id
    const accessToken = auth?.accessToken

    if (!adAccountId || !pixelId || !businessId) {
      return
    }

    if (user.adHealthCheckup && user.adHealthCheckup[adAccountId]) {
      const adHealthCheckup = user.adHealthCheckup[adAccountId].find(ahc => {
        return ahc.pixelId === pixelId && ahc.businessId === businessId
      })

      if (
        adHealthCheckup &&
        adHealthCheckup.insights &&
        adHealthCheckup.adsetsAndCreatives &&
        adHealthCheckup.score
      ) {
        // The data is already saved to the database, so we can directly use it from the user object.
        return dispatch(adHealthCheckupActions.setPreparedData(adHealthCheckup))
      }
    }

    // Prepare the data for the Ad Health Checkup by sending a request to the backend.
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/ad-health-checkup/prepareAdHealthCheckup`

      const preparedData = await sendRequest(
        url,
        'POST',
        JSON.stringify({
          adAccountId,
          pixelId,
          businessId,
          accessToken,
        }),
        authHeader,
      )

      dispatch(adHealthCheckupActions.setPreparedData(preparedData))
    } catch (err) {
      errorHandler(err)
      dispatch(
        adHealthCheckupActions.setError(err?.message || 'Something went wrong'),
      )
    }
  }
  const adHealthCheckupLastViewedSlide = slideIndex => {
    const adAccountId = selectedAdAccount?.id
    const pixelId = selectedPixel?.id
    const businessId = selectedBusiness?.id
    const accessToken = auth?.accessToken

    if (!adAccountId || !pixelId || !businessId) {
      return
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/ad-health-checkup/lastViewedSlide`

    sendRequest(
      url,
      'POST',
      JSON.stringify({
        slideIndex,
        adAccountId,
        pixelId,
        businessId,
        accessToken,
      }),
      authHeader,
    )
  }

  

  return {
    prepareAdHealthCheckup,
    adHealthCheckupLastViewedSlide,
  }
}

export default useAdHealthCheckup
