import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  error: null,
  insights: {
    timeRange: {
      start: '',
      end: '',
    },
    yearlyInsights: {},
    totalRevenue: 0,
    averageMoMRevenueGrowth: 0,
    averageBudget: 0,
    averageROAS: 0,
    averageCTR: 0,
    averageCostPerPurchase: 0,
  },
  adsetsAndCreatives: {
    topAdsets: [],
    worstAdsets: [],
    topCreatives: [],
    worstCreatives: [],
  },
  score: {
    score: 0,
    scoreSummary: {
      optimized: [],
      critical: [],
      needsImprovement: [],
    },
  },
  isMouseWheelActive: true,
  connected: true,
}

const adHealthCheckupSlice = createSlice({
  name: 'adHealthCheckup',
  initialState: initialState,
  reducers: {
   
    setConnected(state, action) {
      state.connected = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setPreparedData(state, action) {
      state.insights.timeRange = action.payload.insights.timeRange
      state.insights.yearlyInsights = action.payload.insights.yearlyInsights
      state.insights.totalRevenue = action.payload.insights.totalRevenue
      state.insights.averageMoMRevenueGrowth =
        action.payload.insights.averageMoMRevenueGrowth
      state.insights.averageBudget = action.payload.insights.averageBudget
      state.insights.averageROAS = action.payload.insights.averageROAS
      state.insights.averageCTR = action.payload.insights.averageCTR
      state.insights.averageCostPerPurchase =
        action.payload.insights.averageCostPerPurchase

      state.adsetsAndCreatives.topAdsets =
        action.payload.adsetsAndCreatives.topAdsets
      state.adsetsAndCreatives.worstAdsets =
        action.payload.adsetsAndCreatives.worstAdsets
      state.adsetsAndCreatives.topCreatives =
        action.payload.adsetsAndCreatives.topCreatives
      state.adsetsAndCreatives.worstCreatives =
        action.payload.adsetsAndCreatives.worstCreatives

      state.score.score = action.payload.score.score
      state.score.scoreSummary.optimized =
        action.payload.score.summary.optimized || []
      state.score.scoreSummary.needsImprovement =
        action.payload.score.summary.needsImprovement || []
      state.score.scoreSummary.critical =
        action.payload.score.summary.critical || []
    },
    setMouseWheelActive(state, action) {
      state.isMouseWheelActive = action.payload
    },
  },
})

export const adHealthCheckupActions = adHealthCheckupSlice.actions

export default adHealthCheckupSlice.reducer
