import React, { useEffect, useRef, useState } from "react";
import Switch from "../../shared/components/UIElements/Switch";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from "react-chartjs-2";
import "./AudiencesSummary.css"
import gradientMaker from "../../shared/util/gradientMaker";
import { useForm } from "../../shared/hooks/form-hook";
import numberFormatter from "../../shared/util/numberFormatter";
import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";
import { useConstants } from "../../shared/hooks/constants-hook";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const AudiencesSummary = props => {
    const { publicConstants } = useConstants()
    const enhencerAudienceChartRef = useRef()
    const bAudienceChartRef = useRef()
    const [expectedLiveSwitch, setExpectedLiveSwitch] = useState('expected');

    const [selectedChartVariable, setSelectedChartVariable] = useState("Conversion Rate");

    // const chartLabels = ['Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22'];

    const chartOptions = {
        responsive: true,
        tension: 0.4,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
        scales: {
            x: {
                display: false
            },
            y: {
                display: false,
                min: 0,
            },
        }

    }

    const [enhencerAudienceChartData, setEnhencerAudienceChartData] = useState({
        options: chartOptions,
        labels: props.enhencerAudience.expectedLabels,
        datasets: [
            {
                label: selectedChartVariable,
                // data: [58, 60, 56, 51, 65, 70, 72, 68, 69, 68, 71, 71],
                data: props.enhencerAudience.expectedConvRateData,
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#46B680',
                fill: true,
                pointRadius: 1,
                pointHitRadius: 10,
                pointHoverRadius: 3,
                pointBorderWidth: window.innerWidth > 1400 ? 3 : 2,
                pointBackgroundColor: '#46B680',
                pointHoverBackgroundColor: '#46B680'
            },
        ],

    })

    const [bAudienceChartData, setBAudienceChartData] = useState({
        options: chartOptions,
        labels: props.allVisitorsLabels,
        datasets: [
            {
                label: selectedChartVariable,
                // data: [47, 52, 56, 51, 48, 47, 48, 51, 54, 55, 53, 52],
                data: props.allVisitorsConvRateData,
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#FF4600',
                fill: true,
                pointRadius: 1,
                pointHitRadius: 10,
                pointHoverRadius: 3,
                pointBorderWidth: window.innerWidth > 1400 ? 3 : 2,
                pointBackgroundColor: '#FF4600',
                pointHoverBackgroundColor: '#FF4600'
            },
        ],

    })

    const [formState, inputHandler, setFormData] = useForm(
        {
            audienceResultInterval: {
                value: publicConstants.audienceResultIntervals[2].name,
                isValid: true
            },
        },
        true
    );

    useEffect(() => {
        let chartYMaxValue = 0;
        let newOptions = { ...chartOptions }
        if (enhencerAudienceChartRef.current) {
            const datasetsCopy = enhencerAudienceChartData.datasets.slice(0);
            datasetsCopy[0].backgroundColor = gradientMaker(enhencerAudienceChartRef.current.ctx, "#46B680", 110);
            datasetsCopy[0].label = selectedChartVariable;
            let data;
            let labels;
            if (expectedLiveSwitch === "expected") {
                labels = props.enhencerAudience.expectedLabels;
                if (selectedChartVariable === "Conversion Rate") {
                    let values = props.enhencerAudience.expectedConvRateData.map(i => i * 100);
                    let maxValue = Math.max(...values);
                    if (maxValue >= chartYMaxValue) {
                        chartYMaxValue = maxValue;
                        newOptions.scales.y.max = maxValue
                    }
                    data = values;
                } else {
                    let maxValue = Math.max(...props.enhencerAudience.expectedNumOfVisitorsData);
                    if (maxValue >= chartYMaxValue) {
                        chartYMaxValue = maxValue;
                        newOptions.scales.y.max = maxValue
                    }
                    data = props.enhencerAudience.expectedNumOfVisitorsData;
                }
            } else {
                labels = props.enhencerAudience.liveLabels;
                if (selectedChartVariable === "Conversion Rate") {
                    let values = props.enhencerAudience.liveConvRateData.map(i => i * 100);
                    let maxValue = Math.max(...values);
                    if (maxValue >= chartYMaxValue) {
                        chartYMaxValue = maxValue;
                        newOptions.scales.y.max = maxValue
                    }
                    data = values;

                } else {
                    let maxValue = Math.max(...props.enhencerAudience.liveNumOfVisitorsData);
                    if (maxValue >= chartYMaxValue) {
                        chartYMaxValue = maxValue;
                        newOptions.scales.y.max = maxValue
                    }
                    data = props.enhencerAudience.liveNumOfVisitorsData;
                }
            }
            datasetsCopy[0].data = data;

            setEnhencerAudienceChartData(Object.assign({}, enhencerAudienceChartData, {
                labels: labels,
                datasets: datasetsCopy,
                options: newOptions
            }))
        }
        if (bAudienceChartRef.current) {
            const datasetsCopy = bAudienceChartData.datasets.slice(0);
            datasetsCopy[0].backgroundColor = gradientMaker(bAudienceChartRef.current.ctx, "#FF4600dd", 110);
            datasetsCopy[0].label = selectedChartVariable;
            let allVisitorsdata;
            if (selectedChartVariable === "Conversion Rate") {
                allVisitorsdata = props.allVisitorsConvRateData.map(i => i * 100);
                let maxValue = Math.max(...allVisitorsdata)
                if (maxValue >= chartYMaxValue) {
                    chartYMaxValue = maxValue;
                    newOptions.scales.y.max = maxValue
                }
            } else {
                let maxValue = Math.max(...props.allVisitorsNumOfVisitorsData);
                if (maxValue >= chartYMaxValue) {
                    chartYMaxValue = maxValue;
                    newOptions.scales.y.max = maxValue
                }
                allVisitorsdata = props.allVisitorsNumOfVisitorsData;
            }
            datasetsCopy[0].data = allVisitorsdata;
            setBAudienceChartData(Object.assign({}, bAudienceChartData, {
                datasets: datasetsCopy,
                options: newOptions
            }))
        }
        //document.querySelector(".audience-card .stats")
    }, [expectedLiveSwitch, selectedChartVariable, props.enhencerAudience])


    return <React.Fragment>
        {/* <div className="card-header">
            <div className="row">
                <img src={summaryOfAudiencesIcon} />
                <h4>Summary of Audiences</h4>
            </div>
            <Input element="dropdown"
                initialValue={formState.inputs.audienceResultInterval.value}
                id="audienceResultInterval"
                type="text"
                onInput={inputHandler}
                options={constants.audienceResultIntervals.map(item => item.name)}
                apply={props.onDayChange} />
        </div> */}
        <div className="card-content">
            <div className="row">
                <div className="audience-card enhencer-audience">
                    <div className="header">
                        <div>Enhencer Remarketing AI Audience</div>
                    </div>
                    <div className="content">
                        {props.isChartLoading ? <InlineLoadingSpinner center={true} />
                            :
                            <React.Fragment>

                                <div className="row">
                                    <div className="chart-container">

                                        <Line ref={enhencerAudienceChartRef} options={enhencerAudienceChartData.options} data={enhencerAudienceChartData} />
                                    </div>
                                    <div className="stats">
                                        <div className={selectedChartVariable === 'Conversion Rate' ? 'selected' : ''} onClick={() => { setSelectedChartVariable('Conversion Rate') }}>
                                            {(expectedLiveSwitch === "expected" ? props.enhencerAudience.expected.propensity * 100 : props.enhencerAudience.live.propensity * 100).toFixed(2)}&nbsp;%
                                        </div>
                                        <div className={selectedChartVariable === 'Number of Visitors' ? 'selected' : ''} onClick={() => { setSelectedChartVariable('Number of Visitors') }}>
                                            {numberFormatter.formatNumber(expectedLiveSwitch === "expected" ? props.enhencerAudience.expected.size : props.enhencerAudience.live.size)}
                                        </div>
                                        <div>
                                            {expectedLiveSwitch === "expected" ? (props.enhencerAudience.expected.propensity / props.overall.propensity).toFixed(1) : (props.enhencerAudience.live.propensity / props.overall.propensity).toFixed(1)}x
                                        </div>
                                    </div>
                                </div>
                                <div className="switch-container">
                                    <Switch options={["expected", "live"]} value={expectedLiveSwitch} onChange={setExpectedLiveSwitch} />
                                </div>
                            </React.Fragment>}
                    </div>
                </div>
                <div className="middle-column">
                    <p className={selectedChartVariable === 'Conversion Rate' ? 'active' : ''} onClick={() => { setSelectedChartVariable('Conversion Rate') }}>Conversion&nbsp;Rate</p>
                    <p className={selectedChartVariable === 'Number of Visitors' ? 'active' : ''} onClick={() => { setSelectedChartVariable('Number of Visitors') }}>Number&nbsp;of&nbsp;Visitors</p>
                    <p>Uplift</p>
                </div>
                <div className="audience-card b-audience">
                    <div className="header">
                        <div>All Visitors</div>
                    </div>
                    <div className="content">
                        <div className="row">
                            <div className="stats">
                                <div className={selectedChartVariable === 'Conversion Rate' ? 'selected' : ''} onClick={() => { setSelectedChartVariable('Conversion Rate') }}>
                                    {(props.overall.propensity * 100).toFixed(2)}&nbsp;%
                                </div>
                                <div className={selectedChartVariable === 'Number of Visitors' ? 'selected' : ''} onClick={() => { setSelectedChartVariable('Number of Visitors') }}>
                                    {numberFormatter.formatNumber(props.overall.count)}
                                </div>
                                <div>
                                    1.0x
                                </div>
                            </div>
                            <div className="chart-container">

                                <Line ref={bAudienceChartRef} options={bAudienceChartData.options} data={bAudienceChartData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default AudiencesSummary;