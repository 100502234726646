import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import Segment from '../Modals/Segment'
import SelectCategories from '../Modals/SelectCategories'
import AreYouSure from '../Modals/AreYouSure'
import ChangePassword from '../Modals/ChangePassword'
import LoginWithShopify from '../Modals/LoginWithShopify'
import ModelLogs from '../Modals/ModelLogs'
import TermsofUse from '../Modals/TermsofUse'
import CreateCustomSubscriptionPlan from '../Modals/CreateCustomSubscriptionPlan'
import PaymentRequired from '../Modals/PaymentRequired'
import ScheduleADemo from '../Modals/ScheduleADemo'
import AccountSuspended from '../Modals/AccountSuspended'
import SMSVerification from '../Modals/SMSVerification'
import StripeUpdareCreditCard from '../Modals/StripeUpdateCreditCard'
import ConnectionRequired from '../Modals/ConnectionRequired'
import GenericModal from '../Modals/GenericModal'
import PixelEventsVerificationRequired from '../Modals/PixelEventsVerificationRequired'
import CreateASupportTicket from '../Modals/CreateASupportTicket'
import TicketDetails from '../Modals/TicketDetails'
import GoogleAdsSupport from '../Modals/GoogleAdsSupport'
import CampaignDeactivation from '../Modals/CampaignDeactivation'
import CampaignCreationSuccess from '../Modals/CampaignCreationSuccess'
import DemoSession from '../Modals/DemoSession'
import FacebookReLogin from '../Modals/FacebookReLogin'
import CheckupMeeting from '../Modals/CheckupMeeting'
import NewAlarm from '../Modals/NewAlarm'
import EditAlarm from '../Modals/EditAlarm'
import UpdateMeetingDate from '../Modals/UpdateMeetingDate'
import NewDemoMeeting from '../Modals/NewDemoMeeting'
import AddUser from '../Modals/AddUser'
import AddNote from '../Modals/AddNote'
import ExpandNote from '../Modals/ExpandNote'
import AdminCheckUpMeeting from '../Modals/AdminCheckUpMeeting'
import CancelSubscription from '../Modals/CancelSubscription'
import BeforeYouCancel from '../Modals/BeforeYouCancel'
import AdHealthCheckupModal from '../Modals/AdHealthCheckupModal'
import SeeAllAlarms from '../Modals/SeeAllAlarms'
import StartScreen from '../Modals/StartScreen'
import PeekCard from '../Modals/PeekCard'
import PredefinedAlarmsModal from '../Modals/PredefinedAlarmsModal'
import LookalikeAudienceConfig from '../Modals/LookalikeAudienceConfig'

import Backdrop from './Backdrop'
import Modals from '../../enums/Modals'
import { useModal } from '../../hooks/modal-hook'

import './Modal.css'

const ModalOverlay = props => {
  if (!props.data) {
    return null
  }

  const content = (data) => {
    switch (data.type) {
      case Modals.SEGMENT:
        return <Segment data={data} />
      case Modals.SELECT_CATEGORIES:
        return <SelectCategories data={data} />
      case Modals.ARE_YOU_SURE:
        return <AreYouSure data={data} />
      case Modals.CHANGE_PASSWORD:
        return <ChangePassword data={data} />
      case Modals.LOGIN_WITH_SHOPIFY:
        return <LoginWithShopify data={data} />
      case Modals.MODEL_LOGS:
        return <ModelLogs data={data} />
      case Modals.TERMS_OF_USE:
        return <TermsofUse />
      case Modals.PAYMENT_REQUIRED:
        return <PaymentRequired data={data} />
      case Modals.ACCOUNT_SUSPENDED:
        return <AccountSuspended data={data} />
      case Modals.SCHEDULE_A_DEMO:
        return <ScheduleADemo />
      case Modals.CREATE_CUSTOM_SUBSCRIPTION_PLAN:
        return <CreateCustomSubscriptionPlan data={data} />
      case Modals.SMS_VERIFICATION:
        return <SMSVerification data={data} />
      case Modals.STRIPE_UPDATE_CREDIT_CARD:
        return <StripeUpdareCreditCard data={data} />
      case Modals.CONNECTION_REQUIRED:
        return <ConnectionRequired data={data} />
      case Modals.GENERIC_MODAL:
        return <GenericModal data={data} />
      case Modals.PIXEL_EVENTS_VERIFICATION_REQUIRED:
        return <PixelEventsVerificationRequired data={data} />
      case Modals.CREATE_A_SUPPORT_TICKET:
        return <CreateASupportTicket data={data} />
      case Modals.TICKET_DETAILS:
        return <TicketDetails data={data} />
      case Modals.GOOGLE_ADS_SUPPORT:
        return <GoogleAdsSupport data={data} />
      case Modals.CAMPAIGN_DEACTIVATION:
        return <CampaignDeactivation data={data} />
      case Modals.CAMPAIGN_CREATION:
        return <CampaignCreationSuccess data={data} />
      case Modals.DEMO_SESSION:
        return <DemoSession data={data} />
      case Modals.CHECKUP_MEETING:
        return <CheckupMeeting data={data} />
      case Modals.FACEBOOK_RE_LOGIN:
        return <FacebookReLogin data={data} />
      case Modals.BEFORE_YOU_CANCEL:
        return <BeforeYouCancel data={data} />
      case Modals.CANCEL_SUBSCRIPTION:
        return <CancelSubscription data={data} />
      case Modals.NEW_ALARM:
        return <NewAlarm data={data} />
      case Modals.EDIT_ALARM:
        return <EditAlarm data={data} />
      case Modals.PREDEFINED_ALARMS:
        return <PredefinedAlarmsModal data={data} />
      case Modals.ADD_NOTE:
        return <AddNote data={data} />
      case Modals.EXPAND_NOTE:
        return <ExpandNote data={data} />
      case Modals.SEE_ALL_ALARMS:
        return <SeeAllAlarms data={data} />
      case Modals.NEW_DEMO_MEETING:
        return <NewDemoMeeting data={data} />
      case Modals.ADMIN_CHECK_UP_MEETING:
        return <AdminCheckUpMeeting data={data} />
      case Modals.UPDATE_MEETING_DATE:
        return <UpdateMeetingDate data={data} />
      case Modals.AD_HEALTH_CHECKUP_MODAL:
        return <AdHealthCheckupModal data={props.data} />
      case Modals.START_SCREEN:
        return <StartScreen data={props.data} />
      case Modals.PEEK_CARD:
        return <PeekCard data={data} />
      case Modals.ADD_USER:
        return <AddUser data={data} />
      case Modals.LOOKALIKE_AUDIENCE_CONFIG:
        return <LookalikeAudienceConfig data={data} />
      default:
        return null
    }
  }
  const elements = props.activeModals.map(modalData => {
    return (
      <div className="modal-overlay" key={modalData.type}>
        <div className={`modal-container ${modalData.type}`}>{content(modalData)}</div>
      </div>
    )
  })
  return ReactDOM.createPortal(elements, document.getElementById('modal-hook'))
}

const Modal = props => {
  const { closeModal, activeModals } = useModal()
  const lastOpenedModal = activeModals[activeModals.length - 1]

  return (
    <React.Fragment>
      {!!activeModals.length && (
        <Backdrop
          blur={lastOpenedModal.blurBackground}
          onClick={() => {
            if (lastOpenedModal.closeOnClickOutside) {
              closeModal()
            }
          }}
        />
      )}
      <CSSTransition
        in={activeModals.length > 0}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay data={lastOpenedModal} activeModals={activeModals} />
      </CSSTransition>
    </React.Fragment>
  )
}

export default Modal
