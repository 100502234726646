import React, { useEffect, useState } from 'react'
import { FaPen, FaSave, FaUndo } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from '../../shared/hooks/http-hook'
import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'
import Button from '../../shared/components/FormElements/Button'
import Switch from '../../shared/components/UIElements/Switch'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'

import './AIAdsTable.css'
import { useModal } from '../../shared/hooks/modal-hook'
import Modals from '../../shared/enums/Modals'

const AIAdsTable = ({ isLoading, facebookInfo }) => {
  const [fbInfo, setFbInfo] = useState(facebookInfo)

  const columns = [
    'Campaign',
    'Adsets',
    'Status',
    'Budget %',
    'Budget Amount',
    'Advantage+ Audience',
    'AN Lookalike',
    // 'Advantage Lookalike',
    'Audiences',
    'Exclude'
  ]

  useEffect(() => {
    if (fbInfo) {
      return
    }

    setFbInfo(facebookInfo)
  }, [facebookInfo])

  if (isLoading) {
    return <InlineLoadingSpinner message="Fetching the AI Ads table..." />
  }

  if (!fbInfo) {
    return null
  }

  return (
    <table className="ai-ads-table">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!fbInfo.enhCampaigns?.length ? (
          <tr className="no-campaigns">
            <td colSpan={columns.length}>No campaigns</td>
          </tr>
        ) : (
          fbInfo.enhCampaigns.map((campaign, campaignIndex) => (
            <CampaignRow
              key={campaign._id}
              facebookAuth={fbInfo.auth}
              currency={fbInfo.selectedAdAccount?.currency}
              campaign={campaign}
              campaignIndex={campaignIndex}
              columnsLength={columns.length}
              fbInfo={fbInfo}
              setFbInfo={setFbInfo}
            />
          ))
        )}
      </tbody>
    </table>
  )
}

const CampaignRow = ({
  facebookAuth,
  campaign,
  campaignIndex,
  columnsLength,
  currency,
  fbInfo,
  setFbInfo,
}) => {
  const { sendRequest } = useHttpClient()
  const { openModal } = useModal()
  const customer = useSelector(state => state.admin.customer)
  const authToken = useSelector(state => state.auth.token)

  const [isDirty, setIsDirty] = useState(false)

  const [budget, setBudget] = useState({})
  const [budgetAmount, setBudgetAmount] = useState({})
  const [advantagePlusAudience, setAdvantagePlusAudience] = useState({})
  const [advantageLookalikeAudience, setAdvantageLookalikeAudience] = useState({})
  const [includeANLookalikes, setIncludeANLookalikes] = useState({})

  const [rmRetentionDays, setRmRetentionDays] = useState({})
  const [budgetError, setBudgetError] = useState(null)
  const [totalValues, setTotalValues] = useState({})
  const [saving, setSaving] = useState(false)

  const save = async () => {
    const updatedAdsets = []

    for (const [adsetType, adset] of Object.entries(campaign.adsets)) {
      const additionalAdsetDetails = {
        audiences: adset.audiences,
        excludedAudiences: adset.excludedAudiences,
        countryCodes: campaign.countryCodes,
        age: campaign.age,
        genders: campaign.genders,
      }
      const enteredBudget = budgetAmount[adsetType]
      const currentBudget = adset.dailyBudget

      const enteredIncludeANLookalikes = includeANLookalikes[adsetType] === "yes" ? 1 : 0
      const currentIncludeANLookalikes = adset.includeincludeANLookalikes

      const enteredAdvantagePlusAudience = advantagePlusAudience[adsetType] === "yes" ? 1 : 0
      const currentAdvantagePlusAudience = adset.includeAdvantagePlusAudience

      const enteredAdvantageLookalikeAudience = advantageLookalikeAudience[adsetType] === "yes" ? 1 : 0
      const currentAdvantageLookalikeAudience = adset.includeAdvantageLookalike

      const updatedAdset = {
        adsetName: adsetType,
        adsetId: adset.id,
        additionalAdsetDetails,
      }

      if (enteredBudget !== currentBudget) {
        updatedAdset.dailyBudget = enteredBudget
        updatedAdset.hasBudgetChanged = true
      } else {
        updatedAdset.dailyBudget = currentBudget
        updatedAdset.hasBudgetChanged = false
      }

      if (enteredIncludeANLookalikes !== currentIncludeANLookalikes) {
        updatedAdset.includeANLookalikes = enteredIncludeANLookalikes
        updatedAdset.hasANLookalikeChanged = true
      } else {
        updatedAdset.includeANLookalikes = currentIncludeANLookalikes
        updatedAdset.hasANLookalikeChanged = false
      }

      if (
        enteredAdvantagePlusAudience !== currentAdvantagePlusAudience ||
        enteredAdvantageLookalikeAudience !== currentAdvantageLookalikeAudience
      ) {
        updatedAdset.includeAdvantagePlusAudience = enteredAdvantagePlusAudience
        updatedAdset.includeAdvantageLookalike =
          enteredAdvantageLookalikeAudience
        updatedAdset.hasAdvantageOptionsChanged = true
      } else {
        updatedAdset.includeAdvantagePlusAudience = currentAdvantagePlusAudience
        updatedAdset.includeAdvantageLookalike =
          currentAdvantageLookalikeAudience
        updatedAdset.hasAdvantageOptionsChanged = false
      }

      updatedAdsets.push(updatedAdset)
    }

    try {
      setSaving(true)

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateAdsetDetails/`,
        'POST',
        JSON.stringify({
          userId: customer.id,
          enhCampaignId: campaign._id,
          countries: campaign.countries,
          adAccountId: fbInfo.selectedAdAccount.id,
          updatedAdsets,
          accessToken: facebookAuth.accessToken,
          currency
        }),
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      // Update the fbInfo state after saving the changes.
      const newFbInfo = { ...fbInfo }
      const indexToUpdate = newFbInfo.enhCampaigns.findIndex(
        c => c._id === campaign._id,
      )
      const newCampaign = { ...newFbInfo.enhCampaigns[indexToUpdate] }

      newCampaign.dailyBudget = totalValues.budgetAmount
      for (const adsetType in campaign.adsets) {
        newCampaign.adsets = {
          ...newCampaign.adsets,
          [adsetType]: {
            ...newCampaign.adsets[adsetType],
            dailyBudget: budgetAmount[adsetType],
            includeAdvantageLookalike: advantageLookalikeAudience[adsetType],
            includeAdvantagePlusAudience: advantagePlusAudience[adsetType],
          }
        }
      }

      newFbInfo.enhCampaigns[indexToUpdate] = newCampaign
      setFbInfo(newFbInfo)

      toast.success("Changes saved!")
      setIsDirty(false)
    } catch (err) {
      console.log("Error while updating the adsets ", err)
      toast.error(err?.response?.data ?? "Error happened, check console")
    } finally {
      setSaving(false)
    }
  }

  const validateBudget = (adsetType, percentage) => {
    const MAX_BUDGET_TOTAL = 100
    const TOLERANCE = 0.05

    let totalOfExistingBudgets = 0

    Object.keys(campaign.adsets).forEach(adset => {
      if (adset !== adsetType) {
        totalOfExistingBudgets += budget[adset]
      }
    })

    const currentTotal = totalOfExistingBudgets + percentage

    if (currentTotal > MAX_BUDGET_TOTAL + TOLERANCE) {
      setBudgetError({ type: 'over' })
      return false
    }

    if (currentTotal < MAX_BUDGET_TOTAL - TOLERANCE) {
      setBudgetError({ type: 'under' })
      return false
    }

    setBudgetError(null)
    return true
  }

  const handleValueChange = (adsetType, property, value) => {
    switch (property) {
      case 'budget':
        if (!value) value = 0

        const percentage = parseFloat(value)
        const dailyBudgetOfCampaign = campaign.dailyBudget
        const amount = (dailyBudgetOfCampaign * percentage) / 100

        validateBudget(adsetType, percentage)

        setBudget(prev => ({ ...prev, [adsetType]: percentage }))
        setBudgetAmount(prev => ({ ...prev, [adsetType]: amount }))
        break

      case 'includeANLookalikes':
        setIncludeANLookalikes(prev => {
          return { ...prev, [adsetType]: value }
        })
        break

      case 'advantagePlusAudience':
        if (value === "yes" && campaign.adsets[adsetType].audiences.filter(a => a.isLookalike).length) {
          toast.warn("You cannot enable Advantage+ with lookalike audiences in use.")
          return
        }
        
        setAdvantagePlusAudience(prev => {
          return { ...prev, [adsetType]: value }
        })

        if (value === 'yes') {
          setAdvantageLookalikeAudience(prev => {
            return { ...prev, [adsetType]: 'no' }
          })
        }
        break
      case 'advantageLookalikeAudience':
        setAdvantageLookalikeAudience(prev => {
          return { ...prev, [adsetType]: value }
        })

        if (value === 'yes') {
          setAdvantagePlusAudience(prev => {
            return { ...prev, [adsetType]: 'no' }
          })
        }
        break
      case 'rmRetentionDays':
        setRmRetentionDays(prev => {
          return { ...prev, [adsetType]: value }
        })
        break
      default:
        break
    }

    if (!isDirty) {
      setIsDirty(true)
    }
  }

  const formatAdsetType = adsetType => {
    return adsetType[0].toUpperCase() + adsetType.slice(1)
  }

  const setInitialValues = () => {
    let newBudgetAmounts = {}
    let newBudgetPercentages = {}
    let newAdvantagePlusAudiences = {}
    let newAdvantageLookalikeAudiences = {}
    let newANLookalikeAudiences = {}

    Object.keys(campaign.adsets).forEach(adsetType => {
      const dailyBudgetOfCampaign = campaign.dailyBudget
      const dailyBudgetOfAdset = campaign.adsets[adsetType].dailyBudget
      const percentage = (dailyBudgetOfAdset / dailyBudgetOfCampaign) * 100
      const amount = (dailyBudgetOfCampaign * percentage) / 100

      newBudgetAmounts = {
        ...newBudgetAmounts,
        [adsetType]: parseFloat(amount.toFixed(2)),
      }

      newBudgetPercentages = {
        ...newBudgetPercentages,
        [adsetType]: parseFloat(percentage.toFixed(2)),
      }

      const includeAdvantagePlusAudience = campaign.adsets[adsetType].includeAdvantagePlusAudience === 1 ? 'yes' : 'no'
      const includeAdvantageLookalike = campaign.adsets[adsetType].includeAdvantageLookalike === 1 ? 'yes' : 'no'

      const includeANLookalikes = campaign.adsets[adsetType].includeANLookalikes === 1 ? 'yes' : 'no'

      newAdvantagePlusAudiences = {
        ...newAdvantagePlusAudiences,
        [adsetType]: includeAdvantagePlusAudience,
      }

      newAdvantageLookalikeAudiences = {
        ...newAdvantageLookalikeAudiences,
        [adsetType]: includeAdvantageLookalike,
      }

      newANLookalikeAudiences = {
        ...newANLookalikeAudiences,
        [adsetType]: includeANLookalikes,
      }
    })

    setBudget(newBudgetPercentages)
    setBudgetAmount(newBudgetAmounts)

    setAdvantagePlusAudience(newAdvantagePlusAudiences)
    setAdvantageLookalikeAudience(newAdvantageLookalikeAudiences)

    setIncludeANLookalikes(newANLookalikeAudiences)

    setBudgetError(null)
  }

  // Calculate the initial values of the columns at the first render.
  useEffect(() => {
    if (!campaign.adsets) {
      return
    }

    setInitialValues()
  }, [])

  useEffect(() => {
    if (!campaign.adsets) {
      return
    }

    const totalBudgetPercentage = Object.values(budget).reduce(
      (acc, current) => acc + current,
      0,
    )
    const totalBudgetAmount = Object.values(budgetAmount).reduce(
      (acc, current) => acc + current,
      0,
    )

    setTotalValues({
      budget: totalBudgetPercentage,
      budgetAmount: totalBudgetAmount,
    })
  }, [budget])

  if (!campaign.adsets) {
    return null
  }

  const openLookalikeModal = ({ adset, adsetType }) => {
    console.log(adset.includeAdvantagePlusAudience)
    if (advantagePlusAudience[adsetType] !== "no") {
      toast.warn("For adding lookalike audiences you must turn off the Advantage+ option")
      return
    }


    openModal({
      type: Modals.LOOKALIKE_AUDIENCE_CONFIG,
      closeOnClickOutside: true,
      adset,
      enhCampaign: campaign,
      userId: customer.id,
      adAccountAudiences: fbInfo.audiences[fbInfo.selectedAdAccount.id],
      callback: (finalAdsetAudiences) => {
        adsetLookalikeAudiencesUpdated({
          newAdsetAudiences: finalAdsetAudiences,
          adsetType
        })
      },
    })
  }

  const adsetLookalikeAudiencesUpdated = ({ newAdsetAudiences, adsetType }) => {

    const newFbInfo = { ...fbInfo }
    const indexToUpdate = newFbInfo.enhCampaigns.findIndex(
      c => c._id === campaign._id,
    )
    const newCampaign = { ...newFbInfo.enhCampaigns[indexToUpdate] }

    newCampaign.adsets = {
      ...newCampaign.adsets,
      [adsetType]: {
        ...newCampaign.adsets[adsetType],
        audiences: newAdsetAudiences
      }

    }


    newFbInfo.enhCampaigns[indexToUpdate] = newCampaign
    setFbInfo(newFbInfo)
  }


  return (
    <React.Fragment>
      {Object.keys(campaign.adsets).map((adsetType, adsetIndex) => (
        <tr
          className={`${campaignIndex % 2 === 0 ? 'darker' : ''}`}
          key={`${campaign.label}-${adsetType}`}
        >
          {adsetIndex === 0 && (
            <td rowSpan={Object.keys(campaign.adsets).length} style={{ fontWeight: "600" }}>
              {campaign.label}
            </td>
          )}

          <td>{formatAdsetType(adsetType)}</td>

          <td>{campaign.adsets[adsetType].effectiveStatus}</td>

          <td>
            {!helper.isEmptyObject(budget) && (
              <input
                className="input"
                type="number"
                value={budget[adsetType]}
                onChange={e =>
                  handleValueChange(adsetType, 'budget', e.target.value)
                }
              />
            )}
          </td>

          <td>
            {helper.getCurrencySymbol(currency)}
            {numberFormatter.formatNumber(
              budgetAmount[adsetType],
              budgetAmount[adsetType] > 10000 ? 0 : 1,
            )}
          </td>

          <td>
            <Switch
              value={advantagePlusAudience[adsetType] ?? 'no'}
              onChange={state =>
                handleValueChange(adsetType, 'advantagePlusAudience', state)
              }
              options={['no', 'yes']}
            />
          </td>

          <td className='audiences-cell'>
            {

              ['remarketing', 'broad', 'traffic'].includes(adsetType) ? "NA" :
                <React.Fragment>
                  <Button className="cell-hover-button" size="small" onClick={() => { openLookalikeModal({ adset: campaign.adsets[adsetType], adsetType }) }}>
                    <FaPen />
                  </Button>
                  {
                    campaign.adsets[adsetType].audiences.filter(a => a.isLookalike).length ?
                      campaign.adsets[adsetType].audiences.filter(a => a.isLookalike).map(a =>
                        <div className='cell-content'>
                          {a.name}
                        </div>)
                      : "-"
                  }
                </React.Fragment>
            }

          </td>

          <td className='audiences-cell'>
            {campaign.adsets[adsetType].audiences.length ? campaign.adsets[adsetType].audiences.filter(a => !a.isLookalike).map(a =>
              <div className='cell-content'>
                {a.name}
              </div>)
              :
              "-"
            }
          </td>

          <td className='audiences-cell'>
            {campaign.adsets[adsetType].excludedAudiences.length ? campaign.adsets[adsetType].excludedAudiences.map(a =>
              <div className='cell-content'>
                {a.name}
              </div>)
              :
              "-"
            }
          </td>
        </tr>
      ))}

      <tr className="total-values">
        <td className="total-cell" colSpan="3">
          Total
        </td>
        <td className={`total-budget ${budgetError?.type || ''}`}>
          {totalValues.budget?.toFixed(2)}%
        </td>
        <td>
          {helper.getCurrencySymbol(currency)}
          {numberFormatter.formatNumber(
            totalValues.budgetAmount,
            totalValues.budgetAmount > 10000 ? 0 : 1,
          )}
        </td>
        <td className="error-message" colSpan={columnsLength - 5}>
          {budgetError ? <p>Please notice you are changing the campaign budget.</p> : null}
        </td>
      </tr>

      {isDirty && (
        <tr className="campaign-actions">
          <td colSpan={columnsLength}>
            <div className="save-button-container">
              <Button
                className="save-button"
                loading={saving}
                disabled={saving}
                primary
                onClick={save}
              >
                <FaSave />
                Save
              </Button>
              <Button
                className="undo-changes-button"
                onClick={setInitialValues}
                disabled={saving}
              >
                <FaUndo />
                Undo Changes
              </Button>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  )
}

export default AIAdsTable
