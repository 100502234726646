import React, { useMemo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaTrash } from 'react-icons/fa' // Example with FaTrash (Delete) icon
import { toast } from 'react-toastify'

import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import CustomTable from '../../shared/components/UIElements/CustomTable'
import SelectColumnFilter from '../../shared/components/UIElements/SelectColumnFilter'
import DateRangeColumnFilter from '../../shared/components/UIElements/DateRangeColumnFilter'
import NumberRangeColumnFilter from '../../shared/components/UIElements/NumberRangeColumnFilter'
import EditableCell from '../../shared/components/UIElements/EditableCell'
import helper from '../../shared/util/helper'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useConstants } from '../../shared/hooks/constants-hook'

import 'react-datepicker/dist/react-datepicker.css'
import './AdminPanelTable.css'

function dateBetweenFilterFn(rows, id, filterValues) {
  let sd = filterValues[0] ? new Date(filterValues[0]) : null
  let ed = filterValues[1] ? new Date(filterValues[1]) : null

  return rows.filter(r => {
    var time = new Date(r.values[id])

    if (!sd && !ed) {
      return true // if there is no filter, return all rows
    } else if (!sd) {
      return time <= ed //just end date filter
    } else if (!ed) {
      return time >= sd //just start date filter
    } else {
      return time >= sd && time <= ed //both filter
    }
  })
}

const AdminPanelTable = () => {
  const { privateConstants: constants } = useConstants()
  const auth = useSelector(state => state.auth)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()

  let [userData, setUserData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const skipPageResetRef = useRef()
  const dataArray = []

  const [cardNotificationVisible, setCardNotificationVisible] = useState(false)
  const [cardNotificationMessage, setCardNotificationMessage] = useState('')
  const { managerUsers } = useSelector(state => state.admin)

  const transformedData = userData.map(row => {
    const performanceManager = helper.findBy(
      Object.values(managerUsers),
      '_id',
      row.performanceManager,
    )
    const accountManager = helper.findBy(
      Object.values(managerUsers),
      '_id',
      row.accountManager,
    )

    return {
      ...row,
      performanceManager: performanceManager ? performanceManager.name : 'N/A',
      accountManager: accountManager ? accountManager.name : 'N/A',
    }
  })

  const columns = useMemo(
    () => [
      {
        Header: 'User',
        columns: [
          {
            Header: 'Username',
            accessor: 'name',
            Cell: ({ cell }) => (
              <div className="username-cell">
                <button
                  className="move-to-leads-button"
                  onClick={() => moveToLeads(cell.row.original)}
                >
                  <FaTrash />
                  <span className="move-to-leads-tooltip-text">
                    move to leads
                  </span>
                </button>
                {cell.value}
              </div>
            ),
            className: 'first-cell',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Account Manager',
            accessor: 'accountManager',
          },
          {
            Header: 'Performance Manager',
            accessor: 'performanceManager',
          },
          {
            Header: 'Country',
            accessor: 'country',
          },
          {
            Header: 'Contract Start',
            Cell: EditableCell,
            meta: {
              type: 'date',
            },
            accessor: 'contractStartDate',
            Filter: DateRangeColumnFilter,
            filter: dateBetweenFilterFn,
          },
          {
            Header: 'Contract End',
            Cell: EditableCell,
            meta: {
              type: 'date',
            },
            accessor: 'contractEndDate',
            Filter: DateRangeColumnFilter,
            filter: dateBetweenFilterFn,
          },
        ],
      },
      {
        Header: 'Subscription',
        columns: [
          {
            Header: 'Status',
            Cell: EditableCell,
            meta: {
              type: 'select',
              options: constants.EditibleCellSelectOptions.status,
            },
            accessor: 'status',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Paused',
            Cell: EditableCell,
            meta: {
              type: 'select',
              options: constants.EditibleCellSelectOptions.isPaused,
            },
            accessor: 'isPaused',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Period',
            Cell: EditableCell,
            meta: {
              type: 'select',
              options: constants.EditibleCellSelectOptions.period,
            },
            accessor: 'period',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'MRR',
            Cell: EditableCell,
            meta: {
              type: 'numeric',
            },
            accessor: 'mrr',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Currency',
            Cell: EditableCell,
            meta: {
              type: 'select',
              options: constants.EditibleCellSelectOptions.currency,
            },
            accessor: 'currency',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Currency Rate',
            Cell: EditableCell,
            meta: {
              type: 'numeric',
            },
            accessor: 'contractCurrencyRate',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        ],
      },
      {
        Header: 'Payment',
        columns: [
          {
            Header: 'Invoice Amount',
            Cell: EditableCell,
            meta: {
              type: 'numeric',
            },
            accessor: 'totalInvoiceAmount',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Invoice Months',
            Cell: EditableCell,
            meta: {
              type: 'numeric',
            },
            accessor: 'totalInvoiceMonths',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Unpaid Amount',
            Cell: EditableCell,
            meta: {
              type: 'numeric',
            },
            accessor: 'totalUnpaidAmount',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Unpaid Months',
            Cell: EditableCell,
            meta: {
              type: 'numeric',
            },
            accessor: 'totalUnpaidMonths',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
        ],
      },
    ],
    [],
  )

  useEffect(() => {
    //get customers or leads
    const fetchUsers = async () => {
      if (auth.token) {
        try {
          const leadCustomerSwitch = 'Customers'
          const sortBy = {
            name: 'crmDetails.contractStartDate',
            option: 'desc',
          }
          const response = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/?list=${leadCustomerSwitch}&sort=${sortBy.name}:${sortBy.option}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          )
          setUserData(response.users)
          dataArray.push(response.users)
          setOriginalData(response.users)
        } catch (err) {
          toast.error(err?.message || 'Something went wrong')
        }
      }
    }
    fetchUsers()
  }, [])

  // useEffect(() => {
  //   // After the table has updated, always remove the flag
  //   skipPageResetRef.current = false
  // })

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    updateUserInDb(rowIndex, columnId, value)

    // We also turn on the flag to not reset the page
    skipPageResetRef.current = true
    setUserData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          }
        }
        return row
      }),
    )
  }

  const updateUserInDb = async (rowIndex, columnId, value) => {
    const id = userData[rowIndex].id
    let key = columnId
    if (
      columnId === 'status' ||
      columnId === 'isPaused' ||
      columnId === 'period' ||
      columnId === 'mrr' ||
      columnId === 'currency' ||
      columnId === 'contractCurrencyRate'
    ) {
      key = `crmDetails.subscription.${columnId}`
      if (columnId === 'mrr' || columnId === 'contractCurrencyRate') {
        value = parseFloat(value)
      }
    } else if (
      columnId === 'contractStartDate' ||
      columnId === 'contractEndDate' ||
      columnId === 'accountManager' ||
      columnId === 'performanceManager'
    ) {
      key = `crmDetails.${columnId}`
    } else if (
      columnId === 'totalInvoiceAmount' ||
      columnId === 'totalInvoiceMonths' ||
      columnId === 'totalUnpaidAmount' ||
      columnId === 'totalUnpaidMonths'
    ) {
      key = `crmDetails.${columnId}`
      value = parseFloat(value)
    }
    const queryData = { [key]: value }

    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${id}`,
      'PATCH',
      JSON.stringify(queryData),
      {
        Authorization: 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      },
    )
  }

  const moveToLeads = user => {
    const data = {
      // "crmDetails.contractStartDate": contractStartDate,
      // "crmDetails.contractEndDate": contractEndDate,
      'leadDetails.isWon': false,
      'leadDetails.funnel': constants.leadFunnelSteps,
      'crmDetails.subscription.status': 'Sign Up',
      'crmDetails.funnel': constants.funnel,
    }

    setCardNotificationMessage('Moving to leads...')
    setCardNotificationVisible(true)
    setTimeout(() => {
      setCardNotificationVisible(false)
      //removeUserFromList(user.id)

      try {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${user.id}`,
          'PATCH',
          JSON.stringify(data),
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )
        skipPageResetRef.current = true
        toast.success('Changes saved!')
        let newList = [...dataArray[0]]
        let i = newList.indexOf(helper.findBy(newList, 'id', user.id))
        newList.splice(i, 1)
        setUserData(newList)
      } catch (err) {
        toast.error(err?.message || 'Something went wrong')
      }
    }, 1500)
  }

  return (
    <div className="admin-panel-table">
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      <React.Fragment>
        <CustomTable
          columns={columns}
          data={transformedData}
          updateMyData={updateMyData}
          skipPageResetRef={skipPageResetRef}
          isResetFiltersVisible={true}
        />
      </React.Fragment>
      {/* {!isLoading && data &&
        <React.Fragment>

          <CustomTable columns={columns} data={data} updateMyData={updateMyData} skipPageResetRef={skipPageResetRef} />
        </React.Fragment>
      } */}
    </div>
  )
}

export default AdminPanelTable
