import React from 'react'
import { FaArrowRight } from 'react-icons/fa'

import googleLogo from '../../assets/images/icons/google-logo-sm.png'
import youtubeLogo from '../../assets/images/icons/youtube-logo.png'
import Button from '../FormElements/Button'
import { useSelector } from 'react-redux'

import './GoogleAdsSupport.css'

const GoogleAdsSupport = ({ data }) => {
  const performanceManagerProfile = useSelector(state => state.auth.user?.crmDetails?.performanceManagerProfile)

  const handleCustomerSupportButton = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        
        url: performanceManagerProfile.meetingUrl || process.env.REACT_APP_CALENDLY_DEMO_MEETING_LINK,
      })
    }
  }

  return (
    <div className="google-ads-support">
      <header>
        <h2 className="title">
          Book a Support Meeting to set up your
          <span className="highlight">Google Ads!</span>
        </h2>
      </header>
      <div className="icon-bubble google">
        <img className="icon" src={googleLogo} alt="Google" />
      </div>
      <div className="icon-bubble youtube">
        <img className="icon" src={youtubeLogo} alt="YouTube" />
      </div>
      <div className="buttons">
        <Button className="book-a-call-btn" onClick={handleCustomerSupportButton}>
          Book a call with us
          <FaArrowRight />
        </Button>
        {/* <Button
          className="customer-support-btn"
          onClick={handleCustomerSupportButton}
        >
          Customer Support
        </Button> */}
      </div>
      <div className="background">
        <div className="bg-bubble xl"></div>
        <div className="bg-bubble lg"></div>
        <div className="bg-bubble md"></div>
        <div className="bg-bubble sm"></div>
      </div>
    </div>
  )
}

export default GoogleAdsSupport
