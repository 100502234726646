import { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authActions } from '../../store/auth';
import { generalActions } from '../../store/general';

export const useHttpClient = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const auth = useSelector(state => state.auth)

  const dispatch = useDispatch();

  const activeHttpRequests = useRef([]);
  let isMounted;

  const sendRequest = useCallback(
    async (url, method = 'GET', body = null, headers = {}) => {
      setIsLoading(true);
      const httpAbortCtrl = new AbortController();
      activeHttpRequests.current.push(httpAbortCtrl);

      try {
        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          reqCtrl => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          throw responseData;
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        // TODO: Check do we really need the condition below
        if (err && (err.status === 401 || err.message?.includes("Token verification failed"))) {
          if (auth.token) {
            toast.warn("Your session has expired. Please log in again.");
            dispatch(authActions.logout({}));
            dispatch(generalActions.setPageTitle({ pageTitle: null }));
          }
        }

        if (isMounted) {
          setError(err);
          setIsLoading(false);
        }
        throw err;
      }
    },
    []
  );

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    isMounted = true;
    return () => {
      isMounted = false
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach(abortCtrl => abortCtrl.abort());
    };
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
