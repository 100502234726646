

const fitAudiencePageContent = () => {
    if (document.querySelector(".audience-flow") && document.querySelector(".control-center")) {

        let flowHeight = document.querySelector(".audience-flow").getBoundingClientRect().height

        document.querySelector(".page-content").setAttribute("style", `height: calc(100% - ${flowHeight}px - 1em)`)

        let controlCenterHeight = document.querySelector(".control-center").getBoundingClientRect().height
        let minusHeight = document.querySelector(".control-center-content .header-row")?.getBoundingClientRect().height + document.querySelector(".control-center-content .progress-bar")?.getBoundingClientRect().height + 5
        //if (document.querySelector(".control-center .flow-container")) document.querySelector(".control-center .flow-container").setAttribute("style", `height: ${controlCenterHeight - minusHeight}px`)
    }
}

const handleMainScrollbar = (activate) => {
    let scrollable = document.querySelector("main");
    let appNoScroll = document.querySelector(".App.full-screen-noscroll");
    let barTimeout;
    if (scrollable && !appNoScroll) {

        const handleScroll = () => {
            if (barTimeout) {
                clearTimeout(barTimeout); //clear to reset
            }
            barTimeout = setTimeout(() => {
                scrollable.classList.remove('scrolling');
            }, 500); //0.5s delay
            scrollable.classList.add('scrolling');

        }

        if (activate) {
            scrollable.addEventListener('scroll', handleScroll);
        } else {
            scrollable.removeEventListener('scroll', handleScroll);
        }
    }

}

export {
    fitAudiencePageContent,
    handleMainScrollbar
}