import React from "react";
import { Link } from "react-router-dom";
import { getShortDateString } from "../../shared/util/dateFormatter";
import { FaCheckCircle, FaExclamationTriangle, FaTrash } from "react-icons/fa";

const WebsiteConnected = props => {
    return <div className="connect-your-website">
        <h3 className="connect-text">
            <FaCheckCircle className="icon check" />
            {`Enhencer connected to your store. Data collection started on ${props.date ? getShortDateString(props.date) : "5"}`}
        </h3>
        <div className="row actions-row" style={{ fontSize: "1.1em" }}>
            <Link>
                <FaExclamationTriangle /> Report a connection problem
            </Link>

            <Link>
                <FaTrash /> Request data removal
            </Link>
        </div>

    </div>
}

export default WebsiteConnected;