import React, { Component, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "./DatePickerWithPresets.css"
import { FaCalendar, FaCalendarCheck } from "react-icons/fa";

const datePresets = {
    "Lifetime": "maximum",
    "Today": "today",
    "Yesterday": "yesterday",
    "This week": "this_week_sun_today",
    "This month": "this_month",
    "Last month": "last_month",
    "Last 7 days": "last_7d",
    "Last 14 days": "last_14d",
    "Last 30 days": "last_30d",
    "Last 60 days": "last_60d",
    "Last 90 days": "last_90d",
    "This year": "this_year",
}

const datePresetsReverse = {
    "maximum": "Lifetime",
    "today": "Today",
    "yesterday": "Yesterday",
    "this_week_sun_today": "This week",
    "this_month": "This month",
    "last_month": "Last month",
    "last_7d": "Last 7 days",
    "last_14d": "Last 14 days",
    "last_30d": "Last 30 days",
    "last_60d": "Last 60 days",
    "last_90d": "Last 90 days",
    "this_year": "This year",
}

const DatePickerWithPresets = (props) => {

    let filter;
    let start;
    let end;

    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const [dateInterval, setDateInterval] = useState(filter || "Lifetime");
    useEffect(() => {
        if (props.filter) {
            if (props.filter.type === "date_preset") {
                filter = datePresetsReverse[props.filter.value]
                setDateInterval(filter)
            } else if (props.filter.type === "time_range") {
                let range = props.filter.range
                start = new Date(parseInt(range.since))
                end = new Date(parseInt(range.until))
            }
        } else {
            setDateInterval("Lifetime")
        }
    }, [props.filter])
    const [isOpen, setIsOpen] = useState(false)

    const togglerRef = useRef()
    const contRef = useRef()

    const openDateSelection = () => {
        if (!isOpen) {
            let rect = togglerRef.current.getBoundingClientRect()
            let x = rect.x
            let y = rect.y
            let width = rect.width
            let height = rect.height

            contRef.current.style.right = window.innerWidth - x - width + "px"
            contRef.current.style.top = y + height + 10 + "px"
            setIsOpen(true)

            document.addEventListener('mousedown', clickOutsideDateSelection);
        } else {
            closeDateSelection()
        }
    }

    const clearSelection = () => {
        selectDatePreset("Lifetime", "maximum")
    }


    const clickOutsideDateSelection = (event) => {
        let path = event.path || (event.composedPath && event.composedPath())
        let inside = false;
        path.forEach(elem => {
            if (elem.classList !== undefined) {
                if (elem.classList.contains("presetted-date-picker")) {
                    inside = true
                }
            }
        })

        if (!inside) {
            setIsOpen(false)
        }
    }

    const closeDateSelection = () => {
        document.removeEventListener('mousedown', clickOutsideDateSelection);
        setIsOpen(false)
    }

    const convertPresetToRange = (preset) => {
        let now = new Date();
        let since;
        let until = now;
        switch (preset) {
            case "today":
                since = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
                break;
            case "yesterday":
                let yesterday = new Date(now);
                yesterday.setDate(now.getDate() - 1);
                since = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0, 0);
                until = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
                break;
            case "this_week_sun_today":
                let sunday = new Date(now);
                sunday.setDate(now.getDate() - now.getDay()); // Get Sunday of the current week
                since = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate(), 0, 0, 0, 0);
                break;
            case "this_month":
                since = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);
                break;
            case "last_month":
                let lastMonthFirstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                since = new Date(lastMonthFirstDay.getFullYear(), lastMonthFirstDay.getMonth(), 1, 0, 0, 0, 0);
                let lastMonthLastDay = new Date(now.getFullYear(), now.getMonth(), 0);
                until = new Date(lastMonthLastDay.getFullYear(), lastMonthLastDay.getMonth(), lastMonthLastDay.getDate(), 23, 59, 59, 999);
                break;
            case "last_7d":
                since = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
                break;
            case "last_14d":
                since = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000);
                break;
            case "last_30d":
                since = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
                break;
            case "last_60d":
                since = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000);
                break;
            case "last_90d":
                since = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
                break;
            case "this_year":
                since = new Date(now.getFullYear(), 0, 1, 0, 0, 0, 0);
                break;
            default:
                return {
                    since: undefined,
                    until: undefined,
                };
                break;
        }

        return {
            since: since.valueOf(),
            until: until.valueOf()
        }
    }

    const selectDatePreset = (name, preset) => {
        // let filters = { datePreset: preset }

        let filters = {
            type: "date_preset", value: preset, range: convertPresetToRange(preset)
        }

        setDateInterval(name)
        setIsOpen(false)
        setStartDate(null);
        setEndDate(null);

        if (props.setFilters) props.setFilters(filters)
    }

    const selectDateRange = (dates) => {
        const [start, end] = dates
        setDateInterval(null)
        setStartDate(start)
        setEndDate(end)

    }

    const updateDateRange = () => {
        // let filters = { timeRange: JSON.stringify({ since: getFBDateFormat(startDate), until: getFBDateFormat(endDate) }) }
        let filters = {
            type: "time_range",
            value: "time_range",
            range: {
                since: startDate.valueOf(), until: endDate.valueOf()
            }
        }

        setDateInterval(getFBDateFormat(new Date(startDate)) + "  :  " + getFBDateFormat(new Date(endDate)))
        setIsOpen(false)
        if (props.setFilters) props.setFilters(filters)
    }

    const getFBDateFormat = (date) => {
        let m = (date.getMonth() + 1).toString()
        let d = date.getDate().toString()
        let y = date.getFullYear().toString()
        if (m.length == 1) {
            m = "0" + m
        }
        if (d.length == 1) {
            d = "0" + d
        }
        return y + "-" + m + "-" + d
    }


    return <div className="presetted-date-picker">
        <div className="main-buttons">
            <button ref={togglerRef} onClick={openDateSelection} className="toggle-button">
                <FaCalendarCheck />
                {dateInterval}
            </button>
            {
                dateInterval !== "Lifetime" ? <button className="clear-button" onClick={clearSelection}>
                    X
                </button> : null
            }

        </div>

        <div className={`${isOpen ? 'open' : ''} date-selection-popover`} ref={contRef}>
            <div className="date-selection-content">
                <div className="left-side">
                    <DatePicker
                        selected={startDate}
                        onChange={selectDateRange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        inline
                    />
                    {endDate && <div className="date-text">

                        {startDate.toDateString()} - {endDate.toDateString()}
                    </div>}
                    <div className="action-buttons">
                        <button className="button" onClick={closeDateSelection}>Cancel</button>
                        <button className="button update" onClick={updateDateRange}>Update</button>
                    </div>
                </div>
                <div className="right-side">
                    <h6>Date&nbsp;presets</h6>
                    <ul>
                        {
                            Object.keys(datePresets).map(key =>
                                <li key={key} onClick={() => { selectDatePreset(key, datePresets[key]) }} className={dateInterval === key ? 'active' : ''}>{key}</li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>


    </div>
}

export default DatePickerWithPresets;

