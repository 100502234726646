import React, { useEffect, useRef, useState } from 'react'
import { FaPen } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import { useDebounce } from '../../shared/hooks/debounce-hook'

import './CampaignLabel.css'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'

const CampaignLabel = () => {

  const inputRef = useRef(null)

  const currentCampaign = useSelector(state => state.facebookCampaignEditor.currentCampaign)
  const countries = currentCampaign?.targeting?.countries
  const value = currentCampaign?.label
  const campaignId = currentCampaign?.id
  const isLabelDuplicate = currentCampaign?.isLabelDuplicate
  const isCampaignEditing = !!currentCampaign
  const isLabelValid = isCampaignEditing ? currentCampaign?.isLabelValid : true

  const [isTyping, setIsTyping] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const [label, setLabel] = useState(value || "New campaign")
  const [isLabelFallback, setIsLabelFallback] = useState(false)
  const debouncedLabel = useDebounce(label)

  const { changeLabel } = useFacebookCampaignEditor()

  const fallback = (() => { return countries?.toString().replaceAll(',', ', ') })()

  const handleClick = () => {
    if (isCampaignEditing) setIsEditing(true)

  }

  const handleChange = e => {
    setIsTyping(true)
    setLabel(e.target.value)
    changeLabel({ newLabel: e.target.value, campaignId })
    setIsLabelFallback(false)
  }

  const handleKeyPress = e => {
    if (e.key !== 'Enter') return
    handleBlur()
  }

  const handleBlur = () => {
    if (isTyping) return
    setIsEditing(false)
  }

  useEffect(() => {
    if (!isEditing) return
    setIsTyping(false)

  }, [isEditing, debouncedLabel])

  useEffect(() => {
    if (!isEditing) return

    inputRef.current.focus()
  }, [isEditing])


  useEffect(() => {
    if ((label === "New campaign" || isLabelFallback) && fallback) {
      setLabel(fallback)
      changeLabel({ newLabel: fallback, campaignId })
      setIsLabelFallback(true)
    }
  }, [fallback])

  return (
    <div className={`campaign-label-wrapper${isCampaignEditing ? ' campaign-edit-mode' : ''}`}>


      {
        isEditing ?
          <input
            ref={inputRef}
            type="text"
            value={label}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            className="label-input"
          />
          :
          <React.Fragment>
            <div
              onClick={handleClick}
              className="campaign-label"
              title={label}
            >
              {label}
            </div>
            {isCampaignEditing && <FaPen className="icon" onClick={handleClick} />}
          </React.Fragment>
      }
      {
        !isTyping && (
          isLabelDuplicate ?
            <div className="label-error">Duplicate campaign label</div>
            :
            isLabelValid ?
              null
              :
              <div className="label-error">Enter a valid campaign label</div>
        )
      }
    </div>

  )

}

export default CampaignLabel
