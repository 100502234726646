import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Joyride, { STATUS } from 'react-joyride'
import enhencerLogo from '../../assets/images/icons/enhencer-logo.png'
import introStep1 from '../../assets/images/enhencer-guide/intro-step1.png'
import introStep2 from '../../assets/images/enhencer-guide/intro-step2.png'
import introStep3 from '../../assets/images/enhencer-guide/intro-step3.png'
import introStep4 from '../../assets/images/enhencer-guide/intro-step4.png'
import introStep5 from '../../assets/images/enhencer-guide/intro-step5.png'
import './Intro.css'
import { useLocation } from 'react-router-dom'
import cookies from '../../util/cookies'

function generateStep(stepInfo, target, totalSteps) {
  let content = {
    content: (
      <div className="intro">
        <img src={enhencerLogo} alt="Enhencer" className="enh-logo" />
        <div className="content">
          <img
            src={stepInfo.imgSource}
            alt={stepInfo.imgAlt}
            className="step-img"
          />
          <span className="title">{stepInfo.title}</span>
          <span>{stepInfo.description}</span>
          <span className="progress">{`${stepInfo.progress}/${totalSteps}`}</span>
        </div>
      </div>
    ),
    locale: {
      back: <FaChevronLeft aria-label="back" />,
      next: <FaChevronRight aria-label="next" />,
      last: <FaChevronRight aria-label="last" />,
    },
    target: target,
  }
  if (stepInfo.imgAlt === 'Step1') {
    content = {
      ...content,
      placement: 'center',
    }
  }
  return content
}

function generateSteps() {
  const isMobile = window.innerWidth < 768
  const allSteps = [
    {
      imgSource: introStep1,
      title: 'Welcome to Enhencer AI Ads',
      description: 'This is a quick guide on how to launch your first AI Ads.',
      target: 'body',
      imgAlt: 'Step1',
    },
    {
      imgSource: introStep2,
      title: 'Connect your Facebook',
      description:
        'You first need to connect your Facebook Ads. Click here to go to the connect page.',
      target:
        '.topbar-container .topbar .right-menu .bar-button.meta-connection',
      imgAlt: 'Step2',
    },
    {
      imgSource: introStep3,
      title: 'AI Ads Launcher',
      description:
        'After connecting your Facebook account, click on this card and launch your AI Ads.',
      target:
        '.ai-ads .ai-ads-content-wrapper .ai-ads-home-grid .fluid-card-container.click-to-start',
      imgAlt: 'Step3',
    },
    {
      imgSource: introStep4,
      title: 'Advanced Reporting',
      description:
        'After launching your AI Ads, you can monitor your ad performance here.',
      target: isMobile
        ? '.ai-ads .ai-ads-content-wrapper .ai-ads-home-grid .mobile-campaign-report-wrapper .card-header '
        : '.ai-ads .ai-ads-content-wrapper .ai-ads-home-grid .campaign-report-wrapper .fluid-card-container .fluid-card-header',
      imgAlt: 'Step4',
    },
    {
      imgSource: introStep5,
      title: 'Enhencer Academy',
      description:
        'Finally, to access resources about paid marketing, visit Enhencer Academy.',
      target: '.ai-ads .ai-ads-sidebar-wrapper .sidebar-items .tutorial-slider',
      imgAlt: 'Step5',
    },
  ]

  const selectedSteps = isMobile ? [0, 2, 3] : [0, 1, 2, 3, 4]
  const totalSteps = selectedSteps.length
  const steps = selectedSteps.map((index, i) => {
    const stepInfo = {
      ...allSteps[index],
      progress: i + 1,
    }
    return generateStep(stepInfo, stepInfo.target, totalSteps)
  })

  return steps
}

const Intro = forwardRef((props, ref) => {
  const location = useLocation()
  const user = useSelector(state => state.auth.user)
  const introKey = 'introStatus'
  useImperativeHandle(ref, () => ({
    handleClickStart() {
      setIsRun(true)
    },
  }))

  useEffect(() => {
    if (!user.isSpecialUser && location.pathname === '/ai-ads') {
      const showIntro = JSON.parse(localStorage.getItem(introKey))
      if (!showIntro && cookies.get(`fcmmsa_${user?.id}`)) {
        setTimeout(() => {
          if (location.pathname === '/ai-ads') {
            setIsRun(true)
            localStorage.setItem(introKey, JSON.stringify('hide'))
          }
        }, 6000)
      }
    }
  }, [location.pathname])

  const handleJoyrideCallback = data => {
    const { action, status } = data
    const options = [STATUS.FINISHED, STATUS.SKIPPED]
    if (options.includes(status) || action === 'close') {
      const { reset } = helpers.current
      reset(true)
      setIsRun(false)
    }
  }

  const helpers = useRef()
  const [isRun, setIsRun] = useState(false)
  const [steps, setSteps] = useState(generateSteps())

  const setHelpers = storeHelpers => {
    helpers.current = storeHelpers
  }

  useEffect(() => {
    const handleResize = () => {
      setSteps(generateSteps())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Joyride
      callback={handleJoyrideCallback}
      getHelpers={setHelpers}
      continuous
      hideCloseButton
      run={isRun}
      scrollToFirstStep
      steps={steps}
      styles={{
        options: {
          primaryColor: '#85ca6e',
          width: 500,
          zIndex: 1000,
        },
        tooltip: {
          boxSizing: 'border-box',
          fontSize: 16,
          maxWidth: '100%',
          padding: 15,
          position: 'relative',
        },
        tooltipContainer: {
          lineHeight: 1.4,
          textAlign: 'center',
        },
        tooltipTitle: {
          fontSize: 18,
          margin: 0,
        },
        tooltipContent: {
          padding: 0,
        },
        tooltipFooter: {
          display: 'block',
          marginTop: 0,
          height: 25,
        },
        tooltipFooterSpacer: {
          flex: 1,
        },
        buttonNext: {
          borderRadius: 4,
          backgroundColor: 'transparent',
          color: 'gray',
          float: 'right',
        },
        buttonBack: {
          borderRadius: 4,
          backgroundColor: 'transparent',
          color: 'gray',
          float: 'left',
        },
      }}
    />
  )
})

export default Intro
