import React, { useMemo, useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import items from '../../shared/data/recommended-shopify-apps.json'
import shopifyLogo from '../../shared/assets/images/icons/shopify-bag.png'


import './RecommendedShopifyAppsSlider.css'

const SuccessStoriesSlider = () => {
 

  const [currentItemInfo, setCurrentItemInfo] = useState({
    idx: 0,
    isOpen: false,
  })

  const currentItem = useMemo(
    () => items[currentItemInfo.idx],
    [currentItemInfo.idx],
  )

  const handlePrev = () => {
    if (currentItemInfo.idx === 0) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx - 1,
      isOpen: false,
    }))
  }

  const handleNext = () => {
    if (currentItemInfo.idx === items.length - 1) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx + 1,
      isOpen: false,
    }))
  }

  return (
    <div className="recommended-shopify-apps-slider">
      {items.length > 1 && (
        <React.Fragment>
          <div
            onClick={handlePrev}
            className={`controller prev${
              currentItemInfo.idx === 0 ? ' disabled' : ''
            }`}
          >
            <FaAngleLeft className="icon" />
          </div>
          <div
            onClick={handleNext}
            className={`controller next${
              currentItemInfo.idx === items.length - 1 ? ' disabled' : ''
            }`}
          >
            <FaAngleRight className="icon" />
          </div>
        </React.Fragment>
      )}

      <div className="content">
        <div className="company">
          <div className="header">
            <h5 className="line">Recommended Shopify Apps</h5>
            <h5 className="line">
              Explore industry leading apps to enhance your shopify experience.
            </h5>
            <img
                className="header-background"
                src={shopifyLogo}
                alt="Background"
              />
          </div>
          <div className="main">
            
            <div>
              <div className='row'>
              <h3>
                The #{currentItemInfo.idx + 1}: {currentItem.company}
              </h3>
              <img className="logo" src={currentItem.logo} alt="Logo" />
              </div>
              <p className="description">{currentItem.description}</p>
            </div>

            <a href={currentItem.link} target="_blank" className="link">
              Try it out
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessStoriesSlider
