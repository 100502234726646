import React from 'react'
import { Link } from 'react-router-dom'
import { FaSpinner } from 'react-icons/fa'

import './Button.css'

const Button = ({
  href,
  to,
  exact,
  size,
  title,
  type,
  onClick,
  disabled,
  loading,
  loadingText,
  inverse,
  danger,
  primary,
  info,
  className,
  children,
  openInNewTab
}) => {

  const classes = [
    'button',
    `button--${size || 'default'}`,
    primary && 'button--green-gradient',
    loading && 'button--loading',
    inverse && 'button--inverse',
    danger && 'button--danger',
    info && 'button--info',
    className,
    disabled && 'button--disabled'
  ]
    .filter(Boolean)
    .join(' ')

  const content = loading ? (
    <>
      <FaSpinner className='icon' />
      {loadingText ?? "Please wait"}
    </>
  ) : (
    children
  )

  if (href) {
    return (
      <a className={classes} href={href} target={openInNewTab ? "_blank": "_self"}>
        {content}
      </a>
    )
  }

  if (to) {
    return (
      <Link to={to} exact={exact} className={classes}>
        {content}
      </Link>
    )
  }

  return (
    <button
      className={classes}
      title={title || ''}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {content}
    </button>
  )
}

export default Button
