import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AdminPanelCustomerCard from './AdminPanelCustomerCard'

import './AdminPanelCustomersList.css'
import helper from '../../shared/util/helper'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import { adminActions } from '../../store/admin'

const AdminPanelCustomersList = props => {
  const { lastCursorCustomers, customers, navigatedFromCustomerID } = useSelector(state => state.admin)

  const dispatch = useDispatch()
  const ref = useRef()
  const [isFetching, setIsFetching] = useState(props.isFetching)
  const bottomDetector = () => {
    if (helper.isElementInViewport(ref.current) && !isFetching) {
      setIsFetching(true)
      if (lastCursorCustomers) {
        /* console.log("Fetch started") */
        props.reFetch()
      } else {
        setIsFetching(false)
      }
    }
  }
  useEffect(() => {
    if (navigatedFromCustomerID) {
      const customerCard = document.querySelector("[data-id='" + navigatedFromCustomerID + "']")
      if (customerCard) {
        customerCard.scrollIntoView({ behavior: "smooth" })
        // block: "end"
        //görevini yerine getirdi kaldırıyoruz
        dispatch(adminActions.setNavigatedFromCustomerID(undefined))
      }
    }
  }, [])

  useEffect(() => {
    setIsFetching(props.isFetching)
  }, [props.isFetching])

  useEffect(() => {
    if (!lastCursorCustomers && customers.length) {
      //customers var, lastCursor yok, yani sondayız
      ref.current.style.display = "none"
    } else {
      //ilk çekerken ve aradaki pagination isteklerinde
      ref.current.style.display = "flex"
    }
  }, [lastCursorCustomers])

  return (
    <div className="admin-panel-list-wrapper" onScroll={bottomDetector}>
      <div className="admin-panel-customers-list">
        {Object.keys(customers).map(key => {
          const user = customers[key]
          const notConnectDay = parseInt(user.notConnectedInDays)
          const dayDifference = parseInt(user.lifetimeInDays)
          const connectionsCompletedInDays = parseInt(user.connectionsCompletedInDays)

          const daysPassedSinceFullFunnelCampaignStarted = parseInt(user.daysPassedSinceFullFunnelCampaignStarted)
          const daysPassedSinceViewedDetailedFacebookReport = parseInt(user.viewedDetailedFacebookReportInDays)
          const daysPassedSinceLastAdminReportCheck = parseInt(user.daysPassedSinceLastAdminReportCheck)
          const fullFunnelCampaignStartedInDays = parseInt(user.fullFunnelCampaignStartedInDays)
          const viewedDetailedFacebookReportInDays = parseInt(user.viewedDetailedFacebookReportInDays)
          const daysPassedSinceLastCall = parseInt(user.daysPassedSinceLastCall)
          const daysPassedSinceLastTalk = parseInt(user.daysPassedSinceLastTalk)
          const daysPassedSinceLastEmail = parseInt(user.daysPassedSinceLastEmail)
          const daysPassedSinceLastSMS = parseInt(user.daysPassedSinceLastSMS)
          const daysPassedSinceLastSupport = parseInt(user.daysPassedSinceLastSupport)
          const daysPassedSinceLastComment = parseInt(user.daysPassedSinceLastComment)
          const daysPassedSinceLastSelfLed = parseInt(user.daysPassedSinceLastSelfLed)
          const weeklyReports = user.facebookAds?.weeklyReports
          const currency = user.facebookAds?.currency
          const daysPassedMapping = user.mapping
          const daysPassedStart = user.start
          const daysPassedFollowUp = user.followUp
          const nextStep = user.nextStep
          const performanceStatusType = user.crmDetails?.performanceStatusType
          const performanceStatusDay = user.performanceStatusDay

          return (
            <AdminPanelCustomerCard
              user={user}
              key={user.id}
              notConnectDay={notConnectDay}
              dayDifference={dayDifference}
              daysPassedMapping={daysPassedMapping}
              connectionsCompletedInDays={connectionsCompletedInDays}
              daysPassedSinceFullFunnelCampaignStarted={daysPassedSinceFullFunnelCampaignStarted}
              daysPassedSinceViewedDetailedFacebookReport={daysPassedSinceViewedDetailedFacebookReport}
              fullFunnelCampaignStartedInDays={fullFunnelCampaignStartedInDays}
              viewedDetailedFacebookReportInDays={viewedDetailedFacebookReportInDays}
              daysPassedSinceLastAdminReportCheck={daysPassedSinceLastAdminReportCheck}
              daysPassedStart={daysPassedStart}
              daysPassedFollowUp={daysPassedFollowUp}
              nextStep={nextStep}
              performanceStatusType={performanceStatusType}
              performanceStatusDay={performanceStatusDay}
              weeklyReports={weeklyReports}
              currency={currency}
              daysPassedSinceLastCall={daysPassedSinceLastCall}
              daysPassedSinceLastTalk={daysPassedSinceLastTalk}
              daysPassedSinceLastEmail={daysPassedSinceLastEmail}
              daysPassedSinceLastSMS={daysPassedSinceLastSMS}
              daysPassedSinceLastSupport={daysPassedSinceLastSupport}
              daysPassedSinceLastComment={daysPassedSinceLastComment}
              daysPassedSinceLastSelfLed={daysPassedSinceLastSelfLed}
            />
          )
        })}
      </div>
      <div className='scroll-ending-box' ref={ref}>
        {isFetching && <InlineLoadingSpinner message="Fetching..." />}
      </div>
    </div>
  )
}

export default AdminPanelCustomersList
