import React, { useEffect, useState } from 'react'

import './AdminPanelSummarySection.css'
import BarChart from './BarChart'
import { useSelector } from 'react-redux'
import { FaMapPin } from 'react-icons/fa'
import numberFormatter from '../../shared/util/numberFormatter'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import helper from '../../shared/util/helper'


const AdminPanelSummarySection = props => {
  const { panelSummary } = useSelector(state => state.admin)

  const [isLoading, setIsLoading] = useState(true)
  const [summary, setSummary] = useState({})
  const [isPinned, setPinned] = useState(false);

  const handlePinToggle = () => {
    setPinned(!isPinned);
  };

  useEffect(() => {
    if (panelSummary) {
      setIsLoading(false)
      setSummary({
        "reach.avgCalls": {
          "value": (panelSummary.sumCalls / panelSummary.count),
          "title": "Calls",
          "tooltip": "Total calls / Total customers",
          "totalValue": panelSummary.sumCalls,
        },
        "reach.avgTalks": {
          "value": (panelSummary.sumTalks / panelSummary.count),
          "title": "Talks",
          "tooltip": "Total talks / Total customers",
          "totalValue": panelSummary.sumTalks,
        },
        "reach.avgSupports": {
          "value": (panelSummary.sumSupports / panelSummary.count),
          "title": "Support",
          "tooltip": "Total support meetings / Total customers",
          "totalValue": panelSummary.sumSupports,
        },
        "avgComments": {
          "value": panelSummary.avgComments ?? 0.0,
          "title": "Comment",
          "tooltip": "Avg. comment (Count)",
          "totalValue": panelSummary.commentsCount,
        },
        "process.metaconnected": {
          "value": (panelSummary.process_metaCompleted / panelSummary.count),
          "title": "Meta Connected",
          "totalValue": panelSummary.process_metaCompleted,
          "isPercentage": true,
        },
        "process.googleconnected": {
          "value": (panelSummary.process_googleCompleted / panelSummary.count),
          "title": "Google Connected",
          "totalValue": panelSummary.process_googleCompleted,
          "isPercentage": true,
        },
        "process.campaignpublished": {
          "value": (panelSummary.process_metaCampPublished / panelSummary.count),
          "title": "Meta Campaign Published",
          "totalValue": panelSummary.process_metaCampPublished,
          "isPercentage": true
        },
        "process.asstCampPublished": {
          "value": (panelSummary.process_asstCampPublished / panelSummary.count),
          "title": "Asst. Campaign Published",
          "tooltip": "(Talks>0 or Support>0)",
          "totalValue": panelSummary.process_asstCampPublished,
          "isPercentage": true
        },
        "process.fourteenDayActiveCamp": {
          "value": (panelSummary.process_fourteenDayActiveCamp / panelSummary.count),
          "title": "14-day Active Campaign",
          "totalValue": panelSummary.process_fourteenDayActiveCamp,
          "isPercentage": true
        },
        "process.goodBadRatio": {
          "value": (panelSummary.process_goodsCount / (panelSummary.process_goodsCount + panelSummary.process_badsCount)),
          "title": "Good-Bad Ratio",
          "totalValue": panelSummary.process_goodsCount + "/" + panelSummary.process_badsCount,
          "isPercentage": true
        },
        "churnReason.noCustomerAccess": {
          "value": (panelSummary.churnReason_noCustomerAccess / panelSummary.count),
          "title": "No Customer Access",
          "totalValue": panelSummary.churnReason_noCustomerAccess,
          "isPercentage": true,
        },
        "churnReason.technicalIssues": {
          "value": (panelSummary.churnReason_technicalIssues / panelSummary.count),
          "title": "Technical Issues / Bugs",
          "totalValue": panelSummary.churnReason_technicalIssues,
          "isPercentage": true
        },
        "churnReason.poorSupport": {
          "value": (panelSummary.churnReason_poorSupport / panelSummary.count),
          "title": "Poor Support",
          "tooltip": "(Talks>0 or Support>0)",
          "totalValue": panelSummary.churnReason_poorSupport,
          "isPercentage": true
        },
        "churnReason.lowBudget": {
          "value": (panelSummary.churnReason_lowBudget / panelSummary.count),
          "title": "Low Budget",
          "totalValue": panelSummary.churnReason_lowBudget,
          "isPercentage": true
        },
        "churnReason.lowRoas": {
          "value": (panelSummary.churnReason_lowRoas / panelSummary.count),
          "title": "Low ROAS",
          "totalValue": panelSummary.churnReason_lowRoas,
          "isPercentage": true
        },
        "churnReason.noMeta": {
          "value": (panelSummary.churnReason_noMeta / panelSummary.count),
          "title": "No Meta Ads, Only Google Ads",
          "totalValue": panelSummary.churnReason_noMeta,
          "isPercentage": true
        },
        "churnReason.other": {
          "value": (panelSummary.churnReason_other / panelSummary.count),
          "title": "Other",
          "totalValue": panelSummary.churnReason_other,
          "isPercentage": true
        },
        "churnReason.notset": {
          "value": (panelSummary.churnReason_notSet / panelSummary.count),
          "title": "<Not set>",
          "totalValue": panelSummary.churnReason_notSet,
          "isPercentage": true
        },
        "source.selfSubscribed": {
          "value": (panelSummary.source_selfSubscribed / panelSummary.count),
          "title": "Self-Subscribed",
          "totalValue": panelSummary.source_selfSubscribed,
          "isPercentage": true
        },
        "source.outboundReach": {
          "value": (panelSummary.source_outboundReach / panelSummary.count),
          "title": "Outbound Reach",
          "totalValue": panelSummary.source_outboundReach,
          "isPercentage": true
        },
        "source.metaDemo": {
          "value": (panelSummary.source_metaDemo / panelSummary.count),
          "title": "Meta Demo",
          "totalValue": panelSummary.source_metaDemo,
          "isPercentage": true
        },
        "source.googleDemo": {
          "value": (panelSummary.source_googleDemo / panelSummary.count),
          "title": "Google Demo",
          "totalValue": panelSummary.source_googleDemo,
          "isPercentage": true
        },
        "source.directDemo": {
          "value": (panelSummary.source_directDemo / panelSummary.count),
          "title": "Direct Demo",
          "totalValue": panelSummary.source_directDemo,
          "isPercentage": true
        },
        "source.organicDemo": {
          "value": (panelSummary.source_organicDemo / panelSummary.count),
          "title": "Organic Demo",
          "totalValue": panelSummary.source_organicDemo,
          "isPercentage": true
        },
        "source.signupCall": {
          "value": (panelSummary.source_signupCall / panelSummary.count),
          "title": "Sign-up Call",
          "totalValue": panelSummary.source_signupCall,
          "isPercentage": true
        },
        "source.signupDemo": {
          "value": (panelSummary.source_signupDemo / panelSummary.count),
          "title": "Sign-up + Demo",
          "totalValue": panelSummary.source_signupDemo,
          "isPercentage": true
        },
        "source.tidio": {
          "value": (panelSummary.source_tidio / panelSummary.count),
          "title": "Tidio",
          "totalValue": panelSummary.source_tidio,
          "isPercentage": true
        },
        "source.partner": {
          "value": (panelSummary.source_partner / panelSummary.count),
          "title": "Partner",
          "totalValue": panelSummary.source_partner,
          "isPercentage": true
        },
        "subscription.monthlyRatio": {
          "value": (panelSummary.subscription_monthlyCount / panelSummary.count),
          "title": "Monthly Subs",
          "tooltip": "Subscriptions in monthly period",
          "totalValue": panelSummary.subscription_monthlyCount,
          "isPercentage": true
        },
        "subscription.yearlyRatio": {
          "value": (panelSummary.subscription_yearlyCount / panelSummary.count),
          "title": "Yearly Subs",
          "tooltip": "Subscriptions in yearly period",
          "totalValue": panelSummary.subscription_yearlyCount,
          "isPercentage": true
        },
        "subscription._divider1": { "isDivider": true },
        "subscription.threeMonthRecurringCount": {
          "value": (panelSummary.threeMonthRecurringCount / panelSummary.count),
          "title": "+3 months Subs",
          "tooltip": "Count of 3-month subscribers / Total customers (Affected from Perf. Mngr. Changes)",
          "totalValue": panelSummary.threeMonthRecurringCount,
          "isPercentage": true
        },
        "subscription.sixMonthRecurringCount": {
          "value": (panelSummary.sixMonthRecurringCount / panelSummary.count),
          "title": "+6 months Subs",
          "tooltip": "Count of 6-month subscribers / Total customers (Affected from Perf. Mngr. Changes)",
          "totalValue": panelSummary.sixMonthRecurringCount,
          "isPercentage": true
        },
        "subscription.oneYearRecurringCount": {
          "value": (panelSummary.oneYearRecurringCount / panelSummary.count),
          "title": "+12 months Subs",
          "tooltip": "Count of 12-month subscribers / Total customers (Affected from Perf. Mngr. Changes)",
          "totalValue": panelSummary.oneYearRecurringCount,
          "isPercentage": true
        },
        "subscription._divider2": { "isDivider": true },
        "subscription.avgLifetime": {
          "value": panelSummary.avgLifetime,
          "title": "Avg. Lifetime",
          "tooltip": "(Affected from Perf. Mngr. Changes)",
          "totalValue": "days",
        },
        "subscription.avgMonthsOfSubscription": {
          "value": (panelSummary.avgLifetime / 30) * panelSummary.count,
          "title": "Total Months of Subs",
          "tooltip": "(Affected from Perf. Mngr. Changes)",
          "totalValue": "months",
        },
        "subscription.churnRate": {
          "value": (panelSummary.status_churnCount / panelSummary.count),
          "title": "Churn Rate",
          "tooltip": "Churns + Criticals / Total customers (Affected from Perf. Mngr. Changes)",
          "totalValue": panelSummary.status_churnCount,
          "isPercentage": true
        },
        "comparison.hrevenue": {
          "value": (panelSummary.comparison_hrevenue / panelSummary.count),
          "title": "Higher Revenue",
          "totalValue": panelSummary.comparison_hrevenue,
        },
        "comparison.hroas": {
          "value": (panelSummary.comparison_hroas / panelSummary.count),
          "title": "Higher ROAS",
          "totalValue": panelSummary.comparison_hroas,
        },
        "comparison.hcost": {
          "value": (panelSummary.comparison_hcost / panelSummary.count),
          "title": "Higher Cost",
          "totalValue": panelSummary.comparison_hcost,
        },
      });
    } else {
      setIsLoading(true)
    }
  }, [panelSummary])

  const groupDetails = {};
  // Grouping details based on keys
  Object.keys(summary).forEach((key) => {
    const groupKey = key.split('.')[0]; // Extracting the common prefix
    if (!groupDetails[groupKey]) {
      groupDetails[groupKey] = [];
    }
    groupDetails[groupKey].push(summary[key]);
  })
  if (isLoading) {
    return (
      <div></div>
    )
  }

  return (
    <div className={`overlay-panel ${isPinned ? 'pinned' : ''}`}
    >
      <div className='summary-title'>Summary</div>
      <div className={`pin-toggle ${isPinned ? 'pinned' : ''}`} onClick={handlePinToggle} >
        <FaMapPin />
      </div>
      <div className='summary-details'>
        <div className='summary-details-column'>
          <div className='summary-group-container'>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgCalls'].title}</div>
              <div className='data'>{summary['reach.avgCalls'].value?.toFixed(2) + " (" + summary['reach.avgCalls'].totalValue + ")"}</div>
            </div>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgTalks'].title}</div>
              <div className='data'>{summary['reach.avgTalks'].value?.toFixed(2) + " (" + summary['reach.avgTalks'].totalValue + ")"}</div>
            </div>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgSupports'].title}</div>
              <div className='data'>{summary['reach.avgSupports'].value?.toFixed(2) + " (" + summary['reach.avgSupports'].totalValue + ")"}</div>
            </div>
            <div className='summary-group-data'>
              <div className='title'>{summary['avgComments'].title}</div>
              <div className='data'>{summary['avgComments'].value?.toFixed(2) + " (" + summary['avgComments'].totalValue + ")"}</div>
            </div>

          </div>
          <div className='summary-details-column'>
            <div className='summary-group-container summary-group-data' style={{ flexGrow: 1 }}>
              <div className='title'>{"Source (🤝 by Acc. Mngr.)"} </div>
              {groupDetails["source"].map((detail, index) => {
                if (detail.isPercentage) {
                  return (
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value * 100)?.toFixed(1)}%
                        </div>
                        <div className='helper-text'>({numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  )
                } else {
                  return (
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value ?? 0)?.toFixed(2)}
                        </div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  )
                }
              }
              )
              }
            </div>
          </div>
        </div>
        <div className='summary-details-column'>
          <div className='summary-group-container summary-group-data'>
            <div className='title'>Process</div>
            {groupDetails["process"].map((detail, index) => {
              if (detail.isPercentage) {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value * 100)?.toFixed(1)}%
                        </div>
                        <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value ?? 0)?.toFixed(2)}
                        </div>
                        <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              }
            }
            )}
          </div>
          <div className='summary-group-container summary-group-data' style={{ flexGrow: 1 }}>
            <div className='title'>Comparison</div>
            {groupDetails["comparison"].map((detail, index) => (
              <div key={index} className='summary-details-row'>
                <div className='data'>
                  <div className='value'>
                    {(detail.value * 100)?.toFixed(1)}%
                  </div>
                  <div className='helper-text'>({numberFormatter.formatNumber(detail.totalValue)})</div>
                </div>
                <div className='description'>{detail.title}</div>
              </div>
            ))}
          </div>
          <div className='summary-details-column'>
            <div className='summary-group-container summary-group-data' style={{ flexGrow: 1 }}>
              <div className='title'>Subscription</div>
              {groupDetails["subscription"].map((detail, index) => {
                if (detail.isDivider) {
                  return (<div className='summary-divider' key={index}></div>)
                }
                if (detail.isPercentage) {
                  return (
                    <Tooltip
                      key={detail.title}
                      content={detail.tooltip ?? ""}
                      isHidden={!detail.tooltip}
                      placement="right">
                      <div key={index} className='summary-details-row'>
                        <div className='data'>
                          <div className='value'>
                            {(detail.value * 100)?.toFixed(1)}%
                          </div>
                          <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                        </div>
                        <div className='description'>{detail.title}</div>
                      </div>
                    </Tooltip>
                  )
                } else {
                  return (
                    <Tooltip
                      key={detail.title}
                      content={detail.tooltip ?? ""}
                      isHidden={!detail.tooltip}
                      placement="right">
                      <div key={index} className='summary-details-row'>
                        <div className='data'>
                          <div className='value'>
                            {(detail.value ?? 0)?.toFixed(2)}
                          </div>
                          <div className='helper-text'>{isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)}</div>
                        </div>
                        <div className='description'>{detail.title}</div>
                      </div>
                    </Tooltip>
                  )
                }
              }
              )
              }
            </div>
          </div>
        </div>
        <div className='summary-details-column'>
          <div className='summary-group-container summary-group-data'>
            <div className='title'>Churn Reason</div>
            {groupDetails["churnReason"].map((detail, index) => {
              if (detail.isPercentage) {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value * 100)?.toFixed(1)}%
                        </div>
                        <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value ?? 0)?.toFixed(2)}
                        </div>
                        <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              }
            }
            )}
          </div>
        </div>
      </div>

    </div >
  )
}

export default AdminPanelSummarySection

/* 

*/