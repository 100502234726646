import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { toast } from "react-toastify";

import { generalActions } from "../../store/general";
import { authActions } from "../../store/auth";
import { useHttpClient } from "../../shared/hooks/http-hook";
import helper from "../../shared/util/helper";
import dateFormatter from "../../shared/util/dateFormatter";
import numberFormatter from "../../shared/util/numberFormatter";
import gradientMaker from "../../shared/util/gradientMaker";
import Table from "../../shared/components/UIElements/Table";
import InfoBadge from "../../shared/components/UIElements/InfoBadge";
import Card from "../../shared/components/UIElements/Card";
import CRMGeoChart from "../components/CRMGeoChart";
import Button from "../../shared/components/FormElements/Button";
import Switch from "../../shared/components/UIElements/Switch";

import "./CRMReport.css"

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const CRMReport = props => {
    const auth = useSelector((state) => state.auth);
    const { sendRequest } = useHttpClient();
    const [totalActiveCustomers, setTotalActiveCustomers] = useState(0);
    const [mrr, setMrr] = useState(0);
    const [monthlyChurnRate, setMonthlyChurnRate] = useState(0);
    const [mapData, setMapData] = useState([]);
    const activeCustomersChartRef = useRef()
    const mrrChartRef = useRef()
    const churnChartRef = useRef()
    const activeCustomersByPlatformChartRef = useRef()
    const activeCustomersBySourceChartRef = useRef()
    const adSpentByPlatformChartRef = useRef()

    const chartPointRadius = parseInt(window.innerWidth / 400) > 5 ? 5 : parseInt(window.innerWidth / 400)

    const [activeCustomersPlatformTableData, setActiveCustomersPlatformTableData] = useState({
        head: ["Platform", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeCustomersSourceTableData, setActiveCustomersSourceTableData] = useState({
        head: ["Source", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });

    const [activeCustomersSourceandPlatformTableData, setActiveCustomersSourceandPlatformTableData] = useState({
        head: ["Platform", "Source", "Active Customers", "Average MRR", "Total MRR", "% MRR"],
        rows: []
    });


    const [activeCustomersCountryTableData, setActiveCustomersCountryTableData] = useState({
        head: ["Country", "Active Customers", "% Share", "Total MRR", "% MRR"],
        rows: []
    });


    const allCustomersSourceTableData = {
        head: ["Source", "Lifetime Value", "CAC", "ROI"], // average mrr was in the second column
        rows: [
            ["Inbound", "$989", "$188", "5.3"],
            ["Outbound", "$2,049", "$855", "2.4"],
            [<b>Grand Total</b>, <b>$1,627</b>, <b>$522</b>, <b>3.1</b>],
        ]
    };
    const labels = ['Jan 21', 'Feb 21', 'Mar 21', 'Apr 21', 'May 21', 'Jun 21', 'Jul 21', 'Aug 21', 'Sep 21', 'Oct 21', 'Nov 21', 'Dec 21',
        'Jan 22', 'Feb 22', 'Mar 22', 'Apr 22', 'May 22', 'Jun 22', 'Jul 22', 'Aug 22', 'Sep 22', 'Oct 22', 'Nov 22', 'Dec 22'];

    const labels2 = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];
    const labels3 = ['Jul-23', 'Aug-23', 'Sep-23', 'Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'Jul-24'];
    const [activeCustomersChartData, setActiveCustomersChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'Total retained customers',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
        ],

    })
    const [mrrChartData, setMrrChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false,
                    // text: 'Monthly MRR',
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'MRR',
                data: [990, 990, 990, 1190, 1190, 2740, 2959, 4346, 6467, 9806, 10488, 11275, 11962, 13658, 16810, 19897, 23902, 25302, 25842, 26248, 31028, 36960, 42257,
                ],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],
    })

    const [churnChartData, setChurnChartData] = useState({

        options: {
            responsive: true,
            tension: 0.4,
            scales: {
                y: {
                    min: 0,
                    max: 30
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
            },
        },
        labels: labels,
        datasets: [
            {
                label: 'Churn Rate',
                data: [0, 6, 3, 2, 4, 4, 5, 6, 3, 5, 3, 3, 1],
                lineWidth: 0,
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#c15f10',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#c15f10',
                pointHoverBackgroundColor: '#c15f10'
            },
        ],

    })

    const [activeCustomersByPlatformChartData, setActiveCustomersByPlatformChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'Other',
                data: [10, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: 'Shopify',
                data: [10, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],

    })

    const [activeCustomersBySourceChartData, setActiveCustomersBySourceChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels,
        datasets: [
            {
                label: 'Inbound',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: 'Outbound',
                data: [2, 2, 2, 3, 3, 7, 8, 10, 12, 24, 29, 38, 42, 57, 78, 95, 102, 109, 110, 111, 132, 173, 216,],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
        ],

    })

    const [adSpentByPlatformChartData, setAdSpentByPlatformChartData] = useState({
        options: {
            responsive: true,
            tension: 0.4,
            plugins: {
                legend: {
                    display: true
                },
                title: {
                    display: true,
                },
            },
        },
        labels: labels3,
        datasets: [
            {
                label: 'Facebook',
                data: [2791, 1035, 4186, 1590, 1962, 1266, 496, 1108, 4306, 6222, 14769, 12368, 12979],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#006b92',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#006b92',
                pointHoverBackgroundColor: '#006b92'
            },
            {
                label: 'Shopify',
                data: [1401, 2030, 2366, 197, 991, 3971, 2120, 3180, 2409, 7121, 15366, 15514, 11067],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#eec52c',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#eec52c',
                pointHoverBackgroundColor: '#eec52c'
            },
            {
                label: 'Google',
                data: [1795, 2014, 5435, 2893, 698, 1869, 1776, 2597, 1492, 5075, 10608, 5546, 5177],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#f27805',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#f27805',
                pointHoverBackgroundColor: '#f27805'
            },
            {
                label: 'Linkedin',
                data: [0, 824, 1082, 0, 0, 0, 0, 0, 0, 1485, 0, 0, 0],
                backgroundColor: "transparent",
                lineWidth: 0,
                borderColor: '#1fa323',
                fill: true,
                pointRadius: chartPointRadius,
                pointHoverRadius: parseInt(chartPointRadius * 1.6),
                pointBorderWidth: 5,
                pointBackgroundColor: '#1fa323',
                pointHoverBackgroundColor: '#1fa323'
            },

        ],

    })

    const adSpendByPlatform = {
        head: ["Period", "Google", "Facebook", "Shopify", "Linkedin"],
        rows: [
            ["July 2023", "$1,795", "$2,791", "$1,401", "0"],
            ["August 2023", "$2,014", "$1,035", "$2,030", "$824"],
            ["September 2023", "$5,435", "$4,186", "$2,366", "$1,082"],
            ["October 2023", "$2,893", "$1,590", "$197", "0"],
            ["November 2023", "$698", "$1,962", "$991", "0"],
            ["December 2023", "$1,869", "$1,266", "$3,971", "0"],
            ["January 2024", "$1,776", "$496", "$2,120", "0"],
            ["February 2024", "$2,597", "$1,108", "$3,180", "0"],
            ["March 2024", "$1,492", "$4,306", "$2,409", "0"],
            ["April 2024", "$5,075", "$6,222", "$7,121", "$1,485"],
            ["May 2024", "$10,608", "$14,769", "$15,366", "0"],
            ["June 2024", "$5,546", "$12,368", "$15,514", "0"],
            ["July 2024", "$5,177", "$12,979", "$11,067", "0"],
        ]
    }


    const pageTitle = "Enhencer Financials"

    useEffect(() => {
        document.title = pageTitle
        document.querySelector(".App").classList.add("full-screen")

        const fetchUsers = async () => {
            if (auth.token) {
                try {
                    let url = `${process.env.REACT_APP_BACKEND_URL}/dev/getReportData`

                    const responseData = await sendRequest(url, 'POST', null, {
                        Authorization: 'Bearer ' + auth.token,
                    })
                    const activeCustomersData = responseData.activeCustomersChartData;
                    setTotalActiveCustomers(activeCustomersData[activeCustomersData.length - 1]);
                    const newLabels = responseData.chartLabels;
                    const mrrData = responseData.mrrChartData;
                    const activeOtherCustomersChartData = responseData.activeOtherCustomersChartData;
                    const activeShopifyCustomersChartData = responseData.activeShopifyCustomersChartData;
                    const activeInboundCustomersChartData = responseData.activeInboundCustomersChartData;
                    const activeOutboundCustomersChartData = responseData.activeOutboundCustomersChartData;
                    setMrr(numberFormatter.formatNumber(parseInt(mrrData[mrrData.length - 1])) + "$");

                    const discardingMonthCountInChurn = 11
                    const churnData = responseData.churnChartData.slice(discardingMonthCountInChurn);
                    const churnRate = churnData.slice(-6).reduce((a, b) => a + b, 0) / 6;
                    setMonthlyChurnRate(churnRate.toFixed(2) + "%");

                    if (activeCustomersChartRef.current) {
                        const datasetsCopy = activeCustomersChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeCustomersData;

                        const footer = (tooltipItems) => {
                            let churnCount = responseData.monthlyChurnCountData[tooltipItems[0].dataIndex] ?? 0
                            let additionCount = responseData.monthlyNewCustomerCountData[tooltipItems[0].dataIndex] ?? 0
                            return '🟢New Customer: ' + additionCount + "\n🛑Churn Customer: " + churnCount;
                        };

                        setActiveCustomersChartData(Object.assign({}, activeCustomersChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy,
                            options: {
                                ...activeCustomersChartData.options,
                                plugins: {
                                    ...activeCustomersChartData.options.plugins,
                                    tooltip: {
                                        callbacks: {
                                            footer: footer,
                                        }
                                    }
                                }
                            }
                        }))
                    }
                    if (mrrChartRef.current) {
                        const datasetsCopy = mrrChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(mrrChartRef.current.ctx, "#eec52c");
                        datasetsCopy[0].data = mrrData;

                        const footer = (tooltipItems) => {                            
                            let churnCount = numberFormatter.formatNumber(responseData.monthlyChurnedMRRCountData[tooltipItems[0].dataIndex]) ?? 0
                            let additionCount = numberFormatter.formatNumber(responseData.monthlyNewMRRCountData[tooltipItems[0].dataIndex]) ?? 0
                            return '🟢New MRR: ' + additionCount + "\n🛑Churned MRR: " + churnCount;
                        };

                        setMrrChartData(Object.assign({}, mrrChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy,
                            options: {
                                ...mrrChartData.options,
                                plugins: {
                                    ...mrrChartData.options.plugins,
                                    tooltip: {
                                        callbacks: {
                                            footer: footer,
                                        }
                                    }
                                }
                            }
                        }))
                    }
                    if (activeCustomersByPlatformChartRef.current) {
                        const datasetsCopy = activeCustomersByPlatformChartData.datasets.slice(0);
                        // datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersByPlatformChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeOtherCustomersChartData;
                        datasetsCopy[1].data = activeShopifyCustomersChartData;
                        setActiveCustomersByPlatformChartData(Object.assign({}, activeCustomersByPlatformChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (activeCustomersBySourceChartRef.current) {
                        const datasetsCopy = activeCustomersBySourceChartData.datasets.slice(0);
                        // datasetsCopy[0].backgroundColor = gradientMaker(activeCustomersBySourceChartRef.current.ctx, "#006b92");
                        datasetsCopy[0].data = activeInboundCustomersChartData;
                        datasetsCopy[1].data = activeOutboundCustomersChartData;
                        setActiveCustomersBySourceChartData(Object.assign({}, activeCustomersBySourceChartData, {
                            labels: newLabels,
                            datasets: datasetsCopy
                        }))
                    }
                    if (churnChartRef.current) {
                        const datasetsCopy = churnChartData.datasets.slice(0);
                        datasetsCopy[0].backgroundColor = gradientMaker(churnChartRef.current.ctx, "#c15f10");
                        datasetsCopy[0].data = churnData;

                        const footer = (tooltipItems) => {
                            let churnCount = responseData.monthlyChurnCountData.slice(discardingMonthCountInChurn)[tooltipItems[0].dataIndex] ?? 0
                            return "Churn Count: " + churnCount;
                        };

                        setChurnChartData(Object.assign({}, churnChartData, {
                            labels: newLabels.slice(discardingMonthCountInChurn),
                            datasets: datasetsCopy,
                            options: {
                                ...churnChartData.options,
                                plugins: {
                                    ...churnChartData.options.plugins,
                                    tooltip: {
                                        callbacks: {
                                            footer: footer,
                                        }
                                    }
                                }
                            }
                        }))
                    }

                    const activeCustomersPlatformTableRows = [];
                    const activeCustomersTableDataByPlatform = responseData.activeCustomersTableDataByPlatform;
                    activeCustomersTableDataByPlatform.forEach((result, index) => {
                        if (index !== activeCustomersTableDataByPlatform.length - 1) {
                            activeCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersPlatformTableRows.push([
                                <b>{result._id.platform}</b>,
                                <b>{result.count}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$"}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$"}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveCustomersPlatformTableData(Object.assign({}, activeCustomersPlatformTableData, {
                        rows: activeCustomersPlatformTableRows
                    }))

                    const activeCustomersSourceTableRows = [];
                    const activeCustomersTableDataBySource = responseData.activeCustomersTableDataBySource;
                    activeCustomersTableDataBySource.forEach((result, index) => {
                        if (index !== activeCustomersTableDataBySource.length - 1) {
                            activeCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersSourceTableRows.push([
                                <b>{result._id.source}</b>,
                                <b>{result.count}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$"}</b>,
                                <b>{numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$"}</b>,
                                <b>{result.percentageMrr.toFixed(2) + "%"}</b>,
                            ]);
                        }
                    });

                    setActiveCustomersSourceTableData(Object.assign({}, activeCustomersSourceTableData, {
                        rows: activeCustomersSourceTableRows
                    }))

                    const activeCustomersSourceandPlatformTableRows = [];
                    const activeCustomersTableData = responseData.activeCustomersTableData;
                    activeCustomersTableData.forEach((result, index) => {
                        if (index % 2 === 0) {
                            activeCustomersSourceandPlatformTableRows.push([
                                <td rowSpan={2} key={result._id}>{result._id.platform}</td>,
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        } else {
                            activeCustomersSourceandPlatformTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.averageMrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                                result.percentageMrr.toFixed(2) + "%",
                            ]);
                        }
                    });

                    setActiveCustomersSourceandPlatformTableData(Object.assign({}, activeCustomersSourceandPlatformTableData, {
                        rows: activeCustomersSourceandPlatformTableRows
                    }))

                    const activeCustomersCountryTableRows = [];
                    const activeCustomersMapData = [["Country", "Active Customers", "Total MRR"]];
                    const activeCustomersMapTableData = responseData.mapTableData;
                    activeCustomersMapTableData.forEach((result, index) => {
                        activeCustomersCountryTableRows.push([
                            result._id.country,
                            result.count,
                            result.percentageShare.toFixed(2) + "%",
                            numberFormatter.formatNumber(parseInt(result.totalMrr)) + "$",
                            result.percentageMrr.toFixed(2) + "%",
                        ]);
                        activeCustomersMapData.push([
                            result._id.country,
                            result.count,
                            result.totalMrr
                        ]);
                        setMapData(activeCustomersMapData);
                    });

                    setActiveCustomersCountryTableData(Object.assign({}, activeCustomersCountryTableData, {
                        rows: activeCustomersCountryTableRows
                    }))

                    const allCustomersPlatformTableRows = [];
                    const lifetimeValueTableDataByPlatform = responseData.lifetimeValueTableDataByPlatform;
                    lifetimeValueTableDataByPlatform.forEach((result, index) => {
                        if (result._id.platform === "Other") {
                            allCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "335$",
                                "5.6"
                            ]);
                        } else {
                            allCustomersPlatformTableRows.push([
                                result._id.platform,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "473$",
                                "1.2"
                            ]);
                        }
                    });

                    const allCustomersSourceTableRows = [];
                    const lifetimeValueTableDataBySource = responseData.lifetimeValueTableDataBySource;
                    lifetimeValueTableDataBySource.forEach((result, index) => {
                        if (result._id.source === "Outbound") {
                            allCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "317$",
                                "5.7"
                            ]);
                        } else {
                            allCustomersSourceTableRows.push([
                                result._id.source,
                                result.count,
                                numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                "492$",
                                "1.1"
                            ]);
                        }
                    });
                    const allCustomersLTVTableRows = [];
                    const lifetimeValueTableData = responseData.lifetimeValueTableData;
                    lifetimeValueTableData.forEach((result, index) => {
                        if (index % 2 === 0) {
                            if (result._id.platform === "Other") {
                                allCustomersLTVTableRows.push([
                                    <td rowSpan={2}>{result._id.platform}</td>,
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "220$",
                                    "9.4"
                                ]);
                            } else {
                                allCustomersLTVTableRows.push([
                                    <td rowSpan={2}>{result._id.platform}</td>,
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "413$",
                                    "1.7"
                                ]);
                            }
                        } else {
                            if (result._id.platform === "Other") {
                                allCustomersLTVTableRows.push([
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "451$",
                                    "1.4"
                                ]);
                            } else {
                                allCustomersLTVTableRows.push([
                                    result._id.source,
                                    result.count,
                                    numberFormatter.formatNumber(parseInt(result.mrr)) + "$",
                                    numberFormatter.formatNumber(parseInt(result.ltv)) + "$",
                                    "534$",
                                    "0.9"
                                ]);
                            }
                        }
                    });

                } catch (err) {
                    toast.error(err?.message || "Something went wrong")
                }
            }
        };
        fetchUsers();

        document.querySelector("#app main").addEventListener('scroll', handleScroll)

        return () => {
            document.querySelector("#app main").classList.remove("scrolled")
            document.querySelector("#app main").removeEventListener('scroll', handleScroll)
            document.querySelector(".App").classList.remove("full-screen")
        }


    }, [])

    const handleScroll = (e) => {
        let rect = document.querySelector(".control-row").getBoundingClientRect()
        let controlRowY = document.querySelector(".control-row").getBoundingClientRect().y
        let controlRowHeight = rect.height
        if (controlRowY <= 0 && !e.target.classList.contains("scrolled")) {
            e.target.classList.add("scrolled")
        } else if (e.target.scrollTop < controlRowHeight && e.target.classList.contains("scrolled")) {
            e.target.classList.remove("scrolled")
        }
    }

    // const logOut = () => {
    //     if (auth.user.isSpecialUser) {
    //         document.body.classList.remove("adminsr")
    //     }
    //     dispatch(
    //         authActions.logout({})
    //     );
    //     dispatch(
    //         generalActions.setPageTitle({ pageTitle: null })
    //     );
    // }

    return (
        <div className="crm-report">
            <div className="row control-row">
                <div className="title-container">
                    <h1>CRM Report</h1>
                    <h6>Updated at {dateFormatter(new Date())}</h6>
                </div>
                {/* <Button className="action-button" onClick={logOut}>
            Log Out
          </Button> */}
            </div>
            <div className="row info-badges">
                <InfoBadge
                    value={totalActiveCustomers}
                    name="Total Active Customers"
                />
                <InfoBadge
                    value={mrr}
                    name="Current MRR"
                    background={['#eec52c', '#bd9b1d']}
                />
            </div>
            <div className="row wrappable">
                <Card>
                    <Line
                        ref={activeCustomersChartRef}
                        options={activeCustomersChartData.options}
                        data={activeCustomersChartData}
                    />
                </Card>
                <Card>
                    <Line
                        ref={mrrChartRef}
                        options={mrrChartData.options}
                        data={mrrChartData}
                    />
                </Card>
            </div>
            <div>
                <Card>
                    <Table
                        title="Active Customers"
                        data={activeCustomersSourceandPlatformTableData}
                    />
                </Card>
            </div>
            <div className="row wrappable" style={{ alignItems: 'center' }}>
                <Card>
                    <Table
                        title="Active Customers by Platform"
                        data={activeCustomersPlatformTableData}
                    />
                </Card>
                <Card>
                    <Table
                        title="Active Customers by Source"
                        data={activeCustomersSourceTableData}
                    />
                </Card>
            </div>

            <div className="row wrappable">
                <Card>
                    <Line
                        ref={activeCustomersByPlatformChartRef}
                        options={activeCustomersByPlatformChartData.options}
                        data={activeCustomersByPlatformChartData}
                    />
                </Card>
                <Card>
                    <Line
                        ref={activeCustomersBySourceChartRef}
                        options={activeCustomersBySourceChartData.options}
                        data={activeCustomersBySourceChartData}
                    />
                </Card>
            </div>

            <div className="row wrappable">
                <Card>
                    <Table
                        title="Active Customers by Country"
                        data={activeCustomersCountryTableData}
                    />
                </Card>
                <Card>
                    <h4>Active Customers Map</h4>
                    <CRMGeoChart mapData={mapData} />
                </Card>
            </div>

            <div className="row">
                <InfoBadge
                    value={'1,627$' /*averageLifetimeValue*/}
                    name="Average Lifetime Value"
                    background={['#85ca6e', '#4c9035']}
                />
                <InfoBadge
                    value={'522$' /*customerAcquisitionCost*/}
                    name="Customer Acquisition Cost"
                    background={['#058694', '#066670']}
                />
            </div>
            <div className="row" style={{ alignItems: 'center' }}>
                {/* <Card>
                <Table title="LTV & CAC by Platform" data={allCustomersPlatformTableData} />
            </Card> */}
                <Card>
                    <Table
                        title="LTV & CAC by Source"
                        data={allCustomersSourceTableData}
                    />
                </Card>
            </div>
            <div>
                {/* <Card>
                <Table title="LTV & CAC" data={allCustomersLTV} />
            </Card> */}
            </div>
            <div className="row">
                <Card>
                    <Table title="Ad Spent By Platform" data={adSpendByPlatform} />
                </Card>
                <Card>
                    <Line
                        ref={adSpentByPlatformChartRef}
                        options={adSpentByPlatformChartData.options}
                        data={adSpentByPlatformChartData}
                    />
                </Card>
            </div>
            {/* <div className="row">
            <Card>
                <Table title="Inbound Ad Spend (1 Sept 2022 - 13 Mar 2023)" data={inboundAdSpend} />
            </Card>
        </div> */}


            {/* <div className="row">
          <Card>
            <Table
              title="Inbound Ad Spend (14 Aug 2023 - 23 Oct 2023)"
              data={inboundAdSpend}
            />
          </Card>
        </div> */}


            <div className="row">
                {/* <Card>
                <Table title="Inbound Ad Spend (1 Sep - 30 Nov, 2022)" data={inboundSales} />
            </Card> */}
            </div>

            <div className="row">
                <InfoBadge
                    value={monthlyChurnRate}
                    name="Monthly Churn Rate"
                    background={['#c15f10', '#8e4912']}
                />
            </div>
            <Card>
                <Line
                    ref={churnChartRef}
                    options={churnChartData.options}
                    height={70}
                    data={churnChartData}
                />
            </Card>
        </div>
    )
}

export default CRMReport;
