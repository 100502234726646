import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import helper from '../util/helper'
import { facebookActions } from '../../store/facebook'
import { useModal } from '../../shared/hooks/modal-hook'
import { authActions } from '../../store/auth'
import { adminActions } from '../../store/admin'
import cookies from '../util/cookies'
import { useShopify } from './shopify-hook'
import useFacebookAudiences from './facebook/audience-hook'
import Modals from '../enums/Modals'

export const useConnectionsHandler = () => {
  const { openModal } = useModal()
  const location = useLocation()
  const { checkForAudienceErrors } = useFacebookAudiences()
  const { canContinueToSubscription } = useShopify();
  const user = useSelector(state => state.auth.user)
  const admin = useSelector(state => state.admin)
  const userInView = (user?.isSpecialUser && admin.userInView) ? admin.userInView : user
  const shopifyConnected = userInView?.shopify?.scriptId
  const storeConnected = shopifyConnected ?? userInView?.connectFlow?.dataCollectionStarted
  const isLoggedIn = useSelector(state => state.facebook.isLoggedIn)
  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const adAccountSelectionIsEligible = useSelector(state => state.facebook.adAccountSelectionIsEligible)
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const pixelSelectionIsEligible = useSelector(state => state.facebook.pixelSelectionIsEligible)
  const connectionPageIsReady = useSelector(state => state.facebook.connectionPageIsReady)
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const audiences = useSelector(state => state.facebook.audiences)

  const dispatch = useDispatch()

  useEffect(() => {
    const checkConnections = () => {
      if (connectionPageIsReady) {
        let hasAudienceError = false;

        if (audiences && audiences[selectedAdAccount?.id]) {
          let result = checkForAudienceErrors({ audiences })
          hasAudienceError = !!result.error

        }

        const connectionsCompleted =
          storeConnected &&
          isLoggedIn &&
          selectedAdAccount?.id &&
          (user.isSpecialUser || (adAccountSelectionIsEligible && pixelSelectionIsEligible)) &&
          selectedBusiness?.id &&
          audiences && audiences[selectedAdAccount?.id] &&
          !hasAudienceError

        if (!userInView.connectionsCompleted) {
          if (user.isSpecialUser) {
            dispatch(adminActions.setConnectionsCompleted(connectionsCompleted))
          } else {
            dispatch(authActions.setConnectionsCompleted(connectionsCompleted))
          }
        }

        if (!user.isSpecialUser) {
          let pathname = location.pathname

          if (!connectionsCompleted && !pathname.includes("ai-audience") && (pathname.includes("ai-ads/") || pathname.includes("full-funnel"))) {
            openModal({
              type: Modals.CONNECTION_REQUIRED,
              shouldRedirect: true
            })
          }
        }


      }
    }

    if (user) {
      checkConnections()
    }

  }, [storeConnected,
    isLoggedIn,
    selectedAdAccount,
    adAccountSelectionIsEligible,
    selectedBusiness,
    pixelSelectionIsEligible,
    connectionPageIsReady,
    selectedPixel,
    audiences])

  return {
  }
}
