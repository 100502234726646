import React from "react";
import Canvas from "../../shared/components/UIElements/Canvas";
import numberFormatter from "../../shared/util/numberFormatter";

const ProductStats = (props) => {
  return (
    <React.Fragment>
      <div className="product">
        <div className="step-name">Product page</div>
        <div className="conversion-badge">
          <div className="number" title="Likely to buy">
            {((props.purchaseRowCount * 100) / props.productRowCount).toFixed(
              1
            )}{" "}
            %
          </div>
          <div className="text">Likely to buy</div>
        </div>
        <div className="canvas-container">
          <Canvas
            start={(props.productRowCount / props.customerRowCount) * 100}
            end={
              props.addToBasketRowCount
                ? (props.addToBasketRowCount / props.customerRowCount) * 100
                : (props.purchaseRowCount / props.customerRowCount) * 100
            }
            className="funnel-canvas"
          ></Canvas>
        </div>
        <div className="summary-badge">
          <div className="number" title="Visitors">
            {numberFormatter.formatNumber(props.productRowCount)}
          </div>
          <div className="text">Visitors</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductStats;
