import React from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import 'react-toastify/dist/ReactToastify.css'

const AreYouSure = props => {
  const { closeModal, modalData } = useModal()

  const yes = () => {
    modalData.callback()
    closeModal()
  }

  const no = () => {
    closeModal()
  }

  return (
    <div className="are-you-sure">
      <div className="question">{props.data.question}</div>
      <div className="footer">
        <Button className="action-button no" onClick={no}>
          
          {props.data.cancelButtonText ?? "Cancel"}
        </Button>
        <Button danger={props.data.isConfirmButtonDanger} className="action-button yes" onClick={yes}>
          {props.data.confirmButtonText ?? "Confirm"}
        </Button>
      </div>
    </div>
  )
}

export default AreYouSure
