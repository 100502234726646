import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FaCheck } from 'react-icons/fa'

import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/FormElements/Button'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import backgroundImage from '../../shared/assets/images/register-decor/complete-registration-bg.png'
import backgroundImageMobile from '../../shared/assets/images/register-decor/complete-registration-bg-mobile.png'
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png'
import countriesData from '../../shared/data/countries.json'
import timezonesData from '../../shared/data/timezones.json'
import helper from '../../shared/util/helper'
import analytics from '../../shared/util/analytics'
import CallTimeRangeSelection from '../components/CallTimeRangeSelection'
import {
  VALIDATOR_COUNTRY_CODE,
  VALIDATOR_EMAIL,
  VALIDATOR_PHONE_ONLY_DIGITS,
  VALIDATOR_REQUIRE,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import { authActions } from '../../store/auth'
import { generalActions } from '../../store/general'

import './CompleteRegistration.css'
import './Auth.css'
import constants from '../../constants'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'

const CompleteRegistration = props => {
  const { openModal } = useModal()
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { error, sendRequest, clearError } = useHttpClient()
  const navigate = useNavigate()
  const [whereDidYouHearUsError, setWhereDidYouHearUsError] = useState('')
  const [customWhereDidYouHearUsError, setCustomWhereDidYouHearUsError] = useState('')
  const [countryError, setCountryError] = useState('')
  const [timezoneError, setTimezoneError] = useState('')
  const [verifyNumberError, setVerifyNumberError] = useState('')
  const [busy, setBusy] = useState(false)
  const isMounted = useRef(true)
  const [preferredCallTimeRanges, setPreferredCallTimeRanges] = useState([])
  const [shouldSkipVerification, setShouldSkipVerification] = useState(process.env.NODE_ENV !== "production")

  const prioritizedCountries = ['United States', 'Canada', 'United Kingdom', 'Australia'];

  const countries = useMemo(() => {
    // Filter and maintain the order of prioritized countries
    const prioritized = countriesData
      .filter(country => prioritizedCountries.includes(country.name))
      .sort((a, b) => prioritizedCountries.indexOf(a.name) - prioritizedCountries.indexOf(b.name))
      .map(country => country.name);

    // Filter out the prioritized countries from the rest
    const others = countriesData
      .filter(country => !prioritizedCountries.includes(country.name))
      .map(country => country.name);

    // Concatenate prioritized countries first, then the others
    return [...prioritized, ...others];
  }, [countriesData]);

  const timezones = useMemo(
    () => timezonesData.map(c => c.name),
    [timezonesData],
  )
  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])


  const generateAvailableHours = (selectedRanges) => {

    let availableHours = [];

    // Loop through each selected range
    selectedRanges.forEach(rangeText => {
      let range = constants.callTimeRanges[rangeText]
      const [startHour, endHour] = range.split('-').map(time => parseInt(time));

      // Add hours within the range to availableHours array
      for (let i = startHour; i < endHour; i++) {
        availableHours.push(i);
      }
    });

    return availableHours;
  }

  useEffect(() => {
    if (auth.user.completedRegistration) {
      navigate('/')
    }

    handleAppClass(true)

    return () => {
      handleAppClass(false)
    }
  }, [])

  const initialFormState = {
    email: {
      value: auth.user?.email,
      isValid: !!auth.user?.email,
    },
    country: {
      value: auth.user?.country || '',
      isValid: false,
    },
    timezone: {
      value: auth.user?.timezone || '',
      isValid: false,
    },
    contactNumber: {
      value: auth.user?.contactNumber || '',
      isValid: !!auth.user?.contactNumber,
    },
    contactNumberCountryCode: {
      value: auth.user?.contactNumberCountryCode || (auth.user?.country ? helper.getCountryPhonePrefix(auth.user?.country) : ''),
      isValid: !!auth.user?.country || !!auth.user?.contactNumberCountryCode,
    },
    whereDidYouHearUs: {
      value: auth.user?.crmDetails?.whereDidYouHearUs || '',
      isValid: false,
    },
    customWhereDidYouHearUs: {
      value: auth.user?.crmDetails?.customWhereDidYouHearUs || '',
      isValid: true,
    },
  }

  const [formState, inputHandler, setFormData] = useForm(
    initialFormState,
    false,
  )

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-noscroll')
      : document.getElementById('app')?.classList.remove('full-screen-noscroll')
  }

  const submit = async event => {
    event.preventDefault()
    let whereDidYouHereUsError = false
    let countryError = false
    let timezoneError = false
    let verificationError = false
    let inboundType = 'Other'

    if (formState.inputs.whereDidYouHearUs.value === '') {
      setWhereDidYouHearUsError('Please select an option')
      setCustomWhereDidYouHearUsError('')
      whereDidYouHereUsError = true
    } else if (formState.inputs.whereDidYouHearUs.value !== 'Other') {
      setWhereDidYouHearUsError('')
      setCustomWhereDidYouHearUsError('')
      inboundType = formState.inputs.whereDidYouHearUs.value
    } else if (
      formState.inputs.whereDidYouHearUs.value === 'Other' &&
      formState.inputs.customWhereDidYouHearUs.value !== ''
    ) {
      setWhereDidYouHearUsError('')
      setCustomWhereDidYouHearUsError('')
      inboundType = formState.inputs.customWhereDidYouHearUs.value
    } else if (
      formState.inputs.whereDidYouHearUs.value === 'Other' &&
      formState.inputs.customWhereDidYouHearUs.value === ''
    ) {
      setWhereDidYouHearUsError('')
      setCustomWhereDidYouHearUsError('Please enter your custom option')
      whereDidYouHereUsError = true
    }

    if (formState.inputs.country.value === '') {
      countryError = true
      setCountryError('Please select your country')
    } else {
      countryError = false
      setCountryError('')
    }
    if (formState.inputs.timezone.value === '') {
      timezoneError = true
      setTimezoneError('Please select your timezone')
    } else {
      timezoneError = false
      setTimezoneError('')
    }


    if (!auth.user?.contactNumberVerifiedAt && process.env.NODE_ENV === "production") {
      verificationError = true
      setVerifyNumberError('Please verify your phone number')
    } else {
      verificationError = false
      setVerifyNumberError('')
    }

    if (whereDidYouHereUsError || countryError || verificationError || timezoneError) {
      return
    }

    setBusy(true)
    let timezoneOffset = timezonesData.find((timezoneObj) => {
      return timezoneObj.name === formState.inputs.timezone.value
    }).offset;

    const availableCallHours = generateAvailableHours(preferredCallTimeRanges)

    //offset callhours with timezone's offset
    for (const index in availableCallHours) {
      if (Object.hasOwnProperty.call(availableCallHours, index)) {
        availableCallHours[index] = availableCallHours[index] - timezoneOffset

        if (availableCallHours[index] >= 24) {
          availableCallHours[index] = availableCallHours[index] - 24
        } else if (availableCallHours[index] < 0) {
          availableCallHours[index] = availableCallHours[index] + 24
        }
      }
    }

    try {
      let userResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/completeRegistration`,
        'POST',
        JSON.stringify({
          userId: auth.user.id,
          email: formState.inputs.email.value,
          country: formState.inputs.country.value,
          timezoneOffset: timezoneOffset,
          inboundType: inboundType,
          availableCallHours: availableCallHours
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        },
      )

      analytics.completeSignUp({ registerType: !!auth.user.googleId ? 'google' : !!auth.user.shopify ? 'shopify' : '', userId: auth.user.id, email: formState.inputs.email.value })
      toast.success('Information saved')
      navigate('/industry-selection')
      dispatch(
        authActions.updateUserObj({
          ...auth.user,
          completedRegistration: true,
          contactEmail: formState.inputs.email.value,
          country: formState.inputs.country.value,
          crmDetails: userResult.user.crmDetails
        }),
      )
    } catch (err) {
      errorHandler(err)
    } finally {
      if (isMounted.current) {
        setBusy(false)
      }
    }
  }

  const logout = () => {
    dispatch(authActions.logout({}))
    dispatch(generalActions.setPageTitle({ pageTitle: null }))
  }

  const handleSMSVerificationButton = () => {
    if (process.env.NODE_ENV === "production") {
      if (auth.user?.contactNumberVerifiedAt) {
        return
      }

      const contactNumber = formState.inputs.contactNumber.value
      const contactNumberCountryCode =
        formState.inputs.contactNumberCountryCode.value

      const blockedCountryCodes = ['+880', '+91', '+92'] // Bangladesh, India, Pakistan

      if (blockedCountryCodes.includes(contactNumberCountryCode)) {
        return toast.info(
          'Unfortunately, we are currently not available in your country. We hope to be available soon. Thank you for your patience!',
          {
            autoClose: 6000,
          },
        )
      }

      openModal({
        type: Modals.SMS_VERIFICATION,
        closeOnClickOutside: true,
        contactNumber,
        contactNumberCountryCode,
      })
    }
  }

  const isNumberVerified = useMemo(() => {
    const currentContactNumber =
      auth.user?.contactNumberCountryCode + auth.user?.contactNumber
    const contactNumberInForm =
      formState.inputs.contactNumberCountryCode.value +
      formState.inputs.contactNumber.value

    return (
      (currentContactNumber === contactNumberInForm &&
        auth.user?.contactNumberVerifiedAt) || shouldSkipVerification

    )
  }, [
    auth.user?.contactNumber,
    auth.user?.contactNumberCountryCode,
    formState.inputs.contactNumber.value,
    formState.inputs.contactNumberCountryCode.value,
  ])

  // When the user chooses a country from the dropdown,
  // we fill the Country Code input with the selected country's code.
  useEffect(() => {
    const selectedCountry = formState.inputs.country.value

    if (!selectedCountry) {
      return
    }

    const countryCode = helper.getCountryPhonePrefix(selectedCountry)
    inputHandler('contactNumberCountryCode', countryCode, true)
  }, [formState.inputs.country.value])

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <div className="full-container complete-registration">
        <div className="almost-done-section">
          <div className="texts">
            <h2>Almost Done</h2>
            <p>
              By completing this last step, you'll gain access to a world of
              data-driven insights and AI-powered audience segments that will
              supercharge your ad marketing.
            </p>
          </div>
          <img
            className="mobile-bg-image"
            src={backgroundImageMobile}
            alt="Background"
          />
          <img className="bg-image" src={backgroundImage} alt="Background" />
        </div>
        <div className="form-section">
          <div className="mobile-almost-done-section">
            <div className="texts">
              <h2>Almost Done</h2>
              <p>
                By completing this last step, you'll gain access to a world of
                data-driven insights and AI-powered audience segments that will
                supercharge your ad marketing.
              </p>
            </div>
          </div>
          <div className="login-cont">
            <div className="logo-container">
              <img className="logo" src={enhencerLogo} />
            </div>
            <form onSubmit={submit}>
              <Input
                initialValue={initialFormState.email.value}
                element="input"
                id="email"
                type="email"
                label="We need your primary work e-mail to get in touch with you"
                validators={[VALIDATOR_EMAIL()]}
                errorText="Please enter a valid email address"
                onInput={inputHandler}
              />

              <Input
                initialValue={initialFormState.country.value}
                element="dropdown"
                id="country"
                type="text"
                label="Select your country"
                onInput={inputHandler}
                options={countries}
              />
              <div className="country error-text">{countryError}</div>
              <Input
                initialValue={initialFormState.timezone.value}
                element="dropdown"
                id="timezone"
                type="text"
                label="Select your timezone"
                onInput={inputHandler}
                options={timezones}
              />
              <div className="country error-text">{timezoneError}</div>

              < React.Fragment >
                <div className="contact-number-wrapper">
                  <div className="label-row">
                    <label htmlFor="contactNumberCountryCode">Country Code</label>
                    <label htmlFor="contactNumber">Contact Number</label>
                  </div>
                  <div className="input-row">
                    <Input
                      initialValue={
                        initialFormState.contactNumberCountryCode.value
                      }
                      forceValue={formState.inputs.contactNumberCountryCode.value}
                      element="input"
                      id="contactNumberCountryCode"
                      type="text"
                      onInput={inputHandler}
                      validators={[VALIDATOR_COUNTRY_CODE()]}
                      errorText="Please enter a valid country code"
                      placeholder="+1"
                    />
                    <Input
                      initialValue={initialFormState.contactNumber.value}
                      forceValue={formState.inputs.contactNumber.value}
                      element="input"
                      id="contactNumber"
                      type="tel"
                      validators={[VALIDATOR_PHONE_ONLY_DIGITS(), VALIDATOR_REQUIRE()]}
                      errorText="Please enter a valid phone number"
                      onInput={inputHandler}
                    />
                    <button
                      disabled={
                        !formState.inputs.contactNumber.isValid ||
                        !formState.inputs.contactNumberCountryCode.isValid ||
                        !formState.inputs.contactNumberCountryCode.value
                      }
                      onClick={handleSMSVerificationButton}
                      className={`verify-button ${isNumberVerified && 'verified'
                        }`}
                      type="button"
                    >
                      {isNumberVerified ? (
                        <>
                          <span>Verified</span>
                          <FaCheck />
                        </>
                      ) : (
                        'Verify'
                      )}
                    </button>
                  </div>
                  {verifyNumberError && (
                    <div className="verify error-text">{verifyNumberError}</div>
                  )}
                </div>
              </React.Fragment>
              {
                isNumberVerified ?
                  <CallTimeRangeSelection
                    preferredCallTimeRanges={preferredCallTimeRanges}
                    setPreferredCallTimeRanges={setPreferredCallTimeRanges}
                  /> : null
              }

              <Input
                initialValue={initialFormState.whereDidYouHearUs.value}
                element="dropdown"
                id="whereDidYouHearUs"
                type="text"
                label="Where did you hear about us?"
                validators={[VALIDATOR_REQUIRE()]}
                onInput={inputHandler}
                options={[
                  'Google',
                  'Facebook / Instagram',
                  'Shopify',
                  'LinkedIn',
                  'Email',
                  'Colleague / Friend',
                  'Other',
                ]}
              />

              <div className="error-text">{whereDidYouHearUsError}</div>

              {formState.inputs.whereDidYouHearUs.value === 'Other' && (
                <React.Fragment>
                  <Input
                    initialValue={
                      initialFormState.customWhereDidYouHearUs.value
                    }
                    element="input"
                    id="customWhereDidYouHearUs"
                    type="text"
                    label=""
                    validators={[]}
                    placeholder="Where did you hear about us"
                    errorText="Where did you hear about us"
                    onInput={inputHandler}
                  />
                  <div className="error-text">
                    {customWhereDidYouHearUsError}
                  </div>
                </React.Fragment>
              )}

              <div className="cont">
                <Button loading={busy} className="action-button" type="submit">
                  Continue
                </Button>
              </div>
            </form>

            <div className="no-account-row">
              <div className="log-out-link" onClick={logout}>
                Log Out
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment >
  )
}

export default CompleteRegistration
