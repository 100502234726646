import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import helper from '../../shared/util/helper'
import AdminUserDetailLayout from '../components/AdminUserDetailLayout'
import AdminUserDetailName from '../components/AdminUserDetailName'
import CardAdminAccount from '../components/CardAdminAccount'
import CardAdminTeam from '../components/CardAdminTeam'
import CardAdminSubscription from '../components/CardAdminSubscription'
import CardAdminPayments from '../components/CardAdminPayments'
import CardAdminAudienceNetwork from '../components/CardAdminAudienceNetwork'
import CardAdminCampaignReporting from '../components/CardAdminCampaignReporting'
import CardAdminAIAds from '../components/CardAdminAIAds'
import CardAdminIntegration from '../components/CardAdminIntegration'
import CardAdminNotes from '../components/CardAdminNotes'
import CardAdminDescription from '../components/CardAdminDescription'
import { useAdmin } from '../../shared/hooks/admin-hook'
import CardAdminAIAudienceModel from '../components/CardAdminAIAudienceModel'
import CardAdminDevZone from '../components/CardAdminDevZone'

const AdminUserDetail = () => {
  const dispatch = useDispatch()
  const { getOneSignUp, getOneCustomer } = useAdmin()
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const userId = pathname.split('/')[3]
  const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser) || false
  const accessDetails = useSelector(state => state.auth.user.accessDetails)
  const query = useMemo(() => new URLSearchParams(search), [search])
  const isSignUp = query.get('isSignUp') ?? false

  const [loading, setLoading] = useState(false)
  const { customers, signUps, teamInfo, lastCursorCustomers, panelFilters, signUpFilters } = useSelector(state => state.admin)
  const authToken = useSelector(state => state.auth.token)

  useEffect(() => {
    let users = isSignUp ? signUps : customers;

    const getUser = async () => {
      let foundCustomer = users?.find(c => c.id === userId)

      if (!foundCustomer) {
        setLoading(true)
        if (isSignUp) {
          const fetchedUser = await getOneSignUp({ userId })
          foundCustomer = fetchedUser
        } else {
          const fetchedUser = await getOneCustomer({ userId })
          foundCustomer = fetchedUser
        }

        if (!foundCustomer) {
          navigate('/')
          return
        } else {
          setLoading(false)
        }
      }

      dispatch(adminActions.setCustomer(foundCustomer))
    }

    getUser()
  }, [pathname])


  // Get the teamInfo data if it does not exist in the Redux store.
  useEffect(() => {
    if (!helper.isEmptyObject(teamInfo)) {
      return
    }

    const getTeamInfo = async () => {
      try {
        const data = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getTeamInfo`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + authToken,
          },
        )

        dispatch(adminActions.setTeamInfo(data))
      } catch (err) {
        toast.error(err?.message || "Something went wrong")
        throw err;
      }
    }

    getTeamInfo()
  }, [teamInfo])

  return (
    <AdminUserDetailLayout loading={loading}>
      <AdminUserDetailLayout.Header>
        <AdminUserDetailName />
      </AdminUserDetailLayout.Header>

      <AdminUserDetailLayout.Main>
        <CardAdminAccount isSignup={isSignUp} />
        <CardAdminTeam isSignup={isSignUp} />
        {<CardAdminSubscription isSignUp={isSignUp} />}
        {!isSignUp && <CardAdminPayments />}
        {<CardAdminAudienceNetwork />}
        {!isSignUp && <CardAdminAIAds />}
        {!isSignUp && <CardAdminCampaignReporting />}

        {isSpecialUser && accessDetails.permissions.includes("devZoneChanges") &&
          <React.Fragment>
            <CardAdminAIAudienceModel userId={userId} />
            <CardAdminDevZone userId={userId} />
          </React.Fragment>
        }
      </AdminUserDetailLayout.Main>

      <AdminUserDetailLayout.Side>
        {!isSignUp && <CardAdminIntegration />}
        <CardAdminNotes isSignup={isSignUp} />
        <CardAdminDescription />
      </AdminUserDetailLayout.Side>
    </AdminUserDetailLayout>
  )
}

export default AdminUserDetail
