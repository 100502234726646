import React, { useEffect, useState } from 'react'
import { Route, Navigate, Routes, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FaBell } from 'react-icons/fa'

// Homepage
import Home from './project/pages/Home'

// Admin/Developer Pages
import AdminPanel from './admin/pages/AdminPanel'
import AdminPanelTable from './admin/pages/AdminPanelTable'
import AdminPanelAnStatisticsTable from './admin/pages/AdminPanelAnStatisticsTable'
import AdminUserDetail from './admin/pages/AdminUserDetail'
import CRMReport from './admin/pages/CRMReport'
import TeamReport from './admin/pages/TeamReport'
import SupportTickets from './admin/pages/SupportTickets'

// Auth and Profile Pages
import Register from './user/pages/Register'
import Login from './user/pages/Login'
import ForgotPassword from './user/pages/ForgotPassword'
import ResetPassword from './user/pages/ResetPassword'
import SelectCategory from './user/pages/SelectCategory'
import SelectApparelCategory from './user/pages/SelectApparelCategory'
import AccountSettings from './user/pages/AccountSettings'
import PersonalInfo from './user/pages/PersonalInfo'
import Tickets from './user/pages/Tickets'
import TicketDetails from './user/pages/TicketDetails'

// Start Page
import Start from './user/pages/Start'

// Connect Pages
import VisitingDataOverview from './connect/pages/VisitingDataOverview'
import GTMConnect from './connect/pages/GTMConnect'
import JSConnect from './connect/pages/JSConnect'
import Connect from './connect/pages/Connect'
import ConnectFacebook from './connect/components/ConnectFacebook'
import ConversionIDBox from './connect/components/ConversionIDBox'
import ConnectStore from './connect/pages/ConnectStore'
import ConnectionConfig from './connect/pages/ConnectionConfig'
import CheckupConnectionConfig from './connect/pages/CheckupConnectionConfig'

// Audience Pages
import AudiencePage from './project/pages/AudiencePage'
import AudienceManager from './project/pages/AudienceManager'

// AI Ads Pages
import AIAds from './ai-ads/pages/AIAds'
import AIAudience from './ai-ads/pages/AIAudience'
import ReportDetails from './ai-ads/pages/ReportDetails'
import FullFunnelCampaigns from './ai-ads/pages/FullFunnelCampaigns'
import CreateCampaign from './ai-ads/pages/CreateCampaign'
import EnhencedCatalogEditor from './ai-ads/components/enhenced-catalog/Editor'
import ProductsSelection from './ai-ads/components/enhenced-catalog/ProductsSelection'

// Ad Health Checkup Pages
import AdHealthCheckup from './ad-health-checkup/pages/AdHealthCheckup'

// Subscription and Payment Pages
import Subscription from './user/pages/Subscription'
import UpdatePlan from './user/pages/UpdatePlan'
import Payment from './user/pages/Payment'
import StripePayment from './user/pages/StripePayment'
import PaymentCompleted from './user/pages/PaymentCompleted'
import CompleteRegistration from './user/pages/CompleteRegistration'
import ShopifyPaymentCompleted from './user/pages/ShopifyPaymentCompleted'
import Billing from './user/pages/Billing'
import UpdateCreditCard from './user/pages/UpdateCreditCard'

import HowToFindConversionID from './docs/pages/google-ads/HowToFindConversionID'

import { useCampaigns } from './shared/hooks/campaigns-hook'
import { useSubscriptionStatusHandler } from './shared/hooks/subscription-status-handler-hook'
import { useSubscriptionGuard } from './shared/hooks/subscription-guard-hook'
import { useUser } from './shared/hooks/user-hook'
import { useAuth } from './shared/hooks/auth-hook'
import { handleMainScrollbar } from './shared/util/uiPositioner'
import PrivateRoute from './shared/components/Navigation/PrivateRoute'
import Splash from './shared/components/UIElements/Splash'
import { useFacebookAuth } from './shared/hooks/facebook-auth-hook'
import { facebookActions } from './store/facebook'
import { useConnectionsHandler } from './shared/hooks/connections-handler-hook'
import { useAdminAuth } from './shared/hooks/admin-auth-hook'
import { useConstants } from './shared/hooks/constants-hook'
import { useConfig } from './shared/hooks/config-hook'
import OnboardingMeetingSuccess from './connect/pages/OnboardingMeetingSuccess'
import ProfilePageLayout from './user/components/ProfilePageLayout'
import InitialIndustrySelection from './user/pages/InitialIndustrySelection'
import InitialApparelCategorySelection from './user/pages/InitialApparelCategorySelection'
import constants from './constants'
import AdHealthCheckupMeetingSuccess from './ai-ads/pages/AdHealthCheckupMeetingSuccess'
import SupportMeetingSuccess from './ai-ads/pages/SupportMeetingSuccess'
import SpecialUsers from './admin/pages/SpecialUsers'
import DemoMeetings from './admin/pages/DemoMeetings'
import SignUps from './admin/pages/SignUps'
import { useAdmin } from './shared/hooks/admin-hook'
import { useSupportTickets } from './shared/hooks/support-tickets-hook'
import { useNotificationPopup } from './shared/hooks/notification-popup-hook'
import AlarmsCalendar from './admin/components/AlarmsCalendar'
import { toast } from 'react-toastify'
import { generalActions } from './store/general'

const App = () => {
  const _ = useCampaigns()
  const project = useSelector(state => state.project)
  const areConstantsReady = useSelector(state => state.admin.areConstantsReady)

  const campaigns = project.campaigns
  const campaignsFetched = project.campaignsFetched
  const { getProfile } = useUser()
  const [handledShopifyLoginOverwrite, setHandledShopifyLoginOverwrite] = useState(false)
  const { getTeamInfo, getTickets: fetchTicketsForAdmin } = useAdmin()
  const {
    fetchTickets: fetchTicketsForUser,
    hasUnreadMessages: userHasUnreadMessages,
  } = useSupportTickets()
  const { showPopup } = useNotificationPopup()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    handleSessionExpiration,
    auth,
    authTokenInStorage,
    authToken,
    authTokenExpirationDate,
    logout,
    emailInStorage,
  } = useAuth()

  useConfig()
  useConstants()

  const pageUrl = window.location.pathname
  const query = new URLSearchParams(window.location.search)
  const isComingFromShopifyLoginCallback = query.get("token") && pageUrl.includes("/login")

  const isLoginStateReady = auth.isLoginStateReady


  // We display an error toast and log out the user when the session has expired.
  useEffect(() => {
    handleSessionExpiration()
  }, [authToken, authTokenExpirationDate])

  // We fetch the logged-in user data at each page refresh.
  useEffect(() => {
    getProfile({})
  }, [])

  useEffect(() => {
    const checkShopifyLogin = async () => {
      if (authTokenInStorage && isComingFromShopifyLoginCallback && (authTokenInStorage !== query.get("token"))) {
        setHandledShopifyLoginOverwrite(true)
        const oldEmail = emailInStorage
        dispatch(generalActions.showLoggedOutFromAccountNotification({
          email: oldEmail,
          notificationText: `You have been logged out of ${oldEmail} account.`
        }))
        await logout();
      }
    };
    if (!handledShopifyLoginOverwrite) checkShopifyLogin();
  }, [authTokenInStorage, isComingFromShopifyLoginCallback]);

  useEffect(() => {
    if (authToken && auth.user.isSpecialUser) {
      getTeamInfo()
    }
  }, [authToken])

  // We control the 'scrolling' class on the 'main' element and handle the scrollbar.
  useEffect(() => {
    handleMainScrollbar(true)

    return () => {
      handleMainScrollbar(false)
    }
  }, [])

  // Getting facebook info for AI Ads
  const { fetchFacebookInfo } = useFacebookAuth()
  const fb = useSelector(state => state.facebook)

  // We fetch the user's support tickets.
  useEffect(() => {
    if (authToken && !auth.user.isSpecialUser) {
      fetchTicketsForUser()
    }
  }, [authToken])

  // We fetch the support tickets for the admin panel.
  useEffect(() => {
    if (authToken && auth.user.isSpecialUser) {
      fetchTicketsForAdmin({ freshFetch: false })
    }
  }, [authToken])

  // We display a notification popup if the user has unread messages in their support tickets.
  useEffect(() => {
    if (!userHasUnreadMessages) {
      return
    }

    const timer = setTimeout(() => {
      showPopup({
        content: (
          <div className="notification-popup-content">
            <FaBell className="icon" />
            <div className="texts">
              <div className="main-text">
                You have unread messages in your support tickets.
              </div>
              <div className="sub-text">
                Click here to view your support tickets.
              </div>
            </div>
          </div>
        ),
        callback: () => {
          navigate('/account/tickets?filter=unread_messages')
        },
      })
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [userHasUnreadMessages])

  useEffect(() => {
    if (authToken && !fb.fetching && !auth.user.isSpecialUser) {
      dispatch(facebookActions.fetching(true))
      fetchFacebookInfo()
    }
  }, [authToken])

  useConnectionsHandler()

  // We check the user's subscription and update it if necessary.
  useSubscriptionStatusHandler()

  // We prevent subscription required routes if the user does not have an active subscription.
  useSubscriptionGuard()

  useAdminAuth()

  const isAuthorized = auth.user && auth.user.isSpecialUser

  const shouldRenderCampaignRoutes =
    auth.user &&
    campaignsFetched &&
    (campaigns.length > 0 || isAuthorized) &&
    (isAuthorized ? areConstantsReady : true)

  const hasSelectedIndustry =
    //hiçbir şey seçmediyse
    auth.user?.userSelectedEnhencerCategories &&
    auth.user?.userSelectedEnhencerCategories.length && //bir şeyler seçmiş ama
    (!auth.user?.userSelectedEnhencerCategories.includes(
      'Apparel & Accessories',
    ) || // apparel seçmemiş
      (auth.user?.userSelectedEnhencerCategories.includes(
        'Apparel & Accessories',
      ) && // apparel seçmiş ve
        auth.user?.userSelectedEnhencerCategories.some(ai =>
          constants.apparelAccessoriesEnhencerCategories
            .map(e => e.name)
            .includes(ai),
        ))) //apparel seçmiş ve içinden bir şeyler seçmişse

  const isSubscribed = auth.user?.subscription?.status === 'active'

  return (
    <Routes>
      { isLoginStateReady ? (
      !authTokenInStorage ? (
        <React.Fragment>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!shouldRenderCampaignRoutes ? (
            <Route path="/" element={<Splash />} exact />
          ) : !auth.user?.completedRegistration && !auth.user.isSpecialUser ? (
            <React.Fragment>
              <Route
                path="/complete-registration"
                element={<CompleteRegistration />}
              />
              <Route
                path="*"
                element={<Navigate replace to="/complete-registration" />}
              />
            </React.Fragment>
          ) : !hasSelectedIndustry && !auth.user.isSpecialUser ? (
            <React.Fragment>
              <Route
                path="/industry-selection"
                element={<InitialIndustrySelection />}
              />
              <Route
                path="/industry-selection/apparel"
                element={<InitialApparelCategorySelection />}
              />
              <Route
                path="*"
                element={<Navigate replace to="/industry-selection" />}
              />
            </React.Fragment>
          ) : !isSubscribed && !auth.user.isSpecialUser ? (
            <React.Fragment>
              <Route path="/start" element={<Start />} />
              <Route
                path="/checkup/config"
                element={<CheckupConnectionConfig />}
              />
              <Route
                path="/ad-health-checkup/*"
                element={<AdHealthCheckup />}
              />
              <Route path="/subscription" element={<Subscription />} />
              <Route
                path="/subscription/update-plan"
                element={<UpdatePlan />}
              />
              <Route path="/subscription/billing" element={<Billing />} />
              <Route path="/subscription/payment" element={<Payment />} />
              <Route
                path="/subscription/stripePayment"
                element={<StripePayment />}
              />
              <Route
                path="/subscription/payment/update-card"
                element={<UpdateCreditCard />}
              />
              <Route path="/payment-completed" element={<PaymentCompleted />} />
              <Route
                path="/shopify-payment-completed"
                element={<ShopifyPaymentCompleted />}
              />
              <Route path="/account/info" element={<PersonalInfo />} />
              <Route
                path="/account/tickets"
                element={
                  <PrivateRoute>
                    <Tickets />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/tickets/:ticketId"
                element={
                  <PrivateRoute>
                    <TicketDetails />
                  </PrivateRoute>
                }
                exact
              />
              <Route path="/account/subscription" element={<Subscription />} />
              <Route path="/account/settings" element={<AccountSettings />} />
              <Route
                path="/account/industry-selection"
                element={<SelectCategory inAccountSettings={true} />}
              />
              <Route
                path="/account/industry-selection/apparel"
                element={<SelectApparelCategory inAccountSettings={true} />}
              />
              <Route path="*" element={<Navigate replace to="/start" />} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Route
                path="/ads-manager/:campaignId"
                element={
                  <PrivateRoute>
                    <AudiencePage />
                  </PrivateRoute>
                }
                exact
              >
                <Route
                  path="audience"
                  element={
                    <PrivateRoute>
                      <AudienceManager />
                    </PrivateRoute>
                  }
                />
              </Route>

              <Route path="/home" element={<Home />} exact />

              <Route
                path="/ai-ads"
                element={
                  <PrivateRoute>
                    <AIAds />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/ai-ads/report-details"
                element={
                  <PrivateRoute>
                    <ReportDetails />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/ai-ads/ai-audience"
                element={
                  <PrivateRoute>
                    <AIAudience />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/ai-ads/full-funnel-campaigns"
                element={
                  <PrivateRoute>
                    <FullFunnelCampaigns />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/ai-ads/full-funnel-campaigns/create"
                element={
                  <PrivateRoute>
                    <CreateCampaign />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="/ai-ads/enhenced-catalog/editor"
                element={
                  <PrivateRoute>
                    <EnhencedCatalogEditor />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/ai-ads/enhenced-catalog/products-selection"
                element={
                  <PrivateRoute>
                    <ProductsSelection />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="/ad-health-checkup/*"
                element={
                  <PrivateRoute>
                    <AdHealthCheckup />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin-panel"
                element={
                  <PrivateRoute permissions={["viewCustomersPage"]}>
                    <AdminPanel />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/table"
                element={
                  <PrivateRoute permissions={["viewTablePage"]}>
                    <AdminPanelTable />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/antable"
                element={
                  <PrivateRoute permissions={["viewAnTablePage"]}>
                    <AdminPanelAnStatisticsTable />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/visiting-data-overview"
                element={
                  <PrivateRoute permissions={["viewCustomersPage"]}>
                    <VisitingDataOverview />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/user-detail/:id"
                element={
                  <PrivateRoute permissions={["viewAdminUserDetailPage"]}>
                    <AdminUserDetail />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/alarms"
                element={
                  <PrivateRoute permissions={["viewAlarmsPage"]}>
                    <AlarmsCalendar />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/support-tickets"
                element={
                  <PrivateRoute permissions={["viewSupportTicketsPage"]}>
                    <SupportTickets />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/team"
                element={
                  <PrivateRoute permissions={["viewTeamPage"]}>
                    <SpecialUsers />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/demo-meetings"
                element={
                  <PrivateRoute permissions={["viewDemoMeetingsPage"]}>
                    <DemoMeetings />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin-panel/sign-ups"
                element={
                  <PrivateRoute permissions={["viewSignUpsPage"]}>
                    <SignUps />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/crm-report"
                element={
                  <PrivateRoute permissions={["viewCRMReportsPage"]}>
                    <CRMReport />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/team-report"
                element={
                  <PrivateRoute permissions={["viewTeamReportsPage"]}>
                    <TeamReport />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/complete-registration"
                element={
                  <PrivateRoute>
                    <CompleteRegistration />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/industry-selection"
                element={
                  <PrivateRoute>
                    <InitialIndustrySelection />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/industry-selection/apparel"
                element={
                  <PrivateRoute>
                    <InitialApparelCategorySelection />
                  </PrivateRoute>
                }
                exact
              />


              <Route
                path="/config"
                element={
                  <PrivateRoute>
                    <ConnectionConfig />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="/checkup/config"
                element={
                  <PrivateRoute>
                    <CheckupConnectionConfig />
                  </PrivateRoute>
                }
                exact
              />


              <Route
                // path="/book-a-meeting-success"
                path="/support-meeting-success"
                element={
                  <PrivateRoute>
                    <SupportMeetingSuccess />
                  </PrivateRoute>
                }
                exact
              />


              <Route
                // path="/book-a-meeting-success"
                path="/onboarding-meeting-success"
                element={
                  <PrivateRoute>
                    <OnboardingMeetingSuccess />
                  </PrivateRoute>
                }
                exact
              />


              <Route
                path="/ad-health-checkup-meeting-success"
                element={
                  <PrivateRoute>
                    <AdHealthCheckupMeetingSuccess />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="/connect/"
                element={
                  <PrivateRoute>
                    <Connect />
                  </PrivateRoute>
                }
                exact
              >
                <Route
                  path="store"
                  element={
                    <PrivateRoute>
                      <ConnectStore />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="google-conversion-setup"
                  element={
                    <PrivateRoute>
                      <ConversionIDBox />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="facebook"
                  element={
                    <PrivateRoute>
                      <ConnectFacebook />
                    </PrivateRoute>
                  }
                />
              </Route>

              <Route
                path="/connect"
                element={
                  <PrivateRoute>
                    <Connect />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/connect/google-tag-manager"
                element={
                  <PrivateRoute>
                    <GTMConnect />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/connect/javascript-sdk"
                element={
                  <PrivateRoute>
                    <JSConnect />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/info"
                element={
                  <PrivateRoute>
                    <PersonalInfo />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/settings"
                element={
                  <PrivateRoute>
                    <AccountSettings />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/subscription"
                element={
                  <PrivateRoute>
                    <Subscription />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/industry-selection"
                element={
                  <PrivateRoute>
                    <ProfilePageLayout>
                      <SelectCategory inAccountSettings={true} />
                    </ProfilePageLayout>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/industry-selection/apparel"
                element={
                  <PrivateRoute>
                    <ProfilePageLayout>
                      <SelectApparelCategory inAccountSettings={true} />
                    </ProfilePageLayout>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/tickets"
                element={
                  <PrivateRoute>
                    <Tickets />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/account/tickets/:ticketId"
                element={
                  <PrivateRoute>
                    <TicketDetails />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/subscription"
                element={
                  <PrivateRoute>
                    <Subscription />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/subscription/update-plan"
                element={
                  <PrivateRoute>
                    <UpdatePlan />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/subscription/billing"
                element={
                  <PrivateRoute>
                    <Billing />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/subscription/payment"
                element={
                  <PrivateRoute>
                    <Payment />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/subscription/stripePayment"
                element={
                  <PrivateRoute>
                    <StripePayment />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/subscription/payment/update-card"
                element={
                  <PrivateRoute>
                    <UpdateCreditCard />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/payment-completed"
                element={
                  <PrivateRoute>
                    <PaymentCompleted />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/shopify-payment-completed"
                element={
                  <PrivateRoute>
                    <ShopifyPaymentCompleted />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="/how-to-find-google-ads-conversion-id"
                element={
                  <PrivateRoute>
                    <HowToFindConversionID />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="/industry-category"
                element={
                  <PrivateRoute>
                    <SelectCategory />
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/industry-category/apparel"
                element={
                  <PrivateRoute>
                    <SelectApparelCategory />
                  </PrivateRoute>
                }
                exact
              />

              <Route
                path="*"
                element={
                  auth.user.accessDetails.role === "Investor" ? <Navigate to="/crm-report" /> :
                    auth.user.isSpecialUser ?
                      <Navigate to="admin-panel" />
                      : !auth.user.completedRegistration ?
                        <Navigate to="/complete-registration" />
                        : !auth.user?.userSelectedEnhencerCategories ||
                          !auth.user?.userSelectedEnhencerCategories.length ?
                          <Navigate to="/industry-selection" />
                          :
                          <Navigate to={'/ai-ads'} />

                }
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )
    ): null }
    </Routes>
  )
}

export default App
