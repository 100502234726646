import React from "react";
import './InitialApparelCategorySelection.css'
import SelectApparelCategory from "./SelectApparelCategory";

const InitialApparelCategorySelection = props => {
    return (
        <div className="initial-industry-container">
            <SelectApparelCategory inAccountSettings={false} />
        </div>
    );
}

export default InitialApparelCategorySelection