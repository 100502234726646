import React from 'react'
import TutorialSlider from './TutorialSlider'
import SuccessStoriesSlider from './SuccessStoriesSlider'
import RecommendedShopifyAppsSlider from './RecommendedShopifyAppsSlider'

const SidebarContent = () => {
  return (
    <React.Fragment>
      <TutorialSlider />
      <SuccessStoriesSlider />
      <RecommendedShopifyAppsSlider />
    </React.Fragment>
  )
}

export default SidebarContent
