import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaLock } from 'react-icons/fa';
import { toast } from "react-toastify";

import { useHttpClient } from "../../shared/hooks/http-hook";
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png';
import Button from "../../shared/components/FormElements/Button";

import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = props => {
    const query = new URLSearchParams(window.location.search);

    const token = query.get("token");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordUpdatedComplete, setPasswordUpdatedComplete] = useState(false);
    const { sendRequest } = useHttpClient();

    const onSubmit = async event => {
        event.preventDefault();
        
        if (password === "") { 
            toast.error("Password is required");
        } else if (password.length < 6) {
            toast.error("Password must be at least 6 characters");
        } else if (password.length > 30) {
            toast.error("Password must be maximum 30 characters");
        } else if (password !== password2) {
            toast.error("Passwords do not match");
        } else {
            const userData = {
                token: token,
                password: password,
                password2: password2
            };
            
            sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/resetPassword`,
                "POST",
                JSON.stringify(userData),
                {
                    "Content-Type": "application/json",
                }

                    );
            setPasswordUpdatedComplete(true);
        }
    };

    return (
        <React.Fragment>
            <div className="full-container">
                <div className="login-cont">
                    <div style={{ display: 'flex' }}>
                        <img className="logo" src={enhencerLogo} alt="Logo"/>
                    </div>
                    <p>Enter your new password.</p>
                    <form onSubmit={onSubmit}>
                        <div className="input-cont">
                            <div className="input-field">
                                <FaLock className="icon" />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                            </div>
                            <div className="input-field">
                                <FaLock className="icon" />
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    name="password2"
                                    value={password2}
                                    onChange={(event) => setPassword2(event.target.value)}
                                />
                            </div>
                        </div>
                        <div className="cont">
                            <Button className="action-button" type="submit">
                                Reset Password
                            </Button>
                        </div>
                    </form>
                    {passwordUpdatedComplete ? (
                        <div className="input-cont">
                            <p>Password updated successfully. <Link to="/login">Login</Link></p>
                        </div>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {}
)(ResetPassword);