import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

import { useHttpClient } from './http-hook'
import helper from '../util/helper'
import { adminActions } from '../../store/admin'
import { useFacebookAuth } from './facebook-auth-hook';
import { useAdmin } from './admin-hook';

export const useAdminAuth = () => {

  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { fetchFacebookInfo } = useFacebookAuth();
  const { endViewingAsUser } = useAdmin();

  const adminUser = useSelector(state => state.auth.user)
  const authToken = useSelector(state => state.auth.token)
  const admin = useSelector(state => state.admin)
  const project = useSelector(state => state.project)

  const authHeader = {
    Authorization: "Bearer " + authToken,
    "Content-Type": "application/json",
  }

  const facebook = useSelector(state => state.facebook)

  useEffect(() => {
    if (adminUser?.isSpecialUser) {
      checkUrl()
    }
  }, [location.pathname, adminUser])

  const checkUrl = async () => {
    let params = new URLSearchParams(location.search)
    const routeName = location.pathname.replace("/", "")
    const userInViewExists = admin.userInView && Object.keys(admin.userInView).length

    const isAdmin = params.get("admin");
    const userInViewId = params.get("user_in_view_id");
    const userInViewName = params.get("user_in_view_name");

    // if the user info doesn't exist or it belongs to an old user
    if (isAdmin && userInViewId && (!userInViewExists || admin.userInView.id !== userInViewId)) {
      await fetchFacebookInfo(true, userInViewId);

      let user = await fetchUser({ userId: userInViewId })

      dispatch(adminActions.setUserInView({
        ...user,
        connectionsCompleted: true,
        displayName: userInViewName
      }));
    }

    if (routeName.includes("admin-panel") && !routeName.includes("user-detail")) {
      endViewingAsUser()
    }
  }

  const fetchUser = async ({ userId }) => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/admin/getUser/${userId}`, "GET", null, authHeader);
      let user = responseData.user
      user.isFromShopify = !!user.shopify
      user.domain = user.shopify ? user.shopify.domain : user.url
      return user
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
      throw err
    }
  }
  /* const getCampaignDetails = async ({ userId }) => {
    if (!project.currentCampaign) {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${userId}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + auth.token,
          },
        )
        dispatch(
          projectActions.setAdminUserUsersCampaigns({
            campaigns: responseData.campaigns,
          }),
        )
        navigate(`/ads-manager/${responseData.campaigns[0].id}/audience`)
      } catch (err) {
        toast.error(err?.message || "Something went wrong")
      }

      let body = JSON.stringify({
        userId,
        campaignId,
      });

      const url = `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaignDetails/`;

      const responseData = await sendRequest(url, 'POST', body, authHeader)

      dispatch(
        projectActions.setCurrentCampaign({
          campaign: {
            ...responseData.campaign,
            isDemo: user.isDemo,
          },
          isDemo: user.isDemo,
        }),
      )
    }

  } */

  const getFacebookInfo = () => {

  }

  return {
  }
}
