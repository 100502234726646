import React, { useEffect, useState } from "react";
import { FaCalendarCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import "./OnboardingMeetingSuccess.css"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import { useUser } from "../../shared/hooks/user-hook";

const OnboardingMeetingSuccess = () => {

  const user = useSelector(state => state.auth.user);
  const { setBookedOnboardingMeeting } = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.search !== "") {
      const query = new URLSearchParams(window.location.search);

      //if user already set user.crmDetails.bookedOnboardingMeeting=true, dont take any action again
      if (user?.crmDetails?.bookedOnboardingMeeting) {
        return;
      }

      const generateMeetingTime = (starts, ends) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const getDoubleString = (s) => {
          return (s / 10 < 1 ? "0" : "") + s.toString()
        }
        let offset = starts.getTimezoneOffset()
        let offsetMagnitude = offset > 0 ? offset : (offset * -1)

        return {
          time: getDoubleString(starts.getHours()) + ":" + getDoubleString(starts.getMinutes()) + " - " + getDoubleString(ends.getHours()) + ":" + getDoubleString(ends.getMinutes()) + ", " + dayNames[starts.getDay()] + ", " + monthNames[starts.getMonth()] + " " + starts.getDate() + ", " + starts.getFullYear(),
          timeZone: "GMT" + (offset > 0 ? "-" : "+") + getDoubleString((offsetMagnitude - (offsetMagnitude % 60)) / 60) + ":" + getDoubleString(offsetMagnitude % 60)
        }
      }

      let starts = new Date(query.get("event_start_time"))
      let ends = new Date(query.get("event_end_time"))
      let fullName = query.get("invitee_full_name")
      let result = generateMeetingTime(starts, ends)
      let meetingTime = result.time
      let timeZone = result.timeZone
      let email = query.get("invitee_email")
      let userMeetingData = {
        companyName: query.get("answer_1"),
        contactNumber: query.get("answer_2"),
        email: email,
        name: fullName,
        meetingTime: meetingTime + " " + timeZone,
      }

      if (userMeetingData) {
        setBookedOnboardingMeeting({ userMeetingData: userMeetingData })
      }
    }
  }, [location])

  const onClick = () => {
    navigate(`/config`)
  }


  return <div className="config-page">
    <br />
    <FaCalendarCheck fontSize={30} />

    <h2>Onboarding meeting scheduled successfully!</h2>

    <span>{user?.name}, you're halfway there! </span>
    <span>Completing the sign-up process only takes a few more minutes.</span>
    <br />
    <br />
    <Button
      className="plan-button continue-flow-button"
      onClick={onClick}
    >
      Continue
    </Button>
  </div>
}

export default OnboardingMeetingSuccess;
