import React, { useEffect } from 'react'
import gsap from 'gsap'

import creamImage from '../../shared/assets/images//ad-health-checkup/cream.png'
import watchImage from '../../shared/assets/images//ad-health-checkup/watch.png'
import juiceImage from '../../shared/assets/images//ad-health-checkup/juice.png'
import bottlesImage from '../../shared/assets/images//ad-health-checkup/bottles.png'
import parfumImage from '../../shared/assets/images//ad-health-checkup/parfum.png'

import './FunPart.css'

const FunPart = () => {
  useEffect(() => {
    const tl = gsap.timeline()

    tl.from('.second-big-text.first.text', {
      x: -100,
      opacity: 0,
      duration: 0.8,
    })
      .from(
        '.second-big-text.second.text',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )
      .from(
        ['.image-1 img', '.image-3 img', '.image-5 img'],
        {
          y: -100,
          opacity: 0,
          duration: 0.8,
          stagger: 0, // this will make the animations run at same time
        },
        '-=0.5',
      )
      .from(
        ['.image-2 img', '.image-4 img'],
        {
          y: 100,
          opacity: 0,
          duration: 0.8,
          stagger: 0,
        },
        '-=0.5',
      )
      .to('.image-1 img', {
        scale: 2,
        x: '-100%',
        y: '-100%',
        opacity: 1,
        duration: 1,
      })
      .to(
        '.image-2 img',
        {
          scale: 2,
          x: '60%',
          y: '40%',
          opacity: 1,
          duration: 1,
        },
        '-=1',
      )
      .to('.white-big-text', {
        opacity: 1,
        duration: 2,
      })
  }, [])

  return (
    <div className="top-ads-content">
      <div className="opening-text-first">
        <div className="second-big-text first text">
          Moving on... <span className="gradient-text">to the fun part</span>
        </div>
        <div className="second-big-text second text">let’s discover your</div>
        <div className="images-row">
          <div className="images">
            <div className="image-1">
              {' '}
              <img src={creamImage} />
            </div>
            <div className="image-2">
              {' '}
              <img src={watchImage} />
            </div>
            <div className="image-3">
              <img src={juiceImage} />
            </div>
            <div className="image-4">
              <img src={bottlesImage} />
            </div>
            <div className="image-5">
              <img src={parfumImage} />
            </div>
          </div>

          <div className="white-big-text">
            Top Ad Sets
            <br /> <span>by ROAS</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FunPart
