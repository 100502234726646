import React from 'react'
import { useModal } from '../../hooks/modal-hook'
import Button from '../FormElements/Button'
import { FaCheck, FaTimes } from 'react-icons/fa'

import './PredefinedAlarmsModal.css'

const PredefinedAlarmsModal = props => {
  const { closeModal } = useModal()

  return (
    <div className="alarm-modal edit-alarm-modal .predefined-alarms-modal">
      <div className="modal-title">
        <div className="alarm-name">Select from Predefined Alarms</div>
        <Button size="small" onClick={closeModal}>
          <FaTimes />
        </Button>
      </div>
      <div className="modal-content">
        {Object.keys(props.data.alarms).map((key, index) => {
          const alarm = props.data.alarms[key]
          return (
            <div key={index} className="predefined-alarm-box" onClick={() => {
              props.data.onSelect(alarm)
              closeModal()
            }}>
              <div className="alarm-title">{alarm.title}</div>
              {alarm.nextAlarmTitle ? <div className="alarm-due-date">{'When completed: "' + alarm.nextAlarmTitle + '" alarm will be created'}</div> : null}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PredefinedAlarmsModal
