import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './AdminCustomerTabs.css'
import PrivateComponent from '../../shared/components/UIElements/PrivateComponent'

const AdminCustomerTabs = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(2)

  const handleTabClick = tabIndex => {
    switch (tabIndex) {
      case 3:
        navigate('/admin-panel/alarms')
        break

      case 0:
        navigate('/admin-panel/demo-meetings')
        break

      case 1:
        navigate('/admin-panel/sign-ups')
        break

      case 2:
        navigate('/admin-panel')
        break

      default:
        navigate('/admin-panel')
        break
    }
  }
  const checkIfActive = () => {
    if (window.location.pathname === '/admin-panel/alarms') {
      return 3
    }
    if (window.location.pathname === '/admin-panel') {
      return 2
    }

    if (window.location.pathname === '/admin-panel/sign-ups') {
      return 1
    }

    if (window.location.pathname === '/admin-panel/demo-meetings') {
      return 0
    }

    return -1
  }

  useEffect(() => {
    setActiveTab(checkIfActive())
  }, [window.location.pathname])

  return (
    <div>
      <div className="tab-buttons">
        <button
          className={activeTab === 3 ? 'active' : ''}
          onClick={() => handleTabClick(3)}
        >
          Alarms <span>⏰</span>
        </button>
        <span>|</span>

        <PrivateComponent permissions={['viewDemoMeetingsPage']}
        children={ <button
          className={activeTab === 0 ? 'active' : ''}
          onClick={() => handleTabClick(0)}
        >
          <span>Demo </span>
          Meetings
        </button>} /> 
        <PrivateComponent permissions={['viewSignUpsPage']}
        children={   <button
          className={activeTab === 1 ? 'active' : ''}
          onClick={() => handleTabClick(1)}
        >
          Sign Ups
        </button>} />
        <PrivateComponent permissions={['viewCustomersPage']}
        children={   <button
          className={activeTab === 2 ? 'active' : ''}
          onClick={() => handleTabClick(2)}
        >
          Customers
        </button>} />     
      </div>
    </div>
  )
}

export default AdminCustomerTabs
