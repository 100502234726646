import React, { useEffect, useRef, useState } from 'react'
import { useFacebook } from '../../shared/hooks/facebook-hook'
import { Link } from 'react-router-dom'
import "./AdCreativeMaker.css"
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import helper from '../../shared/util/helper'
import CustomDropdown from './CustomDropdown'
import ProductSetDropdownHover from './ProductSetDropdownHover'
import AdCreativesList from "./AdCreativesList"
import AdCreativeTypeSelectionTabs from "./AdCreativeTypeSelectionTabs"
import { useSelector } from 'react-redux'
import Button from '../../shared/components/FormElements/Button'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'
import { FaCheck, FaPlus, FaTrash, FaUndo } from 'react-icons/fa'
import LoadingDotsSpinner from '../../shared/components/UIElements/LoadingDotsSpinner'
import CreativeTypes from '../../shared/enums/CreativeTypes'
import Tooltip from '../../shared/components/UIElements/Tooltip'


const EditingStates = Object.freeze({
    "NOT_SELECTED_TYPE": 0,
    "NEW_CREATIVE_WITH_TYPE": 1,
    "EXISTING_CREATIVE": 2
})

const AdCreativeMaker = ({
    existingCampaignCreativeType,
}) => {

    const { getCatalogs } = useFacebook()
    const { newCatalogSelected,
        newProductSetSelected,
        newFacebookPageSelected,
        newInstagramAccountSelected,
        catalogFormTextFieldsUpdated,
        startLoadingState,
        endLoadingState,

        addCurrentCreativeToList,
        editCurrentCreativeInList,
        undoCreativeChanges,
        removeCurrentCreativeFromList,
        checkIfCurrentCreativeIsInList
    } = useFacebookCampaignEditor()

    const facebook = useSelector(state => state.facebook)
    const user = useSelector(state => state.auth.user)

    const [textupdateTimer, setTextUpdateTimer] = useState()
    const [editingState, setEditingState] = useState(EditingStates.NOT_SELECTED_TYPE)

    const [primaryText, setPrimaryText] = useState()
    const [websiteUrl, setWebsiteUrl] = useState()

    const [displayMissingFieldErrors, setDisplayMissingFieldErrors] = useState(false)

    const { details, type, name, isValid, isEdited } = useSelector(state => state.facebookCampaignEditor.currentCampaign?.currentCreative) ?? {}
    const { catalog, productSet, facebookPage, instagramAccount } = details ?? {}

    const { isFetchingCatalogs, isFetchingFacebookPages, isProcessingTextFields } = useSelector(state => state.facebookCampaignEditor.loadings)

    /* useEffect(() => {
        if (type === CreativeTypes.CATALOG) {
            console.log("hello use effect", "user url: ", user.url, "details : ", details)


            console.log("final text ", details)
            setWebsiteUrl(details?.websiteUrl || (!user.url.includes('https://')
                ? `https://${user.url}`
                : user.url))
            setPrimaryText(details?.primaryText)
        }

    }, [details?.primaryText, details?.websiteUrl]) */

    useEffect(() => {
        if (type === CreativeTypes.CATALOG) {
            setDisplayMissingFieldErrors(false)
            setTimeout(() => {
                setDisplayMissingFieldErrors(true)
            }, 7000);

            console.log("hello use effect", "user url: ", user.url, "details : ", details)


            console.log("final text ", details)
            setWebsiteUrl(details?.websiteUrl || (!user.url.includes('https://')
                ? `https://${user.url}`
                : user.url))
            setPrimaryText(details?.primaryText)
        }

        if (type) {
            if (checkIfCurrentCreativeIsInList()) {
                setEditingState(EditingStates.EXISTING_CREATIVE)
            } else {
                setEditingState(EditingStates.NEW_CREATIVE_WITH_TYPE)
            }
        } else {
            setEditingState(EditingStates.NOT_SELECTED_TYPE)
        }

    }, [type, name])



    const onPrimaryTextChange = (event) => {
        setPrimaryText(event.target.value)
    }

    const onWebsiteUrlChange = (event) => {
        setWebsiteUrl(event.target.value)
    }

    useEffect(() => {
        if (type === CreativeTypes.CATALOG) {
            if (textupdateTimer) {
                clearTimeout(textupdateTimer)
            }
            if (primaryText
                && websiteUrl
                && (primaryText !== details.primaryText
                    || websiteUrl !== details.websiteUrl)
            ) {
                startLoadingState("isProcessingTextFields")
                let timer = setTimeout(() => {
                    catalogFormTextFieldsUpdated({
                        primaryText: primaryText,
                        websiteUrl: websiteUrl
                    })
                    endLoadingState("isProcessingTextFields")
                }, 3000);
                setTextUpdateTimer(timer)
            }

            return () => { clearTimeout(textupdateTimer) }
        }

    }, [primaryText, websiteUrl])


    const fetchCatalogsForAdmin = () => {
        if (user.isSpecialUser) {
            getCatalogs({
                businessId: facebook.selectedBusiness.id,
                accessToken: facebook.auth.accessToken,
            })
        }
    }

    return <div className='creative-maker-form'>
        <div className='row head'>
            <h4>{name ?? "New Creative"}</h4>
            {
                isProcessingTextFields ? <LoadingDotsSpinner /> :
                    editingState === EditingStates.NEW_CREATIVE_WITH_TYPE ?
                        (
                            <Tooltip isHidden={isValid} content="Fill out all fields.">
                                <div className={`creative-action-button${isValid ? '' : ' disabled'}`} onClick={() => { if (isValid) { addCurrentCreativeToList() } }}>
                                    <FaCheck className='icon' />
                                    <span>Save</span>
                                </div>
                            </Tooltip>
                        )
                        :
                        editingState === EditingStates.EXISTING_CREATIVE ?
                            <div className='actions-row'>
                                <div className='creative-action-button remove' onClick={removeCurrentCreativeFromList}>
                                    <FaTrash className='icon' />
                                    <span>Remove</span>
                                </div>
                                {
                                    isEdited ? <React.Fragment>
                                        <div className={`creative-action-button remove${isValid ? '' : ' disabled'}`} onClick={() => { if (isValid) { undoCreativeChanges() } }}>
                                            <FaUndo className='icon' />
                                            <span>Undo Changes</span>
                                        </div>
                                        <div className={`creative-action-button${isValid ? '' : ' disabled'}`} onClick={() => { if (isValid) { editCurrentCreativeInList() } }}>
                                            <FaCheck className='icon' />
                                            <span>Update</span>
                                        </div>
                                    </React.Fragment> : null
                                }
                            </div>
                            : null

            }
        </div>

        {
            existingCampaignCreativeType ? null :
                <AdCreativeTypeSelectionTabs hide={editingState === EditingStates.EXISTING_CREATIVE} />
        }

        {type === "Craft from Catalog" ?
            <React.Fragment>
                <div className="row input-container-row">
                    <label>Catalog:</label>
                    {isFetchingCatalogs ? <InlineLoadingSpinner size="xs" message="Fetching catalogs"></InlineLoadingSpinner>
                        :
                        <>
                            {facebook.catalogs.length ?
                                <CustomDropdown
                                    placeholder="Select catalog..."
                                    initialValue={catalog}
                                    forcedValue={catalog}
                                    options={facebook.catalogs}
                                    onChange={c => { newCatalogSelected({ catalog: c }) }}
                                    onHover={(edgeId, product_count) => (
                                        <ProductSetDropdownHover edgeId={edgeId} productCount={product_count} />
                                    )}
                                />
                                :
                                <p className="error-text dropdown">
                                    No catalog found. Please try selecting{' '}
                                    <Link to="/config">another ad account</Link> or visit{' '}
                                    <a
                                        href={`https://business.facebook.com/products/catalogs/new?business_id=${facebook?.selectedBusiness?.id}`}
                                        target="_blank"
                                    >
                                        Meta Ecommerce Manager
                                    </a>{' '}
                                    to create a new one.
                                </p>
                            }
                            {user.isSpecialUser ? <Button onClick={fetchCatalogsForAdmin} className="admin-spec-button">Fetch Catalogs (A)</Button> : null}
                        </>
                    }
                </div>

                <div className="row input-container-row">
                    <label>Product Set:</label>
                    {catalog ?

                        facebook.productSets[catalog?.id] && facebook.productSets[catalog?.id].length ? (
                            <CustomDropdown
                                placeholder="Select product set..."
                                initialValue={productSet}
                                options={facebook.productSets[catalog.id]}
                                onChange={(p) => { newProductSetSelected({ productSet: p }) }}
                                forcedValue={productSet}
                                onHover={(edgeId, product_count) => (
                                    <ProductSetDropdownHover edgeId={edgeId} productCount={product_count} />
                                )}
                            />
                        ) : (
                            <p className="error-text dropdown">
                                No products set found. Please try selecting another catalog
                                or visit{' '}
                                <a
                                    href={`https://business.facebook.com/commerce/catalogs/${catalog?.id}/sets?business_id=${facebook.selectedBusiness?.id}&layer=createProductSet&createSetMethod=Static&prefillGlobalLogicOperator=or&originLocation=SETS_PAGE`}
                                    target="_blank"
                                >
                                    Meta Ecommerce Manager
                                </a>{' '}
                                to create a new one.
                            </p>
                        )

                        :
                        <p className="error-text dropdown">
                            Please select a catalog
                        </p>


                    }
                </div>

                <div className="row input-container-row">
                    <label>Facebook Page:</label>

                    {
                        isFetchingFacebookPages ? <InlineLoadingSpinner size="xs" message="Fetching pages"></InlineLoadingSpinner>
                            :
                            facebook.pages.length ? (
                                <CustomDropdown
                                    placeholder="Select page..."
                                    initialValue={facebookPage}
                                    onChange={p => newFacebookPageSelected({ facebookPage: p })}
                                    options={facebook.pages}
                                />
                            ) : (
                                <p className="error-text dropdown">
                                    No page found. Please try selecting{' '}
                                    <Link to="/config?io=3">another ad account</Link> or visit{' '}
                                    <a
                                        href={`https://business.facebook.com/settings/pages?business_id=${facebook?.selectedBusiness?.id}`}
                                        target="_blank"
                                    >
                                        Meta Business Settings
                                    </a>{' '}
                                    to create a page.
                                </p>
                            )}

                </div>


                <div className="row input-container-row">
                    <label>
                        Instagram <br /> Account:
                    </label>

                    {facebookPage ?
                        facebook.instagramAccounts[facebookPage?.id] && facebook.instagramAccounts[facebookPage?.id].length ? (
                            <CustomDropdown
                                placeholder="Select account..."
                                initialValue={instagramAccount}
                                forcedValue={instagramAccount}
                                onChange={i => newInstagramAccountSelected({ instagramAccount: i })}
                                options={facebook.instagramAccounts[facebookPage?.id]}
                            />
                        ) : (
                            <p className="error-text dropdown">
                                No connected Instagram account found. Without it, your
                                campaign might not be working properly.
                            </p>
                        )
                        :
                        <p className="error-text dropdown">
                            Please select a Facebook page
                        </p>

                    }
                </div>



                <div className="row input-container-row">
                    <label className="label">Primary text:</label>
                    <div className='form-control'>

                        <textarea
                            id=""
                            placeholder="Unlock the Future with Our Innovative Solution!"
                            value={primaryText}
                            onChange={onPrimaryTextChange}
                        ></textarea>
                    </div>
                </div>

                {!primaryText && displayMissingFieldErrors && (
                    <div className="error row">
                        <label>&nbsp;</label>
                        <p className="error-text">Please enter a primary text</p>
                    </div>
                )}

                <div className="row input-container-row">
                    <label>Website URL:</label>
                    <div className='form-control'>

                        <input
                            type="text"
                            placeholder="https://yourwebsite.com"
                            value={websiteUrl}
                            onChange={onWebsiteUrlChange}
                        />
                    </div>

                </div>

                {!websiteUrl && displayMissingFieldErrors && (
                    <div className="error row">
                        <label>&nbsp;</label>
                        <p className="error-text">Please enter a redirection page</p>
                    </div>
                )}
            </React.Fragment>
            :
            type === CreativeTypes.HIGH_PERFORMING ?
                <AdCreativesList />
                :
                type === CreativeTypes.ENHENCED_CATALOG ?
                    <Link to="/ai-ads/enhenced-catalog/editor">
                        Create new
                    </Link> : null

        }
    </div>

}

export default AdCreativeMaker;