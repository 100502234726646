import React from "react";

import './ProgressBar.css'

const ProgressBar = props => {
    return <div className="progress-bar" style={{height: `${props.height}px`}}>
        <div className="progress" style={{height: `${props.height - 2}px`, width: "calc(" + props.percentage +"% - 4px)"}}></div>
    </div>
}

export default ProgressBar;