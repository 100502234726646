import React, { useEffect, useState } from 'react'

import Button from '../FormElements/Button'
import Table from '../UIElements/Table'
import { useModal } from '../../hooks/modal-hook'

import './ModelLogs.css'
import 'react-toastify/dist/ReactToastify.css'

const ModelLogs = props => {
  const { closeModal } = useModal()
  const [tableLogs, setTableLogs] = useState()
  const logs = props.data.modelLogs

  useEffect(() => {
    let tableData = {
      head: ['Name', 'Email', 'Domain', 'Error', 'CreatedAt'],
      rows: logs,
    }
    setTableLogs(tableData)
  }, [])

  const cancel = async () => {
    closeModal()
  }

  return (
    <div className="model-logs">
      <div className="header-title">Model Logs({logs?.length})</div>
      <div className="content-box">
        <Table data={tableLogs} />
      </div>
      <div className="footer">
        <div className="action-bar" style={{ display: 'contents' }}>
          <Button className="action-button no" onClick={cancel}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ModelLogs
