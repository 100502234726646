import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import circleCheck from '../../shared/assets/images/icons/circle-check.svg'
import circleCheckFilled from '../../shared/assets/images/icons/circle-check-filled.svg'
import { useConstants } from '../../shared/hooks/constants-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import './SelectCategory.css'
import ProfilePageLayout from '../components/ProfilePageLayout'
import Button from '../../shared/components/FormElements/Button'
import { useNavigate } from 'react-router-dom'
import { useShopify } from '../../shared/hooks/shopify-hook'
import errorHandler from '../../shared/util/errorHandler'

const SelectApparelCategory = ({ inAccountSettings }) => {
  const { publicConstants } = useConstants()
  const [saving, setSaving] = useState(false)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { sendRequest } = useHttpClient()
  const { connectShopifyStore } = useShopify()
  const user = auth.user
  const [selectedCategories, setSelectedCategories] = useState(
    user.userSelectedEnhencerCategories || [],
  )
  const initialCategories = user.userSelectedEnhencerCategories || []
  const [isDirty, setIsDirty] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate loading delay for demonstration purposes
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 100)

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (initialCategories.length != selectedCategories.length) {
      setIsDirty(true)
      return
    }
    var sortedinitialCategories = initialCategories.slice().sort()
    var sortedselectedCategories = selectedCategories.slice().sort()
    const isChanged = sortedinitialCategories.every(
      (value, index) => value === sortedselectedCategories[index],
    )
    setIsDirty(!isChanged)
  }, [selectedCategories])

  const categorySelected = itemName => {
    const newSelectedCategories = [...selectedCategories]

    if (newSelectedCategories.includes(itemName)) {
      newSelectedCategories.splice(newSelectedCategories.indexOf(itemName), 1)
    } else {
      newSelectedCategories.push(itemName)
    }

    setSelectedCategories(newSelectedCategories)

    publicConstants.apparelAccessoriesEnhencerCategories.forEach(item => {
      if (item.name === itemName) {
        item.selected = !item.selected
      }
    })
  }

  const goBackToMain = itemName => {
    navigate((inAccountSettings ? '/account' : '') + '/industry-selection')
  }

  const saveCategories = async () => {
    try {
      setSaving(true)
      const enhencerCategories = selectedCategories

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${inAccountSettings ? 'updateEnhencerCategories' : 'initializeEnhencerCategories'}`,
        'POST',
        JSON.stringify({
          userId: user.id,
          enhencerCategories: enhencerCategories,
        }),
        {
          authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      dispatch(
        authActions.updateUserObj({
          ...auth.user,
          userSelectedEnhencerCategories: enhencerCategories,
        }),
      )

      toast.success('Selected categories saved!')
      if (!inAccountSettings && auth.user.shopify) {
        await connectShopifyStore()
      }
      if (!inAccountSettings) navigate('/')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const pageContent = (
    <div className="select-category-container">
      <div className="header-row">
        <h2>Select Subcategories for Apparel & Accessories</h2>
        <div className="buttons-row row">
          <Button className="save-button" onClick={goBackToMain}>
            Back to Main Categories
          </Button>
          {isDirty && (
            <Button
              className="save-button"
              disabled={saving}
              onClick={saveCategories}
            >
              {saving
                ? 'Saving'
                : inAccountSettings
                  ? 'Save'
                  : 'Save and Continue'}
            </Button>
          )}
        </div>
      </div>
      <div className="category-part">
        <div className="category-part-grid">
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <div className="category-row-item-wrapper" key={index}>
                  <Skeleton height={250} />
                </div>
              ))
            : publicConstants.apparelAccessoriesEnhencerCategories.map(
                (item, index) => (
                  <div
                    className={`category-row-item-wrapper ${
                      selectedCategories.includes(item.name) ? 'selected' : ''
                    }`}
                    key={index}
                  >
                    <div
                      className={`category-part-row-item ${
                        selectedCategories.includes(item.name) ? 'selected' : ''
                      }`}
                      onClick={() => categorySelected(item.name)}
                    >
                      <div className="category-image-overlay"></div>
                      <img
                        className="category-image apparal-category-image"
                        src={item.imageUrl}
                        alt={item.name}
                      />
                      {selectedCategories.includes(item.name) ? (
                        <img
                          className="check-icon"
                          src={circleCheckFilled}
                          alt="checked"
                        />
                      ) : (
                        <img
                          className="check-icon"
                          src={circleCheck}
                          alt="check"
                        />
                      )}
                      <div className="category-name-overlay">{item.name}</div>
                    </div>
                  </div>
                ),
              )}
        </div>
      </div>
    </div>
  )

  return !inAccountSettings ? (
    <>
      <div className="select-category-desc">
        We need to know your industry to optimize AI audiences for you.
      </div>
      {pageContent}
    </>
  ) : (
    <ProfilePageLayout>{pageContent}</ProfilePageLayout>
  )
}

export default SelectApparelCategory
