import ReactPixel from 'react-facebook-pixel';
import SHA1 from './SHA1';
import TagManager from 'react-gtm-module/dist/TagManager';


function initAnalytics() {

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // to be moved away from this file
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
            cookie: true,  // enable cookies to allow the server to access
            // the session
            xfbml: true,  // parse social plugins on this page
            version: 'v2.1' // use version 2.1
        });
    };


    if (process.env.NODE_ENV === "production") {
        const gtmArgs = {
            gtmId: 'GTM-WRTQKM3',
        };
        TagManager.initialize(gtmArgs);

        const advancedMatching = {};
        const options = {
            autoConfig: true,
            debug: false,
        };
        ReactPixel.init('265072665480064', advancedMatching, options);
    }

    initializeHotjar()


}

const identifyImpact = ({ userId, userEmail }) => {
    if (process.env.NODE_ENV === "production") {
        window.ire('identify', { customerId: userId || '', customerEmail: userEmail ? SHA1(userEmail) : '' });
    }
}

const initializeHotjar = () => {
    let id;
    if (process.env.NODE_ENV === "production") {
        id = 3717174
    } else if (process.env.NODE_ENV === "test") {
        id = 3733709
    }

    if (id) {

        (function (h, o, t, j, a, r) {
            h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
            h._hjSettings = { hjid: id, hjsv: 6 };
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script'); r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
}

const completeSignUp = ({ registerType, userId, email }) => {

    if (process.env.NODE_ENV === "production") {
        let action = registerType === "google" ? 'sign up google' : (registerType === "shopify" ? 'sign up shopify' : 'sign up')
        TagManager.dataLayer({
            dataLayer: {
                event: 'Complete Sign Up',
                event_category: "App",
                event_label: "Complete Sign Up",
                event_action: action,
                user_email: email,
            },
        });

        ReactPixel.trackCustom('Sign Up');
        ReactPixel.trackCustom('Complete Conversion');
    }


}

const completeSubscription = ({ value, currency, subscriptionId, subscriptionPlanName, userId, userEmail }) => {


    if (process.env.NODE_ENV === "production") {
        setTimeout(() => {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'Start Subscription',
                    subscriptionValue: value,
                    subscriptionCurrency: currency,
                    event_category: "App",
                    event_label: "Complete Subscription",
                    event_action: "subscription"
                },
            });

            ReactPixel.trackCustom('Complete Subscription', { currency: currency, value: value })
        }, 1500);
    }

}


const completeConnections = () => {

    if (process.env.NODE_ENV === "production") {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Complete Connections',
                event_category: "App",
                event_label: "Complete Connections",
                event_action: "complete_connections"
            },
        });
        ReactPixel.trackCustom('Complete Connections', {})
    }
}

const createFirstFullFunnelCampaign = () => {

    if (process.env.NODE_ENV === "production") {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Create Campaign',
                "event_category": "App",
                "event_label": "Create Campaign",
                "event_action": "create_campaign"
            },
        });
        ReactPixel.trackCustom('Create Campaign', {})
    }
}

const bookAdHealthCheckupMeeting = () => {

    if (process.env.NODE_ENV === "production") {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Ad Health Checkup Meeting',
                "event_category": "App",
                "event_label": "Ad Health Checkup Meeting",
                "event_action": "ad_health_checkup_meeting"
            },
        });
        ReactPixel.trackCustom('Ad Health Checkup Meeting', {})
    }
}

const bookASupportMeeting = () => {

    if (process.env.NODE_ENV === "production") {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Support Meeting',
                "event_category": "App",
                "event_label": "Support Meeting",
                "event_action": "support_meeting"
            },
        });
        ReactPixel.trackCustom('Support Meeting', {})
    }
}

// support meeting
// 

export default {
    initAnalytics,
    completeSignUp,
    completeSubscription,
    completeConnections,
    createFirstFullFunnelCampaign,
    bookAdHealthCheckupMeeting,
    bookASupportMeeting
}