import React from 'react'
import { useSelector } from 'react-redux'
import './ProductsSelection.css'
import ProductsSelectionList from './ProductsSelectionList'

const ProductsSelection = props => {
  return (
    <div className="enhenced-product-selection-container">
      <div className="main-row">
        <div className="flow">
          <div className="product-section-body">
            <ProductsSelectionList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsSelection
