import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import store from './store/index'
import AppLayout from './shared/components/UIElements/AppLayout'
import { ModalProvider } from './shared/hooks/modal-hook'
import { NotificationPopupProvider } from './shared/hooks/notification-popup-hook'
import analytics from './shared/util/analytics'

import './index.css'
import './responsive.css'
import './shared/components/UIElements/Toast.css'
import UnderMaintenance from './shared/components/Navigation/UnderMaintenance'

analytics.initAnalytics()

let appComponent = (
  <ReduxProvider store={store}>
    <ModalProvider>
      <NotificationPopupProvider>
        <BrowserRouter>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            /* hideProgressBar={true} */
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
          <AppLayout>
            <App />
          </AppLayout>
        </BrowserRouter>
      </NotificationPopupProvider>
    </ModalProvider>
  </ReduxProvider>
)

/* ReactDOM.render(
  <UnderMaintenance />,
  document.getElementById('root'),
) */
ReactDOM.render(
  appComponent,
  document.getElementById('root'),
)
