import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { useFacebook } from '../../hooks/facebook-hook'
import { useModal } from '../../hooks/modal-hook'
import Button from '../FormElements/Button'

import './CampaignDeactivation.css'

const CampaignDeactivation = ({ data }) => {
  const { campaign, callback } = data

  const { getAdPreviews } = useFacebook()
  const { closeModal } = useModal()
  const [preview, setPreview] = useState(null)

  const campaignCreation = campaign?.createdAt
    ? new Date(campaign?.createdAt)
    : new Date()
  const campaignCreationTimestamp = campaignCreation.getTime()
  const sevenDaysLater = 7 * 24 * 60 * 60 * 1000
  const recommendedDate = new Date(campaignCreationTimestamp + sevenDaysLater)

  const generateAndSetRenderedPreview = previewResults => {
    const desktopFeedStandardPreview = previewResults.find(
      p => p.format === 'DESKTOP_FEED_STANDARD',
    )

    if (desktopFeedStandardPreview) {
      let { format, body } = desktopFeedStandardPreview

      body = body.replace('scrolling="yes"', 'scrolling="no"')

      const previewToRender = (
        <div
          key={format}
          className={`ad-creative-container ${format
            .toLowerCase()
            .replaceAll('_', '-')}`}
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      )

      setPreview(previewToRender)
    }
  }

  useEffect(() => {
    const getPreviewsOfCreative = async cid => {
      const previewResults = await getAdPreviews({
        creativeId: cid ? cid : '120200070614810307',
      })

      generateAndSetRenderedPreview(previewResults)
    }

    getPreviewsOfCreative(campaign.adCreative.id)
  }, [])

  return (
    <div className="campaign-deactivation">
      <div className="close-modal">
        <div className="cross" onClick={() => closeModal()} />
      </div>
      <div className="content">
        <div className="information-container">
          <div className="top">
            <h3 className="title">
              Are you sure? Let{' '}
              <span className="gradient-text">AI boost your Campaign</span>
            </h3>
            <p className="description">
              We recommend keeping your AI Ads running at least until
              {` ${moment(recommendedDate).format('MMMM Do')} `}
              to maximize your results and achieve peak performance!
            </p>
          </div>
          <div className="bottom">
            <div className="milestones">
              <div className="main-border">
                <div className="ai-ad-creation circle">
                  {moment(campaignCreation).format('MMM')}
                  <br />
                  {moment(campaignCreation).format('D')}
                </div>
                <p className="ai-ad-creation-text">AI Ad Creation</p>
                <div className="audience-optimization box">
                  AI Target Audience Optimization
                </div>
                <div className="inner-line"></div>
                <div className="improved-campaign-sales circle">
                  {moment(recommendedDate).format('MMM')}
                  <br />
                  {moment(recommendedDate).format('D')}
                </div>
                <p className="improved-campaign-sales-text">
                  Improved Campaign Sales
                </p>
                <div className="ai-ad-optimization box">AI Ad Optimization</div>
              </div>
            </div>
            <div className="buttons">
              <Button
                onClick={() => closeModal()}
                className="let-ai-optimize-btn"
              >
                Let AI Optimize
              </Button>
              <Button
                onClick={() => {
                  callback()
                  closeModal()
                }}
                className="close-campaign-btn"
              >
                Close Campaign
              </Button>
            </div>
          </div>
        </div>
        <div className="preview-container">
          {preview}
          <div className="circle top"></div>
          <div className="circle bottom"></div>
        </div>
      </div>
    </div>
  )
}

export default CampaignDeactivation
