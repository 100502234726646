import React, { useEffect } from 'react'
import gsap from 'gsap'
import { useSelector } from 'react-redux'

import AdCard from './AdCard'

import './AdList.css'

const BestCreatives = () => {
  const { topCreatives } = useSelector(state => state.adHealthCheckup.adsetsAndCreatives)

  useEffect(() => {
    const tl = gsap.timeline()

    tl.from('.top-ads-list .grey-line', {
      opacity: 0,
      duration: 0.8,
    })
    tl.from('.top-ads-list .header', {
      x: 100,
      y: 150,
      opacity: 0,
      duration: 0.8,
    })

      .from('.top-ads-list .detail-card.first', {
        y: 100,
        opacity: 0,
        duration: 0.8,
      })
      .from(
        '.top-ads-list .detail-card.first .order',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )

      .from('.top-ads-list .detail-card.second', {
        y: 100,
        opacity: 0,
        duration: 0.8,
      })
      .from(
        '.top-ads-list .detail-card.second .order',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )

      .from('.top-ads-list .detail-card.third', {
        y: 100,
        opacity: 0,
        duration: 0.8,
      })
      .from(
        '.top-ads-list .detail-card.third .order',
        {
          opacity: 0,
          duration: 0.8,
        },
        '-=0.5',
      )
  }, [])

  return (
    <div className="top-ads-content">
      <div className="top-ads-list content-box">
      <div className="grey-line">
          <svg
            width="1440"
            height="925"
            viewBox="0 0 1440 925"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1596_12739"
              masktype="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="1440"
              height="925"
            >
              <rect width="1440" height="925" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1596_12739)">
              <path
                className="svg-path-grey"
                opacity="0.2"
                d="M50 1079.5L730 635L975 963L1142.5 588L1042 106L1641.5 522"
                stroke="#9099A2"
                strokeWidth="95"
              />
            </g>
          </svg>
        </div>
        <div className="column">
          <div className="header">
            <div className="second-big-text"> Best Creatives by CTR </div>
            <div className="secondary-text gradient-text">Last 12 months</div>
          </div>

          <div className="grid ad-card-list ">
            {topCreatives.map((ad, index) => {
              const { ctr, budget, creativeId, adId } = ad
              const order = index + 1
              const className = {
                1: 'first',
                2: 'second',
                3: 'third',
              }[order]

              return (
                <AdCard
                  key={adId}
                  {...{
                    order,
                    compareBy: 'CTR',
                    compareByValue: ctr,
                    additionalMetric: 'Budget',
                    additionalMetricValue: budget,
                    creativeId,
                    className,
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BestCreatives
 