import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { FaLongArrowAltRight } from 'react-icons/fa'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import { useHttpClient } from '../../hooks/http-hook'
import shopifyLogo from '../../assets/images/icons/shopify_logo_black.png'

import 'react-toastify/dist/ReactToastify.css'

const LoginWithShopify = () => {
  const { sendRequest } = useHttpClient()
  const [shopName, setShopName] = useState('')
  const { closeModal } = useModal()

  const confirm = async () => {
    const shop = shopName.includes('.myshopify.com')
      ? shopName
      : shopName + '.myshopify.com'

    closeModal()
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/shopify?shopName=${shop}`,
        'GET',
      )
      window.open(result, '_self')
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }

  const onChange = e => {
    setShopName(e.target.value)
  }

  const submit = e => {
    e.preventDefault()
    confirm()
  }

  return (
    <div className="are-you-sure login-with-shopify">
      <form onSubmit={submit}>
        <div className="type-row">
          <div className="img-cont">
            <img alt="shopifylogo" src={shopifyLogo} />
          </div>
          <h5>Enter your shop name</h5>
          <div className="input-cont">
            <input
              id="shopName"
              value={shopName}
              onChange={onChange}
              type="text"
            />
            <span>.myshopify.com</span>
          </div>
        </div>
        <div style={{ marginBottom: '20px' }} className="action-bar">
          <Button className="action-button yes" onClick={confirm}>
            &nbsp; Continue
            <FaLongArrowAltRight className="icon" />
          </Button>
        </div>
      </form>
    </div>
  )
}
export default LoginWithShopify
