
const customerCategoryTypes = [
    "Fashion/Erkek", "Fashion/Kadın", "Home Decor", "Gifts"
]

const userProgressSteps = [
    "GTM Access",
    "GTM Publish",
    "Data connected",
    "Audiences defined on FB/Google Ads",
    "Enhencer Audience is ready",
    "Create Facebook A/B test ",
    "Enhencer Audience in all campaigns",
]

const campaigns = [
    {
        name: "Remarketing Audience",
        campaign: "Conversion Campaign",
        platform: "Facebook",
        isActive: true
    },
    {
        name: "Remarketing Audience",
        campaign: "Dynamic Product Ads Campaign",
        platform: "Facebook",
        isActive: false
    },
    {
        name: "Remarketing Audience",
        campaign: "Google Display Network - GDN",
        platform: "Google",
        isActive: true
    },
    {
        name: "High Intent Remarketing Audience",
        campaign: "Conversion Campaign",
        platform: "Facebook",
        isActive: true
    },
    {
        name: "High Intent Remarketing Audience",
        campaign: "Dynamic Product Ads Campaign",
        platform: "Facebook",
        isActive: false
    },
    {
        name: "Remarketing Audience",
        campaign: "Performance Max",
        platform: "Google",
        isActive: true
    },
    {
        name: "Remarketing Audience",
        campaign: "Search Campaign",
        platform: "Google",
        isActive: true
    },
    {
        name: "Remarketing Audience",
        campaign: "RLSA Campaign",
        platform: "Google",
        isActive: false
    },
]


const audienceResultIntervals = [
    { name: "Last 7 Days", days: 7 },
    { name: "Last 14 Days", days: 14 },
    { name: "Last 28 Days", days: 28 },
]

const topEnhencerCategories = [
    { name: "Animals & Pet Supplies", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/animals.png", selected: false },
    { name: "Apparel & Accessories", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/apparel.png", selected: false },
    { name: "Arts & Entertainment & Media", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/arts.png", selected: false },
    { name: "Baby & Toddler", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/baby.png", selected: false },
    { name: "Business & Industrial & Office Supplies", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/business.png", selected: false },
    { name: "Electronics & Cameras & Optics", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/electronics.png", selected: false },
    { name: "Food & Beverages & Tobacco", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/food.png", selected: false },
    { name: "Furniture & Home & Garden", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/furniture.png", selected: false },
    { name: "Hardware", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/hardware.png", selected: false },
    { name: "Health & Beauty", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/health.png", selected: false },
    { name: "Luggage & Bags", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/luggage.png", selected: false },
    { name: "Mature", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/mature.png", selected: false },
    { name: "Software", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/software.png", selected: false },
    { name: "Sporting Goods", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/sporting.png", selected: false },
    { name: "Toys & Games", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/toys.png", selected: false },
    { name: "Vehicles & Parts", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/vehicles.png", selected: false }
]

const apparelAccessoriesEnhencerCategories = [
    { name: "Accessories", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/accessories.png", selected: false },
    { name: "Activewear & Outerwear", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/activewear.png", selected: false },
    { name: "Dresses", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/dresses.png", selected: false },
    { name: "Jewelry", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/jewelry.png", selected: false },
    { name: "Outfit Sets", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/outfit.png", selected: false },
    { name: "Pants", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/pants.png", selected: false },
    { name: "Shirts & Tops", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/shirts.png", selected: false },
    { name: "Shoes", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/shoes.png", selected: false },
    { name: "Modest Hijab", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/modest.jpeg", selected: false },
    { name: "Shorts & Skirts & Skorts", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/shorts.png", selected: false },
    { name: "Sleepwear & Loungewear", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/sleepwear.png", selected: false },
    { name: "Suits", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/suits.png", selected: false },
    { name: "Swimwear", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/swimwear.png", selected: false },
    { name: "Underwear & Socks", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/underwear.png", selected: false },
    { name: "Uniforms", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/uniforms.png", selected: false },
    { name: "Wedding & Bridal Party Dresses", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/wedding.png", selected: false },
    { name: "Handbags & Wallets & Cases", imageUrl: "https://cdn.enhencer.com/admin-assets/images/select-category-page/handbags.png", selected: false }
]

const createPerformanceManagerDetails = person => {
  let cdnUrl = 'https://cdn.enhencer.com/website-assets/images/team/09.23/'
  switch (person) {
    case 'Görkem Beşer':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/gorkem-beser/enhencer-customer-support-gorkem',
        email: 'gorkem.beser@enhencer.com',
        phoneNumber: '+905307809901',
        imgUrl: `${cdnUrl}gorkem-beser.jpg`,
      }
    case 'Serkan Oğuz':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/serkan-oguz/enhencer-customer-support-serkan',
        email: 'serkan.oguz@enhencer.com',
        phoneNumber: '+905550470180',
        imgUrl: `${cdnUrl}serkan-oguz.jpg`,
      }
    case 'Cengiz Kalkan':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/cengiz-kalkan/enhencer-customer-support-cengiz',
        email: 'cengiz.kalkan@enhencer.com',
        phoneNumber: '+905393440567',
        imgUrl: `${cdnUrl}cengiz-kalkan.jpg`,
      }
    case 'Onur Tan':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/onur-tan/enhencer-customer-support-onur',
        email: 'onur.tan@enhencer.com',
        phoneNumber: '+905346160911',
        imgUrl: `${cdnUrl}onur-tan.jpg`,
      }
    case 'Cerena Ateş':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/cerena-ates/enhencer-customer-support-cerena',
        email: 'ceren.ates@enhencer.com',
        phoneNumber: '',
        imgUrl: `${cdnUrl}rena-cerena-ates.jpg`,
      }
    case 'Ilgın Candan Esmer':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/ic-esmer/enhencer-customer-support-ilgin',
        email: 'ic.esmer@enhencer.com',
        phoneNumber: '+905305526514',
        imgUrl: `${cdnUrl}ilgin-candan-esmer.jpg`,
      }
    case 'Selin Altıntaş':
      return {
        name: person,
        meetingUrl:
          'https://calendly.com/selin-altintas/enhencer-customer-support-selin',
        email: 'selin.altintas@enhencer.com',
        phoneNumber: '+905305526514',
        imgUrl: `${cdnUrl}selin-altintas.jpg`,
      }
    default:
      return {
        name: person,
        meetingUrl: '',
        email: '',
        phoneNumber: '',
        imgUrl: '',
      }
  }
}

const callTimeRanges = {
    "morning": "09:00-12:00",
    "afternoon": "12:00-18:00",
    "evening": "18:00-22:00"
}

const usdTlCurrencyRate = 32.5

const performanceManagers = [
    "Cengiz Kalkan",
    "Cerena Ateş",
    "Görkem Beşer",
    "Ilgın Candan Esmer",
    "Onur Tan",
    "Selin Altıntaş",
    "Serkan Oğuz",
    "Kamer Şahin",
    "Fırat Çetin",
]

const accountManagers = [
    "Nehir Kaya",
    "Osman Zafer Yılmaz",
    "Özlem Malatyalı",
    "Cengiz Kalkan",
    "Cerena Ateş",
    "Görkem Beşer",
    "Ilgın Candan Esmer",
    "Onur Tan",
    "Selin Altıntaş",
    "Serkan Oğuz",
]

// New UI Constants - End

export default {
    customerCategoryTypes,
    userProgressSteps,
    campaigns,
    audienceResultIntervals,
    usdTlCurrencyRate,
    topEnhencerCategories,
    apparelAccessoriesEnhencerCategories,
    callTimeRanges,
    createPerformanceManagerDetails,
    performanceManagers: performanceManagers,
    accountManagers: accountManagers,
}
