import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
  FaUser,
  FaSignOutAlt,
  FaChevronUp,
  FaChevronDown,
  FaScroll,
  FaEnvelope,
} from 'react-icons/fa'
import BarButton from '../UIElements/BarButton'

const ProfileMenu = props => {
  const [isProfileExpanded, setIsProfileExpanded] = useState(false)

  const location = useLocation()
  const user = useSelector(state => state.auth.user)
  const { hasUnreadMessages } = useSelector(state => state.supportTickets)

  const profileMenuRef = useRef()

  const closeMenu = () => {
    profileMenuRef.current.classList.remove('open')
    document.removeEventListener('mousedown', handleClickOutside, true)
  }

  const toggleProfileMenu = e => {
    if (e) {
      e.preventDefault()
    }

    if (profileMenuRef.current) {
      if (profileMenuRef.current.classList.contains('open')) {
        setIsProfileExpanded(false)
        profileMenuRef.current.classList.remove('open')
        document.removeEventListener('mousedown', handleClickOutside, true)
      } else {
        setIsProfileExpanded(true)
        profileMenuRef.current.classList.add('open')
        document.addEventListener('mousedown', handleClickOutside, true)
      }
    }
  }

  const handleClickOutside = event => {
    if (!event.target.closest('.profile-menu-cont')) {
      closeProfileMenu()
    }
  }


  const closeProfileMenu = () => {
    setIsProfileExpanded(false)
    profileMenuRef.current?.classList?.remove('open')
    document.removeEventListener('mousedown', handleClickOutside, true)
  }


  return (
    <div className="profile-menu-cont">
      <BarButton
        type="profile-button"
        title="Profile"
        elem={
          user?.image ? (
            <div style={{ height: '100%' }}>
              <img alt="" className="nav-profile-pic" src={user.image} />
            </div>
          ) : (
            createLetterImage(user.email)
          )
        }
        chevron={
          isProfileExpanded ? (
            <FaChevronUp className="profileicon" />
          ) : (
            <FaChevronDown className="profileicon" />
          )
        }
        onClick={toggleProfileMenu}
        badge={hasUnreadMessages}
      ></BarButton>
      <div className="profile-menu" ref={profileMenuRef}>
        <div className="">
          <div className={`menu-list`}>
            <Link
              onClick={() => {
                closeMenu()
                props.onToggleMenuCalled()
                setIsProfileExpanded(false)
              }}
              className={`menu-item ${location.pathname.includes('account/info') ? 'active' : ''}`}
              to="/account/info"
            >
              <FaUser className="icon" />
              {user?.email}
            </Link>

            {
              !props.isSpecialUser &&
              <>
                <Link
                  onClick={() => {
                    closeMenu()
                    props.onToggleMenuCalled()
                    setIsProfileExpanded(false)
                  }}
                  className={`menu-item ${location.pathname.includes('account/tickets') ? 'active' : ''}`}
                  to={
                    hasUnreadMessages
                      ? '/account/tickets?filter=unread_messages'
                      : '/account/tickets'
                  }
                >
                  {hasUnreadMessages ? (
                    <div className="icon-container">
                      <FaEnvelope className="icon" />
                      <div className="badge" />
                    </div>
                  ) : (
                    <FaEnvelope className="icon" />
                  )}
                  Support Tickets
                </Link>

                <Link
                  onClick={() => {
                    closeMenu()
                    props.onToggleMenuCalled()
                    setIsProfileExpanded(false)
                  }}
                  className={`menu-item ${location.pathname.includes('account/subscription') ? 'active' : ''}`}
                  to="/account/subscription"
                >
                  <FaScroll className="icon" />
                  Subscription
                </Link>
              </>
            }
            <div className="menu-item" onClick={props.logout}>
              <FaSignOutAlt className="icon" /> Log Out
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileMenu

const createLetterImage = name => {
  let letter = name.substring(0, 1)
  return <div className="name-first-letter">{letter}</div>
}
