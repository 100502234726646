import React, { useMemo, useState } from 'react'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'

import 'react-toastify/dist/ReactToastify.css'
import CustomDatePicker from '../UIElements/CustomDatePicker'

import "./AdminCheckUpMeeting.css"
import '../../../admin/components/AdminPanelCustomerCardAlarmPart.css'
import Input from '../FormElements/Input'
import countriesData from "../../../shared/data/countries.json";
import { FaPhone, FaTimes, FaTrash } from 'react-icons/fa'
import { useForm } from '../../hooks/form-hook'
import Checkbox from '../FormElements/Checkbox'
import helper from '../../util/helper'
import Modals from '../../enums/Modals'

const AdminCheckUpMeeting = props => {
  const { openModal, closeModal, modalData } = useModal()

  const [customNote, setCustomNote] = useState(modalData.customNote ?? '')
  const [meetingDone, setMeetingDone] = useState(modalData.meetingDone ?? false)

  const handleDoneToggle = () => {
    setMeetingDone(!meetingDone);
  };

  const handleNoteChange = (e) => {
    let newValue = e.target.value;
    setCustomNote(newValue)
  }

  const save = () => {
    modalData.callback({ customNote, meetingDone })
    closeModal()
  }


  const cancelMeeting = () => {
    openModal({
      type: Modals.ARE_YOU_SURE,
      question: <div>
        <h3>Are you sure you want to cancel this meeting?</h3>
        <span>After you cancel this meeting, customer will be able to book another checkup meeting.</span>
      </div>,
      callback: async () => {
        modalData.cancelMeetingCallback()
      },
    })
  }

  const close = () => {
    closeModal()
  }

  return (
    <div className="alarm-modal checkup-meeting-modal">
      <div className="modal-title">
        <div className='customer-name'>Check Up Meeting - {modalData.name}</div>
        <Button size="small" onClick={close}><FaTimes /></Button>
      </div>
      <div className="modal-middle">
        <div className='property'>
          <div className='property-desc'>Meeting Date:</div>
          <div>{helper.convertDateToString(modalData.meetingStarts)}</div>
        </div>
        <div className='property'>
          <div className='property-desc'>Customer Note:</div>
          <textarea element="input" placeholder="" id="note" type="text" value={customNote} onChange={handleNoteChange} rows={5} />
        </div>
        <div className='property'>
          <div className='property-desc'>Done:</div>
          <Checkbox element="input" id="done" type="text" checked={meetingDone} onChange={handleDoneToggle} />
        </div>
      </div>
      <div className="modal-footer">
        <Button size="small" inverse={true} className="action-button yes" onClick={cancelMeeting}>
          Cancel meeting
          <FaTrash />
        </Button>
        <div className='row'>
          <Button size="small" className="action-button update" onClick={() => {
            save()
          }}>
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}


export default AdminCheckUpMeeting
