import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useModal } from '../../shared/hooks/modal-hook';
import Modals from '../../shared/enums/Modals';

const NotConnectedOverview = params => {
    const { openModal } = useModal()
    const user = useSelector(state => state.auth.user)
    const navigate = useNavigate()

    const goTo = loc => {
        if (user.isSpecialUser || user.subscription) {
          navigate(loc)
        } else {
          openModal({
            type: Modals.PAYMENT_REQUIRED,
            closeOnClickOutside: false,
          })
        }
    }

    return <React.Fragment>
        <div className="no-data">
            Welcome to Enhencer.
            <br /> <br />
            This is the connect page of the platform. Once Enhencer is
            integrated with your E-Commerce website, you will see the
            information about the website visitors’ data that Enhencer collected
            automatically.
            <br /> <br />
            To get started, connect your website to Enhencer through one of the
            choices below. Not comfortable with codes, not to worry, <a href="https://enhencer.com/contact-form" target="_blank">contact us</a>,
            and our team will reach out to you as soon as possible.
            <div className="connect-options">
                <div
                    className="option-button"
                    onClick={() => {
                        goTo("/connect-gtm");
                    }}
                >
                    Google Tag Manager
                </div>
                <div
                    className="option-button"
                    onClick={() => {
                        goTo("/connect-js");
                    }}
                >
                    Javascript SDK
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default NotConnectedOverview;