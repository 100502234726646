import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useModal } from '../../hooks/modal-hook'
import FreeAdHealthCheckupIcon from '../../assets/images/icons/free-ad-health-checkup.svg'
import StartSubscriptionIcon from '../../assets/images/icons/start-subscription.svg'
import BookADemoIcon from '../../assets/images/icons/book-a-demo.svg'
import AppLogos from '../../assets/images/start-screen-modal-apps.png'

import './StartScreen.css'
import SocialLogosAnimated from '../../../ad-health-checkup/components/SocialLogosAnimated'

const StartScreen = () => {
  const user = useSelector(state => state.auth.user)
  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const fbInfoFetching = useSelector(state => state.facebook.fetching)

  const shouldCompleteConnections = (!selectedAdAccount || !selectedPixel || !selectedBusiness) && !fbInfoFetching

  const navigate = useNavigate()

  const handleFreeAdHealthCheckup = () => {
    navigate('/ad-health-checkup')
  }

  const handleStartSubscription = () => {
    navigate('/account/subscription')
  }

  const handleBookADemo = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: process.env.REACT_APP_CALENDLY_DEMO_MEETING_LINK,
        prefill: {
          email: user.email || '',
          name: user.name || '',
        },
      })
    }
  }

  return (
    <div className="start-screen">
      <img src={AppLogos} alt="App Logos" className="app-logos" />
      <SocialLogosAnimated />
      <div className="header">
        <h2 className="small-title">Start Your AI Ads Journey</h2>
        <h1 className="title">
          Ready to elevate your paid marketing with
          <span className="highlighted"> AI Ads?</span>
        </h1>
      </div>
      <div className="divider"></div>
      <div className="content">
        <div className="card" onClick={handleBookADemo}>
          <img className="icon" src={BookADemoIcon} alt="Book a Demo" />
          <div className="title-box">
            <h3 className="title">Book a Demo</h3>
          </div>
        </div>
        <div className="card colored" onClick={handleFreeAdHealthCheckup}>
          <img
            className="icon"
            src={FreeAdHealthCheckupIcon}
            alt="Free Ad Health Checkup"
          />
          <div className="title-box">
            <h3 className="title">
              FREE Ad Health Checkup
              {/* <span className="highlighted">Free</span> Ad Health Checkup */}
            </h3>
          </div>
          {
            shouldCompleteConnections &&
            <span className="helper-text">Connect your Facebook account and get started!</span>
          }
        </div>
        <div className="card" onClick={handleStartSubscription}>
          <img
            className="icon"
            src={StartSubscriptionIcon}
            alt="Start Subscription"
          />
          <div className="title-box">
            <h3 className="title">Start Your Subscription</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartScreen
