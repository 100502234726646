import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useConstants } from '../../shared/hooks/constants-hook'
import { useAdmin } from '../../shared/hooks/admin-hook'

import Checkbox from '../../shared/components/FormElements/Checkbox'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import DatePickerWithPresets from '../../shared/components/UIElements/DatePickerWithPresets'

import './FilterGroup.css'
import HourRangePicker from '../../shared/components/UIElements/HourRangePicker'
import helper from '../../shared/util/helper'

const FilterGroup = props => {
  const { page, category, handleFilterChange, tempFilter } = props
  const { privateConstants: constants } = useConstants()
  const { convertManagerNameToId } = useAdmin()
  let headers

  const [hasSelectedFilter, setHasSelectedFilter] = useState(false)

  const {
    panelSummary,
    performanceManagers,
    accountManagers,
    overdueAlarmCounts,
  } = useSelector(state => state.admin)
  const [summaryExtras, setSummaryExtras] = useState({})

  const performanceManagersArray = Object.values(performanceManagers)
  const accountManagersArray = Object.values(accountManagers)
  let namesOfPerformanceManagers = performanceManagersArray.map(
    manager => manager.name,
  )
  let namesOfAccountManagers = accountManagersArray.map(manager => manager.name)
  namesOfPerformanceManagers.push('<Not set>')
  namesOfAccountManagers.push('<Not set>')

  if (page === 'demoMeetings') {
    namesOfAccountManagers.push('Others')
  }

  switch (page) {
    case 'customers':
      headers = constants.performanceFilters
      break
    case 'signUps':
      headers = constants.signUpFilters
      break
    case 'demoMeetings':
      headers = constants.demoMeetingFilters
      break
    case 'alarmCalendar':
      headers = constants.alarmsCalendarFilters
      break
    case 'supportTickets':
      headers = constants.ticketFilters
      break
    default:
      break
  }

  // Find the filter object based on the category key
  const filterObj = headers?.find(header => header.key === category)

  const getColorForValue = value => {
    switch (value) {
      case 'Onboarding':
        return '#F1B623'
      case 'No Access':
        return '#4269F1'
      case 'Bad':
        return '#E20C0C'
      case 'Good':
        return '#46B680'
      case 'No Status':
        return '#464A4B'
      default:
        return '#464A4B'
    }
  }

  const getSummaryExtra = value => {
    return summaryExtras[value] != undefined ? ' - ' + summaryExtras[value] : ''
  }

  const showPercent = (val, panelSummary, invert = false) => {
    return (
      (
        Math.abs((invert ? 1 : 0) - panelSummary[val] / panelSummary['count']) *
        100
      ).toFixed(1) + '%'
    )
  }
  const getFontWeight = () => {
    if (filterObj?.name === 'Performance Status') return 600
  }

  const summaryMapping = {
    Country: {
      TR: 'country_tr',
      'USA & Canada': 'country_usca',
      UK: 'country_uk',
      Australia: 'country_au',
      Germany: 'country_de',
      France: 'country_fr',
      Other: 'country_other',
    },
    'Account Manager': {
      'Nehir Kaya': 'accmanager_nehir',
      'Osman Zafer Yılmaz': 'accmanager_osman',
      'Özlem Malatyalı': 'accmanager_ozlem',
      'Not set': 'accmanager_notset',
    },
    'Performance Manager': {
      'Cengiz Kalkan': 'perfmanager_cengiz',
      'Cerena Ateş': 'perfmanager_cerena',
      'Görkem Beşer': 'perfmanager_gorkem',
      'Ilgın Candan Esmer': 'perfmanager_ilgin',
      'Onur Tan': 'perfmanager_onur',
      'Serkan Oğuz': 'perfmanager_serkan',
      'Selin Altıntaş': 'perfmanager_selin',
      'Kamer Şahin': 'perfmanager_kamer',
      'Fırat Çetin': 'perfmanager_firat',
      'Not set': 'perfmanager_notset',
    },
    'Lead Type': {
      Outbound: 'lead_outbound',
      'Facebook / Instagram': 'lead_faceinsta',
      Google: 'lead_google',
      Shopify: 'lead_shopify',
      LinkedIn: 'lead_linkedin',
      Email: 'lead_email',
    },
    'Performance Status': {
      'No Status': 'perfstatus_nostatus',
      Onboarding: 'perfstatus_onboarding',
      Bad: 'perfstatus_bad',
      Good: 'perfstatus_good',
      'No Access': 'perfstatus_noaccess',
      'No Touch': 'perfstatus_notouch',
    },
    Platform: {
      Shopify: 'platformShopifyCount',
      Other: '-platformShopifyCount',
    },
    'Audience Network': {
      Yes: 'anCount',
      No: '-anCount',
    },
    'AI Ads': {
      Yes: 'aiAdsCount',
      No: '-aiAdsCount',
    },
    Paused: {
      Yes: 'pausedCount',
      No: '-pausedCount',
    },
    'Usage Charges': {
      Yes: 'usageChargesCount',
      No: '-usageChargesCount',
    },
  }

  useEffect(() => {
    if (panelSummary) {
      for (const groupName in summaryMapping) {
        const groupMap = summaryMapping[groupName]
        if (filterObj?.name === groupName) {
          const resultSummaryExtras = {}
          Object.keys(groupMap).forEach(filterElementName => {
            const nameValue = groupMap[filterElementName]
            if (nameValue.startsWith('-')) {
              //invert the percent
              resultSummaryExtras[filterElementName] = showPercent(
                nameValue.split('-')[1],
                panelSummary,
                true,
              )
            } else {
              resultSummaryExtras[filterElementName] = showPercent(
                nameValue,
                panelSummary,
              )
            }
          })
          setSummaryExtras(resultSummaryExtras)
        }
      }
    }
  }, [panelSummary])

  useEffect(() => {
    const thisCategoryFilter = tempFilter[category]
    if (Array.isArray(thisCategoryFilter) && tempFilter[category].length) {
      setHasSelectedFilter(true)
    } else {
      setHasSelectedFilter(false)
    }
  }, [tempFilter])

  const renderCheckboxes = (
    filterObj,
    category,
    tempFilter,
    handleFilterChange,
    getColorForValue,
    getFontWeight,
    getSummaryExtra,
  ) => {
    let itemsToMap = []

    if (filterObj.key === 'performanceManager') {
      itemsToMap = namesOfPerformanceManagers
    } else if (filterObj.key === 'accountManager') {
      itemsToMap = namesOfAccountManagers
    } else {
      itemsToMap = filterObj.values
    }

    return itemsToMap.map((value, index) => {
      let managerId = value
      if (
        (category === 'performanceManager' || category === 'accountManager') &&
        value !== '<Not set>'
      ) {
        managerId = convertManagerNameToId(value).found
      }

      return (
        <Tooltip
          size="sm"
          key={value}
          content={filterObj.explanations ? filterObj.explanations[index] : ''}
          offsetX={5}
        >
          <Checkbox
            name={category}
            value={value}
            label={
              page === 'customers'
                ? value + getSummaryExtra(value)
                : page === 'alarmCalendar' &&
                    (category === 'performanceManager' ||
                      category === 'accountManager') &&
                    value !== '<Not set>'
                  ? value +
                    (overdueAlarmCounts[managerId]
                      ? ' (⏰ ' + overdueAlarmCounts[managerId] + ')'
                      : '')
                  : value
            }
            checked={
              (category === 'performanceManager' ||
                category === 'accountManager') &&
              value !== '<Not set>' &&
              value !== 'Others'
                ? tempFilter[category].includes(managerId)
                : tempFilter[category].includes(value)
            }
            color={getColorForValue(value)}
            fontWeight={getFontWeight()}
            onChange={event => {
              handleFilterChange({
                groupName: category,
                value: event.target.value,
                type: 'checkbox',
                checked:
                  (category === 'performanceManager' ||
                    category === 'accountManager') &&
                  value !== '<Not set>' &&
                  value !== 'Others'
                    ? !tempFilter[category].includes(managerId)
                    : !tempFilter[category].includes(value),
              })
            }}
          />
        </Tooltip>
      )
    })
  }
  if (page === 'alarmCalendar' && !overdueAlarmCounts) {
    return null
  }

  return (
    <div
      className={`filter-group ${
        hasSelectedFilter ? 'is-active' : ''
      } ${filterObj?.name.toLowerCase().replaceAll(' ', '')}`}
    >
      {filterObj?.type === 'checkbox' ? (
        <div className="checkboxes">
          <h2>{filterObj?.name}</h2>
          {renderCheckboxes(
            filterObj,
            category,
            tempFilter,
            handleFilterChange,
            getColorForValue,
            getFontWeight,
            getSummaryExtra,
          )}
        </div>
      ) : filterObj?.type === 'range' ? (
        <div className="range">
          <h2>{filterObj?.name}</h2>
          <div className="inputs-wrapper">
            {filterObj?.inputs.map(input => {
              return (
                <div className="input" key={input.key}>
                  <label>{input.name}</label>
                  <input
                    type={input.type}
                    min={input.minValue}
                    value={tempFilter[category][input.key]}
                    name={`${filterObj?.key}.${input.key}`}
                    onChange={event => {
                      handleFilterChange({
                        groupName: `${filterObj?.key}.${input.key}`,
                        value: event.target.value,
                        type: 'range',
                      })
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ) : filterObj?.type === 'date-range' ? (
        <div className="date-range">
          <Tooltip
            size="sm"
            key={filterObj?.name}
            content={filterObj.explanations[0]}
          >
            <h2>{filterObj?.name}</h2>
          </Tooltip>
          <DatePickerWithPresets
            filter={tempFilter[category]}
            setFilters={newFilter => {
              handleFilterChange({
                groupName: `${filterObj?.key}`,
                value: newFilter,
                type: 'date-range',
              })
            }}
          />
        </div>
      ) : filterObj?.type === 'hour-range' ? (
        <div className="hour-range">
          <Tooltip
            size="sm"
            key={filterObj?.name}
            content={filterObj.explanations[0]}
          >
            <h2>{filterObj?.name}</h2>
          </Tooltip>
          <HourRangePicker
            filter={tempFilter[category]}
            setFilters={newFilter => {
              handleFilterChange({
                groupName: `${filterObj?.key}`,
                value: newFilter,
                type: 'hour-range',
              })
            }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default FilterGroup
