import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

import { useHttpClient } from './http-hook'
import helper from '../util/helper'
import { facebookActions } from '../../store/facebook'
import { useModal } from '../../shared/hooks/modal-hook'
import InlineLoadingSpinner from '../components/UIElements/InlineLoadingSpinner'
import { useUser } from '../hooks/user-hook'

import cookies from '../util/cookies'
import analytics from '../util/analytics'

import dummyReport from "../data/dummyreport.json"
import { adminActions } from '../../store/admin'
import { reportIssueToTheTeam } from '../util/autoReporter'
import useFacebookAudiences from './facebook/audience-hook'
import currencyOffsets from "../../shared/data/marketing-api-currency-offset.json"
import Modals from '../enums/Modals'
import errorHandler from '../util/errorHandler'


export const useFacebook = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { closeModal } = useModal()
  const { setConnectFlowStep } = useUser();
  const { checkForAudienceErrors } = useFacebookAudiences()

  const user = useSelector(state => state.auth.user)
  const authToken = useSelector(state => state.auth.token)
  const authHeader = {
    Authorization: 'Bearer ' + authToken,
    'Content-Type': 'application/json',
  }
  const facebookGraphUrl = process.env.REACT_APP_FACEBOOK_GRAPH_URL

  const [auth, setAuth] = useState()
  const facebook = useSelector(state => state.facebook)
  const [isErrorToastShown, setIsErrorToastShown] = useState(false)



  const handleError = (err) => {
    if (!err.code) {
      return toast.error(err.message || 'Something went wrong')
    }

    if (err.code !== 190 && err.code !== 200) {
      return toast.error(
        'Error connecting to Facebook, please try again later.',
      )
    }

    if (!isErrorToastShown) {
      toast.warn('Your session has expired, please log in again.')
      setIsErrorToastShown(true)
    }

    logOut(true)
    navigate('/config')
  }

  const initializeFacebookInfo = async fb => {
    console.log("initializing facebook info", fb)
    dispatch(facebookActions.connectionPageIsReady(false))

    if (!user.isSpecialUser) {
      let promises = []
      promises.push(
        getAdAccounts({
          userId: fb.auth.id,
          accessToken: fb.auth.accessToken,
          selectedAdAccountId: fb.selectedAdAccount?.id,
          freshFetch: false,
        }),
      )


      promises.push(
        getBusinesses({
          userId: fb.auth.id,
          accessToken: fb.auth.accessToken,
          selectedBusinessId: fb.selectedBusiness?.id,
          freshFetch: false,
        })
      )

      if (fb.selectedAdAccount) {

        promises.push(
          getPixelsOfAdAccount({
            adAccountId: fb.selectedAdAccount.id,
            accessToken: fb.auth.accessToken,
            selectedPixelId: fb.selectedPixel?.id,
          }),
          fetchReportsForCampaigns({ fb, isInitialFetch: true })
        )
      } else {
        //when below is active, state is updated without businesses and adaccounts
        /* dispatch(facebookActions.updateInfo(fb)) */
      }


      try {
        let r = await Promise.all(promises)
        dispatch(facebookActions.connectionPageIsReady(true))
        console.log("initializing facebook info finish")
      } catch (err) {
        dispatch(facebookActions.connectionPageIsReady(true))
        handleError(err)
      }
    } else { // if admin

      let newFb = { ...fb }
      if (fb.selectedAdAccount && fb.selectedAdAccount.id) {
        newFb.adAccounts = [fb.selectedAdAccount]
        newFb.adAccountSelectionIsEligible = true
      }
      if (fb.selectedBusiness && fb.selectedBusiness.id) {
        newFb.businesses = [fb.selectedBusiness]
        newFb.businessSelectionIsEligible = true
      }
      if (fb.selectedPixel && fb.selectedPixel.id) {
        newFb.pixels = [fb.selectedPixel]
        newFb.pixelSelectionIsEligible = true
      }
      if (fb.selectedAdAccount) {
        await fetchReportsForCampaigns({ fb: newFb, isInitialFetch: true })
      } else {
        dispatch(facebookActions.updateInfo(newFb))
      }

      dispatch(facebookActions.connectionPageIsReady(true))
    }
  }

  const fetchReportsForCampaigns = async ({ fb = facebook, filter = facebook.reportsFilter, isInitialFetch = false }) => {
    let allAdCampaignIds = [] // all ad campaigns including traffic
    let trafficCampaignIds = []
    let conversionAdCampaignIds = []

    /* this check has been removed, but may be useful in the future
    if (!c.apiErrors || (!c.apiErrors.adCampaign && !c.apiErrors.adsets && !c.apiErrors.ads))
    */

    if (fb.enhCampaigns?.length) {
      fb.enhCampaigns.forEach((c, i) => {
        let conversionCampaignId = c.adCampaign.id
        let trafficCampaignId = c.trafficCampaign?.campaign?.id

        allAdCampaignIds.push(conversionCampaignId)
        conversionAdCampaignIds.push(conversionCampaignId)


        if (trafficCampaignId) {
          allAdCampaignIds.push(trafficCampaignId)
          trafficCampaignIds.push(trafficCampaignId)
        }
      })

      try {
        dispatch(facebookActions.fetchingReports(true))
        let enhCampaigns = [...fb.enhCampaigns]
        let allAdCampaigns;
        if (isInitialFetch) {
          allAdCampaigns = await getAllCampaignsDetails({ adAccountId: fb.selectedAdAccount.id, campaignIds: allAdCampaignIds, filter, accessToken: fb.auth.accessToken })

          const conversionAdCampaigns = allAdCampaigns.filter(c => c.objective === "OUTCOME_SALES")

          if (conversionAdCampaigns.length !== conversionAdCampaignIds.length) { // the campaign has been deleted or archived on fb panel
            let indexesToRemove = []
            for (var i = 0; i < enhCampaigns.length; i++) {
              let exists = false
              conversionAdCampaigns.forEach(c => {
                if (c.id === enhCampaigns[i].adCampaign.id) {
                  exists = true
                }
              })

              if (exists === false) {
                indexesToRemove.push(i)
              }
            }
            for (var i = indexesToRemove.length - 1; i >= 0; i--) {
              enhCampaigns.splice(indexesToRemove[i], 1)
            }
            // TODO: here we should update the fb info in DB
          }
        }

        let adsets = await getAllAdsetsDetails({ adAccountId: fb.selectedAdAccount.id, campaignIds: allAdCampaignIds, filter, accessToken: fb.auth.accessToken, enhCampaigns, currentFacebookInfo: fb })

        console.log("here ", adsets)

        let newEnhCampaigns = []
        enhCampaigns.forEach(enhCampaign => {


          let adsetsOfConversionAdCampaign = adsets[enhCampaign.adCampaign.id]
          let adsetsOfTrafficAdCampaign = []
          if (enhCampaign.trafficCampaign) {
            adsetsOfTrafficAdCampaign = adsets[enhCampaign.trafficCampaign.campaign.id]
          }

          let newReportsBody = {
            ...enhCampaign.reports,
            adsetLevel: {
              ...enhCampaign.reports?.adsetLevel,
              [filter.value]: Object.assign({}, adsetsOfConversionAdCampaign, adsetsOfTrafficAdCampaign),
            },
          }

          if (isInitialFetch) {
            let foundAdCampaign = helper.findBy(allAdCampaigns, "id", enhCampaign.adCampaign.id)
            newReportsBody.campaignLevel = {
              ...foundAdCampaign,
              effectiveStatus: foundAdCampaign.effective_status
            }
          }

          let newCampaign = {
            ...enhCampaign,
            reports: newReportsBody
          }
          newEnhCampaigns.push(newCampaign)
        })

        dispatch(facebookActions.fetchingReports(false))
        let newFbObj = {
          enhCampaigns: newEnhCampaigns,
        }
        // if (isInitialFetch) newFbObj.activeEnhCampaignIdForReports = enhCampaigns[0]?._id


        dispatch(facebookActions.updateInfo(newFbObj))


        const overallReportByAdsets = calculateAndUpdateAdsetBasedOverallReportForEnhCampaigns({
          enhCampaigns: newEnhCampaigns,
          filter,
          isInitialFetch
        })

        // Send the overall report to backend
        if (['last_14d', 'last_7d', 'yesterday'].includes(filter.value)) {

          let allAdsetsList = []
          let adsetsByCampaignId = Object.values(adsets)

          adsetsByCampaignId.forEach(adsetsOfSingleCampaign => {
            let adsetsArray = Object.values(adsetsOfSingleCampaign)
            allAdsetsList = allAdsetsList.concat(adsetsArray)
          })

          await updateDailyReport({
            datePreset: filter.value,
            overallReportByAdsets,
            allEnhAdsets: allAdsetsList,
            userId: fb.userId,
            adAccountId: fb.selectedAdAccount.id,
          })
        }

        return enhCampaigns
      } catch (err) {
        throw err
      }
    }
  }


  const getAllCampaignsDetails = async ({ adAccountId, campaignIds, filter, accessToken }) => {

    let filtering = `[{"field": "id","operator": "IN","value": [${campaignIds.join(",")}]}]`

    let url = `${facebookGraphUrl}${adAccountId}/campaigns?filtering=${filtering}&fields=name,objective,effective_status,start_time&&access_token=${accessToken}`

    let campaigns = await fetchWithPagination({ url })
    return campaigns

  }



  const getAllAdsetsDetails = async ({ adAccountId, enhCampaigns, campaignIds, currentFacebookInfo, filter, accessToken }) => {
    let dateFilter = ''

    if (filter.type === "time_range") {
      dateFilter = `.time_range(${filter.value})`
    } else if (filter.type === "date_preset") {
      dateFilter = `.date_preset(${filter.value})`
    }

    let conversionAdCampaignIds = []
    let trafficAdCampaignIds = []
    let allAdCampaignIds = []

    enhCampaigns.forEach((enhCampaign) => {
      conversionAdCampaignIds.push(enhCampaign.adCampaign.id)
      allAdCampaignIds.push(enhCampaign.adCampaign.id)

      if (enhCampaign.trafficCampaign) {
        trafficAdCampaignIds.push(enhCampaign.trafficCampaign.campaign.id)
        allAdCampaignIds.push(enhCampaign.trafficCampaign.campaign.id)
      }

    })

    let creativeInfosForEnhCampaigns = []
    let filtering = `[{"field": "campaign.id","operator": "IN","value": [${allAdCampaignIds.join(",")}]}]`


    let url = `${facebookGraphUrl}${adAccountId}/adsets?filtering=${filtering}&fields=ads{name,effective_status,creative},name,campaign_id,effective_status,daily_budget,start_time,insights${dateFilter}{account_currency,clicks,cpm,cpc,impressions,reach,attribution_setting,spend,auction_bid,ctr,conversions,purchase_roas,conversion_values,actions,action_values,cost_per_conversion,cost_per_action_type}&access_token=${accessToken}`

    let allAdsets = await fetchWithPagination({ url })

    let response = {}

    allAdsets.forEach(adset => {
      let creative;

      let finalStatus = adset.effective_status
      if (finalStatus === "ACTIVE") {
        if (adset.ads?.data?.length) {
          let hasActiveAd = false
          adset.ads.data.forEach(ad => {
            creative = ad.creative
            if (ad.effective_status === "ACTIVE") {
              hasActiveAd = true
            }
          })
          if (!hasActiveAd) {
            finalStatus = `AD ${adset.ads.data[0].effective_status}`
          }
        } else {
          finalStatus = "NO ADS"
        }
      }

      // adds the adCreative to enhCampaign if missing
      let parentEnhCampaignIndex = conversionAdCampaignIds.indexOf(adset.campaign_id)
      if (conversionAdCampaignIds.includes(adset.campaign_id) && !enhCampaigns[parentEnhCampaignIndex].adCreatives && creative) {
        console.log("inside missing enh campaign", enhCampaigns[parentEnhCampaignIndex])
        let newAdCreativesOfEnhCampaign = {
          [creative.id]: {
            type: "Craft from Catalog",
            name: "Catalog #1",
            id: creative.id,
            body: {
              id: creative.id
            },
            details: {
              catalog: enhCampaigns[parentEnhCampaignIndex].catalog,
              productSet: enhCampaigns[parentEnhCampaignIndex].productSet,
              instagramAccount: enhCampaigns[parentEnhCampaignIndex].instagramAccount,
              facebookPage: enhCampaigns[parentEnhCampaignIndex].page,
              websiteUrl: enhCampaigns[parentEnhCampaignIndex].websiteUrl,
              primaryText: enhCampaigns[parentEnhCampaignIndex].primaryText,
            }
          }
        }
        enhCampaigns[parentEnhCampaignIndex] = {
          ...enhCampaigns[parentEnhCampaignIndex],
          adCreatives: newAdCreativesOfEnhCampaign
        }

        creativeInfosForEnhCampaigns.push({
          id: enhCampaigns[parentEnhCampaignIndex]._id,
          adCreatives: newAdCreativesOfEnhCampaign
        })
      }


      let adsetType = getAdsetType({ adsetName: adset.name })
      let obj = {}
      obj.campaignId = adset.campaign_id
      obj.status = adset.effective_status
      obj.adsetName = adset.name
      obj.adsetId = adset.id
      obj.startTime = (new Date(adset.start_time)).valueOf()
      obj.effectiveStatus = finalStatus
      obj.dailyBudget = parseInt(adset.daily_budget) / (currencyOffsets[facebook.selectedAdAccount?.currency] ?? 100)
      let insights = adset.insights.data[0]

      obj.dateStart = insights.date_start
      obj.dateEnd = insights.date_stop
      obj.spend = parseFloat(insights.spend)
      obj.reach = parseInt(insights.reach)
      obj.impressions = parseInt(insights.impressions)
      obj.frequency =
        parseInt(insights.impressions) / parseInt(insights.reach)
      obj.cpm = parseFloat(insights.cpm)
      obj.clicks = parseInt(insights.clicks)
      obj.cpc = parseFloat(insights.cpc)
      obj.ctr = parseFloat(insights.ctr)
      obj.addToCart = insights.actions
        ? parseInt(helper.findBy(insights.actions, 'action_type', 'add_to_cart')?.value)
        : 0
      obj.purchases = insights.actions
        ? parseInt(helper.findBy(insights.actions, 'action_type', 'omni_purchase')?.value)
        : 0

      obj.sales = 0
      obj.costPerPurchase = 0

      if (insights.action_values) {
        obj.sales = parseInt(
          helper.findBy(insights.action_values, 'action_type', 'omni_purchase')?.value || '-',
        )
      }

      if (insights.cost_per_action_type) {
        obj.costPerPurchase = parseInt(
          helper.findBy(insights.cost_per_action_type, 'action_type', 'omni_purchase')?.value || '-',
        )
      }

      obj.cpp = parseFloat(insights.cpp)
      obj.roas = parseFloat(
        insights.purchase_roas?.length > 0
          ? insights.purchase_roas[0].value
          : '-',
      )
      response[adset.campaign_id] = {
        ...response[adset.campaign_id],
        [adsetType]: obj
      }

    })

    if (creativeInfosForEnhCampaigns.length) addMissingCreativeInfoToDb({ creativeInfosForEnhCampaigns, fbInfoId: currentFacebookInfo._id })

    console.log("ill return the response ")
    console.log(response)
    return response
  }

  const getAdsetType = ({ adsetName }) => {
    switch (adsetName) {
      case "Enhencer Remarketing":
        return "remarketing"
      case "Enhencer Broad":
        return "broad"
      case "Enhencer Lookalike":
        return "lookalike"
      case "Enhencer Traffic":
        return "traffic"
      default:
        return adsetName;
    }
  }

  const getAdAccounts = async ({
    userId,
    accessToken,
    selectedAdAccountId,
    freshFetch = false,
  }) => {
    let url = `${facebookGraphUrl}${userId}/adaccounts?fields=name,account_id,currency,business,tos_accepted&access_token=${accessToken}`
    let adAccounts = []/* 
    if (!freshFetch && sessionStorage.getItem(`fb_adaccounts`)) {
      adAccounts = JSON.parse(
        sessionStorage.getItem(`fb_adaccounts`),
      )
      dispatch(
        facebookActions.updateInfo({
          adAccounts: adAccounts,
          adAccountSelectionIsEligible: adAccounts
            .map(act => act.id)
            .includes(selectedAdAccountId),
        }),
      )
      dispatch(facebookActions.stopLoading({ field: "adAccounts" }))
      return {
        adAccounts: adAccounts,
        adAccountSelectionIsEligible: adAccounts
          .map(act => act.id)
          .includes(selectedAdAccountId),
      }
    } */

    dispatch(facebookActions.startLoading({ field: "adAccounts" }))
    const next = async nextUrl => {
      await sendRequest(nextUrl, 'GET')
        .then(res => {
          if (res.error) {
            throw res.error
          } else if (res.paging?.next) {
            adAccounts = adAccounts.concat(res.data)
            next(res.paging?.next)
          } else {
            adAccounts = adAccounts.concat(res.data)
            dispatch(facebookActions.stopLoading({ field: "adAccounts" }))
            /* sessionStorage.setItem('fb_adaccounts', JSON.stringify(adAccounts)) */
            dispatch(
              facebookActions.updateInfo({
                adAccounts: adAccounts,
                adAccountSelectionIsEligible: adAccounts
                  .map(act => act.id)
                  .includes(selectedAdAccountId),
              }),
            )
            return {
              adAccounts: adAccounts,
              adAccountSelectionIsEligible: adAccounts
                .map(act => act.id)
                .includes(selectedAdAccountId),
            }
          }
        })
        .catch(err => {
          throw err
        })
    }

    await next(url)
    return true
  }

  const getBusinesses = async ({ userId, accessToken, selectedBusinessId, freshFetch = false }) => {
    let url = `${facebookGraphUrl}${userId}/businesses?access_token=&access_token=${accessToken}`
    let businesses = []
    /* if (!freshFetch && sessionStorage.getItem(`fb_businesses`)) {
      businesses = JSON.parse(
        sessionStorage.getItem(`fb_businesses`),
      )
      dispatch(
        facebookActions.updateInfo({
          businesses,
          businessSelectionIsEligible: businesses
            .map(b => b.id)
            .includes(selectedBusinessId),
        }),
      )
      dispatch(facebookActions.stopLoading({ field: "businesses" }))
      return {
        businesses,
        businessSelectionIsEligible: businesses
          .map(b => b.id)
          .includes(selectedBusinessId),
      }
    } */
    dispatch(facebookActions.startLoading({ field: "businesses" }))
    const next = async nextUrl => {
      await sendRequest(nextUrl, 'GET')
        .then(res => {
          if (res.error) {
            throw res.error
          } else if (res.paging?.next) {
            businesses = businesses.concat(res.data)
            next(res.paging?.next)
          } else {
            businesses = businesses.concat(res.data)
            dispatch(
              facebookActions.updateInfo({
                businesses,
                businessSelectionIsEligible: businesses
                  .map(b => b.id)
                  .includes(selectedBusinessId),
              }),
            )
            dispatch(facebookActions.stopLoading({ field: "businesses" }))
            /* sessionStorage.setItem('fb_businesses', JSON.stringify(businesses)) */
            return {
              businesses,
              businessSelectionIsEligible: businesses
                .map(b => b.id)
                .includes(selectedBusinessId),
            }
          }
        })
        .catch(err => {
          throw err
        })
    }

    await next(url)
  }

  const getPixelsOfAdAccount = async ({
    adAccountId,
    accessToken,
    selectedPixelId,
  }) => {
    //accessToken = 'EAADZA7gZBdvh4BO5JZAZARCgFmgmB5DxLqATtOwGPQZAOtaZCMyCmJrAKTqo9VZCGQ3ZC13F46bcuxHGTUZCJBUVR7mhSDPxV6v8aVpTSl4E5wCaHvCDIXDsmjyO6InGzddpZCbYhOyCnr9OSpVZAA32bcddnCZA4kcTmbwu6tRvtgPJA9fSVPVXjZB4205A3dFIH88kUppBuYYs9ooROHdtbZCgZDZD';
    let url = `${facebookGraphUrl}${adAccountId}/adspixels?fields=name,is_unavailable,last_fired_time&access_token=${accessToken}`
    try {
      dispatch(facebookActions.startLoading({ field: "pixels" }))
      let result = await sendRequest(url, 'GET')
      let pixels = result.data
      dispatch(
        facebookActions.updateInfo({
          pixels: pixels,
          pixelSelectionIsEligible: pixels
            .map(p => p.id)
            .includes(selectedPixelId),
        }),
      )
      dispatch(facebookActions.stopLoading({ field: "pixels" }))
      return {
        pixels: pixels,
        pixelSelectionIsEligible: pixels
          .map(p => p.id)
          .includes(selectedPixelId),
      }
    } catch (err) {
      throw err
    }
  }

  const selectAdAccount = async (account, isCheckup) => {
    dispatch(facebookActions.adAccountSelected(account))
    let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/adAccountSelected`
    let result = await sendRequest(
      url,
      'POST',
      JSON.stringify({
        userId: user.id,
        adAccount: account,
        accessToken: facebook.auth.accessToken,
        isCheckup: isCheckup
      }),
      authHeader,
    )

    dispatch(facebookActions.updateInfo({
      initialOverallReport: result.overallReportSnapshot,
      settings: {
        ...facebook.settings,
        minDailyBudget: result.minDailyBudgetForAdAccountCurrency
      }
    }))

    if (account) {
      getPixelsOfAdAccount({
        adAccountId: account.id,
        accessToken: facebook.auth.accessToken,
        selectedPixelId: facebook.selectedPixel?.id
      })

      if (facebook.enhCampaigns?.length) {
        await fetchReportsForCampaigns({
          fb: {
            ...facebook,
            selectedAdAccount: account
          },
          isInitialFetch: true
        })
      }
    }
    return true
  }

  const logOut = async forced => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/logout`,
        'POST',
        JSON.stringify({
          userId: user.id,
        }),
        authHeader,
      )
      if (!forced) toast.success('Logged out successfully!')
      dispatch(facebookActions.logOut())
    } catch (err) {
      toast.error(err?.message || 'Something went wrong')
    }
  }

  const selectBusiness = business => {
    let url

    dispatch(facebookActions.businessSelected(business))
    url = `${process.env.REACT_APP_BACKEND_URL}/facebook/businessSelected`

    sendRequest(
      url,
      'POST',
      JSON.stringify({
        userId: user.id,
        business: business,
      }),
      authHeader,
    )
    /* getCatalogs({
      businessId: business.id,
      accessToken: facebook.auth.accessToken,
    }) */
    // getBusinessOwnedPages({ businessId: business.id, accessToken: facebook.auth.accessToken })
  }

  const selectPixel = async (pixel, isCheckup) => {
    if (pixel == null || (!pixel.is_unavailable && pixel.last_fired_time)) {
      let url;

      url = `${process.env.REACT_APP_BACKEND_URL}/facebook/pixelSelected`

      try {
        await sendRequest(
          url,
          'POST',
          JSON.stringify({
            userId: user.id,
            pixel: pixel,
            isCheckup: isCheckup
          }),
          authHeader,
        )

        dispatch(
          facebookActions.pixelSelected({
            pixel,
          }),
        )

        if (pixel && !isCheckup) {
          await helper.timeout(1000)
          createAudiencesForTheFirstTime({ pixel })
        }

      } catch (err) {
        toast.warn(err?.message || "Could not select pixel. Prevented by Facebook.")
      }
    } else {
      toast.warn("Could not select pixel. Prevented by Facebook.")
    }
  }


  const openAudienceNotificationModal = ({ audiencesResult, selectedPixelId }) => {
    let modalConfig;
    const adAccountAudiences = audiencesResult.audiences[facebook.selectedAdAccount.id]

    const { an, remarketing, lookalikeSeed } = adAccountAudiences
    let pixelFilteredRemarketing = remarketing.filter(a => a.pixelId === selectedPixelId)
    let pixelFilteredLookalikeSeed = lookalikeSeed.filter(a => a.pixelId === selectedPixelId)

    for (var index in pixelFilteredRemarketing) {
      //check if there is an error in remarketing
      if (pixelFilteredRemarketing[index].error?.code) {
        let message = pixelFilteredRemarketing[index].error.error_user_msg ? pixelFilteredRemarketing[index].error.error_user_msg : pixelFilteredRemarketing[index].error.message

        if (pixelFilteredRemarketing[index].error?.code == 2663) {
          let urlIndex = message.indexOf("https://")
          let url = message.substring(urlIndex)
          let messageText = message.substring(0, urlIndex)
          message = <span>
            {messageText}
            <Link to={url}>Facebook Audience Manager</Link>
          </span>
        }

        modalConfig = {
          title: 'Audience Creation Prevented by Facebook',
          imageUrl:
            'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
          text: message,
          actions: [
            {
              text: 'Try with different configuration',
              callback() {
                dispatch(facebookActions.businessSelected(undefined))
                navigate('/config?io=3')
              },
            },
            {
              text: 'Report issue to support team',
              callback() {
                reportIssueToTheTeam({
                  sendRequest,
                  authToken,
                  contactEmail: user.crmDetails.contactEmail,
                  performanceManager: user.crmDetails.performanceManager,
                  audienceType: 'Remarketing',
                  errorToDisplay: JSON.stringify(pixelFilteredRemarketing[index].error)
                })
              },
            },
          ],
        }
      }
    }
    if (!modalConfig) {
      for (var index in pixelFilteredLookalikeSeed) {
        //check if there is an error in lookalike

        if (pixelFilteredLookalikeSeed[index].error?.code) {
          modalConfig = {
            title: 'Audience Creation Prevented by Facebook',
            imageUrl:
              'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
            text: `${pixelFilteredLookalikeSeed[index].error.error_user_msg
              ? pixelFilteredLookalikeSeed[index].error.error_user_msg
              : pixelFilteredLookalikeSeed[index].error.message
              }`,
            actions: [
              {
                text: 'Try with different configuration',
                callback() {
                  dispatch(facebookActions.businessSelected(undefined))
                  navigate('/config?io=3')
                },
              },
              {
                text: 'Report issue to support team',
                callback() {
                  reportIssueToTheTeam({
                    sendRequest,
                    authToken,
                    contactEmail: user.crmDetails.contactEmail,
                    performanceManager: user.crmDetails.performanceManager,
                    audienceType: 'LookalikeSeed',
                    errorToDisplay: JSON.stringify(pixelFilteredLookalikeSeed[index].error)
                  })
                },
              },
            ],
          }
        }
      }
    }

    if (!modalConfig) {
      for (var index in an) {
        //check if there is an error in an

        if (an[index].error?.code) {
          let text =
            an[index].error.error_subcode == 1885325
              ? <React.Fragment>
                Enhencer could not share a custom audience that is made special for you,
                since the selected Ad Account is not owned by a Meta Business.<br /><br />
                <span style={{ fontSize: '13px' }}>
                  <a href={`https://business.facebook.com/settings/ad-accounts?business_id=${facebook.selectedBusiness.id}`}>Click here</a>
                  {' '}to go to Meta Business Manager
                </span>
              </React.Fragment>
              : `${an[index].error.error_user_msg
                ? an[index].error.error_user_msg
                : an[index].error.message
              }`
          modalConfig = {
            title: 'Audience Creation Prevented by Facebook',
            imageUrl:
              'https://cdn.enhencer.com/website-assets/images/icon-set/warn.svg',
            text: text,

            actions: [
              {
                text: 'Try with different configuration',
                callback() {
                  dispatch(facebookActions.businessSelected(undefined))
                  navigate('/config?io=3')
                },
              },
              {
                text: 'Report issue to support team',
                callback() {
                  reportIssueToTheTeam({
                    sendRequest,
                    authToken,
                    contactEmail: user.crmDetails.contactEmail,
                    performanceManager: user.crmDetails.performanceManager,
                    audienceType: 'Audience Network',
                    errorToDisplay: JSON.stringify(an[index].error)
                  })
                },
              },
            ],
          }
          break;
        }
      }

    }

    if (!modalConfig && audiencesResult.message.toLowerCase().includes('success')) {
      analytics.completeConnections()
      setConnectFlowStep({ step: "completedFacebookConnections" })
      modalConfig = {
        title: 'Success',
        imageUrl:
          'https://cdn.enhencer.com/website-assets/images/icon-set/success.png',
        text: 'Audiences created successfully!!',
        actions: [
          {
            text: 'Go to campaigns',
            callback() {
              navigate('/ai-ads')
            },
          },
        ],
      }
    }

    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
    })
  }


  const createAudiencesForTheFirstTime = async ({ pixel = facebook.selectedPixel }) => {

    dispatch(facebookActions.creatingAudiences(true))

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/createAudiencesForTheFirstTime`
      let audiencesResult = await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: user.id,
          accessToken: facebook.auth.accessToken,
          selectedAdAccount: facebook.selectedAdAccount,
          pixel,
          fbAudiences: facebook.audiences,
          country: user.country
        }),
        authHeader,
      )

      dispatch(facebookActions.creatingAudiences(false))
      checkForAudienceErrors({ audiences: audiencesResult.audiences })

      dispatch(facebookActions.updateInfo({ audiences: audiencesResult.audiences }))

      dispatch(facebookActions.creatingAudiences(false))
    } catch (err) {
      errorHandler(err)
      closeModal()
    } finally {
      dispatch(facebookActions.creatingAudiences(false))
    }

    return true
  }

  const retryAudienceCreation = async () => {

    dispatch(facebookActions.creatingAudiences(true))

    const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/retryAudienceCreation`
    try {
      let audiencesResult = await sendRequest(
        url,
        'POST',
        JSON.stringify({
          userId: user.id,
          accessToken: facebook.auth.accessToken,
          adAccount: facebook.selectedAdAccount,
          pixel: facebook.selectedPixel,
          currentAudiences: facebook.audiences,
          country: user.country
        }),
        authHeader,
      )


      dispatch(facebookActions.creatingAudiences(false))

      checkForAudienceErrors({ audiences: audiencesResult.audiences })
      dispatch(facebookActions.updateInfo({ audiences: audiencesResult.audiences }))

    } catch (err) {
      errorHandler(err)
      closeModal()
    }

    return true
  }

  const getCatalogs = async ({ businessId = facebook.selectedBusiness?.id, accessToken = facebook.auth.accessToken }) => {
    console.log("will get catalogs")
    let url = `${facebookGraphUrl}${businessId}/owned_product_catalogs?fields=name,product_count&access_token=${accessToken}`
    let catalogs = await fetchWithPagination({ url })
    catalogs = catalogs.filter(a => a.product_count > 0)
    dispatch(facebookActions.updateInfo({ catalogs: catalogs }))

    return catalogs
  }

  const getProductSetsOfCatalog = async ({ catalogId, accessToken = facebook.auth.accessToken }) => {
    let url = `${facebookGraphUrl}${catalogId}/product_sets?fields=name,product_count&access_token=${accessToken}`
    let productSets = await fetchWithPagination({ url })
    let allProductsIndex = helper.findIndex(productSets, 'name', 'All products')
    if (allProductsIndex !== 0) {
      let allProductsSet = productSets[allProductsIndex]
      productSets.unshift(allProductsSet)
      productSets.splice(allProductsIndex + 1, 1)
    }


    dispatch(facebookActions.updateInfo({
      productSets: {
        ...facebook.productSets,
        [catalogId]: productSets
      }
    }))
    return productSets
  }

  const selectProductSet = ({ productSet }) => {
    let productSetId = productSet
      .substring(productSet.indexOf('ID: ') + 3, productSet.lastIndexOf(')'))
      .trim()
  }

  const getFacebookPages = async ({ facebookUserId, accessToken = facebook.auth.accessToken }) => {
    let url = `${facebookGraphUrl}${facebookUserId}/accounts?access_token=${accessToken}`
    let pages = await fetchWithPagination({ url })
    pages.forEach(page => {
      page.profile_pic = `https://graph.facebook.com/v17.0/${page.id}/picture`
    })
    dispatch(facebookActions.updateInfo({ pages }))

    return pages
  }

  const selectFacebookPage = async ({ page }) => {

    let result = await getInstagramAccountsForPage({
      pageId: page.id,
      pageAccessToken: page.access_token,
    })
  }

  const getInstagramAccountsForPage = async ({ pageId, pageAccessToken }) => {
    let url = `${facebookGraphUrl}${pageId}/instagram_accounts?fields=username,profile_pic&access_token=${pageAccessToken}`
    let accounts = await fetchWithPagination({ url })
    dispatch(facebookActions.updateInfo({
      instagramAccounts: {
        ...facebook.instagramAccounts,
        [pageId]: accounts
      }
    }))

    return accounts
  }

  const createCampaign = async ({
    label,
    dailyBudget,
    age,
    countries,
    countryCodes,
    genders,
    adCreatives,
    shouldRecreateCampaign,
    campaignIdToBeRemoved,
    trafficCampaign
  }) => {


    if (shouldRecreateCampaign) {
      let deleteResult = await deleteCampaign({
        enhCampaignId: '',
        adCampaignId: campaignIdToBeRemoved,
        isBackgroundTask: true,
      })
    }

    let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/createEnhencerCampaign`

    let res = await sendRequest(
      url,
      'POST',
      JSON.stringify({
        userId: user.id,
        country: user.country,
        userEnhencerCategories: user.enhencerCategories,
        label,
        accessToken: facebook.auth.accessToken,
        adAccountId: facebook.selectedAdAccount.id,
        businessName: facebook.selectedBusiness.id,
        dailyBudget,
        age,
        countries,
        countryCodes,
        audiences: facebook.audiences,
        adCreatives,
        genders, // 1-male, 2-female, default: [1, 2]]
        trafficCampaign
      }),
      authHeader,
    )
    let adsetValues = Object.values(res.enhCampaign.adsets)
    for (var i = 0; i < adsetValues.length; i++) {
      let adsetDetail = adsetValues[i]

      if (adsetDetail.error?.error_subcode === 1885272) {
        let regex = /[+-]?\d+(\.\d+)?/g
        let floats = adsetDetail.error.error_user_msg
          .match(regex)
          .map(a => parseFloat(a))
        let minBudget = 10 * (floats[0] + 1)
        dispatch(facebookActions.updateInfo({ minDailyBudget: minBudget }))

        let title = 'Daily budget too low'
        let message = `Your daily buget is too low. Enhencer needs at least ${facebook.selectedAdAccount.currency}${minBudget} daily budget to run campaigns.`

        const modalConfig = {
          title: title,
          imageUrl:
            'https://cdn.enhencer.com/website-assets/images/icon-set/error.png',
          text: message,
          actions: [
            {
              text: 'Update budget',
            },
          ],
        }
        openModal({
          type: Modals.GENERIC_MODAL,
          data: modalConfig,
        })

        return {
          shouldRecreate: true,
          adCampaignId: res.enhCampaign.adCampaign?.id,
        }
      }
    }

    if (!facebook.enhCampaigns.length) {
      analytics.createFirstFullFunnelCampaign()
      setConnectFlowStep({ step: "createdFullFunnelCampaign" })
    }

    await fetchReportsForSingleCampaign({
      campaign: res.enhCampaign,
      isUpdate: false,
      fetchAdCampaignDetails: true
    })

    return {
      hasError: false,
      enhCampaign: res.enhCampaign
    }
  }

  const updateCampaign = async ({
    enhCampaignId,
    campaignName,
    label,
    dailyBudget,
    age,
    countries,
    countryCodes,
    genders,
    adCampaign,
    adsets,
    ads,
    adCreatives,

    trafficCampaign
  }) => {
    console.log("ad creatives ", adCreatives)
    let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/updateEnhencerCampaign`

    let res = await sendRequest(
      url,
      'POST',
      JSON.stringify({
        enhCampaignId,
        campaignName,
        userId: user.id,
        userEnhencerCategories: user.enhencerCategories,
        label,
        accessToken: facebook.auth.accessToken,
        adAccountId: facebook.selectedAdAccount.id,
        dailyBudget,
        age,
        genders, // 1-male, 2-female, default: [1, 2]]
        countries,
        countryCodes,
        adCampaign,
        adsets,
        ads,
        adCreatives,

        trafficCampaign
      }),
      authHeader,
    )
    dispatch(
      facebookActions.campaignUpdated({
        campaign: res.enhCampaign,
        id: enhCampaignId,
        isInView: true
      }),
    )

    return true
  }

  const updateCampaignStatus = async ({ label, enhCampaignId, adCampaign, status, trafficCampaign }) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/updateEnhencerCampaign`

    let res = await sendRequest(
      url,
      'POST',
      JSON.stringify({
        userId: user._id || user.id,
        accessToken: facebook.auth.accessToken,
        enhCampaignId,
        adCampaign,
        trafficCampaign,
        status: status,
        label
      }),
      authHeader,
    )

    if (res.error) {
      let modalConfig = {
        title: res.error.error_user_title || 'Error Occured',
        imageUrl:
          'https://cdn.enhencer.com/website-assets/images/icon-set/error.png',
        text: res.error.error_subcode === 1487566 ? 'This campaign has been deleted, so you can only edit the name.' : `${res.error.error_user_msg
          ? res.error.error_user_msg
          : res.error.message
          }`,
        actions: [
          {
            text: 'Close',
            callback() { },
          },
        ],
      }
      openModal({
        type: Modals.GENERIC_MODAL,
        data: modalConfig,
      })
      return false
    } else {
      fetchReportsForSingleCampaign({
        campaign: res.enhCampaign,
        isUpdate: true,
        enhCampaignId,
        fetchAdCampaignDetails: true
      })
      return true
    }


  }


  const deleteCampaign = async ({
    enhCampaignId,
    adCampaignId,
    isBackgroundTask,
    trafficCampaignId
  }) => {
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/removeEnhencerCampaign`,
        'POST',
        JSON.stringify({
          userId: user.id,
          adCampaignId,
          enhCampaignId,
          accessToken: facebook.auth.accessToken,
          trafficCampaignId
        }),
        authHeader,
      )

      dispatch(facebookActions.campaignDeleted(enhCampaignId))
      if (!isBackgroundTask) {
        toast.success('Campaign deleted successfully!')
      }
    } catch (err) {
      handleError(err)
    }
  }

  const newCampaignSelectedForReport = async ({ campaign }) => {
    dispatch(facebookActions.setActiveEnhCampaignIdForReports(campaign._id))
  }

  const newFilterSelectedForReport = async ({ filter, campaign }) => {
    if (!campaign.reports.adsetLevel[filter.value]) {
      await fetchReportsForCampaigns({ filter })
    }
    dispatch(facebookActions.setReportsFilter(filter))
  }


  const fetchReportsForSingleCampaign = async ({ campaign, isUpdate, enhCampaignId, filter = { type: "date_preset", value: "last_14d" }, fetchAdCampaignDetails = false }) => {
    dispatch(facebookActions.fetchingReports(true))
    let newCampaign = {
      ...campaign,
    }
    if (!campaign.reports) {
      newCampaign.reports = {}
    }

    let promises = []

    if (
      !newCampaign.apiErrors ||
      (!newCampaign.apiErrors.campaign &&
        !newCampaign.apiErrors.adset &&
        !newCampaign.apiErrors.ad)
    ) {
      promises.push(
        getCampaignDetails({
          campaignId: newCampaign.adCampaign.id,
          filter,
          adsets: newCampaign.adsets,
          accessToken: facebook.auth.accessToken,
          fetchAdCampaignDetails
        })
          .then(result => {
            newCampaign.reports = {
              ...newCampaign.reports,
              adsetLevel: {
                ...newCampaign.reports.adsetLevel,
                [filter.value]: result.adsetResult
              }
            }
            if (fetchAdCampaignDetails) {
              newCampaign.reports.campaignLevel = { ...result.campaignResult, effectiveStatus: result.campaignResult.effective_status }
            }
          })
          .catch(err => {
            console.log('campaign details error', err)
          }),
      )
    } else {
      console.log('there is an error in campaign', newCampaign.apiErrors)
    }

    try {
      let result = await Promise.all(promises)
      if (isUpdate) {
        dispatch(
          facebookActions.campaignUpdated({
            campaign: newCampaign,
            id: enhCampaignId || campaign._id,
          }),
        )
        dispatch(facebookActions.fetchingReports(false))
        return newCampaign
      } else {
        dispatch(facebookActions.newCampaignAdded(newCampaign))
        dispatch(facebookActions.fetchingReports(false))
        return newCampaign
      }

    } catch (err) {
      handleError(err)
      dispatch(facebookActions.fetchingReports(false))
    }
  }

  const getOverallAdsetBasedReportForEnhCampaigns = async ({ filter = facebook?.reportsFilter }) => {
    if (filter && facebook.overallReportForEnhCampaigns[filter?.value]) {
      return
    } else if (filter && !facebook.overallReportForEnhCampaigns[filter?.value] && facebook.enhCampaigns[0].reports.adsetLevel[filter?.value]) {
      calculateAndUpdateAdsetBasedOverallReportForEnhCampaigns({ filter: filter })
    } else {
      fetchReportsForCampaigns({ filter })
    }
    dispatch(facebookActions.setActiveEnhCampaignIdForReports("allEnhCampaigns"))

    dispatch(facebookActions.setReportsFilter(filter))
  }


  const calculateAndUpdateAdsetBasedOverallReportForEnhCampaigns = ({ enhCampaigns = facebook.enhCampaigns, filter, isInitialFetch }) => {
    let adsetTypes = ["remarketing", "broad", "lookalike", "traffic"]
    let cumulativeParams = ["spend", "dailyBudget", "reach", "impressions", "clicks", "addToCart", "purchases", "sales"]

    let emptySnap = {
      status: 'AD SETS INACTIVE',
      effectiveStatus: 'AD SETS INACTIVE',
      startTime: 0,
      dateStart: null,
      dateEnd: null,
      dailyBudget: 0,
      spend: 0,
      reach: 0,
      impressions: 0,
      frequency: null,
      cpm: null,
      clicks: 0,
      cpc: null,
      ctr: null,
      addToCart: 0,
      purchases: 0,
      sales: 0,
      costPerPurchase: null,
      roas: null
    }

    let all = {
      remarketing: { ...emptySnap },
      broad: { ...emptySnap },
      lookalike: { ...emptySnap },
      traffic: { ...emptySnap },
      custom: { ...emptySnap },
    }

    let hasAtLeastOneActiveAdset = false
    let hasAdsets = {
      remarketing: false,
      broad: false,
      lookalike: false,
      traffic: false,
      custom: false,
    }

    enhCampaigns.forEach(enhCampaign => {
      let adsetLevelReport = enhCampaign.reports.adsetLevel[filter.value]
      if (adsetLevelReport) {
        let adsetNames = Object.keys(adsetLevelReport)

        adsetNames.forEach(name => {
          let adsetType = (() => {

            switch (name) {
              case "remarketing":
              case "broad":
              case "lookalike":
              case "traffic":
                return name
              default:
                return "custom"
            }
          })()
          hasAdsets[adsetType] = true

          let campaignStatus = enhCampaign.reports.campaignLevel.effectiveStatus
          if (campaignStatus === 'ACTIVE' && adsetLevelReport[name]?.effectiveStatus === 'ACTIVE') {
            hasAtLeastOneActiveAdset = true
            all[adsetType].effectiveStatus = 'ACTIVE'
            all[adsetType].status = 'ACTIVE'
          }

          cumulativeParams.forEach(param => {
            if (adsetLevelReport[name]?.[param]) {
              all[adsetType][param] += adsetLevelReport[name][param]
            }
          })

          if (adsetLevelReport[name]?.startTime && (!all[adsetType].startTime || all[adsetType].startTime > adsetLevelReport[name]?.startTime)) {
            all[adsetType].startTime = adsetLevelReport[name].startTime
          }

          if (adsetLevelReport[name]?.dateStart) {
            all[adsetType].dateStart = adsetLevelReport[name].dateStart
          }

          if (adsetLevelReport[name]?.dateEnd) {
            all[adsetType].dateEnd = adsetLevelReport[name].dateEnd
          }

        })
      }

    })

    adsetTypes.forEach(adsetType => {
      all[adsetType].roas = all[adsetType].spend ? all[adsetType].sales / all[adsetType].spend : 0
      all[adsetType].cpc = all[adsetType].clicks ? all[adsetType].spend / all[adsetType].clicks : 0
      all[adsetType].cpm = all[adsetType].impressions ? all[adsetType].spend / all[adsetType].impressions * 1000 : 0
      all[adsetType].costPerPurchase = all[adsetType].purchases ? all[adsetType].spend / all[adsetType].purchases : 0
      all[adsetType].ctr = all[adsetType].impressions ? (all[adsetType].clicks / all[adsetType].impressions * 100) : 0
      all[adsetType].frequency = all[adsetType].reach ? all[adsetType].impressions / all[adsetType].reach : 0
    })

    Object.keys(all).forEach(key => {
      if (!hasAdsets[key]) {
        delete all[key]
      }
    })

    dispatch(facebookActions.updateOverallReportForEnhCampaigns({
      hasAdset: hasAtLeastOneActiveAdset,
      adsetLevel: {
        ...facebook.overallReportForEnhCampaigns.adsetLevel,
        [filter.value]: all
      }
    }))
    if (isInitialFetch) dispatch(facebookActions.setActiveEnhCampaignIdForReports("allEnhCampaigns"))


    return all
  }

  const getCampaignDetails = async ({
    campaignId,
    filter,
    adsets,
    accessToken,
    fetchAdCampaignDetails
  }) => {
    let campaignDetailsUrl = `${facebookGraphUrl}${campaignId}?fields=name,effective_status&access_token=${accessToken}`

    let promises = [
      getAdsetInsights({ campaignId, filter, adsets, accessToken }),
    ]
    if (fetchAdCampaignDetails) {
      promises.push(sendRequest(campaignDetailsUrl, 'get'))
    }

    let result = await Promise.all(promises)

    let [adsetResult, campaignResult] = result
    return {
      campaignResult,
      adsetResult,
    }
  }

  const getAdsetInsights = async ({
    campaignId,
    filter,
    adsets,
    accessToken,
  }) => {

    try {
      let response = {}
      let adsetTypeList = {}
      Object.entries(adsets).forEach(entry => {
        adsetTypeList[entry[1].id] = entry[0]
      })

      let dateFilter = ''

      if (filter.type === "time_range") {
        dateFilter = `.time_range(${filter.value})`
      } else if (filter.type === "date_preset") {
        dateFilter = `.date_preset(${filter.value})`
      }

      let url = `${facebookGraphUrl}${campaignId}/adsets?fields=name,campaign_id,effective_status,daily_budget,start_time,insights${dateFilter}{account_currency,clicks,cpm,cpc,impressions,reach,attribution_setting,spend,auction_bid,ctr,conversions,purchase_roas,conversion_values,actions,action_values,cost_per_conversion,cost_per_action_type}&access_token=${accessToken}`
      let res = await sendRequest(url, 'get')
      // let res = adsetResult;
      let results = res.data
      results.forEach(adset => {
        let adsetType = adsetTypeList[adset.id]
        let obj = {}
        obj.status = adset.effective_status
        obj.adsetName = adset.name
        obj.adsetId = adset.id
        obj.startTime = (new Date(adset.start_time)).valueOf()
        obj.effectiveStatus = adset.effective_status
        obj.dailyBudget = parseInt(adset.daily_budget) / (currencyOffsets[facebook.selectedAdAccount.currency] ?? 100)
        let insights = adset.insights.data[0]

        obj.dateStart = insights.date_start
        obj.dateEnd = insights.date_stop
        obj.spend = parseFloat(insights.spend)
        obj.reach = parseInt(insights.reach)
        obj.impressions = parseInt(insights.impressions)
        obj.frequency =
          parseInt(insights.impressions) / parseInt(insights.reach)
        obj.cpm = parseFloat(insights.cpm)
        obj.clicks = parseInt(insights.clicks)
        obj.cpc = parseFloat(insights.cpc)
        obj.ctr = parseFloat(insights.ctr)
        obj.addToCart = insights.actions
          ? parseInt(helper.findBy(insights.actions, 'action_type', 'add_to_cart')?.value)
          : 0
        obj.purchases = insights.actions
          ? parseInt(helper.findBy(insights.actions, 'action_type', 'omni_purchase')?.value)
          : 0

        obj.sales = 0
        obj.costPerPurchase = 0

        if (insights.action_values) {
          obj.sales = parseInt(
            helper.findBy(
              insights.action_values,
              'action_type',
              'omni_purchase',
            )?.value || '-',
          )
        }

        if (insights.cost_per_action_type) {
          obj.costPerPurchase = parseInt(
            helper.findBy(insights.cost_per_action_type, 'action_type', 'omni_purchase')?.value || '-',
          )
        }

        obj.cpp = parseFloat(insights.cpp)
        obj.roas = parseFloat(
          insights.purchase_roas?.length > 0
            ? insights.purchase_roas[0].value
            : '-',
        )
        response[adsetType] = obj
      })

      return response
    } catch (err) {
      handleError(err)
      if (err.code === 100) {
        toast.warn('Facebook permission error while getting insights.')
      }
      throw err
    }
  }

  /**
   * TODO: We need to update the daily report with the following structure on the backend:
   * enhCampaignsOverall: {
   *   remarketing,
   *   lookalike,
   *   broad,
   *   custom,
   *   all
   * }
   * Since it is not ready yet on the backend, we are temporarily returning true from this updateDailyReport function.
   */
  const updateDailyReport = async ({ overallReportByAdsets, allEnhAdsets, userId, adAccountId, datePreset }) => {

    try {
      let formattedAdsets = allEnhAdsets.map(adset => {
        const {
          dateStart,
          dateEnd,
          spend,
          reach,
          impressions,
          frequency,
          cpm,
          clicks,
          cpc,
          ctr,
          addToCart,
          purchases,
          sales,
          costPerPurchase,
          cpp,
          roas,
        } = adset
        return {
          campaignId: adset.campaignId,
          status: adset.status,
          adsetName: adset.adsetName,
          adsetId: adset.adsetId,
          startTime: adset.startTime,
          effectiveStatus: adset.effectiveStatus,
          dailyBudget: adset.dailyBudget,
          insights: [{
            dateStart,
            dateEnd,
            datePreset,
            spend,
            reach,
            impressions,
            frequency,
            cpm,
            clicks,
            cpc,
            ctr,
            addToCart,
            purchases,
            sales,
            costPerPurchase,
            cpp,
            roas,
          }]

        }
      })

      const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/updateDailyReport`

      const result = await sendRequest(
        url,
        'POST',
        JSON.stringify({ allEnhAdsets: formattedAdsets, overallReportByAdsets, userId, adAccountId, datePreset }),
        authHeader,
      )

      /* if (user.isSpecialUser) {
        dispatch(adminActions.updateCustomerDailyReport({
          id: userId,
          reportSnapshot: result.data.enhCampaignsOverall
        }))
      } */
    } catch (err) {
      throw err
    }
  }

  const addMissingCreativeInfoToDb = async ({ creativeInfosForEnhCampaigns, fbInfoId }) => {
    console.log("missing ", fbInfoId, creativeInfosForEnhCampaigns)
    try {

      const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/updateMissingCreativeInfoInDb`

      const result = await sendRequest(
        url,
        'POST',
        JSON.stringify({ facebookInfoId: fbInfoId, creativeInfosForEnhCampaigns }),
        authHeader,
      )

    } catch (err) {
      throw err
    }
  }

  const createAdCreativeWithCatalog = async ({
    productSet,
    facebookPage,
    instagramAccount,
    primaryText,
    campaignName,
    websiteUrl }) => {


    let utms = `utm_source=Enhencer&utm_medium=Enhencer_Meta_CPC&utm_campaign=${campaignName}`
    let url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/adcreatives?access_token=${facebook.auth.accessToken}`
    let body = {
      "name": `enh_adcrtv_${productSet.id}`,
      "product_set_id": productSet.id,
      "object_type": "SHARE",
      "object_story_spec": {
        "page_id": facebookPage.id,
        "instagram_actor_id": instagramAccount.id,
        "template_data": {
          "multi_share_end_card": "true",
          "name": "{{product.name}}",
          "link": `${websiteUrl}?${utms}`,
          "message": primaryText, // this is primary text
          "call_to_action": {
            "type": "SHOP_NOW"
          }
        }
      },
      "asset_feed_spec": {
        "optimization_type": "FORMAT_AUTOMATION",
        "ad_formats": [
          "CAROUSEL",
          "COLLECTION"
        ]
      },
      "descriptions": [
        {
          "text": "",
          "From": "{{product.price}}"
        }
      ]
    }

    console.log("create new creative with catalog ", body)

    try {

      if (process.env.NODE_ENV === "production") {
        let result = await sendRequest(url, 'POST', JSON.stringify(body), { 'Content-Type': 'application/json' })


        console.log("creative created!")
        console.log("result: ", result)
        if (result.error) {
          console.log("error while creating the  ad creative...")
          console.log(result.error)
          return result.error
        }
        return {
          ...body,
          ...result
        }

      } else {
        return {
          id: "120200070614810307",
          // id: "120203296136650307",
          ...body,
        }
      }

    } catch (err) {
      console.log("error while creating the  ad creative 2...")
      console.log(err.error.error_user_msg)
      return err.error
    }
  }

  const deleteAdCreative = async ({ creativeId }) => {
    let url = `${facebookGraphUrl}${creativeId}?access_token=${facebook.auth.accessToken}`
    try {
      let deleteResult = await sendRequest(url, "DELETE")
      console.log(deleteResult)
    } catch (err) {
      console.log("error happened while deletion", err)
    }

  }

  const generateAdPreviews = async ({
    productSetId,
    pageId,
    instagramAccountId,
    utms,
    primaryText,
    websiteUrl
  }) => {
    let creative = encodeURIComponent(
      `{
        "object_story_spec": {
          "link_data": {
            "call_to_action": {"type":"SHOP_NOW","value":{"link": "${websiteUrl}"}},
            "description": "Description",
            "link": "${websiteUrl}",
            "message": "${primaryText.length ? primaryText : 'Primary Text'}",
            "name": "Name"
          },
          "page_id": "${pageId}",
          "instagram_actor_id": "${instagramAccountId}",
          "product_set_id": "${productSetId}"
        }
      }`,
    )
    let previews = [
      // { format: "INSTAGRAM_REELS", dimensions: { width: 320, height: 570 } },
      // { format: "INSTAGRAM_STANDARD", dimensions: { width: 320, height: 410 } },
      {
        format: 'DESKTOP_FEED_STANDARD',
        dimensions: { width: 500, height: 600 },
      },
    ]

    let promises = []
    previews.forEach(preview => {
      let url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/generatepreviews?ad_format=${preview.format}&creative=${creative}&access_token=${facebook.auth.accessToken}`
      promises.push(sendRequest(url, 'get'))
    })
    let results = await Promise.all(promises)

    results.forEach((result, index) => {
      previews[index] = {
        ...previews[index],
        body: result.data[0].body,
      }
    })

    return previews
  }

  const getAdPreviews = async ({ creativeId }) => {
    let previews = JSON.parse(
      sessionStorage.getItem(`cr_${creativeId}_previews`),
    )
    let previewsSetTime = parseInt(cookies.get("prw_set"))

    if (previews && previewsSetTime && (Date.now() - previewsSetTime < 12 * 60 * 60 * 1000)) {
      return previews
    } else {
      previews = [
        // { format: "INSTAGRAM_REELS", dimensions: { width: 320, height: 570 } },
        {
          format: 'DESKTOP_FEED_STANDARD',
          dimensions: { width: 500, height: 750 },
        },
        /* { format: "MESSENGER_MOBILE_INBOX_MEDIA", dimensions: { width: 400, height: 250 } },
        { format: "INSTAGRAM_PROFILE_FEED", dimensions: { width: 320, height: 410 } }, */
      ]

      let promises = []
      previews.forEach(preview => {
        let url = `${facebookGraphUrl}${creativeId}/previews?ad_format=${preview.format}&width=160&height=285&access_token=${facebook.auth.accessToken}`
        promises.push(sendRequest(url, 'get'))
      })
      let results = await Promise.all(promises)

      results.forEach((result, index) => {
        previews[index] = {
          ...previews[index],
          body: result.data[0].body,
        }
      })

      cookies.set("prw_set", Date.now(), 0.2)
      sessionStorage.setItem(
        `cr_${creativeId}_previews`,
        JSON.stringify(previews),
      )
      return previews
    }
  }

  const getProductsOfEdge = async ({ edgeName, edgeId, accessToken = facebook.auth.accessToken, fields, filter }) => {

    const cachedProducts = JSON.parse(
      sessionStorage.getItem(`${edgeName === "productSet" ? "ps" : "ctg"}_${edgeId}_products${filter ? '_filter__' + filter : ''}`),
    )

    if (cachedProducts) {
      return cachedProducts
    }

    try {

      const url = `${facebookGraphUrl}${edgeId}/products?fields=${fields}&access_token=${accessToken}${filter ? '&filter=' + filter : ''}`
      const productsResponse = await sendRequest(url, "GET")

      sessionStorage.setItem(`${edgeName === "productSet" ? "ps" : "ctg"}_${edgeId}_products${filter ? '_filter__' + filter : ''}`, JSON.stringify(productsResponse.data))

      return productsResponse.data
    } catch (err) {
      console.log("error while getting products ", err)
      return []
    }
  }

  const getPreviousCreatives = async () => {

    let adAccountId = facebook.selectedAdAccount?.id
    let allCreatives;

    if (facebook.adCreativeLibrary && facebook.adCreativeLibrary[adAccountId] && facebook.adCreativeLibrary[adAccountId].creatives) {
      // if page refreshed at least once, then facebook info already includes ad creatives
      allCreatives = Object.values(facebook.adCreativeLibrary[adAccountId]?.creatives)
      let updatedAt = new Date(facebook.adCreativeLibrary[adAccountId].updatedAt)
      if (updatedAt && (Date.now() - updatedAt.valueOf()) > 7 * 24 * 60 * 60 * 1000) {
        // more than a week passed, fetch short run
        let newCreatives = updateCreativeLibraryIfNecessary({ adAccountId })
        allCreatives = allCreatives.concat(newCreatives)
      }

    } else {
      // front end doesnt have the creatives yet (probably single flow)
      const getFromDb = async () => {
        let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/getCreativeLibraryFromDb?userId=${user.id}&adAccountId=${facebook.selectedAdAccount.id}`
        let result = await sendRequest(url, 'GET', null, authHeader)
        return result.creatives
      }

      let libraryFromDb = await getFromDb()
      if (!libraryFromDb) {
        await helper.timeout(3000)
        libraryFromDb = await getFromDb()
      }

      allCreatives = libraryFromDb ? Object.values(libraryFromDb) : []
    }

    return allCreatives ?? []

  }

  // fetching creative library as short run
  const updateCreativeLibraryIfNecessary = async ({ adAccountId }) => {

    let url = `${process.env.REACT_APP_BACKEND_URL}/facebook/getBestPerformedCreatives?userId=${user.id}&adAccountId=${adAccountId}&longRun=${false}&accessToken=${facebook.auth.accessToken}`
    try {
      let result = await sendRequest(url, 'GET', null, authHeader)
      return Object.values(result.data)
    } catch (err) {
      handleError(err)
      throw err
    }
  }

  const updateCreative = async ({ newCreativeBody, campaignName }) => {
    let utms = `utm_source=Enhencer&utm_medium=Enhencer_Meta_CPC${campaignName ? `&utm_campaign=${campaignName}` : ''}`
    let body = {
      ...newCreativeBody,
      "name": `enh_adcrtvf__${newCreativeBody.id}`
    }
    delete body.id

    let url = `${facebookGraphUrl}${facebook.selectedAdAccount.id}/adcreatives?access_token=${facebook.auth.accessToken}`

    try {
      if (process.env.NODE_ENV === "production") {
        let result = await sendRequest(url, 'POST', JSON.stringify(body), { 'Content-Type': 'application/json' })

        if (result.error) {
          console.log("error while creating the  ad creative...", result.error)
          return result.error
        }
        console.log("creative created! result: ", result)
        return result

      } else {
        return {
          id: "120206153182280307"
        }
      }

    } catch (err) {
      handleError(err)
    }
  }

  const fetchWithPagination = async ({ url }) => {
    let edges = []
    const next = async (nextUrl) => {
      await sendRequest(nextUrl, "GET").then(res => {
        if (res.error) {
          throw { error: res.error }
        } else if (res.paging?.next) {
          edges = edges.concat(res.data)
          next(res.paging.next)
        } else {
          edges = edges.concat(res.data)
          return {
            edges: edges
          }
        }

      }).catch((err) => {
        handleError(err)
      })
    }

    await next(url)
    return edges
  }

  const getInsightsForChart = async (dateFilter = facebook.reportsFilter.value) => {
    let userId = user.id

    if (user.isSpecialUser) {
      const params = new URLSearchParams(window.location.search)
      userId = params.get('user_in_view_id')
    }

    const url = `${process.env.REACT_APP_BACKEND_URL}/facebook/getInsightsForChart?userId=${userId}&dateFilter=${dateFilter}`
    const insights = await sendRequest(url, 'GET', null, authHeader)

    dispatch(facebookActions.setInsightsForChart(insights))
  }

  return {
    auth,
    initializeFacebookInfo,
    getAdAccounts,
    fetchReportsForCampaigns,
    newFilterSelectedForReport,
    newCampaignSelectedForReport,
    getOverallAdsetBasedReportForEnhCampaigns,
    getInsightsForChart,

    selectPixel,
    selectAdAccount,
    selectBusiness,

    createAudiencesForTheFirstTime,
    retryAudienceCreation,

    getCatalogs,
    getProductSetsOfCatalog,
    selectProductSet,

    getFacebookPages,
    selectFacebookPage,
    getInstagramAccountsForPage,
    getProductsOfEdge,

    createCampaign,
    updateCampaign,
    updateCampaignStatus,
    deleteCampaign,
    createAdCreativeWithCatalog,
    deleteAdCreative,
    getAdPreviews,
    updateCreative,
    getPreviousCreatives,
    generateAdPreviews,

    logOut,
  }
}
