import React from 'react'

import './Breadcrumb.css'

const Breadcrumb = ({ children }) => {
  const activeChildren = children.filter(c => c !== null)
  
  return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {React.Children.map(activeChildren, (child, index) => {
          const isLast = index === React.Children.count(activeChildren) - 1

          return (
            <li
              className={`breadcrumb-item${isLast ? ' active' : ''}`}
              aria-current={isLast ? 'page' : null}
            >
              {child}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Breadcrumb
