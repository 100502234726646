import React, { useEffect, useState } from 'react'
import { useModal } from '../../hooks/modal-hook'
import { useLocation, useNavigate } from 'react-router-dom'

import './ConnectionRequired.css'
import cookies from '../../util/cookies'
import { FaArrowRight } from 'react-icons/fa'
import Button from '../FormElements/Button'

const ConnectionRequired = ({ data }) => {
  const { shouldRedirect } = data
  const { closeModal } = useModal()
  const navigate = useNavigate()
  const location = useLocation()
  const [remaining, setRemaining] = useState(6)
  const [redirectInterval, setRedirectInterval] = useState()

  const vanish = () => {
    navigate(`/config${location.search}`)
    clearInterval(redirectInterval)
    closeModal()
  }

  useEffect(() => {
    if (shouldRedirect) {

      const interval = setInterval(() => {
        setRemaining(prevRemaining => {
          if (prevRemaining > 1) {
            return prevRemaining - 1
          }
          vanish()
          return prevRemaining
        })
      }, 1000)

      setRedirectInterval(interval)
    }

    // connection required modal shown
    cookies.set("con_rqmods", Date.now(), 0.5)

    return () => clearInterval(redirectInterval)
  }, [])


  return (
    <div className="connection-required">
      {shouldRedirect ? <h2 className="title">
        Redirecting to <span className="page-name">Connections</span> page
        in {`${remaining} second${remaining > 1 ? 's' : ''}`}...
      </h2> :
        <h2 className="title">
          <span className="page-name">Complete Connections</span>
        </h2>
      }
      <p className="description">
        Before getting started, you need to complete your connections.
        <br />
        <span className="only-a-min-text">This will only take a minute!</span>
      </p>
      {shouldRedirect ?

        <p className="manual-redirection">
          If you're not automatically redirected,{' '}
          <span onClick={vanish} className="click-here-text">
            click here
          </span>
          .
        </p>
        :
        <div className="footer">
          <div className="action-bar">
            <Button className="action-button yes" onClick={closeModal}>
              Not now
            </Button>
            <Button className="action-button yes" onClick={vanish}>
              Let's Start
              <FaArrowRight />
            </Button>
          </div>
        </div>
      }
    </div>
  )
}

export default ConnectionRequired
