import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import imageExpander from "../../shared/util/imageExpander";

import './Tutorials.css'

import { FaCopy } from "react-icons/fa";


import gtm2 from '../../shared/assets/images/gtm-guide/gtm-2.png'
import gtm3 from '../../shared/assets/images/gtm-guide/gtm-3.png'
import gtm4 from '../../shared/assets/images/gtm-guide/gtm-4.png'
import gtm5 from '../../shared/assets/images/gtm-guide/gtm-5.png'
import helper from "../../shared/util/helper";
import ControlCenter from "../../shared/components/Navigation/ControlCenter";
import ProgressBar from "../../shared/components/UIElements/ProgressBar";


const GTMConnect = (props) => {
  
  const userId = useSelector((state) => state.auth.user.id);
  const isSpecialUser = useSelector((state) => state.auth.user.id);
  const userInView = useSelector(state => state.admin.userInView)
  const idInDocs = isSpecialUser ?  userInView.id : userId

  const [conversionID, setConversionID] = useState("")
  const [conversionIDValid, setConversionIDValid] = useState(true)
  const [integrationProcessStep, setIntegrationProcessStep] = useState(1)
  const [integrationProcess, setIntegrationProcess] = useState([

    {
      name: "Introduction",
      detail: "",
    },
    {
      name: "Listing Page Tag",
      detail: "",
      img: gtm2
    },

    {
      name: "Product Page Tag",
      detail: "",
      img: gtm3
    },
    {
      name: "Add to Cart Tag",
      detail: "",
      img: gtm4
    },
    {
      name: "Purchase Tag",
      detail: "",
      img: gtm5
    },
    {
      name: "Publish Changes",
      detail: "",

    },

  ])

  useEffect(() => {
    imageExpander.handleImages()
  }, [])



  const copyToClipboard = (id) => {
    let t = document.getElementById(id).textContent
    navigator.clipboard.writeText(t)

    toast.success("Copied to clipboard!");
  }

  const buildCardContent = () => {
    switch (integrationProcessStep) {
      case 1:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Connecting Enhencer to your website through GTM is a simple process,
              you just have to configure the triggers and variables correctly.
              You have to add only 4 tags described below, in order to complete the setup.

            </p>
            <ol>
              <li>Listing Page</li>
              <li>Product Page</li>
              <li>Add to Cart</li>
              <li>Purchase</li>
            </ol>
          </div>
          <div className="image-cont">
            {/* <img alt="img-cont1" src={integrationProcess[integrationProcessStep - 1].img} /> */}

          </div>
        </div>
      case 2:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Create a tag called "Enhencer - Listing Page" with type 'Custom HTML'. Add the code below to the HTML field of the tag. Add a trigger that fires on listing page load.
            </p>
            <div className="code-cont">
              <pre id="c2">
                &lt;script&gt;<br />
                {`(function (d, s, id) {
var js, sdkjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id) && window.enhencerCollector) { enh_lp(); return; }
js = d.createElement(s); js.id = id;
js.src = 'https://cdn.enhencer.com/scripts/collector_v2.js?token=${idInDocs}${conversionIDValid && conversionID !== '' ? '&g_conv_id=' + conversionID : ''}';
js.onload = enh_lp
js.defer = true
sdkjs.parentNode.insertBefore(js, sdkjs);
}(document, 'script', 'enhencer'));
  
function enh_lp(){
  window.enhencerCollector.listingPage({
    listingCategory: {listing category goes here}
  })
}
</script>`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c2')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
          <img alt="docsimg"src={gtm2} className="docs-img" />
        </div>

      case 3:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Similarly, create a tag called "Enhencer - Product Page" with 'Custom HTML' type. Add the code snippet to the HTML field. Add a trigger that fires on product detail page load.
            </p>
            <div className="code-cont">
              <pre id="c3">
                &lt;script&gt;<br />
                {`(function (d, s, id) {
var js, sdkjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id) && window.enhencerCollector) { enh_pp(); return; }
js = d.createElement(s); js.id = id;
js.src = 'https://cdn.enhencer.com/scripts/collector_v2.js?token=${idInDocs}${conversionIDValid && conversionID !== '' ? '&g_conv_id=' + conversionID : ''}';
js.onload = enh_pp
js.defer = true
sdkjs.parentNode.insertBefore(js, sdkjs);
}(document, 'script', 'enhencer'));
  
function enh_pp(){
  window.enhencerCollector.productPage({
    productID: {product id goes here},
    productCategory: {product category goes here},
    price: {product price goes here}
})
}
</script>`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c3')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
          <img alt="gtm3" src={gtm3} className="docs-img" />
        </div>

      case 4:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Add a new tag called "Enhencer - Add to Cart". Copy the code snippet to the HTML field. Pass the ID of the product that has been added to the cart as parameter. Obviously, the trigger has to fire on 'add to cart/basket' event or button click.
            </p>
            <div className="code-cont">
              <pre id="c4">{`<script>
window.enhencerCollector.addedToBasket({
    productID: {product id goes here}
})
</script>`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c4')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
          <img alt="gtm4" src={gtm4} className="docs-img" />
        </div>

      case 5:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              The last tag that we have to set up is "Enhencer - Purchase". Again, the tag type has to be 'Custom HTML'. Copy the code to the HTML field. When a purchase completed successfully, the tag has to be fired. Pass the purchase products array as a parameter. Every product has to have id, price and quantity parameters at least.
            </p>
            <div className="code-cont">
              <pre id="c5">
                &lt;script&gt;<br />
                {`(function (d, s, id) {
var js, sdkjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id) && window.enhencerCollector) { enh_p(); return; }
js = d.createElement(s); js.id = id;
js.src = 'https://cdn.enhencer.com/scripts/collector_v2.js?token=${idInDocs}${conversionIDValid && conversionID !== '' ? '&g_conv_id=' + conversionID : ''}';
js.onload = enh_p
js.defer = true
sdkjs.parentNode.insertBefore(js, sdkjs);
}(document, 'script', 'enhencer'));
  
function enh_p(){
  window.enhencerCollector.purchased()
}
</script>`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c5')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
          <img alt="gtm5" src={gtm5} className="docs-img" />
        </div>

      case 6:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              The setup is complete, after you publish your changes,
              check out the <Link to="/connect-overview">Connect Overview</Link> page to see if data started to be collected.<br /><br />
              In case of any errors, please contact us.
            </p>
          </div>
        </div>
    }
  }


  return (
    <React.Fragment>
      <div className="manager-page campaign-manager">
        <div className="middle-container">
          <div className={`result-card ${integrationProcessStep === 1 || integrationProcessStep === 6 ? 'min-height' : ''}`}>
            <div className="card-header">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="step-number">
                  {integrationProcessStep}
                </div>
                <h4>{integrationProcess[integrationProcessStep - 1].name}</h4>
              </div>
            </div>

            <div className="card-content">
              {buildCardContent()}

            </div>
          </div>
        </div>


        <ControlCenter>
          <div className="header-row">
            <h4><Link to="/connect">
              Connect
            </Link> &gt; GTM Integration Process</h4>
            <p>{((integrationProcessStep) / integrationProcess.length * 100).toFixed(0)}%</p>
          </div>
          <ProgressBar percentage={(integrationProcessStep) / integrationProcess.length * 100} />

          <div className="flow-container">
            <div className="vertical-line"></div>
            <div className="steps">
              {integrationProcess.map((item, index) =>
                <div className={`row process-item ${item.detail && item.detail !== "" ? "align-start" : "align-center"}`} key={index}>
                  <div className={index < integrationProcessStep ? "icon-circle active" : "icon-circle"} onClick={() => { setIntegrationProcessStep(index + 1) }}>
                    {item.icon || index + 1}
                  </div>
                  <div className="process-item-content">
                    <h5>{item.name}</h5>
                    <div className="detail">{item.detail}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ControlCenter>
      </div>
    </React.Fragment >
  );
};

export default GTMConnect;