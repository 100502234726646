import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useModal } from '../../hooks/modal-hook'
import bg from '../../assets/images/free-checkup-bg.png'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'
import './CheckupMeeting.css'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'


const CheckupMeeting = ({ data }) => {
  const { closeModal } = useModal()
  const user = useSelector(state => state.auth.user)


  useEffect(() => {
    // does two times on dev mode
    if (window.Calendly) {
      window.Calendly.initInlineWidget({
        url: process.env.REACT_APP_CALENDLY_AD_HEALTH_CHECKUP_MEETING_LINK,
        parentElement: document.getElementById('checkupCalendly'),
        prefill: {
          name: user?.name,
          email: user?.email,
        },
      })
    }
  }, [user])

  return (
    <div
      className="checkup-meeting"
      style={{
        backgroundImage: data.scheduledMeeting ? 'none' : `url(${bg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className="close-button" onClick={closeModal}>
        <FaTimes />
      </div>
      {data.scheduledMeeting ? (
        <div>
          You have a scheduled meeting on{' '}
          <b>{data.scheduledMeeting.meetingTime}</b>.
          <br />
          <br />
          If you have any further questions, contact us on{' '}
          <Link to="mailto:hello@enhencer.com">hello@enhencer.com</Link>
        </div>
      ) : (
        <div>
          <div className="left-side">
            <div className="subscription-header">
            Book Your {' '}
              <span className="green">Free</span> Demo with Us, and Lets Have a Chat!{' '}
             
            </div>

            <div className="feature-points">
              <div>
                <FaCheckCircle className="icon" />
                <div>
                  Review your ads with a{' '}
                  <span className="green">performance marketing expert</span>
                </div>
              </div>
              <div>
                <FaCheckCircle className="icon" />
                <div>
                  Compare your ad performance with{' '}
                  <span className="green">your industry standards</span>
                </div>
              </div>
              <div>
                <FaCheckCircle className="icon" />
                <div>
                  Get <span className="green">custom</span> AI Ads pricing
                  suitable <span className="green">for your business</span>
                </div>
              </div>
            </div>
          </div>

          <div className="calendly-inline-widget" id="checkupCalendly"></div>
        </div>
      )}
    </div>
  )
}

export default CheckupMeeting
