import React, { useRef, useState } from 'react'

import './HoverCard.css'

// TODO: Add placement (top, bottom, left, right) prop.
const HoverCard = ({ delay = 300, children }) => {
  const [show, setShow] = useState(false)
  const timeoutRef = useRef(null)

  return (
    <div
      className="hover-card-container"
    >
      {React.Children.map(children, child =>
        React.cloneElement(child, { show, setShow, delay, timeoutRef }),
      )}
    </div>
  )
}

const Trigger = ({ timeoutRef, delay, setShow, children }) => {
  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setShow(true)
    }, delay)
  }

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current)
    setShow(false)
  }

  return (
    <div
      className="hover-card-trigger"
      onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  )
}

const Content = ({ show, setShow, children }) => {
  if (!show) {
    return null
  }

  const handleMouseEnter = () => {
    setShow(true)
  }

  const handleMouseLeave = () => {
    setShow(false)
  }

  return (
    <div
      className="hover-card-content"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  )
}

HoverCard.Trigger = Trigger
HoverCard.Content = Content

export default HoverCard
