import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { authActions } from '../../store/auth'
import { useHttpClient } from './http-hook'
import { generalActions } from '../../store/general'
import helper from '../util/helper'
import { useAdmin } from './admin-hook'

let logoutTimer

export const useAuth = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const { getPerformanceManagerProfileForUser } = useAdmin()

  const storedData = JSON.parse(localStorage.getItem('userData') || '[]')
  const lastLoggedInAccount = storedData.find(user => user.lastLoggedInAccount === true) || {}

  const authTokenInStorage = lastLoggedInAccount?.token;
  const authTokenExpirationDateInStorage = lastLoggedInAccount?.tokenExpirationDate
  const emailInStorage = lastLoggedInAccount?.email
  
  const auth = useSelector(state => state.auth)
  const authToken = auth.token
  const authTokenExpirationDate = auth.tokenExpirationDate
  const isSpecialUser = auth.user?.isSpecialUser
  const authUserId = auth.user?.id
  
  

  const handleSessionExpiration = () => {
    const sessionExpired = () => {
      toast.warn('Your session has expired. Please log in again.')
      dispatch(authActions.logout())
      document.body.classList.remove('adminsr')

      dispatch(generalActions.setPageTitle({ pageTitle: null }))
      dispatch(generalActions.setCompanyTitle({ companyTitle: null }))
    }

    if (authToken && authTokenExpirationDate) {
      const currentDate = new Date()
      const remainingTime = authTokenExpirationDate - currentDate.getTime()
      logoutTimer = setTimeout(sessionExpired, remainingTime)

      if (isSpecialUser) {
        document.body.classList.add('adminsr')
      }
    } else {
      clearTimeout(logoutTimer)
    }
  }

  const register = async ({ name, email, country, url, password }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/signup`,
        'POST',
        JSON.stringify({
          name,
          email,
          country,
          url,
          password,
        }),
        {
          'Content-Type': 'application/json',
        },
      )

      const token = responseData.token
      const tokenExpirationDate = responseData.expirationDate
      const user = responseData.user
      const projectId = responseData.projectId

      const updatedUser = helper.getUIReadyUserObject({ user })

      if (updatedUser.crmDetails?.performanceManager) {
        updatedUser.crmDetails.performanceManagerProfile = await getPerformanceManagerProfileForUser({
          performanceManager: updatedUser.crmDetails.performanceManager,
          authToken: token
        })
      }


      dispatch(
        authActions.login({
          token,
          tokenExpirationDate,
          projectId,
          user: updatedUser,
        }),
      )
    } catch (err) {
      toast.error(err?.message || 'Something went wrong')

      if (err?.message === 'User exists already, please login instead.') {
        navigate('/login')
      }
    }
  }

  const login = async ({ email, password }) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        'POST',
        JSON.stringify({ email, password }),
        { 'Content-Type': 'application/json' },
      )

      const token = responseData.token
      const tokenExpirationDate = responseData.expirationDate
      const user = responseData.user
      const projectId = responseData.projectId

      const updatedUser = helper.getUIReadyUserObject({ user })
      if (updatedUser.crmDetails?.performanceManager) {
        updatedUser.crmDetails.performanceManagerProfile = await getPerformanceManagerProfileForUser({
          performanceManager: updatedUser.crmDetails.performanceManager,
          authToken: token
        })
      }

      dispatch(
        authActions.login({
          token,
          tokenExpirationDate,
          projectId,
          user: updatedUser,
        }),
      )
    } catch (err) {
      throw err
    }
  }

  const loginWithGoogle = async () => { }

  const logout = async () => {
    dispatch(authActions.logout())
  }

  const deleteAccount = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${authUserId}`,
        'DELETE',
        null,
        {
          Authorization: 'Bearer ' + authToken,
          'Content-Type': 'application/json',
        },
      )

      toast.error('Account deleted!')
      dispatch(authActions.logout({}))
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }

  return {
    handleSessionExpiration,
    register,
    login,
    loginWithGoogle,
    logout,
    deleteAccount,
    auth,
    authTokenInStorage,
    authToken,
    authTokenExpirationDateInStorage,
    authTokenExpirationDate,
    emailInStorage,
  }
}
