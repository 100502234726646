import React, { useEffect } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "./Payment.css"
import { useDispatch, useSelector } from "react-redux";
import { generalActions } from "../../store/general";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

const Payment = props => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoading, sendRequest } = useHttpClient()
    const auth = useSelector(state => state.auth)

    const billingInfo = sessionStorage.getItem("billingInfo")
    const iyziEndpoint = sessionStorage.getItem("iyziEndpoint")
    if (!billingInfo || !iyziEndpoint) {
        sessionStorage.getItem("plan") ? navigate("/subscription/billingInfo") : navigate("/account/subscription")
    }

    useEffect(() => {
        const pageTitle = "Subscription Payment"
        dispatch(generalActions.setPageTitle({ pageTitle }))
        document.querySelector(".App").classList.add("payment");

        handleCheckoutForm()


        return () => {
            document.querySelector(".App").classList.remove("payment")
        }
    }, [])


    const handleCheckoutForm = async () => {

        let result = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/payment/${iyziEndpoint}`, 'POST', billingInfo, {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
        })

        if (result.status === 'success') {
            let oldElem = document.getElementById("iyzico_script")
            if (oldElem != null) {
                oldElem.remove()
                window.iyziInit = undefined
            }


            let codes = result.checkoutFormContent.split("</script>")
            let c1 = codes[0].replace('<script type="text/javascript">', '')
            let c2 = codes[1].replace('<script type="text/javascript">', '')
            let c3 = codes[2].replace('<script type="text/javascript">', '')
            let all = c1 + c2 + c3

            let elem = document.createElement('script')
            elem.id = "iyzico_script"
            elem.innerHTML = all
            document.body.appendChild(elem)

        } else {
            toast.error(result.errorMessage)
        }
    }

    return isLoading ? <LoadingSpinner /> :
        <div className="payment">

            <div className="checkout-form">
                <div className="header-row">
                    <h3>Complete Payment</h3>
                    <Link className="cancel-payment" to="/subscription/billing">
                        <FaChevronLeft />
                        Back
                    </Link>
                </div>
                <div id="iyzipay-checkout-form" className="responsive"></div>
            </div>

        </div>
}

export default Payment;