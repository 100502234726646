import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { useNotificationPopup } from '../../hooks/notification-popup-hook'
import helper from '../../util/helper'

import './NotificationPopup.css'

const NotificationPopup = ({ autoClose = 5000, ...props }) => {
  const { popupData, hidePopup } = useNotificationPopup()
  const { content, callback } = popupData
  const [visible, setVisible] = useState(false)
  const outerTimerRef = useRef(null)
  const innerTimerRef = useRef(null)

  const handleOnClick = () => {
    if (callback) {
      callback()
    }

    hidePopup()
  }

  const startTimer = () => {
    outerTimerRef.current = setTimeout(() => {
      setVisible(false)

      innerTimerRef.current = setTimeout(() => {
        hidePopup()
      }, autoClose)
    }, autoClose)
  }

  const clearTimer = () => {
    setVisible(true)

    if (outerTimerRef.current) {
      clearTimeout(outerTimerRef.current)
    }

    if (innerTimerRef.current) {
      clearTimeout(innerTimerRef.current)
    }
  }

  useEffect(() => {
    setVisible(true)
    startTimer()

    return () => clearTimer()
  }, [])

  if (helper.isEmptyObject(popupData)) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      onClick={handleOnClick}
      onMouseEnter={clearTimer}
      onMouseLeave={startTimer}
      className={`notification-popup ${visible ? 'visible' : 'hidden'}`}
      {...props}
    >
      {content}
    </div>,
    document.body,
  )
}

export default NotificationPopup
