import React, { useEffect, useRef, useState } from 'react'
import { FaBullhorn, FaChartBar, FaDollarSign, FaPercent, FaSpinner, FaUser, FaWallet } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import metaLogo from '../../shared/assets/images/icons/meta-logo.png'
import numberFormatter from '../../shared/util/numberFormatter'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import dateFormatter, { getShortDateString } from '../../shared/util/dateFormatter'
import ProgressTracker from './ProgressTracker'
import magicEnhencer from '../../shared/assets/images/enhencer-guide/intro-step1.png'

import './YourHistoryCard.css'
import helper from '../../shared/util/helper'

const YourHistoryCard = () => {
  const facebook = useSelector(state => state.facebook)
  const adAccountCurrency = helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)
  const initialReport = facebook.initialOverallReport
  const cpc = (() => {
    if (isNaN(initialReport?.cpc)) return initialReport?.clicks ? initialReport?.spend / initialReport?.clicks : 0
    else return initialReport?.cpc
  })()

  const costPerPurchase = (() => {
    return initialReport?.purchases ? initialReport?.spend / initialReport?.purchases : 0
  })()
  const [reportRange, setReportRange] = useState()

  const [steps, setSteps] = useState([
    {
      name: 'AI Audience',
      detail: (
        <div>
          Unlock the power of AI insights – your personalized ad analysis awaits. Complete the steps to reveal your results here.
        </div>
      ),
      isActive: true,
      icon: <FaUser />,
    },
  ])

  useEffect(() => {
    let start = getShortDateString(initialReport?.dateStart)
    let end = getShortDateString(initialReport?.dateEnd)
    setReportRange(`${start} - ${end}`)


  }, [initialReport])

  return (
    <FluidCard className="ai-audience">
      <FluidCard.Header>{initialReport ? "Your History Before AI Ads: 14-day snapshot" : "AI Insights"}</FluidCard.Header>
      <FluidCard.Content>
        {initialReport ? <div className='progress-container'>


          <div className="rows-wrapper">
            {
              reportRange && <div className="helper-text">
                {reportRange}
              </div>
            }
            <div className="grid">
              <div className="item">
                <div className="texts">
                  <span className="item-title">
                    <img src={metaLogo} alt="Meta" className="meta-logo" />
                    Revenue
                  </span>
                  <span className="value">
                    <span className='currency'>
                      {adAccountCurrency}
                    </span>
                    {numberFormatter.formatNumber(initialReport?.sales)}
                  </span>
                </div>
                <div className="icon">
                  <FaWallet />
                </div>
              </div>
              <div className="item">
                <div className="texts">
                  <span className="item-title">
                    <img src={metaLogo} alt="Meta" className="meta-logo" />
                    Ad Spend
                  </span>
                  <span className="value">
                    <span className='currency'>
                      {adAccountCurrency}
                    </span>
                    {numberFormatter.formatNumber(initialReport?.spend)}
                    {/* <span className="change">+5%</span> */}
                  </span>
                </div>
                <div className="icon">
                  <FaDollarSign />
                </div>
              </div>
              <div className="item">
                <div className="texts">
                  <span className="item-title">
                    <img src={metaLogo} alt="Meta" className="meta-logo" />
                    ROAS
                  </span>
                  <span className="value">
                    {initialReport?.spend && initialReport?.sales ? numberFormatter.formatNumber(initialReport?.sales / initialReport?.spend) : "-"}
                    {/* <span className="change">+40%</span> */}
                  </span>
                </div>
                <div className="icon">
                  <FaChartBar />
                </div>
              </div>
              <div className="item">
                <div className="texts">
                  <span className="item-title">
                    <img src={metaLogo} alt="Meta" className="meta-logo" />
                    Cost/Purchase
                  </span>
                  <span className="value">
                    {
                      costPerPurchase ?
                        <React.Fragment>
                          <span className='currency'>
                            {adAccountCurrency}
                          </span>
                          {numberFormatter.formatNumber(costPerPurchase, costPerPurchase > 1000 ? 1 : 0)}
                        </React.Fragment> : "-"
                    }

                    {/* <span className="change">+55%</span> */}
                  </span>
                </div>
                <div className="icon">
                  <FaPercent />
                </div>
              </div>
            </div>
          </div>
        </div>
          :
          <React.Fragment>
            <div className='center magic-enhencer-img-cont'>
              <img src={magicEnhencer} />
            </div>
            <br />
            <div className='center'>
              <div>

                Unlock the power of AI insights – your personalized ad analysis awaits.
                <br /><br />
                Complete your connections to reveal your results here.
              </div>
            </div>
          </React.Fragment>
        }
      </FluidCard.Content>
    </FluidCard>
  )
}

export default YourHistoryCard
