import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

import OverallStats from "../components/OverallStats";
import ProductStats from "../components/ProductStats";
import AddToBasketStats from "../components/AddToBasketStats";
import PurchaseStats from "../components/PurchaseStats";
import ShopifyConnect from "../components/ShopifyConnect";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./Connect.css";

import { FaCalendarAlt } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import NotConnectedOverview from "../components/NotConnectedOverview";
import { generalActions } from "../../store/general";
import Button from "../../shared/components/FormElements/Button";
import Splash from "../../shared/components/UIElements/Splash";

const VisitingDataOverview = () => {
  const [visitorDataDetails, setVisitorDataDetails] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const auth = useContext(AuthContext);
  const auth = useSelector((state) => state.auth);
  const user = auth.user
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const admin = useSelector(state => state.admin)
  
  const userId = admin.userInView?.id;
  const pageTitle = `Visiting Data ${admin.userInView?.name ? (" of "+  admin.userInView.name) : ""}`
  const dispatch = useDispatch();

  useEffect(() => {
    const companyTitle = auth.tempCompanyName ? auth.tempCompanyName.split('@')[0] : null;
    if (user.isSpecialUser) {
      dispatch(generalActions.setPageTitle({ pageTitle: `${companyTitle} > ${pageTitle}` }));
      } else {
        dispatch(generalActions.setPageTitle({ pageTitle: `${pageTitle}` }));
      }

    }, [])

  useEffect(() => {
    if (userId) {
      const init = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getVisitingDataDetails`,
            "POST",
            JSON.stringify({
              userId: userId
            }),
            {
              Authorization: "Bearer " + auth.token,
              "Content-Type": "application/json",
            }
          );
          let minDate = new Date(responseData.dates.MINDATE);
          minDate.setHours(0);
          minDate.setMinutes(0);
          minDate.setSeconds(0);
          const maxDate = new Date(responseData.dates.MAXDATE);
          setStartDate(minDate);
          setEndDate(maxDate);
          setVisitorDataDetails(responseData.visitorDataDetails);
        } catch (err) {
          toast.error(err?.message || "Something went wrong")
        }
      };
      init();
    }
    
    
  }, [userId, auth.token]);

  const setDates = async (d) => {
    setStartDate(d);
    getVisitingDataDetails(d);
  };

  const getVisitingDataDetails = async (since) => {
    let reqBody = {userId: userId}
    if (since) {
      reqBody.startDate = since
    }
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/projects/getVisitingDataDetails`,
        "POST",
        JSON.stringify(reqBody),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      let minDate = new Date(responseData.dates.MINDATE);
      minDate.setUTCHours(0);
      minDate.setMinutes(0);
      minDate.setSeconds(0);
      const maxDate = new Date(responseData.dates.MAXDATE);
      if (!since) {
        setStartDate(minDate);
      }
      setEndDate(maxDate);
      setVisitorDataDetails(responseData.visitorDataDetails);
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }

  const run = async () => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createJob`,
        "POST",
        JSON.stringify({
          userId: userId,
          startDate: startDate,
          type: "purchase",
          repeats: {
            days: 20
          },
          isJobUpdate: false
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      toast.success("Audience is being created, we will notify you once its completed.");
    } catch (err) {
      toast.error(err?.message || "Something went wrong");
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <Splash />}
      <h1>{pageTitle}</h1> 
      {visitorDataDetails && visitorDataDetails.customerRowCount > 0 ? (
        <div>
          <div className="connect-overview-container">
            <div>
              <div className="date-input-cont">
                <div style={{ fontSize: "0.9em" }}>Since</div>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  className="datepicker"
                  pattern="^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$"
                  selected={startDate}
                  onChange={(date) => setDates(date)}
                />
                <FaCalendarAlt className="icon" />
              </div>
              <div className="data-summary-container">
                <OverallStats
                  purchaseRowCount={visitorDataDetails.purchaseRowCount}
                  customerRowCount={visitorDataDetails.customerRowCount}
                  productRowCount={visitorDataDetails.productRowCount}
                  productPageView={visitorDataDetails.productPageView}
                  listingPageView={visitorDataDetails.listingPageView}
                />
                <ProductStats
                  purchaseRowCount={visitorDataDetails.purchaseRowCount}
                  customerRowCount={visitorDataDetails.customerRowCount}
                  productRowCount={visitorDataDetails.productRowCount}
                  addToBasketRowCount={visitorDataDetails.addToBasketRowCount}
                />
                {visitorDataDetails.addToBasketRowCount !== 0 && (
                  <AddToBasketStats
                    purchaseRowCount={visitorDataDetails.purchaseRowCount}
                    customerRowCount={visitorDataDetails.customerRowCount}
                    addToBasketRowCount={visitorDataDetails.addToBasketRowCount}
                  />
                )}
                <PurchaseStats
                  purchaseRowCount={visitorDataDetails.purchaseRowCount}
                  customerRowCount={visitorDataDetails.customerRowCount}
                />
              </div>
            </div>
            <div className="action-container">
              <Button className="action-button" onClick={run}>
                Create Audiences
                <FaArrowRight className="icon" />
              </Button>
            </div>
          </div>
        </div>
      ) :
        user.shopify ? <ShopifyConnect /> : <NotConnectedOverview />}


    </React.Fragment>
  );
};

export default VisitingDataOverview;
