import React, { useState , useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import { FaTimes } from 'react-icons/fa'
import { FaChevronDown } from 'react-icons/fa'

import { useSelector } from 'react-redux'
import './PixelEventsVerificationRequired.css'
import imageExpander from '../../util/imageExpander'

const PixelEventsVerificationRequired = props => {
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)
  //const [isOpen, setIsOpen] = useState(false)
  const [openCardIndex, setOpenCardIndex] = useState(null);
  const facebook = useSelector(state => state.facebook)
  const businessId = facebook.selectedBusiness.id
  const adAccountId = facebook.selectedAdAccount.account_id

  useEffect(() => {
    imageExpander.handleImages()
  }, [])

  const openCard = (i) => {
    setOpenCardIndex((prevIndex) => (prevIndex === i ? null : i));
  };

  return (
    <div className="verify-events-container">
      <p>Follow the steps below to verify Enhencer events on your Meta Ads panel. This is mandatory for proper functioning of the custom audiences that we create for you.</p>
      <div className="step-card">
        <div className="step-header">
          <div>
            {' '}
            1 - Go to your events manager account by clicking{' '}
            <a
              href={`https://business.facebook.com/events_manager2/overview?business_id=${businessId}&act=${adAccountId}`}
              target="_blank"
            >
              here
            </a>{' '}
          </div>
        </div>
      </div>

      <div className="step-card">
        <div className="step-header" onClick={() => openCard(1)}>
          <div>
            2 - Click verify button in "your custom website events" part{' '}
          </div>
          {openCardIndex == 1 ? (
            <FaTimes className="fa-icon" />
          ) : (
            <FaChevronDown className="fa-icon" />
          )}
        </div>
        <div className={`step-content ${openCardIndex === 1 ? 'open' : ''}`}>
          <img className="expandable" src="https://cdn.enhencer.com/admin-assets/images/campaign-docs/facebook/pixel-events-verify-1.png"></img>
        </div>
      </div>

      <div className="step-card">
        <div className="step-header" onClick={() => openCard(2)}>
          <div>
            {' '}
            3 - Verify <b>enh_conv_rem</b> and <b>enh_conv_lal</b> events by
            clicking verify button
          </div>

          {openCardIndex == 2 ? (
            <FaTimes className="fa-icon" />
          ) : (
            <FaChevronDown className="fa-icon" />
          )}
        </div>
        <div className={`step-content ${openCardIndex === 2 ? 'open' : ''}`}>
          <img className="expandable" src="https://cdn.enhencer.com/admin-assets/images/campaign-docs/facebook/pixel-events-verify-2.png"></img>
        </div>
      </div>
    </div>
  )
}

export default PixelEventsVerificationRequired
