import { createSlice, current } from '@reduxjs/toolkit'

const initialPanelFilters = {
  dateRange: {
    range: {
      since: null,
      until: null,
    },
    type: "date_preset",
    value: "maximum",
  },
  sortBy: ["Subscription date"],
  callAvailabilityHours: {
    start: 0,
    end: 0,
  },
  _empty: [],
  status: ["Newcomer", "Recurring"],
  performanceStatus: ["No Status", "Onboarding", "Bad", "Good", "No Access"],
  accountManager: [],
  performanceManager: [],
  process: [],
  comparison: [],
  period: [],
  adsetComparison: [],
  audienceNetwork: [],
  aiAds: [],
  usageCharges: [],
  supports: {
    min: 0,
    max: 0,
  },
  platform: [],
  paused: [],
  supportTickets: [],
  googleAds: [],
  sourceType: [],
  country: [],
  leadType: [],
  didAccMngrSell: [],
}

const initialDemoMeetingFilters = {
  dateRange: {
    range: {
      since: null,
      until: null,
    },
    type: "date_preset",
    value: "maximum",
  },
  sortBy: ["Creation Date Descending"],
  _empty:[],
  status: [],
  leadType: [],
  accountManager: [],
  country: [],
}

const initialSignUpFilters = {
  dateRange: {
    range: {
      since: null,
      until: null,
    },
    type: "date_preset",
    value: "maximum",
  },
  sortBy: ["Signup date"],
  _empty: [],
  callAvailabilityHours: {
    start: 0,
    end: 0,
  },
  completed: [],
  status: [],
  isSignUpMeetingDone: [],
  accountManager: [],
  performanceManager: [],
  calls: {
    min: 0,
    max: 0,
  },
  talks: {
    min: 0,
    max: 0,
  },
  emails: {
    min: 0,
    max: 0,
  },
  smses: {
    min: 0,
    max: 0,
  },
  leadType: [],
  country: [],
  platform: [],
}

const initialTicketFilters = {
  dateRange: {
    range: {
      since: null,
      until: null,
    },
    type: "date_preset",
    value: "maximum",
  },
  status: [],
  priority: [],
  performanceManager: [],
  bugType: [],
}

const initialAlarmCalendarFilters = {
  type: [],
  isCompleted: [],
  accountManager: [],
  performanceManager: [],
}

const initialPanelSummary = {
  count: null,
  totalCalls: null,
  totalTalks: null,
  totalEmails: null,
  totalSMSes: null,
  avgLifetime: null,
  avgComments: null,
  status_newComerCount: null,
  status_recurringCount: null,
  status_signUpCount: null,
  status_churnCount: null,
  status_fastChurnCount: null,
  sixMonthRecurringCount: null,
  anCount: null,
  aiAdsCount: null,
  usageChargesCount: null,
  process_metaCompleted: null,
  process_campPublished: null,
  process_detailReportViewed: null,
  process_fourteenDayActiveCamp: null,
  comparison_hrevenue: null,
  comparison_hroas: null,
  comparison_hcost: null,
  lead_outbound: null,
  lead_faceinsta: null,
  lead_google: null,
  lead_shopify: null,
  lead_linkedin: null,
  lead_email: null,
  perfstatus_onboarding: null,
  perfstatus_bad: null,
  perfstatus_good: null,
  perfstatus_noaccess: null,
  perfstatus_notouch: null,
  country_tr: null,
  country_usca: null,
  country_uk: null,
  country_au: null,
  country_de: null,
  country_fr: null,
  country_other: null,
}
const savedFilters = sessionStorage.getItem('filters')
const savedDemoMeetingFilters = sessionStorage.getItem('demoMeetingFilters')
const savedSignUpFilters = sessionStorage.getItem('signUpFilters')
const savedTicketFilters = sessionStorage.getItem('ticketFilters')
const savedAlarmCalendarFilters = sessionStorage.getItem('alarmCalendarFilters')

const initialAdminState = {
  customers: [],
  panelDemoMeetingSummary: undefined,
  panelSignUpSummary: undefined,
  panelSummary: undefined,
  lastCursorCustomers: undefined,
  navigatedFromCustomerID: undefined,
  totalUserCount: undefined,
  filteredUserCount: undefined,
  panelFilters: savedFilters ? JSON.parse(savedFilters) : initialPanelFilters,
  customer: {},
  teamInfo: {},
  managerUsers: {},
  performanceManagers: {},
  accountManagers: {},
  userInView: {},
  privateConstants: {},
  areConstantsReady: false,
  demoMeetings: [],
  lastCursorDemoMeetings: undefined,
  totalDemoMeetingCount: undefined,
  filteredDemoMeetingCount: undefined,
  demoMeetingFilters: savedDemoMeetingFilters ? JSON.parse(savedDemoMeetingFilters) : initialDemoMeetingFilters,
  signUps: [],
  specialUsers: [],
  lastCursorSignUps: undefined,
  totalSignUpCount: undefined,
  filteredSignUpCount: undefined,
  signUpFilters: savedSignUpFilters ? JSON.parse(savedSignUpFilters) : initialSignUpFilters,
  tickets: [],
  lastCursorTickets: undefined,
  totalTicketCount: undefined,
  filteredTicketCount: undefined,
  ticketFilters: savedTicketFilters ? JSON.parse(savedTicketFilters) : initialTicketFilters,
  alarmCalendarFilters: savedAlarmCalendarFilters ? JSON.parse(savedAlarmCalendarFilters) : initialAlarmCalendarFilters,
  hasUnreadMessages: false,
}

const adminSlice = createSlice({
  name: 'admin',
  initialState: initialAdminState,

  reducers: {
    setNavigatedFromCustomerID(state, action) {
      state.navigatedFromCustomerID = action.payload
    },
    setLastCursorCustomers(state, action) {
      state.lastCursorCustomers = action.payload
    },
    setCustomers(state, action) {
      state.customers = action.payload
    },
    setSpecialUsers(state, action) {
      state.specialUsers = action.payload
    },
    addSpecialUser(state, action) {
      state.specialUsers.push(action.payload)
    },
    findSpecialUserByIdAndUpdate(state, action) {
      const { id, data } = action.payload
      let list = state.specialUsers;

      const index = list.findIndex(c => c._id === id)

      state["specialUsers"][index] = data
    },
    removeSpecialUser(state, action) {
      const index = state.specialUsers.findIndex(u => u.id === action.payload)
      state.specialUsers.splice(index, 1)
    },
    setDemoMeetingSummary(state, action) {
      state.panelDemoMeetingSummary = action.payload
    },
    setSignUpSummary(state, action) {
      state.panelSignUpSummary = action.payload
    },
    setPanelSummary(state, action) {
      state.panelSummary = action.payload
    },
    setCustomer(state, action) {
      state.customer = action.payload
    },
    findCustomerByIdAndUpdate(state, action) {
      const { id, data, isSignUp } = action.payload
      let list = isSignUp ? state.signUps : state.customers;

      const index = list.findIndex(c => c.id === id)


      state[isSignUp ? "signUps" : "customers"][index] = data
    },
    updateCustomerFieldInList(state, action) {
      const { userId, fieldPath, value, isSignUp } = action.payload

      let list = isSignUp ? state.signUps : state.customers;
      const index = list.findIndex(c => c.id === userId)
      const fields = fieldPath.split('.');
      let currentUser = list[index];

      for (let i = 0; i < fields.length - 1; i++) {
        const field = fields[i];
        if (!(field in currentUser)) {
          currentUser[field] = {};
        }
        currentUser = currentUser[field];
      }

      const lastField = fields[fields.length - 1];
      currentUser[lastField] = value;


    },
    updateCustomerDailyReport(state, action) {
      const { id, reportSnapshot } = action.payload
      const index = state.customers.findIndex(c => c.id === id)
      if (index > -1) {
        state.customers[index].facebookAds = {
          ...state.customers[index].facebookAds,
          weeklyReports: {
            ...state.customers[index].facebookAds.weeklyReports,
            enhOverall: reportSnapshot
          }
        }
      }
    },
    removeCustomerById(state, action) {
      const { id } = action.payload
      const index = state.customers.findIndex(c => c.id === id)
      let newCustomers = [...state.customers]
      delete newCustomers[index]
      state.customers = newCustomers
    },
    setTeamInfo(state, action) {
      state.teamInfo = action.payload
    },
    setManagerUsers(state, action) {
      state.managerUsers = action.payload
    },
    setPerformanceManagers(state, action) {
      state.performanceManagers = action.payload
    },
    setAccountManagers(state, action) {
      state.accountManagers = action.payload
    },
    setUserInView(state, action) {
      state.userInView = action.payload
    },
    setConnectionsCompleted(state, action) {
      state.userInView = {
        ...state.userInView,
        connectionsCompleted: action.payload
      }
    },
    setPrivateConstants(state, action) {
      state.privateConstants = action.payload
      state.areConstantsReady = true;
    },
    setUserCounts(state, action) {
      state.totalUserCount = action.payload.total
      state.filteredUserCount = action.payload.filtered
    },
    setPanelFilters(state, action) {
      state.panelFilters = Object.assign({}, { ...action.payload })
    },
    resetPanelFilters(state, action) {
      state.panelFilters = initialPanelFilters
    },
    setAllAlarms(state, action) {
      state.allAlarms = action.payload
    },
    setOverdueAlarmCounts(state, action) {
      state.overdueAlarmCounts = action.payload
    },
    setLastCursorDemoMeetings(state, action) {
      state.lastCursorDemoMeetings = action.payload
    },
    setDemoMeetings(state, action) {
      state.demoMeetings = action.payload
    },
    setDemoMeetingsCount(state, action) {
      state.totalDemoMeetingCount = action.payload.total
      state.filteredDemoMeetingCount = action.payload.filtered
    },
    setDemoMeetingFilters(state, action) {
      state.demoMeetingFilters = Object.assign({}, { ...action.payload })
    },
    resetDemoMeetingFilters(state, action) {
      state.demoMeetingFilters = initialDemoMeetingFilters
    },
    findDemoMeetingByIdAndUpdate(state, action) {
      const { id, data } = action.payload
      const index = state.demoMeetings.findIndex(t => t._id === id)
      state.demoMeetings[index] = data
    },
    removeDemoMeetingById(state, action) {
      const { id } = action.payload
      const index = state.demoMeetings.findIndex(t => t._id === id)
      let newDemoMeetings = [...state.demoMeetings]
      delete newDemoMeetings[index]
      state.demoMeetings = newDemoMeetings
    },

    setLastCursorSignUps(state, action) {
      state.lastCursorSignUps = action.payload
    },
    setSignUps(state, action) {
      state.signUps = action.payload
    },
    setSignUpsCount(state, action) {
      state.totalSignUpCount = action.payload.total
      state.filteredSignUpCount = action.payload.filtered
    },
    setSignUpFilters(state, action) {
      state.signUpFilters = Object.assign({}, { ...action.payload })
    },
    resetSignUpFilters(state, action) {
      state.signUpFilters = initialSignUpFilters
    },
    findSignUpByIdAndUpdate(state, action) {
      const { id, data } = action.payload
      const index = state.signUps.findIndex(t => t.id === id)
      state.signUps[index] = data
    },
    removeSignUpById(state, action) {
      const { id } = action.payload
      const index = state.signUps.findIndex(t => t.id === id)
      let newSignUps = [...state.signUps]
      delete newSignUps[index]
      state.signUps = newSignUps
    },
    setLastCursorTickets(state, action) {
      state.lastCursorTickets = action.payload
    },
    setTickets(state, action) {
      state.tickets = action.payload
    },
    setTicketCounts(state, action) {
      state.totalTicketCount = action.payload.total
      state.filteredTicketCount = action.payload.filtered
    },
    setTicketFilters(state, action) {
      state.ticketFilters = Object.assign({}, { ...action.payload })
    },
    resetTicketFilters(state, action) {
      state.ticketFilters = initialTicketFilters
    },
    findTicketByIdAndUpdate(state, action) {
      const { id, data } = action.payload
      const index = state.tickets.findIndex(t => t._id === id)
      state.tickets[index] = data
    },
    removeTicketById(state, action) {
      const { id } = action.payload
      const index = state.tickets.findIndex(t => t._id === id)
      let newTickets = [...state.tickets]
      delete newTickets[index]
      state.tickets = newTickets
    },
    setHasUnreadMessages(state, action) {
      state.hasUnreadMessages = action.payload
    },
    setAlarmCalendarFilters(state, action) {
      state.alarmCalendarFilters = Object.assign({}, { ...action.payload })
    },
    resetAlarmCalendarFilters(state, action) {
      state.alarmCalendarFilters = initialAlarmCalendarFilters
    },
  },
})

export const adminActions = adminSlice.actions
export const resettedPanelFilters = initialPanelFilters
export const resettedDemoMeetingFilters = initialDemoMeetingFilters
export const resettedSignUpFilters = initialSignUpFilters
export const resettedTicketFilters = initialTicketFilters
export const resettedAlarmCalendarFilters = initialAlarmCalendarFilters

export default adminSlice.reducer
