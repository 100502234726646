import React from "react";
import Canvas from "../../shared/components/UIElements/Canvas";
import numberFormatter from "../../shared/util/numberFormatter";

const AddToBasketStats = (props) => {
  return (
    <React.Fragment>
      <div className="add-to-basket">
        <div className="step-name">Add to basket</div>
        <div className="conversion-badge">
          <div className="number" title="Likely to buy">
            {(
              (props.addToBasketRowCount * 100) /
              props.customerRowCount
            ).toFixed(1)}{" "}
            %
          </div>
          <div className="text">Likely to buy</div>
        </div>
        <div className="canvas-container">
          <Canvas
            start={
              (props.addToBasketRowCount /
              props.customerRowCount) *
              100
            }
            end={
              (props.purchaseRowCount /
              props.customerRowCount) *
              100
            }
            className="funnel-canvas"
          ></Canvas>
        </div>
        <div className="summary-badge">
          <div className="number" title="Visitors">
            {numberFormatter.formatNumber(
              props.addToBasketRowCount
            )}
          </div>
          <div className="text">Visitors</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddToBasketStats;
