import React, { useEffect, useState } from 'react'
import {
  FaUser,
  FaRegEnvelope,
  FaPhone,
  FaPlay,
  FaStop,
  FaGlobe,
} from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useForm } from '../../shared/hooks/form-hook'
import Input from '../../shared/components/FormElements/Input'
import CustomDatePicker from '../../shared/components/UIElements/CustomDatePicker'
import CollapsibleCard from '../../shared/components/UIElements/CollapsibleCard'

import './CardAdminAccount.css'
import errorHandler from '../../shared/util/errorHandler'

function CardAdminAccount({ isSignup }) {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [contractStartDate, setContractStartDate] = useState(null)
  const [contractEndDate, setContractEndDate] = useState(null)
  const [saving, setSaving] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [accountFormState, accountInputHandler, setAccountFormData] = useForm(
    {
      contactName: {
        value: customer.crmDetails?.contactName || customer.name || '',
        isValid: true,
      },
      contactEmail: {
        value: customer.crmDetails?.contactEmail || customer.email || '',
        isValid: true,
      },
      contactNumber: {
        value:
          customer.crmDetails?.contactNumber || customer.contactNumber || '',
        isValid: true,
      },
      url: {
        value: customer.url || '',
        isValid: true,
      },
    },
    true,
  )

  const save = async () => {
    try {
      setSaving(true)

      const queryData = {
        'crmDetails.contactName': accountFormState.inputs.contactName.value,
        'crmDetails.contactEmail': accountFormState.inputs.contactEmail.value,
        'crmDetails.contactNumber': accountFormState.inputs.contactNumber.value,
        'crmDetails.contractStartDate': contractStartDate,
        'crmDetails.contractEndDate': contractEndDate,
        'crmDetails.url': accountFormState.inputs.url.value,
      }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
        'PATCH',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          isSignup,
          data: {
            ...customer,
            crmDetails: {
              ...customer.crmDetails,
              contactName: accountFormState.inputs.contactName.value,
              contactEmail: accountFormState.inputs.contactEmail.value,
              contactNumber: accountFormState.inputs.contactNumber.value,
              contractStartDate: contractStartDate,
              contractEndDate: contractEndDate,
              url: accountFormState.inputs.url.value,
            },
          },
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const changeContractStartDate = async date => {
    setContractStartDate(new Date(date))
  }

  const changeContractEndDate = async date => {
    setContractEndDate(new Date(date))
  }

  const detectChanges = () => {
    const isContactNameDirty =
      accountFormState.inputs.contactName.value !==
      (customer.crmDetails?.contactName || customer.name || '')

    const isContactEmailDirty =
      accountFormState.inputs.contactEmail.value !==
      (customer.crmDetails?.contactEmail || customer.email || '')

    const isContactNumberDirty =
      accountFormState.inputs.contactNumber.value !==
      (customer.crmDetails?.contactNumber || customer.contactNumber || '')

    const isUrlDirty =
      accountFormState.inputs.url.value !== (customer.url || '')

    const isContractStartDateDirty =
      contractStartDate &&
      contractStartDate.getTime() !==
        (customer.crmDetails?.contractStartDate
          ? new Date(customer.crmDetails.contractStartDate).getTime()
          : null)

    const isContractEndDateDirty =
      contractEndDate &&
      contractEndDate.getTime() !==
        (customer.crmDetails?.contractEndDate
          ? new Date(customer.crmDetails.contractEndDate).getTime()
          : null)

    setIsDirty(
      isContactNameDirty ||
        isContactEmailDirty ||
        isContactNumberDirty ||
        isUrlDirty ||
        isContractStartDateDirty ||
        isContractEndDateDirty,
    )
  }

  // We compare the current input values to the initial values at each render.
  useEffect(() => {
    detectChanges()
  })

  useEffect(() => {
    if (customer.crmDetails) {
      setContractStartDate(
        customer.crmDetails.contractStartDate
          ? new Date(customer.crmDetails.contractStartDate)
          : null,
      )
      setContractEndDate(
        customer.crmDetails.contractEndDate
          ? new Date(customer.crmDetails.contractEndDate)
          : null,
      )
    }
  }, [customer])

  useEffect(() => {
    setAccountFormData(
      {
        contactName: {
          value: customer.crmDetails?.contactName || customer.name || '',
          isValid: true,
        },
        contactEmail: {
          value: customer.crmDetails?.contactEmail || customer.email || '',
          isValid: true,
        },
        contactNumber: {
          value:
            customer.crmDetails?.contactNumber || customer.contactNumber || '',
          isValid: true,
        },
        url: {
          value: customer.url || '',
          isValid: true,
        },
      },
      true,
    )
  }, [customer])

  return (
    <CollapsibleCard title="Account" className="sapphire account-card">
      <div className="input-groups-wrapper">
        <div className="input-group">
          <div className="input-cell">
            <label htmlFor="contactName" className="icon">
              <FaUser />
            </label>
            <Input
              element="input"
              placeholder="Name"
              initialValue={accountFormState.inputs.contactName?.value}
              forceValue={accountFormState.inputs.contactName?.value}
              id="contactName"
              type="text"
              onInput={accountInputHandler}
            />
          </div>
          <div className="input-cell">
            <label htmlFor="contactNumber" className="icon">
              <FaPhone />
            </label>
            <Input
              element="input"
              placeholder="Phone Number"
              initialValue={accountFormState.inputs.contactNumber.value}
              forceValue={accountFormState.inputs.contactNumber.value}
              id="contactNumber"
              type="text"
              onInput={accountInputHandler}
            />
          </div>
          <div className="input-cell">
            <label htmlFor="contactEmail" className="icon">
              <FaRegEnvelope />
            </label>
            <Input
              element="input"
              placeholder="Email"
              initialValue={accountFormState.inputs.contactEmail.value}
              forceValue={accountFormState.inputs.contactEmail.value}
              id="contactEmail"
              type="text"
              onInput={accountInputHandler}
            />
          </div>
          <div className="input-cell">
            <label htmlFor="url" className="icon">
              <FaGlobe />
            </label>
            <Input
              element="input"
              placeholder="URL"
              initialValue={accountFormState.inputs.url.value}
              forceValue={accountFormState.inputs.url.value}
              id="url"
              type="text"
              onInput={accountInputHandler}
            />
          </div>
        </div>
        <div className="input-group">
          <span className="contract-text">Contract</span>
          <div className="contract-start-date">
            <span>Start</span>
            <CustomDatePicker
              placeholder="DD/MM/YYYY"
              date={contractStartDate}
              onChange={d => {
                changeContractStartDate(d)
              }}
            />
          </div>
          <div className="contract-end-date">
            <span>End</span>
            <CustomDatePicker
              placeholder="DD/MM/YYYY"
              date={contractEndDate}
              onChange={d => {
                changeContractEndDate(d)
              }}
            />
          </div>
        </div>
      </div>
      {isDirty && (
        <div className="save-button-container">
          <button disabled={saving} className="save-button" onClick={save}>
            {saving ? 'Saving' : 'Save'}
          </button>
        </div>
      )}
    </CollapsibleCard>
  )
}

export default CardAdminAccount
