import { createSlice } from '@reduxjs/toolkit'
import helper from '../shared/util/helper'

const emptyAuthObject = {
  isLoggedIn: false,
  token: null,
  user: null,
  tokenExpirationDate: null,
  projectId: null,
  isLoginStateReady: false,
}


// turn old userData object into array of objects
let userData = JSON.parse(localStorage.getItem('userData') || '[]')
if (!userData.length && userData.token) {
  let newUserDataArray = [{
    ...userData,
    lastLoggedInAccount: true
  }]
  localStorage.setItem('userData', JSON.stringify(newUserDataArray))
}

const authSlice = createSlice({
  name: 'authentication',
  initialState: emptyAuthObject,
  reducers: {
    login(state, action) {
      state.token = action.payload.token
      state.projectId = action.payload.projectId
      state.user = action.payload.user
      state.tokenExpirationDate = action.payload.tokenExpirationDate
      state.isLoggedIn = true

      let updatedUserData = JSON.parse(localStorage.getItem('userData') || '[]')

      updatedUserData = updatedUserData.map(user => ({
        ...user,
        lastLoggedInAccount: false,
      }))

      const existingUserIndex = updatedUserData.findIndex(
        user => user.email === action.payload.user.email,
      )

      if (existingUserIndex !== -1) {
        updatedUserData[existingUserIndex].lastLoggedInAccount = true
      } else {
        // Add the new user to the array with lastLoggedInAccount set to true
        const newUser = {
          token: action.payload.token,
          projectId: action.payload.projectId,
          tokenExpirationDate: action.payload.tokenExpirationDate,
          email: action.payload.user.email,
          lastLoggedInAccount: true,
        }
        updatedUserData.push(newUser)
      }

      // Update localStorage with the modified userData array
      localStorage.setItem('userData', JSON.stringify(updatedUserData))
    },

    logout(state) {
      let updatedUserData = JSON.parse(localStorage.getItem('userData') || '[]')
      const today = new Date()

      updatedUserData = updatedUserData?.map(user => ({
        ...user,
        lastLoggedInAccount: false,
      }))

      //reset the state
      state.token = null
      state.tokenExpirationDate = null
      state.user = null
      state.isLoggedIn = false
      state.currentAudienceIndex = 0
      state.projectId = null

      // Update localStorage with the modified userData array
      localStorage.setItem('userData', JSON.stringify(updatedUserData))

      if (process.env.NODE_ENV === 'production') {
        sessionStorage.clear()
      }
    },

    // switchAccount(state, action) {
    //   const updatedUserData = JSON.parse(
    //     localStorage.getItem('userData') || '[]',
    //   )
    //   const targetEmail = action.payload.email

    //   updatedUserData.forEach((user, index) => {
    //     if (user.email === targetEmail) {
    //       user.lastLoggedInAccount = true
    //       Object.assign(state, user)

    //     } else {
    //       user.lastLoggedInAccount = false
    //     }
    //   })

    //   localStorage.setItem('userData', JSON.stringify(updatedUserData))
    // },

    // TODO: Move the rest of the reducers to the user slice.

    updateSubscription(state, action) {
      let s = state.user.subscription ? state.user.subscription : {}
      Object.keys(action.payload).forEach(key => {
        s[key] = action.payload[key]
      })
      state.user.subscription = s
    },
    cancelSubscription(state) {
      let user = { ...state.user }

      if (user.subscription.customPlan) {
        user.subscription = {
          status: 'passive',
          customPlan: user.subscription.customPlan,
        }
      } else {
        delete user.subscription
      }
      user.subscription = {}
      user.isActive = false
      if (user.shopify) {
        user.shopify = {
          ...user.shopify,
          scriptId: null,
        }
      }
      user.crmDetails = {
        ...user.crmDetails,
        subscription: {
          ...user.crmDetails.subscription,
          status: 'Churn',
        },
      }
      state.user = user
    },

    updateBillingInfo(state, action) {
      let user = {
        ...state.user,
        billingInfo: action.payload,
      }
      state.user = user
    },
    updateGoogleAds(state, action) {
      let user = {
        ...state.user,
        googleAds: {
          ...state.user.googleAds,
          ...action.payload,
        },
      }
      state.user = user
    },
    updateUserObj(state, action) {
      state.user = action.payload
    },
    updateUserObjOneField(state, action) {
      state.user[action.payload.field] = action.payload.value
    },
    completedShopifyStoreConnection(state, action) {
      state.user.shopify.scriptId = action.payload.scriptId
      state.user.isActive = true
      state.user.connectFlow = {
        ...state.user.connectFlow,
        dataCollectionStarted: action.payload.dataCollectionStarted,
        estimatedModelRun: action.payload.estimatedModelRun,
      }
      state.user.connectFlow.dataCollectionStarted =
        action.payload.dataCollectionStarted
      state.user.connectFlow.estimatedModelRun =
        action.payload.estimatedModelRun
    },
    setAuthObject(state, action) {
      state.user = action.payload.user
      state.token = action.payload.token
      state.tokenExpirationDate = action.payload.tokenExpirationDate
      state.projectId = action.payload.projectId
      state.isLoggedIn = true
      state.isLoginStateReady = action.payload.isLoginStateReady
    },
    updateConnectFlow(state, action) {
      state.user = {
        ...state.user,
        connectFlow: action.payload,
      }
    },
    setConnectionsCompleted(state, action) {
      state.user = {
        ...state.user,
        connectionsCompleted: action.payload,
      }
    },
    setIsLoginStateReady(state, action) {
      state.isLoginStateReady = action.payload
    },
  },
})

export const authActions = authSlice.actions

export default authSlice.reducer
