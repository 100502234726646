import pricingConstants from '../../constants-pricing'
import { authActions } from '../../store/auth'

const paymentRequiringRoutes = [
  'connect/google-tag-manager',
  'connect/javascript-sdk',
]


function getPriceFromPlanNameForShopify({ planName, pricingVersion }) {
  if (!planName) {
    return null
  }
  let price = 'No price info'
  let packages;
  if(pricingVersion === "v3") {
    packages = pricingConstants.monthlyPackages
  }else if(pricingVersion === "v2"){
    packages = pricingConstants.v2_packages
  }else{
    packages = pricingConstants.v1_packages
  }
    
  packages.forEach(p => {
    if (planName === p.name) {
      price = p.price
    }
  })
  return price
}

/**
 * Updates the user's subscription details and synchronizes the Redux store.
 *
 * @async
 * @param {Object} user - The logged in user information.
 * @param {Object} subscriptionDetails - The updated subscription details.
 * @param {Object} config - An object containing API-related functions and values.
 * @param {Function} config.sendRequest - The function from useHttpClient hook to make an API request.
 * @param {Function} config.dispatch - The Redux dispatch function.
 * @param {string} config.authToken - The authentication token for API requests.
 * @returns {Promise<void>} A promise that resolves when the subscription is updated.
 */
async function updateUserSubscription(
  user,
  subscriptionDetails,
  config,
) {
  const { sendRequest, dispatch, authToken } = config

  await sendRequest(
    `${process.env.REACT_APP_BACKEND_URL}/users/updateSubscription`,
    'POST',
    JSON.stringify({
      userId: user.id,
      setQuery: { subscription: subscriptionDetails },
    }),
    {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
    },
  )

  dispatch(authActions.updateSubscription(subscriptionDetails))
}

export {
  paymentRequiringRoutes,
  getPriceFromPlanNameForShopify,
  updateUserSubscription,
}
