import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import AIAdsPageLayout from './AIAdsPageLayout'
import CampaignsLeftMenu from './CampaignsLeftMenu'
import Breadcrumb from '../../shared/components/UIElements/Breadcrumb'
import { useAdmin } from '../../shared/hooks/admin-hook'

import './CampaignsPageLayout.css'

const CampaignsPageLayout = ({ children }) => {
  const { campaignInView, enhCampaigns } = useSelector(state => state.facebook)

  const { pathname } = useLocation()
  const newCampaignPage = useMemo(
    () => pathname.includes('/create'),
    [pathname],
  )

  const { navigateWithAdminParams } = useAdmin()

  return (
    <AIAdsPageLayout>
      <div className="campaign-page-layout">
        <CampaignsLeftMenu />
        <AIAdsPageLayout.Content>
          <Breadcrumb>
            <span onClick={() => navigateWithAdminParams('/ai-ads')}>
              Meta Ads
            </span>
            <span
              onClick={() =>
                navigateWithAdminParams('/ai-ads/full-funnel-campaigns')
              }
            >
              Full Funnel Campaigns
            </span>
            {newCampaignPage ? (
              <span
                onClick={() =>
                  navigateWithAdminParams(
                    '/ai-ads/full-funnel-campaigns/create',
                  )
                }
              >
                Create
              </span>
            ) : null}
            {campaignInView && !newCampaignPage ? (
              <span
                onClick={() =>
                  navigateWithAdminParams(
                    `/ai-ads/full-funnel-campaigns?id=${campaignInView._id}`,
                  )
                }
              >
                {campaignInView.label}
              </span>
            ) : null}
          </Breadcrumb>

          <div
            className={`ai-ads-full-funnel-campaigns-grid${
              campaignInView || newCampaignPage || !enhCampaigns.length
                ? ' full'
                : ''
            }`}
          >
            {children}
          </div>
        </AIAdsPageLayout.Content>
      </div>
    </AIAdsPageLayout>
  )
}

export default CampaignsPageLayout
