import React, { useState } from 'react'
import './Editor.css'
import TemplateList from './TemplateList'
import Customization from './Customization'
import Discount from './Discount'
import CatalogPreview from './CatalogPreview'
import { FaCheck } from 'react-icons/fa'

const Editor = props => {
  const [openSections, setOpenSections] = useState({
    template: true,
    customization: false,
    discount: false,
  })

  const toggleSection = sectionName => {
    setOpenSections(prevState => ({
      ...prevState,
      [sectionName]: !prevState[sectionName],
    }))
  }

  return (
    <div className="enhenced-catalog-editor-container">
      <div className="left-side">
        <div className="main-header">
          <h2>Create Enhanced Catalog</h2>
        </div>
        <div className="main-column">
          <div className="flow">
            <div className="section">
              <div
                className="section-header"
                onClick={() => toggleSection('template')}
              >
                <span>1. Select Template</span>
                <div className="done">
                  <FaCheck className="done-icon" />
                </div>
              </div>
              <div className="section-line"></div>
              <div
                className="section-body"
                style={{ display: openSections.template ? 'block' : 'none' }}
              >
                <TemplateList />
              </div>
            </div>

            <div className="section">
              <div
                className="section-header"
                onClick={() => toggleSection('customization')}
              >
                <span>2. Customization</span>
                <div className="done">
                  <FaCheck className="done-icon" />
                </div>
              </div>
              <div className="section-line"></div>
              <div
                className="section-body"
                style={{
                  display: openSections.customization ? 'block' : 'none',
                }}
              >
                <Customization />
              </div>
            </div>

            <div className="section">
              <div
                className="section-header"
                onClick={() => toggleSection('discount')}
              >
                <span>3. Discount</span>
                <div className="done">
                  <FaCheck className="done-icon" />
                </div>
              </div>
              <div className="section-line"></div>
              <div
                className="section-body"
                style={{ display: openSections.discount ? 'block' : 'none' }}
              >
                <Discount />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="right-side">
        <div className="products-previewer">
          <CatalogPreview />
        </div>
      </div>
    </div>
  )
}

export default Editor
