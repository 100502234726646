import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FaArrowRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { useConstants } from '../../shared/hooks/constants-hook'
import { useHttpClient } from '../../shared/hooks/http-hook'
import helper from '../../shared/util/helper'
import Switch from '../../shared/components/UIElements/Switch'
import PricingCard from './PricingCard'
import greenBg from '../../shared/assets/images/admin/green-pricing-background.svg'
import greyBg from '../../shared/assets/images/admin/grey-pricing-background.svg'

import TestimonialsSwiper from './TestimonialsSwiper'

import 'swiper/css'
import './PricingCards.css'
import { useModal } from '../../shared/hooks/modal-hook'

const PricingCards = props => {
  const { pricingConstants: constants } = useConstants()
  const {
    monthlyPackages,
    yearlyPackages,
  } = constants

  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()

  const {
    subscriptionDetails,
    isFromShopify,
    activePkg,
    hasCustomPlan,
    isUpdate,
    openAreYouSureModal,
    countryCode,
  } = props

  const [monthYearSwitch, setMonthYearSwitch] = useState('MONTHLY')
  const [packagesInUse, setPackagesInUse] = useState(yearlyPackages)

  const customPlan = hasCustomPlan ? props.customPlan : null
  const cards = [[], []]
  const auth = useSelector(state => state.auth)
  const user = auth.user

  useEffect(() => {
    setPackagesInUse(
      monthYearSwitch === 'MONTHLY' ? monthlyPackages : yearlyPackages,
    )
  }, [monthYearSwitch])

  const goToBilling = plan => {
    sessionStorage.setItem('tempSubscriptionPlan', JSON.stringify(plan))
    navigate(`/subscription/billing`)
  }

  const getStarted = plan => {
    if (isUpdate) {
      openAreYouSureModal(plan)
      return
    } else {
      let planRef =
        process.env.NODE_ENV === 'production'
          ? countryCode === 'TR'
            ? plan.iyzicoPlanNameTR
            : plan.stripePlanCode
          : plan.stripePlanCode

      if (plan.actionName === 'Book a Demo') {
        window.open('https://enhencer.com/start-now', '_blank')
      } else if (isFromShopify) {
        props.startShopifyPayment({
          price: plan.price,
          planName: plan.name,
          interval: monthYearSwitch,
        })
      } else {
        goToBilling({
          planRef: planRef,
          planName: plan.name,
          planPrice: plan.price,
          currency: '$',
          paymentInterval: monthYearSwitch,
        })
      }
    }
  }

  packagesInUse.forEach((p, i) => {
    if (i < 2) {
      cards[0].push(
        <PricingCard
          key={p.name}
          plan={p}
          countryCode={countryCode}
          isFromShopify={isFromShopify}
          getStarted={getStarted}
          activePkg={activePkg}
          isUpdate={isUpdate}
          openAreYouSureModal={openAreYouSureModal}
        />,
      )
    } else {
      cards[1].push(
        <PricingCard
          key={p.name}
          plan={p}
          countryCode={countryCode}
          isFromShopify={isFromShopify}
          getStarted={getStarted}
          activePkg={activePkg}
          isUpdate={isUpdate}
          openAreYouSureModal={openAreYouSureModal}
        />,
      )
    }
  })

  const startCheckoutForCustomPlan = () => {
    if (isFromShopify) {
      props.startShopifyPayment({
        price: customPlan.price,
        planName: "Custom Plan",
        interval: customPlan.interval || "MONTHLY",
      })

    } else {
      goToBilling({
        planRef: customPlan.refCode,
        planName: 'Custom Plan',
        planPrice:
          customPlan.price + (customPlan.currency === 'TL' ? '₺' : '$'),
        paymentInterval: 'MONTHLY',
      })
    }
  }

  return hasCustomPlan ? (
    <div className="custom-plan-card">
      <div>
        <h2>A custom plan has been assigned to you</h2>
        <div className="price-container">
          <div className="price">
            <div style={{ fontSize: '1.5em' }}>
              {customPlan.currency === 'USD' ? '$ ' : '₺ '} {customPlan.price}
              <span style={{ fontSize: '0.5em' }}>/&nbsp;per&nbsp;month</span>
            </div>
          </div>
          <button onClick={startCheckoutForCustomPlan}>
            <div>Continue</div>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className={`pricing-cards-cont ${monthYearSwitch}`}>
      <h1 className="intro">
        {isUpdate ? (
          <div className="update-plan-header">
            {' '}
            Update Your Plan
            <div
              className="back-button"
              onClick={() => navigate('/account/subscription')}
            >
              Go back
            </div>
          </div>
        ) : (
          <div className="plans-green-header">
            <div className="small-text">Pick a Plan to </div>
            <div className="gradient-text">Grow your Business with AI Ads</div>
          </div>
        )}
      </h1>
      {/* <h4 className="explanation-text">
        Choose Yearly to save <span className="green">≈72%</span> on your AI Ads
        Package and secure your investments with a{' '}
        <span className="green">100% Refund Guarantee</span>
      </h4> */}
      <br></br>
      <div className="switch-cont">
        {!hasCustomPlan && (
          <Switch lowerCaseOptions={true}
            options={['MONTHLY', 'YEARLY']}
            value={monthYearSwitch}
            onChange={setMonthYearSwitch}
          />
        )}
         {/* <div className="yearly-discount-text">
        (Save 50%)   
      </div> */}
      </div>   
      <div className="main-pricing-cards-box">
        <div className="small-row">{cards[0]}</div>
        <div className="small-row">{cards[1]}</div>
      </div>

      <div className="bg-cont">
        <img
          src={greenBg}
        ></img>
      </div>

      <div className="testimonials">
        <TestimonialsSwiper />
      </div>
    </div>
  )
}

export default PricingCards
