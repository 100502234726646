import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "../../store/auth";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useModal } from "../../shared/hooks/modal-hook";
import shopifyBag from "../../shared/assets/images/icons/shopify-bag.png"
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ShopifyConnectStatusBox from "./ShopifyConnectStatusBox";

import 'react-toastify/dist/ReactToastify.css';
import Modals from "../../shared/enums/Modals";

const ShopifyConnect = (props) => {
  const { openModal } = useModal()
  const { isLoading, sendRequest } = useHttpClient();
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth);
  const [conversionID, setConversionID] = useState(auth.user.googleAds?.conversionId);
  const [shopifyTags, setShopifyTags] = useState([]);
  const [shopifyScriptId, setShopifyScriptId] = useState(auth.user.shopify.scriptId);
  const user = auth.user;

  useEffect(() => {
    setShopifyScriptId(auth.user.shopify.scriptId)
  }, [auth.user.shopify.scriptId])

  useEffect(() => {
    const init = async () => {
      if (!shopifyScriptId) {
        try {
          const scriptResult = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/shopify/scriptTags?domain=${user.shopify.domain}&accessToken=${user.shopify.accessToken}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          let tags = scriptResult.script_tags
          if (tags) {
            tags.forEach(tag => {
              // remove(tag.id)
              if (tag.src.includes("cdn.enhencer.com")) {
                setShopifyScriptId(tag.id)
                if (!conversionID && tag.src.includes("g_conv_id")) {
                  setConversionID(tag.src.substring(tag.src.indexOf("g_conv_id") + 10))
                }
              }
            })
            setShopifyTags(tags)
          } else {
            toast.warn("Invalid access token. Please log in again.")
          }

        } catch (err) {
          toast.error(err?.message || "Something went wrong")
        }
      };
    }

    init();
  }, [sendRequest, auth.token]);

  const connectShopifyStore = async () => {

    if (!user.isSpecialUser && (!user.subscription || (user.subscription && user.subscription.status !== 'active'))) {
      openModal({
        type: Modals.PAYMENT_REQUIRED,
        closeOnClickOutside: false,
        from: "connect-overview",
      })
    } else {
      // start loading
      try {
        let res = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/shopify/injectScript`, "post", JSON.stringify({
          id: user.id,
          domain: user.shopify.domain,
          accessToken: user.shopify.accessToken,
          convID: conversionID
        }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        )
        // stop loading

        toast.success("Enhencer connected successfully to your store. Once your store is visited, you will see the details of data collection in this page.")
        dispatch(authActions.updateUserObj({
          ...auth.user,
          shopify: {
            ...auth.user.shopify,
            scriptId: res.tagInjectResult.script_tag.id
          },
          crmDetails: {
            ...auth.user.crmDetails,
            funnel: [
              { key: "gtm_access", step: 1, completed: true },
              { key: "gtm_publish", step: 2, completed: true },
              { key: "data_connected", step: 3, completed: true },
              { key: "facebook_google_audiences", step: 4, completed: false },
              { key: "enhencer_audience_is_ready", step: 5, completed: false },
              { key: "create_facebook_ab_test", step: 6, completed: false },
              { key: "enhencer_audience_in_all_campaigns", step: 7, completed: false },
            ]
          },
          isActive: true,
          connectFlow: {
            ...auth.user.connectFlow,
            dataCollectionStarted: res.updatedUser.connectFlow.dataCollectionStarted,
            estimatedModelRun: res.updatedUser.connectFlow.estimatedModelRun,
          },

        }))
        return true
      } catch (err) {
        // stop loading
        toast.error(err?.message || "Something went wrong")
      }

    }

  }

  return (
    <div>
      {
        isLoading ? <LoadingSpinner /> :
          shopifyScriptId ?
            <div className="no-data">
              <ShopifyConnectStatusBox scriptId={shopifyScriptId} />
            </div>
            :
            <div className="no-data card">
              <div>

                Welcome to Enhencer.
                <br /> <br />
                This is the connect page of the platform. Once Enhencer is
                integrated with your store, you will see the information about the
                store visitors’ data that Enhencer collected automatically.
                <br /> <br />
                To get started, you have to connect your store to Enhencer. If you have any
                type of questions, do not hesitate to <a href="https://enhencer.com/contact-form" target="_blank">contact us</a> or <a href="https://enhencer.com/schedule-a-demo" target="_blank">to schedule a demo</a>.

                <div className="connect-options">
                  <div
                    className="option-button"
                    onClick={connectShopifyStore}
                  >
                    <img alt="shopifybag" src={shopifyBag} />
                    Connect your Store
                  </div>
                </div>

              </div>
            </div>
      }
    </div>

  );
};

export default ShopifyConnect;
