import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_DOMAIN,
  VALIDATOR_PASSWORD_MATCH,
} from '../../shared/util/validators'
import { useForm } from '../../shared/hooks/form-hook'
import { useAuth } from '../../shared/hooks/auth-hook'
import Input from '../../shared/components/FormElements/Input'
import Button from '../../shared/components/FormElements/Button'
import LoginOptions from '../components/LoginOptions'
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png'
import backgroundImage from '../../shared/assets/images/register-decor/register-bg.png'
import balloonIllustration from '../../shared/assets/images/register-decor/register-illustration.png'

import 'react-toastify/dist/ReactToastify.css'
import './Auth.css'

const Register = props => {
  const [busy, setBusy] = useState(false)
  const isMounted = useRef(true)

  const dispatch = useDispatch()
  const { register } = useAuth()

  const query = new URLSearchParams(window.location.search)

  const userName = query.get('name')
  const userEmail = query.get('email')
  const userCompanyDomain = query.get('domain')

  const [formState, inputHandler] = useForm(
    {
      name: {
        value: userName,
        isValid: false,
      },
      email: {
        value: '',
        isValid: false,
      },
      country: {
        value: '',
        isValid: false,
      },
      companyDomain: {
        value: '',
        isValid: false,
      },
      password: {
        value: '',
        isValid: false,
      },
      confirmPassword: {
        value: '',
        isValid: false,
      },
    },
    false,
  )

  useEffect(() => {
    handleAppClass(true)
    return () => {
      handleAppClass(false)
      isMounted.current = false
    }
  }, [dispatch])

  const handleAppClass = t => {
    return t
      ? document.getElementById('app')?.classList.add('full-screen-noscroll')
      : document.getElementById('app')?.classList.remove('full-screen-noscroll')
  }

  const submit = async event => {
    event.preventDefault()

    const { name, email, country, companyDomain, password } = formState.inputs

    setBusy(true)

    await register({
      name: name.value,
      email: email.value,
      country: country.value,
      url: companyDomain.value,
      password: password.value,
    })

    if (isMounted.current) {
      setBusy(false)
    }
  }

  return (
    <div className="full-container sign-up">
      <div className="login-cont sign-up-cont">
        <div className="sign-up-form-cont">
          <div className="mobile-logo-cont">
            <img alt="logo" className="logo" src={enhencerLogo} />
          </div>
          <h1>Create an account</h1>
          <form onSubmit={submit}>
            <Input
              element="input"
              id="name"
              initialValue={userName}
              type="text"
              label="Your Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter your name."
              onInput={inputHandler}
            />

            <Input
              element="input"
              id="email"
              initialValue={userEmail}
              type="email"
              label="E-Mail"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email address."
              onInput={inputHandler}
            />

            <Input
              element="input"
              id="companyDomain"
              initialValue={userCompanyDomain}
              type="text"
              label="Company Domain"
              validators={[VALIDATOR_DOMAIN()]}
              errorText="Please enter a valid company domain."
              onInput={inputHandler}
            />

            <Input
              element="input"
              id="password"
              type="password"
              label="Password"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid password, at least 6 characters."
              onInput={inputHandler}
            />

            <Input
              element="input"
              id="confirmPassword"
              type="password"
              label="Confirm Password"
              validators={[
                VALIDATOR_PASSWORD_MATCH(
                  formState.inputs.password.value,
                  formState.inputs.confirmPassword.value,
                ),
              ]}
              errorText="Passwords doesn't match."
              onInput={inputHandler}
            />

            <div className="small-text">
              <small>
                By clicking on Sign up, you agree to{' '}
                <a
                  href="https://enhencer.com/terms-of-use"
                  className="link-button"
                  target="_blank"
                >
                  terms & conditions
                </a>{' '}
                and{' '}
                <a
                  href="https://enhencer.com/privacy-policy"
                  className="link-button"
                  target="_blank"
                >
                  privacy policy
                </a>
              </small>
            </div>

            <div className="cont">
              <Button loading={busy} className="action-button" type="submit">
                Sign Up
              </Button>
            </div>
          </form>
          <LoginOptions />
          <div className="no-account-row">
            Already have an account?
            <Link className="link-button" to="/login">
              Log In
            </Link>
          </div>
        </div>
      </div>
      <div className="sign-up-info">
        <div className="heading-container">
          <div className="heading">
            <h2>Take Off!</h2>
            <img
              alt="Illustration"
              src={balloonIllustration}
              className="illustration"
            />
          </div>
        </div>
        <div className="information">
          <h3>Why <span className="gradient">Enhencer AI Audiences?</span></h3>
          <p>
            Maximize your ad returns effortlessly using Enhencer. Our AI
            automatically finds the best target audiences for all your
            campaigns.
          </p>
          <div className="feature-list">
            <ul>
              <li>Automated AI Audiences</li>
              <li>Optimize Budget</li>
              <li>High-Converting Visitors</li>
              <li>Higher CTR</li>
              <li>GDPR Compliant</li>
              <li>Lower Cost Per Result</li>
            </ul>
          </div>
        </div>
        <img
          alt="Background Image"
          src={backgroundImage}
          className="background"
        />
      </div>
    </div>
  )
}

export default Register
