import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import { projectActions } from '../../store/project'
import { authActions } from '../../store/auth'
import helper from '../util/helper'

export const useCampaigns = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const user = useSelector(state => state.auth.user)
  const authToken = useSelector(state => state.auth.token)
  const isSpecialUser = useSelector(state => state.auth.user?.isSpecialUser)

  const campaignsFetched = useSelector(state => state.project.campaignsFetched)

  const userInView = useSelector(state => state.admin.userInView)


  useEffect(() => {
    if (campaignsFetched) dispatch(projectActions.setCampaignsFetched(false))

    const getCampaigns = async () => {

      if (isSpecialUser && userInView && userInView.id) {
        if (window.location.pathname.includes('ads-manager')) {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${userInView.id}`,
              'GET',
              null,
              {
                Authorization: 'Bearer ' + authToken,
              },
            )
            dispatch(
              projectActions.setCampaigns({
                campaigns: responseData.campaigns,
              }),
            )
            // dispatch(projectActions.setCampaignsFetched(true))
          } catch (err) {
            toast.error(err?.message || "Something went wrong")
          }
        } else {
          dispatch(projectActions.setCampaigns({ campaigns: ['enhencer'] }))
          // dispatch(projectActions.setCampaignsFetched(true))
        }
      } else if (!isSpecialUser && user?.id) {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/projects/getCampaignsOfUser/${user.id}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + authToken,
            },
          )
          dispatch(
            projectActions.setCampaigns({
              campaigns: responseData.campaigns,
            }),
          )
          dispatch(projectActions.setCampaignsFetched(true))

          if (!user.isActive) {
            const resData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/checkIfDataActive`,
              'POST',
              null,
              {
                Authorization: 'Bearer ' + authToken,
              },
            )

            if (resData.isActive) {
              let connectFlow = { ...user.connectFlow } || {}
              connectFlow['dataCollectionStarted'] =
                resData.dataCollectionStarted
              connectFlow['estimatedModelRun'] = resData.estimatedModelRun
              dispatch(authActions.updateConnectFlow(connectFlow))
            }
          }
        } catch (err) {
          toast.error("here? ", err)
          toast.error(err?.message || "Something went wrong")
        }
      } else {
        dispatch(projectActions.setCampaignsFetched(true))
      }
    }

    getCampaigns()
  }, [userInView, user?.id])

  return {
    campaignsFetched,
  }
}
