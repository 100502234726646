import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaInfoCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo-white.png'
import metaLogo from '../../shared/assets/images/icons/meta-logo.png'
import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'
import Input from '../../shared/components/FormElements/Input'
import FacebookDatePicker from '../../shared/components/UIElements/FacebookDatePicker'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import dateFormatter from '../../shared/util/dateFormatter'

import './AdvancedCampaignReportMobile.css'

const AdvancedCampaignReportMobile = ({
  filter,
  setNewFilter,
  isSample,
  isFetching,
  currencySymbol,
  overallReport,
  reportsInView,
  formState,
  inputHandler,
  campaignLabels,
  campaignSelected,
}) => {
  const facebook = useSelector(state => state.facebook)
  const [report, setReport] = useState(reportsInView[0])

  useEffect(() => {
    setReport(reportsInView[0])
  }, [reportsInView])

  const onAudienceChange = e => {
    const audience = e.target.value
    const report = helper.findBy(reportsInView, 'audienceType', audience)
    setReport(report)
  }

  return (
    <div className="mobile-advanced-campaign-report-wrapper">
      {!isSample && (
        <div className="filters">
          {/* <div className="filter-group">
            <select className="attribution-dropdown">
              <option value="1">7-day click attribution</option>
              <option value="2">1-month click attribution</option>
              <option value="3">3-month click attribution</option>
            </select>
            <FaCaretDown className="down-arrow-icon" />
          </div> */}
          <div className="filter-group">
            <FacebookDatePicker setFilters={setNewFilter} filter={filter} />
            <FaCaretDown className="down-arrow-icon" />
          </div>
        </div>
      )}
      <div className="mobile-advanced-campaign-report">
        <div className="card-header">
          <div className="logo">
            <img
              src={enhencerLogo}
              alt="Enhencer"
              className="enhencer-logo"
              width={110}
            />
            {!isSample ? (
              <div className="region-dropdown">
                <Input
                  element="dropdown"
                  placeholder="Campaign label"
                  initialValue={formState.inputs.campaignLabel.value}
                  forceValue={formState.inputs.campaignLabel.value}
                  id="label"
                  type="text"
                  options={campaignLabels}
                  onInput={inputHandler}
                  apply={campaignSelected}
                />
              </div>
            ) : (
              <div className="sample-text">
                SAMPLE REPORT
                <Tooltip
                  content="The data displayed here is for demonstration purposes and represents sample data. Real data will become available once you start creating your campaigns with Enhencer."
                >
                  <FaInfoCircle className="icon" />
                </Tooltip>
              </div>
            )}
          </div>
          {!isFetching && (
            <React.Fragment>
              <div className="amount">
                <span>
                  {currencySymbol}
                  {numberFormatter.formatNumber(overallReport.spend)}
                </span>
                <span>Total ad-cost</span>
              </div>
              <div className="amount">
                <span>
                  {currencySymbol}
                  {numberFormatter.formatNumber(overallReport.sales)}
                </span>
                <span>Total sales</span>
              </div>
              <div className="amount">
                <span>{overallReport.roas || '-'}</span>
                <span>ROAS</span>
              </div>
              <div className="amount">
                {overallReport.costPerPurchase ? (
                  <span>
                    {currencySymbol}
                    {overallReport.costPerPurchase}
                  </span>
                ) : (
                  <span>-</span>
                )}
                <span>Cost / Purchase</span>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="card-content">
          {isFetching ? (
            <InlineLoadingSpinner message="Fetching ad reports..." />
          ) : (
            <React.Fragment>
              <div className="audience">
                <select onChange={onAudienceChange}>
                  {reportsInView.map(report => (
                    <option
                      value={report.audienceType}
                      key={report.audienceType}
                    >
                      {`${report.audienceType
                        .charAt(0)
                        .toUpperCase()}${report.audienceType.slice(
                        1,
                      )} AI Audience`}
                    </option>
                  ))}
                </select>
                <div className="campaign-info">
                  <div className="line">
                    <img
                      className="meta-icon"
                      src={metaLogo}
                      alt="Meta"
                      width={18}
                    />
                    <span>Conversion Campaign</span>
                  </div>
                  <div className="line">
                    <span className="minor-text">
                      Status:{' '}
                      <span className="value">{report?.effectiveStatus}</span>
                    </span>
                    {report?.startTime && (
                      <span className="minor-text">
                        Start time:{' '}
                        <span className="value">
                          {dateFormatter(report.startTime, true)}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="stats">
                  <div className="stat">
                    {report?.spend ? (
                      <span>
                        {currencySymbol}
                        {numberFormatter.formatNumber(report.spend)}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>Total spent</span>
                  </div>
                  <div className="stat">
                    {report?.dailyBudget ? (
                      <span>
                        {currencySymbol}
                        {numberFormatter.formatNumber(report.dailyBudget) || 0}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>Daily Budget</span>
                  </div>
                </div>
                <div className="hr-divider" />
              </div>
              <div className="row">
                <div className="stats">
                  <div className="stat">
                    <span>
                      {report?.reach
                        ? numberFormatter.formatNumber(report.reach)
                        : '-'}
                    </span>
                    <span>Reach</span>
                  </div>
                  <div className="stat">
                    <span>
                      {report?.impressions
                        ? numberFormatter.formatNumber(report.impressions)
                        : '-'}
                    </span>
                    <span>Impression</span>
                  </div>
                  <div className="stat">
                    <span>
                      {report?.frequency
                        ? numberFormatter.formatNumber(report.frequency)
                        : '-'}
                    </span>
                    <span>Frequency</span>
                  </div>
                  <div className="stat">
                    {report?.cpm ? (
                      <span>
                        <span className="currency">{currencySymbol}</span>
                        {numberFormatter.formatNumber(report.cpm)}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>CPM</span>
                  </div>
                </div>
                <div className="hr-divider" />
              </div>
              <div className="row">
                <div className="stats">
                  <div className="stat">
                    <span>
                      {report?.clicks
                        ? numberFormatter.formatNumber(report.clicks)
                        : '-'}
                    </span>
                    <span>Clicks</span>
                  </div>
                  <div className="stat">
                    {report?.cpc ? (
                      <span>
                        <span className="currency">{currencySymbol}</span>
                        {numberFormatter.formatNumber(report.cpc)}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>CPC</span>
                  </div>
                  <div className="stat">
                    {report?.ctr ? (
                      <span>{numberFormatter.formatNumber(report.ctr)}%</span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>CTR</span>
                  </div>
                  <div className="stat">
                    <span>
                      {report?.addToCart
                        ? numberFormatter.formatNumber(report.addToCart)
                        : '-'}
                    </span>
                    <span>AddtoCart</span>
                  </div>
                </div>
                <div className="hr-divider" />
              </div>
              <div className="row">
                <div className="stats">
                  <div className="stat">
                    <span>
                      {report?.purchase
                        ? numberFormatter.formatNumber(report.purchase)
                        : '-'}
                    </span>
                    <span>Purchase</span>
                  </div>
                  <div className="stat">
                    {report?.costPerPurchase ? (
                      <span>
                        <span className="currency">{currencySymbol}</span>
                        {numberFormatter.formatNumber(report.costPerPurchase)}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>Cost/Purchase</span>
                  </div>
                  <div className="stat">
                    {report?.sales ? (
                      <span>
                        <span className="currency">{currencySymbol}</span>
                        {numberFormatter.formatNumber(report.sales)}
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                    <span>Purchase Value</span>
                  </div>
                  <div className="stat">
                    <span>
                      {report?.roas
                        ? numberFormatter.formatNumber(report.roas)
                        : '-'}
                    </span>
                    <span>ROAS</span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdvancedCampaignReportMobile
