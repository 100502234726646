import React, { useEffect, useState } from 'react';
import { FaStar } from 'react-icons/fa';
import './StarRating.css';

const StarRating = (props) => {
  const [rating, setRating] = useState(props.initialRating ?? 0);
  const [hover, setHover] = useState(0);

  const handleRatingClick = (value) => {
    if (value === rating) {
      setRating(0); // If the clicked star is already selected, return to default value
      props.onSaveRating(0); // Also, save the default value
    } else {
      setRating(value);
      props.onSaveRating(value); // Call the parent component's function to save the rating
    }
    setRating(value);
  };

  useEffect(() => {
    setRating(props.initialRating ?? 0)
  }, [props.initialRating])

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <FaStar
            key={index}
            className="star"
            color={(ratingValue <= (hover || rating)) ? "#85ca6e" : "#e4e5e9"}
            size={12}
            onClick={() => handleRatingClick(ratingValue)}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(0)}
          />
        );
      })}
    </div>
  );
};

export default StarRating;
