import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaTrashAlt } from 'react-icons/fa'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useModal } from '../../shared/hooks/modal-hook'
import CollapsibleCard from '../../shared/components/UIElements/CollapsibleCard'
import CustomDatePicker from '../../shared/components/UIElements/CustomDatePicker'

import './CardAdminNotes.css'
import Modals from '../../shared/enums/Modals'
import errorHandler from '../../shared/util/errorHandler'

const MAX_NOTES_LIMIT = 10

function CardAdminNotes({ isSignUp }) {
  const { openModal } = useModal()
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [notes, setNotes] = useState(customer.crmDetails?.noteList || [])
  const [isReachedLimit, setIsReachedLimit] = useState(false)
  const [saving, setSaving] = useState(false)

  const addNewNote = () => {
    if (notes.length >= MAX_NOTES_LIMIT) {
      return setIsReachedLimit(true)
    }

    setNotes(prevNotes => [
      {
        text: '',
        createdAt: new Date(),
      },
      ...prevNotes,
    ])
  }

  const deleteNote = async index => {
    openModal({
      type: Modals.ARE_YOU_SURE,
      question: 'Are you sure you want to delete this note?',
      callback: async () => {
        const updatedNotes = [...notes]
        updatedNotes.splice(index, 1)

        setNotes(updatedNotes)

        try {
          setSaving(true)

          const trimmedNotes = updatedNotes
            .filter(note => Boolean(note.text))
            .map(note => ({
              ...note,
              text: note.text.trim(),
            }))
          const queryData = { 'crmDetails.noteList': trimmedNotes }

          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
            'PATCH',
            JSON.stringify(queryData),
            {
              Authorization: 'Bearer ' + auth.token,
              'Content-Type': 'application/json',
            },
          )

          dispatch(
            adminActions.findCustomerByIdAndUpdate({
              id: customer.id,
              isSignUp,
              data: {
                ...customer,
                crmDetails: {
                  ...customer.crmDetails,
                  noteList: trimmedNotes,
                },
              },
            }),
          )

          toast.success('Changes saved!')
        } catch (err) {
          errorHandler(err)
        } finally {
          setSaving(false)
        }
      },
    })
  }

  const isNoteChanged = date => {
    // We check if the note is newly added or if the date has been changed,
    // and return true in those cases.
    const savedNote = customer.crmDetails?.noteList?.find(n => {
      return (
        new Date(n.createdAt).getTime() === new Date(date).getTime()
      )
    })

    if (!savedNote) {
      return true
    }

    const activeNote = notes.find(n => n.createdAt === date)

    if (savedNote.text !== activeNote.text) {
      return true
    }

    return false
  }

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes]
    const note = updatedNotes[index]

    updatedNotes[index] = {
      ...note,
      text: value,
    }

    setNotes(updatedNotes)
  }

  const handleDateChange = (index, newDate) => {
    const updatedNotes = [...notes]
    const note = updatedNotes[index]

    updatedNotes[index] = {
      ...note,
      createdAt: new Date(newDate),
    }

    setNotes(updatedNotes)
  }

  const save = async () => {
    try {
      setSaving(true)

      const trimmedNotes = notes.map(note => ({
        ...note,
        text: note.text.trim(),
      }))
      const queryData = { 'crmDetails.noteList': trimmedNotes }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${customer.id}`,
        'PATCH',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: customer.id,
          data: {
            ...customer,
            crmDetails: {
              ...customer.crmDetails,
              noteList: trimmedNotes,
            },
          },
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    } finally {
      setSaving(false)
    }
  }

  useEffect(() => {
    setNotes(customer.crmDetails?.noteList || [])
  }, [customer])

  return (
    <CollapsibleCard title="Notes" className="topaz notes-card">
      <div className="input-row">
        <button
          disabled={isReachedLimit}
          className="new-note-button"
          onClick={addNewNote}
        >
          + New note
        </button>
      </div>
      {notes.map((note, index) => (
        <div className="input-row note-box" key={index}>
          <label htmlFor={note.createdAt} className="note-box-header">
            <FaTrashAlt
              onClick={() => deleteNote(index)}
              className="delete-note-icon"
            />
            <CustomDatePicker
              date={note.createdAt}
              onChange={date => handleDateChange(index, date)}
            />
          </label>
          <textarea
            key={note.createdAt}
            id={note.createdAt}
            value={note.text}
            onChange={event => handleNoteChange(index, event.target.value)}
          ></textarea>
          {isNoteChanged(note.createdAt) && (
            <div className="save-button-container">
              <button
                disabled={!note.text || saving}
                className="save-button"
                onClick={save}
              >
                {saving ? 'Saving' : 'Save'}
              </button>
            </div>
          )}
        </div>
      ))}
    </CollapsibleCard>
  )
}

export default CardAdminNotes
