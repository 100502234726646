import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import helper from '../../shared/util/helper'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import SupportTicketCard from './SupportTicketCard'

import './SupportTicketsList.css'

const SupportTicketsList = (props) => {
  const { lastCursorTickets, tickets } =
    useSelector(state => state.admin)
  const dispatch = useDispatch()

  const ref = useRef()
  const [isFetching, setIsFetching] = useState(props.isFetching)

  const bottomDetector = () => {
    if (helper.isElementInViewport(ref.current) && !isFetching) {
      setIsFetching(true)
      if (lastCursorTickets) {
        props.reFetch()
      } else {
        setIsFetching(false)
      }
    }
  }

  useEffect(() => {
    setIsFetching(props.isFetching)
  }, [props.isFetching])

  useEffect(() => {
    if (!lastCursorTickets && tickets.length) {
      ref.current.style.display = 'none'
    } else {
      ref.current.style.display = 'flex'
    }
  }, [lastCursorTickets])

  return (
    <div className="support-tickets-list-wrapper" onScroll={bottomDetector}>
      <div className="support-tickets-list">
        {tickets.map(ticket => (
          <SupportTicketCard key={ticket._id} ticket={ticket} />
        ))}
      </div>
      <div className="scroll-ending-box" ref={ref}>
        {isFetching && <InlineLoadingSpinner message="Fetching..." />}
      </div>
    </div>
  )
}

export default SupportTicketsList
