import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronRight, FaChevronUp, FaSearch, FaSlidersH } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import './NavBar.css';
import metaLogo from '../../assets/images/icons/meta-logo.png'
import googleAdsLogo from '../../assets/images/icons/google-ads-logo.png'
import tiktokAdsLogo from '../../assets/images/icons/tiktok-logo.png'
import { projectActions } from '../../../store/project';
import { useAdmin } from '../../hooks/admin-hook';

const NavBar = props => {

    const dispatch = useDispatch();
    const location = useLocation();

    const { navigateWithAdminParams } = useAdmin()
    const campaigns = useSelector(state => state.project.campaigns);
    const auth = useSelector((state) => state.auth);
    const currentCampaignId = useSelector(state => state.project.currentCampaignId);
    const [searchText, setSearchText] = useState("")
    const navigate = useNavigate()


    useEffect(() => {
        var barTimeout;
        let scrollable = document.querySelector(".nav-list")
        const handleScroll = () => {
            if (scrollable) {
                if (barTimeout) {
                    clearTimeout(barTimeout); //clear to reset
                }
                barTimeout = setTimeout(() => {
                    scrollable.classList.remove('scrolling');
                }, 500); //0.5s delay
                scrollable.classList.add('scrolling');
            }
        }

        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        }


    }, [])

    const getAudiencePlatformIcon = (platform) => {
        switch (platform) {
            case "Facebook":
                return metaLogo
            case "Google":
                return googleAdsLogo
            case "TikTok":
                return tiktokAdsLogo;
        }
    }

    const selectCampaign = (campaign) => {
        if (campaign) {
            let id = campaign._id
            dispatch(projectActions.selectCampaign({ id: id }))
            let flowStep = location.pathname.split("/")[3]

            navigateWithAdminParams(`/ads-manager/${id}/${flowStep}`)
            
        }
    }

    const isSearchResult = (camp) => {
        return (camp.audienceName?.toLowerCase().includes(searchText?.toLowerCase()) ||
            camp.type?.toLowerCase().includes(searchText?.toLowerCase()) ||
            camp.adPlatform?.toLowerCase().includes(searchText?.toLowerCase()))
    }

    return auth.isLoggedIn && (auth.user.completedRegistration || auth.user.isSpecialUser) && location.pathname.includes("ads-manager") ? (
        <React.Fragment>
            <div className="navbar-container">
                <div className='navbar-content'>

                    <div className='nav-search-bar'>

                        <FaSearch className='search-icon' />

                        <input className='search-input' type="text" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} placeholder='Search Audiences' />
                        <FaSlidersH />

                    </div>

                    <div className='nav-list'>
                        {campaigns.filter(item => isSearchResult(item)).map((campaign, index) => (
                            <div className={
                                (currentCampaignId === campaign.id) ? 'nav-item selected' : 'nav-item'}
                                onClick={() => { selectCampaign(campaign); }} key={index}>
                                <img alt="cmpad" src={getAudiencePlatformIcon(campaign.adPlatform)} />
                                <div className='text-cont'>
                                    <p className='campaign-name'>{campaign.type}</p>
                                    <p className='audience-name'>{campaign.audienceName}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <CollapsedNavbar></CollapsedNavbar>

            </div>
        </React.Fragment>
    ) : null
};

export default NavBar;


const CollapsedNavbar = () => {

    const collapsedNavbarRef = useRef()
    const [isOpen, setIsOpen] = useState(false)

    const toggleNavbar = () => {
        let navbar = document.querySelector(".navbar-container")
        if (navbar.classList.contains("open")) {
            setIsOpen(false)
            navbar.classList.remove("open")
            collapsedNavbarRef.current.classList.remove("open")
        } else {
            setIsOpen(true)
            navbar.classList.add("open")
            collapsedNavbarRef.current.classList.add("open")
        }
    }


    return <div className='collapsed-navbar' ref={collapsedNavbarRef}>
        <div className='toggle-button' onClick={toggleNavbar} title={`${isOpen ? 'Hide' : 'Show'} campaign audiences`}>
            <FaChevronRight className={`icon ${isOpen ? 'reverse' : ''}`} />

        </div>
    </div>
}