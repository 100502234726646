import React from 'react'
import { FaCheck, FaCheckCircle, FaComment, FaCalendar } from 'react-icons/fa'
import fireIcon from '../../shared/assets/images/icons/fire.svg'

import './PricingCard.css'
import { useSelector } from 'react-redux'
import { useModal } from '../../shared/hooks/modal-hook'
import numberFormatter from '../../shared/util/numberFormatter'
import { Link, useNavigate } from 'react-router-dom'

import FreeAdHealthCheckupIcon from '../../shared/assets/images/icons/free-ad-health-checkup.svg'


const PricingCard = props => {
  const { activePkg, isUpdate, openAreYouSureModal } = props
  let {
    name,
    actionName,
    minAdSpent,
    promotionText,
    price,
    additionalFee,
    paymentInterval,
    features,
  } = props.plan

  const { openModal } = useModal()
  const userSubscription = useSelector(state => state.auth.user.subscription)
  const userCrmDetails = useSelector(state => state.auth.user.crmDetails)
  const planNames = ['XSmall', 'Small', 'Medium', 'Large', 'Enterprise']
  const isActivePackage =
    (userSubscription?.plan === name && userSubscription.status === 'active') ||
    ((!userSubscription || userSubscription?.status !== 'active') &&
      name === 'Ad Health Checkup')

  if (isUpdate) {
    const index = planNames.indexOf(activePkg)

    planNames.forEach((plan, i) => {
      if (plan === name && i < index) {
        actionName = 'Downgrade'
      } else if (plan === name && i > index) {
        actionName = 'Upgrade'
      } else if (plan === name && i === index) {
        actionName = 'Your Plan'
      }
    })
  }

  const getDemoMeeting = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        
        url: process.env.REACT_APP_CALENDLY_DEMO_MEETING_LINK,
      })
    }
  }

  return (
    <div
      className={`pricing-card ${isActivePackage ? 'inverted' : ''} ${name === 'Small' || (name === 'Small' && !props.isFromShopify)
          ? 'no-border-small'
          : ''
        } ${name === 'Large' ? 'no-border-medium' : ''
        } ${name === 'Medium' ? 'no-border-large scale-large' : ''} ${name === 'Enterprise' ? 'no-border-enterprise' : ''
        }`}
    >
      {name === 'Medium' && (
        <div className="most-popular-container">
          <div className="row">
            <img alt="fireicon" src={fireIcon} />
            <h3>Most Popular</h3>
          </div>
        </div>
      )}

      <div className="content">

        <div className="plan-name-row">
          <h2>{name}</h2>
          {/* {paymentInterval === 'MONTHLY' && name === 'Starter' && (
            <div className="save-container">
              <div className="row">
                <p>
                  Buy Yearly - <br />{' '}
                  <span className="biger-text">Save $1800</span>
                </p>
              </div>
            </div>
          )}

          {paymentInterval === 'MONTHLY' && name === 'Growth' && (
            <div className="save-container">
              <div className="row">
                <p>
                  Buy Yearly - <br />{' '}
                  <span className="biger-text">Save $3000</span>
                </p>
              </div>
            </div>
          )} */}
        </div>

        {/* <div className="pageview-container">
          {
            minAdSpent &&
            <span className="pageview-number">${minAdSpent} ad spent per month</span>
          }
          <br className="break" />
          <br className="break" />
        </div> */}
        <div className="price-container">
          {paymentInterval === 'YEARLY' && name === 'Starter' && (
            <div className="old-price">
              <span className="strike-through">$3000</span>
            </div>
          )}
          {paymentInterval === 'YEARLY' && name === 'Growth' && (
            <div className="old-price">
              <span className="strike-through">$4800</span>
            </div>
          )}
          {name !== 'Enterprise' && name !== 'Ad Health Checkup' && (
            <div className="price">
              ${numberFormatter.formatNumber(price)}
              <br />
              <span className="per-month">
                &nbsp; per&nbsp;
                {paymentInterval === 'MONTHLY' ? 'month' : 'year'}{' '}
              </span>
            </div>
          )}

          {name == 'Ad Health Checkup' && (
            <div className="custom-price">
              <span className="name" style={{ fontSize: '.6em' }}>
                Free
              </span>
            </div>
          )}
          {name == 'Enterprise' && (
            <div className="custom-price">
              <FaComment className="icon fa-light" />
              <span className="name">Custom&nbsp;Pricing</span>
            </div>
          )}
        </div>

        <div className="pageview-container">
          <span className="promotion-text">{promotionText}</span>
          <br className="break" />
        </div>

        <div className="price-container">
          {name === 'Ad Health Checkup' && (
            <Link
              className={`ad-health-checkup-meeting`}
              to={'/ad-health-checkup'}
            >
              <div>
                <span className="your-plan-text">
                  {' '}
                  <img src={FreeAdHealthCheckupIcon} alt="Ad Health Checkup" className='icon'/>
                  {' '}
                  Start your checkup
                </span>
              </div>
            </Link>
          )}
          {name !== 'Enterprise' && name !== 'Ad Health Checkup' &&
          ( <button
            className={` ${isActivePackage ? 'active-plan-button' : ''}`}
            onClick={() => {
              if (!isActivePackage) props.getStarted(props.plan)
            }}
          >
            <div>
              {isActivePackage ? (
                <span className="your-plan-text">
                  {' '}
                  <FaCheckCircle className="icon" /> Your Plan
                </span>
              ) : (
                actionName
              )}
            </div>
          </button>)}
          {name === 'Enterprise' && (
            <button
              className={`ad-health-checkup-meeting`}
              onClick={() => {
                getDemoMeeting()
              }}
            >
              <div>
                <span className="your-plan-text">
                  {' '}
                  <FaCalendar
                    className="icon"
                    style={{ fontSize: '.9em' }}
                  />{' '}
                  Get Meeting
                </span>
              </div>
            </button>
          )}  
        </div>
        <ul className="features">
          {features.map(feature => (
            <li
              className={feature.isChecked ? '' : 'passive'}
              key={feature.text}
            >
              <FaCheck className="icon" />
              <div>
                <span> {feature.text} </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PricingCard
