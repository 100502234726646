import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import helper from '../../shared/util/helper'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import DemoMeetingCard from './DemoMeetingCard'

import './DemoMeetingsList.css'

const DemoMeetingsList = (props) => {
  const { lastCursorDemoMeetings, demoMeetings } =
    useSelector(state => state.admin)

  const ref = useRef()
  const [isFetching, setIsFetching] = useState(props.isFetching)

  const bottomDetector = () => {
    if (helper.isElementInViewport(ref.current) && !isFetching) {
      setIsFetching(true)
      if (lastCursorDemoMeetings) {
        props.reFetch()
      } else {
        setIsFetching(false)
      }
    }
  }

  useEffect(() => {
    setIsFetching(props.isFetching)
  }, [props.isFetching])

  useEffect(() => {
    if (!lastCursorDemoMeetings && demoMeetings.length) {
      ref.current.style.display = 'none'
    } else {
      ref.current.style.display = 'flex'
    }
  }, [lastCursorDemoMeetings])

  return (
    <div className="demo-meetings-list-wrapper" onScroll={bottomDetector}>
      <div className="demo-meetings-list">
        {demoMeetings.map(demoMeeting => (
          <DemoMeetingCard key={demoMeeting._id} demoMeeting={demoMeeting} />
        ))}
      </div>
      <div className="scroll-ending-box" ref={ref}>
        {isFetching && <InlineLoadingSpinner message="Fetching..." />}
      </div>
    </div>
  )
}

export default DemoMeetingsList
