import React, { useState } from 'react'
import './Discount.css'
import { FaCheck } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons' // faRotateRight ikonunu import et
import Switch from '../../../shared/components/UIElements/Switch' // Switch bileşeninin doğru yolda olduğundan emin olun

const Discount = () => {
  const [applyDiscountActive, setApplyDiscountActive] = useState(true) // Default olarak açık
  const [discountType, setDiscountType] = useState('PERCENTAGE') // Varsayılan olarak 'PERCENTAGE'

  const handleApplyDiscountToggle = () => {
    setApplyDiscountActive(!applyDiscountActive)
  }

  return (
    <div className="discount-main">
      <div className="discount">
        <div className="apply-discount">
          <label>Apply discount:</label>
          <div className="apply-toggle">
            <input
              type="checkbox"
              id="applyToggle"
              checked={applyDiscountActive}
              onChange={handleApplyDiscountToggle}
            />
            <label htmlFor="applyToggle" className="slider round"></label>
          </div>
        </div>
        {applyDiscountActive && (
          <>
            <div className="discount-amount">
              <label>Discount amount:</label>
              <input type="number" />
              <div className="discount-switch">
                <Switch
                  lowerCaseOptions={true}
                  options={['PERCENTAGE', 'AMOUNT']}
                  value={discountType}
                  onChange={setDiscountType}
                />
              </div>
            </div>
            <div className="discount-code">
              <label>Discount code:</label>
              <input type="text" />
              <button className="generate-button">
                Generate <FontAwesomeIcon icon={faRotateRight} />
              </button>
            </div>
          </>
        )}
      </div>
      <button className="discount-save-button">
        Save
        <FaCheck className="save-check" />
      </button>
    </div>
  )
}

export default Discount
