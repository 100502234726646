const monthlyPackages = [
  {
    name: 'Ad Health Checkup',
    minAdSpent: '',
    promotionText:
      'Review your ads with an expert and start your custom AI Ads Journey',
    price: 0,
    paymentInterval: 'MONTHLY',
    actionName: 'Select Plan',
    features: [
      {
        text: 'Ready-to-onboard Checklist',
        isChecked: true,
      },
      {
        text: 'Industrial Trend Comparison',
        isChecked: true,
      },
      {
        text: 'Efficiency Analysis of Your Campaigns',
        isChecked: true,
      },
      {
        text: 'KPI Settings & Your Custom Plan',
        isChecked: true,
      },
      /* Review your ads with an expert
            Compare ads performance with industry standards
            Get your Custom AI Ads pricing */
    ],
  },
  {
    name: 'Starter',
    minAdSpent: '2,500',
    maxAdSpent: '5,000',
    promotionText: <span>1.49% ad revenue fee </span>,
    price: 250,
    additionalFee: 2.49,
    iyzicoPlanNameTR: '6f701b63-84ba-4335-9e49-9c037803e627',
    stripePlanCode:
      process.env.NODE_ENV === 'production'
        ? 'price_1PYTb7DVZxXin8U3nH2ANViL'
        : 'price_1PYTvuDVZxXin8U3OTtUbM7W',
    paymentInterval: 'MONTHLY',
    actionName: 'Select Plan',

    features: [
      {
        text: '$2.000 - $5.000 Monthly Ad-Budget',
        isChecked: true,
      },
      {
        text: 'Full Funnel AI Ads Launcher and Reporter',
        isChecked: true,
      },
      {
        text: 'AI Ads on Meta',
        isChecked: true,
      },
      {
        text: 'Checkup Meetings per every 14 days',
        isChecked: true,
      },
    ],
  },
  {
    name: 'Growth',
    minAdSpent: '5,000',
    maxAdSpent: '10,000',
    promotionText: <span> 1.29% ad revenue fee </span>,
    price: 400,
    additionalFee: 1.99,
    iyzicoPlanNameTR: 'aac271c5-6cf5-48de-9e6e-457c597e82e3',
    stripePlanCode:
      process.env.NODE_ENV === 'production'
        ? 'price_1PYTcJDVZxXin8U36cmE4obq'
        : 'price_1PYTyMDVZxXin8U3xB0AiKXv',
    paymentInterval: 'MONTHLY',
    actionName: 'Select Plan',
    features: [
      {
        text: 'Starter plan benefits +',
        isChecked: true,
      },
      {
        text: 'Up to $10.000 Monthly Ad-Budget',
        isChecked: true,
      },
      {
        text: 'AI Ads on Meta, Google and Tiktok Ads',
        isChecked: true,
      },
      {
        text: 'Weekly Checkup Meetings',
        isChecked: true,
      },
    ],
  },
  {
    name: 'Enterprise',
    minAdSpent: '10,000',
    promotionText:
      'Review your ads with an expert and start your custom AI Ads Journey',
    price: -1,
    iyzicoPlanNameTR: 'f40b64d9-967c-4f5a-8fb2-33b8af607134',
    stripePlanCode:
      process.env.NODE_ENV === 'production'
        ? 'price_1P9RmoDVZxXin8U3nXnxgnGC'
        : 'price_1NviTmDVZxXin8U3zzOFnwSk',
    paymentInterval: 'MONTHLY',
    actionName: 'Book a Demo',
    features: [
      {
        text: 'Growth plan benefits +',
        isChecked: true,
      },
      {
        text: 'Custom Attribution Reporter',
        isChecked: true,
      },
      {
        text: 'No Revenue Fee',
        isChecked: true,
      },
    ],
  },
]

const yearlyPackages = [
  {
    name: 'Ad Health Checkup',
    minAdSpent: '',
    promotionText:
      'Review your ads with an expert and start your custom AI Ads Journey',
    price: 0,
    paymentInterval: 'YEARLY',
    actionName: 'Select Plan',
    features: [
      {
        text: 'Ready-to-onboard Checklist',
        isChecked: true,
      },
      {
        text: 'Industrial Trend Comparison',
        isChecked: true,
      },
      {
        text: 'Efficiency Analysis of Your Campaigns',
        isChecked: true,
      },
      {
        text: 'KPI Settings & Your Custom Plan',
        isChecked: true,
      },
    ],
  },
  {
    name: 'Starter',
    minAdSpent: '2,500',
    maxAdSpent: '5,000',
    promotionText: <span>%100 Money Back Guranteed</span>,
    price: 1500,
    // additionalFee: 0.99,
    iyzicoPlanNameTR: 'a1dee06c-d9b1-474c-a511-79c24ae3eaa4',
    stripePlanCode:
      process.env.NODE_ENV === 'production'
        ? 'price_1PYTiJDVZxXin8U3BOGsalXw'
        : 'price_1NviTmDVZxXin8U3zzOFnwSk',
    paymentInterval: 'YEARLY',
    actionName: 'Select Plan',
    features: [
      {
        text: '$2.000 - $5.000 Monthly Ad-Budget',
        isChecked: true,
      },
      {
        text: 'Full Funnel AI Ads Launcher and Reporter',
        isChecked: true,
      },
      {
        text: 'AI Ads on Meta',
        isChecked: true,
      },
      {
        text: 'Checkup Meetings per every 14 days',
        isChecked: true,
      },
    ],
  },
  {
    name: 'Growth',
    
    minAdSpent: '5,000',
    maxAdSpent: '10,000',
    promotionText: <span>%100 Money Back Guranteed</span>,
    price: 2400,
    // additionalFee: 0.99,
    iyzicoPlanNameTR: '93c83156-2cf7-4a7d-8a37-b1e27e3f4946',

    stripePlanCode:
      process.env.NODE_ENV === 'production'
        ? 'price_1PYTjVDVZxXin8U3ltylNhrO'
        : 'price_1NviTmDVZxXin8U3zzOFnwSk',
    paymentInterval: 'YEARLY',
    actionName: 'Select Plan',
    features: [
      {
        text: 'Starter plan benefits +',
        isChecked: true,
      },
      {
        text: 'Up to $10.000 Monthly Ad-Budget',
        isChecked: true,
      },
      {
        text: 'AI Ads on Meta, Google and Tiktok Ads',
        isChecked: true,
      },
      {
        text: 'Weekly Checkup Meetings',
        isChecked: true,
      },
    ],
  },
  {
    name: 'Enterprise',
    minAdSpent: '10,000',
    promotionText:
      'Review your ads with an expert and start your custom AI Ads Journey',
    price: -1,
    iyzicoPlanNameTR: 'f40b64d9-967c-4f5a-8fb2-33b8af607134',
    stripePlanCode:
      process.env.NODE_ENV === 'production'
        ? 'price_1P9RmoDVZxXin8U3nXnxgnGC'
        : 'price_1NviTmDVZxXin8U3zzOFnwSk',
    paymentInterval: 'YEARLY',
    actionName: 'Book a Demo',
    features: [
      {
        text: 'Growth plan benefits +',
        isChecked: true,
      },
      {
        text: 'Custom Attribution Reporter',
        isChecked: true,
      },
      {
        text: 'No Revenue Fee',
        isChecked: true,
      },
    ],
  },
]


const v2_packages = [
    {
      name: 'Ad Health Checkup',
      price: 0,
    },
    {
      name: 'Starter',
      price: 149,
    },
    {
      name: 'Growth',
      price: 249,
    },
    {
      name: 'Enterprise',
      price: 'Custom Pricing',
    },
  ]

const pricing_v1_features = [
  { text: 'Full Funnel AI Ads Launcher', isChecked: true },
  { text: 'Real Time Reporting', isChecked: true },
  { text: 'Facebook Ads - Remarketing AI Audiences', isChecked: true },
  { text: 'Facebook Ads - Lookalike AI Audiences', isChecked: true },
  { text: 'Google Ads - Remarketing AI Audiences', isChecked: true },
  { text: 'Video Meeting With Ad-Expert', isChecked: true },
]

const v1_packages = [
  {
    name: 'Small',
    minAdSpent: '1,500',
    promotionText: 'Perfect for small businesses',
    price: 149,
    additionalFee: 0.99,
    paymentInterval: 'MONTHLY',
    actionName: 'Select Plan',
    features: pricing_v1_features.slice(0, 4),
  },
  {
    name: 'Medium',
    minAdSpent: '3,000',
    promotionText: 'Perfect for scaling businesses',
    price: 249,
    additionalFee: 0.89,
    paymentInterval: 'MONTHLY',
    actionName: 'Select Plan',
    features: pricing_v1_features,
  },
  {
    name: 'Large',
    minAdSpent: '6,000',
    promotionText: 'Perfect for bigger businesses',
    price: 399,
    additionalFee: 0.79,
    paymentInterval: 'MONTHLY',
    actionName: 'Select Plan',
    features: pricing_v1_features,
  },
  {
    name: 'Enterprise',
    minAdSpent: '6,000+',
    promotionText: 'Perfect for businesses requiring better limits',
    price: 'Custom Pricing',
    actionName: 'Book a Demo',
    paymentInterval: 'MONTHLY',
    features: pricing_v1_features,
  },
]


export default {
  monthlyPackages,
  yearlyPackages,
  v1_packages,
  v2_packages,
}
