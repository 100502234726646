import React, { useCallback, useEffect, useState } from 'react'
import { FaCaretDown, FaInfoCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo-white.png'
import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'
import dateFormatter from '../../shared/util/dateFormatter'
import Input from '../../shared/components/FormElements/Input'
import FacebookDatePicker from '../../shared/components/UIElements/FacebookDatePicker'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import CampaignReportMobile from './CampaignReportMobile'
import useSmallScreen from '../../shared/hooks/small-screen-hook'
import { useFacebook } from '../../shared/hooks/facebook-hook'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { useForm } from '../../shared/hooks/form-hook'
import fbHelper from '../../shared/util/facebookSdkHelper'
import { useAuth } from '../../shared/hooks/auth-hook'
import sampleReportByCategories from '../../shared/data/sample-report-by-categories.json'
import ReportChartCard from './ReportChartCard'

import './CampaignReport.css'

const CampaignReport = () => {
  const { navigateWithAdminParams } = useAdmin()
  const isSmallScreen = useSmallScreen(992)
  const {
    newFilterSelectedForReport,
    newCampaignSelectedForReport,
    getOverallAdsetBasedReportForEnhCampaigns,
    getInsightsForChart,
  } = useFacebook()
  const facebook = useSelector(state => state.facebook)
  const admin = useSelector(state => state.admin)
  const { reportsFilter: filter, enhCampaigns } = facebook

  const [currencySymbol, setCurrencySymbol] = useState(
    facebook.selectedAdAccount?.currency
      ? helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)
      : '$',
  )
  const { auth } = useAuth()

  const [errors, setErrors] = useState({})
  const [overallReport, setOverallReport] = useState({})
  const [reportsInView, setReportsInView] = useState([])
  const [sampleData, setSampleData] = useState({})
  const [selectedCampaign, setSelectedCampaign] = useState(
    helper.findBy(
      enhCampaigns,
      '_id',
      facebook.activeEnhCampaignIdForReports,
    ) || enhCampaigns[0],
  )

  const getSampleDataValueBySelectedCategory = (level, metric) => {
    let userInView = auth.user

    if (auth.user?.isSpecialUser) {
      userInView = admin.userInView
    }

    let selectedCategory = userInView?.userSelectedEnhencerCategories?.[0] || 'Apparel & Accessories'

    if (!sampleReportByCategories[selectedCategory]) {
      selectedCategory = 'Apparel & Accessories'
    }

    return sampleReportByCategories[selectedCategory][level][metric]
  }

  // TODO: Call this getInsightsForChart function in the facebook-hook,
  // while getting the Facebook reports to reduce the amount of request.
  useEffect(() => {
    getInsightsForChart(filter.value)
  }, [filter])

  useEffect(() => {
    const getReportItem = audienceType => {
      return {
        audienceType,
        status: 'ACTIVE',
        spend: getSampleDataValueBySelectedCategory(audienceType, 'totalSpent'),
        dailyBudget: getSampleDataValueBySelectedCategory(
          audienceType,
          'dailyBudget',
        ),
        sales: getSampleDataValueBySelectedCategory(audienceType, 'sales'),
        purchases: getSampleDataValueBySelectedCategory(
          audienceType,
          'purchases',
        ),
        roas: getSampleDataValueBySelectedCategory(audienceType, 'roas'),
        costPerPurchase: getSampleDataValueBySelectedCategory(
          audienceType,
          'cost/purchase',
        ),
      }
    }

    const data = {
      overall: {
        spend: getSampleDataValueBySelectedCategory('total', 'Total ad-cost'),
        sales: getSampleDataValueBySelectedCategory('total', 'Total sales'),
        roas: getSampleDataValueBySelectedCategory('total', 'ROAS'),
      },
      reports: [getReportItem('remarketing'), getReportItem('lookalike')],
    }

    setSampleData(data)
  }, [admin.userInView, auth.user])

  useEffect(() => {
    if (!facebook.fetchingReports) {
      if (facebook.activeEnhCampaignIdForReports === 'allEnhCampaigns') {
        setSelectedCampaign({
          reports: facebook.overallReportForEnhCampaigns,
          label: 'All Campaigns',
        })
      } else if (facebook.activeEnhCampaignIdForReports) {
        setSelectedCampaign(
          helper.findBy(
            enhCampaigns,
            '_id',
            facebook.activeEnhCampaignIdForReports,
          ),
        )
      } else if (enhCampaigns.length) {
        setSelectedCampaign(enhCampaigns[0])
      }
    }
  }, [
    facebook.activeEnhCampaignIdForReports,
    facebook.overallReportForEnhCampaigns,
    enhCampaigns,
    filter,
  ])

  const [formState, inputHandler, setFormData] = useForm(
    {
      campaignLabel: {
        value: selectedCampaign?.label || '',
        isValid: true,
      },
    },
    true,
  )

  const isSample = !enhCampaigns.length || !selectedCampaign
  const isFetching =
    facebook.fetchingReports || facebook.fetching
  const campaignLabels = enhCampaigns.map(c => c.label).concat('All Campaigns')
  const campaignStatus =
    selectedCampaign?.reports?.campaignLevel?.effectiveStatus

  useEffect(() => {
    if (facebook.selectedAdAccount?.currency) {
      setCurrencySymbol(
        helper.getCurrencySymbol(facebook.selectedAdAccount?.currency),
      )
    }
  }, [facebook.selectedAdAccount])

  useEffect(() => {
    if (!facebook.fetchingReports) {
      if (isSample) {
        setReportsInView(sampleData.reports)
        setCurrencySymbol('$')
        setOverallReport({
          spend: getSampleDataValueBySelectedCategory('total', 'Total ad-cost'),
          sales: getSampleDataValueBySelectedCategory('total', 'Total sales'),
          roas: getSampleDataValueBySelectedCategory('total', 'ROAS'),
        })
        return
      }
      let reportsToView = []
      let overallResults = {
        spend: 0,
        sales: 0,
        roas: 0,
      }

      if (selectedCampaign && selectedCampaign.reports) {
        let adsetLevelReports =
          selectedCampaign.reports.adsetLevel[filter.value]
        if (adsetLevelReports) {
          let adsetTypes = Object.keys(adsetLevelReports)
          adsetTypes.sort(fbHelper.sortAdsetsInView)
          adsetTypes.forEach(audType => {
            if (adsetLevelReports[audType].spend) {
              overallResults.spend += adsetLevelReports[audType].spend
            }
            if (adsetLevelReports[audType].sales) {
              overallResults.sales += adsetLevelReports[audType].sales
            }

            reportsToView.push({
              audienceType: audType,
              ...adsetLevelReports[audType],
            })
          })
          if (overallResults.spend) {
            overallResults.roas = (
              overallResults.sales / overallResults.spend
            ).toFixed(2)
          } else {
            overallResults.roas = '-'
          }

          setReportsInView(reportsToView)
          setOverallReport(overallResults)

          setFormData({
            campaignLabel: {
              value: selectedCampaign?.label || '',
              isValid: true,
            },
          })
        }
      }
    }
  }, [isSample, campaignStatus, selectedCampaign, filter, sampleData])

  useEffect(() => {
    if (!selectedCampaign) {
      return
    }

    const { adCampaign, adsets, ads } = selectedCampaign
    const structure = {
      adCampaign: adCampaign?.error,
      adsets: {
        remarketing: adsets?.remarketing?.error,
        lookalike: adsets?.lookalike?.error,
        broad: adsets?.broad?.error,
      },
      ads: {
        remarketing: ads?.remarketing?.error,
        lookalike: ads?.lookalike?.error,
        broad: ads?.broad?.error,
      },
    }

    setErrors(structure)
  }, [selectedCampaign])

  const campaignSelected = label => {
    if (label === 'All Campaigns') {
      getOverallAdsetBasedReportForEnhCampaigns({})
    } else {
      let newCampaign = helper.findBy(enhCampaigns, 'label', label)
      newCampaignSelectedForReport({
        campaign: newCampaign,
      })
    }
  }

  const getError = (level, audienceType) => {
    if (!audienceType) {
      return errors[level]
    }

    return errors[level]?.[audienceType]
  }

  const setNewFilter = filter => {
    if (selectedCampaign) {
      if (facebook.activeEnhCampaignIdForReports === 'allEnhCampaigns') {
        getOverallAdsetBasedReportForEnhCampaigns({ filter })
      } else {
        newFilterSelectedForReport({
          filter,
          campaign: selectedCampaign,
        })
      }
    }
  }

  const goToReportDetails = () => {
    if (!isSample && !isFetching)
      navigateWithAdminParams('/ai-ads/report-details')
  }

  if (isSmallScreen) {
    return (
      <CampaignReportMobile
        filter={filter}
        setNewFilter={setNewFilter}
        isSample={isSample}
        isFetching={isFetching}
        currencySymbol={currencySymbol}
        overallReport={overallReport}
        reportsInView={reportsInView}
        formState={formState}
        inputHandler={inputHandler}
        campaignLabels={campaignLabels}
        campaignSelected={campaignSelected}
        campaignStatus={campaignStatus}
        selectedCampaign={selectedCampaign}
        goToReportDetails={goToReportDetails}
        errors={errors}
      />
    )
  }

  return (
    <div className="campaign-report-wrapper">
      {!isSample ? (
        <div className="filter">
          <FacebookDatePicker setFilters={setNewFilter} filter={filter} />
          <FaCaretDown className="down-arrow-icon" />
        </div>
      ) : null}
      <FluidCard
        className={`campaign-report ${isSample ? 'sample' : ''}`}
        onClick={goToReportDetails}
        title={isSample ? '' : 'Click for detailed report'}
      >
        <FluidCard.Header>
          <div className="header-wrapper">
            <div className="logo">
              <img
                src={enhencerLogo}
                alt="Enhencer"
                className="enhencer-logo"
                width={120}
              />
              {!isSample ? (
                <Tooltip
                  content="A dropdown menu displaying active ads for the selected location or region. Choose another region to view metrics for that specific area."
                  title=""
                >
                  <div className="region-dropdown">
                    <Input
                      element="dropdown"
                      placeholder="Campaign label"
                      initialValue={formState.inputs.campaignLabel.value}
                      forceValue={formState.inputs.campaignLabel.value}
                      id="label"
                      type="text"
                      options={campaignLabels}
                      onInput={inputHandler}
                      apply={campaignSelected}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div className="sample-text">
                  SAMPLE REPORT
                  <Tooltip
                    content="The data displayed here is for demonstration purposes and represents sample data. Real data will become available once you start creating your campaigns with Enhencer."
                    title=""
                  >
                    <FaInfoCircle className="icon" />
                  </Tooltip>
                </div>
              )}
            </div>
            {!isFetching && (
              <React.Fragment>
                <Tooltip
                  content="The combined cost of all currently active campaigns."
                  title=""
                >
                  <div className="amount">
                    <span>
                      <span className="currency">{currencySymbol}</span>
                      {numberFormatter.formatNumber(overallReport.spend)}
                    </span>
                    <span>Total ad-cost</span>
                  </div>
                </Tooltip>
                <Tooltip
                  content="The overall value generated from all campaign-related sales."
                  title=""
                >
                  <div className="amount">
                    <span>
                      <span className="currency">{currencySymbol}</span>
                      {numberFormatter.formatNumber(overallReport.sales)}
                    </span>
                    <span>Total sales</span>
                  </div>
                </Tooltip>
                <Tooltip
                  content="The aggregate Return on Ad Spend across all campaigns."
                  title=""
                >
                  <div className="amount">
                    <span>{overallReport.roas}</span>
                    <span>ROAS</span>
                  </div>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
        </FluidCard.Header>
        <FluidCard.Content>
          <div className="content-wrapper">
            {getError('adCampaign') ? (
              <div className="error-container center">
                <span className="error-user-title">
                  {`Campaign Error: ${getError('adCampaign').error_user_title}`}
                </span>
                <p className="error-user-message">
                  {getError('adCampaign').error_user_msg}
                  {` (#${getError('adCampaign').error_subcode})`}
                </p>
              </div>
            ) : (
              <React.Fragment>
                {isFetching ? (
                  <InlineLoadingSpinner message="Fetching reports..." />
                ) : (
                  reportsInView?.map((report, index) => {
                    if (report.noReport) {
                      return report.element
                    }
                    const {
                      audienceType,
                      effectiveStatus,
                      startTime,
                      adsetId,
                    } = report
                    const adsetsErr = getError('adsets', audienceType)
                    const adsErr = getError('ads', audienceType)
                    const formatAudienceType = type =>
                      type.charAt(0).toUpperCase() + type.slice(1)

                    return (
                      <React.Fragment key={audienceType}>
                        <div className={`row ${audienceType}`} key={audienceType}>
                          <div className="audience">
                            <Tooltip
                              content={
                                audienceType === 'broad'
                                  ? 'It is the industry standard broad audience that is filtered by Enhencer AI, focusing on New Users who may share an interest in your product and brand.'
                                  : audienceType === 'lookalike'
                                    ? 'It is the audience that Enhencer Lookalike targets based on your website data, reaching new potential customers exhibiting behaviors similar to your existing website visitors.'
                                    : audienceType === 'remarketing'
                                      ? 'It is the audience that Enhencer AI targets for Remarketing, reaching only the website visitors who have genuinely expressed interest in your products.'
                                      : audienceType === 'traffic'
                                        ? 'Traffic campaign boosts your website views by reaching to a broad audience.'
                                        : ''
                              }
                              title=""
                            >
                              <span className="primary-text">
                                {audienceType === 'other'
                                  ? 'Custom Ad Sets'
                                  : `${formatAudienceType(audienceType)}${['broad', 'lookalike', 'remarketing'].includes(audienceType) ? ' AI Audience' : ''}`}
                              </span>
                            </Tooltip>
                            {/* <span className='camp-name'>Conversion Campaign</span> */}
                            <Tooltip
                              content="Indicates the effective status of the ad set."
                              title=""
                            >
                              <span className="minor-text">
                                Status:{' '}
                                <span className="value">{effectiveStatus}</span>
                              </span>
                            </Tooltip>
                            {startTime ? (
                              <span className="minor-text">
                                Start date:{' '}
                                <span className="value">
                                  {dateFormatter(startTime, true)}
                                </span>
                              </span>
                            ) : null}
                          </div>
                          {adsetsErr ? (
                            <div className="error-container">
                              <span className="error-user-title">
                                Error: {adsetsErr.error_user_title}
                              </span>
                              <p className="error-user-message">
                                {adsetsErr.error_user_msg}
                                {` (#${adsetsErr.error_subcode})`}
                              </p>
                            </div>
                          ) : adsErr ? (
                            <div className="error-container">
                              <span className="error-user-title">
                                Error: {adsErr.error_user_title}
                              </span>
                              <p className="error-user-message">
                                {adsErr.error_user_msg}
                                {` (#${adsErr.error_subcode})`}
                              </p>
                            </div>
                          ) : (
                            <React.Fragment>
                              <div className="stats">
                                <ReportChartCard
                                  isHidden={isSample}
                                  metric="spend"
                                  adsetId={adsetId}
                                  audienceType={audienceType}
                                  description="The cumulative amount spent across all campaigns."
                                  title="Total Spent"
                                >
                                  <div className="stat">
                                    {report.spend ? (
                                      <span>
                                        <span className="currency">
                                          {currencySymbol}
                                        </span>
                                        {numberFormatter.formatNumber(
                                          report.spend,
                                        )}
                                      </span>
                                    ) : (
                                      <span>-</span>
                                    )}
                                    <span className="metric-title">
                                      Total Spent
                                    </span>
                                  </div>
                                </ReportChartCard>
                                <ReportChartCard
                                  isHidden={isSample}
                                  metric="dailyBudget"
                                  adsetId={adsetId}
                                  audienceType={audienceType}
                                  description="The allocated budget for daily ad spending."
                                  title="Daily Budget"
                                >
                                  <div className="stat">
                                    <span>
                                      <span className="currency">
                                        {currencySymbol}
                                      </span>
                                      {report.dailyBudget
                                        ? numberFormatter.formatNumber(
                                          report.dailyBudget,
                                        )
                                        : 0}
                                    </span>
                                    <span className="metric-title">
                                      Daily Budget
                                    </span>
                                  </div>
                                </ReportChartCard>
                              </div>
                              {
                                audienceType.includes("traffic") ?
                                  <React.Fragment>

                                    <div className="stats">
                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="clicks"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The number of times users have clicked on your ad."
                                        title="Clicks"
                                      >
                                        <div className="stat">
                                          {report.clicks ? (
                                            <span>
                                              {numberFormatter.formatNumber(
                                                report.clicks,
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">Clicks</span>
                                        </div>
                                      </ReportChartCard>

                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="CPC"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The cost incurred for each click on your ad."
                                        title="CPC"
                                      >
                                        <div className="stat">
                                          {report.cpc ? (
                                            <span>
                                              <span className="currency">{currencySymbol}</span>
                                              {numberFormatter.formatNumber(
                                                report.cpc, report.cpc < 0.1 ? 3 : 1
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">
                                            CPC
                                          </span>
                                        </div>
                                      </ReportChartCard>
                                    </div>

                                    <div className="stats">
                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="CPM"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The cost of 1,000 impressions, indicating the cost to reach a thousand users."
                                        title="CPM"
                                      >
                                        <div className="stat">
                                          {report.cpm ? (
                                            <span>
                                              {numberFormatter.formatNumber(
                                                report.cpm,
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">CPM</span>
                                        </div>
                                      </ReportChartCard>

                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="CTR"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The percentage of users who clicked on your ad after seeing it."
                                        title="CTR"
                                      >
                                        <div className="stat">
                                          {report.ctr ? (
                                            <span>
                                              {numberFormatter.formatNumber(
                                                report.ctr,
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">
                                            CTR
                                          </span>
                                        </div>
                                      </ReportChartCard>
                                    </div>

                                  </React.Fragment>

                                  :

                                  <React.Fragment>

                                    <div className="stats">
                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="sales"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The total value generated from sales attributed to the campaigns."
                                        title="Sales"
                                      >
                                        <div className="stat">
                                          {report.sales ? (
                                            <span>
                                              <span className="currency">
                                                {currencySymbol}
                                              </span>
                                              {numberFormatter.formatNumber(
                                                report.sales,
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">Sales</span>
                                        </div>
                                      </ReportChartCard>

                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="purchases"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The number of actual purchases made through the campaigns."
                                        title="Purchases"
                                      >
                                        <div className="stat">
                                          {report.purchases ? (
                                            <span>
                                              {numberFormatter.formatNumber(
                                                report.purchases,
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">
                                            Purchases
                                          </span>
                                        </div>
                                      </ReportChartCard>
                                    </div>

                                    <div className="stats">
                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="roas"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The revenue generated for every dollar spent on advertising."
                                        title="ROAS"
                                      >
                                        <div className="stat">
                                          <span>
                                            {report.roas
                                              ? report.roas.toFixed(2)
                                              : '-'}
                                          </span>
                                          <span className="metric-title">ROAS</span>
                                        </div>
                                      </ReportChartCard>

                                      <ReportChartCard
                                        isHidden={isSample}
                                        metric="costPerPurchase"
                                        adsetId={adsetId}
                                        audienceType={audienceType}
                                        description="The average cost incurred for each purchase made through the campaigns."
                                        title="Cost/Purchase"
                                      >
                                        <div className="stat">
                                          {report.costPerPurchase ? (
                                            <span>
                                              <span className="currency">
                                                {currencySymbol}
                                              </span>
                                              {numberFormatter.formatNumber(
                                                report.costPerPurchase,
                                              )}
                                            </span>
                                          ) : (
                                            <span>-</span>
                                          )}
                                          <span className="metric-title">
                                            Cost/Purchase
                                          </span>
                                        </div>
                                      </ReportChartCard>
                                    </div>

                                  </React.Fragment>
                              }


                            </React.Fragment>
                          )}
                          {!isSample &&
                            audienceType.toLowerCase() === 'remarketing' && (
                              <p className="description">
                                Please be aware that it may take a few days for
                                remarketing ad set to pass the learning phase.
                              </p>
                            )}
                        </div>
                        {reportsInView.length - 1 !== index && reportsInView[index + 1]?.audienceType !== "traffic" && (
                          <div className="divider"></div>
                        )}
                      </React.Fragment>
                    )
                  })
                )}
              </React.Fragment>
            )}
            {/* {!isSample &&
            !getError('adCampaign') &&
            !getError('adsets', 'remarketing') &&
            !getError('adsets', 'lookalike') &&
            !getError('adsets', 'broad') &&
            !getError('ads', 'remarketing') &&
            !getError('ads', 'lookalike') &&
            !getError('ads', 'broad') &&
            reportsInView.length &&
            !isFetching ? (
              <div className="view-details">
                <Link to={`/ai-ads/report-details${location.search}`}>
                  <FaEye className="icon" />
                  Click for detailed report
                </Link>
              </div>
            ) : null} */}
          </div>
        </FluidCard.Content>
      </FluidCard>
    </div>
  )
}

export default CampaignReport
