import React from "react";
import Canvas from "../../shared/components/UIElements/Canvas";
import numberFormatter from "../../shared/util/numberFormatter";

const PurchaseStats = (props) => {
  return (
    <React.Fragment>
      <div className="purchase">
        <div className="step-name">Purchase</div>
        <div className="conversion-badge hidden-500px"></div>
        <div className="canvas-container">
          <Canvas
            start={(props.purchaseRowCount / props.customerRowCount) * 100}
            end={(props.purchaseRowCount / props.customerRowCount) * 100}
            className="funnel-canvas"
          ></Canvas>
        </div>
        <div className="summary-badge">
          <div className="number" title="Visitors">
            {numberFormatter.formatNumber(props.purchaseRowCount)}
          </div>
          <div className="text">Visitors</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PurchaseStats;
