import React, { useEffect, useState } from 'react'

import './AdminPanelDemoMeetingsSummarySection.css'
import BarChart from './BarChart'
import { useSelector } from 'react-redux'
import { FaMapPin } from 'react-icons/fa'
import numberFormatter from '../../shared/util/numberFormatter'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import helper from '../../shared/util/helper'


const AdminPanelDemoMeetingsSummarySection = props => {
  const { panelDemoMeetingSummary } = useSelector(state => state.admin)

  const [isLoading, setIsLoading] = useState(true)
  const [summary, setSummary] = useState({})
  const [isPinned, setPinned] = useState(false);

  const handlePinToggle = () => {
    setPinned(!isPinned);
  };

  useEffect(() => {
    if (panelDemoMeetingSummary) {
      setIsLoading(false)
      setSummary({
        "status.won": {
          "value": (panelDemoMeetingSummary.status_won / panelDemoMeetingSummary.count),
          "title": "Won",
          "totalValue": panelDemoMeetingSummary.status_won,
          "isPercentage": true
        },
        "status.lost": {
          "value": (panelDemoMeetingSummary.status_lost / panelDemoMeetingSummary.count),
          "title": "Lost",
          "totalValue": panelDemoMeetingSummary.status_lost,
          "isPercentage": true
        },
        "status.waitingForResult": {
          "value": (panelDemoMeetingSummary.status_waitingForResult / panelDemoMeetingSummary.count),
          "title": "Waiting for result",
          "totalValue": panelDemoMeetingSummary.status_waitingForResult,
          "isPercentage": true
        },
        "status.rescheduling": {
          "value": (panelDemoMeetingSummary.status_rescheduling / panelDemoMeetingSummary.count),
          "title": "Rescheduling",
          "totalValue": panelDemoMeetingSummary.status_rescheduling,
          "isPercentage": true
        },
        "status.notApplicable": {
          "value": (panelDemoMeetingSummary.status_notApplicable / panelDemoMeetingSummary.count),
          "title": "Not applicable",
          "totalValue": panelDemoMeetingSummary.status_notApplicable,
          "isPercentage": true
        },
        "status.meetingDoneRate": {
          "value": (panelDemoMeetingSummary.status_won + panelDemoMeetingSummary.status_lost + panelDemoMeetingSummary.status_waitingForResult) / (panelDemoMeetingSummary.count),
          "title": "Meeting Done",
          "totalValue": (panelDemoMeetingSummary.status_won + panelDemoMeetingSummary.status_lost + panelDemoMeetingSummary.status_waitingForResult),
          "isPercentage": true
        },
        "status.winLossRatio": {
          "value": (panelDemoMeetingSummary.status_won / (panelDemoMeetingSummary.status_won + panelDemoMeetingSummary.status_lost)),
          "title": "Win-Loss Ratio",
          "totalValue": panelDemoMeetingSummary.status_won + "/" + panelDemoMeetingSummary.status_lost,
          "isPercentage": true,
        },
        "source.outbound": {
          "value": (panelDemoMeetingSummary.source_outbound / panelDemoMeetingSummary.count),
          "title": "Outbound",
          "totalValue": panelDemoMeetingSummary.source_outbound,
          "isPercentage": true
        },
        "source.direct": {
          "value": (panelDemoMeetingSummary.source_direct / panelDemoMeetingSummary.count),
          "title": "Direct",
          "totalValue": panelDemoMeetingSummary.source_direct,
          "isPercentage": true
        },
        "source.organic": {
          "value": (panelDemoMeetingSummary.source_organic / panelDemoMeetingSummary.count),
          "title": "Organic",
          "totalValue": panelDemoMeetingSummary.source_organic,
          "isPercentage": true
        },
        "source.meta": {
          "value": (panelDemoMeetingSummary.source_meta / panelDemoMeetingSummary.count),
          "title": "Meta",
          "totalValue": panelDemoMeetingSummary.source_meta,
          "isPercentage": true
        },
        "source.google": {
          "value": (panelDemoMeetingSummary.source_google / panelDemoMeetingSummary.count),
          "title": "Google",
          "totalValue": panelDemoMeetingSummary.source_google,
          "isPercentage": true
        },
        "source.emailMarketing": {
          "value": (panelDemoMeetingSummary.source_emailMarketing / panelDemoMeetingSummary.count),
          "title": "Email Marketing",
          "totalValue": panelDemoMeetingSummary.source_emailMarketing,
          "isPercentage": true
        },
        "source.signupCall": {
          "value": (panelDemoMeetingSummary.source_signupCall / panelDemoMeetingSummary.count),
          "title": "Signup Call",
          "totalValue": panelDemoMeetingSummary.source_signupCall,
          "isPercentage": true
        },
        "source.signupDemo": {
          "value": (panelDemoMeetingSummary.source_signupDemo / panelDemoMeetingSummary.count),
          "title": "Signup + Demo",
          "totalValue": panelDemoMeetingSummary.source_signupDemo,
          "isPercentage": true
        },
        "source.tidio": {
          "value": (panelDemoMeetingSummary.source_tidio / panelDemoMeetingSummary.count),
          "title": "Tidio",
          "totalValue": panelDemoMeetingSummary.source_tidio,
          "isPercentage": true
        },
        "source.partner": {
          "value": (panelDemoMeetingSummary.source_partner / panelDemoMeetingSummary.count),
          "title": "Partner",
          "totalValue": panelDemoMeetingSummary.source_partner,
          "isPercentage": true
        },
        "source.notSet": {
          "value": (panelDemoMeetingSummary.source_notSet / panelDemoMeetingSummary.count),
          "title": "<Not set>",
          "totalValue": panelDemoMeetingSummary.source_notSet,
          "isPercentage": true
        },
      });
    } else {
      setIsLoading(true)
    }
  }, [panelDemoMeetingSummary])

  const groupDetails = {};
  // Grouping details based on keys
  Object.keys(summary).forEach((key) => {
    const groupKey = key.split('.')[0]; // Extracting the common prefix
    if (!groupDetails[groupKey]) {
      groupDetails[groupKey] = [];
    }
    groupDetails[groupKey].push(summary[key]);
  })
  if (isLoading) {
    return (
      <div></div>
    )
  }

  return (
    <div className={`overlay-panel ${isPinned ? 'pinned' : ''}`}
    >
      <div className='summary-title'>Summary</div>
      <div className={`pin-toggle ${isPinned ? 'pinned' : ''}`} onClick={handlePinToggle} >
        <FaMapPin />
      </div>
      <div className='summary-details'>
        <div className='summary-details-column'>
          <div className='summary-group-container summary-group-data'>
            <div className='title'>Status</div>
            {groupDetails["status"].map((detail, index) => {
              if (detail.isPercentage) {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value * 100)?.toFixed(1)}%
                        </div>
                        <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value ?? 0)?.toFixed(2)}
                        </div>
                        <div className='helper-text'>({isNaN(detail.totalValue) ? detail.totalValue : numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              }
            }
            )}
          </div>
        </div>
        <div className='summary-details-column'>
          <div className='summary-group-container summary-group-data' style={{ flexGrow: 1 }}>
            <div className='title'>Source</div>
            {groupDetails["source"].map((detail, index) => {
              if (detail.isPercentage) {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value * 100)?.toFixed(1)}%
                        </div>
                        <div className='helper-text'>({numberFormatter.formatNumber(detail.totalValue)})</div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              } else {
                return (
                  <Tooltip
                    key={detail.title}
                    content={detail.tooltip ?? ""}
                    isHidden={!detail.tooltip}
                    placement="right">
                    <div key={index} className='summary-details-row'>
                      <div className='data'>
                        <div className='value'>
                          {(detail.value ?? 0)?.toFixed(2)}
                        </div>
                      </div>
                      <div className='description'>{detail.title}</div>
                    </div>
                  </Tooltip>
                )
              }
            })
            }
          </div>
        </div>
      </div>

    </div >
  )
}

export default AdminPanelDemoMeetingsSummarySection

/* 

*/