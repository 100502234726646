import React, { useState, useEffect } from 'react'
import gsap from 'gsap'
import { useDispatch, useSelector } from 'react-redux'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaMinusCircle,
} from 'react-icons/fa'

import { adHealthCheckupActions } from '../../store/ad-health-checkup'
import './AdHealthScore.css'

Chart.register(ArcElement, Tooltip, Legend, Title)

const AdHealthScore = (props) => {
  const dispatch = useDispatch()
  const { score, scoreSummary } = useSelector(
    state => state.adHealthCheckup.score,
  )
  const [scoreStatus, setScoreStatus] = useState('')
  const facebook = useSelector(state => state.facebook)
  const { selectedBusiness, selectedAdAccount, selectedPixel } = facebook;

  const isConnected = props.isConnected

  useEffect(() => {
    if (score < 25) {
      setScoreStatus('low')
    } else if (score < 80) {
      setScoreStatus('medium')
    } else {
      setScoreStatus('high')
    }
  }, [score])
  useEffect(() => {
    gsap.from('.ad-health-score .second-big-text', {
      duration: 1,
      x: -50,
      opacity: 0,
      ease: 'power3.out',
    })
    
  }, [])


  const handleMouseEnterFeedbacks = e => {
    dispatch(adHealthCheckupActions.setMouseWheelActive(false))
  }

  const handleMouseLeaveFeedbacks = e => {
    dispatch(adHealthCheckupActions.setMouseWheelActive(true))
  }

  useEffect(() => {
    return () => {
      dispatch(adHealthCheckupActions.setMouseWheelActive(true))
    }
  }, [])

  const { optimized, critical, needsImprovement } = scoreSummary

  const paragraphs = [
    critical.map(feedback => ({
      paragraph: feedback.paragraph,
      type: 'critical',
    })),
    needsImprovement.map(feedback => ({
      paragraph: feedback.paragraph,
      type: 'needsImprovement',
    })),
    optimized.map(feedback => ({
      paragraph: feedback.paragraph,
      type: 'optimized',
    })),
  ].flat()

  // Calculate the segments
  const remainingSegment = 100 - score

  let backgroundColor
  if (score < 25) {
    backgroundColor = ['#e54343', '#EBEBEB']
  } else if (score <= 70) {
    backgroundColor = ['#e5ae21', '#EBEBEB']
  } else {
    backgroundColor = ['#46b680', '#EBEBEB']
  }

  const data = {
    datasets: [
      {
        data: [score, remainingSegment],
        backgroundColor: backgroundColor,

        borderWidth: 0,
      },
    ],
  }

  const options = {
    rotation: -90,
    circumference: 180,
    cutout: '85%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  }

  const getStatusText = () => {
    if (scoreStatus === 'low') return 'is very low'
    if (scoreStatus === 'medium') return 'is good'
    if (scoreStatus === 'high') return 'is very good'
  }

  const Feedback = ({ type, htmlContent, bgColor }) => {
    const icon = {
      critical: <FaExclamationCircle />,
      needsImprovement: <FaMinusCircle />,
      optimized: <FaCheckCircle />,
    }[type]

    return (
      <div
        className={`content-row ${type}`}
        style={{ backgroundColor: bgColor }}
      >
        <div className="icon">{icon}</div>
        <p dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
    )
  }

  return (
    <div className="content-box ad-health-score">
      <div className="second-big-text">Your Ad Health Score</div>
      <div className="row">
        <div className="graph">
          <Doughnut options={options} data={data} />
          <div className="your-score">
            <div className={scoreStatus}>{score} %</div>
            <div className="smaller-text"> Ad Health Score</div>
          </div>

          <div className="classified">
            <div className="column">
              <div className="secondary-text optimized">{optimized.length}</div>
              <div className="smaller-text">Optimized</div>
            </div>
            <div className="column">
              <div className="secondary-text needs-improvement">
                {needsImprovement.length}
              </div>
              <div className="smaller-text">Needs Improvement</div>
            </div>
            <div className="column">
              <div className="secondary-text critical">{critical.length}</div>
              <div className="smaller-text">Critical</div>
            </div>
          </div>
        </div>

        <div className="missing-components">
          <div className="secondary-text">
            Your Meta Ad-health score{' '}
            <span className={scoreStatus}>{getStatusText()}</span>. We found the
            following missing components.
          </div>

          {!isConnected && (
            <div className="content">
              <div className="content-row critical">
                <div className="icon">
                  <FaExclamationCircle />
                </div>
                {!selectedBusiness && (
                  <p>
                    Connect your Business account to get a detailed Ad Health
                    Score.
                  </p>
                )}
                {selectedBusiness && !selectedAdAccount && (
                  <p>
                    Connect your Ad Account to get a detailed Ad Health Score.
                  </p>
                )}
                {selectedBusiness && selectedAdAccount && !selectedPixel && (
                  <p>
                    Connect your Facebook account to get a detailed Ad Health
                    Score.
                  </p>
                )}
              </div>
            </div>
          )}
          <div
            onMouseEnter={handleMouseEnterFeedbacks}
            onMouseLeave={handleMouseLeaveFeedbacks}
            className="content"
          >
            {paragraphs.map((feedback, index) => (
              <Feedback
                key={index}
                type={feedback.type}
                htmlContent={feedback.paragraph}
                bgColor={index % 2 === 0 ? '#fff' : '#f4f4f4'}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="footer-text">
        <div>
          Implement these suggestions now with your assigned account manager
          with AI Ads.
        </div>
      </div>
    </div>
  )
}

export default AdHealthScore
