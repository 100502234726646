import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate("/ai-ads")
        }, 2000);
    }, [])


    return <div className="audience-manager manager-page">
        <div className="middle-container column">
            This page is deprecated, redirecting to AI Ads home...

        </div>
    </div>
}

export default Home;