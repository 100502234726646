import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaEnvelope } from 'react-icons/fa';
import { toast } from "react-toastify";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { VALIDATOR_EMAIL } from "../../shared/util/validators";

import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo.png';
//import store from "../../store";

const ForgotPassword = props => {

    const [errors] = useState({});
    const [email, setEmail] = useState("");
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [busy, setBusy] = useState(false);

    const onSubmit = async event => {
        event.preventDefault();

        if  (email === "") {
            toast.error("Email is required for reset password!");
        } else {
            setBusy(true)

            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/forgotPassword`,
                "POST",
                JSON.stringify({
                    email: email,
                }),
                {
                    "Content-Type": "application/json",
                }
            );
            if (responseData) {
                toast.success("Password reset email sent successfully!");
            } else {
                toast.error("Failed to send password reset email!");
            }

            setBusy(false)
        }

    };

    return (
        <React.Fragment>
            <ErrorModal error={errors} onClear={clearError} />
            <div className="full-container">

                <div className="login-cont">
                    <div style={{ display: 'flex' }}>
                        <img alt="logo" className="logo" src={enhencerLogo} />
                    </div>
                    <p>Enter your email address and we will send you a link to reset your password.</p>
                    <form onSubmit={onSubmit}>
                        <div className="input-cont">
                            <FaEnvelope className="icon" />
                            <input
                                onChange={(event) => setEmail(event.target.value)}
                                type="email"
                                placeholder="Email Address"
                                name="email"
                                value={props.email}
                                validators={[VALIDATOR_EMAIL()]}

                                />
                            {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}
                        </div>
                        <div style={{marginTop:"15px"}} className="cont">
                            <Button loading={busy} className="action-button" type="submit">
                                Send E-mail
                            </Button>
                        </div>
                    </form>

                    <div style={{ margin: "1em auto", display: "flex" }}>
                    <Link to="/login" style={{ width: "100%", textAlign: "center" }}>Back to login page</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    auth: state.auth,
  });

export default connect(
    mapStateToProps,
    {})(ForgotPassword);