import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa' // İkonu import edin
import Button from '../../shared/components/FormElements/Button'

import './PublishButton.css'
import { useSelector } from 'react-redux'

const PublishButton = ({ className = '', onClick, busy }) => {
  // className varsayılan değeri boş string olarak ayarlandı

  const countries = useSelector(
    state =>
      state.facebookCampaignEditor?.currentCampaign?.targeting?.countries,
  )
  const dailyBudget = useSelector(
    state =>
      state.facebookCampaignEditor?.currentCampaign?.targeting?.dailyBudget,
  )
  const isDailyBudgetMoreThanMinimum = useSelector(
    state =>
      state.facebookCampaignEditor?.currentCampaign?.targeting
        ?.isDailyBudgetMoreThanMinimum,
  )
  const adCreatives = useSelector(
    state => state.facebookCampaignEditor?.currentCampaign?.adCreatives,
  )
  const isCampaignLabelValid = useSelector(
    state => state.facebookCampaignEditor?.currentCampaign?.isLabelValid,
  )

  const isValid =
    countries?.length &&
    dailyBudget &&
    isDailyBudgetMoreThanMinimum &&
    Object.keys(adCreatives).length &&
    isCampaignLabelValid

  return (
    <Button
      onClick={onClick}
      loading={busy}
      disabled={!isValid}
      className={`publish-button ${className}`} // className eklendi
      type="submit"
    >
      <FaCloudUploadAlt className="fa-cloud-upload-alt" /> Publish Campaign
    </Button>
  )
}

export default PublishButton
