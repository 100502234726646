import React from "react";
import colorizer from "../../shared/util/colorizer";
import "./SegmentDetail.css"

const SegmentDetail = (props) => {

    const labels = createLabels(props.segment)

    const labelList = []
    labels.forEach((label, index) => {
        labelList.push(<div key={label} className="ruleset-row">
            <div className="bullet" style={{ background: colorizer.effect(index) }}></div>
            {label}
        </div>)
    })

    return (
        <div className="segment">
            <div className="header">
                <div className="segment-name">
                    Segment {props.segmentNo}
                </div>
                <div className="conversion-container">
                    <div className="number">{(props.segment.propensity * 100).toFixed(2)}%</div>
                    <div className="text">Likely to purchase</div>
                </div>
            </div>
            <div className="cont-box">
                <div className="visitors-who"><b>{props.segment.count}</b> Visitors who...</div>
                <div className="rules">
                    <div className="vertical-line"></div>
                    {labelList}
                </div>
            </div>
        </div>
    );
}

export default SegmentDetail;

function createLabels(segment) {
    let labels = [];
    let tree = segment.tree;
    for (var i = tree.length - 2; i >= 0; i--) {
        let comparisonExpression = tree[i].comparisonExplanationPart === "" ? ": " : tree[i].comparisonExplanationPart;
        let choicePart = ""
        if (tree[i].choicePart !== undefined) {
            var strIndex = tree[i].choicePart.indexOf(' or Missing');
            if (strIndex !== -1) {
                choicePart = tree[i].choicePart.replace(" or Missing", "");
            } else {
                choicePart = tree[i].choicePart
            }
        }


        let label = tree[i].description
        if (comparisonExpression) {
            label += (" " + comparisonExpression + choicePart);
        }

        labels.push(renameRule(label));
    }
    return labels;
}

function renameRule(label) {
    let newLabel = ""


    if (label.includes("Listing Page Visit")) {
        let cat = label.split("(Listing")[0]
        if (label.includes(": 1")) {
            newLabel = "Listed products from `" + cat + "` category"
        } else {
            newLabel = "Didn't list products from `" + cat + "` category"
        }

    } else if (label.includes("Listing Page Count")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Didn't visit any listing pages"
            } else {
                newLabel = "Visited less than " + val + " listing pages"
            }

        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            if (val === "0") {
                newLabel = "Visited at least one listing pages"
            } else {
                newLabel = "Visited more than " + val + " listing pages"
            }

        } else if (label.includes(" ≤ ")) {

            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            if (val === "0") {
                newLabel = "Didn't visit any listing pages"
            } else if (val === "1") {
                newLabel = "Visited at most one listing page"
            } else {
                newLabel = "Visited " + val + " or less listing pages"
            }

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Visited " + val + " or more listing pages"
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Visited " + val + " listing page"
            } else if (val === "0") {
                newLabel = "Didn't visit any listing pages"
            } else {
                newLabel = "Visited " + val + " listing pages"
            }

        }

    } else if (label.includes("(Distinct Count Product)")) {
        let a = label.split("(Distinct")
        let cat = a[0]
        let valuePart = a[1]
        if (valuePart.includes(" < ")) {
            let arr = valuePart.split(" < ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Didn't view products from `" + cat + "` category"
            } else {
                newLabel = "Viewed less than " + val + " products from `" + cat + "` category"
            }

        } else if (valuePart.includes(" > ")) {
            let arr = valuePart.split(" > ")
            let val = arr[arr.length - 1]
            if (val === "0") {
                newLabel = "Viewed at least one product from `" + cat + "` category"
            } else {
                newLabel = "Viewed more than " + val + " products from `" + cat + "` category"
            }

        } else if (label.includes(" ≤ ")) {

            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            if (val === "0") {
                newLabel = "Didn't view products from `" + cat + "` category"
            } else if (val === "1") {
                newLabel = "Viewed at most one product from `" + cat + "` category"
            } else {
                newLabel = "Viewed " + val + " or less products from `" + cat + "` category"
            }

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Viewed " + val + " or more products from `" + cat + "` category"
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Viewed " + val + " product from `" + cat + "` category"
            } else if (val === "0") {
                newLabel = "Didn't viewed products from `" + cat + "` category"
            } else {
                newLabel = "Viewed " + val + " products from `" + cat + "` category"
            }

        }

    } else if (label.includes("Distinct Count Product") && !label.includes("(Distinct Count Product)")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Didn't view any products"
            } else {
                newLabel = "Viewed less than " + val + " distinct products"
            }

        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            if (val === "0") {
                newLabel = "Viewed at least one product"
            } else {
                newLabel = "Viewed more than " + val + " product" + (val !== "1" ? "s" : "")
            }

        } else if (label.includes(" ≤ ")) {

            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            if (val === "0") {
                newLabel = "Didn't view products"
            } else if (val === "1") {
                newLabel = "Viewed at most one product"
            } else {
                newLabel = "Viewed " + val + " or less products"
            }

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Viewed " + val + " or more products"
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Viewed " + val + " product"
            } else if (val === "0") {
                newLabel = "Didn't viewed products"
            } else {
                newLabel = "Viewed " + val + " products"
            }

        }

    } else if (label.includes("Distinct Category Count on Product Page")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            newLabel = "Viewed products in less than " + val + " categories"
        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            newLabel = "Viewed products in more than " + val + " categories"

        } else if (label.includes(" ≤ ")) {
            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            newLabel = "Viewed products in " + val + " or less categories"

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Viewed products in " + val + " or more categories"
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Viewed products in only one category"
            } else if (val === "0") {
                newLabel = "Did not view any products"
            } else {
                newLabel = "Viewed products in " + val + " categories"
            }

        }
    } else if (label.includes("Distinct Category Count on Listing Page")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            newLabel = "Listed products in less than " + val + " categories"
        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            newLabel = "Listed products in more than " + val + " categories"

        } else if (label.includes(" ≤ ")) {
            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            newLabel = "Listed products in " + val + " or less categories"

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Listed products in " + val + " or more categories"
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            if (val === "1") {
                newLabel = "Listed products in only one category"
            } else if (val === "0") {
                newLabel = "Did not list any products"
            } else {
                newLabel = "Listed products in " + val + " categories"
            }
        }
    } else if (label.includes("Average Price Of Visited Products")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            newLabel = "Price of visited products is less than $" + val
        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            newLabel = "Price of visited products is more than $" + val

        } else if (label.includes(" ≤ ")) {
            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            newLabel = "Price of visited products is less than or equal to $" + val

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Price of visited products is more than or equal to $" + val
        }

    } else if (label.includes("User Lifetime")) {

        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            newLabel = "Less than " + val + " days since first visit"

        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            newLabel = "More than " + val + " days since first visit"

        } else if (label.includes(" ≤ ")) {

            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            newLabel = val + " or less days since first visit"

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = val + " or more days since first visit"
        }

    } else if (label.includes("Inactive Days")) {

        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            newLabel = "Active in the last " + (parseInt(val) - 1).toString() + " days"

        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            newLabel = "Inactive for more than " + val + " days"

        } else if (label.includes(" ≤ ")) {

            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            newLabel = "Active in the last " + val + " days"

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            newLabel = "Inactive for " + val + " or more days"
        }
    } else if (label.includes("Average-")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Average of " + varName + " on " + page + " page is less than " + val
        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Average of " + varName + " on " + page + " page is more than " + val

        } else if (label.includes(" ≤ ")) {
            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Average of " + varName + " on " + page + " page is less than or equal to " + val

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Average of " + varName + " on " + page + " page is more than or equal to " + val
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Average of " + varName + " on " + page + " page is equal to " + val
        }
    } else if (label.includes("Maximum-")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Maximum of " + varName + " on " + page + " page is less than " + val
        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Maximum of " + varName + " on " + page + " page is more than " + val

        } else if (label.includes(" ≤ ")) {
            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Maximum of " + varName + " on " + page + " page is less than or equal to " + val

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Maximum of " + varName + " on " + page + " page is more than or equal to " + val
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Maximum of " + varName + " on " + page + " page is equal to " + val
        }
    } else if (label.includes("Minimum-")) {
        if (label.includes(" < ")) {
            let arr = label.split(" < ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Minimum of " + varName + " on " + page + " page is less than " + val
        } else if (label.includes(" > ")) {
            let arr = label.split(" > ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Minimum of " + varName + " on " + page + " page is more than " + val

        } else if (label.includes(" ≤ ")) {
            let arr = label.split(" ≤ ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Minimum of " + varName + " on " + page + " page is less than or equal to " + val

        } else if (label.includes(" ≥ ")) {
            let arr = label.split(" ≥ ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Minimum of " + varName + " on " + page + " page is more than or equal to " + val
        } else if (label.includes(" : ")) {
            let arr = label.split(" : ")
            let val = arr[arr.length - 1]
            let secondArr = arr[0].split("-")
            let page = secondArr[1]
            let varName = secondArr[2]
            newLabel = "Minimum of " + varName + " on " + page + " page is equal to " + val
        }
    }
    else {
        newLabel = label
    }

    return newLabel;
}