import React, { useEffect } from 'react'

import ImageOverlay from './ImageOverlay'
import Modal from './Modal'
import TopBar from '../Navigation/TopBar'
import NavBar from '../Navigation/NavBar'
import BottomNotificationBar from '../UIElements/BottomNotificationBar'
import NotificationPopup from './NotificationPopup'

const AppLayout = ({ children }) => {

  return (
    <div className="App" id="app">
      <NotificationPopup />
      <Modal />
      <TopBar />
      <div className="main-cont">
        {<NavBar />}
        <main>{children}</main>
      </div>
      <BottomNotificationBar />
      <ImageOverlay />
    </div>
  )
}

export default AppLayout
