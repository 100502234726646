import React, { useEffect, useState } from "react";
import "./AdCreativeTypeSelectionTabs.css"
import { useSelector } from "react-redux";
import { useFacebookCampaignEditor } from "../../shared/hooks/facebook-campaign-editor-hook";
import CreativeTypes from "../../shared/enums/CreativeTypes";

const AdCreativeTypeSelectionTabs = ({ hide }) => {

    const activeType = useSelector(state => state.facebookCampaignEditor.currentCampaign?.currentCreative?.type)
    const existingCampaignId = useSelector(state => state.facebookCampaignEditor.currentCampaign?.id)
    const user = useSelector(state => state.auth.user)
    const { changeCreativeType } = useFacebookCampaignEditor()

    let creativeTypes = Object.values(CreativeTypes)
    /* if (user.id === "65eeb1f426b3620ee2ef1cf8") {//TODO bu user'a high-performingleri göster
        creativeTypes = [Object.values(CreativeTypes)]
    } else {
        creativeTypes = ["Craft from Catalog"]
    } */

    return hide ? null : <div className="row input-container-row creative-type-selection-tab">
        <label>
            Source:
        </label>

        <div className="creative-source">
            {creativeTypes.map(type =>
                <div className={`creative-type-option ${type === activeType ? 'active' : ''}`} key={type}
                    onClick={() => { changeCreativeType({ newType: type }) }}
                >
                    <div className="circle"></div>
                    <span className="title">
                        {type}
                    </span>
                </div>
            )}
        </div>
    </div>
}

export default AdCreativeTypeSelectionTabs;