import React from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import paymentRequiredImage from '../../assets/images/payment-required.png'
import { FaTimes } from 'react-icons/fa'

import './PaymentRequired.css'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'
import Modals from '../../enums/Modals'

const PaymentRequired = props => {
  const { closeModal } = useModal()
  const { openModal } = useModal()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)

  const yes = () => {
    closeModal()
    navigate('/account/subscription')
  }
  const closeModalClick = () => {
    return () => {
      closeModal()
      openModal({
        type: Modals.CHECKUP_MEETING,
        closeOnClickOutside: true,
      })


    }
  }

  return (
    <div
      className="are-you-sure payment-required"
      style={{
        backgroundImage: `url(${paymentRequiredImage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
      }}
    >
      <div className="payment-modal-close-button" onClick={closeModalClick()}>
        <FaTimes />
      </div>
      <div className="subscription-header">
        Thank you for <br />
        Choosing Enhencer
      </div>

      <div className="content">
        Welcome to Enhencer, where marketing success meets cutting-edge
        technology! Our platform is designed to transform your advertising
        campaigns and maximize your return on investment.
      </div>
      <div className="second-subscription-header">
        Your Guideline for success!
      </div>

      <div className="progress-container">
        <div className="bar-container">
          <div className="grey-bar"></div>
          <div className="green-bar"></div>
        </div>
        <div className="step-circles">
          <div className="step">
            <div className="circle">1</div>
            <div className="circle-green circle-green-1">1</div>
            <div className="step-text">Install App</div>
          </div>

          <div className="step">
            <div className="circle">2</div>
            <div className="circle-green circle-green-2 ">2</div>
            <div className="step-text">Connect Facebook Account</div>
          </div>

          <div className="step">
            <div className="circle">3</div>
            <div className="circle-green circle-green-3 ">3</div>
            <div className="step-text">Launch AI Ads!</div>
          </div>
        </div>
      </div>
      <div className="subscription-content">
        <div className="content-1">
          <span>6x</span>&nbsp; Conversion Rate
        </div>
        <div className="content-2">
          <span>30% </span>&nbsp; Higher Revenue
        </div>
        <div className="content-3">
          <span>2x</span>&nbsp; ROAS
        </div>
      </div>

      <div className="subscription-footer">
        <div className="action-bar" style={{ display: 'contents' }}>
          <div className="watch-video-button">
            <a
              href="https://www.youtube.com/watch?v=Ci34rFSNesc"
              style={{
                color: '#7EBF59',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                gap: '0.5em',
                lineHeight: '24px'
              }}
              target="_blank"
            >
              Watch explainer video
            </a>
          </div>

          <Button
            className="action-button-subscribe"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5em',
            }}
            onClick={yes}
          >
            Subscribe
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PaymentRequired
