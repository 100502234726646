import React, { useEffect } from 'react'
import './StartYourJourney.css'
import gsap from 'gsap'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

import lacoste from '../../shared/assets/images/ad-health-checkup/success-cards/lacoste.png'
import oliviaJ from '../../shared/assets/images/ad-health-checkup/success-cards/olivia-j.png'
import maaji from '../../shared/assets/images/ad-health-checkup/success-cards/Maaji.png'
import ninnimo from '../../shared/assets/images/ad-health-checkup/success-cards/ninnimo.png'
import suiSleep from '../../shared/assets/images/ad-health-checkup/success-cards/sui-sleep.jpg'
import suiLogo from '../../shared/assets/images/ad-health-checkup/success-cards/sui-logo.png'
import lacosteLogo from '../../shared/assets/images/ad-health-checkup/success-cards/lacoste-logo.png'
import ninnimoLogo from '../../shared/assets/images/ad-health-checkup/success-cards/ninnimo-logo.svg'
import { FaArrowRight, FaCalendar } from 'react-icons/fa'

const StartYourJourney = () => {
  useEffect(() => {
    gsap.from('.start-your-journey-main .left', {
      duration: 1,
      x: -50,
      opacity: 0,
      ease: 'power3.out',
    })
    gsap.from('.start-your-journey-main .header', {
      duration: 1,
      y: -50,
      opacity: 0,
      ease: 'power3.out',
    })
    gsap.from('.start-your-journey-main .box', {
      duration: 1,
      x: 50,
      opacity: 0,
      stagger: 0.2,
      ease: 'power3.out',
    })
  }, [])
  const getDemoMeeting = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: process.env.REACT_APP_CALENDLY_DEMO_MEETING_LINK,
      })
    }
  }

  const handleCardClick = url => {
    window.open(url, '_blank')
  }

  return (
    <div className="content-box start-your-journey-main">
      <div className="column left">
        <Swiper
          className="start-your-journey-slider"
          modules={[Navigation, Autoplay]}
          spaceBetween={40}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          direction="horizontal"
          navigation={{ clickabke: true }}
        >
          <SwiperSlide>
            <div
              className="success-card"
              onClick={() =>
                handleCardClick('https://enhencer.com/stories/lacoste')
              }
            >
              <img src={lacoste} alt="Success Story lacoste" />
              <img className="logo" src={lacosteLogo}></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="success-card"
              onClick={() =>
                handleCardClick('https://enhencer.com/stories/oliviaJ')
              }
            >
              <img src={oliviaJ} alt="Success Story oliviaJ" />
              <img
                className="logo"
                src="https://cdn.enhencer.com/website-assets/images/success-stories/oliviaj-logo.png"
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="success-card"
              onClick={() =>
                handleCardClick('https://enhencer.com/stories/maaji')
              }
            >
              <img src={maaji} alt="Success Story maaji" />
              <img
                className="logo"
                src="https://cdn.enhencer.com/website-assets/images/success-stories/maaji/logo.svg"
              ></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="success-card"
              onClick={() =>
                handleCardClick('https://enhencer.com/stories/ninnimo')
              }
            >
              <img src={ninnimo} alt="Success Story ninnimo" />
              <img className="logo" src={ninnimoLogo}></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              className="success-card"
              onClick={() =>
                handleCardClick('https://enhencer.com/stories/sui-sleep')
              }
            >
              <img src={suiSleep} alt="Success Story suiSleep" />
              <img className="logo sui" src={suiLogo}></img>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="column right">
        <div className="header">
          <div className="second-big-text">Start your</div>
          <div className="big-text gradient-text"> AI Ads Journey</div>
        </div>

        <div className="box gradient">
          <div className="secondary-text ">60% Discount on Yearly Plans</div>
          <div className="smaller-text">
            Boost your Meta Ads with Full Funnel AI Ads Launcher and Reporter
            with weekly meetings.{' '}
          </div>
          <div
            className="button"
            onClick={() => (window.location.href = '/account/subscription')}
          >
            <FaArrowRight />
            Choose your Plan
          </div>
        </div>
        <div className="box">
          <div className="secondary-text gradient-text">
            Free Onboarding meeting
          </div>
          <div className="smaller-text">
            Review your ads with an expert and start your custom AI Ads Journey{' '}
          </div>
          <div
            className="button"
            onClick={() => {
              getDemoMeeting()
            }}
          >
            <FaCalendar />
            Schedule Your Meeting
          </div>
        </div>
      </div>
    </div>
  )
}

export default StartYourJourney
