import React, { useEffect, useState } from "react";
import { FaArrowRight, FaFacebookF, FaGoogle, FaLink, FaPaperclip, FaTiktok } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ControlCenter from "../../shared/components/Navigation/ControlCenter";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ProgressBar from "../../shared/components/UIElements/ProgressBar";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "./ConnectionConfig.css"
import { getShortDateString } from "../../shared/util/dateFormatter";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NewConnectFacebook from "../components/NewConnectFacebook";
import ChecklistCard from "../../shared/components/UIElements/ChecklistCard";
import Button from "../../shared/components/FormElements/Button";
import helper from "../../shared/util/helper";
import NonShopifyStartSubscription from "../components/NonShopifyStartSubscription";
import ShopifyStartSubscription from "../components/ShopifyStartSubscription";
import NewStoreConnect from "../components/NewStoreConnect";
import AudienceCreationBox from "../components/AudienceCreationBox";

const ConnectionConfig = () => {

  const user = useSelector(state => state.auth.user);
  const admin = useSelector(state => state.admin)
  const userInView = user?.isSpecialUser ? admin.userInView : user
  const storeConnected = userInView?.shopify ? userInView?.shopify?.scriptId : userInView?.connectFlow?.dataCollectionStarted;
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [percentage, setPercentage] = useState(0)
  const facebook = useSelector(state => state.facebook)

  const isLoggedInToFacebook = useSelector(state => state.facebook.isLoggedIn)
  const selectedBusiness = useSelector(state => state.facebook.selectedBusiness)
  const businessSelectionIsEligible = useSelector(state => state.facebook.businessSelectionIsEligible)
  const selectedAdAccount = useSelector(state => state.facebook.selectedAdAccount)
  const adAccountSelectionIsEligible = useSelector(state => state.facebook.adAccountSelectionIsEligible)
  const selectedPixel = useSelector(state => state.facebook.selectedPixel)
  const pixelSelectionIsEligible = useSelector(state => state.facebook.pixelSelectionIsEligible)
  const hasAudienceError = useSelector(state => state.facebook.hasAudienceError)
  const fbAudiences = useSelector(state => state.facebook.audiences)


  const [stepCompletions, setStepCompletions] = useState({
    subscribed: (userInView.subscription && userInView.subscription?.status === 'active'),
    storeConnected: !!storeConnected,
    loggedInToFacebook: false,
    selectedBusiness: false,
    selectedAdAccount: false,
    selectedPixel: false,
    hasAudiencesWithoutError: false
  })

  const location = useLocation()

  useEffect(() => {
    if (location.search !== "") {
      const query = new URLSearchParams(window.location.search);
      if (query.get("io")) {
        const io = parseInt(query.get("io"))
        
      }
    }
  }, [location])

  useEffect(() => {
    let p = 0

    setStepCompletions({
      subscribed: (userInView.subscription && userInView.subscription?.status === 'active'),
      storeConnected: !!storeConnected,
      loggedInToFacebook: isLoggedInToFacebook,
      selectedBusiness: isLoggedInToFacebook && selectedBusiness?.id && businessSelectionIsEligible,
      selectedAdAccount: isLoggedInToFacebook && selectedAdAccount?.id && adAccountSelectionIsEligible,
      selectedPixel: isLoggedInToFacebook && selectedPixel?.id && pixelSelectionIsEligible,
      hasAudiencesWithoutError: selectedAdAccount && !hasAudienceError && fbAudiences[selectedAdAccount.id]
    })

    if ((userInView.subscription && userInView.subscription?.status === 'active')) {
      p += 10
    }

    if (storeConnected) {
      p += 10
    }
    if (storeConnected && isLoggedInToFacebook) {
      p += 20
    }
    if (storeConnected && isLoggedInToFacebook && selectedBusiness?.id && businessSelectionIsEligible) {
      p += 20
    }
    if (storeConnected && isLoggedInToFacebook && selectedAdAccount?.id && adAccountSelectionIsEligible) {
      p += 20
    }
    if (storeConnected && isLoggedInToFacebook && selectedPixel?.id && pixelSelectionIsEligible) {
      p += 10
      if (!hasAudienceError && fbAudiences[selectedAdAccount.id]) {
        p += 10
      }
    }

    setPercentage(p)
  }, [facebook, storeConnected])

  return <div className="config-page">
    <h1>Connections</h1>

    <div className="process-status-box">
      <div className="process-text">Integration Process Completed: &nbsp;
        <span className="emphesize">{percentage}%</span>
      </div>
      <ProgressBar percentage={percentage} height={14} />
    </div>

    {isLoading ? <LoadingSpinner /> :
      <React.Fragment>
        {percentage === 100 &&
          <div className="config-completed">
            Your connections are completed, start your campaigns right away!
            <Button to="/ai-ads">
              Go to campaigns
              <FaArrowRight />
            </Button>
          </div>
        }
        <div className="config-grid configure-facebook">

          {userInView?.shopify
            ? <ChecklistCard number={1} name="Start Your Subscription" completed={stepCompletions.subscribed}>
              <ShopifyStartSubscription userInView={user.isSpecialUser ? userInView : null} />
            </ChecklistCard>
            : <>
              <ChecklistCard
                number={1}
                name="Start Your Subscription"
                completed={stepCompletions.subscribed}>
                <NonShopifyStartSubscription
                  userInView={user.isSpecialUser ? userInView : null}
                />
              </ChecklistCard>
              <ChecklistCard number={2} name="Connect Your Store" completed={stepCompletions.storeConnected} >
                <NewStoreConnect
                  disabled={!stepCompletions.subscribed}
                  completed={!stepCompletions.storeConnected}
                  userInView={user.isSpecialUser ? userInView : null}
                />
              </ChecklistCard>
            </>
          }
          {stepCompletions.subscribed && stepCompletions.storeConnected &&
            <NewConnectFacebook
              stepCompletions={stepCompletions}
              userInView={user.isSpecialUser ? userInView : null}
            />
          }

          {stepCompletions.storeConnected && stepCompletions.selectedPixel &&
            <AudienceCreationBox
              hasAudiencesWithoutError={stepCompletions.hasAudiencesWithoutError}
              userInView={user.isSpecialUser ? userInView : null}
            />
          }

        </div>
      </React.Fragment>
    }
  </div>
}

export default ConnectionConfig;
