import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProductSetDropdownHover from '../ProductSetDropdownHover'
import { useFacebook } from '../../../shared/hooks/facebook-hook'
import CustomDropdown from '../CustomDropdown'
import './ProductsSelectionList.css'
import AdminSearchBar from '../../../admin/components/AdminSearchBar'

import circleCheck from '../../../shared/assets/images/icons/circle-check.svg'
import circleCheckFilled from '../../../shared/assets/images/icons/circle-check-filled.svg'
import helper from '../../../shared/util/helper'
import { FaCheck, FaCheckCircle, FaCircle } from 'react-icons/fa'

const ProductsSelectionList = () => {
  const { getCatalogs, getProductSetsOfCatalog, getProductsOfEdge } =
    useFacebook()
  const catalogs = useSelector(state => state.facebook.catalogs)
  const productSets = useSelector(state => state.facebook.productSets)
  const facebookReady = useSelector(
    state => state.facebook.connectionPageIsReady,
  )

  const [selectedCatalog, setSelectedCatalog] = useState(
    catalogs[0] || 'Select catalog',
  )
  const [selectedProductSet, setSelectedProductSet] =
    useState('Select product set')
  const [productsList, setProductsList] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const fetchCatalogs = async () => {
      let newCatalogs
      if (!catalogs.length) {
        newCatalogs = await getCatalogs({})
        setSelectedCatalog(newCatalogs[0])
      }
    }

    if (facebookReady) fetchCatalogs()
  }, [facebookReady])

  useEffect(() => {
    const fetchProductSets = async catalogId => {
      let newProductSets
      if (!productSets[catalogId]?.length) {
        newProductSets = await getProductSetsOfCatalog({ catalogId })
        setSelectedProductSet(newProductSets[0])
      }
    }

    const fetchProducts = async edgeId => {
      console.log('will fetch')
      const products = await getProductsOfEdge({
        edgeId,
        fields: 'image_url,name,price,url',
      })
      setProductsList(products)
    }

    console.log('now ', productSets, 'catalog id: ', selectedCatalog?.id)

    if (facebookReady && selectedCatalog) {
      fetchProducts(selectedCatalog.id)
      // fetchProductSets(selectedCatalog.id)
    }
  }, [selectedCatalog])

  const searchProducts = async v => {
    setSearchText(v)
    let filter = JSON.stringify({
      name: {
        contains: v,
      },
    })
    const products = await getProductsOfEdge({
      edgeId: selectedCatalog.id,
      fields: 'image_url,name,price,url',
      filter,
    })
    setProductsList(products)
  }

  const toggleProduct = product => {
    let index = helper.findIndex(selectedProducts, 'id', product.id)
    let newSelectedProducts = [...selectedProducts]
    if (index >= 0) {
      newSelectedProducts.splice(index, 1)
    } else {
      newSelectedProducts.push(product)
    }

    setSelectedProducts(newSelectedProducts)
  }

  return (
    <div className="product-selection-list">
      <div className="left-side">
        <div className="main-header">
          <h2>Enhenced Catalog&nbsp; &#x2022;</h2>
          <h4>Products</h4>
        </div>
        <div className="main-column">
          <div className="header">
            {catalogs.length ? (
              <div className="row catalog-row">
                <CustomDropdown
                  placeholder="Select catalog..."
                  initialValue={selectedCatalog}
                  forcedValue={selectedCatalog}
                  options={catalogs}
                  isSingleLine={true}
                  onChange={c => {
                    setSelectedCatalog(c)
                  }}
                  onHover={(edgeId, product_count) => (
                    <ProductSetDropdownHover
                      edgeId={edgeId}
                      productCount={product_count}
                    />
                  )}
                />
              </div>
            ) : null}
            <AdminSearchBar
              doSearch={searchProducts}
              searchText={searchText}
              placeholder="Search products"
            />
          </div>
          <div className="products-list">
            {productsList?.length ? (
              <div className="products-grid">
                {productsList.map(product => (
                  <div
                    className="product-container"
                    key={product.id}
                    onClick={() => {
                      toggleProduct(product)
                    }}
                  >
                    <div className="select-circle">
                      {selectedProducts.map(p => p.id).includes(product.id) ? (
                        <FaCheck className="check-icon icon" />
                      ) : null}
                    </div>
                    <div className="product-image">
                      <img src={product.image_url} />
                    </div>
                    <div className="price">{product.price}</div>
                    <div className="name-cont">
                      <div className="name">{product.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="helper-text">No products found</div>
            )}
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="right-side">
        <div className="products-previewer">
          <div className="header">
            <h4>Selected Products ({selectedProducts.length})</h4>
          </div>
          {selectedProducts?.length ? (
            <div className="selected-products-list">
              <div className="products-grid">
                {selectedProducts.map(product => (
                  <div
                    className="product-container"
                    key={product.id}
                    onClick={() => {
                      toggleProduct(product)
                    }}
                  >
                    <div className="select-circle">
                      {selectedProducts.map(p => p.id).includes(product.id) ? (
                        <FaCheck className="check-icon icon" />
                      ) : null}
                    </div>
                    <div className="product-image">
                      <img src={product.image_url} />
                    </div>
                    <div className="price">{product.price}</div>
                    <div className="name-cont">
                      <div className="name">{product.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="helper-text">Start selecting products below</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductsSelectionList
