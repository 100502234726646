import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { authActions } from '../../store/auth'
import { useHttpClient } from './http-hook'
import helper from '../util/helper'
import { useEffect, useState } from 'react'
import cookies from '../util/cookies'



// Gets the config document from DB to check if the
// localStorage, sessionStorage should be cleaned
// or user should be forced to log out.

export const useConfig = () => {
  const dispatch = useDispatch()
  const { sendRequest } = useHttpClient()
  const navigate = useNavigate()
  const userEmail = useSelector(state => state.auth.user?.email)
  const isSpecialUser = useSelector(state => state.auth.user?.isSpecialUser)
  const [completedCheck, setCompletedCheck] = useState(false)

  const getConfigInfo = async () => {
    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/getConfigInfo`,
        'GET',
        null,
        { 'Content-Type': 'application/json' },
      )

      let config = result.config
      const version = config.version
      if (isSpecialUser) config = config.admin
      else config = config.users

      if (config.shouldClearSessionStorage) clearSessionStorage({ version })
      if (config.shouldClearLocalStorage) clearLocalStorage({ version })
      if (config.shouldLogout) logout({ version })
      return true
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }

  useEffect(() => {
    if (!completedCheck) {
      setCompletedCheck(true)
      getConfigInfo()
    } // handle else case
  }, [userEmail])

  const clearSessionStorage = ({ version }) => {
    // cleared session storage
    let doneBefore = cookies.get(`cl_ss_${version}`)
    if (!doneBefore) {
      cookies.set(`cl_ss_${version}`, true, 999)
      sessionStorage.clear()
    }
  }
  const clearLocalStorage = ({ version }) => {
    // cleared local storage
    let doneBefore = cookies.get(`cl_ls_${version}`)
    if (!doneBefore) {
      cookies.set(`cl_ls_${version}`, true, 999)
      localStorage.clear()
    }
  }
  
  const logout = ({ version }) => {
    // logged out
    let doneBefore = cookies.get(`cl_lo_${version}`)
    if (!doneBefore) {
      cookies.set(`cl_lo_${version}`, true, 999)
      dispatch(authActions.logout({}));
    }

  }


  return {
    getConfigInfo,
  }
}
