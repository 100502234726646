import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import { FaArrowRight, FaCheckCircle, FaTimes } from 'react-icons/fa'
import { toast } from 'react-toastify'

import { useNavigate, useLocation } from 'react-router-dom'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import analytics from '../../shared/util/analytics'
import { generalActions } from '../../store/general'
import Button from '../../shared/components/FormElements/Button'
import { useConstants } from '../../shared/hooks/constants-hook'
import { useShopify } from '../../shared/hooks/shopify-hook'

const ShopifyPaymentCompleted = props => {
  const { publicConstants } = useConstants()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let location = useLocation()
  const { sendRequest } = useHttpClient()

  const auth = useSelector(state => state.auth)
  const user = auth.user
  const authHeader = {
    Authorization: 'Bearer ' + auth.token,
    'Content-Type': 'application/json',
  }

  useEffect(() => {
    dispatch(generalActions.setPageTitle({ pageTitle: 'Payment Successful' }))
  }, [])

  const { connectShopifyStore } = useShopify()
  const [processing, setProcessing] = useState(true)
  const [connectingToStore, setConnectingToStore] = useState(false)
  const [paymentError, setPaymentError] = useState(false)
  const [connectionError, setConnectionError] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const completeSubscription = async () => {
      let chargeID = query.get('charge_id')
      /* console.log("-----")
            analytics.completeSubscription({
                value: 50,
                currency: "USD",
                subscriptionId: "chargeID",
                subscriptionPlanName: "charge.name",
                userId: user.id,
                userEmail: user.email
            }) */

      if (chargeID) {
        try {
          let result = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/shopify/subscription?chargeID=${chargeID}&accessToken=${user.shopify.accessToken}&shopDomain=${user.shopify.domain}`,
            'GET',
            null,
            authHeader,
          )
          let charge = result.recurring_application_charge
          if (charge.status === 'active') {
            let interval = 'MONTHLY'
            let nextBillingOn = new Date(charge.billing_on)

            if (
              nextBillingOn.getTime() - Date.now() >
              300 * 24 * 60 * 60 * 1000
            ) {
              interval = 'YEARLY'
            }

            const subscriptionDetails = {
              source: 'Shopify',
              chargeID: chargeID,
              plan: charge.name,
              paymentInterval: interval,
              interval: interval,
              price: parseInt(charge.price),
              currency: 'USD',
              createdDate: new Date(charge.created_at).getTime(),
              lastPaymentDate: new Date(charge.created_at).getTime(),
              nextPaymentDate: nextBillingOn.getTime(),
              status: 'active',
              pricingVersion: 'v3',
            }

            const setQuery = {
              subscription: subscriptionDetails,
              'crmDetails.subscription.status': 'Recurring',
              'crmDetails.subscription.isPaused': 'No',
              'crmDetails.subscription.type': 'Shopify',
              'crmDetails.subscription.mrr':
                parseInt(charge.price) / (interval === 'YEARLY' ? 12 : 1),
              'crmDetails.subscription.price': parseInt(charge.price),
              'crmDetails.subscription.currency': 'USD',
              'crmDetails.subscription.period': interval,
              'crmDetails.subscription.paymentInterval': interval,
              'crmDetails.subscription.contractCurrencyRate':
                publicConstants.usdTlCurrencyRate,
              'crmDetails.contractStartDate': new Date(),
              'leadDetails.isWon': true,
            }

            try {
              await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/updateSubscription`,
                'POST',
                JSON.stringify({
                  userId: user.id || user._id,
                  setQuery: setQuery,
                }),
                authHeader,
              )

              analytics.completeSubscription({
                value: charge.price,
                currency: 'USD',
                subscriptionId: chargeID,
                subscriptionPlanName: charge.name,
                userId: user.id,
                userEmail: user.email,
              })
              setProcessing(false)
              setConnectingToStore(true)

              dispatch(
                authActions.updateUserObj({
                  ...auth.user,
                  subscription: subscriptionDetails,
                }),
              )
              connectShopifyStore().then(_ => {
                setTimeout(() => {
                  setConnectingToStore(false)
                  //navigate("/subscription");
                }, 2000)
              })
            } catch (err) {
              toast.warn(err?.message || 'Something went wrong')
              setConnectingToStore(false)
              setPaymentError(
                'Error occured after the payment completed. Please, contact us.',
              )
            }

            sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/sendNewSubscriptionEmail`,
              'POST',
              JSON.stringify({
                name: user.name,
                email: user.email,
                plan: charge.name,
                price: charge.price,
                interval,
                source: 'Shopify',
              }),
              authHeader,
            )

            sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/sendNewSubscriptionMessageToSlack`,
              'POST',
              JSON.stringify({
                name: user.name,
                email: user.email,
                url: user.url,
                contactNumber: user.contactNumber,
                country: user.country,
                inboundType: user.crmDetails.inboundType,
                plan: charge.name,
                price: charge.price,
                interval,
                currency: charge.currency,
                shopify: user.shopify,
              }),
              authHeader,
            )
          } else {
            setPaymentError('Payment could not be completed.')
            navigate('/account/subscription')
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        setPaymentError('Payment could not be completed.')
        navigate('/account/subscription')
      }
    }
    let isUpdate = query.get('isUpdate')
    if (user.subscription && user.subscription.status === 'active' && !isUpdate) {
      navigate('/account/subscription')
    } else {
      completeSubscription()
    }
  }, [])

  return (
    <div className="payment-completed">
      <div className="subscription-success">
        {processing && (
          <div className="row">
            <LoadingSpinner />
            <span>Starting subscription...</span>
          </div>
        )}
        {connectingToStore && (
          <React.Fragment>
            <div className="row">
              {paymentError ? (
                <React.Fragment>
                  <FaTimes className="icon red" />
                  <span>
                    Subscription failed. Try again later or contact us.
                  </span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FaCheckCircle className="icon" />
                  <span>Subscription started</span>
                </React.Fragment>
              )}
            </div>

            <div className="row">
              <LoadingSpinner />
              <span>Establishing data connection to your store...</span>
            </div>
          </React.Fragment>
        )}
        {!connectingToStore && !processing && (
          <React.Fragment>
            <div className="row">
              <FaCheckCircle className="icon" />
              <span>Subscription started</span>
            </div>

            <div className="row">
              {connectionError ? (
                <React.Fragment>
                  <FaTimes className="icon red" />
                  <span>Data connection error, please contact us.</span>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <FaCheckCircle className="icon" />
                  <span>Data connection established</span>
                </React.Fragment>
              )}
            </div>
            <div className="row actions">
              <Button to='/config'>
                Connections Page
                <FaArrowRight />
              </Button>
              {/* <Button to="/subscription" className="white">
                                Subscription Page
                                <FaArrowRight />
                            </Button> */}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default ShopifyPaymentCompleted
