import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Link, useLocation } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import './StripePayment.css'

import StripePaymentCard from '../components/StripePaymentCard'

 
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

function StripePayment() {
  const location = useLocation()
  return (
    <div className='stripe-payment-page'>
      <div className="header-row">
        <h3>Complete Payment</h3>
        <Link className="cancel-payment" to="/subscription/billing">
          <FaChevronLeft />
          Back
        </Link>
      </div>
      <div className="stripe-payment">
        <Elements stripe={stripePromise}>
          <StripePaymentCard billingInfo={location.state.billingInfo} />
        </Elements>
      </div>
    </div>
  )
}

export default StripePayment
