import React, { useState } from 'react'
import { FaPen } from 'react-icons/fa'

import './ImageUpload.css'

const ImageUpload = ({ id, onInput, defaultImageURL, maxSizeInMB = 2 }) => {
  const [imageURL, setImageURL] = useState(defaultImageURL || '')
  const [imageError, setImageError] = useState(true)

  const handleImageChange = e => {
    const file = e.target.files[0]
    const url = URL.createObjectURL(file)
    const maxSizeInByte = maxSizeInMB * 1024 * 1024

    if (!file) {
      // TODO: Decide whether to show an error when no image is provided
      return
    }

    if (file.size > maxSizeInByte) {
      setImageError(`Uploaded image size must be smaller than ${maxSizeInMB}MB`)
      onInput(id, null, false)
      return
    }

    setImageError('')
    setImageURL(url)

    onInput(id, file, true)
  }

  return (
    <React.Fragment>
      <div className="p-pic-cont">
        <input
          type="file"
          id="picInput"
          name="image"
          className="file-input"
          accept=".jpg,.jpeg,.png"
          onChange={handleImageChange}
        />
        {imageURL ? (
          <img alt="imgurl" className="p-pic" src={imageURL} />
        ) : (
          <div>
            <img
              alt="vector"
              src="https://img.freepik.com/free-vector/image-upload-concept-illustration_114360-996.jpg?w=826&t=st=1684305959~exp=1684306559~hmac=e81d53e7a98405fcadcf7848b5a445c7631961aaa02521f401562a3106a844d0"
              height="50px"
              width="50px"
            ></img>
          </div>
        )}
        <div className="change-pic-button">
          <FaPen className="icon" />
        </div>
      </div>
      {imageError && <p className="error-text">{imageError}</p>}
    </React.Fragment>
  )
}

export default ImageUpload
