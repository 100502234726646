import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import helper from '../../shared/util/helper'
import './SegmentationScoreIcon.css'
import numberFormatter from '../../shared/util/numberFormatter'

const SegmentationScoreIcon = ({ userId, previouslySegmentationInfo }) => {
  const { sendRequest } = useHttpClient()
  const token = useSelector(state => state.auth.token)

  const [isFetching, setIsFetching] = useState(false)
  const [currentSegmentationInfo, setCurrentSegmentationInfo] = useState(previouslySegmentationInfo)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const fetchSegmentationScore = async () => {
    setIsFetching(true)
    let result = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/admin/getSegmentationScoreForSignUp/${userId}`,
      'GET',
      null,
      {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    )

    setCurrentSegmentationInfo((result.segmentationInfo))
    setIsFetching(false)
  }

  const keyToLabelMap = {
    adHealthScore: "Ad Health Score",
    adHealthScoreScore: "Ad Health Score Score",
    audienceNetworkCountry: "Audience Network Country",
    audienceNetworkCountryScore: "Audience Network Country Score",
    avgCTR: "Average CTR",
    avgCTRScore: "Average CTR Score",
    avgMonthlyAdSpend: "Average Monthly Ad Spend (in $)",
    avgMonthlyAdSpendScore: "Average Monthly Ad Spend Score",
    avgOrderValue: "Average Order Value (in $)",
    avgOrderValueScore: "Average Order Value Score",
    leadType: "Lead Type",
    leadTypeScore: "Lead Type Score",
    momGrowth: "Month-over-Month Growth",
    momGrowthScore: "Month-over-Month Growth Score",
    orderCountLastMonth: "Order Count Last Month",
    orderCountLastMonthScore: "Order Count Last Month Score",
    overallScore: "Overall Score",
    sourceType: "Source Type",
    sourceTypeScore: "Source Type Score",
    yearlyRevenue: "Yearly Revenue (in $)",
    yearlyRevenueScore: "Yearly Revenue Score"
  };
  
  const logObjectInAlphabeticalOrder = (obj) => {
    const ordered = Object.keys(keyToLabelMap).sort().reduce(
      (acc, key) => {
        const label = keyToLabelMap[key]; // Get the label from the map
        if (key in obj) {
          acc[label] = typeof obj[key] === "number" 
            ? numberFormatter.formatNumber(obj[key], 2)
            : obj[key];
        } else {
          acc[label] = ""; // Set an empty string if the key is not in the input object
        }
        return acc;
      },
      {}
    );
    return ordered;
  };
  


  var previouslySegmentationInfoSorted = logObjectInAlphabeticalOrder(currentSegmentationInfo ?? {})

  return (
    <Popover
      isOpen={isPopoverOpen}
      reposition={true}
      positions={['bottom', 'right']}
      padding={10}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
          nudgetLeft={5}
        >
          <div
            onClick={e => e.stopPropagation()}
            className="notes-popup segmentation-info-popup"
          >
            <h4 style={{ margin: "2px" }}>Segmentation Info</h4>
            <table >
              {/* <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                </tr>
              </thead> */}
              <tbody>
                {(Object.keys(previouslySegmentationInfoSorted ?? {}) ?? []).map((oneInfo) => {
                  return (
                    <tr key={oneInfo}>
                      <td>{oneInfo}</td>
                      <td>{previouslySegmentationInfoSorted[oneInfo]}</td>
                    </tr>)
                })}
              </tbody>
            </table>

          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="notes-popup-icon"
        onMouseEnter={e => {
          setIsPopoverOpen(true)
        }}
        onMouseLeave={e => {
          setIsPopoverOpen(false)
        }}
        onClick={e => {
          e.stopPropagation()
          /* props.onClick() */
        }}
      >
        <div className='customer-segmentation-score-icon' onClick={() => {
          fetchSegmentationScore()
        }}>{isFetching ? "..." : ((typeof (currentSegmentationInfo?.overallScore) === 'number' && !isNaN(currentSegmentationInfo?.overallScore)) ? (currentSegmentationInfo?.overallScore * 100).toFixed(0) : "?")
          }</div>
      </div>
    </Popover>
  )

}

export default SegmentationScoreIcon
