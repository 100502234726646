import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import './Tutorials.css'

import { useHttpClient } from "../../shared/hooks/http-hook";
import { FaCopy } from "react-icons/fa";
import gtm2 from '../../shared/assets/images/gtm-guide/gtm-2.png'
import gtm3 from '../../shared/assets/images/gtm-guide/gtm-3.png'
import gtm4 from '../../shared/assets/images/gtm-guide/gtm-4.png'
import gtm5 from '../../shared/assets/images/gtm-guide/gtm-5.png'
import ControlCenter from "../../shared/components/Navigation/ControlCenter";
import ProgressBar from "../../shared/components/UIElements/ProgressBar";
import imageExpander from "../../shared/util/imageExpander";


const JSConnect = (props) => {

  const userId = useSelector((state) => state.auth.user.id);
  const isSpecialUser = useSelector((state) => state.auth.user.id);
  const userInView = useSelector(state => state.admin.userInView)
  const idInDocs = isSpecialUser ?  userInView.id : userId
  
  const [conversionID, setConversionID] = useState("")
  const [conversionIDValid, setConversionIDValid] = useState(true)


  const [integrationProcessStep, setIntegrationProcessStep] = useState(1)
  const [integrationProcess, setIntegrationProcess] = useState([

    {
      name: "Introduction",
      detail: "",
    },
    {
      name: "Listing Page Tag",
      detail: "",
      img: gtm2
    },

    {
      name: "Product Page Tag",
      detail: "",
      img: gtm3
    },
    {
      name: "Add to Cart Tag",
      detail: "",
      img: gtm4
    },
    {
      name: "Purchase Tag",
      detail: "",
      img: gtm5
    },
    {
      name: "Publish Changes",
      detail: "",

    },

  ])

  useEffect(() => {
    imageExpander.handleImages()
  }, [])

  const copyToClipboard = (id) => {
    let t = document.getElementById(id).textContent
    navigator.clipboard.writeText(t)

    toast.success("Copied to clipboard!");
  }

  const buildCardContent = () => {
    switch (integrationProcessStep) {
      case 1:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Integrating Enhencer to your website is pretty easy and straightforward. You just have to paste the 4 tags below into correct places.

            </p>
            <ol>
              <li>Listing Page</li>
              <li>Product Page</li>
              <li>Add to Cart</li>
              <li>Purchase</li>
            </ol>
          </div>
          <div className="image-cont">
            <img alt="intproc" src={integrationProcess[integrationProcessStep - 1].img} />

          </div>
        </div>
      case 2:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Add the function below to the loading/opening function of product listing pages.
            </p>
            <div className="code-cont">
              <pre id="c2">
                {`(function (d, s, id) {
var js, sdkjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id) && window.enhencerCollector) { enh_lp(); return; }
js = d.createElement(s); js.id = id;
js.src = 'https://cdn.enhencer.com/scripts/collector_v2.js?token=${idInDocs}${conversionIDValid && conversionID !== '' ? '&g_conv_id=' + conversionID : ''}';
js.onload = enh_lp
js.defer = true
sdkjs.parentNode.insertBefore(js, sdkjs);
}(document, 'script', 'enhencer'));
  
function enh_lp(){
  window.enhencerCollector.listingPage({
    listingCategory: {listing category goes here}
  })
}`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c2')} className="cp-icon" title="Copy to clipboard" />
            </div>
            <p>
              Pass the category string to the function with slashes in-between. For example, 'shoes/men/outdoor'.
            </p>
          </div>
        </div>

      case 3:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              For product detail pages, add the function below to the loading/opening function of your product detail pages.
            </p>
            <div className="code-cont">
              <pre id="c3">
                {`(function (d, s, id) {
var js, sdkjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id) && window.enhencerCollector) { enh_pp(); return; }
js = d.createElement(s); js.id = id;
js.src = 'https://cdn.enhencer.com/scripts/collector_v2.js?token=${idInDocs}${conversionIDValid && conversionID !== '' ? '&g_conv_id=' + conversionID : ''}';
js.onload = enh_pp
js.defer = true
sdkjs.parentNode.insertBefore(js, sdkjs);
}(document, 'script', 'enhencer'));
  
function enh_pp(){
  window.enhencerCollector.productPage({
    productID: {product id goes here},
    productCategory: {product category goes here},
    price: {product price goes here}
})
}`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c3')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
        </div>

      case 4:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              When a product is added to the cart, call the function below with the ID of the product that's added.
            </p>
            <div className="code-cont">
              <pre id="c4">{`<script>
window.enhencerCollector.addedToBasket({
    productID: {product id goes here}
})
</script>`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c4')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
        </div>

      case 5:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              Call the function below when a purchase completed successfully. Pass the purchase products array as a parameter. Every product has to have id, price and quantity parameters at least.
            </p>
            <div className="code-cont">
              <pre id="c5">
                &lt;script&gt;<br />
                {`(function (d, s, id) {
var js, sdkjs = d.getElementsByTagName(s)[0];
if (d.getElementById(id) && window.enhencerCollector) { enh_p(); return; }
js = d.createElement(s); js.id = id;
js.src = 'https://cdn.enhencer.com/scripts/collector_v2.js?token=${idInDocs}${conversionIDValid && conversionID !== '' ? '&g_conv_id=' + conversionID : ''}';
js.onload = enh_p
js.defer = true
sdkjs.parentNode.insertBefore(js, sdkjs);
}(document, 'script', 'enhencer'));
  
function enh_p(){
  window.enhencerCollector.purchased({
    products: {purchased products array goes here : OPTIONAL}
  })
}
</script>`}
              </pre>
              <FaCopy onClick={() => copyToClipboard('c5')} className="cp-icon" title="Copy to clipboard" />
            </div>
          </div>
          <div className="text-container">
            <p>
              Example for a 'products' variable would be:<br />
              <code>
                [&#123;id: 'lw-154', price: 89, quantity: 2&#125;,
                &#123;id: 'lw-301', price: 45, quantity: 1&#125;]
              </code>
            </p>
          </div>
        </div>

      case 6:
        return <div className="tutorial-page">
          <div className="text-container">
            <p>
              The setup is complete, after you publish your changes,
              check out the <Link to="/connect-overview">Connect Overview</Link> page to see if data started to be collected.<br /><br />
              In case of any errors, please contact us.
            </p>
          </div>
        </div>
    }
  }


  return (
    <React.Fragment>
      <div className="manager-page campaign-manager">
        <div className="middle-container">
          <div className={`result-card min-height`}>
            <div className="card-header">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="step-number">
                  {integrationProcessStep}
                </div>
                <h4>{integrationProcess[integrationProcessStep - 1].name}</h4>
              </div>
            </div>

            <div className="card-content">
              {buildCardContent()}

            </div>
          </div>
        </div>


        <ControlCenter>
          <div className="header-row">
            <h4><Link to="/connect">
              Connect
            </Link> &gt; Javascript Integration Process</h4>
            <p>{((integrationProcessStep) / integrationProcess.length * 100).toFixed(0)}%</p>
          </div>
          <ProgressBar percentage={(integrationProcessStep) / integrationProcess.length * 100} />

          <div className="flow-container">
            <div className="vertical-line"></div>
            <div className="steps">
              {integrationProcess.map((item, index) =>
                <div className={`row process-item ${item.detail && item.detail !== "" ? "align-start" : "align-center"}`} key={index}>
                  <div className={index < integrationProcessStep ? "icon-circle active" : "icon-circle"} onClick={() => { setIntegrationProcessStep(index + 1) }}>
                    {item.icon || index + 1}
                  </div>
                  <div className="process-item-content">
                    <h5>{item.name}</h5>
                    <div className="detail">{item.detail}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ControlCenter>
      </div>

      <div className="docs-cont with-nav">


        <div className="tutorial-page">
          <div className="title-row">
            <h2 id="listing">Listing page function</h2>
          </div>
          <div className="page-content">


          </div>
        </div>

        <div className="tutorial-page">
          <div className="title-row">
            <h2 id="product">Product page function</h2>
          </div>
          <div className="page-content">

          </div>
        </div>
        <div className="tutorial-page">
          <div className="title-row">
            <h2 id="addToCart">Add to cart function</h2>
          </div>
          <div className="page-content">

          </div>
        </div>
        <div className="tutorial-page">
          <div className="title-row">
            <h2 id="purchase">Purchase function</h2>
          </div>
          <div className="page-content">

          </div>
        </div>
      </div>


      <div className="separator"></div>

      <div className="docs-nav">
        <ul>
          <li>
            <a href="#script">Introduction</a>
          </li>

          <li>
            <a href="#listing">Listing Page Tag</a>
          </li>

          <li>
            <a href="#product">Product Page Tag</a>
          </li>
          <li>
            <a href="#addToCart">Add to Cart Tag</a>
          </li>
          <li>
            <a href="#purchase">Purchase Tag</a>
          </li>

        </ul>
      </div>

    </React.Fragment >
  );
};

export default JSConnect;