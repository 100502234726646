import React, { useState, useRef, useEffect } from 'react'
import { SketchPicker } from 'react-color'
import './Palette.css'

const palettes = [
  ['#FF6F61', '#DE4D4A', '#C03A3A'], // Palette 1
  ['#6B5B95', '#564682', '#443366'], // Palette 2
  ['#88B04B', '#7BA243', '#678A37'], // Palette 3
  ['#F7CAC9', '#F3A4A9', '#EF7C89'], // Palette 4
  ['#92A8D1', '#7D91BA', '#5B6E97'], // Palette 5
  ['#955251', '#7A423F', '#5A302E'], // Palette 6
  ['#B565A7', '#9E4E89', '#7C3A6A'], // Palette 7
  ['#009B77', '#008367', '#006B51'], // Palette 8
  ['#DD4124', '#B5381E', '#912E18'], // Palette 9
  ['#D65076', '#B14461', '#8F324B'], // Palette 10
  ['#45B8AC', '#39978F', '#2E7A72'], // Palette 11
  ['#EFC050', '#D4A545', '#B28B3A'], // Palette 12
  ['#5B5EA6', '#4C4E8B', '#3B3D70'], // Palette 13
  ['#9B2335', '#7F1B29', '#61121E'], // Palette 14
  ['#DFCFBE', '#C4B3A3', '#A99789'], // Palette 15
  ['#BC243C', '#9E1F32', '#801827'], // Palette 16
  ['#C3447A', '#A23663', '#85294E'], // Palette 17
  ['#98B4D4', '#829FC4', '#6B88A9'], // Palette 18
  ['#C4AEAD', '#A8908E', '#8C726F'], // Palette 19
  ['#E15D44', '#BE4B37', '#9C3A2B'], // Palette 20
]

const Palette = () => {
  const [selectedPalette, setSelectedPalette] = useState([])
  const [selectedColor, setSelectedColor] = useState({
    index: null,
    color: '#000',
  })

  const pickerRef = useRef(null)

  const handlePaletteClick = palette => {
    setSelectedPalette(palette)
  }

  const handleColorChange = (color, index) => {
    const newPalette = [...selectedPalette]
    newPalette[index] = color.hex
    console.log('newPalette ', newPalette)

    setSelectedPalette(newPalette)
    setSelectedColor({
      index,
      color,
    })
  }

  const handleHexInputChange = (event, index) => {
    const newPalette = [...selectedPalette]
    newPalette[index] = event.target.value
    setSelectedPalette(newPalette)
  }

  const handleClickOutside = event => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setSelectedColor({ index: null, color: '#000' })
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="palette-container">
      <div className="palette-box">
        <div className="palettes">
          {palettes.map((palette, paletteIndex) => (
            <div
              key={paletteIndex}
              className="palette-row"
              onClick={() => handlePaletteClick(palette)}
            >
              {palette.map((color, colorIndex) => (
                <div
                  key={colorIndex}
                  className="color-box"
                  style={{ backgroundColor: color }}
                ></div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="selected-colors">
        {selectedPalette.map((color, index) => (
          <div key={index} className="selected-color">
            <span>Color #{index + 1}:</span>
            <div
              className="color-display"
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor({ index, color })}
            ></div>
            <input
              type="text"
              className="hexadecimal-value"
              value={color}
              onChange={event => handleHexInputChange(event, index)}
            />
          </div>
        ))}
      </div>
      {selectedColor.index !== null && (
        <div className="color-picker" ref={pickerRef}>
          <SketchPicker
            color={selectedColor.color}
            onChange={color => {
              handleColorChange(color, selectedColor.index)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default Palette
//
