import React, { useEffect, useState } from "react";
import { FaPen } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import googleAdsLogo from "../../shared/assets/images/icons/google-ads-logo.png";
import gtm6 from '../../shared/assets/images/gtm-guide/gtm-6.png'

import "./ConversionIDBox.css"
import { authActions } from "../../store/auth";
import imageExpander from "../../shared/util/imageExpander";

const ConversionIDBox = props => {

    const { sendRequest } = useHttpClient()
    const dispatch = useDispatch()

    const [conversionIDValid, setConversionIDValid] = useState(true);
    const auth = useSelector(state => state.auth)
    const [conversionID, setConversionID] = useState(auth?.user?.googleAds?.conversionId || "");
    const isFromShopify = !!(auth?.user?.shopify)
    const [editing, setEditing] = useState(false)

    useEffect(() => {
        imageExpander.handleImages()
    }, [])

    const conversionIDChanged = (e) => {

        setConversionID(e.target.value)
        if (/^[0-9]{8,}$/.test(e.target.value)) {
            setConversionIDValid(true)
        } else {
            setConversionIDValid(false)
        }
    }


    const updateConversionID = async () => {
        try {
            const result = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/updateGoogleAds`,
                "POST",
                JSON.stringify({
                    userId: auth.user.id,
                    googleAds: { ...auth.user.googleAds, conversionId: conversionID }
                }),
                {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                }
            );

            if (result) {
                setEditing(false)
                setConversionID(conversionID)
                if (props.onUpdate) props.onUpdate(conversionID)
                dispatch(authActions.updateGoogleAds({ conversionId: conversionID }))
                setUserConnectFlowDates()
                if (!isFromShopify) toast.success("Updated successfully!")
            } else {
                throw "error"
            }

        } catch (err) {
          if (!isFromShopify) {
            toast.error(err?.message || "Something went wrong")
          }
        }
    }

    const updateHandler = async () => {
        if (conversionIDValid) {
            updateConversionID()

        } else {
            toast.error("Enter a valid conversion ID or remove it.")
        }

    }

    const setUserConnectFlowDates = async () => {
        let connectFlow = {...auth.user.connectFlow} || {}
        connectFlow["linkedGoogleAds"] = Date.now()
        dispatch(authActions.updateConnectFlow(connectFlow))

        try {
            const result = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/updateConnectFlow`,
                "POST",
                JSON.stringify({
                    userId: auth.user.id,
                    connectFlow: connectFlow
                }),
                {
                    Authorization: "Bearer " + auth.token,
                    "Content-Type": "application/json",
                }
            );

        } catch (err) {
            toast.error(err?.message || "Something went wrong")
        }
    }

    return <div className="card-content conv-id-cont">
        <div className="head-row">
            <h5>Link Google Ads</h5>
        </div>
        <img alt="googleadslogo" className="google-ads-logo" src={googleAdsLogo} />

        {editing ?
            <div className="content">
                <span style={{marginRight: "0.5em"}}>Conversion ID</span>
                <input className={conversionIDValid && conversionID !== '' ? 'valid' : ''} placeholder="Conversion ID" value={conversionID} onChange={conversionIDChanged} />
                {conversionID !== "" ? <span className="clear-button" onClick={() => { setConversionID(""); setConversionIDValid(true) }}>Clear</span> : null}
                {!conversionIDValid ? <span className="error-text">Conversion ID is not valid</span> : null}
                <div className="action-bar">

                    <Button className="action-button button--danger" onClick={() => { setEditing(false); setConversionID(auth.user.googleAds?.conversionId) }}>Cancel</Button>
                    <Button className="action-button" onClick={updateHandler}>Update</Button>
                </div>
            </div>
            :

            <div className="content row">
                {conversionID && conversionID !== "" ?
                    <div>
                        Your Conversion ID: <span style={{color: "green", fontWeight:"bold"}}>{conversionID}</span>
                    </div>
                    :
                    <i>No Conversion ID entered</i>
                }
                <FaPen title="Edit" className="icon" style={{  cursor: "pointer" }} onClick={() => { setEditing(true) }} />
            </div>
        }
        <div className="content border-top">
            <div className="text-container">
                <p>
                    Conversion ID is the key for connecting your webpage events to your Google Ads account. Enhencer also relies on it to create events and audiences on Google Ads. <br></br><br></br>
                    To get your Conversion ID, log in to your Google Ads account and go to the main page.<br /><br />
                    - Go to <b>'Tools & Settings'</b>, select <b>'Audience Manager'</b>.<br />
                    - On the left panel select <b>'Your data sources'</b>, click on <b>'Details'</b> in the <b>'Google Ads tag'</b>.<br />
                    - At the very bottom, open <b>'Tag setup'</b> dropdown and select <b>'Use Google Tag Manager'</b>.<br /><br />
                    You can see your <b>Conversion ID</b> there.<br />
                </p>

            </div>
            <img alt="docsimg" src={gtm6} className="docs-img" />
        </div>


    </div>
}

export default ConversionIDBox;