import React from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import './StripeUpdateCreditCard.css'
import { useSelector } from 'react-redux'

const StripeUpdateCard = props => {
  const stripe = useStripe()
  const elements = useElements()
  const { customerRefCode, refCode } = props
  const auth = useSelector(state => state.auth)

  const handleStripeCardChange = async () => {
    const { error, paymentMethod } = await stripe?.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })

    if (!error) {
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            newPaymentMethod: paymentMethod,
            customerRefCode: customerRefCode,
            refCode: refCode,
          }),
        }

        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/stripe/updatePaymentCard`,
          requestOptions,
        )

        props.close()
        if(response.ok) {
          alert("Card updated successfully")
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <div className="stripe-update-card">
      <CardElement className="new-card-info" />
      <button onClick={handleStripeCardChange}>Save Card</button>
    </div>
  )
}

export default StripeUpdateCard
