import React, { useEffect } from 'react'

import { useModal } from '../../shared/hooks/modal-hook'
import Modals from '../../shared/enums/Modals'

const Start = () => {
  const { openModal, closeModal } = useModal()

  useEffect(() => {
    openModal({
      type: Modals.START_SCREEN,
      closeOnClickOutside: false,
    })

    return () => {
      closeModal()
    }
  }, [])

  return <div className="start-screen" />
}

export default Start
