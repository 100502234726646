import React, { useEffect } from 'react'
import { useNavigate, } from 'react-router-dom'
import Button from '../FormElements/Button'
import { useModal } from '../../hooks/modal-hook'
import demoSessionImage from '../../assets/images/demo-session-image.jpg'
import { FaTimes } from 'react-icons/fa'
import './DemoSession.css'
import 'react-toastify/dist/ReactToastify.css'
import { useSelector } from 'react-redux'

const DemoSession = props => {
  const { closeModal } = useModal()
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (window.Calendly) {
      window.Calendly.initInlineWidget({
        url: process.env.REACT_APP_CALENDLY_DEMO_MEETING_LINK,
        parentElement: document.getElementById('calendlyMyDiv'),
        prefill: {
          name: user?.name,
          email: user?.email,
        },
      })
    }
  }, [user])



  const yes = () => {
    closeModal()
    navigate('/account/subscription')
  }

  return (
    <div
      className="demo-session-modal"
      style={{
        backgroundImage: `url(${demoSessionImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div className="payment-modal-close-button" onClick={closeModal}>
        <FaTimes />
      </div>
      <div className='row'>
        <div className='left-side'>
          <div className="subscription-header">
            Do you need any&nbsp; <span>help?</span>
          </div>

          <div className="content">
            Ready to kickstart your AI Ads? Simply subscribe to a package and link
            your Meta ad account. Our team is eager to help and assist you during
            this process. Feel free to book a support meeting to connect with your
            assigned account manager and get started!
          </div>
          {/* <img className="who-trust-us" src="https://cdn.enhencer.com/admin-assets/images/modals/who-trust-us.png" alt="companies worldwide" /> */}
        </div>
        <div className="calendly-inline-widget" id="calendlyMyDiv"></div>
      </div>

    </div>
  )
}

export default DemoSession
