import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { FaCheck, FaChevronDown, FaTimes } from 'react-icons/fa'

import { useHttpClient } from '../../shared/hooks/http-hook'
import { useForm } from '../../shared/hooks/form-hook'
import CollapsibleCard from '../../shared/components/UIElements/CollapsibleCard'
import dateFormatter from '../../shared/util/dateFormatter'
import AIAdsTable from './AIAdsTable'
import Modals from '../../shared/enums/Modals'
import { useModal } from '../../shared/hooks/modal-hook'

import './CardAdminAIAds.css'
import Button from '../../shared/components/FormElements/Button'
import errorHandler from '../../shared/util/errorHandler'

function CardAdminAIAds() {
  const { sendRequest } = useHttpClient()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [isBudgetFormDirty, setIsBudgetFormDirty] = useState(false)
  const [isAudienceFormDirty, setIsAudienceFormDirty] = useState(false)
  const [saving, setSaving] = useState(false)
  const [facebookInfo, setFacebookInfo] = useState(null)
  const [campaignsWithErrors, setCampaignsWithErrors] = useState([])
  const [connectionsVisible, setConnectionsVisible] = useState(true)
  const [audiencesVisible, setAudiencesVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isCheckingAudiences, setIsCheckingAudiences] = useState(false)
  const [isRecreatingAudiences, setIsRecreatingAudiences] = useState(false)
  let newCampaignIdRef = useRef()
  let newTrafficCampaignIdRef = useRef()
  const { openModal } = useModal()

  const [budgetFormState, budgetInputHandler, setBudgetFormData] = useForm(
    {
      broadCampaignBudgetPercentage: {
        value: facebookInfo?.budgetPercentages?.broad || 30,
        isValid: true,
      },
      remCampaignBudgetPercentage: {
        value: facebookInfo?.budgetPercentages?.remarketing || 10,
        isValid: true,
      },
      lalCampaignBudgetPercentage: {
        value: facebookInfo?.budgetPercentages?.lookalike || 30,
        isValid: true,
      },
      trafficCampaignBudgetPercentage: {
        value: facebookInfo?.budgetPercentages?.traffic || 30,
        isValid: true,
      },
    },
    true,
  )

  const [audienceFormState, audienceInputHandler, setAudienceFormData] =
    useForm(
      {
        retentionDays: {
          value: facebookInfo?.audienceSettings?.retentionDays || 90,
          isValid: true,
        },
      },
      true,
    )

  useEffect(() => {
    if (!customer.id) {
      return
    }

    const getFacebookInfo = async () => {
      try {
        setIsLoading(true)
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getUpToDateFacebookInfoOfUser/${customer.id}`,
          'GET',
          null,
          {
            Authorization: 'Bearer ' + auth.token,
          },
        )

        if (response.message !== 'success') {
          setIsLoading(false)
          setFacebookInfo(null)
          return
        }

        setFacebookInfo(response.facebookInfo)
      } catch (err) {
        console.log('getUpToDateFacebookInfoOfUser:err:' + err?.message)
      } finally {
        setIsLoading(false)
      }
    }

    getFacebookInfo()
  }, [customer.id])

  const checkAudiencesOnFb = async () => {
    try {
      setIsCheckingAudiences(true)
      let { audiences } = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/checkAudiencesOnFb`,
        'POST',
        JSON.stringify({
          userId: customer.id,
          adAccountId: facebookInfo.selectedAdAccount.id,
          audiences: facebookInfo.audiences,
          accessToken: facebookInfo.auth.accessToken,
        }),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )
      let newFacebookInfo = { ...facebookInfo }
      newFacebookInfo.audiences = audiences

      setFacebookInfo(newFacebookInfo)
      return true
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsCheckingAudiences(false)
    }
  }

  const recreateAudience = async ({ audienceType }) => {
    try {
      let url
      let body
      if (audienceType === 'an') {
        url = `${process.env.REACT_APP_BACKEND_URL}/facebook/reshareANAudiences`
        body = {
          userId: customer.id,
          adAccountId: facebookInfo.selectedAdAccount.id,
          audiences: facebookInfo.audiences,
          accessToken: facebookInfo.auth.accessToken,
        }
      } else {
        url = `${process.env.REACT_APP_BACKEND_URL}/facebook/recreateAudience`
        body = {
          userId: customer.id,
          adAccountId: facebookInfo.selectedAdAccount.id,
          pixelId: facebookInfo.selectedPixel.id,
          audienceType,
          audiences: facebookInfo.audiences,
          accessToken: facebookInfo.auth.accessToken,
        }
      }

      setIsRecreatingAudiences(true)
      let { audiences } = await sendRequest(url, 'POST', JSON.stringify(body), {
        Authorization: 'Bearer ' + auth.token,
        'Content-Type': 'application/json',
      })
      let newFacebookInfo = { ...facebookInfo }
      newFacebookInfo.audiences = audiences

      setFacebookInfo(newFacebookInfo)
      return true
    } catch (err) {
      errorHandler(err)
    } finally {
      setIsRecreatingAudiences(false)
    }
  }

  useEffect(() => {
    setBudgetFormData(
      {
        broadCampaignBudgetPercentage: {
          value: facebookInfo?.budgetPercentages?.broad || 30,
          isValid: true,
        },
        remCampaignBudgetPercentage: {
          value: facebookInfo?.budgetPercentages?.remarketing || 10,
          isValid: true,
        },
        lalCampaignBudgetPercentage: {
          value: facebookInfo?.budgetPercentages?.lookalike || 30,
          isValid: true,
        },
        trafficCampaignBudgetPercentage: {
          value: facebookInfo?.budgetPercentages?.traffic || 30,
          isValid: true,
        },
      },
      true,
    )
    setAudienceFormData(
      {
        retentionDays: {
          value: facebookInfo?.audienceSettings?.retentionDays || 90,
          isValid: true,
        },
      },
      true,
    )
  }, [facebookInfo])

  const addNewCampaign = async () => {
    const modalConfig = {
      title: 'Add new Campaign',
      modalComponent: (
        <div className='input-part'>
          <h3>Conversion campaign ID</h3>
          <input type="string" id="newCampaignId" ref={newCampaignIdRef} />
          <h3>Traffic campaign ID</h3>
          <input type="string" id="newTrafficCampaignId" ref={newTrafficCampaignIdRef} />
        </div>
      ),
      text: '',
      actions: [
        {
          text: 'Cancel',
          callback() {},
        },
        {
          text: 'Add',
          callback() {
            const enteredCampaignId = newCampaignIdRef.current.value
            const enteredTrafficCampaignId = newTrafficCampaignIdRef.current.value || ''

            if (enteredCampaignId) {
              try {
                sendRequest(
                  `${process.env.REACT_APP_BACKEND_URL}/admin/addNewCampaignById`,
                  'POST',
                  JSON.stringify({
                    userId: customer.id,
                    campaignId: enteredCampaignId,
                    trafficCampaignId: enteredTrafficCampaignId,
                  }),
                  {
                    Authorization: 'Bearer ' + auth.token,
                    'Content-Type': 'application/json',
                  },
                )
                toast.success('New campaign added!')
              } catch (err) {
                errorHandler(err)
              }
            } else {
              alert('enter the campaign id')
            }
          },
        },
      ],
    }

    openModal({
      type: Modals.GENERIC_MODAL,
      data: modalConfig,
    })
  }

  const _saveAudienceSettings = async () => {
    try {
      setSaving(true)

      const queryData = {
        userId: customer.id,
        audienceSettings: {
          retentionDays: parseFloat(
            audienceFormState.inputs.retentionDays.value,
          ),
        },
      }

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/audienceSettingsUpdated`,
        'POST',
        JSON.stringify(queryData),
        {
          Authorization: 'Bearer ' + auth.token,
          'Content-Type': 'application/json',
        },
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    } finally {
      setSaving(false)
    }
  }

  const detectChanges = () => {
    const isBroadCampaignBudgetPercentageDirty =
      parseFloat(budgetFormState.inputs.broadCampaignBudgetPercentage.value) !==
      (facebookInfo?.budgetPercentages?.broad || 0)

    const isRemCampaignBudgetPercentageDirty =
      parseFloat(budgetFormState.inputs.remCampaignBudgetPercentage.value) !==
      (facebookInfo?.budgetPercentages?.remarketing || 0)

    const isLalCampaignBudgetPercentageDirty =
      parseFloat(budgetFormState.inputs.lalCampaignBudgetPercentage.value) !==
      (facebookInfo?.budgetPercentages?.lookalike || 0)

    const isTrafficCampaignBudgetPercentageDirty =
      parseFloat(
        budgetFormState.inputs.trafficCampaignBudgetPercentage.value,
      ) !== (facebookInfo?.budgetPercentages?.traffic || 0)

    setIsBudgetFormDirty(
      isBroadCampaignBudgetPercentageDirty ||
        isRemCampaignBudgetPercentageDirty ||
        isLalCampaignBudgetPercentageDirty ||
        isTrafficCampaignBudgetPercentageDirty,
    )
    const isRetentionDaysDirty =
      parseFloat(audienceFormState.inputs.retentionDays.value) !==
      (facebookInfo?.audienceSettings?.retentionDays || 0)

    setIsAudienceFormDirty(isRetentionDaysDirty)
  }

  // We compare the current input values to the initial values at each render.
  useEffect(() => {
    detectChanges()
  })

  useEffect(() => {
    if (!facebookInfo || !facebookInfo.enhCampaigns) return

    const { enhCampaigns } = facebookInfo

    for (const campaign of enhCampaigns) {
      const { adCampaign, adsets, ads, adCreative } = campaign

      const hasNoAnyError =
        !adCampaign?.error &&
        !adCreative?.error &&
        !adsets?.remarketing?.error &&
        !adsets?.lookalike?.error &&
        !adsets?.broad?.error &&
        !ads?.remarketing?.error &&
        !ads?.lookalike?.error &&
        !ads?.broad?.error

      if (hasNoAnyError) {
        continue
      }

      const errors = {
        adCampaign: adCampaign?.error,
        adCreative: adCreative?.error,
        adsets: {
          remarketing: adsets?.remarketing?.error,
          lookalike: adsets?.lookalike?.error,
          broad: adsets?.broad?.error,
        },
        ads: {
          remarketing: ads?.remarketing?.error,
          lookalike: ads?.lookalike?.error,
          broad: ads?.broad?.error,
        },
      }

      setCampaignsWithErrors(prev => [...prev, { ...campaign, errors }])
    }
  }, [facebookInfo])

  const hasErrors = campaign => {
    const { errors } = campaign

    if (!errors) return false

    const hasAnyError =
      errors.adCampaign ||
      errors.adCreative ||
      errors.adsets.remarketing ||
      errors.adsets.lookalike ||
      errors.adsets.broad ||
      errors.ads.remarketing ||
      errors.ads.lookalike ||
      errors.ads.broad

    return hasAnyError
  }

  const renderErrorBox = (error, level, type) => {
    const formatLevel = l => {
      switch (l) {
        case 'adCampaign':
          return 'Campaign'
        case 'adCreative':
          return 'Creative'
        case 'adsets':
          return 'Adset'
        case 'ads':
          return 'Ad'
        default:
          return l
      }
    }

    return (
      <details
        className="campaign-error"
        key={type ? error.fbtrace_id + level + type : error.fbtrace_id + level}
      >
        <summary className="campaign-error-title">
          <span className="campaign-label">
            {formatLevel(level)} Error{`${type && ': '}`}
            <span className="campaign-error-level">
              {`${type[0].toUpperCase()}${type.slice(1)}`}
            </span>
          </span>
          <span className="error-message">
            {`${error.message} ${error.type ? `- ${error.type}` : ''}`}
          </span>
        </summary>
        {(error.error_user_title ||
          error.error_user_msg ||
          error.code ||
          error.error_subcode) && (
          <div className="details-content">
            <span className="error-user-title">{error.error_user_title}</span>
            <span className="error-user-msg">{error.error_user_msg}</span>
            <span className="error-code">
              <span className="error-code-type">
                Error Code:&nbsp;
                <span className="code">{error.code}</span>
              </span>
              <span className="error-code-type">
                Error Subcode:&nbsp;
                <span className="code">{error.error_subcode}</span>
              </span>
            </span>
          </div>
        )}
      </details>
    )
  }

  return (
    <CollapsibleCard title="AI Ads" className="sky ai-ads-card">
      <div className="content-wrapper">
        <div className="info">
          <div
            onClick={() => {
              setConnectionsVisible(!connectionsVisible)
            }}
            className="head-row"
          >
            <h4>Connections</h4>
            <FaChevronDown className="icon" />
          </div>
          {connectionsVisible ? (
            <React.Fragment>
              <div className="row">
                <div className="details">
                  <span className="key">Ad Account</span>
                  <span className="value">
                    {facebookInfo?.selectedAdAccount?.id ? (
                      <React.Fragment>
                        {facebookInfo?.selectedAdAccount?.name} <br />
                        <span className="helper-text">
                          {facebookInfo?.selectedAdAccount?.id}
                          {' - '}
                        </span>
                        <span className="helper-text">
                          {facebookInfo?.selectedAdAccount?.currency}
                        </span>
                      </React.Fragment>
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="details">
                  <span className="key">Business</span>
                  <span className="value">
                    {facebookInfo?.selectedAdAccount?.id ? (
                      <React.Fragment>
                        {facebookInfo?.selectedBusiness?.name} <br />
                        <span className="helper-text">
                          {facebookInfo?.selectedBusiness?.id}
                        </span>
                      </React.Fragment>
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="details">
                  <span className="key">Pixel</span>
                  <span className="value">
                    {facebookInfo?.selectedPixel?.id ? (
                      <React.Fragment>
                        {facebookInfo?.selectedPixel?.name} <br />
                        <span className="helper-text">
                          {facebookInfo?.selectedPixel?.id}
                        </span>
                        <br />
                        <span className="helper-text">
                          Last fired:{' '}
                          {dateFormatter(
                            facebookInfo?.selectedPixel?.last_fired_time,
                            true,
                          )}
                        </span>
                      </React.Fragment>
                    ) : (
                      <span>-</span>
                    )}
                  </span>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </div>

        <div className="audiences">
          <div
            onClick={() => {
              setAudiencesVisible(!audiencesVisible)
            }}
            className="head-row"
          >
            <h4>Audiences</h4>
            <FaChevronDown className="icon" />
          </div>
          {audiencesVisible ? (
            facebookInfo?.audiences &&
            facebookInfo.audiences[facebookInfo?.selectedAdAccount.id] ? (
              <React.Fragment>
                <div className="row">
                  <div className="details">
                    {Object.keys(
                      facebookInfo.audiences[
                        facebookInfo?.selectedAdAccount.id
                      ],
                    ).map(audienceType => {
                      return (
                        <div
                          className={`audience-group ${audienceType}`}
                          key={audienceType}
                        >
                          <div className="title">{audienceType}</div>
                          <ul className="audiences-box">
                            {facebookInfo.audiences[
                              facebookInfo?.selectedAdAccount.id
                            ][audienceType].map(audience => {
                              return (
                                <li key={audience.id ?? Date.now().toString()}>
                                  {audience.status === 'configured' ? (
                                    <FaCheck className="icon green" />
                                  ) : audience.status ? (
                                    <FaTimes className="icon red" />
                                  ) : null}
                                  {audience.name}
                                </li>
                              )
                            })}
                          </ul>
                          {facebookInfo.audiences[
                            facebookInfo?.selectedAdAccount.id
                          ][audienceType].some(
                            a => a.status && a.status !== 'configured',
                          ) ? (
                            <div className="action-cont">
                              <Button
                                onClick={() => {
                                  recreateAudience({ audienceType })
                                }}
                                loading={isRecreatingAudiences}
                              >
                                {audienceType === 'an' ? 'Reshare' : 'Recreate'}
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="row">
                  {facebookInfo.audiencesChecked ? (
                    <span className="helper-text">
                      Last checked:{' '}
                      {dateFormatter(facebookInfo.audiencesChecked, true)}
                    </span>
                  ) : null}
                  <Button
                    onClick={checkAudiencesOnFb}
                    loading={isCheckingAudiences}
                  >
                    Refresh list
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <div className="helper-text">
                No Enhencer audiences in selected ad account
              </div>
            )
          ) : null}
        </div>

        {campaignsWithErrors?.length ? (
          <div className="errors">
            <span className="title">Campaign Issues</span>
            <div className="divider" />
            {campaignsWithErrors.map(campaign => {
              if (!hasErrors(campaign)) return
              return (
                <details className="campaign-error-stack" key={campaign._id}>
                  <summary className="campaign-error-stack-title">
                    {campaign.label}
                  </summary>
                  <div>
                    {Object.keys(campaign.errors).map(level => {
                      const { errors } = campaign

                      if (level === 'adCampaign' || level === 'adCreative') {
                        const error = errors[level]
                        if (!error) return

                        return renderErrorBox(error, level)
                      }

                      return Object.keys(errors[level]).map(type => {
                        const error = errors[level][type]
                        if (!error) return

                        return renderErrorBox(error, level, type)
                      })
                    })}
                  </div>
                </details>
              )
            })}
            {!campaignsWithErrors.length && (
              <div className="no-error">
                There is no campaign with an error found.
              </div>
            )}
          </div>
        ) : null}
        <div className="button-row">
          <Button onClick={addNewCampaign}>Add new Campaign</Button>
        </div>
        <div className="table">
          <AIAdsTable isLoading={isLoading} facebookInfo={facebookInfo} />
        </div>
      </div>
    </CollapsibleCard>
  )
}

export default CardAdminAIAds
