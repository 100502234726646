import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";

import helper from "../../shared/util/helper";
import cookies from "../../shared/util/cookies";
import { fitAudiencePageContent } from "../../shared/util/uiPositioner";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useModal } from "../../shared/hooks/modal-hook";
import { projectActions } from "../../store/project";
import errorHandler from '../../shared/util/errorHandler'

import "./AudiencePage.css"
import Modals from "../../shared/enums/Modals";

const AudiencePage = props => {
    const dispatch = useDispatch()
    const { isLoading, sendRequest } = useHttpClient()
    const { openModal } = useModal()
    const location = useLocation()
    const campaigns = useSelector(state => state.project.campaigns)
    const campaignId = useSelector(state => state.project.currentCampaignId)
    const authToken = useSelector(state => state.auth.token)
    const isDemoUser = useSelector(state => state.auth.user.isDemo)
    const isSpecialUser = useSelector(state => state.auth.user.isSpecialUser)
    const authUserId = useSelector(state => state.auth.user.id)
    const userInViewId = useSelector(state => state.admin.userInView?.id)

    useEffect(() => {
        document.querySelector(".App").classList.add("has-navbar");
        let timeoutForModal;

        return () => {
            document.querySelector(".App").classList.remove("has-navbar");
        }

    }, [])


    useEffect(() => {


        const getCampaign = async () => {
            const data = createDataForRequest();
            let url = `${process.env.REACT_APP_BACKEND_URL}/${isSpecialUser ? 'admin' : 'projects'}/getCampaignDetails/`;
            try {
                const responseData = await sendRequest(
                    url,
                    "POST",
                    data,
                    {
                        Authorization: "Bearer " + authToken,
                        "Content-Type": "application/json",
                    }
                );
                dispatch(projectActions.setCurrentCampaign({
                    campaign: {
                        ...responseData.campaign,
                        isDemo: isDemoUser
                    },
                    isDemo: isDemoUser

                }));

            } catch (err) {
                errorHandler(err)
            }
        }

        if ((isSpecialUser && userInViewId) || (!isSpecialUser && authUserId)) getCampaign()

        fitAudiencePageContent()
    }, [location.pathname, userInViewId])

    const createDataForRequest = () => {
        let data;
        if (isSpecialUser) {
            data = JSON.stringify({
                userId: userInViewId,
                campaignId: campaignId,
            });
        } else {
            if (isDemoUser) {

                const foundCampaign = helper.findBy(campaigns, "id", campaignId);
                const audiencePath = foundCampaign.audiencePath
                data = JSON.stringify({
                    audiencePath: audiencePath
                })
            } else {
                data = JSON.stringify({
                    userId: authUserId,
                    campaignId: campaignId
                });
            }
        }

        return data;
    }

    return isLoading ? <LoadingSpinner /> :
        <div className="audience-page">

            <div className="page-content">
                <Outlet />
            </div>
        </div>
}

export default AudiencePage;