import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { FaSyncAlt } from 'react-icons/fa'

import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import Button from "../../shared/components/FormElements/Button";

import metaLogo from '../../shared/assets/images/icons/meta-logo.png'
import './AdminPanelCustomerCard.css'
import numberFormatter from '../../shared/util/numberFormatter'
import helper from '../../shared/util/helper'
import errorHandler from '../../shared/util/errorHandler'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import { getShortElapsedTimeString } from '../../shared/util/dateFormatter'

const AdminPanelCustomerCardReportSummaryPart = props => {
  const {
    userId,
    weeklyReports,
    currency,
  } = props


  const getComparisonTextClassName = (a, b) => {
    if (a >= b) return 'up'
    if (a < b) return 'down'
    return ''
  }

  const { sendRequest, isLoading } = useHttpClient()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)

  const [leftPartResult, setLeftPartResult] = useState()
  const [rightPartResult, setRightPartResult] = useState()
  const [leftName, setLeftName] = useState()
  const [rightName, setRightName] = useState()
  const user = useSelector(state => state.auth.user)

  const millisSinceLastUpdate = weeklyReports?.lastUpdatedAt ?
    Date.now() - weeklyReports.lastUpdatedAt : null

  const refreshTooltipContent = <>
    {weeklyReports?.lastUpdatedAt ? <span>Last updated: {getShortElapsedTimeString(new Date(weeklyReports.lastUpdatedAt))} ago <br /></span> : ''}
    <span>
      {
        millisSinceLastUpdate && (millisSinceLastUpdate / (60 * 60 * 1000)) < 1 ? "\nAt least an hour should pass for an update" : "\nClick to update"
      }
    </span>
  </>


  useEffect(() => {
    if (weeklyReports) {

      if (weeklyReports.beforeEnhencerSlice) {
        setLeftName(`Last ${weeklyReports.beforeEnhencerSlice.dayCountBeforeEnhencer} Days`)
        setRightName("Before AI Ads")

        setLeftPartResult(weeklyReports.enhOverall)
        setRightPartResult(weeklyReports.beforeEnhencerSlice)

      } else if (weeklyReports.beforeEnhencer && weeklyReports.enhOverall) {
        setLeftName("Last 14 Days")
        setRightName("Before AI Ads")

        setLeftPartResult(weeklyReports.enhOverall)
        setRightPartResult(weeklyReports.beforeEnhencer)

      } else if (weeklyReports.beforeEnhencer) {
        setLeftPartResult(weeklyReports.beforeEnhencer)
        setLeftName("Before AI Ads")

      } else if (weeklyReports.enhOverall) {
        setLeftPartResult(weeklyReports.enhOverall)
        setLeftName("Last 14 Days")
      }
    }
  }, [])


  const refreshReports = async (e) => {
    e.stopPropagation()

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/refreshDailyFacebookReport`,
        'POST',
        JSON.stringify({
          userId
        }),
        {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      )

      dispatch(adminActions.updateCustomerFieldInList({
        userId,
        fieldPath: "facebookAds.weeklyReports",
        value: result.weeklyReports
      }))

      toast.success('Report is up to date now!')
    } catch (err) {
      errorHandler(err)
    }
  }

  return <>

    {leftPartResult ? (
      <React.Fragment>
        <div className="comparison">
          <div className="heading">

            <span className={`large ${rightName ? 'green' : ''}`}>
              <img src={metaLogo} />
              {leftName}
            </span>
            {rightName ?
              <span className="small">{' / '}{rightName}</span> : null
            }
          </div>

          <div className="results">

            <div className="item">
              <div className="value">
                <span className={`meta ${rightPartResult
                  ? getComparisonTextClassName(
                    leftPartResult.sales,
                    rightPartResult.sales,
                  )
                  : ''
                  }`}>
                  <span>
                    <span className="currency">
                      {currency
                        ? helper.getCurrencySymbol(currency)
                        : '$'}
                    </span>
                    <span>
                      {leftPartResult.sales > 10000
                        ? numberFormatter.shortenNumber(
                          leftPartResult.sales,
                        )
                        : numberFormatter.formatNumber(
                          leftPartResult.sales,
                        )
                      }
                    </span>
                  </span>

                  {rightPartResult && (
                    <span className="slash">/</span>
                  )}
                </span>

                {rightPartResult && (
                  <span className="before">
                    <span className="currency">
                      {currency
                        ? helper.getCurrencySymbol(currency)
                        : '$'}
                    </span>
                    <span>
                      {rightPartResult.sales > 10000
                        ? numberFormatter.shortenNumber(
                          rightPartResult.sales,
                        )
                        : numberFormatter.formatNumber(
                          rightPartResult.sales,
                        )}
                    </span>
                  </span>
                )}
              </div>
              <div className="description">Revenue</div>
            </div>

            <div className="item">
              <div className="value">
                <span
                  className={`meta ${rightPartResult
                    ? getComparisonTextClassName(
                      leftPartResult.spend,
                      rightPartResult.spend,
                    )
                    : ''
                    }`}
                >
                  <span>
                    <span className="currency">
                      {currency
                        ? helper.getCurrencySymbol(currency)
                        : '$'}
                    </span>
                    <span>
                      {
                        leftPartResult.spend > 10000
                          ? numberFormatter.shortenNumber(
                            leftPartResult.spend,
                          )
                          : numberFormatter.formatNumber(
                            leftPartResult.spend,
                          )
                      }
                    </span>
                  </span>
                  {rightPartResult && (
                    <span className="slash">/</span>
                  )}
                </span>
                {rightPartResult && (
                  <span className="before">
                    <span className="currency">
                      {currency
                        ? helper.getCurrencySymbol(currency)
                        : '$'}
                    </span>
                    <span>
                      {rightPartResult.spend > 10000
                        ? numberFormatter.shortenNumber(
                          rightPartResult.spend,
                        )
                        : numberFormatter.formatNumber(
                          rightPartResult.spend,
                        )}
                    </span>
                  </span>
                )}
              </div>
              <div className="description">Cost</div>
            </div>

            <div className="item">
              <div className="value">
                <span
                  className={`meta ${rightPartResult
                    ? getComparisonTextClassName(
                      leftPartResult.roas,
                      rightPartResult.roas,
                    )
                    : ''
                    }`}
                >
                  <span>
                    <span>
                      {leftPartResult
                        ? numberFormatter.formatNumber(
                          leftPartResult.roas,
                        )
                        : numberFormatter.formatNumber(
                          rightPartResult.roas,
                        )}
                    </span>
                  </span>
                  {rightPartResult && (
                    <span className="slash">/</span>
                  )}
                </span>
                {rightPartResult && (
                  <span className="before">
                    {numberFormatter.formatNumber(
                      rightPartResult.roas,
                    )}
                  </span>
                )}
              </div>
              <div className="description">ROAS</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ) : (
      <div className="no-report">
        <img src={metaLogo} />
        No report
      </div>
    )
    }


    <Tooltip
      key={userId + "report_update"}
      content={isLoading ? "" : refreshTooltipContent}
      placement="right">
      <Button className={`report-refresh-button ${isLoading ? 'loading' : ''}`} disabled={millisSinceLastUpdate && (millisSinceLastUpdate / (60 * 60 * 1000)) < 1} loading={isLoading} loadingText={"Fetching..."} onClick={refreshReports}>
        <FaSyncAlt />
      </Button>
    </Tooltip>
  </>

}

export default AdminPanelCustomerCardReportSummaryPart
