import { useDispatch, useSelector } from 'react-redux'

import { useHttpClient } from './http-hook'
import { supportTicketsActions } from '../../store/support-tickets'

export const useSupportTickets = () => {
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()

  const { hasUnreadMessages } = useSelector(state => state.supportTickets)
  const authToken = useSelector(state => state.auth.token)
  const user = useSelector(state => state.auth.user)
  const authHeader = {
    Authorization: 'Bearer ' + authToken,
    'Content-Type': 'application/json',
  }

  const fetchTickets = async () => {
    try {
      dispatch(supportTicketsActions.setLoading(true))
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/getSupportTickets`,
        'GET',
        null,
        authHeader,
      )

      dispatch(supportTicketsActions.setTickets(response.data))

      const hasUnreadMessages = response.data.some(ticket =>
        ticket.messages.some(
          message => message.receiver === user._id && !message.read,
        ),
      )

      dispatch(supportTicketsActions.setHasUnreadMessages(hasUnreadMessages))
    } catch (error) {
      dispatch(supportTicketsActions.setError(error.message))
    } finally {
      dispatch(supportTicketsActions.setLoading(false))
    }
  }

  return {
    fetchTickets,
    hasUnreadMessages,
  }
}
