import React, { useState } from 'react'
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { FaCompass } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useHttpClient } from '../../shared/hooks/http-hook'
import { projectActions } from '../../store/project'
import { adminActions } from '../../store/admin'
import { useConstants } from '../../shared/hooks/constants-hook'

import './AdminPanelCustomerCardMenu.css'
import { useAdmin } from '../../shared/hooks/admin-hook'
import errorHandler from '../../shared/util/errorHandler'

const AdminPanelCustomerCardMenu = props => {
  const { privateConstants: constants } = useConstants()
  const { startViewingAsUser } = useAdmin()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const { sendRequest } = useHttpClient()
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = props.user

  const goTo = async page => {

    switch (page) {
      case 'Connect':
        if (user.isActive) {
          startViewingAsUser({
            user,
            pageRoute: '/visiting-data-overview',
            openInNewTab: props.openInNewTab,
          })

        } else {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/admin/checkIfDataActive`,
              'POST',
              JSON.stringify({
                userId: user.id,
              }),
              {
                Authorization: 'Bearer ' + auth.token,
                'Content-Type': 'application/json',
              },
            )
            if (responseData.isActive) {

              startViewingAsUser({
                user,
                pageRoute: '/visiting-data-overview',
                openInNewTab: props.openInNewTab,
              })
            } else {
              toast.warn('Data is not connected yet!')
            }
          } catch (err) {
            errorHandler(err)
          }
        }
        break
      case 'Audience':
        if (!user.job) {
          toast.warn('Audience is not created yet!')
        } else if (user.job) {
          if (!user.isReady) {
            toast.warn('Audience creation in progress. Please wait for it.')
          } else {

            try {
              const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${user.id}`,
                'GET',
                null,
                {
                  Authorization: 'Bearer ' + auth.token,
                },
              )

              dispatch(adminActions.startViewingAsUser({
                user,
                pageRoute: `/ads-manager/${responseData.campaigns[0].id}/audience`,
                openInNewTab: props.openInNewTab,
              }))

              dispatch(
                projectActions.setAdminUserUsersCampaigns({
                  campaigns: responseData.campaigns,
                }),
              )
              dispatch(adminActions.setNavigatedFromCustomerID(user.id))
            } catch (err) {
              errorHandler(err)
            }
          }
        }

        break
      case 'Profit':
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${user.id}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          )
          dispatch(
            projectActions.setAdminUserUsersCampaigns({
              campaigns: responseData.campaigns,
            }),
          )
          dispatch(adminActions.setNavigatedFromCustomerID(user.id))

          startViewingAsUser({
            user,
            pageRoute: `/ads-manager/${responseData.campaigns[0].id}/profit`,
            openInNewTab: props.openInNewTab,
          })
        } catch (err) {
          errorHandler(err)
        }
        break
      case 'AIAds':

        startViewingAsUser({
          user,
          pageRoute: "/ai-ads",
          openInNewTab: props.openInNewTab,
        })
        break
      case 'Website':
        if (user.shopify) {
          window.open('https://' + user.shopify.domain, '_blank')
        } else if (user.url) {
          let url = user.url
          if (!url.includes('https://')) {
            url = 'https://' + url
          }
          window.open(url, '_blank')
        } else {
          toast.error('Website URL not defined.')
        }

        break
    }
  }

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'left', 'left', 'bottom']}
      padding={10}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#c5c5c5'}
          arrowSize={10}
          arrowStyle={{ opacity: 1 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div
            onClick={e => e.stopPropagation()}
            className="admin-panel-customer-card-menu"
          >
            <div className="wrapper">
              <div
                className="nav-button"
                onClick={() => {
                  goTo('Connect')
                }}
              >
                Connect
              </div>
              <div
                className="nav-button"
                onClick={() => {
                  goTo('Audience')
                }}
              >
                Audience
              </div>

              <div
                className="nav-button"
                onClick={() => {
                  goTo('AIAds')
                }}
              >
                AI Ads
              </div>

              <div
                className="nav-button"
                onClick={() => {
                  goTo('Website')
                }}
                title="Visit website"
              >
                Website
              </div>
            </div>
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className="admin-panel-customer-card-menu-icon"
        onClick={e => {
          e.stopPropagation()
          setIsPopoverOpen(!isPopoverOpen)
        }}
      >
        <FaCompass className='icon' title="Navigate" />
      </div>
    </Popover>
  )
}

export default AdminPanelCustomerCardMenu
