import React from "react";
import Canvas from "../../shared/components/UIElements/Canvas";

import numberFormatter from "../../shared/util/numberFormatter";

const OverallStats = (props) => {
  return (
    <React.Fragment>
      <div className="overall">
        <div className="step-name">Overall</div>
        <div className="conversion-badge">
          <div className="number" title="Likely to buy">
            {((props.purchaseRowCount * 100) / props.customerRowCount).toFixed(
              1
            )}{" "}
            %
          </div>
          <div className="text">Likely to buy</div>
        </div>
        <div className="canvas-container">
          <Canvas
            start="100"
            end={(props.productRowCount / props.customerRowCount) * 100}
            className="funnel-canvas"
          ></Canvas>
        </div>
        <div className="summary-badge">
          <div className="number" title="Visitors">
            {numberFormatter.formatNumber(props.customerRowCount)}
          </div>
          <div className="text">Visitors</div>
        </div>
        <div className="summary-badge">
          <div className="number" title="Pageviews">
            {numberFormatter.formatNumber(
              props.productPageView + props.listingPageView
            )}
          </div>
          <div className="text">Pageviews</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OverallStats;
