import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import CustomDatePicker from './CustomDatePicker'

const EditableCell = ({
  value: initialValue,
  row: { index },
  column,
  updateMyData,
}) => {
  const [value, setValue] = React.useState(initialValue)
  const columnMeta = column?.meta
  const onChange = e => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    let parsedValue = value
    if (columnMeta?.type === 'numeric') {
      parsedValue = parseFloat(value)
    }
    updateMyData(index, column.id, parsedValue)
  }

  const handleDateChange = date => {
    setValue(date)
    updateMyData(index, column.id, date)
  }

  const handleSelectChange = e => {
    setValue(e.target.value)
    updateMyData(index, column.id, e.target.value)
  }

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  if (columnMeta?.type === 'numeric') {
    return (
      <input
        type="text"
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={e => {
          if (!/[0-9.]/.test(e.key)) {
            e.preventDefault()
          }
        }}
      />
    )
  } else if (columnMeta?.type === 'date') {
    return (
      <CustomDatePicker
        placeholder="--"
        date={value}
        onChange={d => {
          handleDateChange(d)
        }}
      />
    )
  } else if (columnMeta?.type === 'select') {
    return (
      <select value={value} onChange={handleSelectChange}>
        {columnMeta?.options?.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    )
  }

  // Default to rendering a basic input
  return <input type="text" value={value} onChange={onChange} onBlur={onBlur} />
}

export default EditableCell
