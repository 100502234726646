import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import shopifyIcon from '../../shared/assets/images/icons/shopify-bag.png'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { authActions } from '../../store/auth'
import { projectActions } from '../../store/project'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { useConstants } from '../../shared/hooks/constants-hook'

import './AdminUserDetailName.css'

const AdminUserDetailName = () => {
  const { privateConstants: constants } = useConstants()
  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const customer = useSelector(state => state.admin.customer)
  const auth = useSelector(state => state.auth)
  const [moveToLeadsNotificationVisible, setMoveToLeadsNotificationVisible] = useState(false)
  const { startViewingAsUser } = useAdmin()

  const { pathname } = useLocation()
  const userId = pathname.split('/')[3]
  const [displayName, setDisplayName] = useState()


  useEffect(() => {
    let dName = customer.shopify
      ? customer.shopify.name || customer.shopify.domain
      : customer.url || customer.name

    dName = dName?.replace('https://', '').replace('.myshopify.com', '')
    if (dName && dName[dName.length - 1] === '/') {
      dName = dName.substring(0, dName.length - 1)
    }

    setDisplayName(dName)

  }, [customer])

  const moveToLeads = () => {
    const data = {
      'leadDetails.isWon': false,
      'leadDetails.funnel': constants.leadFunnelSteps,
      'crmDetails.subscription.status': 'No subscription',
      'crmDetails.funnel': constants.funnel,
    }

    setMoveToLeadsNotificationVisible(true)
    setTimeout(() => {
      setMoveToLeadsNotificationVisible(false)

      try {
        sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userId}`,
          'PATCH',
          JSON.stringify(data),
          {
            Authorization: 'Bearer ' + auth.token,
            'Content-Type': 'application/json',
          },
        )
        toast.success('Changes saved!')
        navigate('/admin-panel')
      } catch (err) {
        toast.error(err?.message || "Something went wrong")
      }
    }, 1500)
  }

  const copyLink = () => {
    navigator.clipboard.writeText(userId)
    toast.info('Customer ID copied.')
  }

  const goToAudience = async () => {
    if (!customer.job) {
      toast.warn('Audience is not created yet!')
    } else if (customer.job) {
      if (!customer.isReady) {
        toast.warn('Audience creation in progress. Please wait for it.')
      } else {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${customer.id}`,
            'GET',
            null,
            {
              Authorization: 'Bearer ' + auth.token,
            },
          )
          console.log("going to audience ", responseData)
          dispatch(
            projectActions.setAdminUserUsersCampaigns({
              campaigns: responseData.campaigns,
            }),
          )

          startViewingAsUser({
            user: customer,
            pageRoute: `/ads-manager/${responseData.campaigns[0].id}/audience`,
          })
        } catch (err) {
          toast.error(err?.message || "Something went wrong")
        }
      }
    }
  }

  const goToProfit = async () => {

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getCampaigns/${customer.id}`,
        'GET',
        null,
        {
          Authorization: 'Bearer ' + auth.token,
        },
      )
      dispatch(
        projectActions.setAdminUserUsersCampaigns({
          campaigns: responseData.campaigns,
        }),
      )

      startViewingAsUser({
        user: customer,
        pageRoute: `/ads-manager/${responseData.campaigns[0].id}/profit`,
      })
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  }


  const goToAIAds = async ({ }) => {
    startViewingAsUser({ user: customer })
  }

  const goToWebsite = () => {
    if (customer.shopify) {
      window.open("https://" + customer.shopify.domain, "_blank")
    } else if (customer.url) {
      let url = customer.url
      if (!url.includes("https://")) {
        url = "https://" + url
      }
      window.open(url, "_blank")
    } else {
      toast.error("Website URL not defined.")
    }
  }

  return (
    <div className="user-name-area">
      <div className="domain">
        <span className="text" onClick={goToWebsite} title="Visit website">
          {displayName}
        </span>
        {customer.shopify && (
          <img
            src={shopifyIcon}
            alt="Shopify"
            width="40"
            height="40"
            className="shopify-icon"
          />
        )}
      </div>
      <ul className="actions">

        <li onClick={moveToLeads} className="link">
          Move to leads
        </li>
        <li onClick={copyLink} className="link">
          Copy ID
        </li>
        <li onClick={goToAudience} className="link">
          Audience Page
        </li>
        <li onClick={goToAIAds} className="link">
          AI Ads
        </li>

      </ul>
      {moveToLeadsNotificationVisible && (
        <div className="move-to-leads-overlay">
          <div>Moving to leads...</div>
        </div>
      )}
    </div>
  )
}

export default AdminUserDetailName