import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  error: null,
  tickets: [],
  hasUnreadMessages: false,
}

const supportTicketsSlice = createSlice({
  name: 'supportTickets',
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setTickets(state, action) {
      state.tickets = action.payload || []
    },
    setMouseWheelActive(state, action) {
      state.isMouseWheelActive = action.payload
    },
    findTicketByIdAndUpdate(state, action) {
      const { id, data } = action.payload
      const index = state.tickets.findIndex(t => t._id === id)
      state.tickets[index] = data
    },
    setHasUnreadMessages(state, action) {
      state.hasUnreadMessages = action.payload
    },
  },
})

export const supportTicketsActions = supportTicketsSlice.actions

export default supportTicketsSlice.reducer
