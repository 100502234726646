import React, { useEffect } from 'react';
//import { Link } from 'react-router-dom';
import './ControlCenter.css'
import { useSelector } from 'react-redux';

const ControlCenter = props => {
    const user = useSelector(state => state.auth.user)

    useEffect(() => {

        var barTimeout;
        let scrollable = document.querySelector(".control-center .flow-container")

        const handleScroll = () => {
            if (scrollable) {
                if (barTimeout) {
                    clearTimeout(barTimeout); //clear to reset
                }
                barTimeout = setTimeout(() => {
                    scrollable.classList.remove('scrolling');
                }, 500); //0.5s delay
                scrollable.classList.add('scrolling');

            }
        }

        if (scrollable) scrollable.addEventListener('scroll', handleScroll);

        return () => {
            if (scrollable) scrollable.removeEventListener('scroll', handleScroll);
        }


    }, [])

    return <div className='control-center'>
        <div className='control-center-content'>
            {props.children}
        </div>
        <div className='support-meeting-button-container'>
            <span>Need support? </span>
        </div>
    </div>
}

export default ControlCenter;