import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { useHttpClient } from './http-hook'
import { Link } from 'react-router-dom'
import { facebookActions } from '../../store/facebook'
import { useFacebook } from './facebook-hook'
import { useUser } from '../../shared/hooks/user-hook'

export const useFacebookAuth = () => {

  const { sendRequest } = useHttpClient()
  const dispatch = useDispatch()
  const { setConnectFlowStep } = useUser()

  const user = useSelector(state => state.auth.user)
  const authToken = useSelector(state => state.auth.token)
  const authHeader = {
    Authorization: "Bearer " + authToken,
    "Content-Type": "application/json",
  }

  const [auth, setAuth] = useState()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const facebook = useSelector(state => state.facebook)
  const { initializeFacebookInfo } = useFacebook()

  const fetchFacebookInfo = async (requestByAdmin, userId) => {

    if (authToken && !facebook.fetching) {
      dispatch(facebookActions.fetching(true))

      try {
        let result = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/facebook?uid=${requestByAdmin ? userId : user.id}`,
          "GET", null, authHeader
        )

        let newInfo = { ...result.info, initialOverallReport: result.initialOverallReport }

        const TIME_THRESHOLD = 60000 // Threshold value for expiration time (1 minute)
        if (newInfo?.auth?.accessToken && newInfo?.auth?.willExpireOn - Date.now() > TIME_THRESHOLD && !newInfo?.auth?.loggedOut) {
          newInfo.isLoggedIn = true;
          setIsLoggedIn(true)
          initializeFacebookInfo(newInfo)
        } else if (facebook?.auth?.loggedOut && !user.isSpecialUser) {
          dispatch(facebookActions.connectionPageIsReady(true))
          toast.warn(
            <div>
              You are logged out of your Facebook account. Please <Link to="/config?s=fbl">log in</Link> again.
            </div>
          )
        } else {
          dispatch(facebookActions.connectionPageIsReady(true))
        }

        dispatch(facebookActions.updateInfo(newInfo))
        dispatch(facebookActions.fetching(false))

        dispatch(facebookActions.connectionPageIsReady(true))
        return true
      } catch (err) {
        console.log("err", err)
        dispatch(facebookActions.connectionPageIsReady(true))
        return false
      }
    } else if (authToken) {
      dispatch(facebookActions.connectionPageIsReady(true))
      return true
    }
  }


  const loginCallback = async (response) => {

    dispatch(facebookActions.connectionPageIsReady(false))
    if (response.accessToken) {

      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/loginCompleted`,
        "POST",
        JSON.stringify({
          userId: user.id,
          facebookAuth: response,
        }),
        authHeader,
      )
      let facebookAuth = {
        ...response,
        accessToken: result.accessToken,
        expiresIn: result.expiresIn,
      }

      setConnectFlowStep({ step: "loggedInToFacebook" })

      dispatch(facebookActions.loginCompleted(facebookAuth))
      initializeFacebookInfo({ ...facebook, auth: facebookAuth, isLoggedIn: true })

      setAuth(facebookAuth)
      toast.success("Logged in successfully!")
    } else {
      dispatch(facebookActions.connectionPageIsReady(true))
      toast.error("Facebook Login Failed!")
    }
  }

  const logOut = async (forced) => {
    try {

      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/facebook/logout`,
        "POST",
        JSON.stringify({
          userId: user.id,
        }),
        authHeader,
      )
      setIsLoggedIn(false)
      if (!forced) toast.success("Logged out successfully!")
      dispatch(facebookActions.logOut())
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }

  }

  return {
    auth,
    isLoggedIn,
    loginCallback,
    logOut,
    fetchFacebookInfo
  }
}
