import React, { useEffect, useState } from 'react'
import { FaCaretDown, FaInfoCircle } from 'react-icons/fa'
import { useSelector } from 'react-redux'

import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'
import useSmallScreen from '../../shared/hooks/small-screen-hook'
import enhencerLogo from '../../shared/assets/images/icons/enhencer-logo-white.png'
import FacebookDatePicker from '../../shared/components/UIElements/FacebookDatePicker'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import Input from '../../shared/components/FormElements/Input'
import AdvancedCampaignReportMobile from './AdvancedCampaignReportMobile'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import dateFormatter from '../../shared/util/dateFormatter'
import { useFacebook } from '../../shared/hooks/facebook-hook'
import { useForm } from '../../shared/hooks/form-hook'
import fbHelper from "../../shared/util/facebookSdkHelper"
import ReportChartCard from './ReportChartCard'

import './AdvancedCampaignReport.css'

const AdvancedCampaignReport = () => {
  const isSmallScreen = useSmallScreen(992)
  const {
    newFilterSelectedForReport,
    newCampaignSelectedForReport,
    getOverallAdsetBasedReportForEnhCampaigns,
    getInsightsForChart,
  } = useFacebook()
  const facebook = useSelector(state => state.facebook)
  const filter = facebook.reportsFilter
  const enhCampaigns = facebook.enhCampaigns
  const [currencySymbol, setCurrencySymbol] = useState(
    facebook.selectedAdAccount?.currency
      ? helper.getCurrencySymbol(facebook.selectedAdAccount?.currency)
      : '$',
  )
  const [overallReport, setOverallReport] = useState({})
  const [reportsInView, setReportsInView] = useState([])

  const [selectedCampaign, setSelectedCampaign] = useState(helper.findBy(enhCampaigns, "_id", facebook.activeEnhCampaignIdForReports) || enhCampaigns[0])

  useEffect(() => {
    if (!facebook.fetchingReports) {
      if (facebook.activeEnhCampaignIdForReports === "allEnhCampaigns") {
        setSelectedCampaign(
          {
            reports: facebook.overallReportForEnhCampaigns,
            label: "All Campaigns"
          }
        )
      } else if (facebook.activeEnhCampaignIdForReports) {
        setSelectedCampaign(helper.findBy(enhCampaigns, "_id", facebook.activeEnhCampaignIdForReports))
      } else if (enhCampaigns.length) {
        setSelectedCampaign(enhCampaigns[0])
      }
    }

  }, [facebook.activeEnhCampaignIdForReports, facebook.overallReportForEnhCampaigns, enhCampaigns, filter])

  const [formState, inputHandler, setFormData] = useForm(
    {
      campaignLabel: {
        value: selectedCampaign?.label || '',
        isValid: true,
      },
    },
    true,
  )

  useEffect(() => {
    if (facebook.selectedAdAccount?.currency) {
      setCurrencySymbol(
        helper.getCurrencySymbol(facebook.selectedAdAccount?.currency),
      )
    }
  }, [facebook.selectedAdAccount])

  useEffect(() => {
    if (!facebook.fetchingReports) {


      if (selectedCampaign && selectedCampaign.reports) {
        let reportsToView = []
        let overallResults = {
          spend: 0,
          sales: 0,
          roas: 0,
          purchases: 0,
          costPerPurchase: 0,
        }

        let adsetLevelReports = selectedCampaign.reports.adsetLevel[filter.value]
        if (adsetLevelReports) {
          let adsetTypes = Object.keys(adsetLevelReports)
          adsetTypes.sort(fbHelper.sortAdsetsInView)
          adsetTypes.forEach(audType => {
            if (adsetLevelReports[audType].spend) {
              overallResults.spend += adsetLevelReports[audType].spend
            }
            if (adsetLevelReports[audType].sales) {
              overallResults.sales += adsetLevelReports[audType].sales
            }
            if (adsetLevelReports[audType].purchases) {
              overallResults.purchases += adsetLevelReports[audType].purchases
            }


            reportsToView.push({
              audienceType: audType,
              ...adsetLevelReports[audType],
            })
          })

          if (overallResults.spend) {
            overallResults.roas = (overallResults.sales / overallResults.spend).toFixed(2)
          } else {
            overallResults.roas = '-'
          }

          if (overallResults.purchases) {
            overallResults.costPerPurchase = (
              overallResults.spend / overallResults.purchases
            ).toFixed(2)
          } else {
            overallResults.costPerPurchase = '-'
          }

          setReportsInView(reportsToView)
          setOverallReport(overallResults)
        }
      } else {
        setReportsInView(sampleData.reports)
        setOverallReport({
          spend: 1205,
          sales: 9675,
          roas: 8.24,
          costPerPurchase: 10,
        })
      }

      setFormData({
        campaignLabel: {
          value: selectedCampaign?.label || '',
          isValid: true,
        },
      })
    }
  }, [selectedCampaign, filter])

  // TODO: Call this getInsightsForChart function in the facebook-hook,
  // while getting the Facebook reports to reduce the amount of request.
  useEffect(() => {
    getInsightsForChart(filter.value)
  }, [filter])

  const campaignSelected = label => {
    if (label === "All Campaigns") {
      getOverallAdsetBasedReportForEnhCampaigns({})
    } else {
      let newCampaign = helper.findBy(enhCampaigns, 'label', label)
      newCampaignSelectedForReport({
        campaign: newCampaign
      })
    }
  }

  const setNewFilter = (filter) => {
    if (selectedCampaign) {
      if (facebook.activeEnhCampaignIdForReports === "allEnhCampaigns") {
        getOverallAdsetBasedReportForEnhCampaigns({ filter })
      } else {
        newFilterSelectedForReport({
          filter,
          campaign: selectedCampaign
        })
      }
    }
  }

  const isSample = !enhCampaigns.length
  const isFetching = facebook.fetchingReports
  const campaignLabels = enhCampaigns.map(c => c.label).concat("All Campaigns")
  const formatAudienceType = type =>
    type.charAt(0).toUpperCase() + type.slice(1)

  if (isSmallScreen) {
    return (
      <AdvancedCampaignReportMobile
        filter={filter}
        setNewFilter={setNewFilter}
        isSample={isSample}
        isFetching={isFetching}
        currencySymbol={currencySymbol}
        overallReport={overallReport}
        reportsInView={reportsInView}
        formState={formState}
        inputHandler={inputHandler}
        campaignLabels={campaignLabels}
        campaignSelected={campaignSelected}
      />
    )
  }

  return (
    <div className="advanced-campaign-report-wrapper">
      {!isSample && (
        <div className="filters">
          {/* <div className="filter-group">
            <select className="attribution-dropdown">
              <option value="1">7-day click, 1-day view</option>
              <option value="1">7-day click</option>
              <option value="1">1-day view</option>

            </select>
            <FaCaretDown className="down-arrow-icon" />
          </div> */}
          <div className="filter-group">
            <FacebookDatePicker setFilters={setNewFilter} filter={filter} />
            <FaCaretDown className="down-arrow-icon" />
          </div>
        </div>
      )}
      <FluidCard className="advanced-campaign-report">
        <FluidCard.Header>
          <div className="header-wrapper">
            <div className="logo">
              <img
                src={enhencerLogo}
                alt="Enhencer"
                className="enhencer-logo"
                width={130}
              />
              {!isSample ? (
                <Tooltip
                  content="A dropdown menu displaying active ads for the selected location or region. Choose another region to view metrics for that specific area."
                >
                  <div className="region-dropdown">
                    <Input
                      element="dropdown"
                      placeholder="Campaign label"
                      initialValue={formState.inputs.campaignLabel.value}
                      forceValue={formState.inputs.campaignLabel.value}
                      id="label"
                      type="text"
                      options={campaignLabels}
                      onInput={inputHandler}
                      apply={campaignSelected}
                    />
                  </div>
                </Tooltip>
              ) : (
                <div className="sample-text">
                  SAMPLE REPORT
                  <Tooltip
                    content="The data displayed here is for demonstration purposes and represents sample data. Real data will become available once you start creating your campaigns with Enhencer."
                  >
                    <FaInfoCircle className="icon" />
                  </Tooltip>
                </div>
              )}
            </div>
            {!isFetching && (
              <React.Fragment>
                <Tooltip
                  content="The combined cost of all currently active campaigns."
                >
                  <div className="amount">
                    <span>
                      <span className="currency">{currencySymbol}</span>
                      {numberFormatter.formatNumber(overallReport.spend)}
                    </span>
                    <span>Total ad-cost</span>
                  </div>
                </Tooltip>
                <div className='vr-divider'></div>
                <Tooltip
                  content="The overall value generated from all campaign-related sales."
                >
                  <div className="amount">
                    <span>
                      <span className="currency">{currencySymbol}</span>
                      {numberFormatter.formatNumber(overallReport.sales)}
                    </span>
                    <span>Total sales</span>
                  </div>
                </Tooltip>
                <div className='vr-divider'></div>
                <Tooltip
                  content="The aggregate Return on Ad Spend across all campaigns."
                >
                  <div className="amount">
                    <span>
                      {overallReport.roas || "-"}
                    </span>
                    <span>ROAS</span>
                  </div>
                </Tooltip>
                <div className='vr-divider'></div>
                <Tooltip
                  content="The average cost incurred for each purchase made through the campaigns."
                >
                  <div className="amount">
                    {
                      overallReport.costPerPurchase ?
                        <React.Fragment>
                          <span>
                            <span className="currency">{currencySymbol}</span>
                            {overallReport.costPerPurchase}
                          </span>
                          <span>Cost / Purchase</span>
                        </React.Fragment>
                        :
                        <span>-</span>
                    }
                  </div>
                </Tooltip>
              </React.Fragment>
            )}
          </div>
        </FluidCard.Header>
        <FluidCard.Content>
          <div className="content-wrapper">
            {isFetching ? (
              <InlineLoadingSpinner message="Fetching ad reports..." />
            ) : (
              reportsInView.map((report, index) => {
                const { audienceType, effectiveStatus, startTime, adsetId } = report
                return <React.Fragment key={report.audienceType}>
                  <div className={`row ${audienceType}`}>
                    <div className="audience">
                      <Tooltip
                        content={
                          audienceType === 'broad'
                            ? 'It is the industry standard broad audience that is filtered by Enhencer AI, focusing on New Users who may share an interest in your product and brand.'
                            : audienceType === 'lookalike'
                              ? 'It is the audience that Enhencer Lookalike targets based on your website data, reaching new potential customers exhibiting behaviors similar to your existing website visitors.'
                              : audienceType === 'remarketing'
                                ? 'It is the audience that Enhencer AI targets for Remarketing, reaching only the website visitors who have genuinely expressed interest in your products.'
                                : ''
                        }
                      >
                        <span className='primary-text'>
                          {
                            audienceType === 'other' ? 'Custom Ad Sets' :
                              `${formatAudienceType(audienceType)}${['broad', 'lookalike', 'remarketing'].includes(audienceType) ? ' AI Audience' : ''}`
                          }
                        </span>
                      </Tooltip>
                      {/* <div className="campaign-info">
                        <img
                          className="meta-icon"
                          src={metaLogo}
                          alt="Meta"
                          width={18}
                        />
                        <span>Conversion Campaign</span>
                      </div> */}
                      <Tooltip
                        content="Indicates whether the campaign is currently active or paused."
                      >
                        <span className="minor-text">
                          Status:{' '}
                          <span className="value">{effectiveStatus}</span>
                        </span>
                      </Tooltip>
                      {startTime ? <span className='minor-text'>Start time: <span className='value'>{dateFormatter(startTime, true)}</span></span> : null}
                    </div>
                    <div className="cell">
                      <div className="stats">
                        <ReportChartCard
                          isHidden={isSample}
                          metric="spend"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The cumulative amount spent across all campaigns."
                          title="Total Spent"
                        >
                          <div className="stat">
                            {report.spend ?
                              <span>
                                <span className="currency">{currencySymbol}</span>
                                {numberFormatter.formatNumber(report.spend)}
                              </span> :
                              <span>-</span>
                            }
                            <span className='metric-title'>Total Spent</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="dailyBudget"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The allocated budget for daily ad spending."
                          title="Daily Budget"
                        >
                          <div className="stat">
                            {
                              report.dailyBudget ?
                                <span>
                                  <span className="currency">{currencySymbol}</span>
                                  {numberFormatter.formatNumber(report.dailyBudget)}
                                </span> :
                                <span>-</span>
                            }
                            <span className='metric-title'>Daily Budget</span>
                          </div>
                        </ReportChartCard>
                      </div>
                    </div>
                    <div className="vr-divider"></div>
                    <div className="cell">
                      <div className="stats">
                        <ReportChartCard
                          isHidden={isSample}
                          metric="reach"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The total number of unique users who have seen your ad."
                          title="Reach"
                        >
                          <div className="stat">
                            <span>
                              {report.reach ? numberFormatter.formatNumber(report.reach) : "-"}
                            </span>
                            <span className="metric-title">Reach</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="impressions"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The total number of times your ad has been displayed."
                          title="Impression"
                        >
                          <div className="stat">
                            <span>
                              {report.impressions ?
                                numberFormatter.formatNumber(report.impressions)
                                : "-"}
                            </span>
                            <span className="metric-title">Impression</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="frequency"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The average number of times a user has seen your ad."
                          title="Frequency"
                        >
                          <div className="stat">
                            <span>
                              {report.frequency ? numberFormatter.formatNumber(report.frequency) : "-"}
                            </span>
                            <span className="metric-title">Frequency</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="cpm"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The cost of 1,000 impressions, indicating the cost to reach a thousand users."
                          title="CPM"
                        >
                          <div className="stat">
                            {
                              report.cpm ?
                                <span>
                                  <span className="currency">{currencySymbol}</span>
                                  {numberFormatter.formatNumber(report.cpm)}
                                </span> :
                                <span>-</span>
                            }
                            <span className="metric-title">CPM</span>
                          </div>
                        </ReportChartCard>
                      </div>
                    </div>
                    <div className="vr-divider"></div>
                    <div className="cell">
                      <div className="stats">
                        <ReportChartCard
                          isHidden={isSample}
                          metric="clicks"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The number of times users have clicked on your ad."
                          title="Clicks"
                        >
                          <div className="stat">
                            <span>
                              {report.clicks ? numberFormatter.formatNumber(report.clicks) : "-"}
                            </span>
                            <span className="metric-title">Clicks</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="cpc"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The cost incurred for each click on your ad."
                          title="CPC"
                        >
                          <div className="stat">
                            {
                              report.cpc ?
                                <span>
                                  <span className="currency">{currencySymbol}</span>
                                  {numberFormatter.formatNumber(
                                    report.cpc, report.cpc < 0.1 ? 3 : 1
                                  )}
                                </span> :
                                <span>-</span>
                            }
                            <span className="metric-title">CPC</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="ctr"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The percentage of users who clicked on your ad after seeing it."
                          title="CTR"
                        >
                          <div className="stat">
                            {
                              report.ctr ?
                                <span>
                                  {numberFormatter.formatNumber(report.ctr)}%
                                </span> :
                                <span>-</span>
                            }
                            <span className="metric-title">CTR</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="addToCart"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The number of times users have added products to their cart as a result of your ad."
                          title="AddtoCart"
                        >
                          <div className="stat">
                            <span>
                              {report.addToCart ? numberFormatter.formatNumber(report.addToCart) : "-"}
                            </span>
                            <span className="metric-title">AddtoCart</span>
                          </div>
                        </ReportChartCard>
                      </div>
                    </div>
                    <div className="vr-divider"></div>
                    <div className="cell">
                      <div className="stats">
                        <ReportChartCard
                          isHidden={isSample}
                          metric="purchases"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The number of actual purchases made through the campaigns."
                          title="Purchase"
                        >
                          <div className="stat">
                            <span>
                              {report.purchases ? numberFormatter.formatNumber(report.purchases) : "-"}
                            </span>
                            <span className="metric-title">Purchase</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="costPerPurchase"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The average cost incurred for each purchase made through the campaigns."
                          title="Cost/Purchase"
                        >
                          <div className="stat">
                            {
                              report.costPerPurchase ?
                                <span>
                                  <span className="currency">{currencySymbol}</span>
                                  {numberFormatter.formatNumber(report.costPerPurchase)}
                                </span> :
                                <span>-</span>
                            }
                            <span className="metric-title">Cost/Purchase</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="sales"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The total value generated from sales attributed to the campaigns."
                          title="Sales"
                        >
                          <div className="stat">
                            {
                              report.sales ?
                                <span>
                                  <span className="currency">{currencySymbol}</span>
                                  {numberFormatter.formatNumber(report.sales)}
                                </span> :
                                <span>-</span>
                            }
                            <span className="metric-title">Sales</span>
                          </div>
                        </ReportChartCard>
                        <ReportChartCard
                          isHidden={isSample}
                          metric="roas"
                          adsetId={adsetId}
                          audienceType={audienceType}
                          description="The revenue generated for every dollar spent on advertising."
                          title="ROAS"
                        >
                          <div className="stat">
                            <span>
                              {report.roas ? numberFormatter.formatNumber(report.roas) : "-"}
                            </span>
                            <span className="metric-title">ROAS</span>
                          </div>
                        </ReportChartCard>
                      </div>
                    </div>
                  </div>
                  {reportsInView.length - 1 !== index && reportsInView[index + 1]?.audienceType !== "traffic" && (
                    <div className="divider"></div>
                  )}
                </React.Fragment>
              }))
            }
          </div>
        </FluidCard.Content>
      </FluidCard>
    </div>
  )
}

const sampleData = {
  overall: {
    spend: '',
    sales: '',
    roas: '',
    costPerPurchase: '',
  },
  reports: [
    {
      audienceType: 'Remarketing',
      status: 'ACTIVE',
      spend: 356,
      dailyBudget: 12,
      sales: 4393,
      s: 25,
      roas: 12.34,
      costPerPurchase: 14,
      reach: 15.236,
      impressions: 53.326,
      frequency: 3.5,
      cpm: 22,
      clicks: 1.066,
      cpc: 1.13,
      ctr: 2.0,
      addToCart: 120,
    },
    {
      audienceType: 'Lookalike',
      status: 'ACTIVE',
      spend: 849,
      dailyBudget: 28,
      sales: 5543,
      purchases: 28,
      roas: 6.53,
      costPerPurchase: 30,
      reach: 15.236,
      impressions: 53.326,
      frequency: 3.5,
      cpm: 22,
      clicks: 1.066,
      cpc: 1.13,
      ctr: 2.0,
      addToCart: 120,
    },
  ],
}

export default AdvancedCampaignReport
