import React, { useState } from 'react'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { toast } from 'react-toastify'
import { useEffect, useSelector } from 'react'
import Button from '../../shared/components/FormElements/Button'
import dateFormatter from '../../shared/util/dateFormatter'
import shopifyLogo from '../../shared/assets/images/icons/shopify_logo_black.png'
import iyzicoLogo from '../../shared/assets/images/icons/iyzico_logo.png'
import stripeLogo from '../../shared/assets/images/icons/stripe-logo.png'
import checkIcon from '../../shared/assets/images/icons/check-green.svg'
import upgrade from '../../shared/assets/images/admin/upgrade-plan.svg'
import { FaChevronUp } from 'react-icons/fa'
import constants from '../../constants'
import pricingConstants from '../../constants-pricing'
import helper from '../../shared/util/helper'
import numberFormatter from '../../shared/util/numberFormatter'

const SubscriptionDetails = props => {
  const {
    status,
    nextPaymentDate,
    lastPaymentDate,
    subscriptionCreatedDate,
    subscriptionEndDate,
    isFromShopify,
    activePlanName,
    activePlanPrice,
    activePlanInterval,
    pricingVersion,
    source,
    error,
    retryPayment,
    updateCard,
    cancelSubscription,
    upgradePlan,
    usageCharges,
    monthlyAdSpent,
  } = props

  const paymentChannelLogo = source === 'Iyzico' ? iyzicoLogo : stripeLogo
  const { sendRequest } = useHttpClient()
  /* const packagesInUse = isFromShopify
    ? constants.shopifyMonthlyPackages
    : constants.monthlyPackages */
  // const packagesInUse = pricingVersion === "v2" ?
  //   (activePlanInterval === "MONTHLY" ? pricingConstants.monthlyPackages : pricingConstants.yearlyPackages)
  //   :
  //   pricingConstants.v1_packages

  const packagesInUse =
    pricingVersion === 'v3' || pricingVersion === 'v2'
      ? activePlanInterval === 'MONTHLY'
        ? pricingConstants.monthlyPackages
        : pricingConstants.yearlyPackages
      : pricingConstants.v1_packages

  const [currentPackage, setCurrentPackage] = useState({})
  const [nextPackage, setNextPackage] = useState()

  useEffect(() => {
    const getPackageFeatures = () => {
      let p = helper.findBy(packagesInUse, 'name', activePlanName) || {
        name: 'Custom Plan',
        minAdSpent: '10,000',
        price: -1,
        paymentInterval: 'MONTHLY',
        actionName: 'Book a Demo',
      }
      let finalPackage = { ...p }

      if (p.name === 'Ad Health Checkup') {
      } else if (p.name === 'Starter') {
        finalPackage.features = [
          {
            text: 'Industrial Trend Comparison',
            isChecked: true,
          },
          ...p.features,
        ]
      } else if (p.name === 'Growth') {
        finalPackage.features = [
          {
            text: 'Industrial Trend Comparison',
            isChecked: true,
          },
          ...packagesInUse[1].features,
          ...p.features,
        ]
      } else if (p.name === 'Enterprise') {
        finalPackage.features = [
          {
            text: 'Industrial Trend Comparison',
            isChecked: true,
          },
          ...packagesInUse[1].features,
          ...packagesInUse[2].features.slice(1),
          ...p.features,
        ]
      }

      setCurrentPackage(finalPackage)
    }

    getPackageFeatures()
  }, [])

  return (
    <div className="subscribed">
      <div className="subscribe-container">
        <div className="left-part">
          <div className="plan-info-card">
            <div className="plan-column">
              <div>
                <div className="plan-name">
                  Your Current Plan :{' '}
                  <span className="green-text">
                    {activePlanName ? activePlanName : 'Custom'}{' '}
                  </span>
                </div>
                {nextPaymentDate && (
                  <div className="renew-date">
                    Your next billing is on{' '}
                    {dateFormatter(nextPaymentDate, true)}
                  </div>
                )}
              </div>
              <div>
                <div className="plan-price">
                  Current Plan:{' '}
                  <span className="green-text">
                    {activePlanPrice ? (
                      <React.Fragment>
                        {activePlanPrice} /{' '}
                        {activePlanInterval
                          ? activePlanInterval.toLowerCase()
                          : 'Monthly'}
                      </React.Fragment>
                    ) : (
                      'No info'
                    )}
                  </span>
                </div>
                <div className="payment-channel">
                  Payment Channel:{' '}
                  <img
                    alt="channellogo"
                    src={isFromShopify ? shopifyLogo : paymentChannelLogo}
                    className="channel-icon"
                  />
                </div>
              </div>
              <div className="buttons-row">
                {!isFromShopify ? (
                  <>
                    {' '}
                    <Button
                      className="plan-button update-billing-info-button "
                      onClick={updateCard}
                    >
                      Update Billing Information
                    </Button>
                  </>
                ) : (
                  ''
                )}

                <Button
                  className="plan-button cancel-subs-button"
                  onClick={cancelSubscription}
                >
                  Cancel Subscription
                </Button>
              </div>
            </div>
            <div className="plan-features-column">
              {currentPackage.features?.length ? (
                <div>
                  Available Features in your plan:
                  <div className="plan-features-box">
                    {currentPackage.features
                      ? currentPackage.features.map((feature, index) => {
                          return (
                            <div
                              key={index}
                              // className={`grid-item ${currentPackage.features[index] ? '' : 'unchecked'
                              className={`grid-item ${
                                feature.isChecked ? '' : 'unchecked'
                              }`}
                            >
                              <span>
                                <img
                                  className="icon"
                                  src={checkIcon}
                                  alt="check icon"
                                />
                              </span>
                              {feature.text}
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="visitor-count-part">
            <div className="visitor-count-header">
              <div>
                Maximum ad spent per month for current plan:{' '}
                <b>${currentPackage.maxAdSpent || 1500}</b>
              </div>
              <div>
                Your ad spent this month:{' '}
                <span className="green-text">
                  {monthlyAdSpent
                    ? '$' + numberFormatter.formatNumber(monthlyAdSpent, 0)
                    : 'Waiting data...'}
                </span>{' '}
              </div>

              {/* <div className='additional-fee'>
              Additional fee from your ad revenue:{' '}
                <span className="green-text">{additionalCharge}</span>{' '}
              </div>
               */}
            </div>
            {/* <div className="visitor-count-slider">
              <input
                type="range"
                min="1"
                max="100000"
                value="45023"
                className="slider"
                id="myRange"
                readOnly
              />
              <div className="number-range">
                <div>0</div>
                <div>100k</div>
              </div>
            </div> */}
          </div>

          <div className="bottom-payment-info">
            <h4>Your Plan Details</h4>
            <div className="payment-info-row header">
              <div className="plan-info-part">Created Date</div>
              <div className="plan-info-part">Subscription Plan</div>
              <div className="plan-info-part">Last Payment</div>
              <div className="plan-info-part">Next Payment</div>
              <div className="plan-info-part">Status</div>
              {isFromShopify ? <div className="plan-info-part"></div> : ''}
            </div>
            <div className="payment-info-row body">
              <div className="plan-info-part">
                {' '}
                {subscriptionCreatedDate
                  ? dateFormatter(subscriptionCreatedDate, true)
                  : ''}
              </div>
              <div className="plan-info-part">
                {activePlanName ? activePlanName : 'Custom'}
              </div>
              <div className={`plan-info-part ${error ? `error` : ``}`}>
                {error
                  ? `your payment could not be processed on ${dateFormatter(
                      lastPaymentDate,
                      true,
                    )}`
                  : lastPaymentDate
                    ? dateFormatter(lastPaymentDate, true)
                    : ''}
                {error ? (
                  <button className="retry-payment" onClick={retryPayment}>
                    Retry Payment
                  </button>
                ) : (
                  ``
                )}
              </div>
              <div className="plan-info-part">
                {nextPaymentDate ? dateFormatter(nextPaymentDate, true) : ''}
              </div>
              <div className="plan-info-part subscription-status">
                <span className={status}>{status}</span>
              </div>
              {isFromShopify ? (
                <div className="plan-info-part">
                  <span>
                    <div className="upgrade-plan-button">
                      <Button
                        className="upgrade-plan"
                        onClick={() => upgradePlan(nextPackage)}
                      >
                        Upgrade Plan <FaChevronUp />{' '}
                      </Button>
                    </div>
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          {usageCharges?.length ? (
            <div className="bottom-payment-info usage-charges">
              <h4>Additional Fees</h4>

              <div className="payment-info-row header">
                <div className="plan-info-part">Name</div>
                <div className="plan-info-part">Created Date</div>
                <div className="plan-info-part">Price</div>
                <div className="plan-info-part">Billing On</div>
              </div>
              {usageCharges.map(charge => (
                <div
                  className={`payment-info-row body ${charge.name === 'Total' ? 'total' : ''}`}
                  key={charge.id}
                >
                  <div className="plan-info-part">
                    {charge.name || 'AI Ads Weekly Revenue Charge'}
                  </div>
                  <div className="plan-info-part">
                    {dateFormatter(new Date(charge.created_at), true)}
                  </div>
                  <div className="plan-info-part">${charge.price}</div>

                  <div className="plan-info-part">
                    {nextPaymentDate
                      ? dateFormatter(nextPaymentDate, true)
                      : ''}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default SubscriptionDetails
