import React, { useEffect, useState } from 'react'
import { Popover } from 'react-tiny-popover'

import RegionPopoverContent from './RegionPopoverContent'
import { useSelector } from 'react-redux'
import { useFacebookCampaignEditor } from '../../shared/hooks/facebook-campaign-editor-hook'

const CountriesInput = () => {
  const regions = useSelector(state => state.facebookCampaignEditor.currentCampaign?.targeting?.countries) ?? []
  const regionsString = (() => {return regions.toString().replaceAll(',', ', ')})()
  const { changeTargeting } = useFacebookCampaignEditor()
  const [isRegionPopoverOpen, setIsRegionPopoverOpen] = useState(false)
  const countryCountLimit = 5

  const close = () => {
    setIsRegionPopoverOpen(false)
  }
  
  const setRegions = (newRegions) => { 
    changeTargeting({
      countries: newRegions
    })
  }

  return (
    <div className="countries-input">
      <Popover
        isOpen={isRegionPopoverOpen}
        positions={['bottom', 'right', 'left', 'top']}
        padding={-25}
        onClickOutside={() => {
          setIsRegionPopoverOpen(false)

          /* if (onBlur) {
            onBlur(regions, regionsString)
          } */
        }}
        content={
          <RegionPopoverContent regions={regions} setRegions={setRegions} close={close} countryCountLimit={countryCountLimit} />
        }
      >
        <input
          type="text"
          value={regionsString}
          title={regionsString}
          onClick={() => {
            // if (disabled) return
            setIsRegionPopoverOpen(true)
          }}
          placeholder="Select"
          readOnly
        />
      </Popover>
    </div>
  )
}

export default CountriesInput
