import React, { useEffect, useMemo, useState } from 'react'
import helper from '../../shared/util/helper'


const CampaignDetailErrors = ({ errors }) => {

  return <React.Fragment>

    {errors.adCampaign ? (
      <div className="adcampaign-error">
        <span className="adcampaign-error-title">Campaign Error</span>
        <div className="adcampaign-error-description">
          <span className="adcampaign-error-subtitle">
            {errors.adCampaign.error_user_title}
          </span>
          <div className="adcampaign-error-message">
            {errors.adCampaign.error_user_msg}
            {` (#${errors.adCampaign.error_subcode})`}
          </div>
        </div>
      </div>
    ) : errors.adCreative ? (
      <div className="adcampaign-error">
        <span className="adcampaign-error-title">
          Ad Creative Error
        </span>
        <div className="adcampaign-error-description">
          <span className="adcampaign-error-subtitle">
            {errors.adCreative.error_user_title}
          </span>
          <div className="adcampaign-error-message">
            {errors.adCreative.error_user_msg}
            {` (#${errors.adCreative.error_subcode})`}
          </div>
        </div>
      </div>
    ) : (
      <div className="details">
        {!helper.isEmptyObject(errors?.adsets) || !helper.isEmptyObject(errors?.ads) && (
          <div className="errors">
            {Object.keys(errors).map(level => {
              if (!errors[level] || level === 'adCampaign') return

              return Object.keys(errors[level]).map(type => {
                const error = errors[level][type]
                if (!error) return

                const formatLevel = l =>
                  l === 'adsets' ? 'Adset' : 'Ad'

                return (
                  <details
                    className="campaign-error"
                    key={error.fbtrace_id + level + type}
                  >
                    <summary className="campaign-error-title">
                      <span className="campaign-label">
                        {formatLevel(level)} Error:{' '}
                        <span className="error-message">
                          {`${type[0].toUpperCase()}${type.slice(1)}`}
                        </span>
                      </span>
                    </summary>
                    {(error.error_user_title ||
                      error.error_user_msg ||
                      error.message ||
                      error.error_subcode) && (
                        <div className="details-content">
                          <span className="error-user-title">
                            {error.error_user_title}
                          </span>
                          <span className="error-user-msg">
                            {error.error_user_msg || error.message}
                            {error.error_subcode &&
                              ` (#${error.error_subcode})`}
                          </span>
                        </div>
                      )}
                  </details>
                )
              })
            })}
          </div>
        )}
      </div>
    )}
  </React.Fragment>
}

export default CampaignDetailErrors