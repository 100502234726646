import countries from "../data/countries.json";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isElementInViewport: function (el) {

    var rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  },
  findBy: function (array, propertyName, value) {
    const foundElement = array.find(function (element) {
      return element[propertyName] === value;
    });
    return foundElement;
  },
  findAll: function (array, propertyName, value) {
    var foundList = array.filter(function (element) {
      return element[propertyName] === value;
    });
    return foundList;
  },
  findIndex: function (array, propertyName, value) {
    return array.findIndex(function (element) {
      return element[propertyName] === value;
    });
  },
  clone: function clone(a) {
    return JSON.parse(JSON.stringify(a));
  },
  isDefined: function (element) {
    return (element !== null && element !== undefined && element !== "");
  },
  isEmptyObject: function (obj) {
    if (obj) return !Object.keys(obj).length; else return false;
  },
  isEqual: function (a, b) {
    if (a === b) {
      return true
    }

    if (
      typeof a !== 'object' ||
      typeof b !== 'object' ||
      a === null ||
      b === null
    ) {
      return false
    }

    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)

    if (aKeys.length !== bKeys.length) {
      return false
    }

    for (const key of aKeys) {
      if (!this.isEqual(a[key], b[key])) {
        return false
      }
    }

    return true
  },
  isAdmin: function (email) {
    return email === "admin@enhencer.com";
  },
  isDev: function (email) {
    return email === "dev@enhencer.com";
  },
  isDevOrAdmin: function (email) {
    return email === "dev@enhencer.com" || email === "admin@enhencer.com";
  },
  isReport: function (email) {
    return email === "report@enhencer.com" || email === "team-report@enhencer.com";;
  },
  isTeamReport: function (email) {
    return email === "team-report@enhencer.com";
  },
  getUIReadyUserObject: function ({ user }) {
    delete user.password
    const updatedUser = { ...user }
    updatedUser.id = user._id
    updatedUser.completedRegistration =
      this.isDefined(user.contactNumber) &&
      user.contactNumber !== '' &&
      this.isDefined(user.crmDetails?.inboundType)
    updatedUser.isDemo =
      !user.isSpecialUser &&
      (!this.isDefined(user.connectFlow) ||
        (this.isDefined(user.connectFlow) &&
          !user.connectFlow.segmentsReady))
    
    return updatedUser
  },
  getAudienceNo: function (audienceName) {
    switch (audienceName) {
      case "Enhencer Audience 1":
      case "enhencer_audience_1":
        return 1;
      case "Enhencer Audience 2":
      case "enhencer_audience_2":
        return 2;
      case "Enhencer Audience 3":
      case "enhencer_audience_3":
        return 3;
      case "Enhencer Audience 4":
      case "enhencer_audience_4":
        return 4;
      case "Enhencer Audience 5":
      case "enhencer_audience_5":
        return 5;
      case "Enhencer Audience 6":
      case "enhencer_audience_6":
        return 6;
      case "Enhencer Audience 7":
      case "enhencer_audience_7":
        return 7;
      case "Enhencer Audience 8":
      case "enhencer_audience_8":
        return 8;
      case "Enhencer Audience 9":
      case "enhencer_audience_9":
        return 9;
      case "Enhencer Audience 10":
      case "enhencer_audience_10":
        return 10;
      default:
        return -1;
    }
  },
  getAudienceIndex: function (audienceName) {
    return this.getAudienceNo(audienceName) - 1
  },
  getSortBy: function (sortByOption) {
    switch (sortByOption) {
      case "Name (A - Z)":
        return {
          "name": "name",
          "option": "asc",
        };
      case "Name (Z - A)":
        return {
          "name": "name",
          "option": "desc",
        };
      case "Created At (Old - New)":
        return {
          "name": "id",
          "option": "asc",
        };
      case "Created At (New - Old)":
        return {
          "name": "id",
          "option": "desc",
        };
      case "Contract Start Date (Old - New)":
        return {
          "name": "crmDetails.contractStartDate",
          "option": "asc",
        };
      case "Contract Start Date (New - Old)":
        return {
          "name": "crmDetails.contractStartDate",
          "option": "desc",
        };
      case "Last Action Date (Old - New)":
        return {
          "name": "crmDetails.lastActionDate",
          "option": "asc",
        };
      case "Last Action Date (New - Old)":
        return {
          "name": "crmDetails.lastActionDate",
          "option": "desc",
        };
      case "Next Action Date (Old - New)":
        return {
          "name": "crmDetails.nextActionDate",
          "option": "asc",
        };
      case "Next Action Date (New - Old)":
        return {
          "name": "crmDetails.nextActionDate",
          "option": "desc",
        };
      case "Last Report Date (Old - New)":
        return {
          "name": "crmDetails.lastReportDate",
          "option": "asc",
        };
      case "Last Report Date (New - Old)":
        return {
          "name": "crmDetails.lastReportDate",
          "option": "desc",
        };
      case "Price - TL (Low - High)":
        return {
          "name": "tlPrice",
          "option": "asc",
        };
      case "Price - TL (High - Low)":
        return {
          "name": "tlPrice",
          "option": "desc",
        };
      case "Price - USD (Low - High)":
        return {
          "name": "usdPrice",
          "option": "asc",
        };
      case "Price - USD (High - Low)":
        return {
          "name": "usdPrice",
          "option": "desc",
        };
      case "Last Run At (Old - New)":
        return {
          "name": "job.lastRunAt",
          "option": "asc",
        };
      case "Last Run At (New - Old)":
        return {
          "name": "job.lastRunAt",
          "option": "desc",
        };
      case "Next Run At (Old - New)":
        return {
          "name": "job.nextRunAt",
          "option": "asc",
        };
      case "Next Run At (New - Old)":
        return {
          "name": "job.nextRunAt",
          "option": "desc",
        };
      // case "Price (Low - High)":
      //   return {
      //     "name": "crmDetails.subscription.mrr",
      //     "option": "asc",
      //   };
      // case "Price (High - Low)":
      //   return {
      //     "name": "crmDetails.subscription.mrr",
      //     "option": "desc",
      //   };
      default:
        return {
          "name": "created",
          "option": "desc",
        };
    }
  },
  getShortAudienceName: function (audienceName) {
    if (audienceName === 'New User Lookalike Audience') {
      return 'LAL'
    } else if (audienceName === 'High Intent Remarketing Audience') {
      return 'High REM'
    } else {
      return 'REM'
    }
  },
  addMonthToDateAndReturnISO(d) {
    return this.addMonth(d).toISOString()
  },
  addMonthAndReturnTimestamp(d) {
    return addMonth(d).getTime()
  },
  addYearAndReturnTimestamp(d) {
    return addYear(d).getTime()
  },
  getCustomerDomain(customer) {
    let domain = customer.shopify
      ? customer.shopify.name || customer.shopify.domain
      : customer.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')

    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }

    return domain || ""
  },
  getCurrencySymbol(c) {
    switch (c) {
      case "TRY":
      case "TL":
        return "₺";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return c;
    }
  },
  getCountryPhonePrefix(countryName) {
    const country = this.findBy(countries, "name", countryName)
    if (!country) return ""

    return country.phone
  },
  getGendersList(value) {
    switch (value) {
      case 'Male':
        return [1]
      case 'Female':
        return [2]
      case 'All':
        return [1, 2]
    }
  },
  getGenderString(gendersList) {
    if (gendersList.includes(1) && gendersList.includes(2)) {
      return 'All'
    } else if (gendersList.includes(1)) {
      return 'Male'
    } else if (gendersList.includes(2)) {
      return 'Female'
    }
  },
  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  convertDateToString(dateString) {
    if (dateString === undefined) {
      return ''
    }
    const date = new Date(dateString);

    const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${formattedDate} - ${formattedTime}`;
  }

}

function addMonth(d) {
  let date = new Date(d)

  const endDate = new Date(date.getTime());
  const originalTimeZoneOffset = endDate.getTimezoneOffset();
  endDate.setMonth(endDate.getMonth() + 1);
  while (monthDiff(date, endDate) > 1) {
    endDate.setDate(endDate.getDate() - 1);
  }
  const endTimeZoneOffset = endDate.getTimezoneOffset();
  const diff = endTimeZoneOffset - originalTimeZoneOffset;
  const finalDate = diff ? endDate.setMinutes(endDate.getMinutes() - diff) : endDate;
  return new Date(finalDate);
}

function monthDiff(from, to) {
  const years = to.getFullYear() - from.getFullYear();
  const months = to.getMonth() - from.getMonth();
  return 12 * years + months;
}

function addYear(d) {
  let date = new Date(d)

  const endDate = new Date(date.getTime());
  endDate.setFullYear(endDate.getFullYear() + 1);
  const finalDate = endDate;
  return new Date(finalDate);
}
