import React, { useMemo, useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import items from '../../shared/data/success-stories.json'

import HeaderBackground from '../../shared/assets/images/ai-ads/success-stories-slider-header-bg.png'

import SPXBackground from '../../shared/assets/images/ai-ads/success-stories-spx.png'
import SporjinalBackground from '../../shared/assets/images/ai-ads/success-stories-sporjinal.png'
import LacosteBackground from '../../shared/assets/images/ai-ads/success-stories-lacoste.png'
import SuiSleepBackground from '../../shared/assets/images/ai-ads/success-stories-sui-sleep.png'
import JebindeBackground from '../../shared/assets/images/ai-ads/success-stories-jebinde.png'

import './SuccessStoriesSlider.css'

const SuccessStoriesSlider = () => {
  const backgroundImages = {
    spx: SPXBackground,
    sporjinal: SporjinalBackground,
    lacoste: LacosteBackground,
    suiSleep: SuiSleepBackground,
    jebinde: JebindeBackground,
  }

  const [currentItemInfo, setCurrentItemInfo] = useState({
    idx: 0,
    isOpen: false,
  })

  const currentItem = useMemo(
    () => items[currentItemInfo.idx],
    [currentItemInfo.idx],
  )

  const handlePrev = () => {
    if (currentItemInfo.idx === 0) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx - 1,
      isOpen: false,
    }))
  }

  const handleNext = () => {
    if (currentItemInfo.idx === items.length - 1) {
      return
    }

    setCurrentItemInfo(prev => ({
      idx: prev.idx + 1,
      isOpen: false,
    }))
  }

  return (
    <div className="success-stories-slider">
      {items.length > 1 && (
        <React.Fragment>
          <div
            onClick={handlePrev}
            className={`controller prev${
              currentItemInfo.idx === 0 ? ' disabled' : ''
            }`}
          >
            <FaAngleLeft className="icon" />
          </div>
          <div
            onClick={handleNext}
            className={`controller next${
              currentItemInfo.idx === items.length - 1 ? ' disabled' : ''
            }`}
          >
            <FaAngleRight className="icon" />
          </div>
        </React.Fragment>
      )}
      <a href={currentItem.url} target="_blank">
        <div className="content">
          <div className="company">
            <div className="header">
              <h5 className="line">Why Enhencer AI Ads?</h5>
              <h5 className="line">
                Take your business to the next level of growth with AI Ads!
              </h5>
              <img
                className="header-background"
                src={HeaderBackground}
                alt="Background"
              />
            </div>
            <div className="main">
              <div className="texts">
                <p className="description">{currentItem.description}</p>
                <div className="metrics">
                  {currentItem.metrics.map(m => (
                    <span key={m.name} className="metric">
                      <span className="value">{m.value}</span>
                      <span className="name">{m.name}</span>
                    </span>
                  ))}
                </div>
              </div>
              <div className="image">
                <img
                  className="background-image"
                  src={backgroundImages[currentItem.company]}
                  alt="Background"
                />
                <img className="logo" src={currentItem.logo} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default SuccessStoriesSlider
