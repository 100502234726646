import React from "react";
import { Chart } from "react-google-charts";
import "./CRMGeoChart.css"

/* export const data = [
    ["Country", "Active Customers", "Total MRR"],
    ["Turkey", 134, 31289],
    ["United States", 16, 2540],
    ["Slovenia", 2, 799],
    ["France", 2, 360],
    ["MENA", 2, 349],
    ["Netherlands", 4, 336],
    ["UK", 5, 335],
    ["Australia", 2, 328],
    ["Pakistan", 1, 199],
    ["Spain", 1, 149],
    ["United Arab Emirates", 1, 99],
    ["Mexico", 1, 99],
    ["Europe", 1, 99],
    ["Germany", 1, 79],
    ["Croatia", 1, 79],
    ["Switzerland", 1, 69],
    ["Singapore", 1, 49],
]; */

const CRMGeoChart = props => {
    return <div className="crm-geochart">
        <Chart mapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} className="chart"
            chartEvents={[
                {
                    eventName: "select",
                    callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = props.mapData[selection[0].row + 1];
                    },
                },
            ]}
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={props.mapData}
            options={{
                tooltip: { isHtml: true },
                legend: 'none',
                colorAxis: {
                    colors: ['#85ca6e', '#478433']
                }
            }}
        />
    </div>
}

export default CRMGeoChart;