import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import helper from "../../util/helper";
import user from "../../../store/user";

const PrivateRoute = ({ children, permissions }) => {
  const auth = useSelector((state) => state.auth);
  const userPermissions = auth.user?.accessDetails?.permissions || [];


  if (!auth.user) {
    return <Navigate to="/login" />;
  }

  if (permissions && !permissions.some((permission) => userPermissions.includes(permission))) {
    return <Navigate to="/login" />;
  }
  return children ? children : <Outlet />;
};

export default PrivateRoute;
