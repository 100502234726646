import React, { useEffect, useState } from "react";
import { FaCalendarCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import "./SupportMeetingSuccess.css"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../../shared/components/FormElements/Button";
import { useUser } from "../../shared/hooks/user-hook";

const SupportMeetingSuccess = () => {

  const user = useSelector(state => state.auth.user);
  const { createSupportMeeting } = useUser()
  const location = useLocation()
  const navigate = useNavigate()
  const [meetingTime, setMeetingTime] = useState()

  useEffect(() => {
    if (location.search !== "") {
      const query = new URLSearchParams(window.location.search);


      const generateMeetingTime = (starts, ends) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
        var dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const getDoubleString = (s) => {
          return (s / 10 < 1 ? "0" : "") + s.toString()
        }
        let offset = starts.getTimezoneOffset()
        let offsetMagnitude = offset > 0 ? offset : (offset * -1)

        return {
          time: getDoubleString(starts.getHours()) + ":" + getDoubleString(starts.getMinutes()) + " - " + getDoubleString(ends.getHours()) + ":" + getDoubleString(ends.getMinutes()) + ", " + dayNames[starts.getDay()] + ", " + monthNames[starts.getMonth()] + " " + starts.getDate() + ", " + starts.getFullYear(),
          timeZone: "GMT" + (offset > 0 ? "-" : "+") + getDoubleString((offsetMagnitude - (offsetMagnitude % 60)) / 60) + ":" + getDoubleString(offsetMagnitude % 60)
        }
      }

      let pm = query.get("pm").split(".")
      let performanceManager = pm.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(" ")

      let starts = new Date(query.get("event_start_time"))
      let ends = new Date(query.get("event_end_time"))
      let fullName = query.get("invitee_full_name")
      
      let meetingTimeResult = generateMeetingTime(starts, ends)

      let meetingTime = meetingTimeResult.time
      let timeZone = meetingTimeResult.timeZone
      setMeetingTime(`${meetingTime} ${timeZone}`)

      let email = query.get("invitee_email")
      
      let userMeetingData = {
        email: email,
        name: fullName,
        meetingDone: false,
        customNote: query.get("answer_1"),
        meetingTime: meetingTime + " " + timeZone,
        meetingStarts: starts.toISOString(),
        meetingEnds: ends.toISOString(),
        performanceManager
      }

      if (userMeetingData) {
        createSupportMeeting({ userMeetingData: userMeetingData })
      }
    }
  }, [location])

  const onClick = () => {
    navigate(`/ai-ads`)
  }


  return <div className="meeting-scheduled-success">
    <br />
    <div className="header-row row">

      <FaCalendarCheck fontSize={30} className="icon" />
      <h2>Support Meeting scheduled successfully!</h2>
    </div>

    <span>{user?.name}, we are looking forward to meet with you!
    </span>
    <br />
    <br />
    <span>
      Meeting time: &nbsp;
      <b>{meetingTime}</b>
    </span>
    <br />
    <p>A calendar invitation has been sent to your email address.</p>

    <br />
    <Button
      className="plan-button continue-flow-button"
      onClick={onClick}
    >
      Back to Home Page
    </Button>
  </div>
}

export default SupportMeetingSuccess;
