import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import dateFormatter from "../../shared/util/dateFormatter";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { ToastContainer, toast } from 'react-toastify';
import Switch from "../../shared/components/UIElements/Switch";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import Table from "../../shared/components/UIElements/Table";

import "./CardAdminViewUserDataVariables.css"
import InlineLoadingSpinner from "../../shared/components/UIElements/InlineLoadingSpinner";

const CardAdminViewUserDataVariables = ({ userId }) => {
  const [visitorDataVariables, setVisitorDataVariables] = useState();
  const [event, setEvent] = useState();
  const [maxAudiences, setMaxAudiences] = useState(5);
  const [targetChoices, setTargetChoices] = useState([]);
  const [audienceSettings, setAudienceSettings] = useState([]);
  const [tableData, setTableData] = useState()
  const [gettingVisitingDataVariables, setGettingVisitingDataVariables] = useState(false)

  const auth = useSelector((state) => state.auth);
  const { sendRequest } = useHttpClient();


  useEffect(() => {
    const init = async () => {
      if (userId) {
        try {
          setGettingVisitingDataVariables(true)
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/admin/getVisitingDataVariables`,
            "POST",
            JSON.stringify({
              userId: userId
            }),
            {
              Authorization: "Bearer " + auth.token,
              "Content-Type": "application/json",
            }
          );
          setVisitorDataVariables(responseData);
          let tableData = { head: ["Customers", "Listing", "Product", "Purchase"], rows: [[]] }

          tableData.rows[0].push(dateFormatter(responseData.dates.customerMinDate) || "-")
          tableData.rows[0].push(dateFormatter(responseData.dates.listingMinDate) || "-")
          tableData.rows[0].push(dateFormatter(responseData.dates.productMinDate) || "-")
          tableData.rows[0].push(dateFormatter(responseData.dates.purchaseMinDate) || "-")


          setTableData(tableData)

          setEvent(responseData.event);

          const userResult = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/dev/getMaxAudiences/${userId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          setMaxAudiences(userResult.maxAudiences);

          const project = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/dev/getProjectByUserId/${userId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
          if (project.connectInfo?.targetChoices) {
            setTargetChoices(project.connectInfo.targetChoices);
          } else {
            setTargetChoices([{ name: "basket", usageLabel: "Not" }, { name: "purchase", usageLabel: "Use" }]);
          }
          if (project.audiences) {
            const tempAudienceSettings = [];
            project.audiences.forEach(audience => {
              tempAudienceSettings.push({
                name: audience.name,
                type: audience.type,
                thresholds: audience.thresholds ? audience.thresholds : { lower: 25, upper: 75 }
              });
            })
            setAudienceSettings(tempAudienceSettings);
          }
        } catch (err) {
          /* toast.error(err?.message || "Something went wrong") */
        } finally {
          setGettingVisitingDataVariables(false)
        }
      }
    };

    init();

  }, [userId]);

  const onDayChange = (e) => {
    setEvent({
      ...event,
      [e.target.id]: parseInt(e.target.value),
    });
  };

  const onMaxAudiencesChange = (e) => {
    setMaxAudiences(parseInt(e.target.value));
  };

  const saveMaxAudiences = async () => {
    const data = {
      maxAudiences: maxAudiences,
    };
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${userId}`,
        "PATCH",
        JSON.stringify(data),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      toast.success("Changes saved!");
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };

  const onSelectTargetChoice = (selection, index) => {
    let tempTargetChoices = [...targetChoices];
    tempTargetChoices[index] = {
      ...tempTargetChoices[index],
      usageLabel: selection
    }
    setTargetChoices(tempTargetChoices);
  };

  const saveTargetChoices = async () => {
    const data = {
      "connectInfo.targetChoices": targetChoices,
    };
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/updateProjectByUserId/${userId}`,
        "PATCH",
        JSON.stringify(data),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      toast.success("Changes saved!");
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };

  const onLowerThresholdChange = (e, index) => {
    let tempAudienceSettingList = [...audienceSettings];
    tempAudienceSettingList[index] = {
      ...tempAudienceSettingList[index],
      thresholds: {
        ...tempAudienceSettingList[index].thresholds,
        lower: parseInt(e.target.value)
      }
    }
    setAudienceSettings(tempAudienceSettingList);
  };

  const onUpperThresholdChange = (e, index) => {
    let tempAudienceSettingList = [...audienceSettings];
    tempAudienceSettingList[index] = {
      ...tempAudienceSettingList[index],
      thresholds: {
        ...tempAudienceSettingList[index].thresholds,
        upper: parseInt(e.target.value)
      }
    }
    setAudienceSettings(tempAudienceSettingList);
  };

  const saveAudienceThresholdsSettings = async () => {
    const data = audienceSettings;
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/updateAudienceThresholdsSettings/${userId}`,
        "PATCH",
        JSON.stringify(data),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      toast.success("Changes saved!");
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };


  const saveEvent = async () => {
    const eventData = {
      userId: userId,
      dayCount: event.DAY_COUNT,
    };
    try {
      const event = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/dev/updateVisitorTableEvents`,
        "POST",
        JSON.stringify(eventData),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
      setEvent(event);
      toast.success("Changes saved!");
    } catch (err) {
      toast.error(err?.message || "Something went wrong")
    }
  };

  return visitorDataVariables ?
    <div className="section view-user card-admin-user-data-variables">
      <div>
        <div className="header-row">
          <h4>
            User Variables
          </h4>
        </div>
        <div className="content-box">
          <div className="attribute-name">Min Dates in Visitor Tables</div>

          <div className="row">
            <Table data={tableData} />
            {event && (
              <div>
                <div>
                  <div className="text-edit row">
                    <label>Delete data after </label>
                    <input
                      id="DAY_COUNT"
                      type="number"
                      value={event.DAY_COUNT}
                      onChange={onDayChange}
                    />
                    <label>days</label>
                  </div>
                </div>

                <Button className="small" onClick={saveEvent} >
                  {event.EVENT_NAME ? "Save" : "Create"}
                </Button>

              </div>
            )}
          </div>
        </div>
      </div>


      <div className="row" style={{ alignItems: "flex-start", gap: "5em" }}>
        <div className="create-audience">
          <div className="attribute-name">Max Audiences</div>
          <div className="content-box">
            <div className="type-row">
              <div className="text-edit">
                <input
                  id="day"
                  type="number"
                  value={maxAudiences}
                  onChange={onMaxAudiencesChange}
                />
              </div>
            </div>
            <Button className="small" onClick={saveMaxAudiences}>Save</Button>
          </div>
        </div>

        <div className="create-audience">
          <div className="attribute-name">Target Choices</div>

          <div className="switch-container">
            {targetChoices.map((targetChoice, index) => (
              <div key={targetChoice.name}>
                {targetChoice.name}
                <Switch options={["Use", "Not Use"]} value={targetChoice.usageLabel} onChange={(selection) => { onSelectTargetChoice(selection, index) }} />
              </div>
            ))}
          </div>
          <Button className="small" onClick={saveTargetChoices}>Save</Button>
        </div>
      </div>

      <div className="create-audience">
        <div className="attribute-name">Audience Thresholds Settings</div>

        <div className="audiences-threshold-grid">
          {audienceSettings.map((audienceSetting, index) => (
            <div key={audienceSetting.name} className="audience-threshold-box">
              <p className="audience-name">{audienceSetting.name} - {audienceSetting.type}</p>
              <div className="row">
                <div>
                  <label>Lower Threshold</label>
                  <div className="text-edit">
                    <input
                      id="thresholds.lower"
                      type="number"
                      value={audienceSetting.thresholds.lower}
                      onChange={(e) => { onLowerThresholdChange(e, index) }}
                    />
                  </div>
                </div>
                <div>
                  <label>Upper Threshold</label>
                  <div className="text-edit">
                    <input
                      id="thresholds.upper"
                      type="number"
                      value={audienceSetting.thresholds.upper}
                      onChange={(e) => { onUpperThresholdChange(e, index) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {audienceSettings.length > 0 &&
          <div className="action-row">
            <Button className="main-button" onClick={saveAudienceThresholdsSettings}>Save</Button>
          </div>
        }
      </div>

    </div>
    :
    gettingVisitingDataVariables ? <InlineLoadingSpinner message={"Getting visitor data variables..."} />
      : <p>No data</p>

};

export default CardAdminViewUserDataVariables;
