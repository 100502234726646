import { createSlice, current } from "@reduxjs/toolkit";
import helper from "../shared/util/helper";
//import { generalActions } from "./general"
//import store from "./index"
const initialState = {
  fetching: false,
  fetchingReports: false,
  isLoggedIn: false,
  adAccounts: [],
  adAccountSelectionIsEligible: true,
  settings: { minDailyBudget: 40 },
  businesses: [],
  businessSelectionIsEligible: true,
  pixels: [],
  creatingAudiences: false,
  pixelSelectionIsEligible: true,
  connectionPageIsReady: false,
  audiences: {},
  hasAudienceError: true,
  creatingCampaign: false,
  enhCampaigns: [],
  overallReportForEnhCampaigns: {
    adsetLevel: {
      last_14d: {}
    }
  },
  campaignInView: null,
  catalogs: [],
  productSets: [],
  pages: [], // facebook pages
  instagramAccounts: [],
  userPreferences: {
    datePreset: "last_14d"
  },
  reportsFilter: {
    type: "date_preset",
    value: "last_14d"
  },
  activeEnhCampaignIdForReports: "",
  insightsForChart: null,
  loadings: {
    businesses: false,
    adAccounts: false,
    pixels: false
  }
}

const facebookSlice = createSlice({
  name: "facebook",
  initialState: { ...initialState },
  reducers: {
    updateInfo(state, action) {
      state = Object.assign(state, action.payload);
    },
    clearInfo(state, action) {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    loginCompleted(state, action) {
      state.auth = action.payload
      state.isLoggedIn = true
    },
    sessionExpired(state, action) {
      state.auth = {
        ...state.auth,
        expiresIn: 0,
        accessToken: null,
        sessionExpired: true
      }
    },
    logOut(state, action) {
      state.auth = {
        expiresIn: 0,
        loggedOut: true,
        accessToken: null
      }
      state.isLoggedIn = false;
    },
    adAccountSelected(state, action) {
      state.selectedAdAccount = action.payload;
      state.adAccountSelectionIsEligible = action.payload ? true : false;
      state.selectedPixel = undefined
      state.pixelSelectionIsEligible = false
    },
    adAccountSelectionIsEligible(state, action) {
      state.adAccountSelectionIsEligible = action.payload;
    },
    businessSelected(state, action) {
      state.selectedBusiness = action.payload;
      state.businessSelectionIsEligible = action.payload ? true : false;
      state.selectedAdAccount = undefined;
      state.adAccountSelectionIsEligible = false;
      state.selectedPixel = undefined;
      state.pixelSelectionIsEligible = false;
    },
    pixelSelected(state, action) {
      state.selectedPixel = action.payload?.pixel
      state.pixelSelectionIsEligible = action.payload?.pixel ? true : false
      if (action.payload?.pixel && action.payload.audiences) {
        state.audiences = Object.assign(state.audiences, action.payload.audiences);
        state.creatingAudiences = false;
      }
    },
    creatingAudiences(state, action) {
      state.creatingAudiences = action.payload;
    },
    newCampaignAdded(state, action) {
      let newCampaigns = [...state.enhCampaigns]
      newCampaigns.push(action.payload)
      state.enhCampaigns = newCampaigns
    },
    audiencesAdded(state, action) {
      state.audiences = Object.assign(state.audiences, action.payload);
    },
    campaignDeleted(state, action) {
      let newCampaigns = [...current(state).enhCampaigns]
      for (var i = 0; i < newCampaigns.length; i++) {
        if (newCampaigns[i]._id === action.payload) {
          newCampaigns.splice(i, 1)
          break;
        }
      }
      state.enhCampaigns = newCampaigns
    },
    campaignUpdated(state, action) {
      let newCampaigns = [...current(state).enhCampaigns]
      let i = helper.findIndex(newCampaigns, "_id", action.payload.id)

      let newCampaign = {
        ...newCampaigns[i],
        ...action.payload.campaign
      }
      newCampaigns[i] = newCampaign
      state.enhCampaigns = newCampaigns
      if (action.payload.isInView) {
        state.campaignInView = newCampaign
      }
    },
    fetching(state, action) {
      state.fetching = action.payload
    },
    fetchingReports(state, action) {
      state.fetchingReports = action.payload
    },
    creatingCampaign(state, action) {
      state.creatingCampaign = action.payload
    },
    connectionPageIsReady(state, action) {
      state.connectionPageIsReady = action.payload
    },
    setHasAudienceError(state, action) {
      state.hasAudienceError = action.payload
    },
    setCampaignInView(state, action) {
      if (!action.payload) {
        state.campaignInView = null
        return
      }

      const campaign = state.enhCampaigns.find(c => c._id === action.payload)
      state.campaignInView = campaign
    },
    setReportsFilter(state, action) {
      state.reportsFilter = action.payload
    },
    setActiveEnhCampaignIdForReports(state, action) {
      state.activeEnhCampaignIdForReports = action.payload
    },
    updateOverallReportForEnhCampaigns(state, action) {
      state.overallReportForEnhCampaigns = {
        ...state.overallReportForEnhCampaigns,
        ...action.payload,
      }
    },
    setInsightsForChart(state, action) {
      state.insightsForChart = {
        ...state.insightsForChart,
        ...action.payload
      }
    },
    startLoading(state, action) {
      state.loadings[action.payload.field] = true
    },
    stopLoading(state, action) {
      state.loadings[action.payload.field] = false
    }
  },
});

export const facebookActions = facebookSlice.actions;

export default facebookSlice.reducer;
