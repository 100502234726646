import React, { useEffect, useState } from 'react'

import './AdminPanelSignUpsSummarySection.css'
import BarChart from './BarChart'
import { useSelector } from 'react-redux'
import { FaMapPin } from 'react-icons/fa'
import numberFormatter from '../../shared/util/numberFormatter'
import Tooltip from '../../shared/components/UIElements/Tooltip'
import helper from '../../shared/util/helper'


const AdminPanelSignUpsSummarySection = props => {
  const { panelSignUpSummary } = useSelector(state => state.admin)

  const [isLoading, setIsLoading] = useState(true)
  const [summary, setSummary] = useState({})
  const [isPinned, setPinned] = useState(false);

  const handlePinToggle = () => {
    setPinned(!isPinned);
  };

  useEffect(() => {
    if (panelSignUpSummary) {
      setIsLoading(false)
      setSummary({
        "noTouchRate": {
          "value": panelSignUpSummary.noTouchCount / (panelSignUpSummary.touchCount + panelSignUpSummary.noTouchCount),
          "title": "% No Touch",
          "tooltip": "(No Touch / (Touch + No Touch))",
          "totalValue": panelSignUpSummary.sumCalls,
          "numerator": panelSignUpSummary.touchCount,
          "denominator": (panelSignUpSummary.touchCount + panelSignUpSummary.noTouchCount),
        },
        "meetingDoneRate": {
          "value": panelSignUpSummary.meetingDoneCount / (panelSignUpSummary.meetingNotDoneCount + panelSignUpSummary.meetingDoneCount),
          "title": "% Meeting done",
          "tooltip": "(Meeting done / All)",
          "totalValue": panelSignUpSummary.meetingDoneCount,
          "numerator": panelSignUpSummary.meetingDoneCount,
          "denominator": (panelSignUpSummary.meetingDoneCount + panelSignUpSummary.meetingNotDoneCount),
        },
        "reach.avgCalls": {
          "value": (panelSignUpSummary.sumCalls / panelSignUpSummary.count),
          "title": "Calls",
          "tooltip": "Total calls / Total customers",
          "totalValue": panelSignUpSummary.sumCalls,
        },
        "reach.avgTalks": {
          "value": (panelSignUpSummary.sumTalks / panelSignUpSummary.count),
          "title": "Talks",
          "tooltip": "Total talks / Total customers",
          "totalValue": panelSignUpSummary.sumTalks,
        },
        "reach.avgMails": {
          "value": (panelSignUpSummary.sumEmails / panelSignUpSummary.count),
          "title": "Mails",
          "tooltip": "Total mails / Total customers",
          "totalValue": panelSignUpSummary.sumEmails,
        },
        "reach.avgSMSes": {
          "value": (panelSignUpSummary.sumSMSes / panelSignUpSummary.count),
          "title": "SMS",
          "tooltip": "Total smses / Total customers",
          "totalValue": panelSignUpSummary.sumSMSes,
        },
      });
    } else {
      setIsLoading(true)
    }
  }, [panelSignUpSummary])

  const groupDetails = {};
  // Grouping details based on keys
  Object.keys(summary).forEach((key) => {
    const groupKey = key.split('.')[0]; // Extracting the common prefix
    if (!groupDetails[groupKey]) {
      groupDetails[groupKey] = [];
    }
    groupDetails[groupKey].push(summary[key]);
  })
  if (isLoading) {
    return (
      <div></div>
    )
  }

  return (
    <div className={`overlay-panel ${isPinned ? 'pinned' : ''}`}
    >
      <div className='summary-title'>Summary</div>
      <div className={`pin-toggle ${isPinned ? 'pinned' : ''}`} onClick={handlePinToggle} >
        <FaMapPin />
      </div>
      <div className='summary-details'>
        <div className='summary-details-column'>
          <div className='summary-group-container'>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgCalls'].title}</div>
              <div className='data'>{summary['reach.avgCalls'].value?.toFixed(2) + " (" + summary['reach.avgCalls'].totalValue + ")"}</div>
            </div>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgTalks'].title}</div>
              <div className='data'>{summary['reach.avgTalks'].value?.toFixed(2) + " (" + summary['reach.avgTalks'].totalValue + ")"}</div>
            </div>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgMails'].title}</div>
              <div className='data'>{summary['reach.avgMails'].value?.toFixed(2) + " (" + summary['reach.avgMails'].totalValue + ")"}</div>
            </div>
            <div className='summary-group-data'>
              <div className='title'>{summary['reach.avgSMSes'].title}</div>
              <div className='data'>{summary['reach.avgSMSes'].value?.toFixed(2) + " (" + summary['reach.avgSMSes'].totalValue + ")"}</div>
            </div>
          </div>
        </div>
        <div className='summary-details-column'>
          <div className='summary-group-container'>
            <div className='summary-group-data'>
              <Tooltip
                key={summary['noTouchRate'].title}
                content={summary['noTouchRate'].tooltip ?? ""}
                isHidden={!summary['noTouchRate'].tooltip}
                placement="right">
                <div key={summary['noTouchRate'].title} className='summary-details-row'>
                  <div className='data'>
                    <div className='value'>
                      {(summary['noTouchRate'].value * 100)?.toFixed(1)}%
                    </div>
                    <div className='helper-text'>({numberFormatter.formatNumber(summary['noTouchRate'].totalValue)})</div>
                  </div>
                  <div className='description'>{summary['noTouchRate'].title}</div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className='summary-group-container'>
            <div className='summary-group-data'>
              <Tooltip
                key={summary['meetingDoneRate'].title}
                content={summary['meetingDoneRate'].tooltip ?? ""}
                isHidden={!summary['meetingDoneRate'].tooltip}
                placement="right">
                <div key={summary['meetingDoneRate'].title} className='summary-details-row'>
                  <div className='data'>
                    <div className='value'>
                      {(summary['meetingDoneRate'].value * 100)?.toFixed(1)}%
                    </div>
                    <div className='helper-text'>({numberFormatter.formatNumber(summary['meetingDoneRate'].totalValue)})</div>
                  </div>
                  <div className='description'>{summary['meetingDoneRate'].title}</div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}

export default AdminPanelSignUpsSummarySection

/* 

*/