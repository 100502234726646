import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import './Sidebar.css'

function Sidebar({ children, isOpen }) {
  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>{children}</div>
  )
}

function SidebarHeader({ className, children }) {
  const classes = ['sidebar-header', className].filter(Boolean).join(' ')

  return <span className={classes}>{children}</span>
}

function NavItem({ to, className, children }) {
  const { pathname } = useLocation()
  const isActive = useMemo(() => pathname.startsWith(to), [pathname])

  const classes = ['nav-item', isActive && 'active', className]
    .filter(Boolean)
    .join(' ')

  return (
    <Link to={to}>
      <div className={classes}>{children}</div>
    </Link>
  )
}

function NavItems({ items, className }) {
  const classes = ['nav-items', className].filter(Boolean).join(' ')

  return (
    <div className={classes}>
      {items.map(item => (
        <NavItem key={item.to} to={item.to}>
          {item.icon}
          {item.title}
        </NavItem>
      ))}
    </div>
  )
}

export { Sidebar, NavItems, SidebarHeader }
