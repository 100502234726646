import React from 'react'
import { FaTimes } from 'react-icons/fa'

import imageExpander from '../../util/imageExpander'

const ImageOverlay = () => {
  return (
    <div
      id="imageOverlay"
      onClick={() => {
        imageExpander.closeImage()
      }}
    >
      <button className="close-button">
        <FaTimes className="icon" />
      </button>
    </div>
  )
}

export default ImageOverlay
