import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { useModal } from '../../hooks/modal-hook'
import './StripeUpdateCreditCard.css'
import { useSelector } from 'react-redux'
import StripeUpdateCard from '../../../user/components/StripeUpdateCreditCard'
import { loadStripe } from '@stripe/stripe-js'
import stripeLogo from '../../assets/images/icons/stripe-logo.png'

const StripeUpdateCreditCard = props => {
  const { closeModal } = useModal()
  const user = useSelector(state => state.auth.user)
  const customerRefCode = user.subscription.customerRefCode
  const refCode = user.subscription.refCode

  const stripePromise = process.env.NODE_ENV == "development" ? loadStripe('pk_test_51NlB3NDVZxXin8U3vmGV3yFVGrwfnF8In9i8mYYCFJsBl0lym3FqnEBl7mSHrLp26m4ffXfgADF41RAgllBNAnzl00kQCBMGF6') :
  loadStripe('pk_live_51NlB3NDVZxXin8U39PunZZIn9KMZtjxUm5RwfC4ft3pQ5mgX6Yxv1jdZ2ckbtbI7cH6oaAqv90GM19nCuAqfkY96007qfemTSU') 


  const close = () => {
    closeModal()
  }

  return (
    <div className="stripe-card-modal">
      <div className="header-row">
        <img src={stripeLogo} alt="stripe-logo" />
        <p>Update Card</p>
      </div>
      <Elements stripe={stripePromise}>
        <StripeUpdateCard customerRefCode={customerRefCode} refCode={refCode} close={close} />
      </Elements>
    </div>
  )
}

export default StripeUpdateCreditCard
