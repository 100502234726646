import React, { useEffect, useState } from 'react'
import {
  FaPlug,
} from 'react-icons/fa'
import { Link } from 'react-router-dom'

import './NotificationBadge.css'

const NotificationBadge = props => {
  const [innerElem, setInnerElem] = useState()
  useEffect(() => {

  }, [])



  return <div className='notification-badge'>
    {
      props.children ||
      <span>1</span>
    }
  </div>
}

export default NotificationBadge
