import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaComment } from 'react-icons/fa'
import moment from 'moment'

import { useSelector } from 'react-redux'

import './TicketCard.css'

const TicketCard = ({ ticket }) => {
  const navigate = useNavigate()
  const auth = useSelector(state => state.auth)

  const unreadMessages = ticket.messages.filter(
    message => !message.read && message.receiver === auth.user._id,
  )

  const cardTitle =
    ticket.bugType === 'Other' ? ticket.otherBugTypeDescription : ticket.bugType

  return (
    <div
      onClick={() => navigate(`/account/tickets/${ticket._id}`)}
      className="ticket-card"
    >
      <div className="ticket-card-header">
        <div className="ticket-card-header-left">
          <p title={cardTitle} className="ticket-card-title">
            {cardTitle}
          </p>
        </div>
        <div className="ticket-card-header-right">
          <div className="ticket-card-date">
            {moment(ticket.createdAt).fromNow()}
          </div>
        </div>
      </div>
      {ticket.description && (
        <div className="ticket-card-body">
          <p className="ticket-card-description">{ticket.description}</p>
        </div>
      )}
      <div className="ticket-card-footer">
        <div
          className={`ticket-card-status ${ticket.displayStatus || 'pending'}`}
        >
          {ticket.displayStatus
            ? ticket.displayStatus[0].toUpperCase() +
              ticket.displayStatus.slice(1)
            : 'Pending'}
        </div>
        <div className="ticket-card-messages">
          <div className="icon-container">
            <FaComment className="icon" />
            {unreadMessages.length > 0 && <span className="unread" />}
          </div>
          <span className="count">{ticket.messages.length}</span>
        </div>
      </div>
    </div>
  )
}

export default TicketCard
