const CRMReportFilterTypes = Object.freeze({
  STRING: 'STRING',
  REGISTRATION_SOURCE: 'REGISTRATION_SOURCE',
  NUMERIC: 'NUMERIC',
  UTM_SOURCE: 'UTM_SOURCE',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  FUNNEL: 'FUNNEL',
  OBJECT_ID: 'OBJECT_ID',
})

export default CRMReportFilterTypes
