import React from 'react'
import { FaArrowRight, FaInfoCircle } from 'react-icons/fa'

import cookies from '../../util/cookies'
import Button from '../FormElements/Button'

import 'react-toastify/dist/ReactToastify.css'
import { useModal } from '../../hooks/modal-hook'

const ScheduleADemoPopUp = () => {
  const { closeModal } = useModal()

  const yes = () => {
    closeModal()
    cookies.set('demo_popup_sh', 'true', 100)
    window.open('https://enhencer.com/schedule-a-demo', '_blank')
  }

  const no = () => {
    closeModal()
  }

  return (
    <div className="are-you-sure payment-required">
      <div className="info-icon">
        <FaInfoCircle />
      </div>

      <div className="question" style={{ textAlign: 'center' }}>
        Do you need professional assistance with your audiences? <br /> <br />{' '}
        Schedule a demo with a performance marketing expert right now.
      </div>
      <div className="footer">
        <div className="action-bar" style={{ display: 'contents' }}>
          <Button className="action-button no" onClick={no}>
            Not now
          </Button>
          <Button
            className="action-button yes"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5em',
            }}
            onClick={yes}
          >
            Schedule a Demo
            <FaArrowRight />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ScheduleADemoPopUp
