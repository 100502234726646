export default {
  background: function (selected, n, length) {
    if (n < length / 10) {
      return selected ? "segment-color-one selected" : "segment-text-color-one";
    } else if (n < (2 * length) / 10) {
      return selected ? "segment-color-two selected" : "segment-text-color-two";
    } else if (n < (3 * length) / 10) {
      return selected
        ? "segment-color-three selected"
        : "segment-text-color-three";
    } else if (n < (4 * length) / 10) {
      return selected
        ? "segment-color-four selected"
        : "segment-text-color-four";
    } else if (n < (5 * length) / 10) {
      return selected
        ? "segment-color-five selected"
        : "segment-text-color-five";
    } else if (n < (6 * length) / 10) {
      return selected ? "segment-color-six selected" : "segment-text-color-six";
    } else if (n < (7 * length) / 10) {
      return selected
        ? "segment-color-seven selected"
        : "segment-text-color-seven";
    } else if (n < (8 * length) / 10) {
      return selected
        ? "segment-color-eight selected"
        : "segment-text-color-eight";
    } else if (n < (9 * length) / 10) {
      return selected
        ? "segment-color-nine selected"
        : "segment-text-color-nine";
    } else {
      return selected ? "segment-color-ten selected" : "segment-text-color-ten";
    }
  },

  text: function (n, length) {
    if (n < length / 10) {
      return "segment-text-color-one";
    } else if (n < (2 * length) / 10) {
      return "segment-text-color-two";
    } else if (n < (3 * length) / 10) {
      return "segment-text-color-three";
    } else if (n < (4 * length) / 10) {
      return "segment-text-color-four";
    } else if (n < (5 * length) / 10) {
      return "segment-text-color-five";
    } else if (n < (6 * length) / 10) {
      return "segment-text-color-six";
    } else if (n < (7 * length) / 10) {
      return "segment-text-color-seven";
    } else if (n < (8 * length) / 10) {
      return "segment-text-color-eight";
    } else if (n < (9 * length) / 10) {
      return "segment-text-color-nine";
    } else {
      return "segment-text-color-ten";
    }
  },

  effect: function (n) {
    switch (n) {
      case 0:
        return "#C54938"
      case 1:
        return "#CF623A"
      case 2:
        return "#DC9336"
      case 3:
        return "#F1BF42"
      case 4:
        return "#DCCF59"
      case 5:
        return "#D8DB5E"
      case 6:
        return "#CADC59"
      case 7:
        return "#81CC47"
    }
  }
};
