import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import helper from '../../shared/util/helper'
import InlineLoadingSpinner from '../../shared/components/UIElements/InlineLoadingSpinner'
import Button from '../../shared/components/FormElements/Button'
import { useAdmin } from '../../shared/hooks/admin-hook'
import { adminActions, resettedAlarmCalendarFilters } from '../../store/admin'
import './AlarmsCalendar.css'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import AlarmCard from './AlarmCard'
import FiltersSection from './FiltersSection'

const AlarmsCalendar = props => {
  const dispatch = useDispatch()
  const [visibleCount, setVisibleCount] = useState(3)
  const handleResize = () => {
    if (window.innerWidth < 675) {
      setVisibleCount(1)
    } else if (window.innerWidth < 971) {
      setVisibleCount(2)
    } else {
      setVisibleCount(3)
    }
  }
  const { allAlarms, alarmCalendarFilters } = useSelector(state => state.admin)
  const admin = useAdmin()

  const [groupedAlarmsByDate, setGroupedMeetings] = useState([])
  const [middleDate, setMiddleDate] = useState(new Date())
  const [isFetching, setIsFetching] = useState(props.isFetching)
  const [isFilterChanged, setIsFilterChanged] = useState(false)
  const [tempFilter, setTempFilter] = useState(alarmCalendarFilters)
  const { managerUsers } = useSelector(state => state.admin)

  useEffect(() => {
    const savedFilters = sessionStorage.getItem('alarmCalendarFilters')

    if (savedFilters) {
      const initialFilter = JSON.parse(savedFilters)
      setTempFilter(initialFilter)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize() // Initialize the correct count on mount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    admin.getOverdueAlarmCounts({ filters: tempFilter })
  }, [allAlarms])

  useEffect(() => {
    admin.getAllAlarmsBetweenDates({ middleDate, filters: tempFilter })
  }, [middleDate])

  useEffect(() => {
    setGroupedMeetings(groupAlarmsByDate(allAlarms ?? []))
  }, [allAlarms])

  useEffect(() => {
    setIsFetching(props.isFetching)
  }, [props.isFetching])

  useEffect(() => {
    setTempFilter(alarmCalendarFilters)
  }, [alarmCalendarFilters])

  const handleFilterChange = ({ groupName, type, value, checked }) => {
    switch (type) {
      case 'range':
        const [group, input] = groupName.split('.')
        setTempFilter(prevFilter => {
          const prevGroup = prevFilter[group]

          return {
            ...prevFilter,
            [group]: {
              ...prevGroup,
              [input]: Number(value),
            },
          }
        })
        break
      case 'checkbox':
        if (groupName === 'sortBy' || groupName === 'isCompleted') {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [value] : [],
            }
          })
        } if (groupName === 'performanceManager' || groupName === 'accountManager') {
          let objId;
          if (value !== '<Not set>') {
            objId = helper.findBy(Object.values(managerUsers), 'name', value)._id
          } else {
            objId = value
          }

          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked ? [...prevGroup, objId] : prevGroup.filter(item => item !== objId),
            }
          })
        } else {
          setTempFilter(prevFilter => {
            const prevGroup = prevFilter[groupName]
            return {
              ...prevFilter,
              [groupName]: checked
                ? [...prevGroup, value]
                : prevGroup.filter(item => item !== value),
            }
          })
        }
        break
      case 'date-range':
        setTempFilter(prevFilter => {
          const { user, ...filter } = prevFilter
          return {
            ...filter,
            [groupName]: value,
          }
        })
        break
      case 'hour-range':
        setTempFilter(prevFilter => {
          return {
            ...prevFilter,
            [groupName]: value,
          }
        })
        break
      default:
        break
    }

    setIsFilterChanged(true)
  }

  const handleApplyFilter = () => {
    const filter = { ...tempFilter }

    dispatch(adminActions.setSignUpFilters(filter))
    dispatch(adminActions.setSignUps([]))
    sessionStorage.setItem('alarmCalendarFilters', JSON.stringify(tempFilter))
    admin.getAllAlarmsBetweenDates({ middleDate, filters: filter })
    setIsFilterChanged(false)
  }

  const resetFilters = () => {
    const filter = { ...resettedAlarmCalendarFilters }

    setTempFilter(filter)
    dispatch(adminActions.resetAlarmCalendarFilters())
    sessionStorage.setItem(
      'alarmCalendarFilters',
      JSON.stringify(resettedAlarmCalendarFilters),
    )
    admin.getAllAlarmsBetweenDates({ middleDate, filters: filter })
    setIsFilterChanged(false)
  }

  const groupAlarmsByDate = alarms => {
    // Create a map to hold alarms grouped by date
    const alarmMap = alarms.reduce((acc, alarm) => {
      const date = new Date(alarm.alarm.dueDate).toISOString().split('T')[0]
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(alarm)
      return acc
    }, {})

    // Initialize currentDate to the startDate
    let startDate = new Date(middleDate)
    startDate.setDate(startDate.getDate() - 1)
    let endDate = new Date(middleDate)
    endDate.setDate(endDate.getDate() + 1)

    // Add empty arrays for dates without alarms
    while (startDate <= new Date(endDate)) {
      const dateStr = startDate.toISOString().split('T')[0]
      if (!alarmMap[dateStr]) {
        alarmMap[dateStr] = []
      }
      startDate.setDate(startDate.getDate() + 1) // Move to the next day
    }
    return alarmMap
  }

  const formatDate = dateString => {
    const date = new Date(dateString)
    const day = date.getDate()

    const month = date.toLocaleString('en', { month: 'long' })
    const dayName = date.toLocaleString('en', { weekday: 'long' })

    return `${day} ${month} ${dayName}`
  }

  // Function to sort meetings by time
  const sortAlarmsByTime = alarms => {
    return [...alarms].sort((a, b) =>
      a.alarm.dueDate > b.alarm.dueDate ? 1 : -1,
    )
  }

  const refreshAlarms = () => {
    admin.getAllAlarmsBetweenDates({ middleDate, filters: tempFilter })
  }

  function isToday(dateComing) {
    const date = new Date(dateComing)
    const today = new Date()

    const newLocal =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    return newLocal
  }

  return (
    <div className="alarms-calendar-container">
      <div className="layout">
        <div className="content">
          <div className="alarms-calendar-wrapper">
            <div className="date-changer">
              <Button
                className="save-button"
                onClick={() => {
                  const previousDay = new Date(middleDate)
                  previousDay.setDate(previousDay.getDate() - 1)
                  setMiddleDate(previousDay)
                }}
              >
                <FaChevronLeft />
              </Button>
              {Object.keys(groupedAlarmsByDate)
                .sort()
                .slice(0, visibleCount)
                .map(date => (
                  <div
                    key={date}
                    style={{
                      flex: '1',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ color: isToday(date) ? 'green' : '' }}>
                      {formatDate(date)}
                    </div>
                  </div>
                ))}
              <Button
                className="save-button"
                onClick={() => {
                  const nextDay = new Date(middleDate)
                  nextDay.setDate(nextDay.getDate() + 1)
                  setMiddleDate(nextDay)
                }}
              >
                <FaChevronRight />
              </Button>
            </div>
            <div className="alarms-calendar">
              {Object.keys(groupedAlarmsByDate)
                .sort()
                .slice(0, visibleCount)
                .map(date => (
                  <div className="alarms-date-column" key={date}>
                    {groupedAlarmsByDate[date].length === 0 ? (
                      <div></div>
                    ) : (
                      sortAlarmsByTime(groupedAlarmsByDate[date]).map(alarm => (
                        <AlarmCard
                          alarm={alarm}
                          key={alarm.alarm._id}
                          refreshAlarms={refreshAlarms}
                        ></AlarmCard>
                      ))
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <FiltersSection
          page='alarmCalendar'
          isFilterChanged={isFilterChanged}
          handleApplyFilter={handleApplyFilter}
          handleFilterChange={handleFilterChange}
          resetFilters={resetFilters}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />
      </div>
    </div>
  )
}
export default AlarmsCalendar
