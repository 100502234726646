import React from 'react';
import SegmentItem from './SegmentItem';
import colorizer from "../../shared/util/colorizer";
import './SegmentList.css';

const SegmentList = props => {

  return (
    <div className="segment-list">
      {props.segments.map((segment, index) => (
        <SegmentItem
          key={index}
          index={index}
          colorClassname={colorizer.background(segment.isSelected, index, props.segments.length)}
          segment={segment}
          detailOpen={props.segmentDetailIndex === index}
          segmentNo={ index + 1 }
          openSegmentDetail={props.openSegmentDetail}
          uplift={segment.propensity / props.overallPropensity}
          numberOfSegments={props.segments.length}
          onToggleSegment={props.onToggleSegment}
        />
      ))}
    </div>
  );
};

export default SegmentList;
