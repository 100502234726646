import React from 'react'

import helper from '../../shared/util/helper'
import FluidCard from '../../shared/components/UIElements/FluidCard'
import { useAdmin } from '../../shared/hooks/admin-hook'

import './Campaign.css'

const Campaign = ({ campaign }) => {
  const { navigateWithAdminParams } = useAdmin()

  const status = campaign?.reports?.campaignLevel?.effectiveStatus

  return (
    <FluidCard
      onClick={() =>
        navigateWithAdminParams(
          `/ai-ads/full-funnel-campaigns?id=${campaign._id}`,
        )
      }
      className="campaign"
    >
      <FluidCard.Header>
        <div className="header-wrapper">
          <div className="row">
            <span className="label">{campaign.label}</span>
            <div className='row'>
              {
                status !== 'ACTIVE' &&
                <div style={{fontSize: ".8em"}}>{status}</div>
              }
              <div
                title={`${status?.[0] + status?.slice(1).toLowerCase()}`}
                className={`status ${status === 'ACTIVE' ? 'active' : ''}`}>
              </div>
            </div>
          </div>
          {/* <span className="primary-text">{campaign.primaryText}</span>
          <span className="website-url">{campaign.websiteUrl}</span> */}
        </div>
      </FluidCard.Header>
      <FluidCard.Content>
        <div className="content-wrapper">
          <span className="countries">
            <span className="bold">Countries: </span>
            {campaign.countries.toString().replaceAll(',', ', ')}
          </span>
          <span className="daily-budget">
            <span className="bold">Daily Budget: </span>
            {campaign.dailyBudget}
          </span>
          <span className="gender">
            <span className="bold">Gender: </span>

            {helper.getGenderString(campaign.genders)}
          </span>
          <span className="age">
            <span className="bold">Age: </span>
            {campaign.age.min} - {campaign.age.max}
          </span>
        </div>
      </FluidCard.Content>
    </FluidCard>
  )
}

export default Campaign