import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import {
  FaShopify,
  FaSave,
  FaCopy,
  FaPenSquare,
  FaRegClock,
  FaHistory,
  FaHandPointUp,
  FaHandsHelping,
  FaGoogle,
} from 'react-icons/fa'

import audienceNetworkIcon from '../../shared/assets/images/icons/audience-network.svg'
import AdminPanelCustomerCardMenu from './AdminPanelCustomerCardMenu'
import AdminPanelCustomerCardReportSummaryPart from './AdminPanelCustomerCardReportSummaryPart'
import { adminActions } from '../../store/admin'
import { useHttpClient } from '../../shared/hooks/http-hook'
import { useConstants } from '../../shared/hooks/constants-hook'
import { useAdmin } from '../../shared/hooks/admin-hook'
import helper from '../../shared/util/helper'
import Checkbox from '../../shared/components/FormElements/Checkbox'
import StarRating from './StarRating'
import AdminPanelCustomerCardAlarmPart from './AdminPanelCustomerCardAlarmPart'

import './AdminPanelCustomerCard.css'
import NotesPopup from './NotesPopup'
import { Tooltip } from 'chart.js'
import PrivateComponent from '../../shared/components/UIElements/PrivateComponent'
import QuickDropdown from './QuickDropdown'
import errorHandler from '../../shared/util/errorHandler'
import CustomerCardInput from './CustomerCardInput'
import SegmentationScoreIcon from './SegmentationScoreIcon'


const AdminPanelCustomerCard = props => {
  const {
    user,
    dayDifference,
    connectionsCompletedInDays,
    fullFunnelCampaignStartedInDays,
    viewedDetailedFacebookReportInDays,
    weeklyReports,
    currency,
    daysPassedSinceLastAdminReportCheck,
    daysPassedSinceFullFunnelCampaignStarted,
    shownInOutsideOfList
  } = props

  const userId = user.id

  const [daysPassedSinceLastComment, setDaysPassedSinceLastComment] = useState(props.daysPassedSinceLastComment)

  const { accountManagers, performanceManagers } = useSelector(state => state.admin)
  const token = useSelector(state => state.auth.token)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { startViewingAsUser, convertManagerIdToName } = useAdmin()
  const { sendRequest } = useHttpClient()
  const { privateConstants: constants, publicConstants } = useConstants()
  const fullPhoneNumber = user.crmDetails?.contactNumber ? ((user.crmDetails?.contactNumberCountryCode || "") + user.crmDetails?.contactNumber) : ""
  const country = user.crmDetails?.country === "United States" ? "USA" : user.crmDetails?.country === "United Kingdom" ? "UK" : user.crmDetails?.country
  let preferredCallTimeRanges = user.crmDetails?.preferredCallTimeRanges
  let availableCallHours = user.crmDetails?.availableCallHours
  const [preferredCallTimeRange, setPreferredCallTimeRange] = useState()
  const rippleAnimateRef = useRef()
  const usageChargeElementRef = useRef()
  const cardRef = useRef()

  const usageCharges = (() => {
    let total = 0
    if (user.subscription?.usageCharges?.length) {
      let recentCharges = user.subscription.usageCharges.filter(charge => (new Date(charge.createdAt)).valueOf() > user.subscription.lastPaymentDate)
      recentCharges.forEach((c) => {
        total += parseFloat(c.price)
      })
    }
    return total
  })()

  function convertToTimeRanges(availableCallHoursRaw, timezoneOffset) {
    let availableHours = Array.from(availableCallHoursRaw)

    if (availableHours.length === 0) {
      return "Not specified";
    }

    availableHours = availableHours.map(hour => {
      let newHour = hour + timezoneOffset
      if (newHour >= 24) {
        newHour = newHour - 24
      } else if (newHour < 0) {
        newHour = newHour + 24
      }
      return newHour
    });

    let timeRanges = [];
    let startHour = availableHours[0];
    let endHour = availableHours[0];

    // Loop through the sorted available hours to find continuous ranges
    for (let i = 1; i < availableHours.length; i++) {
      if (availableHours[i] === ((endHour + 1) % 24)) {
        endHour = availableHours[i];
      } else {
        timeRanges.push(`${startHour < 10 ? '0' : ''}${startHour}:00-${endHour + 1 < 10 ? '0' : ''}${endHour + 1}:00`);
        startHour = availableHours[i];
        endHour = availableHours[i];
      }
    }

    // Push the last range
    timeRanges.push(`${startHour < 10 ? '0' : ''}${startHour}:00-${endHour + 1 < 10 ? '0' : ''}${endHour + 1}:00`);

    return timeRanges.join(", ");
  }
  const handleUsageChargeCardEmphesize = () => {
    let isAnimating = false;
    let interval = setInterval(() => {
      if (cardRef.current && helper.isElementInViewport(cardRef.current) && !isAnimating) {
        isAnimating = true
        setTimeout(() => {
          rippleAnimateRef.current?.classList.add("ripple-animate")
          usageChargeElementRef.current?.classList.add("blink-animate")
          clearInterval(interval)
        }, 1000);
        setTimeout(() => {
          rippleAnimateRef.current?.classList.remove("ripple-animate")
          isAnimating = false
        }, 3000);
      }
    }, 1000);
  }

  useEffect(() => {
    let rangesString = ""
    let displayedRanges = []
    if (preferredCallTimeRanges && preferredCallTimeRanges.length) {
      let ranges = []
      preferredCallTimeRanges.forEach(range => {
        ranges.push(range)
        displayedRanges.push(publicConstants.callTimeRanges[range])
      })
      if (ranges.includes("dontCall")) {
        rangesString = "Don't call"
      } else if (ranges.includes("morning") && ranges.includes("afternoon") && ranges.includes("evening")) {
        rangesString = "09:00-22:00"
      } else if (ranges.includes("morning") && ranges.includes("afternoon")) {
        rangesString = "09:00-18:00"
      } else if (ranges.includes("afternoon") && ranges.includes("evening")) {
        rangesString = "12:00-22:00"
      } else if (ranges.includes("notSelected")) {
        rangesString = "Not specified"
      } else {
        rangesString = displayedRanges.join(", ")
      }

      setPreferredCallTimeRange(rangesString)
    } else if (availableCallHours) {
      //Turkey's timezone: gmt+3
      let timezoneOffset = 3
      setPreferredCallTimeRange(convertToTimeRanges(availableCallHours, timezoneOffset))
    } else {
      rangesString = "Not specified"
      setPreferredCallTimeRange(rangesString)
    }

    if (usageCharges) {
      handleUsageChargeCardEmphesize()
    }

  }, [])


  const [fields, setFields] = useState({
    comment: { value: user.crmDetails?.comment?.value ?? 0 },
    selfLed: { value: user.crmDetails?.selfLed?.value ?? false },
  })

  const save = async (field, payload) => {
    const query = { cardType: 'customer' }

    switch (field) {
      case 'subscription.status':
        if (user.displayStatus === "Sign Up" && payload.includes("Recurring")) {
          query['leadDetails.funnel'] = constants.leadFunnelSteps.map(item => { return { ...item, completed: true } })
          query['leadDetails.isWon'] = true
          query['crmDetails.contractStartDate'] = new Date()
        }
        if (payload.includes("Churn")) {
          query['crmDetails.contractEndDate'] = new Date()
        }
        query['crmDetails.subscription.status'] = payload
        break;
      case 'performanceStatusType':
        query['crmDetails.performanceStatusType'] = payload
        query['crmDetails.performanceStatusChangeDate'] = new Date()

      case 'calls':
      case 'talks':
      case 'emails':
      case 'smses':
      case 'supports':
      case 'comment':
      case 'selfLed':
      case 'accountManager':
      case 'performanceManager':
      case 'sourceType':
      case 'didAccMngrSell':
      case 'churnReason':
        query[`crmDetails.${field}`] = payload
        break;
    }

    try {
      let result = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/updateUser/${user.id}`,
        'PATCH',
        JSON.stringify(query),
        {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      )

      let newUserData = {
        ...user,
        crmAlarms: result.user.crmAlarms,
        crmDetails: {
          ...user.crmDetails,
          [field]:
            typeof payload !== 'object'
              ? payload
              : {
                ...user.crmDetails[field],
                ...payload,
              },
        },
      }
      if (field === 'subscription') {
        newUserData.displayStatus = payload.status
      }

      dispatch(
        adminActions.findCustomerByIdAndUpdate({
          id: user.id,
          data: newUserData,
        }),
      )

      toast.success('Changes saved!')
    } catch (err) {
      errorHandler(err)
    }
  }

  const saveDidAccMngrSell = async (didAccMngrSell) => {
    await save('didAccMngrSell', didAccMngrSell)
  }

  const saveComment = async () => {
    await save('comment', { value: fields.comment.value, lastModified: Date.now() })
    setDaysPassedSinceLastComment(0)
  }

  const goToDetails = () => {
    dispatch(adminActions.setNavigatedFromCustomerID(userId))
    dispatch(adminActions.setCustomer(user))
    if (shownInOutsideOfList) {
      window.open(`/admin-panel/user-detail/${user.id}`)
    } else {
      navigate(`/admin-panel/user-detail/${user.id}`)
    }
  }

  const goToTickets = username => {
    //TODO For shownInOutsideOfList
    dispatch(adminActions.setNavigatedFromCustomerID(userId))
    navigate(`/admin-panel/support-tickets?uid=${user.id}`, {
      state: {
        username,
      },
    })
  }

  const goToReportPage = e => {
    e.stopPropagation()
    startViewingAsUser({ user, pageRoute: "/ai-ads", openInNewTab: shownInOutsideOfList }) // go to report detail or here?
  }

  const username = useMemo(() => {
    let domain = user.shopify
      ? user.shopify.name || user.shopify.domain
      : user.url

    domain = domain?.replace('https://', '').replace('.myshopify.com', '')
    if (domain && domain[domain.length - 1] === '/') {
      domain = domain.substring(0, domain.length - 1)
    }
    return domain ? domain : user.crmDetails?.contactName
  }, [user])

  const getPerformanceClass = () => {
    if (typeof user.crmDetails === 'undefined' || typeof user.crmDetails?.performanceStatusType === 'undefined') {
      return 'undefinedPerf'
    }

    switch (user.crmDetails.performanceStatusType) {
      case 'No Status':
        return 'no-status'
      case 'No Touch':
        return 'no-touch'
      case 'Bad':
        return 'bad'
      case 'No Access':
        return 'no-access'
      case 'Good':
        return 'good'
      case 'Onboarding':
        return 'onboarding'
      default:
        return user.crmDetails?.performanceStatusType
    }
  }

  const copyPhoneNumber = (e) => {
    e.stopPropagation()
    if (fullPhoneNumber) {
      navigator.clipboard.writeText(fullPhoneNumber)
      toast.info('Phone number copied')
    }
  }

  const writeEmail = (e) => {
    e.stopPropagation()
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const year = date.getFullYear();

    const month = date.toLocaleString('en', { month: 'long' });

    return `${day} ${month} ${year}`;
  };

  const calculateDayPassed = (date) => {
    if (!date) {
      return 0;
    }

    const currentDate = new Date();
    const givenDate = new Date(date);

    // Calculate the difference in time
    const timeDifference = currentDate - givenDate;

    // Convert time difference from milliseconds to days
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    return Math.floor(dayDifference); // Round down to the nearest whole number
  };

  const getSupportTicketIcon = () => {
    const { supportTickets } = user

    if (!supportTickets || !supportTickets.length) return null

    let allResolved = false

    for (const ticket of supportTickets) {
      if (ticket.status === 'ignored') {
        continue
      }

      if (ticket.status !== 'completed') {
        allResolved = false
        break
      }

      allResolved = true
    }

    if (!allResolved) {
      return (
        <FaHandPointUp
          className="icon has-ticket-badge"
          onClick={() => goToTickets(username)}
          title="There are unresolved tickets"
        />
      )
    }

    return (
      <FaHandsHelping
        className="icon has-tickets-resolved-badge"
        onClick={() => goToTickets(username)}
        title="All tickets resolved"
      />
    )
  }

  return (
    <div data-id={userId} className={`admin-panel-customer-card ${getPerformanceClass()}`} ref={cardRef}>
      <div className="card-header">
        <div className="company">
          <SegmentationScoreIcon userId={userId} previouslySegmentationInfo={user.segmentationInfo} />
          {user.shopify && <FaShopify className="icon" />}
          <span className="username-bar" title={user.url}>{username}</span>
        </div>
        <div className="row">
          {!isNaN(dayDifference) ? (
            <div className="days">Day {dayDifference}</div>
          ) : null}
          <div className="more">
            {getSupportTicketIcon()}
            <NotesPopup user={user} username={username} onClick={goToDetails} />
            <AdminPanelCustomerCardMenu user={user} username={username} openInNewTab={shownInOutsideOfList} />
          </div>
        </div>
      </div>
      <div className="card-content" ref={rippleAnimateRef}>
        <div className="section status">
          <div className="account-status">
            <QuickDropdown
              className="status-text"
              options={
                constants.subscriptionStatusOptions
              }
              emptyText={"<Not set>"}
              preSelectedOption={user.displayStatus}
              onSave={async (value) => {
                await save('subscription.status', value)
              }} />
          </div>
          {user.displayStatus === "Churn" ?
            <div>
              <div>
                <QuickDropdown
                  className="churn-reason"
                  options={
                    [
                      "No Customer Access",
                      "Technical Issues / Bugs",
                      "Poor Support",
                      "Low Budget",
                      "Low ROAS",
                      "No Meta Ads, Only Google Ads",
                      "Other"
                    ]
                  }
                  preText={"⛔️"}
                  emptyText={"<Not set>"}
                  preSelectedOption={user.crmDetails?.churnReason}
                  onSave={async (value) => {
                    await save('churnReason', value)
                  }} />
              </div>
              <div>{formatDate(user.crmDetails.contractEndDate)}</div>
            </div>
            : null}
          <div className="performance-status">
            <QuickDropdown
              className="status-text"
              options={
                constants.performanceStatusTypes
              }
              emptyText={"No status"}
              preSelectedOption={user.crmDetails?.performanceStatusType}
              onSave={async (value) => {
                await save('performanceStatusType', value)
              }} />
          </div>
        </div>

        <div onClick={goToReportPage} className="section process">
          <div className="overview">
            <div className="item">
              <span className="day">
                {!isNaN(connectionsCompletedInDays) ? (
                  `Day ${connectionsCompletedInDays < 0
                    ? 0
                    : connectionsCompletedInDays
                  }`
                ) : (
                  <span className="ne">-</span>
                )}
                {/* (connectionsCompletedInDays != null && connectionsCompletedInDays != undefined) ? */}
              </span>
              <span className="description">Connected</span>
            </div>

            {
              <div className="item">
                <span className="day">
                  {!isNaN(fullFunnelCampaignStartedInDays) ? (
                    `Day ${fullFunnelCampaignStartedInDays < 0
                      ? 0
                      : fullFunnelCampaignStartedInDays
                    }`
                  ) : (
                    <span className="ne">-</span>
                  )}
                  &nbsp;
                </span>
                <span className="description">Campaign Start</span>
              </div>
            }

            <div className="item">
              <span className="day">
                {!isNaN(viewedDetailedFacebookReportInDays) ? (
                  `Day  ${viewedDetailedFacebookReportInDays < 0
                    ? 0
                    : viewedDetailedFacebookReportInDays
                  }`
                ) : (
                  <span className="ne">-</span>
                )}
                &nbsp;
              </span>
              <span className="description">Report Viewed</span>
            </div>
          </div>
          <AdminPanelCustomerCardReportSummaryPart
            userId={userId}
            fullFunnelCampaignStartedInDays={fullFunnelCampaignStartedInDays}
            weeklyReports={weeklyReports}
            currency={currency} />

        </div>

        <div className='section contact'>
          <div className="inputs">
            <div className="line">
              <CustomerCardInput
                label={"Calls"}
                userId={user.id}
                oldValue={user.crmDetails?.calls?.count}
                oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastCall)}
                onSave={async (value) => {
                  await save('calls', { count: value, lastModified: Date.now() })
                }}
              />
              <CustomerCardInput
                label={"Talks"}
                userId={user.id}
                oldValue={user.crmDetails?.talks?.count}
                oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastTalk)}
                onSave={async (value) => {
                  await save('talks', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
            <div className="line">
              <CustomerCardInput
                label={"Emails"}
                userId={user.id}
                oldValue={user.crmDetails?.emails?.count}
                oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastEmail)}
                onSave={async (value) => {
                  await save('emails', { count: value, lastModified: Date.now() })
                }}
              />
              <CustomerCardInput
                label={"SMSes"}
                userId={user.id}
                oldValue={user.crmDetails?.smses?.count}
                oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastSMS)}
                onSave={async (value) => {
                  await save('smses', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
            <div className="line">
              <CustomerCardInput
                label={"Supports"}
                userId={user.id}
                oldValue={user.crmDetails?.supports?.count}
                oldDaysPassedSinceLast={parseInt(user.daysPassedSinceLastSupport)}
                onSave={async (value) => {
                  await save('supports', { count: value, lastModified: Date.now() })
                }}
              />
            </div>
          </div>
        </div>
        <div className="section contact">
          <div className="customer-info">
            <span
              className={`phone ${fullPhoneNumber ? '' : 'not-available'}`}
              onClick={copyPhoneNumber}
              title={fullPhoneNumber ? 'Click to copy' : 'No phone number info'}
            >
              <div>
                <div>
                  {fullPhoneNumber ? fullPhoneNumber : 'No phone'}
                  <FaCopy className="icon" />
                </div>
                {preferredCallTimeRange &&
                  <span className='helper-text'>
                    <FaRegClock className='clock-icon' />{' '}
                    {preferredCallTimeRange}
                  </span>
                }
              </div>
            </span>
            <Link
              className="email"
              onClick={writeEmail}
              to={`mailto:${user.crmDetails?.contactEmail}`}
              title="Send new mail"
            >
              <FaPenSquare className="icon" />
              {user.crmDetails?.contactEmail}
            </Link>
          </div>
        </div>
        <div className="section account-details">
          <div className='line'>
            <div className="item">
              <div className='pre-desc'>Source: </div>
              <QuickDropdown
                className="source-type"
                options={[
                  "Self-Subscribed",
                  "Outbound Reach",
                  "Meta Demo",
                  "Google Demo",
                  "Direct Demo",
                  "Organic Demo",
                  "Sign-up Call",
                  "Sign-up Demo",
                  "Tidio",
                  "Partner"
                ]}
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.sourceType}
                onSave={async (value) => {
                  await save('sourceType', value)
                }} />
            </div>
          </div>
          <div className="line">
            <div className="item">
              <div className='pre-desc'>Acc.Mngr: </div>
              <QuickDropdown
                className="account-manager"
                options={
                  Object.keys(accountManagers)
                }
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.accountManager}
                isShorteningName={true}
                isConvertingFromId={true}
                onSave={async (value) => {
                  await save('accountManager', value)
                }} />
              <Checkbox
                name={"didAccMngrSell"}
                label={"🤝"}
                value={user.crmDetails?.didAccMngrSell ?? false}
                checked={user.crmDetails?.didAccMngrSell ?? false}
                onChange={(event) => {
                  saveDidAccMngrSell(event.target.value !== "true")
                }}
              />
            </div>
            {user.crmDetails?.subscription && (
              <div className="item">
                <span className='currency'>
                  {user.crmDetails?.subscription?.currency
                    ? helper.getCurrencySymbol(
                      user.crmDetails?.subscription?.currency,
                    )
                    : '$'}
                </span>
                <span className='price'>
                  {user.crmDetails?.subscription?.mrr * (user.crmDetails?.subscription?.period === "YEARLY" ? 12 : 1)}
                </span>
                /{' '}
                {user.crmDetails?.subscription?.period
                  ? user.crmDetails?.subscription?.period.toLowerCase().replace('ly', '')
                  : 'month'}

                {usageCharges ?
                  <span title='Extra usage charge' style={{ color: "var(--second-green)", fontWeight: "400" }} ref={usageChargeElementRef}>
                    + ${usageCharges.toFixed(1)}
                  </span> : null
                }
              </div>
            )}
          </div>
          <div className="line">
            <div className="item">
              <div className='pre-desc'>Perf.Mngr: </div>
              <QuickDropdown
                className="performance-manager"
                options={
                  Object.keys(performanceManagers)
                }
                emptyText={"<Not set>"}
                preSelectedOption={user.crmDetails?.performanceManager}
                isShorteningName={true}
                isConvertingFromId={true}
                onSave={async (value) => {
                  await save('performanceManager', value)
                }} />
              {user.crmDetails?.performanceManagerChangeDate ? (
                <p className="description">
                  <FaHistory />
                  {calculateDayPassed(user.crmDetails?.performanceManagerChangeDate) === 0
                    ? 'Today'
                    : `${calculateDayPassed(user.crmDetails?.performanceManagerChangeDate)}d`}
                </p>
              ) : null}
            </div>
            <div className="item">
              {country}
              {user.crmDetails?.audienceNetworkSwitch ? (
                <img
                  src={audienceNetworkIcon}
                  alt="Audience Network"
                  title="Audience Network"
                />
              ) : null}
            </div>
          </div>
          <div className='line'>
            <div className="item input" onClick={e => e.stopPropagation()} style={
              { display: 'flex', flexDirection: 'column' }
            }>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
                <label htmlFor={`comment-${user.id}`}>{"Comment"}</label>
                <StarRating initialRating={fields.comment.value} onSaveRating={(rating) => {
                  setFields(prev => ({
                    ...prev,
                    comment: {
                      ...prev.comment,
                      value: rating
                    },
                  }))
                }} />

                {fields.comment.value !== (user.crmDetails?.comment?.value ?? 0) ? (
                  <button onClick={saveComment} className="save-btn">
                    <FaSave className="icon" />
                  </button>
                ) : !isNaN(daysPassedSinceLastComment) ? (
                  <p className="description">
                    <FaHistory />
                    {daysPassedSinceLastComment === 0
                      ? 'Today'
                      : `${daysPassedSinceLastComment}d`}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="item">
              {user.googleAds?.conversionId ? (
                <FaGoogle className='icon' />
              ) : null}
            </div>
          </div>
        </div>
        {/* <PrivateComponent children={ <div className='section'>
          <AdminPanelCustomerCardAlarmPart user={user} findByIdAndUpdate={adminActions.findCustomerByIdAndUpdate} alarmsPath="crmAlarms" />
        </div>}
        permissions={["viewAlarmPart"]} accountManager={user.crmDetails.accountManager} performanceManager={user.crmDetails.performanceManager}  /> */}
        <div className='section'>
          <AdminPanelCustomerCardAlarmPart user={user} findByIdAndUpdate={adminActions.findCustomerByIdAndUpdate} alarmsPath="crmAlarms" />
        </div>
      </div>
    </div>
  )
}

export default AdminPanelCustomerCard
